import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ShortTermBookingRequest } from 'ux/Public/Listing/STB/types';

export type STBRequestState = {
  stbRequest: Partial<ShortTermBookingRequest>;
};

const initialState: STBRequestState = {
  stbRequest: {}
};

const stbRequestSlice = createSlice({
  name: 'STBRequest',
  initialState,
  reducers: {
    updateStbRequest: (state, action: PayloadAction<Partial<ShortTermBookingRequest>>) => {
      state.stbRequest = { ...state.stbRequest, ...action.payload };
    },
    reset: () => initialState
  }
});

export const { actions } = stbRequestSlice;

export default stbRequestSlice.reducer;
