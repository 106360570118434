import React, { PropsWithChildren, useContext, useEffect } from 'react';

import { app, media } from 'context';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { CONTENT_MAX_WIDTH, HEADER_Z_INDEX } from 'ds/constants';
import { setRootBackgroundColor } from 'ds/utils';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import AppPageTitle from './AppPageTitle';
import TertiaryMenu from './TertiaryMenu';
import {
  APP_CONTENT_GUTTER_BOTTOM,
  APP_CONTENT_GUTTER_TOP,
  APP_CONTENT_GUTTER_TOP_MOBILE,
  APP_PAGE_NAV_BAR_HEIGHT,
  APP_PAGE_PADDING_TOP,
  APP_PAGE_PADDING_X,
  APP_PAGE_PADDING_X_MOBILE
} from './constants';
import { CommonPageLayoutProps, PageTypeProps } from './types';

type Props = Pick<
  CommonPageLayoutProps,
  'maxWidth' | 'hasPaddingXMobile' | 'hasGutterTop' | 'hasGutterBottom' | 'hasPaddingX' | 'titleColor'
> &
  PageTypeProps;

const Content: React.FC<PropsWithChildren<Props>> = ({
  children,
  type,
  hasPaddingXMobile = true,
  tertiaryMenuProps,
  StickyBar,
  title,
  CTA,
  breadCrumbs,
  mobileColor,
  hasGutterTop = true,
  hasGutterBottom = true,
  hasPaddingX = true,
  titleColor,
  ...props
}) => {
  const { appPaddingX } = useContext(app);
  const { isMobile } = useContext(media);
  const noNavBar = !title && !breadCrumbs && !CTA;

  useEffect(() => {
    if (isMobile && mobileColor) {
      setRootBackgroundColor(mobileColor);
    } else {
      setRootBackgroundColor(null);
    }

    return () => {
      setRootBackgroundColor(null);
    };
  }, [isMobile, mobileColor]);

  const gutterTop = hasGutterTop ? (isMobile ? APP_CONTENT_GUTTER_TOP_MOBILE : APP_CONTENT_GUTTER_TOP) : undefined;
  const paddingBottom = hasGutterBottom ? APP_CONTENT_GUTTER_BOTTOM : undefined;

  let paddingX: number | undefined;

  if (isMobile) {
    paddingX = hasPaddingXMobile ? appPaddingX : undefined;
  } else {
    paddingX = hasPaddingX ? appPaddingX : undefined;
  }

  switch (type) {
    case 'object':
    case 'index':
    default:
      const maxWidth = props.maxWidth === 'none' ? undefined : props.maxWidth || CONTENT_MAX_WIDTH + appPaddingX * 2;
      return (
        <Layout flexGrow={1} minWidth={0}>
          {StickyBar && (
            <StickyHeader top={APP_PAGE_NAV_BAR_HEIGHT} zIndex={100}>
              <Layout justify="center" flexGrow={1} paddingY={APP_PAGE_PADDING_TOP} color="white" zIndex={1}>
                <Layout flexGrow={1} align="center" justify="space-between" maxWidth={maxWidth} paddingX={appPaddingX}>
                  <Text size="headline-md" color="blue-700">
                    {title}
                  </Text>
                  {StickyBar}
                </Layout>
              </Layout>
            </StickyHeader>
          )}
          <Layout justify="center" flexGrow={1}>
            <Layout
              flexGrow={1}
              paddingX={paddingX}
              paddingTop={gutterTop}
              paddingBottom={paddingBottom}
              maxWidth={isMobile ? undefined : maxWidth}
              minWidth={0}
            >
              {children}
            </Layout>
          </Layout>
        </Layout>
      );
    case 'secondary':
      return (
        <Layout
          flexGrow={1}
          {...(isMobile
            ? { paddingX: APP_PAGE_PADDING_X_MOBILE }
            : { paddingX: hasPaddingX ? APP_PAGE_PADDING_X : undefined })}
          paddingBottom={paddingBottom}
          paddingTop={hasGutterTop ? (isMobile ? gutterTop : noNavBar ? 24 : 16) : undefined}
        >
          {children}
        </Layout>
      );
    case 'tertiary':
      return isMobile ? (
        <Layout
          flexGrow={1}
          paddingX={hasPaddingXMobile ? APP_PAGE_PADDING_X_MOBILE : undefined}
          maxWidth={CONTENT_MAX_WIDTH + appPaddingX * 2}
          paddingTop={gutterTop}
          paddingBottom={paddingBottom}
        >
          {children}
        </Layout>
      ) : (
        <Layout flexGrow={1} justify="center">
          <Layout marginLeft={40} paddingTop={APP_PAGE_PADDING_TOP} zIndex={HEADER_Z_INDEX}>
            <TertiaryMenu {...tertiaryMenuProps} />
          </Layout>
          <Layout maxWidth={CONTENT_MAX_WIDTH + appPaddingX * 2} flexGrow={1} paddingBottom={40}>
            {StickyBar ? (
              <StickyHeader top={APP_PAGE_NAV_BAR_HEIGHT} zIndex={100} shadow={false}>
                <Layout
                  justify="center"
                  flexGrow={1}
                  paddingX={40}
                  paddingY={APP_PAGE_PADDING_TOP}
                  color="white"
                  zIndex={1}
                >
                  <Layout flexGrow={1} align="center" justify="space-between">
                    <Text size="h5" scale>
                      {title}
                    </Text>
                    {StickyBar}
                  </Layout>
                </Layout>
              </StickyHeader>
            ) : title ? (
              <Layout paddingX={40} paddingY={APP_PAGE_PADDING_TOP}>
                <AppPageTitle title={title} titleColor={titleColor} />
              </Layout>
            ) : null}
            <Layout flexGrow={1} marginX={40} justify="center">
              <Layout paddingTop={gutterTop} flexGrow={1}>
                {children}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      );
  }
};

export default Content;
