import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

export interface Props {
  // TODO: determine sizes for this prop
  size?: 'sm' | 'md' | 'lg';
}

const Card: React.FC<PropsWithChildren<Props>> = ({ size = 'md', children }) => {
  return <div className={classNames('Card', `is-size-${size}`)}>{children}</div>;
};

export default Card;
