import React, { useContext, useEffect, useState } from 'react';
import { BreadcrumbList, WebPage, WithContext } from 'schema-dts';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Animate, Button, FullWidthBackground, Icon, Img, Layout, Text } from 'ds';
import { ADD_PROPERTY_PATH } from 'routes';

import TenantLeadRow from './TenantLeadRow';
import { fetchTenantLeads } from './requests';
import { TenantLead } from './types';

import { ReactComponent as Bridgeton } from './logos/Bridgeton.svg';
import { ReactComponent as Colliers } from './logos/Colliers.svg';
import { ReactComponent as CushmanAndWakefield } from './logos/CushmanAndWakefield.svg';
import { ReactComponent as Jamestown } from './logos/jamestown.svg';

const CommercialRealEstate: React.FC = () => {
  const { xs, xl, xxl } = useContext(media);
  const { maxWidth, contentPaddingX, windowWidth, maxContentWidth } = useContext(app);
  const [tenantLeads, setTenantLeads] = useState<TenantLead[]>();

  const difference =
    maxContentWidth && windowWidth > maxContentWidth + contentPaddingX * 2 ? windowWidth - maxContentWidth : undefined;
  const fullScreenImageRight = difference && difference > 0 ? difference / 2 : contentPaddingX;

  useEffect(() => {
    fetchTenantLeads().then(({ data: leads }) => {
      setTenantLeads(leads);
    });
  }, []);

  if (!tenantLeads) return <Layout minHeight="100vh" />;

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${window.location.origin}/#website`,
          name: 'Codi',
          url: window.location.origin
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${window.location.origin}/#commercial-real-estate`,
          name: 'Grow Your Commercial Real Estate Revenue',
          url: window.location.href
        }
      }
    ]
  };

  const schema: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': window.location.href,
    url: window.location.href,
    breadcrumb: breadcrumbs,
    name: 'Grow Your Commercial Real Estate Revenue',
    speakable: { '@type': 'SpeakableSpecification', xpath: ["/html/head/meta[@name='description']/@content"] },
    description:
      'Codi helps you find reliable tenants with no additional effort. We’ll handle everything, from listing and marketing your vacant commercial properties, as well as providing monthly performance updates.',
    significantLink: `${window.location.origin}${ADD_PROPERTY_PATH}`,
    image: 'https://cdn.buttercms.com/tUVhA8aXSJqPVe4elAhK'
  };

  return (
    <>
      <Head
        title="Grow Your Commercial Real Estate Revenue"
        description="Codi helps you find reliable tenants with no additional effort. We’ll handle everything, from listing and marketing your vacant commercial properties, as well as providing monthly performance updates."
      />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <HeroImage img="https://cdn.buttercms.com/tUVhA8aXSJqPVe4elAhK" height={xs ? 608 : 616}>
        <Layout
          position="relative"
          width="100%"
          height="100%"
          justify="center"
          align="center"
          zIndex={2}
          __style={{
            background: xs
              ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))'
              : 'linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))'
          }}
        >
          <Layout width="100%" __style={{ maxWidth }} paddingX={contentPaddingX}>
            <Animate>
              <Text size={'h2'} color="white" scale>
                Lower vacancy.
                <br />
                Higher returns.
              </Text>
            </Animate>
            <Animate marginTop={xs ? 16 : 24} direction={xs ? 'column' : 'row'}>
              <Text size={xs ? 'h6' : 'h3'} color="white" notBold>
                Grow your commercial
                <br />
                real estate cash flow.
              </Text>
            </Animate>
            <Layout marginTop={40} direction="row" align="center">
              <Button
                type="primary"
                size="lg"
                icon="rightArrow"
                iconStroke={2}
                text="Get started"
                href={ADD_PROPERTY_PATH}
              />
              <Layout marginLeft={16} />
              <Button
                iconStroke={1.5}
                size="lg"
                type="secondary"
                href="https://codi-downloads.s3.amazonaws.com/Codi LL Broker 2-Pager.pdf"
                target="_blank"
                text="Download PDF"
                iconPosition="left"
                icon="download"
              />
            </Layout>
          </Layout>
        </Layout>
      </HeroImage>
      <FullWidthBackground color="blue-800">
        <Layout paddingTop={xs ? 80 : 100} paddingBottom={xs ? 80 : 124} direction="column" align="center">
          <Animate>
            <Text size="h3" color="blue-gradient" align="center" scale>
              Share your space
              <br />
              with our clients
            </Text>
          </Animate>
          <Layout marginTop={16} />
          <Layout paddingX={24}>
            <Animate>
              <Text size="body1" color="white" align="center" scale>
                Below are some of our most recent tenant requests for office space.
              </Text>
            </Animate>
          </Layout>
          <Layout marginTop={40} />
          <Layout width="100vw" paddingX={contentPaddingX} direction="row" justify="flex-start" overflowX="scroll">
            <Layout color="white" borderRadius={12} width={1166} margin="0px auto">
              <Layout height={68} direction="row" align="center" paddingX={24} borderBottom>
                <Layout width={116}>
                  <Text size="body3" semibold>
                    Client
                  </Text>
                </Layout>
                <Layout width={232}>
                  <Text size="body3" semibold>
                    Industry
                  </Text>
                </Layout>
                <Layout width={232}>
                  <Text size="body3" semibold>
                    Location
                  </Text>
                </Layout>
                <Layout width={144}>
                  <Text size="body3" semibold>
                    Size (ft²)
                  </Text>
                </Layout>
                <Layout width={168}>
                  <Text size="body3" semibold>
                    Monthly Budget ($)
                  </Text>
                </Layout>
                {!xs && <Layout width={112} />}
              </Layout>
              {tenantLeads.map((tenantLead, index) => (
                <TenantLeadRow
                  key={tenantLead.id}
                  tenantLead={tenantLead}
                  index={index}
                  tenantLeadsLength={tenantLeads.length}
                />
              ))}
            </Layout>
          </Layout>
        </Layout>
      </FullWidthBackground>
      <FullWidthBackground paddingTop={xs ? 80 : 100} paddingBottom={xs ? 80 : 124} color="blue-700">
        <Layout width="100%" justify="center" position="relative">
          <Layout direction="column" align="center" margin="0px auto">
            <Text size="h3" color="white" scale>
              The benefits
            </Text>
            <Layout marginY={64}>
              <Layout __style={{ gap: 28 }} maxWidth={xl ? 1284 : xs ? undefined : 628} wrap>
                <Layout
                  paddingX={24}
                  paddingY={48}
                  height={240}
                  width={xs ? '100%' : 296}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  align="center"
                >
                  <Icon name="payout" size={48} color="blue-gradient" />
                  <Layout marginTop={24} />
                  <Text size="h6" color="gray-800" notBold align="center">
                    Reduce vacancy and
                    <br />
                    improve cash flow
                  </Text>
                </Layout>
                <Layout
                  paddingX={24}
                  paddingY={48}
                  height={240}
                  width={xs ? '100%' : 300}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  align="center"
                >
                  <Icon name="calendar" size={48} color="blue-gradient" />
                  <Layout marginTop={24} />
                  <Text size="h6" color="gray-800" notBold align="center">
                    Find reliable
                    <br />
                    tenants
                  </Text>
                </Layout>
                <Layout
                  paddingX={24}
                  paddingY={48}
                  height={240}
                  width={xs ? '100%' : 300}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  align="center"
                >
                  <Icon name="callout" size={48} color="blue-gradient" />
                  <Layout marginTop={24} />
                  <Text size="h6" color="gray-800" notBold align="center">
                    Expand the reach of your
                    <br />
                    space through our
                    <br />
                    marketing engine
                  </Text>
                </Layout>
                <Layout
                  paddingX={24}
                  paddingY={48}
                  height={240}
                  width={xs ? '100%' : 300}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  align="center"
                >
                  <Icon name="clipboard" size={48} color="blue-gradient" />
                  <Layout marginTop={24} />
                  <Text size="h6" color="gray-800" notBold align="center">
                    Codi activates and operates
                    <br />
                    the space to improve
                    <br />
                    leasing velocity
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout width={212}>
              <Button
                type="primary"
                size="lg"
                text="Get started"
                icon="rightArrow"
                href={ADD_PROPERTY_PATH}
                fullWidth
              />
            </Layout>
          </Layout>
        </Layout>
      </FullWidthBackground>
      <FullWidthBackground color="blue-800">
        <Layout paddingY={xs ? 64 : 80} width="100%" direction="column" align="center">
          <Text size={xs ? 'body1' : 'large-link'} color="blue-300" semibold caps>
            trusted by
          </Text>
          <Layout marginTop={48} />
          <Layout
            width="100%"
            direction="row"
            justify="space-evenly"
            align="center"
            wrap
            __style={{ rowGap: 48, columnGap: 24 }}
          >
            <Bridgeton />
            <Jamestown />
            <Colliers />
            <CushmanAndWakefield />
          </Layout>
        </Layout>
      </FullWidthBackground>
      <Img
        srcKey="e6a421371e38c8d343831983605f0629_yu9zLLeag.jpeg"
        alt="office"
        {...(xs ? { width: 600 } : xl ? { width: 3000 } : { width: 2000 })}
        folder="public"
        style={{
          objectFit: 'cover',
          objectPosition: xxl ? '50% 62%' : undefined,
          height: xs ? 156 : xl ? 356 : 288,
          width: windowWidth,
          right: fullScreenImageRight,
          position: 'relative'
        }}
      />
      <FullWidthBackground paddingY={xs ? 80 : 168} color="blue-300">
        <Layout width="100%" justify="center" position="relative">
          <Layout direction="column" align="center">
            <Text size="h3" align="center" color="blue-800" scale>
              About your space
            </Text>
            <Layout marginY={40}>
              <Layout __style={{ gap: 24 }} maxWidth={584} wrap>
                <Layout
                  height={208}
                  width={xs ? '100%' : 280}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  justify="center"
                  align="center"
                >
                  <Icon name="buildingNew" size={52} color="blue-500" />
                  <Layout height={24} />
                  <Text size="body1" color="blue-800" semibold>
                    Zoned for commercial office
                  </Text>
                </Layout>
                <Layout
                  height={208}
                  width={xs ? '100%' : 280}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  justify="center"
                  align="center"
                >
                  <Icon name="starOutline" size={52} color="blue-500" />
                  <Layout height={24} />
                  <Text size="body1" color="blue-800" semibold>
                    Built out in good condition
                  </Text>
                </Layout>
                <Layout
                  height={208}
                  width={xs ? '100%' : 280}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  justify="center"
                  align="center"
                >
                  <Icon name="outdoors" size={52} color="blue-500" />
                  <Layout height={24} />
                  <Text size="body1" color="blue-800" semibold>
                    Good natural light
                  </Text>
                </Layout>
                <Layout
                  height={208}
                  width={xs ? '100%' : 280}
                  color="white"
                  borderRadius={24}
                  direction="column"
                  justify="center"
                  align="center"
                >
                  <Icon name="calendar" size={52} color="blue-500" />
                  <Layout height={24} />
                  <Text size="body1" color="blue-800" semibold>
                    24/7 access
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout width={242}>
              <Button
                type="primary"
                size="lg"
                text="List your space"
                icon="rightArrow"
                href={ADD_PROPERTY_PATH}
                fullWidth
              />
            </Layout>
          </Layout>
        </Layout>
      </FullWidthBackground>
      <FullWidthBackground paddingY={xs ? 80 : 124} color="blue-50">
        <Layout width="100%" justify="center">
          <Layout maxWidth={500} direction="column" align="center">
            <Text size="h3" color="blue-800" align="center" scale>
              Ready to boost your
            </Text>
            <Text size="h3" color="blue-500" align="center" scale>
              cash flow?
            </Text>
            <Layout marginTop={36} />
            <Layout width={212}>
              <Button
                type="primary"
                size="lg"
                text="Get started"
                href={ADD_PROPERTY_PATH}
                icon="rightArrow"
                fullWidth
              />
            </Layout>
          </Layout>
        </Layout>
      </FullWidthBackground>
    </>
  );
};

export default CommercialRealEstate;
