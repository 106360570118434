import React, { useContext } from 'react';

import { media } from 'context';
import { IconButton, Img, Layout, Modal, Text } from 'ds';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

import ExitModalCTA from './ExitModalCTA';

interface Props {
  isVisible: boolean;
}

const ExitModal: React.FC<Props> = ({ isVisible }) => {
  const dispatch = useAppDispatch();
  const { xs } = useContext(media);

  return (
    <Modal
      width={768}
      isVisible={isVisible}
      onClose={() => dispatch(actions.setExitModalIsVisible(false))}
      paddingX={0}
      paddingTop={0}
      paddingBottom={0}
      showCloseButton={false}
      hasFooterBorder={false}
      {...(xs ? { isFullScreen: true, footer: <ExitModalCTA /> } : {})}
    >
      <Layout
        background="radial-gradient(68.42% 245.16% at 75.52% 81.11%, #10EFEF 0%, #0D99FF 38.44%, #0974D3 100%)"
        {...(xs
          ? {}
          : {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12
            })}
        overflow="hidden"
        position="relative"
      >
        <Img srcKey="office-request-modal-background_NnrB-DBKW.png" folder="public" alt="workspaces" width={900} />
        {xs && (
          <Layout position="absolute" top={24} right={24}>
            <IconButton
              type="transparentDark"
              onClick={() => dispatch(actions.setExitModalIsVisible(false))}
              name="close"
              size="md"
            />
          </Layout>
        )}
      </Layout>
      <Layout padding={xs ? 24 : 32}>
        <Text size="h4" color="blue-800">
          Leaving so soon?
        </Text>
        <Layout marginTop={xs ? 16 : 24}>
          <Text size="body1" color="gray-800">
            Once you exit, you will lose your progress. Prefer to browse on your own? Check out hundreds of available
            office spaces.
          </Text>
        </Layout>
        {!xs && (
          <Layout marginTop={36} {...(xs ? {} : { justify: 'space-between', align: 'center' })}>
            <ExitModalCTA />
          </Layout>
        )}
      </Layout>
    </Modal>
  );
};

export default ExitModal;
