import React, { useCallback, useContext, useState } from 'react';

import { app } from 'context';
import { SwiperSlide } from 'swiper/react';

import SessionContextCard from './SessionContextCard';
import { SessionWorkspace } from './types';
import Carousel from '../Carousel';

export type Props = {
  paginationEnabled?: boolean;
  workspaces: SessionWorkspace[];
};

const CONFIG = {
  xl: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  lg: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  md: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  sm: {
    slidesPerView: 2,
    spaceBetween: 24
  },
  xs: {
    slidesPerView: 2,
    spaceBetween: 24
  },
  phone: {
    slidesPerView: 1,
    spaceBetween: 24
  }
};

const WorkspaceSessionCarousel: React.FC<Props> = ({ workspaces }) => {
  const { width } = useContext(app);
  const [imageRect, setImageRect] = useState<DOMRect>();

  const onImageMeasure = useCallback(
    (rect: DOMRect) => {
      setImageRect(rect);
    },
    [width] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Carousel imageRect={imageRect} config={CONFIG}>
      {workspaces.map(workspace => {
        return (
          <SwiperSlide key={workspace.id}>
            <SessionContextCard workspace={workspace} onImageMeasure={onImageMeasure} />
          </SwiperSlide>
        );
      })}
    </Carousel>
  );
};

export default WorkspaceSessionCarousel;
