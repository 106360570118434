import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { colorClassName } from '@codiwork/codi';
import classNames from 'classnames';
import { scroller } from 'react-scroll';

import Head from 'components/Head';

import { media } from 'context';
import { Layout } from 'ds';
import { CMSRouteProps } from 'routes/types';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

interface Props extends CMSRouteProps {}

const Terms: React.FC<Props> = ({ cmsSlug }) => {
  const { hash } = useLocation();
  const page = useAppSelector(state => selectCmsPage(state, cmsSlug));
  const { xs } = useContext(media);

  useEffect(() => {
    if (!page) return;

    if (hash === '#enterprise') {
      scroller.scrollTo('enterprise', {
        smooth: false,
        offset: -100
      });
    }
  }, [page, hash]);

  if (!page) return <Layout minHeight="100vh" />;

  const {
    fields: {
      seo: { title, description, keywords },
      content
    }
  } = page;

  return (
    <Layout __className="CmsContent" marginTop={xs ? undefined : 64} justify="center">
      <Head title={title} description={description} keywords={keywords} />
      <Layout maxWidth={744}>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className={classNames(colorClassName('gray-600'), 'PolicyPage')}
        />
      </Layout>
    </Layout>
  );
};

export default Terms;
