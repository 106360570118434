import {
  CLEANING_TASK_TYPE,
  CartItem,
  DayCleaning,
  IconName,
  TOUCH_UP_TASK_TYPE,
  WeeklyCleaningDay,
  WeeklyCleaningSchedule
} from '@codiwork/codi';
import { isEqual } from 'lodash';

import { DAY_NUMBER_TO_LONG_DAY_MAP } from './types';

export const MONDAY_0_DAY_TO_NUMBER_MAP = {
  Mondays: 0,
  Tuesdays: 1,
  Wednesdays: 2,
  Thursdays: 3,
  Fridays: 4,
  Saturdays: 5,
  Sundays: 6
};

const getSrcChangeDay = ({
  srcChangeDays,
  dayToChange
}: {
  srcChangeDays: DayCleaning[];
  dayToChange: DayCleaning;
}) => {
  const filteredSrcChangeDays =
    dayToChange.value === 'cleaning' ? srcChangeDays : srcChangeDays.filter(sd => sd.value === 'touch-up');
  const sameDay = filteredSrcChangeDays.find(sd => sd.day === dayToChange.day);

  if (sameDay) return sameDay;

  return filteredSrcChangeDays[0];
};

// TODO: refactor into multiple, simpler, smaller parts?
export const makeCartItemsfromCleaningSchedules = ({
  newSchedule,
  existingSchedule,
  cartId,
  userId,
  weeklyCleaningProductId,
  weeklyTouchUpProductId
}: {
  newSchedule: WeeklyCleaningSchedule;
  existingSchedule: WeeklyCleaningSchedule;
  cartId: number;
  userId: number;
  weeklyCleaningProductId: number;
  weeklyTouchUpProductId: number;
}) => {
  const cartItems = [];

  let srcChangeDays: DayCleaning[] = [];
  let daysToChange: DayCleaning[] = [];

  existingSchedule.forEach((day, index) => {
    const newScheduleDay = newSchedule[index];
    if (!isEqual(day, newScheduleDay)) {
      if (day.value) {
        srcChangeDays.push(day);
      }

      if (newScheduleDay.value) {
        daysToChange.push(newScheduleDay);
      }
    }
  });

  // first we take care of the free cleaning change if there is one.
  const freeCleaningInSrcChangeDays = srcChangeDays.find(day => day.value === 'cleaning' && day.price === 0);
  const freeCleaningInDaysToChange = daysToChange.find(day => day.value === 'cleaning' && day.price === 0);

  if (freeCleaningInSrcChangeDays && freeCleaningInDaysToChange) {
    srcChangeDays = srcChangeDays.filter(day => day.day !== freeCleaningInSrcChangeDays.day);
    daysToChange = daysToChange.filter(day => day.day !== freeCleaningInDaysToChange.day);

    const cartItem = {
      cartId,
      userId,
      date: 'fill in',
      productId: weeklyCleaningProductId,
      days_to_remove: [MONDAY_0_DAY_TO_NUMBER_MAP[freeCleaningInSrcChangeDays.day]],
      days_to_add: [MONDAY_0_DAY_TO_NUMBER_MAP[freeCleaningInDaysToChange.day]]
    };

    cartItem['date'] = new Date().toISOString();
    cartItems.push(cartItem);
  }

  srcChangeDays.sort((a, _b) => (a.value === 'touch-up' ? -1 : 1));
  daysToChange.sort((a, _b) => (a.value === 'touch-up' ? -1 : 1));

  for (const day of daysToChange) {
    const changeDay = getSrcChangeDay({ srcChangeDays, dayToChange: day });

    if (changeDay) {
      srcChangeDays = srcChangeDays.filter(day => day.day !== changeDay.day);
      const cartItem = {
        userId,
        cartId,
        date: 'fill in',
        productId: day.value === 'cleaning' ? weeklyCleaningProductId : weeklyTouchUpProductId,
        days_to_remove: [MONDAY_0_DAY_TO_NUMBER_MAP[changeDay.day]],
        days_to_add: [MONDAY_0_DAY_TO_NUMBER_MAP[day.day]],
        product_to_remove_id:
          day.value === 'cleaning' && changeDay.value === 'touch-up' ? weeklyTouchUpProductId : undefined
      };

      cartItem['date'] = new Date().toISOString();
      cartItems.push(cartItem);
    } else {
      cartItems.push({
        userId,
        cartId,
        productId: day.value === 'cleaning' ? weeklyCleaningProductId : weeklyTouchUpProductId,
        days_to_remove: [],
        days_to_add: [MONDAY_0_DAY_TO_NUMBER_MAP[day.day]]
      });
    }
  }

  return cartItems;
};

export const createScheduleFromCartItems = ({
  weeklyCleaningSchedule,
  cleaningScheduleCartItems,
  weeklyCleaningProductId,
  schedule,
  monthly_once_per_week_cleaning_price,
  monthly_once_per_week_touch_up_price
}: {
  weeklyCleaningSchedule: WeeklyCleaningSchedule;
  cleaningScheduleCartItems: CartItem[];
  schedule: WeeklyCleaningSchedule;
  weeklyCleaningProductId: number;
  monthly_once_per_week_cleaning_price: number;
  monthly_once_per_week_touch_up_price: number;
}): WeeklyCleaningSchedule => {
  let newSchedule = [...weeklyCleaningSchedule];

  cleaningScheduleCartItems.forEach(cartItem => {
    const priceFromStateSchedule = schedule[cartItem.days_to_add[0]].price;
    const dayToAdd = cartItem.days_to_add[0];
    const wday = (dayToAdd + 1) % 7;
    const cleaningType = cartItem.product.id === weeklyCleaningProductId ? CLEANING_TASK_TYPE : TOUCH_UP_TASK_TYPE;
    newSchedule[dayToAdd] = {
      value: cleaningType,
      price:
        cartItem.unit_price === 0
          ? priceFromStateSchedule
          : cleaningType === CLEANING_TASK_TYPE
          ? monthly_once_per_week_cleaning_price
          : monthly_once_per_week_touch_up_price,
      day: DAY_NUMBER_TO_LONG_DAY_MAP[dayToAdd] as WeeklyCleaningDay,
      wday
    };
    const dayToRemove = cartItem.days_to_remove[0];
    if (dayToRemove !== undefined) {
      const isThereADayToAddCartItemWithSameDayAsDayToRemove = cleaningScheduleCartItems.find(cartItem =>
        cartItem.days_to_add.includes(dayToRemove)
      );
      if (dayToRemove !== dayToAdd) {
        if (isThereADayToAddCartItemWithSameDayAsDayToRemove) {
          const dayToAddCartItemCleaningType =
            isThereADayToAddCartItemWithSameDayAsDayToRemove.product.id === weeklyCleaningProductId
              ? CLEANING_TASK_TYPE
              : TOUCH_UP_TASK_TYPE;
          newSchedule[dayToRemove] = {
            value: dayToAddCartItemCleaningType,
            price:
              dayToAddCartItemCleaningType === cleaningType
                ? monthly_once_per_week_cleaning_price
                : monthly_once_per_week_touch_up_price,
            day: DAY_NUMBER_TO_LONG_DAY_MAP[dayToRemove] as WeeklyCleaningDay,
            wday
          };
        } else {
          newSchedule[dayToRemove] = {
            value: null,
            price: 0,
            day: DAY_NUMBER_TO_LONG_DAY_MAP[dayToRemove] as WeeklyCleaningDay,
            wday
          };
        }
      } else {
        // this else case is for when we are changing a touch up to a cleaning on the same day
        newSchedule[dayToAdd] = {
          value: CLEANING_TASK_TYPE,
          price: monthly_once_per_week_cleaning_price,
          day: DAY_NUMBER_TO_LONG_DAY_MAP[dayToAdd] as WeeklyCleaningDay,
          wday
        };
      }
    }
  });

  return newSchedule;
};

export const dayCleaningValueToSelectCleaningMap = {
  null: {
    label: 'No cleaning',
    icon: 'noSymbol' as IconName
  },
  cleaning: {
    label: 'Cleaning',
    icon: 'cleaningDeep' as IconName
  },
  'touch-up': {
    label: 'Touch-up',
    icon: 'featherDuster' as IconName
  }
};
