import React, { useRef, useState } from 'react';
import poweredByGoogle from '@codiwork/codi/lib/assets/powered-by-google-on-white-hdpi.png';
import classNames from 'classnames';

import { useListFocus } from 'hooks';
import useFieldSize from 'hooks/useFieldSize';
import { getLocations, getPlaceDetails } from 'lib/location';

import Input from '../Input';
import { BaseProps as InputBaseProps } from '../types';
import { useOnClickOutside } from '../../helpers';

type PlaceType = 'address' | '(regions)' | 'neighborhood' | '(cities)';

export interface Props extends Pick<InputBaseProps, 'value' | 'size' | 'disabled' | 'error' | 'onClear'> {
  onLocationChange?: OnLocationChange;
  onChange: (params: { value: string }) => void;
  origin?: google.maps.LatLngLiteral;
  placeholder?: string;
  types?: PlaceType[];
  label: string;
  hideIcon?: boolean;
  hideLabel?: boolean;
  required?: boolean;
}

export type OnLocationChange = (params: OnLocationChangeParams) => void;
export interface OnLocationChangeParams {
  location: string;
  place: google.maps.places.PlaceResult | null;
}

interface Result {
  display: string;
  place_id: string;
}

const DIV_ID = 'LocationInput';

const LocationInput: React.FC<Props> = ({
  value,
  onChange,
  onLocationChange,
  disabled,
  origin,
  error,
  onClear,
  label,
  placeholder,
  types,
  hideIcon,
  hideLabel,
  required = false,
  ...props
}) => {
  const [results, setResults] = useState<Result[]>([]);
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  useOnClickOutside(ref, () => {
    setResultsVisible(false);
  });

  const onLocationSelect = ({ place_id }: Result) => {
    getPlaceDetails({
      place_id,
      callback: place => {
        onLocationChange && onLocationChange({ place: place, location: place?.formatted_address! });
        setResultsVisible(false);
        setResults([]);
      }
    });
  };

  const [focusedIndex] = useListFocus({
    divId: DIV_ID,
    size: results.length,
    handleEnter: index => {
      const result = results[index];

      if (!result) return;

      onLocationSelect(result);
    }
  });

  return (
    <div
      id={DIV_ID}
      className={classNames('LocationInput', `is-size-${size}`)}
      ref={ref}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          setResultsVisible(false);
        }
      }}
    >
      <Input
        name="location"
        placeholder={placeholder}
        label={label}
        icon={hideIcon ? undefined : 'search'}
        value={value}
        size={size}
        onChange={params => {
          getLocations({
            input: params.value,
            origin,
            types,
            callback: results => {
              setResults(results.map(r => ({ display: r.description, place_id: r.place_id })));

              if (results.length > 0 && !resultsVisible) {
                setResultsVisible(true);
              }
            }
          });
          onChange(params);
        }}
        onFocus={() => setResultsVisible(true)}
        disabled={disabled}
        error={error}
        hideLabel={hideLabel}
        onClear={
          onClear
            ? () => {
                setResults([]);
                onClear();
              }
            : undefined
        }
        required={required}
      />
      {resultsVisible && (
        <LocationResults
          key={results.length}
          size={size}
          results={results}
          onLocationSelect={onLocationSelect}
          focusedIndex={focusedIndex}
        />
      )}
    </div>
  );
};

interface ResultProps extends Pick<Props, 'onLocationChange' | 'size'> {
  results: Result[];
  onLocationSelect: (result: Result) => void;
  focusedIndex: number;
}

const LocationResults: React.FC<ResultProps> = ({ results, size, onLocationSelect, focusedIndex }) => {
  return (
    <div className="LocationInput-results">
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {results.map(({ display, place_id }, index) => (
          <ResultItem
            key={display}
            display={display}
            focused={index === focusedIndex}
            place_id={place_id}
            onLocationSelect={onLocationSelect}
            size={size}
          />
        ))}
      </ul>
      {results.length > 0 && (
        <div className="LocationInput-poweredByGoogleContainer">
          <div className="LocationInput-poweredByGoogleImage">
            <img width={120} src={poweredByGoogle} alt="Powered by Google" />
          </div>
        </div>
      )}
    </div>
  );
};

interface ResultItemProps extends Result, Pick<Props, 'onLocationChange' | 'size'> {
  focused: boolean;
  onLocationSelect: (result: Result) => void;
}

const ResultItem: React.FC<ResultItemProps> = ({ display, focused, place_id, onLocationSelect }) => {
  return (
    <li
      className={classNames('LocationInput-resultItem', { 'is-focused': focused })}
      key={display}
      onClick={() => {
        onLocationSelect({ display, place_id });
      }}
    >
      <span className="LocationInput-resultText">{display}</span>
    </li>
  );
};

export default LocationInput;
