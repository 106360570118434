import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const KnowYourOptions: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout justify="center" flexGrow={1} overflow="hidden">
        <Layout>
          <Text size="h3" color="blue-gradient" align="center" scale={xs}>
            Know Your Options
          </Text>
          <Layout marginTop={84} flex direction={xs ? 'column' : 'row'}>
            <Layout
              paddingTop={64}
              paddingX={48}
              paddingBottom={48}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              direction="column"
              align="center"
              equalWidth
            >
              <Layout
                borderColor="blue-gradient"
                width={92}
                height={92}
                align="center"
                justify="center"
                borderRadius="circular"
              >
                <Icon size={44} color="blue-gradient" name="building" />
              </Layout>
              <Layout marginTop={36}>
                <Text size="h5" color="blue-gradient" align="center">
                  Traditional Office
                </Text>
              </Layout>
            </Layout>
            <Layout
              paddingTop={64}
              paddingX={48}
              paddingBottom={48}
              borderRadius={12}
              marginLeft={xs ? undefined : 32}
              marginTop={xs ? 24 : undefined}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              direction="column"
              align="center"
              equalWidth
            >
              <Layout
                borderColor="blue-gradient"
                width={92}
                height={92}
                align="center"
                justify="center"
                borderRadius="circular"
              >
                <Icon size={44} color="blue-gradient" name="users" />
              </Layout>
              <Layout marginTop={36}>
                <Text size="h5" color="blue-gradient" align="center">
                  Coworking Office
                </Text>
              </Layout>
            </Layout>
            <Layout
              paddingTop={64}
              paddingX={48}
              paddingBottom={48}
              borderRadius={12}
              marginLeft={xs ? undefined : 32}
              marginTop={xs ? 24 : undefined}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              direction="column"
              align="center"
              equalWidth
            >
              <Layout
                borderColor="blue-gradient"
                width={92}
                height={92}
                align="center"
                justify="center"
                borderRadius="circular"
              >
                <Icon size={44} color="blue-gradient" name="calendar" />
              </Layout>
              <Layout marginTop={36}>
                <Text size="h5" color="blue-gradient" align="center">
                  Flex Office
                </Text>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default KnowYourOptions;
