import React, { CSSProperties, useRef } from 'react';

import { generateImgSrc } from 'helpers/images';

import { OnMeasure } from 'ds/types';

export interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  alt: string;
  srcKey: string;
  folder?: string;
  width: number;
  height?: number;
  style?: CSSProperties;
  quality?: number;
  padResize?: boolean;
  onMeasure?: OnMeasure;
}

const Img: React.FC<Props> = ({
  srcKey,
  folder,
  width,
  height,
  alt,
  style,
  quality,
  padResize = false,
  onMeasure,
  onLoad,
  ...props
}) => {
  const ref = useRef<HTMLImageElement>(null);
  const imgUrl = generateImgSrc({ srcKey, folder, width, height, quality, padResize });

  return (
    <img
      {...props}
      src={imgUrl}
      alt={alt}
      style={style}
      ref={onMeasure ? ref : null}
      onLoad={e => {
        onLoad && onLoad(e);

        if (!onMeasure) return;

        const element = ref.current;

        if (!element) return;

        onMeasure(element.getBoundingClientRect());
      }}
    />
  );
};

export default Img;
