import React, { PropsWithChildren, useContext } from 'react';
import { Color } from '@codiwork/codi';

import { media } from 'context';
import { Animate, Text } from 'ds';

interface Props {
  color?: Color;
}

const StepTitle: React.FC<PropsWithChildren<Props>> = ({ children, color = 'white' }) => {
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;

  return isMobile ? (
    <Animate>
      <Text size="h4" color={color}>
        {children}
      </Text>
    </Animate>
  ) : (
    <Text size="h3" color={color}>
      {children}
    </Text>
  );
};

export default StepTitle;
