import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

const GUTTER = 48;

interface Props {}

const DesigningYourSpace: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowHeight } = useContext(app);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={xs ? undefined : -(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={48}
        >
          <Text size="h3" color="blue-600">
            Designing Your Space
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                The biggest challenge with a space in a coworking office is that by very definition, the environment is
                meant to be homogeneous; so that no one company’s presence overshadows the others. Culture is difficult
                to cultivate in a coworking environment, but there are a few things you can do, given that you have
                management’s permission:
              </Text>
              <br />
              <ul>
                <li>
                  <Text size="h6" bold={false} color="gray-700">
                    <Text size="h6" color="gray-700" inline>
                      Bring your own furniture
                    </Text>
                    {'  '}
                    Your coworking space will supply desks and chairs, but if you want to use something different, you
                    should be able to bring in whatever you want, provided the furniture can be transported in easily
                  </Text>
                </li>
                <li>
                  <Text size="h6" bold={false} color="gray-700">
                    <Text size="h6" color="gray-700" inline>
                      Snacks
                    </Text>
                    {'  '}
                    Many coworking spaces offer coffee, flavored water, and beer (or kombucha) on tap. Anything else
                    you’ll have to order for your staff. Bring in your own mini-fridge for anything you want to keep
                    cold.
                  </Text>
                </li>
                <li>
                  <Text size="h6" bold={false} color="gray-700">
                    <Text size="h6" color="gray-700" inline>
                      Branding
                    </Text>
                    {'  '}
                    If your space is cordoned off by walls or glass panels, it’s likely you’ll have the option of
                    putting your logo up. Don’t expect to be able to paint interior walls with your corporate colors or
                    branding, but you may be able to hang signage.
                  </Text>
                </li>
              </ul>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="contract-signing_tjJYspqff.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default DesigningYourSpace;
