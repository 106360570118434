import { CartItem, UnsavedCartItem, castToDateTime, formatDate } from '@codiwork/codi';
import { DateTime, Duration } from 'luxon';

// TODO: replace with formatDate({date, format: 'FULL_WITH_WEEKDAY_YEAR'})
// outputs format Wednesday, February 2, 2022
export function dateToWords(date: string | DateTime) {
  const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : date;

  return dateTime.toFormat('DDDD');
}

// TODO: replace with formatDate({date, format: 'FULL'})
// outputs format December 12
export function monthDayDisplay(date: string | DateTime) {
  const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : date;

  return dateTime.toFormat('MMMM dd');
}

export function getMondayOfWeek(date: DateTime): DateTime {
  return date.startOf('week');
}

export function getFridayOfWeek(date: DateTime): DateTime {
  return date.endOf('week').minus(Duration.fromObject({ days: 2 }));
}

export function isSameDate(date1: DateTime | Date | string, date2: DateTime | Date | string) {
  return castToDateTime(date1).startOf('day').equals(castToDateTime(date2).startOf('day'));
}

export function formatDateWithTime(date: string) {
  const dateTime = castToDateTime(date);

  return `${formatDate({ date: dateTime, format: 'MED' })} at ${dateTime.toFormat('h:mm a')}`;
}

export const nBusinessDaysFromStartDate = ({ startDate, nDays }: { startDate: DateTime; nDays: number }) => {
  if (nDays < 1) return startDate;
  let count = 0;
  let businessDays = [];
  let x = 0;
  while (count <= nDays) {
    const day = startDate.plus({ days: x });
    if (day.weekday !== 6 && day.weekday !== 7) {
      businessDays.push(day);
      count++;
    }
    x++;
  }
  return businessDays[businessDays.length - 1];
};

export const getNextDayOfWeekFromStartDate = ({
  startDate,
  dayOfWeek,
  includeToday
}: {
  startDate: DateTime;
  dayOfWeek: number;
  includeToday: boolean;
}) => {
  let nextDay = startDate;
  const today = DateTime.now();
  // make nextDay start tomorrow if includeToday is false and today is the same day as the nextDay
  if (!includeToday && nextDay.weekday === today.weekday) {
    nextDay = nextDay.plus({ days: 1 });
  }

  while (nextDay.weekday !== dayOfWeek) {
    nextDay = nextDay.plus({ days: 1 });
  }
  return nextDay;
};

export const effectiveDateForCleaningOrTouchup = (item: CartItem | UnsavedCartItem, startDate: DateTime): string => {
  const dayToRemove = item.days_to_remove[0];
  const dayToAdd = item.days_to_add[0];
  const today = DateTime.now();
  const twoBusinessDaysFromToday = nBusinessDaysFromStartDate({ startDate: today, nDays: 2 });

  const nextDayOfWeekToRemoveFromStartDateIncludingToday = getNextDayOfWeekFromStartDate({
    startDate: startDate,
    dayOfWeek: dayToRemove + 1,
    includeToday: true
  });

  const nextDayOfWeekToAddFromStartDateNotIncludingToday = getNextDayOfWeekFromStartDate({
    startDate: startDate,
    dayOfWeek: dayToAdd + 1,
    includeToday: false
  });

  const nextDayOfWeekToRemoveFromStartDateNotIncludingToday = getNextDayOfWeekFromStartDate({
    startDate: startDate,
    dayOfWeek: dayToRemove + 1,
    includeToday: false
  });

  // if startDate is outside of two business days then which ever day is first (add or remove day) will be the effective date.
  if (startDate > twoBusinessDaysFromToday) {
    if (nextDayOfWeekToAddFromStartDateNotIncludingToday < nextDayOfWeekToRemoveFromStartDateNotIncludingToday) {
      return formatDate({ date: nextDayOfWeekToAddFromStartDateNotIncludingToday, format: 'MED' });
    } else {
      return formatDate({ date: nextDayOfWeekToRemoveFromStartDateNotIncludingToday, format: 'MED' });
    }
  }

  const nextDayToAddIsWithinTwoBusinessDays =
    nextDayOfWeekToAddFromStartDateNotIncludingToday < twoBusinessDaysFromToday;
  const nextDayToRemoveIsWithinTwoBusinessDays =
    nextDayOfWeekToRemoveFromStartDateIncludingToday < twoBusinessDaysFromToday;
  const addDayComesBeforeRemoveDay =
    nextDayOfWeekToAddFromStartDateNotIncludingToday < nextDayOfWeekToRemoveFromStartDateNotIncludingToday;

  if (nextDayToAddIsWithinTwoBusinessDays && nextDayToRemoveIsWithinTwoBusinessDays) {
    if (addDayComesBeforeRemoveDay) {
      return formatDate({ date: nextDayOfWeekToAddFromStartDateNotIncludingToday, format: 'MED' });
    } else {
      return formatDate({ date: nextDayOfWeekToRemoveFromStartDateNotIncludingToday, format: 'MED' });
    }
  } else if (!nextDayToAddIsWithinTwoBusinessDays && nextDayToRemoveIsWithinTwoBusinessDays) {
    return formatDate({ date: nextDayOfWeekToAddFromStartDateNotIncludingToday, format: 'MED' });
  } else if (nextDayToAddIsWithinTwoBusinessDays && !nextDayToRemoveIsWithinTwoBusinessDays) {
    return formatDate({ date: nextDayOfWeekToRemoveFromStartDateNotIncludingToday, format: 'MED' });
  } else {
    if (addDayComesBeforeRemoveDay) {
      return formatDate({ date: nextDayOfWeekToAddFromStartDateNotIncludingToday, format: 'MED' });
    } else {
      return formatDate({ date: nextDayOfWeekToRemoveFromStartDateNotIncludingToday, format: 'MED' });
    }
  }
};

export const startDateForCleaningOrTouchUpAddition = (item: CartItem, startDate: DateTime) => {
  const dayToAdd = item.days_to_add[0];
  const today = DateTime.now();
  const twoBusinessDaysFromToday = nBusinessDaysFromStartDate({ startDate: today, nDays: 2 });
  // get the day for days_to_add but for next week
  let nextDayOfWeekToAdd;

  if (startDate <= twoBusinessDaysFromToday) {
    nextDayOfWeekToAdd = getNextDayOfWeekFromStartDate({
      startDate: startDate,
      dayOfWeek: dayToAdd + 1,
      includeToday: false
    });
  } else {
    nextDayOfWeekToAdd = nextDayOfWeekToAdd = getNextDayOfWeekFromStartDate({
      startDate: startDate,
      dayOfWeek: dayToAdd + 1,
      includeToday: true
    });
  }

  if (nextDayOfWeekToAdd.startOf('day') < twoBusinessDaysFromToday.startOf('day')) {
    return formatDate({ date: nextDayOfWeekToAdd.plus({ weeks: 1 }), format: 'MED' });
  } else {
    return formatDate({ date: nextDayOfWeekToAdd, format: 'MED' });
  }
};
