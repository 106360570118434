import React, { useContext, useEffect, useState } from 'react';

import { media } from 'context';
import { Layout, Link, Text } from 'ds';
import butter, { handleError } from 'lib/butter';
import { BLOG_PATH } from 'routes';

interface Props {}

const Insights: React.FC<Props> = () => {
  const { isMobile } = useContext(media);

  const [blogResponse, setBlogResponse] = useState<any>();

  useEffect(() => {
    butter.post
      .list({
        exclude_body: true,
        page_size: 100
      })
      .then(function (resp) {
        setBlogResponse(resp.data);
      })
      .catch(function (error) {
        handleError({ slug: 'posts', error });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!blogResponse) return null;

  const last3Posts = blogResponse.data.slice(0, 3);
  const post1 = last3Posts[0];
  const post2 = last3Posts[1];
  const post3 = last3Posts[2];

  return (
    <Layout>
      <Text
        size={isMobile ? 'headline-lg' : 'display-sm'}
        align={isMobile ? undefined : 'center'}
        color={isMobile ? 'blue-500' : 'blue-600'}
      >
        Read our latest insights
      </Text>
      <Layout marginTop={32} {...(isMobile ? {} : { flex: true })}>
        <Layout {...(isMobile ? { flexGrow: 1 } : { flexGrow: 1, maxWidth: 708 })}>
          <Link
            href={`${BLOG_PATH}/${post1.slug}`}
            {...(isMobile ? { fullWidth: true } : { fullWidth: true, fullHeight: true })}
          >
            <Layout __className="Home-insightLink" {...(isMobile ? {} : { height: '100%' })}>
              <img
                src={post1.featured_image}
                alt="office chair"
                style={{
                  borderRadius: 16,
                  width: isMobile ? undefined : undefined,
                  height: isMobile ? 342 : '100%',
                  objectFit: 'cover'
                }}
              />
              <Layout
                position="absolute"
                left={isMobile ? 44 : 72}
                paddingRight={isMobile ? 44 : 72}
                top={0}
                direction="column"
                justify="center"
                height="100%"
              >
                <Text size="headline-xs" color="white">
                  {post1.categories[0]?.name}
                </Text>
                <Layout marginTop={16}>
                  <Text size={isMobile ? 'headline-md' : 'display-sm'} color="white">
                    {post1.title}
                  </Text>
                </Layout>
                <Layout marginTop={16}>
                  <Text size="body-md" color="white">
                    {post1.author.first_name} {post1.author.last_name}
                  </Text>
                </Layout>
              </Layout>
            </Layout>
          </Link>
        </Layout>
        <Layout {...(isMobile ? { marginTop: 20 } : { marginLeft: 20, direction: 'column' })}>
          <Layout equalWidth>
            <Link href={`${BLOG_PATH}/${post2.slug}`} display={isMobile ? 'inline' : undefined} fullHeight>
              <Layout __className="Home-insightLink">
                <img
                  src={post2.featured_image}
                  alt="office chair"
                  style={{
                    borderRadius: 16,
                    objectFit: 'cover',
                    width: isMobile ? undefined : 372,
                    height: isMobile ? 342 : '100%'
                  }}
                />
                <Layout
                  position="absolute"
                  left={44}
                  paddingRight={44}
                  top={0}
                  direction="column"
                  justify="center"
                  height="100%"
                >
                  <Text size="headline-xs" color="white">
                    {post2.categories[0]?.name}
                  </Text>
                  <Layout marginTop={16}>
                    <Text size="headline-md" color="white">
                      {post2.title}
                    </Text>
                  </Layout>
                  <Layout marginTop={16}>
                    <Text size="body-md" color="white">
                      {post2.author.first_name} {post2.author.last_name}
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
            </Link>
          </Layout>
          <Layout {...(isMobile ? { marginTop: 20 } : { marginTop: 20, equalWidth: true })}>
            <Link href={`${BLOG_PATH}/${post3.slug}`} display={isMobile ? 'inline' : undefined} fullHeight>
              <Layout __className="Home-insightLink">
                <img
                  src={post3.featured_image}
                  alt="office chair"
                  style={{
                    borderRadius: 16,
                    objectFit: 'cover',
                    width: isMobile ? undefined : 372,
                    height: isMobile ? 342 : '100%'
                  }}
                />
                <Layout
                  position="absolute"
                  left={44}
                  paddingRight={44}
                  top={0}
                  direction="column"
                  justify="center"
                  height="100%"
                >
                  <Text size="headline-xs" color="white">
                    {post3.categories[0]?.name}
                  </Text>
                  <Layout marginTop={16}>
                    <Text size="headline-md" color="white">
                      {post3.title}
                    </Text>
                  </Layout>
                  <Layout marginTop={16}>
                    <Text size="body-md" color="white">
                      {post3.author.first_name} {post3.author.last_name}
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
            </Link>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Insights;
