import { useState } from 'react';

let ordinal = 0;

const uniqueId = (prefix: string = 'ds'): string => `id-${prefix}-${ordinal++}`;

const useUniqueId = (prefix?: string) => {
  return useState<string>(uniqueId(prefix))[0];
};

export default useUniqueId;
