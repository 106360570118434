import React, { useContext } from 'react';
import { BreadcrumbList, CollectionPage, WithContext } from 'schema-dts';

import ContactForm from 'components/ContactForm';
import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { media } from 'context';
import { Button, ContentContainer, Icon, Layout, Link, Text } from 'ds';
import { CMSRouteProps } from 'routes/types';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

interface Props extends CMSRouteProps {}

interface Article {
  fields: {
    logo: string;
    blurb: string;
    link: string;
  };
}

const Press: React.FC<Props> = ({ cmsSlug }) => {
  const { xs } = useContext(media);
  const [isContactFormVisible, setIsContactFormVisible] = React.useState<boolean>(false);

  const page = useAppSelector(state => selectCmsPage(state, cmsSlug));

  if (!page) return <Layout minHeight="100vh" />;

  const {
    fields: {
      seo: { title, description, keywords },
      featured,
      articles
    }
  } = page;

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${window.location.origin}/#website`,
          name: 'Codi',
          url: window.location.origin
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${window.location.origin}/#press`,
          name: title,
          url: window.location.href
        }
      }
    ]
  };

  const schema: WithContext<CollectionPage> = {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
    breadcrumb: breadcrumbs,
    name: title,
    url: window.location.href,
    description: description,
    image: featured.background,
    mainEntity: {
      '@type': 'ItemList',
      itemListElement: [featured, ...articles].map((article: any, index: number) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'Article',
          headline: article.fields?.headline || article.headline,
          description: article.fields?.blurb || article.blurb,
          url: article.fields?.link || article.link,
          image: article.fields?.logo || article.logo,
          thumbnail: article.fields?.logo || article.logo
        }
      }))
    }
  };

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <ContactForm isVisible={isContactFormVisible} onCloseModal={() => setIsContactFormVisible(false)} inbox="hello" />
      <HeroImage img={featured.background}>
        <ContentContainer>
          <Layout marginTop={xs ? 56 : 72} direction="column" align="center">
            <Text size={xs ? 'h3' : 'h1'} color="white" align="center" scale>
              {featured.headline}
            </Text>
            <Layout marginTop={64}>
              <img src={featured.logo} height={48} style={{ width: 'unset' }} alt="logo" />
            </Layout>
            <Layout marginTop={32} maxWidth={600}>
              <Text size="body1" color="white" align="center">
                {featured.blurb}
              </Text>
            </Layout>
            <Layout marginTop={72} marginBottom={xs ? 40 : 72} width="100%" justify="center">
              <Button
                type="secondaryOutline"
                size={xs ? 'sm' : 'lg'}
                text="Go to Article"
                href={featured.link}
                target="_blank"
                fullWidth={xs}
              />
            </Layout>
          </Layout>
        </ContentContainer>
      </HeroImage>
      <Layout justify="center" width="100%">
        <Layout maxWidth={960} justify={xs ? 'center' : 'space-between'} direction={xs ? 'column' : 'row'} wrap>
          {articles.map((article: Article, index: number) => {
            const {
              fields: { logo, blurb, link }
            } = article;
            return (
              <Layout key={index} marginTop={80} width={328} padding={8} direction="column">
                <Layout height={160} maxHeight={160} justify="center" align="center" padding={12}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ height: 'unset', width: 'unset', maxHeight: '100%', maxWidth: '100%' }}
                  />
                </Layout>
                <Layout borderColor="gray-50" borderWidth={1} width="100%" />
                <Layout marginY={24} width="100%">
                  <Text size="body1" align="center">
                    {blurb}
                  </Text>
                </Layout>
                <Layout marginTop={24} width="100%" justify="center">
                  <Link href={link} target="_blank">
                    <Layout direction="row" width="100%" justify="center" align="center">
                      <Icon name="rightArrow" size="sm" color="blue-500" />
                      &nbsp;&nbsp;
                      <Text color="blue-500" size="large-link" inline>
                        Go to Article
                      </Text>
                    </Layout>
                  </Link>
                </Layout>
              </Layout>
            );
          })}
        </Layout>
      </Layout>
      <Layout marginTop={72} align="center" width="100%" direction="column">
        <Text size="h5">Press Inquiries</Text>
        <Layout marginTop={16} marginBottom={24}>
          <Text size="body1" color="gray-600" align="center">
            We’re always happy to discuss your press related questions.
          </Text>
        </Layout>
        <Layout direction={xs ? 'column' : 'row'}>
          <Button
            size="sm"
            type="primary"
            text="Contact Us"
            onClick={() => setIsContactFormVisible(true)}
            fullWidth={xs}
          />
          <Layout marginTop={xs ? 16 : undefined} marginLeft={xs ? undefined : 24} />
          <Button
            type="primary"
            size="sm"
            text="Download Press Kit"
            href="https://drive.google.com/drive/folders/1Qa8kjIBmDXJTPPDNq3XEZibhcdLcOT63?usp=sharing"
            target="_blank"
            fullWidth={xs}
          />
        </Layout>
      </Layout>
    </>
  );
};

export default Press;
