import React from 'react';

import Head from 'components/Head';

import EventDescription from './EventDescription';
import Hero from './Hero';
import NYCTechWeekWorkspaces from './NYCTechWeekWorkspaces';

interface Props {}

const NYCTechWeek: React.FC<Props> = () => {
  return (
    <>
      <Head
        title="#NYCTechWeek Live Event: Scaling Your Marketplace"
        description="Join us live on June 6th: Christelle Rohaut @ Codi, Arne Halleraker @ FJ Labs, Brian McMahon @ Pickle, and Derek Chu @ Firstmark discuss how to scale your marketplace."
      />
      <Hero />
      <EventDescription />
      <NYCTechWeekWorkspaces />
    </>
  );
};

export default NYCTechWeek;
