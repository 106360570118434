import React from 'react';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import Icon from './Icon';
import Layout from './Layout';
import Text from './Text';

export interface MenuItem {
  label: string;
  onClick: VoidFunction;
}

export interface Props {
  menuItems: MenuItem[];
}

const KebabMenu: React.FC<Props> = ({ menuItems }) => {
  return (
    <Layout __className={classNames('KebabMenu')} direction="column" position="relative" borderRadius="circular">
      <Layout width={32} height={32} justify="center" align="center" overflow="hidden">
        <Icon size="sm" name="ellipsisV" color="black" />
      </Layout>
      <Layout __className="KebabMenu-menu" position="absolute" top={-8} right={0}>
        <Layout
          marginTop={4}
          paddingY={12}
          color="white"
          borderRadius={12}
          boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
        >
          {menuItems.map((menuItem, index) => (
            <button
              key={index}
              onClick={() => {
                track('Element Interacted', { type: 'button', value: menuItem.label, name: 'kebab' });
                menuItem.onClick();
              }}
              style={{ display: 'block' }}
            >
              <Layout __className="KebabMenu-menuItem" paddingY={12} paddingX={16} width="100%">
                <Text size="body2" scale>
                  {menuItem.label}
                </Text>
              </Layout>
            </button>
          ))}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default KebabMenu;
