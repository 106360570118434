import React, { useContext, useEffect, useRef, useState } from 'react';

import { LOCATION_TYPES, filteredResults } from 'helpers/map';

import { app, media } from 'context';
import { Animate, Icon, Layout, Pressable } from 'ds';
import { useOnClickOutside } from 'ds/helpers';
import { getLocations, getPlaceDetails } from 'lib/location';
import { selectMarkets } from 'store/App/selectors';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { selectOfficeRequestLocations, selectOfficeRequestMarket } from 'store/OfficeRequest/selectors';
import { actions as uiActions } from 'store/UI';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ChangeMarket from './ChangeMarket';
import LocationPill from './LocationPill';
import LocationResults from './LocationResults';
import OfficeRequestBottomSheet from './OfficeRequestBottomSheet';
import OfficeRequestInput from './OfficeRequestInput';
import { BOTTOM_SHEET_PADDING_BOTTOM } from './constants';
import { BaseLocationResult, LocationResult } from './types';
import { centerOfMarket, marketNameFromLatLng } from './utils';

interface Props {}

const DIV_ID = 'OfficeRequest-neighborhoodInput';

const LocationInput: React.FC<Props> = () => {
  const { xs, sm } = useContext(media);
  const isBottomSheet = xs || sm;
  const { contentPaddingX, isSmallPhone } = useContext(app);
  const { ipLocation } = useContext(app);
  const market = useAppSelector(selectOfficeRequestMarket);
  const markets = useAppSelector(selectMarkets);
  const marketWithBounds = markets.find(m => m.name === market);
  const locations = useAppSelector(selectOfficeRequestLocations);
  const origin = !!marketWithBounds
    ? centerOfMarket(marketWithBounds)
    : locations.length
    ? locations[0].loc
    : ipLocation || undefined;
  const [searchValue, setSearchValue] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [results, setResults] = useState<LocationResult[]>([]);

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isSheetExpanded, setIsSheetExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userJustSelected = useRef<boolean>(false);

  useEffect(() => {
    if (locations.length === 0) {
      dispatch(officeRequestActions.setMarket(null));
    } else if (locations.length === 1) {
      const location = locations[0];
      if (!location.loc) return;

      const market = marketNameFromLatLng(location.loc, markets);
      dispatch(officeRequestActions.setMarket(market));
    }
  }, [locations.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideResults = () => {
    setIsInputFocused(false);
  };

  const handleClickOutside = () => {
    if (isBottomSheet) return;

    hideResults();
  };

  const showFooter = () => {
    dispatch(uiActions.setOfficeRequestWizardHideFooter(false));
  };

  const hideFooter = () => {
    dispatch(uiActions.setOfficeRequestWizardHideFooter(true));
  };

  const collapseSheet = () => {
    hideResults();
    setIsSheetExpanded(false);
    showFooter();
    window.scrollTo({ top: 0 });
  };

  async function onLocationSelect(result: BaseLocationResult | null) {
    if (!result) {
      return;
    }

    userJustSelected.current = true;
    inputRef.current?.focus();

    const locationResult = results.find(r => r.place_id === result.value);

    if (!locationResult) {
      return;
    }

    const { neighborhood, city, region, country, place_id } = locationResult;
    getPlaceDetails({
      place_id,
      callback: place => {
        if (!place || !place.geometry || !place.geometry.location) return;

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const loc = { lat, lng };
        if (!locations.find(l => l.place_id === place_id)) {
          dispatch(officeRequestActions.addLocation({ neighborhood, city, region, country, place_id, loc }));
        }
      }
    });

    setSearchValue('');
    setIsInputFocused(true);

    if (isBottomSheet) {
      setTimeout(() => {
        userJustSelected.current = false;
      }, 250);
    }
  }

  const handleChange = (value: string) => {
    setSearchValue(value);

    getLocations({
      input: value,
      origin,
      types: [...LOCATION_TYPES],
      country: ['us'],
      locationRestriction: !!marketWithBounds ? marketWithBounds.bounds : undefined,
      callback: results => {
        const filtered = filteredResults(results).filter(r => !locations.find(l => l.place_id === r.place_id));
        setResults(filtered.slice(0, 5));
      }
    });
  };

  useOnClickOutside(ref, handleClickOutside);

  const locationsLength = locations.length || 0;

  const calculateContainerHeight = () => {
    if (!isSheetExpanded && !locationsLength) {
      return undefined;
    } else if (isSheetExpanded) {
      return isSmallPhone ? (!locationsLength ? 180 : 180) : 280;
    } else if (locationsLength > 0) {
      return isSmallPhone ? 120 : 220;
    } else {
      return 80;
    }
  };

  const resultToBaseLocationResult = (result: LocationResult): BaseLocationResult => {
    const { place_id, neighborhood, city, region } = result;

    return {
      label: !!neighborhood ? `${neighborhood}, ${city}, ${region}` : `${city}, ${region}`,
      value: place_id
    };
  };

  return isBottomSheet ? (
    <OfficeRequestBottomSheet>
      {isSheetExpanded && (
        <Layout justify="flex-end" paddingRight={contentPaddingX}>
          <Pressable onPress={() => {}}>
            <Icon size="md" color="gray-900" name="close" />
          </Pressable>
        </Layout>
      )}
      <OfficeRequestInput
        onChange={handleChange}
        value={searchValue}
        ref={inputRef}
        onFocus={() => {
          if (userJustSelected.current) return;

          setIsSheetExpanded(true);
          hideFooter();
        }}
        onBlur={() => {
          setTimeout(() => {
            if (userJustSelected.current) return;

            collapseSheet();
          }, 50);
        }}
        placeholder="Add neighborhoods"
      />
      <Layout height={calculateContainerHeight()} overflow="auto" transition="height 240ms">
        {isSheetExpanded && searchValue.length > 0 ? (
          <LocationResults
            divId={DIV_ID}
            results={results.slice(0, isSmallPhone ? 2 : 3).map(res => resultToBaseLocationResult(res))}
            onLocationSelect={onLocationSelect}
            searchValue={searchValue}
            showGoogleAttribution={false}
            isBottomSheet
          />
        ) : (
          <Layout
            paddingTop={36}
            paddingBottom={12}
            paddingX={24}
            wrap
            flex
            __ref={resultsContainerRef}
            maxHeight={isSheetExpanded ? undefined : isSmallPhone ? 120 : 220}
            overflow="auto"
          >
            {locations.map(location => {
              const { neighborhood, city, place_id } = location;
              return (
                <Layout marginTop={12} key={place_id}>
                  <LocationPill
                    key={place_id}
                    primaryLabel={neighborhood || city}
                    secondaryLabel={!!neighborhood ? city : undefined}
                    onRemove={() => {
                      if (isSheetExpanded) {
                        userJustSelected.current = true;
                        inputRef.current?.focus();

                        setTimeout(() => {
                          userJustSelected.current = false;
                        }, 250);
                      }

                      dispatch(officeRequestActions.removeLocation(location));
                    }}
                  />
                </Layout>
              );
            })}
          </Layout>
        )}
      </Layout>
      <Layout paddingX={contentPaddingX} marginTop={BOTTOM_SHEET_PADDING_BOTTOM}>
        <ChangeMarket />
      </Layout>
    </OfficeRequestBottomSheet>
  ) : (
    // top is used to make input have same y position as city input in previous step.
    <Layout width="100%" maxWidth={524} height={400} position="relative" top={174} __ref={ref} __id={DIV_ID}>
      <OfficeRequestInput
        onChange={handleChange}
        value={searchValue}
        onFocus={() => setIsInputFocused(true)}
        placeholder="Enter a city or neighborhood"
        ref={inputRef}
      />
      {isInputFocused && searchValue.length > 0 && (
        <LocationResults
          divId={DIV_ID}
          results={results.slice(0, 5).map(res => resultToBaseLocationResult(res))}
          onLocationSelect={onLocationSelect}
          searchValue={searchValue}
          showGoogleAttribution={false}
        />
      )}
      <Animate
        delay={30}
        marginTop={24}
        marginBottom={24}
        minHeight={116}
        maxHeight={234}
        overflow="auto"
        wrap
        flex
        __style={{ gap: 16 }}
      >
        {locations.map(location => {
          const { neighborhood, city, place_id } = location;
          return (
            <Layout marginTop={12} key={place_id}>
              <LocationPill
                key={place_id}
                primaryLabel={neighborhood || city}
                secondaryLabel={!!neighborhood ? city : undefined}
                onRemove={() => {
                  dispatch(officeRequestActions.removeLocation(location));
                }}
              />
            </Layout>
          );
        })}
      </Animate>
      <Layout marginTop={48}>
        <ChangeMarket />
      </Layout>
    </Layout>
  );
};

export default LocationInput;
