import React from 'react';

import { IconButton, Layout, Link, Text } from 'ds';
import { PLAYBOOK_PATH } from 'routes';

interface Props {
  closeMenu: VoidFunction;
  step: number;
}

const Menu: React.FC<Props> = ({ closeMenu, step }) => {
  return (
    <Layout padding={24} color="white" height="100vh">
      <Layout justify="flex-end">
        <IconButton size="lg" type="noBackground" onClick={closeMenu} name="close" />
      </Layout>
      <Layout paddingY={12} paddingX={14} marginTop={24}>
        <Layout>
          <Link href={`${PLAYBOOK_PATH}/intro`}>
            <Text size="body2" color={step === 0 ? 'blue-500' : 'blue-700'} semibold>
              INTRODUCTION
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Link href={`${PLAYBOOK_PATH}/review-your-options`}>
            <Text size="body2" color={step === 1 ? 'blue-500' : 'blue-700'} semibold>
              01 REVIEW YOUR OPTIONS
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Link href={`${PLAYBOOK_PATH}/the-search`}>
            <Text size="body2" color={step === 2 ? 'blue-500' : 'blue-700'} semibold>
              02 THE SEARCH
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Link href={`${PLAYBOOK_PATH}/negotiations-agreements`}>
            <Text size="body2" color={step === 3 ? 'blue-500' : 'blue-700'} semibold>
              03 NEGOTIATIONS & AGREEMENTS
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Link href={`${PLAYBOOK_PATH}/moving-in`}>
            <Text size="body2" color={step === 4 ? 'blue-500' : 'blue-700'} semibold>
              04 MOVING IN & MANAGING YOUR OFFICE
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Link href={`${PLAYBOOK_PATH}/handy-tools`}>
            <Text size="body2" color={step === 5 ? 'blue-500' : 'blue-700'} semibold>
              CONCLUSION
            </Text>
          </Link>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Menu;
