import React, { FocusEventHandler, useState } from 'react';
import { Color, IconName, colorToRgba, validatePresence } from '@codiwork/codi';
import classNames from 'classnames';

import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import Icon from './Icon';
import { DS_CLASS_NAME } from './constants';
import { ValidateProp } from './inputs/types';
import { FieldSize } from './types';

export interface Props<T extends string | number = string> extends ValidateProp {
  // value that is selected
  value?: T;
  label?: string;
  required?: boolean;
  options: Option<T>[];
  onChange: OnChange<T>;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  size?: FieldSize;
  icon?: IconName;
  iconColor?: Color;
  textColor?: 'gray-900' | 'gray-300';
  fullWidth?: boolean;
  disabled?: boolean;
  semibold?: boolean;
  autofocus?: boolean;
  name?: string;
  backgroundColor?: Color;
  backgroundOpacity?: number;
  hideChevron?: boolean;
  hideSmallLabel?: boolean;
}

export type OnChange<T extends string | number> = (params: OnChangeParams<T>) => void;

interface OnChangeParams<T extends string | number> {
  event: React.ChangeEvent<HTMLSelectElement>;
  value: T;
}

export interface Option<T extends string | number> {
  value: T;
  label: string;
}

const Select = <T extends string | number>({
  required,
  options,
  onChange,
  onValidate,
  value,
  label,
  icon,
  iconColor,
  fullWidth,
  semibold = false,
  disabled = false,
  autofocus = false,
  textColor = 'gray-900',
  name = '',
  backgroundColor,
  backgroundOpacity = 1,
  hideChevron,
  hideSmallLabel = false,
  ...props
}: Props<T>) => {
  const [isFocused, setIsFocused] = useState<boolean>(autofocus);
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  const validate = (value: string) => {
    const validationResult = validatePresence({ fieldName: label || '', value, required: !!required });
    onValidate && onValidate(validationResult);

    return validationResult.error;
  };

  const firstOption = options[0];
  const isNumberValue = typeof firstOption.value === 'number';

  const onChangeWrapper = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    onChange({ event: e, value: (isNumberValue ? Number(value) : value) as T });
    validate(value);

    const option = options.find(o => o.value === value);
    track('Element Interacted', { type: 'select', value: option?.label, name, context: 'selection' });
  };

  const hasValue = value !== undefined && value !== '';

  return (
    <div
      style={{
        backgroundColor: !!backgroundColor
          ? disabled
            ? 'gray-25'
            : colorToRgba(backgroundColor, backgroundOpacity)
          : undefined
      }}
      className={classNames(DS_CLASS_NAME, 'Select', `is-size-${size}`, `is-color-${textColor}`, {
        'is-full-width': fullWidth,
        'has-icon': !!icon,
        'has-label': !!label && !hideSmallLabel,
        'has-value': hasValue,
        'is-semibold': semibold,
        'is-disabled': disabled,
        'is-focused': isFocused
      })}
    >
      {icon && iconColor && (
        <span className="Select-iconContainer">
          <Icon name={icon} color={iconColor} size={size} />
        </span>
      )}
      {!hideChevron && (
        <span className="Select-chevronContainer">
          <Icon name="downChevron" color={isFocused ? 'blue-500' : 'gray-900'} size={size} />
        </span>
      )}
      {label && !hideSmallLabel && hasValue && (
        <label className={classNames('Select-label', { 'has-value': hasValue })}>
          {label} {required && ' *'}
        </label>
      )}
      <select
        className={classNames('Select-select', {
          'has-label': !!label && !hideSmallLabel,
          'has-value': hasValue,
          'is-chevron-hidden': hideChevron
        })}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          onChangeWrapper(e);
        }}
        value={hasValue ? value : label || undefined}
        disabled={disabled}
        onFocus={e => {
          setIsFocused(true);
          const option = options.find(o => o.value === value);

          props.onFocus && props.onFocus(e);
          track('Element Interacted', { type: 'select', value: option?.label, name, context: 'open' });
        }}
        onBlur={e => {
          setIsFocused(false);

          props.onBlur && props.onBlur(e);
        }}
        autoFocus={autofocus}
      >
        {label && (
          <option disabled value={label}>
            {label} {required && ' *'}
          </option>
        )}
        {options.map(({ value, label }) => (
          <option key={value} className="Select-option" value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
