import React, { useState } from 'react';
import { ValidationResult, validateEmail } from '@codiwork/codi';

import { COMMON_NON_WORK_EMAIL_DOMAINS } from 'ds/constants';

import Input from './Input';
import { BaseProps, InputProps, OnChangeParams, ValidateProp } from './types';
import useValidate from './useValidate';

export interface Props extends BaseProps, ValidateProp, Pick<InputProps, 'placeholder' | 'hideLabel'> {
  label?: string;
  invalidateNonWorkEmail?: boolean;
  excludedDomains?: string[];
}

const EmailInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  size,
  onValidate,
  required = true,
  disabled,
  invalidateNonWorkEmail,
  excludedDomains = [],
  placeholder = '',
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const validate = (value?: string) => {
    const validationResult = validateEmail(value, required, { excludedDomains });

    if (invalidateNonWorkEmail) {
      if (validationResult.valid && value) {
        return handleInvalidateNonWorkEmail(value);
      } else {
        return validationResult;
      }
    }

    return validationResult;
  };

  useValidate({ value: value || undefined, onValidate, validate, setErrorMessage });

  const onChangeWrapper = (e: OnChangeParams) => {
    onChange({ event: e.event, value: e.value });

    const validationResult = validate(e.value);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onValidate && onValidate(validationResult);
  };

  return (
    <Input
      name="email"
      value={value}
      onBlur={e => {
        setErrorMessage(validate(value || '').error);

        onBlur && onBlur(e);
      }}
      type="text"
      label={label || 'Email address'}
      placeholder={placeholder}
      onChange={onChangeWrapper}
      size={size}
      error={error || errorMessage}
      required={required}
      disabled={disabled}
      {...props}
    />
  );
};

export const handleInvalidateNonWorkEmail = (email?: string): ValidationResult => {
  if (!email) return { valid: true };

  if (!email.includes('@')) return { valid: true };

  const domain = email.split('@').slice(-1)[0];

  if (domain && COMMON_NON_WORK_EMAIL_DOMAINS.includes(domain.toLowerCase())) {
    return { valid: false, error: 'Please enter a valid work email.' };
  } else {
    return { valid: true };
  }
};

export default EmailInput;
