import React, { useContext } from 'react';

import { app, media } from 'context';
import { Button, Layout } from 'ds';

export interface Props {
  viewState: 'verify' | 'signup';
  setViewState: (viewState: 'signin' | 'verify') => void;
}

const GoBack: React.FC<Props> = ({ setViewState }) => {
  const { contentPaddingX } = useContext(app);
  const { xs } = useContext(media);

  return (
    <Layout left={contentPaddingX} top={xs ? 16 : 44} position="absolute">
      <Button
        size={xs ? 'xs' : 'sm'}
        type="secondaryOutline"
        onClick={() => {
          setViewState('signin');
        }}
        text="Back"
      />
    </Layout>
  );
};

export default GoBack;
