import React from 'react';
import pluralize from 'pluralize';

import { Layout, Text } from 'ds';

import Breadcrumbs from './Breadcrumbs';
import { SearchLocation } from './types';

interface Props {
  numResults: number;
  searchLocation: SearchLocation;
}

const HeaderTags: React.FC<Props> = ({ numResults, searchLocation }) => {
  return (
    <Layout direction="column" paddingY={12} wrap>
      <Breadcrumbs searchLocation={searchLocation} />
      <Layout paddingTop={4}>
        {!!numResults ? (
          <Text tag="h1" size="h6">
            {numResults} office {pluralize('listing', numResults)} in {searchLocation.name}
          </Text>
        ) : (
          <Text tag="h1" size="h6">
            Office listings in {searchLocation.name}
          </Text>
        )}
      </Layout>
    </Layout>
  );
};

export default HeaderTags;
