import React, { useContext } from 'react';

import { app } from 'context';
import { Layout, Link, Logo, Text } from 'ds';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

import ProgressFooter from './ProgressFooter';
import StepMobileHeader from './StepMobileHeader';
import { MOBILE_PROGRESS_FOOTER_HEIGHT, PUBLIC_OFFICE_REQUEST_HEADER_HEIGHT_MOBILE } from './constants';
import { StepProps } from './types';

interface Props extends StepProps {}

const StepMobile: React.FC<Props> = ({
  children,
  step,
  nextHref,
  ctaDisabled,
  skippable,
  backHref,
  textLayoutProps = { paddingBottom: 200, align: 'center', flexGrow: 1 },
  onSubmit,
  requestState,
  mobileLayout = 'default',
  stepCompletedEventValue,
  stepName
}) => {
  const { contentPaddingX } = useContext(app);
  const dispatch = useAppDispatch();
  const progressFooter = (
    <ProgressFooter
      onSubmit={onSubmit}
      step={step}
      nextHref={nextHref}
      ctaDisabled={ctaDisabled}
      skippable={skippable}
      backHref={backHref}
      requestState={requestState}
      stepCompletedEventValue={stepCompletedEventValue}
      stepName={stepName}
    />
  );

  const content = children[1];

  if (mobileLayout === 'white') {
    return (
      <>
        <Layout paddingBottom={MOBILE_PROGRESS_FOOTER_HEIGHT + 24}>
          <Layout paddingX={contentPaddingX}>
            <Layout justify="space-between" height={PUBLIC_OFFICE_REQUEST_HEADER_HEIGHT_MOBILE} align="center">
              <Logo color="blue-500" size="sm" />
              <Link onClick={() => dispatch(actions.setExitModalIsVisible(true))}>
                <Text size="body1" color="gray-900">
                  Exit
                </Text>
              </Link>
            </Layout>
            <Layout {...textLayoutProps}>{children[0]}</Layout>
            {content}
          </Layout>
        </Layout>
        {progressFooter}
      </>
    );
  } else if (mobileLayout === 'long') {
    return (
      <>
        <Layout background="linear-gradient(94.99deg, #1AB2D9 28.48%, #0078FF 141.74%)" direction="column">
          <Layout paddingX={contentPaddingX}>
            <StepMobileHeader />
          </Layout>
          <Layout paddingX={contentPaddingX} {...textLayoutProps}>
            {children[0]}
          </Layout>
          {content}
        </Layout>
        {progressFooter}
      </>
    );
  }

  return (
    <>
      <Layout
        background="linear-gradient(94.99deg, #1AB2D9 28.48%, #0078FF 141.74%)"
        height="calc(100vh - 120px)"
        paddingX={contentPaddingX}
        direction="column"
      >
        <StepMobileHeader />
        <Layout {...textLayoutProps}>{children[0]}</Layout>
      </Layout>
      {content}
      {progressFooter}
    </>
  );
};

export default StepMobile;
