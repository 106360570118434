import React from 'react';
import classNames from 'classnames';

import { IS_TOUCH_DEVICE } from 'ds/constants';

import { Direction } from './types';
import Icon from '../Icon';
import Layout from '../Layout';

interface Props {
  activeSortDirection?: Direction;
  sortable: boolean;
}

const SortControls: React.FC<Props> = ({ activeSortDirection, sortable }) => {
  const isAsc = activeSortDirection === 'asc';
  const isDesc = activeSortDirection === 'desc';

  return (
    <>
      {isAsc && <Icon name="sortAsc" color="blue-500" size="md" />}
      {isDesc && <Icon name="sortDesc" color="blue-500" size="md" />}
      {(!activeSortDirection || !sortable) && (
        <Layout
          __className={classNames('Table-defaultSortIcon', {
            'is-sortable': sortable,
            'is-sort_active': !!activeSortDirection,
            'is-sort-not-active': !activeSortDirection,
            'is-touch': IS_TOUCH_DEVICE
          })}
          display="inline-flex"
        >
          <Icon name="sortControl" color="gray-200" size="md" />
        </Layout>
      )}
    </>
  );
};

export default SortControls;
