import React, { useContext } from 'react';

import { app } from 'context';
import { Layout } from 'ds';

import { SearchLocation } from './types';

interface Props {
  searchLocation: SearchLocation;
}

const SeoContent: React.FC<Props> = ({ searchLocation }) => {
  const { contentPaddingX } = useContext(app);
  const { seo_content } = searchLocation;

  if (!seo_content) return null;

  return (
    <Layout flexGrow={1} paddingX={contentPaddingX} paddingY={24}>
      <div className="SeoContent" dangerouslySetInnerHTML={{ __html: seo_content }} />
    </Layout>
  );
};

export default SeoContent;
