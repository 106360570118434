import React, { useContext, useEffect, useState } from 'react';
import { Listing } from '@codiwork/codi';

import { app, media } from 'context';
import { Animate, Button, Layout, Text, WorkspacePromoCarousel } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import { CTA } from './CmsPage/types';
import { findWorkspaces } from './requests';

interface Props {
  title: string;
  ids?: number[];
  cta: CTA;
  text: React.ReactNode;
}

const Workspaces: React.FC<Props> = ({ title, ids, cta, text }) => {
  const { xs, sm } = useContext(media);
  const { contentWidth } = useContext(app);
  const [workspaces, setWorkspaces] = useState<Listing[]>([]);

  useEffect(() => {
    if (!ids) return;

    findWorkspaces({ workspaceIds: ids }).then(({ data }) => {
      setWorkspaces(data);
    });
  }, [ids?.join(',')]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!workspaces.length) return null;

  return xs || sm ? (
    <Layout paddingY={72}>
      <Animate>
        {text || (
          <Text size="h4" color="blue-700">
            {title}
          </Text>
        )}
      </Animate>
      <Animate marginTop={36}>
        <WorkspacePromoCarousel
          showSeePricing={false}
          workspaces={workspaces.map(w => ({
            ...w,
            image: w.images[0]
          }))}
          key={contentWidth}
        />
      </Animate>
      <Animate marginTop={24}>
        <Button text={cta.text} size="md" type={cta.type} href={OFFICE_REQUEST_PATH} />
      </Animate>
    </Layout>
  ) : (
    <Layout paddingY={160}>
      <Animate>
        <Text size="h4" color="blue-700" align="center">
          {title}
        </Text>
      </Animate>
      <Animate flexGrow={1}>
        <Layout marginTop={40}>
          <WorkspacePromoCarousel
            showSeePricing={false}
            workspaces={workspaces
              .filter(w => !!w.images.length)
              .map(w => ({
                ...w,
                image: w.images[0]
              }))}
          />
        </Layout>
      </Animate>
      <Animate>
        <Layout marginTop={32} justify="center">
          <Button text={cta.text} size={cta.size} type={cta.type} href={OFFICE_REQUEST_PATH} />
        </Layout>
      </Animate>
    </Layout>
  );
};

export default Workspaces;
