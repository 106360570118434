import React, { PropsWithChildren } from 'react';

import { track } from 'lib/analytics';

import { DEFAULT_PAGINATION_CONTROL_PADDING } from './constants';
import Icon from '../Icon';
import Layout from '../Layout';

interface Props {
  onClick: VoidFunction;
  direction: 'left' | 'right';
  disabled?: boolean;
  dimension: number;
  padding?: number;
}

const PaginationControl: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  direction,
  disabled,
  dimension,
  padding = DEFAULT_PAGINATION_CONTROL_PADDING,
  children
}) => {
  return (
    <button
      onClick={e => {
        track('Element Interacted', {
          type: 'iconButton',
          name: `carousel control ${direction === 'left' ? 'previous' : 'next'}`
        });
        e.preventDefault();
        onClick && onClick();
      }}
      style={{
        display: 'inline-flex',
        zIndex: 5,
        cursor: disabled ? 'not-allowed' : undefined,
        position: 'relative',
        padding
      }}
      className="PaginationControl-button"
      disabled={disabled}
    >
      {children || (
        <Layout
          borderRadius="circular"
          boxShadow="2px 0px 22px 0px #57496D1F"
          align="center"
          justify="center"
          __className="PaginationControl-icon"
          width={dimension}
          height={dimension}
        >
          <Icon name={direction === 'left' ? 'leftChevron' : 'rightChevron'} size="xs" color="blue-500" />
        </Layout>
      )}
    </button>
  );
};

export default PaginationControl;
