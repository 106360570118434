import React from 'react';
import { COLORS, Color } from '@codiwork/codi';
import { ReactComponent as DefaultAvatar } from '@codiwork/codi/lib/assets/default-avatar.svg';
import classNames from 'classnames';

import Layout from './Layout';

export interface Props {
  size?: number;
  avatarUrl?: string | null;
  borderColor?: string;
  borderWidth?: 0 | 1 | 2 | 4;
  hoverBorderWidth?: 1 | 2 | 4;
  outlineWidth?: 1 | 2;
  outlineColor?: string;
  defaultAvatar?: JSX.Element;
  backgroundColor?: Color;
  alt?: string;
  hexColor?: string;
  opacity?: number;
  hasHoverStyle?: boolean;
  firstname?: string;
}

const Avatar: React.FC<Props> = ({
  size = 160,
  avatarUrl,
  borderColor = 'white',
  outlineWidth = 0,
  outlineColor = 'transparent',
  borderWidth = 1,
  hoverBorderWidth,
  defaultAvatar,
  backgroundColor,
  alt = 'user avatar',
  hexColor = COLORS.white,
  opacity,
  hasHoverStyle
}) => {
  return (
    <Layout
      __style={{
        background: avatarUrl ? borderColor : undefined,
        border: `${outlineWidth}px solid ${outlineColor}`
      }}
      color={backgroundColor}
      justify="center"
      align="center"
      width={size}
      height={size}
      overflow="hidden"
      borderRadius="circular"
      position="relative"
      __className="Avatar"
      opacity={opacity}
    >
      {!avatarUrl ? (
        defaultAvatar || <DefaultAvatar style={{ width: '50%', height: '50%' }} fill={hexColor} />
      ) : (
        <img
          style={{
            objectFit: 'cover',
            borderRadius: '50%'
          }}
          className={classNames(
            'Avatar-image',
            `is-avatar-border-width-${borderWidth}`,
            hoverBorderWidth ? `is-avatar-hover-border-width-${hoverBorderWidth}` : undefined
          )}
          src={avatarUrl}
          alt={alt}
        />
      )}
      {hasHoverStyle && (
        <Layout
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          borderRadius="circular"
          hoverColor="gray-950"
          opacity={0.2}
        />
      )}
    </Layout>
  );
};

export default Avatar;
