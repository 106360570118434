import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, EmailInput, Layout, PhoneInput, Select, TextInput } from 'ds';
import { actions } from 'store/OfficeRequest';
import {
  selectOfficeRequest,
  selectOfficeRequestReferralInfo,
  selectOfficeRequestUser
} from 'store/OfficeRequest/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { HOW_HEARD_OPTIONS } from '../utils';

interface Props {
  handleFocus?: VoidFunction;
  onBlur?: VoidFunction;
  onSelectFocus?: VoidFunction;
}

const UserFields: React.FC<Props> = ({ handleFocus, onBlur, onSelectFocus }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { firstName, lastName, email, phone, callingCode } = useAppSelector(selectOfficeRequestUser) || {};
  const { howHeard, searchTerms } = useAppSelector(selectOfficeRequest) || {};
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;
  const referralInfo = useAppSelector(selectOfficeRequestReferralInfo);

  return (
    <Layout>
      <Layout flex={!isMobile}>
        <Animate delay={20} {...(isMobile ? { marginTop: 16 } : { equalWidth: true })}>
          <TextInput
            autoFocus={!isMobile && !user}
            size="md"
            onChange={({ value }) => {
              dispatch(actions.setUser({ firstName: value }));
            }}
            label="First name"
            value={firstName}
            onFocus={handleFocus}
            onBlur={onBlur}
            name="first name"
            required
          />
        </Animate>
        <Animate delay={30} {...(isMobile ? { marginTop: 16 } : { equalWidth: true, marginLeft: 12 })}>
          <TextInput
            size="md"
            onChange={({ value }) => {
              dispatch(actions.setUser({ lastName: value }));
            }}
            label="Last name"
            value={lastName}
            onFocus={handleFocus}
            onBlur={onBlur}
            name="last name"
            required
          />
        </Animate>
      </Layout>
      <Animate delay={45} marginTop={16}>
        <EmailInput
          size="md"
          onChange={({ value }) => {
            dispatch(actions.setUser({ email: value }));
          }}
          label="Work email"
          value={email}
          onFocus={handleFocus}
          onBlur={onBlur}
          required
        />
      </Animate>
      <Animate delay={55} marginTop={16}>
        <PhoneInput
          size="md"
          onChange={({ value }) => {
            dispatch(actions.setUser({ phone: value }));
          }}
          label="Phone"
          value={phone}
          callingCode={callingCode}
          onCallingCodeChange={value => dispatch(actions.setUser({ callingCode: value }))}
          onFocus={handleFocus}
          onBlur={onBlur}
          autoFocus={!isMobile && !!user && !user.phone}
          required
        />
      </Animate>
      {!referralInfo && (
        <Animate delay={55} marginTop={16}>
          <Select
            options={HOW_HEARD_OPTIONS}
            size="md"
            onChange={({ value }) => {
              dispatch(actions.setHowHeard(value));
            }}
            onFocus={onSelectFocus}
            onBlur={onBlur}
            label="How did you hear about us?"
            value={howHeard || ''}
            fullWidth
            required
          />
        </Animate>
      )}
      {howHeard === HOW_HEARD_OPTIONS[0].value && (
        <Animate delay={55} marginTop={16}>
          <TextInput
            size="md"
            onChange={({ value }) => {
              dispatch(actions.setSearchTerms(value));
            }}
            label="Which search term(s) did you use?"
            value={searchTerms}
            onFocus={handleFocus}
            onBlur={onBlur}
          />
        </Animate>
      )}
    </Layout>
  );
};

export default UserFields;
