import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const NowWhat: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowHeight } = useContext(app);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex direction={xs ? 'column' : 'row'}>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={xs ? undefined : -(PLAYBOOK_FOOTER_HEIGHT / 2)}
        >
          <Text size="h3" color="blue-600">
            You’re in — now what?
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                Hopefully, you’d already put a lot of thought into how your office should work and flow before you
                started looking at spaces.
                <br />
                <br />
                If your space came move-in ready and fully furnished, you might not have to do much at all. But the
                likelihood is low that your new office will be picture-perfect without having to lift a finger.
                <br />
                <br />
                Revisit your CapEx budget and plan your construction. If you’ve negotiated well, your landlord might
                contribute some portion, and/or offer the space at free or reduced rent until you’re finished (or close
                to finished) with the work. Make sure you have your landlord’s permission to do anything that needs to
                be done.
              </Text>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="bright-office_5H5g9FjCq.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default NowWhat;
