import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, FullWidthBackground, Icon, Layout, Text } from 'ds';
import { ButterImage } from 'lib/butter/types';

interface Props {
  title: string;
  subtitle: string;
  premium_image: ButterImage;
  standard_image: ButterImage;
  premium_amenities: { name: string }[];
  standard_amenities: { name: string }[];
}

const Amenities: React.FC<Props> = ({
  title,
  subtitle,
  premium_image,
  standard_image,
  premium_amenities,
  standard_amenities
}) => {
  const { xs } = useContext(media);

  const standard = {
    image: standard_image,
    names: standard_amenities.map(a => a.name),
    type: 'Standard'
  };

  const premium = {
    image: premium_image,
    names: premium_amenities.map(a => a.name),
    type: 'Premium'
  };

  const amenities = [standard, premium];

  return (
    <FullWidthBackground paddingY={100} color="blue-50">
      <Layout justify="center">
        <Layout maxWidth={960}>
          <Animate>
            <Text size="h4" color="blue-700" align="center">
              {title}
            </Text>
          </Animate>
          <Layout justify="center" marginTop={36}>
            <Layout maxWidth={560}>
              <Animate>
                <Text size="h6" color="gray-900" align="center" bold={false} scale>
                  {subtitle}
                </Text>
              </Animate>
            </Layout>
          </Layout>
          <Layout {...(xs ? { direction: 'column' } : { direction: 'row' })}>
            {amenities.map(({ image, type, names }, index) => {
              return (
                <Animate
                  key={type}
                  equalWidth
                  marginTop={36}
                  {...(xs ? {} : { ...(index === 1 ? { marginLeft: 44 } : {}), delay: index * 100 })}
                >
                  <Layout direction="column" height="100%">
                    <img
                      src={image.src}
                      alt={image.alt}
                      style={{ borderTopLeftRadius: 24, borderTopRightRadius: 24 }}
                    />
                    <Layout
                      color="white"
                      padding={36}
                      borderBottomLeftRadius={24}
                      borderBottomRightRadius={24}
                      flexGrow={1}
                    >
                      <Text size="h5" color="blue-700">
                        {type}
                      </Text>
                      <Layout direction="column">
                        {names.map(name => (
                          <Layout key={name} display="inline-flex" align="center" marginTop={24}>
                            <Layout display="inline-flex" marginRight={10}>
                              <Icon size="lg" name="checkmark" stroke={2} color="gray-900" />
                            </Layout>
                            <Text size="body1" semibold>
                              {name}
                            </Text>
                          </Layout>
                        ))}
                      </Layout>
                    </Layout>
                  </Layout>
                </Animate>
              );
            })}
          </Layout>
        </Layout>
      </Layout>
    </FullWidthBackground>
  );
};

export default Amenities;
