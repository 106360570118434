import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { media } from 'context';
import { Animate, Button, Layout, Text } from 'ds';
import { SEARCH_PATH } from 'routes';
import { AppLocation } from 'store/App/types';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';
import { generateSearch } from 'ux/Search/utils';

import { generateStepPath } from '../OfficeRequest/utils';

interface Props {
  title: string;
  body: string;
  form_cta: string;
  secondary_form_cta: string;
  search_cta?: string;
  video?: {
    url: string;
    thumbnail: string;
    show_border?: boolean;
  }[];
  images?: {
    image: string;
    alt_text: string;
  }[];
  location: AppLocation;
}

const Hero: React.FC<Props> = ({
  title,
  body,
  form_cta,
  secondary_form_cta,
  location: { name, region, lat, lng, show_secondary_cta }
}) => {
  const mediaContext = useContext(media);
  const { xs, lg, xl } = mediaContext;
  const history = useHistory();
  const { pathname } = useLocation();
  const locationReady = typeof lat === 'number' && typeof lng === 'number';
  const dispatch = useAppDispatch();

  return (
    <>
      <Layout align={xl ? 'center' : undefined}>
        <Animate equalWidth={xl}>
          <Text size={xs ? 'h4' : 'h3'} tag="h1" color="blue-700">
            {title}
            {(xl || xs) && <br />}
            <Text size={xs ? 'h4' : 'h3'} color="blue-500" inline>
              {xl ? '' : ' '}
              {name}
            </Text>
          </Text>
        </Animate>
        <Layout marginTop={xl ? undefined : 24} equalWidth={xl}>
          <Animate>
            <Text size={xs ? 'h6' : 'h5'} bold={false} scale>
              {body}
            </Text>
          </Animate>
          {locationReady && (
            <Animate marginTop={xs ? 24 : 40} flex wrap={!lg}>
              <Layout
                inline
                width={xl ? '50%' : xs ? '100%' : 276}
                display="inline-flex"
                paddingRight={xs ? undefined : 12}
              >
                <Button
                  type="primary"
                  text={form_cta}
                  context="hero"
                  onClick={() => {
                    dispatch(officeRequestActions.setLaunchPath(pathname));
                    dispatch(officeRequestActions.setEntry('location-page-primary-cta'));
                    // TODO
                    // dispatch(officeRequestActions.setLocation({ city: name, region, country }));

                    history.push(generateStepPath({ step: 1 }));
                  }}
                  size="lg"
                  fullWidth
                />
              </Layout>
              {!!show_secondary_cta && (
                <Layout
                  inline
                  width={xl ? '50%' : xs ? '100%' : 276}
                  display="inline-flex"
                  paddingLeft={xs ? undefined : 12}
                  marginTop={xs ? 24 : undefined}
                >
                  <Button
                    type="secondaryOutline"
                    text={secondary_form_cta}
                    context="hero"
                    onClick={() => {
                      if (typeof lat !== 'number' || typeof lng !== 'number') return;

                      history.push({
                        pathname: SEARCH_PATH,
                        search: generateSearch({
                          search: '',
                          center: { lat, lng },
                          location: `${name}, ${region}`
                        })
                      });
                    }}
                    size="lg"
                    fullWidth
                  />
                </Layout>
              )}
            </Animate>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default Hero;
