import React, { useContext } from 'react';

import { media } from 'context';
import Button from 'ds/Button';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Link from 'ds/Link';
import Modal from 'ds/Modal';
import Text from 'ds/Text';
import { STANDARD_FEATURES } from 'ds/constants';
import { CATALOG_PATH } from 'routes';

interface Props {
  isVisible: boolean;
  onClose: VoidFunction;
  isFullScreen?: boolean;
  zIndexPosition?: number;
  bottomSheetZIndexPosition?: number;
}

const PriceExplanationModal: React.FC<Props> = ({
  isVisible,
  onClose,
  isFullScreen,
  zIndexPosition = 1,
  bottomSheetZIndexPosition
}) => {
  const { xs } = useContext(media);

  return (
    <Modal
      isVisible={isVisible}
      headerAlign="left"
      header="Included in the price"
      onClose={onClose}
      paddingX={36}
      zIndexPosition={zIndexPosition}
      hasBottomSheetPaddingX
      bottomSheetIsRounded
      paddingTop={0}
      bottomSheetZIndexPosition={bottomSheetZIndexPosition}
      footer={xs ? <Button text="Dismiss" size="sm" type="secondary" onClick={onClose} fullWidth /> : null}
      isFullScreen={isFullScreen}
    >
      {STANDARD_FEATURES.map((feature, index) => {
        return (
          <Layout marginTop={index ? 16 : undefined} key={feature.label} flex>
            <Icon name={feature.icon} size={xs ? 'sm' : 'md'} />
            <Layout marginLeft={8}>
              <Text size={xs ? 'body2' : 'body1'} semibold>
                {feature.label}
              </Text>
              <Text size={xs ? 'body3' : 'body2'} color="gray-700">
                {feature.description}
              </Text>
            </Layout>
          </Layout>
        );
      })}
      <Layout marginTop={24} color="blue-25" paddingY={12} paddingLeft={12} paddingRight={12} borderRadius={4} flex>
        <Icon name="sparkle" color="blue-300" size="sm" />
        <Layout marginLeft={12}>
          <Text size="body2" color="gray-700">
            Your Codi office is fully customizable. Upgrades and add-ons available upon request.{' '}
            <Link href={CATALOG_PATH} target="_blank" size="body2" color="blue-500" semibold={false}>
              View all add-ons
            </Link>
          </Text>
        </Layout>
      </Layout>
      {!xs && (
        <Layout marginTop={48}>
          <Button text="Dismiss" size="sm" type="secondaryOutline" onClick={onClose} />
        </Layout>
      )}
    </Modal>
  );
};

export default PriceExplanationModal;
