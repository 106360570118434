import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const LeaseTermsToAvoid: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Text size="h3" color="blue-600" scale={xs}>
          Super Sneaky Lease Terms to Avoid
        </Text>
        <Layout marginTop={48} flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <Layout color="red-200" paddingX={12} display="inline-flex">
              <Text size="h4" color="red-700">
                “specified broker for subleasing”
              </Text>
            </Layout>
            <Layout marginTop={48}>
              <Text size="h6" color="gray-700" bold={false}>
                If you discover that you’ve rented more space than you need, or if you you need to cut rental costs in
                the future, subletting some (or all) of your space might be your best option.
                <br />
                <br />
                However, some landlords may push for a lease requirement that stipulates you’d have to use their broker
                to find a subletter. In this case, they have the power to stall the process and keep you paying your
                full rent while they focus on filling other vacancies first. If you see this language in your draft
                agreement, push back on it.
              </Text>
            </Layout>
          </Layout>
          <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 48 : undefined} equalWidth>
            <Layout color="red-200" paddingX={12} display="inline-flex">
              <Text size="h4" color="red-700">
                “specified vendors for repairs”
              </Text>
            </Layout>
            <Layout marginTop={48}>
              <Text size="h6" color="gray-700" bold={false}>
                Some landlords may stipulate that you use only authorized vendors for repairs on your space. Sometimes,
                they do this to ensure that only reputable companies are working on their buildings, which is
                understandable. However, in some less-ethical cases, landlords may want to send business to associates
                of theirs who can charge you high prices with impunity. In the worst cases, the landlord may be getting
                a fee back from these vendors.
                <br />
                <br />
                In your lease negotiations, be sure to give yourself the flexibility to shop for your own services. The
                way to do this is to agree to consider their people first, but specify that their preferred vendor must
                offer a competitive bid. You should reserve the right to go with another vendor if the “preferred”
                vendor’s bid comes in 10% (or more) higher than others you’ve found.
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default LeaseTermsToAvoid;
