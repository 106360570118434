import React, { useContext } from 'react';

import HeroVideo from 'components/HeroVideo';

import { app, media } from 'context';
import { Animate, Button, Layout, Text } from 'ds';
import { SEARCH_PATH } from 'routes';

interface Props {
  tagline: string;
  title: string;
  subtitle: string;
  cta_text: string;
}

const Hero: React.FC<Props> = ({ tagline, title, subtitle, cta_text }) => {
  const { xs, media500, sm, lg } = useContext(media);
  const { contentPaddingX, navBarHeight } = useContext(app);

  return xs ? (
    <>
      <HeroVideo folder="offsites" srcKey="massive-soma-compressed-2_KW0TsWOyq.mov" width={1680} height={256}>
        <Layout height="100%" />
      </HeroVideo>
      <Layout position="relative" width="100vw" right={contentPaddingX} color="blue-50">
        <Animate
          height="100%"
          direction="column"
          align="center"
          paddingTop={media500 ? 36 : 24}
          paddingBottom={48}
          paddingX={contentPaddingX}
        >
          <Text color="blue-600" size="body2" caps semibold>
            {tagline}
          </Text>
          <Layout marginTop={12} />
          <Text size="h3" scale color="blue-600">
            {title}
          </Text>
          <Layout marginTop={12} />
          <Text size="body1" scale color="gray-500" align="center">
            {subtitle}
          </Text>
          <Layout marginTop={40} />
          <Button
            size="lg"
            type="primary"
            text={cta_text}
            href={SEARCH_PATH}
            search="?offsites_only=1"
            fullWidth={media500}
            background="linear-gradient(to right, #1AB2D9, #0078FF)"
          />
        </Animate>
      </Layout>
    </>
  ) : (
    <HeroVideo
      folder="offsites"
      srcKey="massive-soma-compressed-2_KW0TsWOyq.mov"
      width={1680}
      height={`calc(100vh - ${navBarHeight}px)`}
      videoHeight={660}
    >
      <Layout
        background="linear-gradient(to right, rgba(0, 0, 0, 0.654), rgba(0, 0, 0, 0)"
        paddingLeft={contentPaddingX}
        height="100%"
        width="100%"
        zIndex={1}
        position="relative"
      >
        <Animate height="100%">
          <Layout height="100%" direction="column" justify="center" maxWidth={540}>
            <Text color="white" size={sm ? 'body2' : 'body1'} caps semibold>
              {tagline}
            </Text>
            <Layout marginTop={12} />
            <Text size={lg ? 'h2' : 'h3'} scale color="white">
              {title}
            </Text>
            <Layout marginTop={12} />
            <Layout maxWidth={lg ? undefined : 380}>
              <Text size="h5" scale color="white" notBold>
                {subtitle}
              </Text>
            </Layout>
            <Layout marginTop={40} />
            <Layout direction="row" align="center">
              <Button
                size="lg"
                type="primary"
                text={cta_text}
                href={SEARCH_PATH}
                search="?offsites_only=1"
                background="linear-gradient(to right, #1AB2D9, #0078FF)"
              />
            </Layout>
          </Layout>
        </Animate>
      </Layout>
    </HeroVideo>
  );
};

export default Hero;
