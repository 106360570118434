import React, { useContext } from 'react';
import { IconName, ListingWorkspace } from '@codiwork/codi';
import pluralize from 'pluralize';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

interface Props {
  workspace: ListingWorkspace;
}

const Highlights: React.FC<Props> = ({ workspace, workspace: { square_feet, has_storage, spaces } }) => {
  const { xs, sm } = useContext(media);
  const { width, contentPaddingX } = useContext(app);
  const isMobile = xs || sm;

  const horizontalSpace = isMobile ? 44 : 88;
  const highlightHeight = isMobile ? 68 : 72;

  const getMeetingRoomsHighlight = (): HighlightedFeature | null => {
    const meetingRooms = spaces.filter(space => space.is_private && space.capacity >= 2);
    if (meetingRooms.length) {
      return {
        header: 'MEETING',
        text: meetingRooms.length.toString(),
        footer: pluralize('room', meetingRooms.length)
      };
    }

    return null;
  };

  const highlights: HighlightedFeature[] = [];
  highlights.push({
    header: 'EST. CAPACITY',
    text: workspace.capacity.toString(),
    footer: 'desks'
  });

  if (!!square_feet) {
    highlights.push({
      header: 'SIZE',
      text: square_feet.toLocaleString(),
      footer: 'sq. feet'
    });
  }

  const meetingRoomsHighlight = getMeetingRoomsHighlight();
  if (!!meetingRoomsHighlight) {
    highlights.push(meetingRoomsHighlight);
  }

  if (has_storage) {
    highlights.push({
      header: 'STORAGE',
      icon: 'meetingRoom',
      footer: 'provided'
    });
  }

  if (!highlights.length) {
    return null;
  }

  const xsStyles = {
    width: width + contentPaddingX,
    marginLeft: -contentPaddingX * 2,
    paddingLeft: contentPaddingX
  };

  return (
    <Layout __style={isMobile ? xsStyles : undefined} justify="center">
      <Layout direction="row" height={highlightHeight} overflowX={isMobile ? 'auto' : undefined}>
        {highlights.map((highlight, index) => (
          <Layout
            key={index}
            __style={{ marginLeft: index === 0 ? (isMobile ? contentPaddingX : undefined) : horizontalSpace }}
          >
            <Highlight {...highlight} />
          </Layout>
        ))}
        <Layout paddingRight={isMobile ? contentPaddingX : undefined} />
      </Layout>
    </Layout>
  );
};

interface HighlightedFeature {
  header: string;
  text?: string;
  icon?: IconName;
  footer: string;
}

const Highlight: React.FC<HighlightedFeature> = ({ header, text, icon, footer }) => {
  return (
    <Layout direction="column" height="100%" justify="space-between" align="center" __style={{ whiteSpace: 'nowrap' }}>
      <Text size="tag" color="gray-600">
        {header}
      </Text>
      {text && (
        <Text size="h6" color="gray-600">
          {text}
        </Text>
      )}
      {icon && <Icon size="sm" name={icon} color="gray-600" />}
      <Text size="body3" color="gray-600">
        {footer}
      </Text>
    </Layout>
  );
};

export default Highlights;
