import React from 'react';

import { Layout } from 'ds';
import { selectCustomerSupportTicketByInteractionId } from 'store/Chatbot/selectors';
import { useAppSelector } from 'store/hooks';
import ChatbotThreadMessage from 'ux/Chatbot/ChatbotThreadMessage';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

import ChatbotCreateSupportTicket from '../ChatbotCreateSupportTicket';
import ChatbotViewSupportTicket from '../ChatbotViewSupportTicket';

interface Props extends ChatbotIntentProps {}

const Fallback: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  const supportTicket = useAppSelector(selectCustomerSupportTicketByInteractionId(chatbotInteraction.id));
  const { llm_response, resolved_at } = chatbotInteraction;

  return (
    <>
      {llm_response && (
        <ChatbotThreadMessage isLastMessageInGroup>
          <div dangerouslySetInnerHTML={{ __html: llm_response }} />
        </ChatbotThreadMessage>
      )}
      {resolved_at && supportTicket ? (
        <Layout marginTop={16}>
          <ChatbotViewSupportTicket supportTicket={supportTicket} />
        </Layout>
      ) : (
        <Layout marginTop={16}>
          <ChatbotCreateSupportTicket chatbotInteraction={chatbotInteraction} threadId={threadId} />
        </Layout>
      )}
    </>
  );
};

export default Fallback;
