import React from 'react';

import { ButtonSize, ButtonType } from 'ds/types';
import useFieldSize from 'hooks/useFieldSize';

import ButtonPrivate, { Props as ButtonPrivateProps } from './ButtonPrivate';
import { LinkProps } from './Link';
import { ANCHOR_CONFIG, BUTTON_SIZE_CONFIG, BUTTON_TYPE_CONFIG } from './constants';

export interface Props
  extends Partial<LinkProps>,
    Pick<
      ButtonPrivateProps,
      | 'background'
      | 'textOpacity'
      | 'text'
      | 'textSize'
      | 'fullWidth'
      | 'onClick'
      | 'disabled'
      | 'icon'
      | 'iconPosition'
      | 'iconSize'
      | 'iconStroke'
      | 'showSpinner'
      | 'semibold'
      | 'tabIndex'
      | 'htmlType'
      | 'search'
      | '__ref'
      | 'scrollYOffset'
    > {
  size?: ButtonSize;
  type: ButtonType;
  anchor?: 'top' | 'bottom';
}

const Button: React.FC<Props> = ({ type, anchor, ...props }) => {
  const config = BUTTON_TYPE_CONFIG[type];
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  return (
    <ButtonPrivate
      scaleText={!props.size}
      {...BUTTON_SIZE_CONFIG[size]}
      {...config}
      {...props}
      size={size}
      {...(anchor ? ANCHOR_CONFIG[anchor] : undefined)}
    />
  );
};

export default Button;
