import React, { useContext } from 'react';

import { media } from 'context';
import { FullWidthBackground, Layout, Text } from 'ds';

import Logos from './Logos';
import Testimonial from './Testimonial';

interface Props {}

const TESTIMONIALS = [
  {
    quote:
      'At WeWork or Industrious you cannot feel ownership of the space. Codi is different. We get turnkey offices in various locations and have everything taken care of.',
    name: 'Che',
    title: 'CEO at Eppo',
    subtitle: 'Member since Oct 2021',
    avatarSrcKey: 'che-eppo_QpJmRpSMH.jpeg'
  },
  {
    quote:
      'We are a very happy customer of Codi. We talked to a brokerage firm and it was a big hassle, really archaic. Codi was seamless and easy.',
    name: 'Chinmay',
    title: 'CEO at Ridepanda',
    subtitle: 'Member in 3 cities',
    avatarSrcKey: 'chinmay-ridepanda_bFaaq-0xl.jpeg'
  },
  {
    quote:
      'The “Codi Mondays” are deeply ingrained in our company culture now, allowing my team to have a once-a-week turnkey office.',
    name: 'Charles',
    title: 'CEO at Poised',
    subtitle: 'Member since April 2021',
    avatarSrcKey: 'charles-poised_CtHuSY6ep.jpeg'
  }
];

const Testimonials: React.FC<Props> = () => {
  const { xs, lg, xl, xxl } = useContext(media);

  let testimonials;

  if (xxl) {
    testimonials = TESTIMONIALS;
  } else if (lg) {
    testimonials = TESTIMONIALS.slice(0, 2);
  } else {
    testimonials = TESTIMONIALS.slice(0, 1);
  }

  return (
    <FullWidthBackground color="blue-600" paddingY={xs ? 64 : 90}>
      <Text size="h4" color="white" align="center">
        Hear from some of our clients
      </Text>
      <Layout marginTop={xs ? 72 : 44}>
        <Logos />
      </Layout>
      <Layout marginTop={xs ? 72 : 44} flex>
        {testimonials.map((testimonial, index) => {
          return (
            <Layout key={testimonial.name} marginLeft={index === 0 || xs ? undefined : xl ? 48 : 24} equalWidth>
              <Testimonial {...testimonial} />
            </Layout>
          );
        })}
      </Layout>
    </FullWidthBackground>
  );
};

export default Testimonials;
