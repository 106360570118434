import React, { useContext } from 'react';
import { BreadcrumbList, WebPage, WithContext } from 'schema-dts';

import { imageKitUrl } from 'helpers/images';

import Head from 'components/Head';

import { app, media } from 'context';
import { Img, Layout, MAX_CONTENT_WIDTH } from 'ds';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

import Hero from './Hero';
import MarketingBlock from './MarketingBlock';
import Testimonials from './Testimonials';
import ValueProps from './ValueProps';
import Workspaces from './Workspaces';

interface Props {}

type SectionType = {
  type: string;
  fields: any;
};

const Offsites: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const page = useAppSelector(state => selectCmsPage(state, 'offsites'));
  const { windowWidth, ...appContext } = useContext(app);
  const maxContentWidth = Math.min(appContext.contentWidth, MAX_CONTENT_WIDTH);

  if (!page) return <Layout minHeight="100vh" />;

  const {
    seo: { title, description, keywords },
    sections
  } = page.fields;

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${window.location.origin}/#website`,
          name: 'Codi',
          url: window.location.origin
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${window.location.origin}/#offsites`,
          name: title,
          url: window.location.href
        }
      }
    ]
  };

  const schema: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': window.location.href,
    url: window.location.href,
    breadcrumb: breadcrumbs,
    name: title,
    speakable: { '@type': 'SpeakableSpecification', xpath: ["/html/head/meta[@name='description']/@content"] },
    description: description,
    significantLink: `${window.location.origin}/office-space/search?offsites_only=1`,
    image: imageKitUrl({
      key: '60d8c6abb6df03c5fb517d0490f38c22_LXihdaBF7.png',
      folder: 'public',
      transformationChain: ['w-1920,h-1080']
    })
  };

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <div className="CmsPage">
        {sections.map((section: SectionType, index: number) => {
          return <Section key={index} section={section} />;
        })}
      </div>
      <Img
        srcKey="60d8c6abb6df03c5fb517d0490f38c22_LXihdaBF7.png"
        folder="public"
        alt="office"
        width={xs ? 1000 : 1920}
        style={{
          height: xs ? 200 : 328,
          width: windowWidth,
          objectFit: 'cover',
          left: -(windowWidth - maxContentWidth) / 2,
          position: 'relative'
        }}
      />
    </>
  );
};

interface SectionProps {
  section: SectionType;
}

const Section: React.FC<SectionProps> = ({ section }) => {
  switch (section.type) {
    case 'hero':
      return <Hero {...section.fields} />;
    case 'marketing_block':
      return <MarketingBlock {...section.fields} />;
    case 'value_props':
      return <ValueProps {...section.fields} />;
    case 'testimonials':
      return <Testimonials {...section.fields} />;
    case 'workspaces':
      return <Workspaces {...section.fields} />;
    default:
      return null;
  }
};
export default Offsites;
