import React, { ReactElement } from 'react';

import { Layout, Text } from 'ds';

import { CaseStudyPageType } from './types';

interface Props {
  logo: ReactElement;
  industry: string;
  location: string;
  numEmployees: string;
  workplaceStrategy: string;
  pageType: CaseStudyPageType;
}

const CaseStudyCompanyInfo: React.FC<Props> = ({
  logo,
  industry,
  location,
  numEmployees,
  workplaceStrategy,
  pageType
}) => {
  const isSingleColumn = ['phone', 'side-nav'].includes(pageType);

  return (
    <Layout padding={24} borderRadius={8} borderWidth={1} __id="company-info">
      {logo}
      <Layout marginTop={isSingleColumn ? 36 : 24} flex={!isSingleColumn}>
        <Layout {...(isSingleColumn ? {} : { equalWidth: true, flex: true, flexGrow: 1, direction: 'column' })}>
          <Layout>
            <Text size="body3" color="gray-400" semibold>
              INDUSTRY
            </Text>
            <Text size="body1">{industry}</Text>
          </Layout>
          <Layout {...(isSingleColumn ? { marginTop: 36 } : { marginTop: 24 })}>
            <Text size="body3" color="gray-400" semibold>
              LOCATION
            </Text>
            <Text size="body1">{location}</Text>
          </Layout>
        </Layout>
        <Layout
          {...(isSingleColumn
            ? { marginTop: 36 }
            : { equalWidth: true, flex: true, flexGrow: 1, direction: 'column', marginLeft: 24 })}
        >
          <Layout>
            <Text size="body3" color="gray-400" semibold>
              EMPLOYEES
            </Text>
            <Text size="body1">{numEmployees}</Text>
          </Layout>
          <Layout {...(isSingleColumn ? { marginTop: 36 } : { marginTop: 24 })}>
            <Text size="body3" color="gray-400" semibold>
              WORKPLACE STRATEGY
            </Text>
            <Text size="body1">{workplaceStrategy}</Text>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CaseStudyCompanyInfo;
