import { EditableImage, axios } from '@codiwork/codi';

import { CreatableCustomerRequest, CustomerRequest } from './types';

export function getCustomerRequests({ userId }: { userId: number }) {
  return axios.get<CustomerRequest[]>(`/v1/users/${userId}/requests`);
}

export function getCustomerRequest({ userId, requestId }: { userId: number; requestId: number }) {
  return axios.get<CustomerRequest>(`/v1/users/${userId}/requests/${requestId}`);
}

export function deleteCustomerRequest({ userId, requestId }: { userId: number; requestId: number }) {
  return axios.delete<CustomerRequest>(`/v1/users/${userId}/requests/${requestId}`);
}

export function createCustomerRequest({ userId, ...params }: { userId: number; request: CreatableCustomerRequest }) {
  const { images, ...request } = params.request;

  return axios.post<CustomerRequest>(`/v1/users/${userId}/requests`, request).then(response => {
    if (images && images.length) {
      return uploadImages({ images, userId, requestId: response.data.id });
    } else {
      return response;
    }
  });
}

function uploadImages({ images, userId, requestId }: { images: EditableImage[]; userId: number; requestId: number }) {
  const formData = new FormData();
  images.forEach(i => {
    if (i.file) {
      formData.append('images[]', i.file);
    }
  });

  return axios.patch<CustomerRequest>(`/v1/users/${userId}/requests/${requestId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
