import React from 'react';
import { IconName } from '@codiwork/codi';

import FilterContainer from '../FilterContainer';
import Layout from '../Layout';
import RadioButton, { Props as RadioButtonProps } from '../RadioButton';
import Text from '../Text';

export interface Props<T = string | number> extends Pick<RadioButtonProps<T>, 'onChange'> {
  icon: IconName;
  label: string;
  options: Option<T>[];
  value: T;
  defaultValue?: T;
  placeholder?: string;
}

type Option<T> = {
  value: T;
  label: string;
  sublabel?: string;
};

const OptionFilter = <T extends string | number>({
  icon,
  label,
  options,
  onChange,
  value,
  defaultValue,
  placeholder
}: Props<T>): JSX.Element => {
  const isDefaultSelected = value === defaultValue;
  const selectedOption = options.find(o => o.value === value);
  const buttonText = isDefaultSelected && placeholder ? placeholder : selectedOption ? selectedOption.label : label;

  return (
    <FilterContainer icon={icon} isDefaultSelected={isDefaultSelected} buttonText={buttonText} width={200}>
      <Text size="body2" semibold>
        {label}
      </Text>
      {options.map(({ label, sublabel, value: optionValue }, index) => {
        return (
          <Layout
            key={optionValue}
            paddingTop={index === 0 ? 16 : 8}
            paddingBottom={index === options.length - 1 ? 16 : 8}
          >
            <RadioButton
              size="md"
              label={label}
              sublabel={sublabel}
              value={optionValue}
              onChange={onChange}
              checked={optionValue === value}
            />
          </Layout>
        );
      })}
    </FilterContainer>
  );
};

export default OptionFilter;
