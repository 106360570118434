import React, { useContext } from 'react';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {
  step: number;
  supertitle: string;
  title: string;
  subtitle?: string;
  items: Item[];
  srcKey: string;
}

interface Item {
  title: string;
  subtitle: string;
}

const StepExplanation: React.FC<Props> = ({ step, supertitle, title, subtitle, items, srcKey }) => {
  const { xs, sm, md, lg, xl, xxl } = useContext(media);
  const { windowWidth } = useContext(app);
  const isEvenStep = step % 2 === 0;
  const img = (
    <Img
      srcKey={srcKey}
      alt="office"
      width={1200}
      style={{
        height: xl ? 500 : lg ? 322 : md || sm ? 288 : 216,
        marginTop: xs ? 48 : undefined,
        objectFit: 'cover',
        borderRadius: xs ? undefined : 24,
        width: xs ? windowWidth : undefined,
        position: 'relative'
      }}
      folder="public"
    />
  );

  return (
    <Layout direction={xs ? 'column-reverse' : isEvenStep ? 'row-reverse' : 'row'} align="center">
      {xs ? img : <Layout flexGrow={1}>{img}</Layout>}
      <Layout
        width={xxl ? 508 : xl ? 590 : lg ? 480 : md ? 430 : sm ? 320 : undefined}
        flexShrink={0}
        {...(isEvenStep
          ? { marginRight: xl ? 100 : lg ? 80 : xs ? undefined : 40 }
          : { marginLeft: xl ? 100 : lg ? 80 : xs ? undefined : 40 })}
      >
        <Text size={lg ? 'body1' : 'body2'} color="blue-700" semibold>
          {supertitle}
        </Text>
        <Layout marginTop={24}>
          <Text size={lg ? 'h4' : 'h5'} color="blue-700">
            {title}
          </Text>
        </Layout>
        {subtitle && (
          <Layout marginTop={xs ? 4 : 8}>
            <Text size="h6" color="blue-700">
              {subtitle}
            </Text>
          </Layout>
        )}
        <Layout marginTop={24}>
          {items.map(({ title, subtitle }) => {
            return (
              <Layout paddingY={12} key={title} flex>
                <Layout>
                  <Text size={sm || md ? 'body2' : 'body1'} semibold>
                    {title}
                  </Text>
                  <Layout marginTop={4}>
                    <Text size={sm || md ? 'body3' : 'body2'} color="gray-400">
                      {subtitle}
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
            );
          })}
        </Layout>
        <Layout marginTop={40}>
          <Button
            text="Request an office"
            icon="rightArrow"
            iconStroke={2}
            size={lg ? 'lg' : 'md'}
            type="primary"
            href={OFFICE_REQUEST_PATH}
            fullWidth={xs}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default StepExplanation;
