import React from 'react';
import { ChildComponentProps } from 'google-map-react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { track } from 'lib/analytics';

interface Props extends ChildComponentProps {
  count: number;
  onClick: VoidFunction;
}

const ClusterMarker: React.FC<Props> = ({ count, onClick, lat, lng }) => {
  return (
    <button
      onClick={() => {
        onClick();

        track('Element Interacted', {
          type: 'map',
          action: 'cluster clicked',
          name: count.toString(),
          value: `${lat},${lng}`
        });
      }}
      style={{ display: 'inline-flex' }}
    >
      <Layout
        padding={6}
        display="inline-flex"
        color="blue-50"
        borderWidth={1}
        borderColor="blue-500"
        align="center"
        justify="center"
        width={28}
        height={28}
        borderRadius="circular"
        __className="ClusterMarker"
        inline
      >
        <Text size="body3" color="blue-700" semibold>
          {count}
        </Text>
      </Layout>
    </button>
  );
};

export default ClusterMarker;
