import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Text } from 'ds';

interface Props {}

const InfoCards: React.FC<Props> = () => {
  const { xs, sm, md } = useContext(media);

  return (
    <>
      <Layout
        paddingTop={44}
        borderRadius={24}
        {...(xs
          ? { marginTop: 24, paddingBottom: 40 }
          : { equalWidth: true, paddingBottom: sm || md ? 40 : undefined })}
        color="white"
        boxShadow="0 13px 23px 0 rgba(0, 0, 0, 0.0500)"
        paddingX={12}
        align="center"
        direction="column"
      >
        <Icon name="calendar" color="blue-gradient" size={48} />
        <Layout marginTop={24}>
          <Text color="blue-500" size="body1" align="center" bold>
            Tuesday,
            <br />
            June 4th
          </Text>
        </Layout>
      </Layout>
      <Layout
        paddingTop={44}
        borderRadius={24}
        {...(xs
          ? { marginTop: 24, paddingBottom: 40 }
          : { equalWidth: true, marginLeft: 16, paddingBottom: sm || md ? 40 : undefined })}
        color="white"
        boxShadow="0 13px 23px 0 rgba(0, 0, 0, 0.0500)"
        paddingX={12}
        align="center"
        direction="column"
      >
        <Icon name="clock" color="blue-gradient" size={48} />
        <Layout marginTop={24}>
          <Text color="blue-500" size="body1" align="center" bold>
            [Time TBD]
          </Text>
        </Layout>
      </Layout>
      <Layout
        paddingTop={44}
        borderRadius={24}
        {...(xs
          ? { marginTop: 24, paddingBottom: 40 }
          : { equalWidth: true, marginLeft: 16, paddingBottom: sm || md ? 40 : undefined })}
        color="white"
        boxShadow="0 13px 23px 0 rgba(0, 0, 0, 0.0500)"
        paddingX={12}
        align="center"
        direction="column"
      >
        <Icon name="addressPinOutline" color="blue-gradient" size={48} />
        <Layout marginTop={24}>
          <Text color="blue-500" size="body1" align="center" bold>
            Midtown,
            <br />
            NYC
          </Text>
        </Layout>
      </Layout>
    </>
  );
};

export default InfoCards;
