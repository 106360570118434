import React from 'react';
import { Color } from '@codiwork/codi';

import Layout from 'ds/Layout';

import BreadCrumb from './BreadCrumb';
import { BreadCrumb as BreadCrumbType } from './types';

export interface Props {
  items: BreadCrumbType[];
  textColor?: Color;
}

const BreadCrumbs: React.FC<Props> = ({ items, textColor }) => {
  return (
    <Layout align="center">
      {items.length <= 3 ? (
        items.map(({ label, href, search }, index) => (
          <BreadCrumb textColor={textColor} key={label} label={label} href={href} index={index} search={search} />
        ))
      ) : (
        <>
          <BreadCrumb textColor={textColor} {...items[0]} index={0} />
          <BreadCrumb textColor={textColor} items={items.slice(1, -2)} index={1} />
          {items.slice(-2).map(({ label, href, search }, index) => (
            <BreadCrumb textColor={textColor} key={label} label={label} href={href} index={index + 2} search={search} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default BreadCrumbs;
