import React, { useContext } from 'react';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { media } from 'context';
import { Layout } from 'ds';

import CTA from './CTA';
import Hero from './Hero';
import Included from './Included';
import { PLAYBOOK_META } from './meta';

interface Props {}

const PlaybookLandingPage: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const metaTags = PLAYBOOK_META['/playbook'];

  return (
    <Layout height="100%">
      <Head title={metaTags.title} description={metaTags.description} />
      <Hero />
      <Included />
      <CTA />
      <HeroImage imageKey="conference-room_sxvb_uTNB.jpg" imageKitFolder="public" height={xs ? 240 : 392} />
    </Layout>
  );
};

export default PlaybookLandingPage;
