import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { app } from 'context';
import { Layout } from 'ds';
import { actions as uiActions } from 'store/UI';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch } from 'store/hooks';

import { Route } from './types';
import Footer from '../ux/Layouts/Shared/Footer';
import Header from '../ux/Layouts/Shared/Header';

interface Props
  extends Pick<
    Route,
    'requiredRole' | 'customLayout' | 'scrollToTop' | 'hideHeader' | 'hideFooter' | 'hasFooterMargin' | 'hasHeaderCta'
  > {
  children: JSX.Element;
}

const scrollPositions: { [key: string]: number } = {};

window.history.scrollRestoration = 'manual';

const Page: React.FC<Props> = ({
  hideHeader,
  hideFooter,
  hasFooterMargin,
  hasHeaderCta,
  children,
  scrollToTop = true,
  customLayout,
  requiredRole
}) => {
  const { pathname, hash } = useLocation();
  const { action } = useHistory();
  const { overflowX, overflowY, navBarHeight } = useContext(app);
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.document.addEventListener('scroll', handleScroll);

    return window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Do not scroll when there is a hash to avoid interfering with anchor links.
    if (hash.length > 0) return;

    if (!scrollToTop && action === 'POP') {
      window.scrollTo(0, scrollPositions[pathname]);
    } else {
      window.scrollTo(0, 0);
    }
    dispatch(uiActions.updateUIState({ bottomNavigationIsVisible: true }));
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const isAuthenticatedPage = !!requiredRole;

  if ((user && pathname.startsWith('/admin')) || isAuthenticatedPage) {
    return children;
  }

  return (
    <Layout height={overflowY === 'hidden' ? '100vh' : undefined} overflowY={overflowY}>
      {!hideHeader && <Header showCta={hasHeaderCta} />}
      <Layout
        __style={
          customLayout
            ? undefined
            : {
                width: document.body.clientWidth,
                overflowX,
                minHeight: `calc(100vh - ${navBarHeight}px)`
              }
        }
      >
        {children}
        {!hideFooter && <Footer hasMargin={hasFooterMargin} />}
      </Layout>
    </Layout>
  );
};

function handleScroll() {
  scrollPositions[window.location.pathname] = window.scrollY;
}

export default Page;
