import { axios } from '@codiwork/codi';
import { EditableFeedback } from './types';

interface FeedbackParams {
  userId: number;
  feedback: EditableFeedback;
}

export function submitFeedback({ userId, feedback }: FeedbackParams) {
  return axios.post(`/v1/customer_admins/${userId}/feedback`, { ...feedback });
}

export function dismissFeedbackRequest({ userId, feedback }: FeedbackParams) {
  return axios.post(`/v1/customer_admins/${userId}/feedback/dismiss`, { ...feedback });
}
