import React, { TouchEventHandler, useRef, useState } from 'react';
import classNames from 'classnames';

import { RANGE_SLIDER_DIMENSION } from './constants';
import { IS_TOUCH_DEVICE } from '../constants';

interface Props {
  min: number;
  max: number;
  minValue?: number;
  maxValue?: number;
  onMinValueChange: OnChange;
  onMaxValueChange: OnChange;
  interval: number;
}

type OnChange = (params: { event?: React.ChangeEvent; value: number }) => void;

const Slider: React.FC<Props> = ({
  min,
  max,
  minValue = min,
  maxValue = max,
  onMinValueChange,
  onMaxValueChange,
  interval
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [closestControl, setClosestControl] = useState<'min' | 'max'>('max');

  const handleTouchStart: TouchEventHandler = e => {
    const container = containerRef.current;

    if (!container) return;

    const { x, width } = container.getBoundingClientRect();
    const containerX = e.touches[0].clientX - x;
    const minPercentageX = (minValue || 0) / max;
    const maxPercentageX = (maxValue || 0) / max;
    const mousePercentageX = containerX / width;
    const deltaMin = Math.abs(minPercentageX - mousePercentageX);
    const deltaMax = Math.abs(maxPercentageX - mousePercentageX);

    if (deltaMin < deltaMax) {
      setClosestControl('min');
    } else {
      setClosestControl('max');
    }
  };

  const handleTouchMove: TouchEventHandler = e => {
    const container = containerRef.current;

    if (!container) return;

    const { x, width } = container.getBoundingClientRect();
    const containerX = e.touches[0].clientX - x;
    const minPercentageX = (minValue || 0) / max;
    const maxPercentageX = (maxValue || 0) / max;
    const mousePercentageX = containerX / width;
    const deltaMin = Math.abs(minPercentageX - mousePercentageX);
    const deltaMax = Math.abs(maxPercentageX - mousePercentageX);

    if (deltaMin < deltaMax) {
      setClosestControl('min');
      const value = mousePercentageX * (max - min);
      onMinValueChange({ value: Math.round(value / interval) * interval });
    } else if (deltaMin > deltaMax) {
      setClosestControl('max');
      const value = mousePercentageX * (max - min);
      onMaxValueChange({ value: Math.round(value / interval) * interval });
    }
  };

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', height: RANGE_SLIDER_DIMENSION, width: '100%' }}
      {...(IS_TOUCH_DEVICE
        ? {
            onTouchStart: handleTouchStart,
            onTouchMove: handleTouchMove
          }
        : {
            onMouseMove: e => {
              const container = containerRef.current;

              if (!container) return;

              const { x, width } = container.getBoundingClientRect();
              const containerX = e.clientX - x;

              const minPercentageX = (minValue || 0) / max;
              const maxPercentageX = (maxValue || max) / max;
              const mousePercentageX = containerX / width;

              const deltaMin = Math.abs(minPercentageX - mousePercentageX);
              const deltaMax = Math.abs(maxPercentageX - mousePercentageX);

              if (deltaMin < deltaMax) {
                setClosestControl('min');
              } else {
                setClosestControl('max');
              }
            }
          })}
    >
      <input
        type="range"
        min={min}
        max={max}
        value={minValue}
        className={classNames('Filter-slider', { 'is-z-index-above': closestControl === 'min' })}
        onChange={e => {
          const value = parseInt(e.target.value);

          if (value >= maxValue) {
            return;
          }

          onMinValueChange({ event: e, value });
        }}
        step={interval}
      ></input>
      <input
        type="range"
        min={min}
        max={max}
        value={maxValue}
        className={classNames('Filter-slider', { 'is-z-index-above': closestControl === 'max' })}
        onChange={e => {
          const value = parseInt(e.target.value);

          if (value <= minValue) {
            return;
          }

          onMaxValueChange({ event: e, value });
        }}
        step={interval}
      ></input>
    </div>
  );
};

export default Slider;
