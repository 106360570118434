import React, { useState } from 'react';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Popover from 'ds/Popover';
import Pressable from 'ds/Pressable';
import Text from 'ds/Text';
import { IS_TOUCH_DEVICE, PRICE_TOOLTIP_TEXT } from 'ds/constants';

import TextButton from './TextButton';
import Tooltip, { Props as TooltipProps } from './Tooltip';

interface Props extends Partial<TooltipProps> {}

const PriceExplanationTooltip: React.FC<Props> = ({ ...props }) => {
  const [popoverIsVisible, setPopoverIsVisible] = useState<boolean>(false);

  if (IS_TOUCH_DEVICE) {
    return (
      <Popover
        content={
          <Layout flex>
            <Text size="body3" color="white">
              {PRICE_TOOLTIP_TEXT}
            </Text>
            <Layout flexShrink={0} marginLeft={12}>
              <TextButton
                scale
                color="white"
                textSize="body3"
                text="Got it"
                paddingY={false}
                onClick={() => {
                  setPopoverIsVisible(false);
                }}
              />
            </Layout>
          </Layout>
        }
        placement="top-start"
        wrap
        popoverStyle={{ whiteSpace: 'normal' }}
        containerStyle={{ display: 'inline-flex' }}
        isVisible={popoverIsVisible}
        color="black"
        {...props}
      >
        <Pressable onPress={() => setPopoverIsVisible(true)}>
          <Icon size="xs" name="info" />
        </Pressable>
      </Popover>
    );
  }

  return (
    <Tooltip
      text={PRICE_TOOLTIP_TEXT}
      placement="top-start"
      offset={[-24, 8]}
      wrap
      popoverStyle={{ whiteSpace: 'normal' }}
      containerStyle={{ display: 'inline-flex' }}
      {...props}
    >
      <Icon size="xs" name="info" />
    </Tooltip>
  );
};

export default PriceExplanationTooltip;
