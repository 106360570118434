import React from 'react';
import { isAddress } from '@codiwork/codi';

import LocationInput from './Location/LocationInput';
import TextInput from './TextInput';
import { BaseProps as InputBaseProps, OnChange } from './types';
import Layout from '../Layout';

export type Props = Pick<
  InputBaseProps,
  'value' | 'size' | 'disabled' | 'error' | 'onClear' | 'autoFocus' | 'required'
> & {
  onAddressChange?: (params: OnAddressChangeParams) => void;
  onChange: (params: { value: string }) => void;
  origin?: google.maps.LatLngLiteral;
  onNonStreetAddressSelection?: VoidFunction;
  line2OnChange?: OnChange;
  line2Value?: string;
} & NeighborhoodProps;

type NeighborhoodProps =
  | {
      neighborhoodOnChange: (neighborhood?: string | null) => void;
      neighborhoodValue?: string;
      city?: string;
      region?: string;
    }
  | {
      neighborhoodOnChange?: never;
      neighborhoodValue?: never;
      city?: never;
      region?: never;
    };
interface OnAddressChangeParams {
  address: string;
  place: google.maps.places.PlaceResult | null;
}

const AddressInput: React.FC<Props> = ({
  value,
  onChange,
  onAddressChange,
  disabled,
  origin,
  onNonStreetAddressSelection,
  error,
  line2OnChange,
  line2Value,
  neighborhoodOnChange,
  neighborhoodValue,
  onClear,
  size,
  required,
  ...props
}) => {
  return (
    <Layout width="100%">
      <LocationInput
        {...props}
        placeholder="123 Main St, San Francisco, CA 94105, USA"
        label="Address"
        value={value}
        size={size}
        onChange={onChange}
        onLocationChange={({ location, place }) => {
          onAddressChange && onAddressChange({ address: location, place });

          // TODO: add isAddress: boolean to onAddressChange to avoid race condition with onNonStreetAddressSelection
          // after selecting a non-address multiple times.
          if (!isAddress(place!)) {
            onNonStreetAddressSelection && onNonStreetAddressSelection();
          }
        }}
        disabled={disabled}
        error={error}
        onClear={onClear}
        origin={origin}
        types={['address']}
        hideIcon
        required={required}
      />
      {line2OnChange && (
        <Layout marginTop={16}>
          <TextInput
            size={size}
            onChange={line2OnChange}
            label="Unit"
            placeholder="Apartment, suite, unit (optional)"
            value={line2Value}
            maxLength={30}
            showMaxLength={false}
            disabled={disabled}
          />
        </Layout>
      )}
      {neighborhoodOnChange && (
        <Layout marginTop={16}>
          <TextInput
            size={size}
            onChange={({ value }) => neighborhoodOnChange(value)}
            label="Neighborhood"
            placeholder="Neighborhood"
            value={neighborhoodValue}
            disabled={disabled}
          />
        </Layout>
      )}
    </Layout>
  );
};

export default AddressInput;
