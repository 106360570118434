import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, FullWidthBackground, Layout, Text } from 'ds';

import { AmenitySection } from './types';

interface Props extends AmenitySection {}

const IncludedAmenities: React.FC<Props> = ({ title, subtitle, amenities }) => {
  const { xs } = useContext(media);

  return (
    <FullWidthBackground color="blue-50" paddingY={xs ? 48 : 136}>
      <Layout paddingX={xs ? undefined : 144}>
        <Animate>
          <Layout marginBottom={24}>
            <Text size="h3" align="center" scale>
              {title}
            </Text>
          </Layout>
          <Layout>
            <Text size="h5" align="center" bold={false} scale>
              {subtitle}
            </Text>
          </Layout>
        </Animate>
        <Layout marginTop={xs ? 48 : 96} flex={!xs}>
          {amenities.map(({ image, title, subtitle }, index) => {
            return (
              <Animate
                key={title}
                marginLeft={xs ? undefined : index === 0 ? undefined : 24}
                marginTop={xs ? (index === 0 ? undefined : 24) : undefined}
                equalWidth={!xs}
                delay={index * 150}
              >
                <Layout
                  color="white"
                  paddingTop={xs ? undefined : 24}
                  paddingBottom={24}
                  paddingX={xs ? 24 : 40}
                  boxShadow="0px 13px 23px rgba(0, 0, 0, 0.05)"
                  borderRadius={24}
                  height="100%"
                >
                  <Layout justify="center">
                    <Layout width={80}>
                      <img src={image} alt="amenity" />
                    </Layout>
                  </Layout>
                  <Layout marginTop={24}>
                    <Text size="h6" align="center" scale>
                      {title}
                    </Text>
                  </Layout>
                  <Layout marginTop={24}>
                    <Text size="body1" align="center" scale>
                      {subtitle}
                    </Text>
                  </Layout>
                </Layout>
              </Animate>
            );
          })}
        </Layout>
      </Layout>
    </FullWidthBackground>
  );
};

export default IncludedAmenities;
