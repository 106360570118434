import { RootState } from 'store/store';

export const selectOfficeRequestReferralInfo = (state: RootState) => state.OfficeRequest.referralInfo;
export const selectOfficeRequestLocations = (state: RootState) => state.OfficeRequest.locations;
export const selectOfficeRequestMarket = (state: RootState) => state.OfficeRequest.market;
export const selectEntry = (state: RootState) => state.OfficeRequest.entry;
export const selectHeadCount = (state: RootState) => state.OfficeRequest.headCount;
export const selectDaysPerWeek = (state: RootState) => state.OfficeRequest.daysPerWeek;
export const selectOfficeRequestAmenities = (state: RootState) => state.OfficeRequest.amenities;
export const selectOtherAmenities = (state: RootState) => state.OfficeRequest.otherAmenities;
export const selectMaxMonthlyBudget = (state: RootState) => state.OfficeRequest.maxMonthlyBudget;
export const selectTour = (state: RootState) => state.OfficeRequest.tour;
export const selectOfficeRequestUser = (state: RootState) => state.OfficeRequest.user;
export const selectOfficeRequest = (state: RootState) => state.OfficeRequest;
export const selectOfficeRequestTour = (state: RootState) => state.OfficeRequest.tour;
export const selectOfficeRequestCompletedSteps = (state: RootState) => state.OfficeRequest.completedSteps;
export const selectLaunchPath = (state: RootState) => state.OfficeRequest.launchPath;
export const selectExitModalIsVisible = (state: RootState) => state.OfficeRequest.exitModalIsVisible;
export const selectTourRequestWorkspaceId = (state: RootState) => state.OfficeRequest.tourRequestWorkspaceId;
