import axios from '@codiwork/codi/lib/lib/axios';

const OFFSITE_REQUESTS_PATH = '/v1/offsite_requests' as const;

interface PostOffsiteRequestParams {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  company_name?: string;
  location?: string;
  how_heard?: string;
}

export function postOffsiteRequest(params: PostOffsiteRequestParams) {
  return axios.post(OFFSITE_REQUESTS_PATH, params);
}
