import React from 'react';
import classNames from 'classnames';

import Layout from 'ds/Layout';
import Text from 'ds/Text';
import useFieldSize from 'hooks/useFieldSize';

import useUniqueId from './helpers/useUniqueId';

export interface Props<T = string | number> {
  checked: boolean;
  value: T;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  label: string;
  sublabel?: string;
  onChange: (params: OnChangeParams<T>) => void;
}

export interface OnChangeParams<T> {
  event: React.ChangeEvent<HTMLInputElement>;
  checked: boolean;
  value: T;
}

const RadioButton = <T extends string | number>({
  checked,
  disabled,
  label,
  sublabel,
  onChange,
  value,
  ...props
}: Props<T>): JSX.Element => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const id = useUniqueId();

  return (
    <>
      <Layout
        __className={classNames('RadioButton', `is-size-${size}`, { 'is-checked': checked, 'is-disabled': disabled })}
        align="center"
      >
        <div className="RadioButton-button">
          <input
            id={id}
            className="RadioButton-input"
            onChange={e => {
              onChange({ event: e, checked: e.target.checked, value });
            }}
            disabled={disabled}
            type="radio"
            value={value}
            checked={checked}
          />
        </div>
        <label className="RadioButton-label" htmlFor={id}>
          <Text inline scale>
            {label}
          </Text>
        </label>
      </Layout>
      {sublabel && (
        <div className={classNames('RadioButton-sublabel', `is-size-${size}`)}>
          <Text size="body3" color="gray-500" inline>
            {sublabel}
          </Text>
        </div>
      )}
    </>
  );
};

export default RadioButton;
