import React from 'react';
import { ListChildComponentProps } from 'react-window';

import Row from './Row';
import { ItemWrapperData, Row as RowType } from './types';

interface Props<T extends RowType> extends ListChildComponentProps {
  data: ItemWrapperData<T>;
}

const RowWrapper = <T extends RowType>({ data, index, style }: Props<T>) => {
  const {
    ItemRenderer,
    headerIndices,
    rows,
    columns,
    setRowHeights,
    setRowHeightsById,
    rowHasBeenMeasured,
    getColumnWidth,
    getRowHeightById,
    rowHeight,
    minWidth,
    dynamicRowHeight,
    rowOnClick,
    rowHref
  } = data;

  if ((headerIndices && headerIndices.includes(index)) || !ItemRenderer) {
    return null;
  }

  const row = rows[index - headerIndices.length];

  return (
    <Row
      setRowHeights={setRowHeights}
      setRowHeightsById={setRowHeightsById}
      getRowHeightById={getRowHeightById}
      getColumnWidth={getColumnWidth}
      rowHasBeenMeasured={rowHasBeenMeasured}
      data={{ row, columns: columns }}
      minWidth={minWidth}
      rowHeight={rowHeight}
      index={index}
      style={style}
      key={row.id}
      dynamicRowHeight={dynamicRowHeight}
      rowOnClick={rowOnClick}
      rowHref={rowHref}
    />
  );
};

export default RowWrapper;
