import React from 'react';
import { formatDate } from '@codiwork/codi';
import pluralize from 'pluralize';

import { Icon, Layout, PillButton, Text } from 'ds';
import { formatUsd } from 'helpers';
import { actions } from 'store/Chatbot';
import { selectActiveChatbotThread } from 'store/Chatbot/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIntent } from 'ux/Chatbot/requests';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

import EditRecurringCleanings from './EditRecurringCleanings';
import OrderOneOffCleaning from './OrderOneOffCleaning';
import SelectRecurrenceType from './SelectRecurrenceType';

interface Props extends ChatbotIntentProps {}

const OrderCleaning: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  const dispatch = useAppDispatch();
  const thread = useAppSelector(selectActiveChatbotThread);

  const {
    id: chatbotInteractionId,
    llm_response,
    slots: { recurrenceType },
    resolved_at,
    add_on_order
  } = chatbotInteraction;

  const goBack = () => {
    if (!thread) return;

    updateIntent({
      chatbot_interaction_id: chatbotInteractionId,
      slots: { recurrenceType: null }
    }).then(({ data }) => {
      dispatch(actions.updateInteraction({ threadId: thread.id, interaction: data }));
    });
  };

  const totalAmount = add_on_order?.add_ons.reduce((acc, add_on) => acc + add_on.amount, 0) || 0;

  return (
    <Layout marginTop={16}>
      {!!resolved_at && !!add_on_order ? (
        <Layout direction="column" color="white" borderRadius={16} padding={16}>
          <Layout direction="row">
            <Layout width={24} height={24} color="blue-500" borderRadius="circular" justify="center" align="center">
              <Icon size={12} name="checkmark" color="white" stroke={2} />
            </Layout>
            <Layout marginLeft={8} />
            <Text size="body-md" semibold>
              Order confirmed
            </Text>
          </Layout>
          <Layout marginTop={16} />
          <Text size="body-sm">
            We've gone ahead and scheduled your {pluralize('cleanings', add_on_order.add_ons.length)}.
          </Text>
          {add_on_order.add_ons.map(add_on => {
            const { description, product } = add_on;
            if (!product || !product.key) return null;

            const { key } = product;

            return (
              <Layout key={add_on.id} marginTop={16} direction="row">
                <Layout width={72} height={72} borderRadius={16} color="blue-50" justify="center" align="center">
                  <Icon
                    size={36}
                    name={['deep_cleaning', 'weekly_cleaning'].includes(key) ? 'cleaningDeep' : 'cleaningLight'}
                    color="blue-500"
                  />
                </Layout>
                <Layout paddingY={12} paddingLeft={16} direction="column" align="flex-start" justify="space-between">
                  <Text size="body-sm" semibold>
                    {['deep_cleaning'].includes(key)
                      ? 'Cleaning'
                      : ['weekly_cleaning', 'weekly_touch_up'].includes(key)
                      ? product.description
                      : 'Touch-up'}
                  </Text>
                  {key === 'deep_cleaning' && (
                    <Text size="body-sm" color="gray-700">
                      Scheduled for {formatDate({ date: add_on.start_date!, format: 'MED' })}
                    </Text>
                  )}
                  {['weekly_cleaning', 'weekly_touch_up'].includes(key) && (
                    <Text size="body-sm" color="gray-700">
                      {description}
                    </Text>
                  )}
                </Layout>
              </Layout>
            );
          })}
          <Layout marginTop={16} paddingY={16} justify="space-between" align="center" borderTop>
            <Text size="body-sm" semibold>
              Total
            </Text>
            <Text size="body-sm" semibold>
              {formatUsd(totalAmount, true)}
            </Text>
          </Layout>
          {add_on_order.customer_url && (
            <Layout marginTop={16}>
              <PillButton
                size="md"
                type="secondary"
                text="Track order"
                icon="externalLink"
                iconPosition="right"
                fullWidth
                href={add_on_order.customer_url}
              />
            </Layout>
          )}
        </Layout>
      ) : (
        <>
          {!!llm_response && (
            <Layout padding={12} color="white" marginLeft={52} borderRadius={16}>
              <Text size="body-sm">{llm_response}</Text>
            </Layout>
          )}
          <Layout marginTop={16} flexGrow={1} color="white" borderRadius={16}>
            {recurrenceType === 'one-off' ? (
              <OrderOneOffCleaning chatbotInteraction={chatbotInteraction} goBack={goBack} threadId={threadId} />
            ) : recurrenceType === 'recurring' ? (
              <EditRecurringCleanings chatbotInteraction={chatbotInteraction} goBack={goBack} threadId={threadId} />
            ) : (
              <SelectRecurrenceType chatbotInteraction={chatbotInteraction} />
            )}
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default OrderCleaning;
