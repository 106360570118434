import React from 'react';

import { Layout } from 'ds';

interface Props {}

const Divider: React.FC<Props> = () => {
  return <Layout marginY={36} borderBottom borderColor="gray-50" />;
};

export default Divider;
