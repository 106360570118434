import React, { useContext } from 'react';

import { media } from 'context';
import { Button, Layout, Logo } from 'ds';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

import ProgressFooter from './ProgressFooter';
import StepMobile from './StepMobile';
import { StepProps } from './types';

interface Props extends StepProps {
  paddingX?: number;
}

const StepContainer: React.FC<Props> = props => {
  const {
    children,
    step,
    nextHref,
    ctaDisabled,
    skippable,
    backHref,
    onSubmit,
    requestState,
    stepCompletedEventValue,
    stepCompletedEventValues,
    paddingX = 40,
    stepName
  } = props;
  const { xs, sm } = useContext(media);
  const dispatch = useAppDispatch();

  if (xs || sm) {
    return <StepMobile {...props} />;
  }

  return (
    <Layout flex height="100vh">
      <Layout
        equalWidth
        background="linear-gradient(94.99deg, #1AB2D9 28.48%, #0078FF 141.74%)"
        align="center"
        justify="center"
        position="relative"
      >
        <Layout paddingX={40} maxWidth={652}>
          {children[0]}
        </Layout>
        <Layout position="absolute" top={40} left={40}>
          <Logo color="white" />
        </Layout>
      </Layout>
      <Layout direction="column" justify="space-between" equalWidth>
        <Layout justify="flex-end" height={104} align="center" paddingRight={40}>
          <Button
            size="xs"
            type="secondary"
            text="Exit"
            onClick={() => dispatch(actions.setExitModalIsVisible(true))}
          />
        </Layout>
        <Layout justify="center" paddingX={paddingX}>
          {children[1]}
        </Layout>
        <ProgressFooter
          step={step}
          nextHref={nextHref}
          ctaDisabled={ctaDisabled}
          skippable={skippable}
          backHref={backHref}
          onSubmit={onSubmit}
          requestState={requestState}
          stepCompletedEventValue={stepCompletedEventValue}
          stepCompletedEventValues={stepCompletedEventValues}
          stepName={stepName}
        />
      </Layout>
    </Layout>
  );
};

export default StepContainer;
