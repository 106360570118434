import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LatLng, Listing } from '@codiwork/codi';

import { app } from 'context';
import { HEADER_Z_INDEX, Icon, Layout, Link, Pressable, PriceExplanationModal, Text } from 'ds';
import { OFFICE_REQUEST_PATH, SEARCH_PATH } from 'routes';
import { selectMarkets } from 'store/App/selectors';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import HeaderTags from './HeaderTags';
import MarketNavigationTree from './MarketNavigationTree';
import SeoContent from './SeoContent';
import { SearchLocation, SearchState } from './types';
import { transformStateToSearch } from './utils';
import NoResults from '../NoResults';
import RequestOfficePrompt from '../RequestOfficePrompt';
import WorkspaceListCard from '../WorkspaceListCard';

interface Props {
  setSearchState: (state: SearchState) => void;
  backPathname: string;
  searchLocation: SearchLocation;
  workspaces: Listing[];
  center: LatLng;
  zoom: number;
  selectedWorkspaceId: number | null;
}

const MobileUI: React.FC<Props> = ({ searchLocation, workspaces, center, zoom }) => {
  const { windowHeight, windowWidth, contentPaddingX } = useContext(app);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [priceExplanationModalIsVisible, setPriceExplanationModalIsVisible] = useState<boolean>(false);
  const listWorkspaces = workspaces.slice(0, 5);
  const cardHeight = 366;
  const markets = useAppSelector(selectMarkets);
  const market = markets.find(
    m => m.name === (searchLocation.type === 'Market' ? searchLocation.name : searchLocation.market?.name)
  )!;

  return (
    <>
      <PriceExplanationModal
        isVisible={priceExplanationModalIsVisible}
        onClose={() => setPriceExplanationModalIsVisible(false)}
      />
      <Layout position="relative" paddingBottom={90}>
        {workspaces.length > 0 ? (
          <>
            <Layout padding={contentPaddingX}>
              <HeaderTags numResults={workspaces.length} searchLocation={searchLocation} />
            </Layout>
            {listWorkspaces.map((ws, index) => (
              <React.Fragment key={ws.id}>
                <Layout
                  paddingBottom={40}
                  height={cardHeight + 24}
                  width={windowWidth}
                  paddingX={contentPaddingX}
                  key={ws.id}
                >
                  <WorkspaceListCard
                    workspace={ws}
                    daysPerWeek={5}
                    offsitesOnly={false}
                    setPriceExplanationModalIsVisible={setPriceExplanationModalIsVisible}
                  />
                </Layout>
                {index === 0 && (
                  <Layout paddingBottom={40} width={windowWidth} paddingX={contentPaddingX} key={ws.id}>
                    <RequestOfficePrompt
                      onClick={() => {
                        dispatch(officeRequestActions.setLaunchPath(location.pathname));
                        // TODO
                        // dispatch(officeRequestActions.setLocation(cityLocation));
                        history.push(OFFICE_REQUEST_PATH);
                      }}
                    />
                  </Layout>
                )}
              </React.Fragment>
            ))}
            {workspaces.length > listWorkspaces.length && (
              <Layout paddingX={contentPaddingX} width="100%" justify="flex-end">
                <Link
                  href={SEARCH_PATH}
                  search={transformStateToSearch({ center, zoom, location: searchLocation })}
                  text={
                    <Layout direction="row" align="center">
                      <Text size="body2">
                        View all {workspaces.length} offices in {searchLocation.name}
                      </Text>
                      <Layout marginLeft={4} />
                      <Icon name="rightArrow" size="xs" color="blue-500" />
                    </Layout>
                  }
                />
              </Layout>
            )}
          </>
        ) : (
          <>
            <Layout padding={contentPaddingX}>
              <HeaderTags numResults={workspaces.length} searchLocation={searchLocation} />
            </Layout>
            <Layout justify="center">
              <NoResults searchLocation={searchLocation} market={market} />
            </Layout>
          </>
        )}
        <SeoContent searchLocation={searchLocation} />
        <MarketNavigationTree market={searchLocation.type === 'Market' ? searchLocation : searchLocation.market!} />
        <Layout
          top={windowHeight - 60}
          width={windowWidth}
          justify="center"
          height={0}
          position="fixed"
          zIndex={HEADER_Z_INDEX - 20}
        >
          <Pressable
            onPress={() => {
              history.push(`${SEARCH_PATH}?${transformStateToSearch({ center, zoom, location: searchLocation })}`);
            }}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              height: 48,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 100,
              boxShadow: '0px 2px 4px 0px #00000021'
            }}
            color="white"
            activeColor="gray-50"
          >
            <Text size="body2">Browse</Text>
            <Layout marginLeft={8} display="inline-flex">
              <Icon size="sm" name="map" color="gray-900" />
            </Layout>
          </Pressable>
        </Layout>
      </Layout>
    </>
  );
};

export default MobileUI;
