import React, { useContext } from 'react';

import { app } from 'context';
import { Layout } from 'ds';
import { selectOfficeRequestLocations, selectOfficeRequestMarket } from 'store/OfficeRequest/selectors';
import { useAppSelector } from 'store/hooks';

import LocationInput from './LocationInput';
import StepContainer from './StepContainer';
import StepDescription from './StepDescription';
import StepTitle from './StepTitle';
import { generateStepPath } from './utils';

interface Props {}

const Step0: React.FC<Props> = () => {
  const market = useAppSelector(selectOfficeRequestMarket);
  const locations = useAppSelector(selectOfficeRequestLocations);
  const { isSmallPhone } = useContext(app);

  return (
    <StepContainer
      step={0}
      nextHref={generateStepPath({ step: 2 })}
      ctaDisabled={!locations.length}
      textLayoutProps={isSmallPhone ? { marginTop: 24 } : undefined}
      stepCompletedEventValue={market || undefined}
      stepCompletedEventValues={{ locations }}
      stepName="location"
    >
      <Layout>
        <StepTitle>Where would you like your office?</StepTitle>
        <Layout marginTop={24}>
          <StepDescription>We deliver the best offices with the most flexible terms — nationwide.</StepDescription>
        </Layout>
      </Layout>
      <LocationInput />
    </StepContainer>
  );
};

export default Step0;
