import { useEffect, useState } from 'react';

export type Status = 'idle' | 'loading' | 'ready' | 'error';
export type LinkElement = HTMLLinkElement | null;

function useLink(
  href: string,
  { setUp, disabled, id }: { setUp?: VoidFunction; disabled?: boolean; id?: string }
): Status {
  const [status, setStatus] = useState<Status>(href ? 'loading' : 'idle');

  useEffect(
    () => {
      if (!href || disabled) {
        setStatus('idle');
        return;
      }

      // Fetch existing link element by href
      // It may have been added by another instance of this hook1
      let link: LinkElement = document.querySelector(`link[href="${href}"]`);

      if (!link) {
        setUp && setUp();
        // Create link
        link = document.createElement('link');
        link.href = href;
        link.setAttribute('data-status', 'loading');
        link.setAttribute('rel', 'stylesheet');

        if (id) link.setAttribute('id', id);

        // Add link to document body
        document.body.appendChild(link);

        // Store status in attribute on link
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          link?.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
        };

        link.addEventListener('load', setAttributeFromEvent);
        link.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing link status from attribute and set to state.
        setStatus(link.getAttribute('data-status') as Status);
      }

      // Link event handler to update status in state
      // Note: Even if the link already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      link.addEventListener('load', setStateFromEvent);
      link.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (link) {
          link.removeEventListener('load', setStateFromEvent);
          link.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [href, disabled, setUp, id] // Only re-run effect if link href changes
  );

  return status;
}

export default useLink;
