import React, { useState } from 'react';
import { validateUrl } from '@codiwork/codi';

import Input from './Input';
import { BaseProps, OnChangeParams, ValidateProp } from './types';
import useValidate from './useValidate';

export interface Props extends BaseProps, ValidateProp {
  label?: string;
}

const UrlInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  size,
  onValidate,
  required = true,
  disabled,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const validate = (value?: string) => {
    return validateUrl({ value, required });
  };

  useValidate({ value: value || undefined, onValidate, validate, setErrorMessage });

  const onChangeWrapper = (e: OnChangeParams) => {
    onChange({ event: e.event, value: e.value });

    const validationResult = validate(e.value);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onValidate && onValidate(validationResult);
  };

  return (
    <Input
      {...props}
      name="domain"
      value={value}
      type="email"
      label={label || 'Url'}
      placeholder=""
      onChange={onChangeWrapper}
      onBlur={e => {
        setErrorMessage(validate(e.value).error);
        onBlur && onBlur(e);
      }}
      size={size}
      error={error || errorMessage}
      required={required}
      disabled={disabled}
    />
  );
};

export default UrlInput;
