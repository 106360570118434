import React, { useRef } from 'react';
import { ChildComponentProps } from 'google-map-react';

import WorkspaceCard, { Props as WorkspaceCardProps } from 'ds/WorkspaceCard';

import Layout from '../Layout';
import SearchMapWorkspaceCard from '../SearchMapWorkspaceCard';
import { useOnClickOutside } from '../helpers';

interface Props extends ChildComponentProps, Pick<WorkspaceCardProps, 'workspace' | 'size'> {
  clearWorkspace?: VoidFunction;
  width: number;
  height: number;
  dragging: boolean;
  daysPerWeek?: number;
  offsitesOnly?: boolean;
  isAdmin?: boolean;
  type?: 'search';
}
const MapWorkspaceCard: React.FC<Props> = ({
  workspace,
  clearWorkspace,
  width,
  height,
  dragging,
  size,
  isAdmin,
  daysPerWeek,
  offsitesOnly,
  type
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    ref,
    event => {
      if (!clearWorkspace || dragging) return;

      if (ref && ref.current && ref.current.contains(event.target as HTMLElement)) {
        return;
      }

      clearWorkspace();
    },
    true
  );

  return (
    <Layout position="relative" zIndex={5} __ref={ref} __style={{ width, height }}>
      {type === 'search' && daysPerWeek ? (
        <SearchMapWorkspaceCard workspace={workspace} daysPerWeek={daysPerWeek} offsitesOnly={offsitesOnly} />
      ) : (
        <WorkspaceCard
          width={width}
          hoverArrows={false}
          workspace={workspace}
          disableClick={dragging}
          size={size}
          isAdmin={isAdmin}
          lazyLoad
        />
      )}
    </Layout>
  );
};

export default MapWorkspaceCard;
