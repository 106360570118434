import React from 'react';
import { useLocation } from 'react-router-dom';

import Layout from 'ds/Layout';
import Link from 'ds/Link';
import Text from 'ds/Text';
import { HEADER_Z_INDEX, SECONDARY_MENU_WIDTH } from 'ds/constants';
import { MenuItem } from 'ds/types';
import { isNavigationItemActive } from 'ux/Layouts/Shared/utils';

import MenuCount from './MenuCount';

export interface Props {
  items: MenuItem[];
  title: string;
}

const SecondaryMenu: React.FC<Props> = ({ items, title }) => {
  const { pathname } = useLocation();

  return (
    <Layout
      zIndex={HEADER_Z_INDEX - 2}
      position="sticky"
      display="inline-flex"
      __style={{ verticalAlign: 'top' }}
      direction="column"
      paddingY={24}
      width={SECONDARY_MENU_WIDTH}
      height="100vh"
      top={0}
      color="blue-25"
      flexShrink={0}
    >
      <Layout paddingY={12} paddingX={32}>
        <Text size="body1" bold>
          {title}
        </Text>
      </Layout>
      <Layout marginTop={24}>
        {items.map(({ label, href, matchHrefs, exact, count }, index) => {
          const isActive = isNavigationItemActive({ href, matchHrefs, exact, pathname });
          const isFirst = index === 0;
          return (
            <Layout key={href} marginTop={isFirst ? undefined : 4} paddingX={16}>
              <Link href={href} opacity={false} display="block" color="gray-900">
                <Layout align="center" height={36} paddingX={16} hoverColor="blue-100" borderRadius={4}>
                  <Text size="body3" {...(isActive ? { color: 'blue-500', semibold: true } : { color: 'gray-700' })}>
                    {label}
                  </Text>
                  {typeof count === 'number' && (
                    <Layout marginLeft={10}>
                      <MenuCount count={count} />
                    </Layout>
                  )}
                </Layout>
              </Link>
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default SecondaryMenu;
