import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const Forward: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout maxWidth={xs ? contentWidth : 1238} direction={xs ? 'column' : 'row'} flex>
        <Layout position="relative">
          <Layout
            height={426}
            position="absolute"
            top={30}
            color="blue-700"
            borderRadius={24}
            width={332}
            zIndex={-1}
          />
          <Img
            srcKey="christelle_QadxdHLqy.png"
            folder="public"
            alt="Christelle"
            width={500}
            style={{ width: 332, borderRadius: 24, position: 'relative', left: 28 }}
          />
        </Layout>
        <Layout marginLeft={xs ? undefined : 128} paddingTop={30}>
          <Text size="h3" color="blue-700">
            Forward
          </Text>
          <Layout marginTop={48} __style={{ columnCount: xs ? 1 : 2, columnGap: 80 }}>
            <Text size="body2" color="gray-700">
              We’ve entered a new era of work, one that is defined by never-before seen flexibility. For more jobs than
              ever thought possible, people can work from just about anywhere. It’s a glorious thing. <br />
              <br />
              Still, many people work best when they’re together face-to-face — at least some of the time. And there’s
              just such a great feeling that comes with walking through a set of doors that belongs to YOUR team. A
              place that tells YOUR story and inspires everyone you work with to build something special.
              <br />
              <br /> …at least that’s how I feel.
              <br />
              <br />
              If you feel the same way and you’re excited about having a physical office to foster and motivate your
              team, then this playbook is for you. My team at Codi is dedicated to helping companies find and make the
              most of unique, inspiring offices — for however long they’re needed. The world of Commercial Real Estate
              can be daunting — seemingly treacherous, at times — and we hope that this playbook will help give you some
              guidance as well as assurance. Inside, you’ll find a step-by-step guide to the process, some important
              considerations, valuable tips, and even a pitfall or two to look out for. Good luck!
            </Text>
            <Layout marginTop={36}>
              <Text size="body3" color="blue-700" semibold>
                — Christelle Rohaut, Co-founder and CEO of Codi
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default Forward;
