import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: OfficesState = {
  officeSearchIsVisible: false,
  defaultOpenSearchComponent: null,
  priceExplanationModalIsVisible: false,
  recommendedOfficesIsVisible: false,
  reviewModalIsVisible: false,
  tourModalIsVisible: false,
  tourCartIsVisible: false,
  workspaceModalIsVisible: false,
  selectedWorkspaceId: null,
  workspaceListOrderKey: 0,
  scheduleTourPrompted: false
};

export type OfficesState = {
  officeSearchIsVisible: boolean;
  defaultOpenSearchComponent: 'locations' | 'budget' | 'headCount' | 'meetingRooms' | null;
  priceExplanationModalIsVisible: boolean;
  recommendedOfficesIsVisible: boolean;
  reviewModalIsVisible: boolean;
  tourModalIsVisible: boolean;
  tourCartIsVisible: boolean;
  workspaceModalIsVisible: boolean;
  selectedWorkspaceId: number | null;
  workspaceListOrderKey: number;
  scheduleTourPrompted: boolean;
};

const officesSlice = createSlice({
  name: 'Offices',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<OfficesState>>) => {
      return { ...state, ...payload };
    }
  }
});

export const { actions } = officesSlice;

export default officesSlice.reducer;
