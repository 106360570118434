import React from 'react';

import { selectChatbotScreen } from 'store/Chatbot/selectors';
import { useAppSelector } from 'store/hooks';

import ChatbotHome from './ChatbotHome';
import ChatbotThread from './ChatbotThread';
import ChatbotThreads from './ChatbotThreads';
import { CHATBOT_HOME_SCREEN, CHATBOT_THREADS_SCREEN } from './constants';
import { parseChatbotThreadId } from './utils';

interface Props {
  bottomSheetRef?: React.RefObject<HTMLDivElement>;
}

const ChatbotScreenContainer: React.FC<Props> = ({ bottomSheetRef }) => {
  const { value: screenValue } = useAppSelector(selectChatbotScreen);
  const threadId = parseChatbotThreadId(screenValue);
  const isHomeScreen = screenValue === CHATBOT_HOME_SCREEN;
  const isThreadsScreen = screenValue === CHATBOT_THREADS_SCREEN;
  const isThread = !!threadId;

  if (isHomeScreen) {
    return <ChatbotHome />;
  }

  if (isThread) {
    return <ChatbotThread bottomSheetRef={bottomSheetRef} />;
  }

  if (isThreadsScreen) {
    return <ChatbotThreads />;
  }

  return null;
};

export default ChatbotScreenContainer;
