import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, Clickable, Layout, Text } from 'ds';
import { actions } from 'store/OfficeRequest';
import { selectDaysPerWeek } from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import DayPerWeekMobile from './DayPerWeekMobile';
import { BOTTOM_SHEET_PADDING_BOTTOM } from './constants';

interface Props {}

const OPTIONS: Option[] = [
  { label: 'Full time', sublabel: '5-7 days per week', value: 5 },
  { label: '3 days per week', value: 3 },
  { label: '2 days per week', value: 2 },
  { label: '1 day per week', value: 1 }
];

const MOBILE_OPTIONS: Option[] = [
  { label: 'Full time', sublabel: '5-7 days per week', value: 5 },
  { label: '3 days', sublabel: 'per week', value: 3 },
  { label: '2 days', sublabel: 'per week', value: 2 },
  { label: '1 day', sublabel: 'per week', value: 1 }
];

interface Option {
  label: string;
  sublabel?: string;
  value: number;
}

const DaysPerWeekSelector: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const daysPerWeek = useAppSelector(selectDaysPerWeek);
  const { xs, sm } = useContext(media);
  const { contentPaddingX } = useContext(app);
  const isMobile = xs || sm;

  return isMobile ? (
    <Layout maxWidth={480} flexGrow={1} paddingBottom={BOTTOM_SHEET_PADDING_BOTTOM} paddingX={contentPaddingX}>
      <Layout flex>
        {MOBILE_OPTIONS.slice(0, 2).map(({ label, sublabel, value }, index) => {
          const isSelected = value === daysPerWeek;

          return (
            <Layout key={value} marginRight={index === 0 ? 12 : undefined} equalWidth>
              <DayPerWeekMobile value={value} isSelected={isSelected} label={label} sublabel={sublabel} />
            </Layout>
          );
        })}
      </Layout>
      <Layout marginTop={12} flex>
        {MOBILE_OPTIONS.slice(2).map(({ label, sublabel, value }, index) => {
          const isSelected = value === daysPerWeek;

          return (
            <Layout key={value} marginRight={index === 0 ? 12 : undefined} equalWidth>
              <DayPerWeekMobile value={value} isSelected={isSelected} label={label} sublabel={sublabel} />
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  ) : (
    <Layout maxWidth={524} flexGrow={1}>
      {OPTIONS.map(({ label, sublabel, value }, index) => {
        const isSelected = value === daysPerWeek;

        return (
          <Animate key={label} delay={index * 40}>
            <Clickable
              onClick={() => {
                dispatch(actions.setDaysPerWeek(value));
              }}
              style={{ marginTop: index !== 0 ? 12 : undefined, display: 'block', width: '100%' }}
            >
              <Layout
                borderRadius={8}
                {...(isSelected
                  ? { borderColor: 'blue-500', borderWidth: 2, color: 'blue-50', paddingX: 31, paddingY: 23 }
                  : { borderColor: 'gray-50', borderWidth: 1, paddingX: 32, paddingY: 24, hoverColor: 'blue-50' })}
              >
                <Text size={xs ? 'h6' : 'body-md'} bold={false} align="left">
                  {label}
                </Text>
                {sublabel && (
                  <Layout marginTop={8}>
                    <Text size="body3" color="gray-700" align="left">
                      {sublabel}
                    </Text>
                  </Layout>
                )}
              </Layout>
            </Clickable>
          </Animate>
        );
      })}
    </Layout>
  );
};

export default DaysPerWeekSelector;
