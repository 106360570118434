import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Head from 'components/Head';

import { media } from 'context';
import { Layout } from 'ds';
import { ButterPage } from 'lib/butter/types';

import Amenity from './Amenity';
import Centered from './Centered';
import ClientCTASection from './ClientCTA';
import CustomAmenities from './CustomAmenities';
import Hero from './Hero';
import ImageText from './ImageText';
import IncludedAmenities from './IncludedAmenities';
import { Section as SectionType } from './types';

interface Props extends RouteComponentProps, ButterPage {}

const CmsPage: React.FC<Props> = ({ fields }) => {
  const { md, lg } = useContext(media);

  const sections = transformFields(fields);
  const marginTop = lg ? 200 : md ? 160 : 140;

  return (
    <>
      <Head title={fields.seo.title} description={fields.seo.description} />
      <div className="CmsPage" style={{ marginTop: lg ? 120 : md ? 72 : 40, minHeight: '100vh' }}>
        {sections.map((section, index) => {
          return <Section key={index} section={section} marginTop={marginTop} />;
        })}
      </div>
    </>
  );
};

interface SectionProps {
  section: SectionType;
  marginTop: number;
}

const Section: React.FC<SectionProps> = ({ section, marginTop }) => {
  switch (section.type) {
    case 'hero':
      return <Hero {...section.fields} />;
    case 'image_text':
      return (
        <Layout marginTop={marginTop}>
          <ImageText {...section.fields} />
        </Layout>
      );
    case 'centered':
      return (
        <Layout marginTop={marginTop}>
          <Centered {...section.fields} />
        </Layout>
      );
    case 'included_amenities':
      return (
        <Layout marginTop={marginTop}>
          <IncludedAmenities {...section.fields} />
        </Layout>
      );
    case 'custom_amenities':
      return (
        <Layout marginTop={marginTop}>
          <CustomAmenities {...section.fields} />
        </Layout>
      );
    case 'amenity':
      return <Amenity {...section.fields} />;
    case 'client_cta_section':
      return <ClientCTASection {...section.fields} hasAnimation />;
    default:
      return null;
  }
};

const SINGLE_REPEATER_FIELDS: Partial<Record<SectionType['type'], string[]>> = {
  hero: ['cta', 'image', 'image_xs', 'blob', 'blob_xs'],
  image_text: ['cta', 'image', 'image_xs', 'blob', 'blob_xs'],
  amenity: ['blob', 'blob_xs', 'image', 'text']
};

function transformFields(fields: any): SectionType[] {
  const componentTypes = Object.keys(SINGLE_REPEATER_FIELDS) as SectionType['type'][];

  return fields.sections.map(({ fields: section, type }: any) => {
    const updated = { ...section };

    componentTypes.forEach(componentType => {
      if (componentType === type) {
        SINGLE_REPEATER_FIELDS[componentType]?.forEach(field => {
          if (Array.isArray(updated[field])) {
            updated[field] = updated[field][0];
          }
        });
      }
    });

    return { fields: updated, type };
  });
}

export default CmsPage;
