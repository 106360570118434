import React, { useContext } from 'react';
import classNames from 'classnames';

import { app, media } from 'context';
import { Animate, Layout, Text } from 'ds';

import { Quote } from './types';

import { ReactComponent as QuoteLeft } from 'assets/images/svgs/quote-left.svg';

interface Props {
  quotes: Quote[];
}

const Quotes: React.FC<Props> = ({ quotes }) => {
  const { xs } = useContext(media);
  const { contentPaddingX } = useContext(app);

  return (
    <Layout paddingLeft={xs ? undefined : 48}>
      {quotes.map(({ text, avatar, name, title }, index) => {
        const pinnedToRight = index % 2 === 0;

        return (
          <Animate
            key={index}
            boxShadow="0px 13px 23px 0px #00000026"
            color="white"
            paddingY={24}
            paddingX={36}
            position="relative"
            __style={{
              right: xs ? (pinnedToRight ? 0 : contentPaddingX) : index * 36,
              marginTop: index === 0 ? undefined : xs ? 40 : 16,
              width: xs ? `calc(100% + ${contentPaddingX}px)` : 'calc(100% - 48px)',
              borderTopRightRadius: xs ? (pinnedToRight ? 0 : 16) : 16,
              borderBottomRightRadius: xs ? (pinnedToRight ? 0 : 16) : 16,
              borderTopLeftRadius: xs ? (pinnedToRight ? 16 : 0) : 16,
              borderBottomLeftRadius: xs ? (pinnedToRight ? 16 : 0) : 16
            }}
            delay={150 * index}
          >
            <span
              className={classNames('Svg', 'is-color-blue-500')}
              style={{ width: 24, height: 21, display: 'inline-block' }}
            >
              <QuoteLeft />
            </span>
            <Layout marginY={24}>
              <Text size="h6" bold={false}>
                {text}
              </Text>
            </Layout>
            <Layout align="center">
              <img src={avatar} alt="person" style={{ borderRadius: '50%', width: 24 }} />
              <Layout marginLeft={12}>
                <Text size="body2" bold>
                  {name} • {title}
                </Text>
              </Layout>
            </Layout>
          </Animate>
        );
      })}
    </Layout>
  );
};

export default Quotes;
