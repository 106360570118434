import React, { PropsWithChildren, useContext } from 'react';

import { app, media } from 'context';
import { BOTTOM_NAVIGATION_HEIGHT, Layout } from 'ds';

interface Props {}

const NavigationContainer: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const { xs, sm } = useContext(media);
  const { overflowY } = useContext(app);

  return (
    <Layout
      flexGrow={1}
      {...(xs || sm
        ? overflowY === 'hidden'
          ? { minHeight: '100vh' }
          : { paddingBottom: BOTTOM_NAVIGATION_HEIGHT, minHeight: '100vh' }
        : { minHeight: '100%' })}
      flex
    >
      {children}
    </Layout>
  );
};

export default NavigationContainer;
