import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createWorkspace, formatAddress, getUser, updateUser } from '@codiwork/codi';

import { app, media } from 'context';
import { Button, EmailInput, Layout, Logo, PhoneInput, Text, TextInput } from 'ds';
import { RequestState } from 'ds/types';
import { apiTrack } from 'lib/analytics';
import { AUTH_PATH, PROPERTIES_PATH } from 'routes';
import { actions } from 'store/AddProperty';
import { selectAddPropertyUser, selectAddPropertyWorkspace } from 'store/AddProperty/selectors';
import { isCreatablePropertyUser } from 'store/AddProperty/utils';
import { actions as authActions } from 'store/Auth';
import { actions as userActions } from 'store/User';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isCreatableWorkspace } from 'ux/Properties/Listings/Listing/utils';

interface Props {}

const AddPropertyContact: React.FC<Props> = () => {
  const propertyUser = useAppSelector(selectAddPropertyUser);
  const { firstname, lastname, email, calling_code, phone, role } = propertyUser;
  const user = useAppSelector(selectUser);
  const { contentPaddingX } = useContext(app);
  const { isMobile } = useContext(media);
  const isValid = isCreatablePropertyUser(propertyUser);
  const workspace = useAppSelector(selectAddPropertyWorkspace);
  const { address, first_hosting_availability, square_feet, building_class, workspace_price } = workspace;
  const [requestState, setRequestState] = useState<RequestState>('ready');

  const history = useHistory();

  const dispatch = useAppDispatch();

  const trackSubmission = () => {
    if (!email || !phone || !role) return;

    apiTrack('Licensor Lead Submitted', {
      name: `${firstname} ${lastname}`,
      email,
      phone: `+${calling_code}${phone}`,
      role,
      location: address ? formatAddress(address) : undefined,
      size: square_feet,
      askingRent: workspace_price ? workspace_price.asking_rent : undefined,
      buildingClass: building_class,
      dateAvailable: first_hosting_availability
    });
  };

  const handleContinue = async () => {
    if (!isCreatableWorkspace(workspace)) return;

    if (user) {
      setRequestState('in_progress');
      const { data } = await updateUser({ id: user.id, is_workspace_creator: true });
      dispatch(userActions.setUser(data));

      await createWorkspace({
        workspace
      });

      const { data: updatedUser } = await getUser({ id: user.id });
      dispatch(userActions.setUser(updatedUser));

      setRequestState('ready');

      history.push(PROPERTIES_PATH);
    } else {
      goToAuth();
    }
  };

  const goToAuth = () => {
    if (!isCreatableWorkspace(workspace)) return;

    trackSubmission();
    dispatch(authActions.setAuthFlowType('addProperty'));
    history.push(AUTH_PATH);
  };

  return (
    <Layout
      {...(isMobile
        ? { paddingX: contentPaddingX }
        : { align: 'center', justify: 'center', height: '100vh', position: 'relative' })}
    >
      {isMobile && (
        <Layout align="center" height={72}>
          <Logo color="blue-500" />
        </Layout>
      )}
      <Layout {...(isMobile ? { paddingTop: 36 } : { maxWidth: 500, flexGrow: 1 })}>
        <Text size="h5" color="blue-700">
          Tell us how to contact you.
        </Text>
        <Layout {...(isMobile ? { marginTop: 24 } : { marginTop: 36, flex: true })}>
          <Layout {...(isMobile ? {} : { equalWidth: true })}>
            <TextInput
              value={firstname}
              label="First Name"
              size="sm"
              onChange={({ value }) => {
                dispatch(actions.updateUser({ firstname: value }));
              }}
              required
              autoFocus={!firstname}
            />
          </Layout>
          <Layout {...(isMobile ? { marginTop: 24 } : { equalWidth: true, marginLeft: 8 })}>
            <TextInput
              value={lastname}
              label="Last Name"
              size="sm"
              onChange={({ value }) => {
                dispatch(actions.updateUser({ lastname: value }));
              }}
              required
            />
          </Layout>
        </Layout>
        <Layout {...(isMobile ? { marginTop: 24 } : { marginTop: 12, flex: true })}>
          <Layout {...(isMobile ? {} : { equalWidth: true })}>
            <EmailInput
              value={email}
              label="Work Email"
              size="sm"
              onChange={({ value }) => {
                dispatch(actions.updateUser({ email: value }));
              }}
              required
            />
          </Layout>
          <Layout {...(isMobile ? { marginTop: 24 } : { equalWidth: true, marginLeft: 8 })}>
            <PhoneInput
              value={phone}
              callingCode={calling_code || '1'}
              label="Phone"
              size="sm"
              onChange={({ value }) => {
                dispatch(actions.updateUser({ phone: value }));
              }}
              onCallingCodeChange={value => {
                dispatch(actions.updateUser({ calling_code: value }));
              }}
              autoFocus={!phone && !!firstname}
              required
            />
          </Layout>
        </Layout>
        {isMobile ? (
          <Layout
            color="white"
            position="fixed"
            bottom={0}
            paddingY={12}
            width={`calc(100% - ${contentPaddingX * 2}px)`}
          >
            <Button
              text="Continue"
              size="sm"
              type="primary"
              disabled={!isValid}
              onClick={handleContinue}
              showSpinner={requestState === 'in_progress'}
              fullWidth
            />
          </Layout>
        ) : (
          <Layout marginTop={12}>
            <Button
              text="Continue"
              size="sm"
              type="primary"
              disabled={!isValid}
              onClick={handleContinue}
              showSpinner={requestState === 'in_progress'}
            />
          </Layout>
        )}
      </Layout>
      {!isMobile && (
        <Layout position="absolute" top={24} left={40}>
          <Logo color="blue-500" />
        </Layout>
      )}
    </Layout>
  );
};

export default AddPropertyContact;
