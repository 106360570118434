import React, { useContext } from 'react';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';

import { BROKERS_PAGE_MAX_WIDTH, BROKER_FORM_LINK, BROKER_FORM_LINK_SCROLL_OFFSET } from './constants';

interface Props {}

const HOME_PAGE_HERO_TITLE = (
  <>
    CodiConnect:
    <br />
    Brokers earn
    <br />& thrive here
  </>
);

const Hero: React.FC<Props> = () => {
  const { xs, sm, md } = useContext(media);
  const { width, contentPaddingX, windowWidth, navBarHeight } = useContext(app);

  const gutterX = (BROKERS_PAGE_MAX_WIDTH && width > BROKERS_PAGE_MAX_WIDTH ? width - BROKERS_PAGE_MAX_WIDTH : 80) / 2;

  return xs || sm ? (
    <Layout height={`calc(100vh - ${navBarHeight}px)`} position="relative">
      <Layout position="relative" height="100vh" top={-navBarHeight} zIndex={0}>
        <Img
          srcKey="c749de39720175b95c0124888fc57312_Ha-zQOqnG.jpeg"
          style={{ objectFit: 'cover', position: 'absolute', width: '100%', height: '100%', zIndex: 0 }}
          width={1600}
          alt="Powell office"
          folder="public"
        />
        <div
          style={{
            background: '#262626',
            opacity: 0.2,
            width: '100%',
            height: '100%',
            position: 'absolute',
            mixBlendMode: 'multiply'
          }}
        />
        <div
          style={{
            background: 'radial-gradient(circle at 52% 88%, rgba(0, 67, 206, 0) 16%, rgba(0, 67, 206, 0.6) 89%)',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      </Layout>
      <Layout paddingX={contentPaddingX} width={windowWidth} zIndex={1} position="absolute" top={148} left={0}>
        <Layout>
          <Text size="headline-xl" color="white">
            {HOME_PAGE_HERO_TITLE}
          </Text>
          <Layout marginTop={36}>
            <Button
              text="Become a broker partner"
              size="md"
              type="primary"
              href={BROKER_FORM_LINK}
              scrollYOffset={BROKER_FORM_LINK_SCROLL_OFFSET}
              fullWidth
            />
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Layout height={700} position="relative">
      <Layout position="relative" height={700 + navBarHeight} top={-navBarHeight} zIndex={0}>
        <Img
          srcKey="c749de39720175b95c0124888fc57312_Ha-zQOqnG.jpeg"
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%'
          }}
          width={2800}
          alt="Powell office"
          folder="public"
        />
        <div
          style={{
            background: '#262626',
            opacity: 0.2,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            mixBlendMode: 'multiply'
          }}
        />
        <div
          style={{
            background: 'radial-gradient(circle at 120% 0%, rgba(0, 67, 206, 0) 36%, rgba(0, 67, 206, 0.60) 85%)',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'absolute'
          }}
        />
      </Layout>
      <Layout zIndex={1} position="absolute" top={206} {...(md ? { left: 64 } : {})} paddingX={gutterX}>
        <Text size="display-lg" color="white">
          {HOME_PAGE_HERO_TITLE}
        </Text>
        <Layout marginTop={36}>
          <Button
            text="Become a broker partner"
            size="md"
            type="primary"
            href={BROKER_FORM_LINK}
            scrollYOffset={BROKER_FORM_LINK_SCROLL_OFFSET}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Hero;
