import { getItem } from './storage';

export const EXPIRY_DURATION = 10800000; // 3 hours in milliseconds 3 hours * 60 minutes * 60 seconds * 1000;

export const locationMissingOrExpired = (): boolean => {
  const ipLocation = getItem('ipLocation');

  if (!ipLocation) return true;

  return ipLocation.expiry < Date.now();
};
