import React from 'react';

import { Clickable, Grid, Icon, Layout, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_THREADS_SCREEN } from './constants';
import { determineIntent, startThread } from './requests';

interface Props {}

export const PROMPTS = [
  { value: 'I need to order an extra cleaning' },
  { value: 'Change the day of my weekly cleaning' },
  { value: "I'd like to order standing desks" }
];

const ChatbotHomePrompts: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const handleClick = async ({ value }: { value: string }) => {
    const { data: thread } = await startThread();
    const { id: threadId } = thread;

    dispatch(actions.addThread(thread));
    dispatch(actions.addPendingThreadInteraction({ threadId, input: value }));
    determineIntent({ chatbotThreadId: thread.id, message: value }).then(({ data }) => {
      dispatch(actions.removePendingThreadInteraction({ threadId }));
      dispatch(actions.addInteraction({ interaction: data, threadId }));
    });
    dispatch(actions.pushScreen({ value: `${CHATBOT_THREADS_SCREEN}/${threadId}` }));
  };

  return (
    <>
      <Text size="body-md" semibold>
        Here are more ways I can help
      </Text>
      <Layout marginTop={24}>
        <Grid gapY={12} itemsPerRow={1}>
          {PROMPTS.map(({ value }) => {
            return (
              <Clickable
                key={value}
                onClick={() => {
                  handleClick({ value });
                }}
              >
                <Layout
                  color="blue-100"
                  paddingLeft={24}
                  paddingRight={16}
                  borderRadius={1000}
                  justify="space-between"
                  align="center"
                  paddingY={16}
                >
                  <Text size="body-sm" color="blue-500" semibold>
                    {value}
                  </Text>
                  <Icon stroke={2} name="rightChevron" color="blue-500" size="xs" />
                </Layout>
              </Clickable>
            );
          })}
        </Grid>
      </Layout>
    </>
  );
};

export default ChatbotHomePrompts;
