import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const Growth1: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowHeight } = useContext(app);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex direction={xs ? 'column' : 'row'}>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={48}
        >
          <Text size="h3" color="blue-600">
            Growth
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                If you’re taking on a dedicated space in a coworking floor, you’ll want to project your company’s growth
                as best you can. Coworking companies have an inelastic supply, so if you quickly expand past your
                office’s capacity, you’ll only be able to add to your footprint if there’s unused space available.
                <br />
                <br />
                Similarly, if your company happens to shrink, you could be on the hook for more space than you need.
                Always keep in mind that while the process of working with a coworking space is much easier than
                traditional commercial offices, you could face the same inflexibility if your needs change and you’re
                locked in for a year or more.
                <br />
                <br />
                Accordingly, you’ll want to inquire about subletting and/or penalties for breaking your coworking
                agreement in the negotiation phase. For some private offices, a coworking space may only require a last
                month’s deposit and 30–60 days notice if you plan to leave. But the larger your company, and the more
                space you take on, the more stringent you can expect those end-of-contract policies to be.
              </Text>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="yellow-chairs_v0HJD1ZE8.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default Growth1;
