import React from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

import { daysOfWeek } from './utils';

interface Props {
  days: number[];
  showAllDays?: boolean;
}

const DaysOfWeekDisplay: React.FC<Props> = ({ days, showAllDays = false }) => {
  return (
    <Layout align="center">
      {daysOfWeek({ firstDay: 'Sunday' })
        .filter(({ ordinal }) => (showAllDays ? true : days.includes(ordinal)))
        .map(({ abbreviation, ordinal }, index) => {
          const isAvailable = showAllDays ? days.includes(ordinal) : true;

          return (
            <Layout
              {...(isAvailable ? { borderColor: 'gray-50', borderWidth: 1 } : { color: 'gray-25' })}
              key={abbreviation}
              justify="center"
              align="center"
              width={30}
              height={24}
              marginLeft={index === 0 ? undefined : 4}
              borderRadius={4}
            >
              <Text size="tag" {...(isAvailable ? {} : { color: 'gray-100' })}>
                {abbreviation}
              </Text>
            </Layout>
          );
        })}
    </Layout>
  );
};

export default DaysOfWeekDisplay;
