import React from 'react';

interface Props {
  value: string;
}

const RichTextDisplay: React.FC<Props> = ({ value }) => {
  return <div className="RichTextDisplay ql-editor" dangerouslySetInnerHTML={{ __html: value }}></div>;
};

export default RichTextDisplay;
