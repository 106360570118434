import React, { useContext } from 'react';

import { app, media } from 'context';
import { ContentBlock, Icon, Img, Layout, Link, Text } from 'ds';
import { CASE_STUDIES_PATH } from 'routes';

interface Props {}

const CaseStudies: React.FC<Props> = () => {
  const { contentPaddingX, windowWidth } = useContext(app);
  const { xs } = useContext(media);
  const content = (
    <>
      <Layout {...(xs ? { marginTop: 90 } : { align: 'center', justify: 'center', paddingY: 142 })}>
        <Layout {...(xs ? { paddingX: contentPaddingX } : { maxWidth: 414, flexShrink: 0 })}>
          <Text size="body2" color="blue-300" bold>
            CLIENT SUCCESS
          </Text>
          <Layout marginTop={4}>
            <Text size={xs ? 'h5' : 'h4'} color="blue-700">
              Learn how TaskRabbit improved collaboration for over 200 employees
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Text size="body1" color="gray-600">
              Codi helped TaskRabbit host 8 offsites across the span of 12 months. Discover how Codi’s workspaces and
              managed services helped drive this success.
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Link href={`${CASE_STUDIES_PATH}/taskrabbit`}>
              <Layout align="center">
                <Text size="h6" color="blue-500">
                  Read the full story&nbsp;
                </Text>
                <Icon name="rightArrow" size={18} stroke={2} color="blue-500" />
              </Layout>
            </Link>
          </Layout>
        </Layout>
        <Layout {...(xs ? { marginTop: 90 } : { marginLeft: 80 })}>
          <Img
            srcKey="task-rabbit-case-study_rmG8TnIpt.jpeg"
            alt="Task Rabbit Office"
            folder="public"
            width={xs ? 600 : 1400}
            style={
              xs
                ? { height: 248, width: windowWidth, objectFit: 'cover' }
                : { maxHeight: 414, objectFit: 'cover', borderRadius: 40 }
            }
          />
        </Layout>
      </Layout>
      <Layout
        {...(xs ? { marginTop: 90 } : { align: 'center', justify: 'center', paddingY: 142, direction: 'row-reverse' })}
      >
        <Layout {...(xs ? { paddingX: contentPaddingX } : { maxWidth: 414, flexShrink: 0 })}>
          <Text size="body2" color="blue-300" bold>
            LANDLORD SUCCESS
          </Text>
          <Layout marginTop={4}>
            <Text size={xs ? 'h5' : 'h4'} color="blue-700">
              See how JVL Property adapted to the market via short-term leases
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Text size="body1" color="gray-600">
              JVL Property partnered with Codi to adapt to the changing office market. Read their success story of
              filling vacant offices quickly and effortlessly with Codi’s move-in ready workspaces and managed services
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Link href="http://testimonials.codi.com/jvl-property/">
              <Layout align="center">
                <Text size="h6" color="blue-500">
                  Read the full story&nbsp;
                </Text>
                <Icon name="rightArrow" size={18} stroke={2} color="blue-500" />
              </Layout>
            </Link>
          </Layout>
        </Layout>
        <Layout {...(xs ? { marginTop: 90 } : { marginRight: 80 })}>
          <Img
            srcKey="jvl-case-study_yLI5-4qzq.jpeg"
            alt="JVL Office"
            folder="public"
            width={xs ? 600 : 1400}
            style={
              xs
                ? { height: 248, width: windowWidth, objectFit: 'cover' }
                : { maxHeight: 414, objectFit: 'cover', borderRadius: 40 }
            }
          />
        </Layout>
      </Layout>
      <Layout {...(xs ? { marginTop: 90 } : { align: 'center', justify: 'center', paddingY: 142 })}>
        <Layout {...(xs ? { paddingX: contentPaddingX } : { maxWidth: 414, flexShrink: 0 })}>
          <Text size="body2" color="blue-300" bold>
            BROKER SUCCESS
          </Text>
          <Layout marginTop={4}>
            <Text size={xs ? 'h5' : 'h4'} color="blue-700">
              Discover how Colton Commercial increased vacancy rates by 60%
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Text size="body1" color="gray-600">
              Mark D. Walker from Colton Commercial & Partners Inc. boosted occupancy rates at 435 Brannan Street by
              partnering with Codi. Learn more about his success story.
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Link href="https://testimonials.codi.com/colton-commercial/">
              <Layout align="center">
                <Text size="h6" color="blue-500">
                  Read the full story&nbsp;
                </Text>
                <Icon name="rightArrow" size={18} stroke={2} color="blue-500" />
              </Layout>
            </Link>
          </Layout>
        </Layout>
        <Layout {...(xs ? { marginTop: 90 } : { marginLeft: 80 })}>
          <Img
            srcKey="colton-case-study_vJcu3dYsH.jpeg"
            alt="Colton Office"
            folder="public"
            width={xs ? 600 : 1400}
            style={
              xs
                ? { height: 248, width: windowWidth, objectFit: 'cover' }
                : { maxHeight: 414, objectFit: 'cover', borderRadius: 40 }
            }
          />
        </Layout>
      </Layout>
    </>
  );

  return xs ? content : <ContentBlock maxWidth={1440}>{content}</ContentBlock>;
};

export default CaseStudies;
