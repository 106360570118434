const rateFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
});

// This gets passed the monthly price, already including the margin
export const formatPriceAdjustedForDaysPerWeek = ({
  monthlyPrice,
  daysPerWeek = 5
}: {
  monthlyPrice: number;
  daysPerWeek?: number;
}) => {
  return rateFormatter.format(priceAdjustedForDaysPerWeek({ monthlyPrice, daysPerWeek })).toString();
};

type DaysPerWeek = 1 | 2 | 3 | 4;

export const DAYS_PER_WEEK_MULTIPLIER: Record<DaysPerWeek, number> = {
  1: 1.23636363636364,
  2: 2.15873015873016,
  3: 3.09090909090909,
  4: 4
};

export const priceAdjustedForDaysPerWeek = ({
  monthlyPrice,
  daysPerWeek = 5,
  roundUp = true
}: {
  monthlyPrice: number;
  daysPerWeek?: number;
  roundUp?: boolean;
}) => {
  const daysAdjusted = Math.min(daysPerWeek || 5, 4);
  let price: number;

  switch (daysAdjusted) {
    case 1:
    case 2:
    case 3:
    case 4:
      price = (monthlyPrice / 4) * DAYS_PER_WEEK_MULTIPLIER[daysAdjusted];
      break;
    default:
      price = 0;
      break;
  }

  return !!roundUp ? Math.ceil(price) : price;
};
