import React from 'react';
import { ListingWorkspace } from '@codiwork/codi';
import GoogleMapReact, { ChildComponentProps } from 'google-map-react';

import { Icon, Layout, Link, Text } from 'ds';
import { LIGHT_MAP_STYLES } from 'ds/map/constants';

interface Props {
  workspace: ListingWorkspace;
}

const Location: React.FC<Props> = ({
  workspace: {
    address: { lat, lon, neighborhood, walk_score, postal_code }
  }
}) => {
  const mapCenter = { lat: lat, lng: lon };

  return (
    <>
      <Text size="h5" color="gray-900" scale>
        Location
      </Text>
      <Layout marginTop={24} />
      <Layout
        height={264}
        borderRadius={24}
        overflow="hidden"
        __style={{
          WebkitMaskImage: ' -webkit-radial-gradient(white, black)'
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API! }}
          defaultZoom={15}
          options={{ fullscreenControl: false, styles: LIGHT_MAP_STYLES }}
          center={mapCenter}
        >
          <MapMarker lat={mapCenter.lat} lng={mapCenter.lng} />
        </GoogleMapReact>
      </Layout>
      {walk_score && (
        <>
          <Layout marginTop={48}>
            <Text size="body2" semibold>
              Neighborhood: {neighborhood || postal_code}
            </Text>
          </Layout>
          <Link href={walk_score.link} color="gray-900" fullWidth>
            <Layout marginTop={12} align="center" justify="space-between">
              {walk_score.walk && (
                <Layout direction="column" align="center">
                  <Text size="body2">Walk Score®</Text>
                  <Layout marginY={8}>
                    <Icon name="walk" size="xl" />
                  </Layout>
                  <Layout align="baseline">
                    <Text size="body1" color="blue-500" semibold>
                      {walk_score.walk.score}
                    </Text>
                    <Layout marginLeft={4}>
                      <Text size="tag">/ 100</Text>
                    </Layout>
                  </Layout>
                  <Text size="tag" bold={false} notSemibold>
                    {walk_score.walk.description}
                  </Text>
                </Layout>
              )}
              {walk_score.transit && (
                <Layout direction="column" align="center">
                  <Text size="body2">Transit Score®</Text>
                  <Layout marginY={8}>
                    <Icon name="transportation" size="xl" />
                  </Layout>
                  <Layout align="baseline">
                    <Text size="body1" color="blue-500" semibold>
                      {walk_score.transit.score}
                    </Text>
                    <Layout marginLeft={4}>
                      <Text size="tag">/ 100</Text>
                    </Layout>
                  </Layout>
                  <Text size="tag" bold={false} notSemibold>
                    {walk_score.transit.description}
                  </Text>
                </Layout>
              )}
              {walk_score.bike && (
                <Layout direction="column" align="center">
                  <Text size="body2">Bike Score®</Text>
                  <Layout marginY={8}>
                    <Icon name="bike" size="xl" />
                  </Layout>
                  <Layout align="baseline">
                    <Text size="body1" color="blue-500" semibold>
                      {walk_score.bike.score}
                    </Text>
                    <Layout marginLeft={4}>
                      <Text size="tag">/ 100</Text>
                    </Layout>
                  </Layout>
                  <Text size="tag" bold={false} notSemibold>
                    {walk_score.bike.description}
                  </Text>
                </Layout>
              )}
            </Layout>
          </Link>
        </>
      )}
    </>
  );
};

interface MapMarkerProps extends ChildComponentProps {}

const MapMarker: React.FC<MapMarkerProps> = () => {
  return (
    <Layout __style={{ marginTop: -16 }}>
      <Icon size="xl" name="addressPin" color="blue-500" />
    </Layout>
  );
};

export default Location;
