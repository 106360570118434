import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthFlowType } from './types';

export const LOGOUT = 'Auth/Logout' as const;

type AuthState = {
  authFlowType: AuthFlowType;
};

export const AUTH_INITIAL_STATE: AuthState = {
  authFlowType: 'default'
};

const authSlice = createSlice({
  name: 'Auth',
  initialState: AUTH_INITIAL_STATE,
  reducers: {
    setAuthFlowType: (state, action: PayloadAction<AuthFlowType>) => {
      state.authFlowType = action.payload;
    },
    reset: () => AUTH_INITIAL_STATE
  }
});

export const { actions } = authSlice;

export default authSlice.reducer;
