import React from 'react';

import { Layout } from 'ds';
import { selectChatbotScreen } from 'store/Chatbot/selectors';
import { useAppSelector } from 'store/hooks';

import ChatbotBottomNavigationButton from './ChatbotBottomNavigationButton';
import { CHATBOT_BOTTOM_NAVIGATION_HEIGHT, CHATBOT_HOME_SCREEN, CHATBOT_THREADS_SCREEN } from './constants';

interface Props {}

const ChatbotBottomNavigation: React.FC<Props> = () => {
  const screen = useAppSelector(selectChatbotScreen);

  return (
    <Layout
      height={CHATBOT_BOTTOM_NAVIGATION_HEIGHT}
      align="center"
      flex
      color="white"
      borderBottomLeftRadius={8}
      borderBottomRightRadius={8}
    >
      <Layout equalWidth>
        <ChatbotBottomNavigationButton
          iconName="house"
          isActive={screen.value === CHATBOT_HOME_SCREEN}
          label="Home"
          screenValue={CHATBOT_HOME_SCREEN}
        />
      </Layout>
      <Layout equalWidth>
        <ChatbotBottomNavigationButton
          iconName="comment"
          isActive={screen.value.startsWith(CHATBOT_THREADS_SCREEN)}
          label="Messages"
          screenValue={CHATBOT_THREADS_SCREEN}
        />
      </Layout>
    </Layout>
  );
};

export default ChatbotBottomNavigation;
