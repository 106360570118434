import React from 'react';

import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const ClosingThoughts: React.FC<Props> = () => {
  return (
    <PlaybookContentContainer>
      <Layout justify="center" flexGrow={1}>
        <Layout maxWidth={876}>
          <Text size="h3" color="blue-gradient">
            Closing Thoughts
          </Text>
          <Layout marginTop={48}>
            <Text size="h6" color="gray-700" bold={false}>
              No matter what you’re building together, your team will perform best when the right environment is
              provided to them. Whether your staff is in every day or part-time, your office is home base for your
              biggest ideas and best moments.
              <br />
              <br />
              Today, there are more ways than ever to find and build that home base. There are a lot of factors to
              consider and a few pitfalls to sidestep, so we hope this playbook has offered some guidance as you get
              underway.
              <br />
              <br />
              Best of luck!
              <br />
              <br />— The Codi Team
            </Text>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default ClosingThoughts;
