import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ADD_PROPERTY_PATH } from 'routes';

import AddPropertyContact from './AddPropertyContact';
import AddPropertyStep0 from './AddPropertyStep0';

interface Props {}

const AddAProperty: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={`${ADD_PROPERTY_PATH}/0`} exact>
        <AddPropertyStep0 />
      </Route>
      <Route path={`${ADD_PROPERTY_PATH}/1`} exact>
        <AddPropertyContact />
      </Route>
      <Redirect to={`${ADD_PROPERTY_PATH}/0`} />
    </Switch>
  );
};

export default AddAProperty;
