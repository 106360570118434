import React, { useContext } from 'react';

import { app, media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const UnwantedNegotiation: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout flex direction={xs ? 'column' : 'row'}>
        <Layout maxWidth={xs ? contentWidth : 363} paddingTop={128}>
          <Text size="h3" color="blue-600">
            Breaking The Lease
          </Text>
          <Layout marginTop={24}>
            <Text size="h4" color="blue-500">
              The negotiation no one wants to have — “we gotta get out of here.”
            </Text>
          </Layout>
          <Layout marginTop={24}>
            <Text size="h6" color="gray-700" bold={false}>
              There’s one negotiation you’ll want to avoid altogether, but sometimes it’s inevitable. Circumstances
              change rapidly and a company needs to get out of their lease. Paul Simon said, “There are 50 ways to leave
              your lover,” but there are far fewer ways to leave your office — actually, just three.
            </Text>
          </Layout>
        </Layout>
        <Layout marginLeft={xs ? undefined : 56} paddingTop={48}>
          <Layout flex align="flex-start" paddingBottom={36} direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="trashcan" color="blue-600" size="xxl" />
              <Layout marginLeft={16} width={112}>
                <Text size="body2" color="blue-600" semibold>
                  OPTION 1
                </Text>
                <Text size="h6" color="blue-600">
                  Termination
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="body1" color="gray-700">
                Hopefully, your lease has a termination option built into it, and hopefully, you negotiated well as
                noted earlier. Pay the landlord, satisfy whatever other terms were agreed to, “drop off the key, Lee,”
                and you’re free.
              </Text>
            </Layout>
          </Layout>
          <Layout flex align="flex-start" paddingY={36} borderTop direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="trashcan" color="blue-600" size="xxl" />
              <Layout marginLeft={16} width={112}>
                <Text size="body2" color="blue-600" semibold>
                  OPTION 2
                </Text>
                <Text size="h6" color="blue-600">
                  Sublease
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="body1" color="gray-700">
                Again, hopefully, you’ve negotiated the ability to find a subletter to take over your lease. Work your
                network and find another company that would be happy taking your office and will be approved by your
                landlord. This can require considerable time — the process takes{' '}
                <Link
                  href="https://www.entrepreneur.com/growing-a-business/the-3-drawbacks-of-subleasing-an-office/270906#:~:text=The%20average%20deal%20usually%20lasts,because%20subleases%20require%20landlord%20consent"
                  size="body1"
                  semibold={false}
                  underline
                >
                  three months on average
                </Link>
                , all while you continue to pay your full rent. You may have to charge less than you’re paying to get
                someone to come in and you can expect to lose 10%–20% each month on the deal. But it’s better than being
                responsible for the entire rent.
              </Text>
            </Layout>
          </Layout>
          <Layout flex align="flex-start" paddingY={36} borderTop direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="trashcan" color="blue-600" size="xxl" />
              <Layout marginLeft={16} width={112}>
                <Text size="body2" color="blue-600" semibold>
                  OPTION 3
                </Text>
                <Text size="h6" color="blue-600">
                  Breaking Your Lease
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="body1" color="gray-700">
                The third, and riskiest, option is to simply break your lease. Remember, a lease is a binding legal
                document. So if you just pack up your stuff and vacate, your landlord has the right to come after you
                for every outstanding payment owed through the end of the lease. Still, you might have some options:
              </Text>
              <ul>
                <li>
                  <Text size="body1" color="gray-700">
                    <Text size="body1" color="gray-700" inline bold>
                      Breach of Contract
                    </Text>
                    {'  '}
                    If you’re leaving because of some unresolved issue with your space (or you can make a case for one),
                    you can claim “breach of contract” by the landlord. This must be a legitimate issue, however. You’ll
                    likely be asked to prove it in court.
                  </Text>
                </li>
                <li>
                  <Text size="body1" color="gray-700">
                    <Text size="body1" color="gray-700" inline bold>
                      Negotiate
                    </Text>
                    {'  '}
                    If you’re renting in a desirable district, especially if rents have risen recently, your landlord
                    may be willing to let you out of the lease before it’s up, assuming they can find another tenant
                    that would be willing to pay more than you. Expect to stay and keep paying for as long as that
                    process takes.
                  </Text>
                </li>
                <li>
                  <Text size="body1" color="gray-700">
                    <Text size="body1" color="gray-700" inline bold>
                      Lump Sum
                    </Text>
                    {'  '}
                    In some cases, you can offer to pay a lump sum of money to be let out of your lease. Your landlord
                    might be tempted by a large single payment now, rather than getting the full amount over time and
                    having to wait for it.
                  </Text>
                </li>
                <li>
                  <Text size="body1" color="gray-700">
                    <Text size="body1" color="gray-700" inline bold>
                      Bankruptcy
                    </Text>
                    {'  '}
                    this isn’t really a good option, but filing for bankruptcy can end your responsibilities to a
                    commercial landlord. Still, it can vary depending on state law.
                  </Text>
                </li>
              </ul>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default UnwantedNegotiation;
