import React, { useContext } from 'react';

import Head from 'components/Head';

import { app, media } from 'context';
import { Button, Img, Layout, MAX_CONTENT_WIDTH, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import Hero from './Hero';
import StepExplanation from './StepExplanation';
import Testimonials from './Testimonials';

interface Props {}

const HowItWorks: React.FC<Props> = () => {
  const { xs, sm, lg } = useContext(media);
  const { windowWidth, ...appContext } = useContext(app);
  const maxContentWidth = Math.min(appContext.contentWidth, MAX_CONTENT_WIDTH);

  return (
    <>
      <Head title="How it Works" />
      <Hero />
      <Layout marginTop={xs ? 48 : sm ? 64 : 96}>
        <StepExplanation
          step={1}
          supertitle="HOW IT WORKS"
          title="The simplest office solution"
          subtitle="Open an office in as little as 3 weeks"
          items={[
            {
              title: 'Request an office',
              subtitle:
                'Outline your ideal office budget, size and location and we’ll curate a list of workspaces for you.'
            },
            {
              title: 'Tour spaces',
              subtitle:
                'Explore our proposed solutions, pick the office that suits you best, and sign a flexible agreement directly with Codi.'
            },
            {
              title: 'Offload the logistics',
              subtitle:
                'You’ll be ready to work from the moment you step in the door as the entire move and setup it managed by the Codi team.'
            }
          ]}
          srcKey="4b161b2c8516b61a94673f448dac1a3c_NfYdZL9Fr.jpeg"
        />
      </Layout>
      <Layout marginTop={xs ? 48 : sm ? 64 : 96}>
        <StepExplanation
          step={2}
          supertitle="WHY FULL-TIME HQ"
          title="Expect more from your office"
          subtitle="Modern solutions for modern companies"
          items={[
            {
              title: 'Flexible terms',
              subtitle: 'Safeguard against long-term liability with flexible terms starting from only 6 months.'
            },
            {
              title: 'Fully managed',
              subtitle: 'All maintenance and office management is done by Codi, so you can remain focused on work.'
            },
            {
              title: 'Complete privacy',
              subtitle: 'Your entire office is always yours, allowing you to build your company’s culture from day one.'
            }
          ]}
          srcKey="cde1504b8e1491eefb749fb0ce9f8f6b_lK3TUoVav.jpeg"
        />
      </Layout>
      <Layout marginTop={xs ? 48 : sm ? 64 : 96}>
        <StepExplanation
          step={3}
          supertitle="BUILT FOR GROWTH"
          title="Make Codi a part of your tech stack"
          subtitle="As your team grows, so can your office"
          items={[
            {
              title: 'Easy expansion',
              subtitle:
                'Ready for more space? Or expanding to a new city? We’ll source your next space and manage the move in weeks not months.'
            },
            {
              title: 'Reallocate headcount',
              subtitle:
                'Bring on new resources that help drive your revenue and leave your office management up to our dedicated support team.'
            },
            {
              title: 'Support on demand',
              subtitle:
                'Don’t have something you need? No problem. Add new furniture, technology or services by simply contacting our dedicated team.'
            }
          ]}
          srcKey="ea69348ec2310d8fdea87192fece6a0f_f9c425p7Q.png"
        />
      </Layout>
      <Layout marginTop={xs ? undefined : sm ? 64 : 96}>
        <Testimonials />
      </Layout>
      <Layout
        position="relative"
        height={xs ? 200 : 542}
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)"
        left={-(windowWidth - maxContentWidth) / 2}
        width={windowWidth}
        justify="center"
      >
        <Img
          srcKey="how-it-works-footer_wAUxWArWU.png"
          folder="public"
          alt="office"
          width={xs ? 1000 : 3000}
          style={{
            height: xs ? 360 : 542,
            position: 'absolute',
            objectFit: 'cover',
            zIndex: -1
          }}
        />
        <Layout zIndex={1} justify="center" direction="column">
          <Text size={xs ? 'h5' : 'h2'} tag="h2" color="white">
            Step into your dream office
          </Text>
          <Layout marginTop={40} justify="center">
            <Button
              text="Request an office"
              iconStroke={2}
              size={lg ? 'lg' : 'md'}
              type="primaryInverse"
              href={OFFICE_REQUEST_PATH}
              fullWidth={xs}
            />
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default HowItWorks;
