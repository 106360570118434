import React, { CSSProperties, PropsWithChildren } from 'react';

type Props = {
  gap?: number;
  gapY?: number;
  gapX?: number;
} & ({ itemsPerRow: number; width?: never } | { itemsPerRow?: never; width: number }) &
  Pick<CSSProperties, 'alignItems'>;

const Grid: React.FC<PropsWithChildren<Props>> = ({ children, gap = 0, itemsPerRow, width, alignItems, ...props }) => {
  const gapX = typeof props.gapX === 'number' ? props.gapX : gap;
  const gapY = typeof props.gapY === 'number' ? props.gapY : gap;
  const columnWidth = itemsPerRow
    ? `calc(${100 / itemsPerRow}% - ${((itemsPerRow - 1) * gapX) / itemsPerRow}px)`
    : `${width}px`;
  const minmax = itemsPerRow ? `${columnWidth}, auto` : `${width}px, ${width}px`;

  return (
    <div
      style={{
        display: 'grid',
        columnGap: gapX,
        rowGap: gapY,
        gridTemplateColumns: `repeat(auto-fill, minmax(${minmax}))`,
        alignItems
      }}
    >
      {children}
    </div>
  );
};

export default Grid;
