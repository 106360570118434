import React, { PropsWithChildren } from 'react';

import { Layout, Text } from 'ds';

interface Props {
  isLastMessageInGroup: boolean;
}

const ChatbotThreadCustomerMessage: React.FC<PropsWithChildren<Props>> = ({ children, isLastMessageInGroup }) => {
  return (
    <Layout
      color="blue-500"
      paddingX={12}
      paddingY={12}
      borderBottomLeftRadius={16}
      borderTopLeftRadius={16}
      borderTopRightRadius={16}
      {...(isLastMessageInGroup ? {} : { borderBottomRightRadius: 16 })}
    >
      <Text size="body-sm" color="white">
        {children}
      </Text>
    </Layout>
  );
};

export default ChatbotThreadCustomerMessage;
