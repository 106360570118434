import React, { useRef, useState } from 'react';
import { IconName } from '@codiwork/codi';
import classNames from 'classnames';

import { useListFocus } from 'hooks';
import useFieldSize from 'hooks/useFieldSize';

import { IS_TOUCH_DEVICE } from './constants';
import { useOnClickOutside } from './helpers';
import useUniqueId from './helpers/useUniqueId';
import TextInput, { Props as TextInputProps } from './inputs/TextInput';
import { FieldSize } from './types';
import { highlightMatches } from './utils';

export interface Props<T extends string | number>
  extends Pick<
    TextInputProps,
    'onChange' | 'label' | 'size' | 'disabled' | 'onClear' | 'placeholder' | 'onFocus' | 'onBlur' | 'isReadOnly'
  > {
  results: Result<T>[];
  query?: string;
  onSelect: (result: Result<T>) => void;
  showResultsWhenEmpty?: boolean;
  maxItems?: number;
  highlightMatch?: boolean;
  required?: boolean;
  allowNewEntry?: boolean;
  hideLabel?: boolean;
  icon?: IconName;
  error?: string;
}

type ResultProps<T extends string | number> = Pick<Props<T>, 'results' | 'onSelect' | 'highlightMatch'>;

export interface Result<T extends string | number> {
  value: T;
  label: string;
}

const CLASSNAME = 'Typeahead';

const Typeahead = <T extends string | number>({
  label,
  hideLabel = false,
  query,
  onChange,
  onSelect,
  showResultsWhenEmpty = false,
  allowNewEntry = false,
  maxItems = 8,
  highlightMatch,
  disabled,
  onClear,
  placeholder,
  icon,
  required = false,
  error,
  onFocus,
  onBlur,
  isReadOnly,
  ...props
}: Props<T>) => {
  const scaledSize = useFieldSize();
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const size = props.size || scaledSize;
  const hasQuery = query && query.length > 0;
  let results = hasQuery ? props.results : showResultsWhenEmpty ? props.results : [];
  const queryDoesNotMatch = query && !results.map(({ label }) => label.toLowerCase()).includes(query.toLowerCase());

  if (allowNewEntry && query && queryDoesNotMatch) {
    results = [{ label: query, value: query as T }, ...results];
  }
  const [suppressValidation, setSuppressValidation] = useState<boolean>();
  const id = useUniqueId();

  const [focusedIndex] = useListFocus({
    divId: id,
    size: results.length,
    handleEnter: index => {
      const result = results[index];

      if (result) onSelect(result);

      setResultsVisible(false);
    }
  });

  useOnClickOutside(ref, () => {
    setResultsVisible(false);
  });

  return (
    <div
      id={id}
      className={classNames(CLASSNAME, `is-size-${size}`)}
      ref={ref}
      onBlur={() => {
        if (!IS_TOUCH_DEVICE) return;

        setTimeout(() => {
          setResultsVisible(false);
        }, 35);
      }}
    >
      <TextInput
        icon={icon}
        label={label}
        hideLabel={hideLabel}
        value={query}
        onChange={params => {
          onChange(params);
          if (!resultsVisible) {
            setResultsVisible(true);
          }
        }}
        isReadOnly={isReadOnly}
        size={size}
        onFocus={params => {
          setResultsVisible(true);
          onFocus && onFocus(params);
        }}
        onBlur={params => {
          onBlur && onBlur(params);
        }}
        disabled={disabled}
        onClear={onClear}
        placeholder={placeholder}
        required={required}
        suppressValidation={suppressValidation}
        onKeyDown={({ key }) => {
          if (key === 'Tab') {
            setResultsVisible(false);
          }
        }}
        // This is causing a bug ... why was it necessary?
        // onBlur={() => {
        //   if (allowNewEntry && query && queryDoesNotMatch) {
        //     onSelect({ label: query, value: query as T });
        //   }
        // }}
        error={error}
      />
      <ResultList
        results={results.slice(0, maxItems)}
        onSelect={result => {
          setSuppressValidation(false);
          onSelect(result);
          setResultsVisible(false);
        }}
        onMouseDown={() => {
          setSuppressValidation(true);
        }}
        size={size}
        highlightMatch={highlightMatch}
        query={query}
        focusedIndex={focusedIndex}
        isVisible={resultsVisible}
      />
    </div>
  );
};

interface ResultListProps<T extends string | number> extends ResultProps<T> {
  size: FieldSize;
  query?: string;
  onMouseDown: VoidFunction;
  focusedIndex: number;
  isVisible?: boolean;
}

const ResultList = <T extends string | number>({
  results,
  size,
  onSelect,
  onMouseDown,
  highlightMatch,
  query,
  focusedIndex,
  isVisible
}: ResultListProps<T>) => {
  return (
    <div className={classNames('Typeahead-results', { 'is-visible': isVisible })}>
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {results.map(({ label, value }, index) => (
          <ResultItem
            key={value}
            label={label}
            focused={index === focusedIndex}
            value={value}
            query={query}
            onSelect={onSelect}
            onMouseDown={onMouseDown}
            results={results}
            size={size}
            highlightMatch={highlightMatch}
          />
        ))}
      </ul>
    </div>
  );
};

interface ResultItemProps<T extends string | number> extends ResultProps<T>, Pick<Props<T>, 'size'>, Result<T> {
  size: FieldSize;
  focused: boolean;
  query?: string;
  onMouseDown: VoidFunction;
}

const ResultItem = <T extends string | number>({
  value,
  focused,
  onSelect,
  // NOTE (Mark): Using this function interferes with onClickOutside in Modals
  // onMouseDown,
  highlightMatch,
  query,
  label
}: ResultItemProps<T>) => {
  const displayedLabel = highlightMatch
    ? highlightMatches({
        label,
        query,
        MatchedWrapper: ({ children }) => <span className="Typeahead-matchedText">{children}</span>
      })
    : label;

  return (
    <li
      className={classNames('Typeahead-resultItem', { 'is-focused': focused })}
      key={value}
      onClick={() => {
        onSelect({ label, value });
      }}
      // onMouseDown={e => {
      //   onMouseDown();
      //   e.stopPropagation();
      // }}
    >
      <span className="Typeahead-resultText">{displayedLabel}</span>
    </li>
  );
};

export default Typeahead;
