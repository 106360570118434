import React from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

type Props = {
  active: boolean;
  type?: 'gold' | 'default';
} & (
  | { label: string; abbreviation?: never }
  | {
      label?: never;
      abbreviation: string;
    }
);

const CONFIG = {
  default: {
    active: {
      color: 'white',
      borderColor: 'gray-50',
      textColor: 'gray-900'
    },
    inactive: { borderColor: undefined, color: 'gray-25', textColor: 'gray-100' }
  },
  gold: {
    active: {
      color: 'gold-100',
      borderColor: 'gold-300',
      textColor: 'gold-900'
    },
    inactive: { borderColor: undefined, color: 'gray-25', textColor: 'gray-100' }
  }
} as const;

const DayOfWeekDisplay: React.FC<Props> = ({ active, abbreviation, label, type = 'default' }) => {
  const text = abbreviation || label;
  const config = CONFIG[type][active ? 'active' : 'inactive'];

  return (
    <Layout
      borderColor={config.borderColor}
      borderWidth={config.borderColor ? 1 : undefined}
      color={config.color}
      justify="center"
      align="center"
      {...(abbreviation ? { width: 30 } : { paddingX: 8 })}
      height={24}
      borderRadius={4}
    >
      <Text size="tag" color={config.textColor}>
        {text}
      </Text>
    </Layout>
  );
};

export default DayOfWeekDisplay;
