import React, { useContext, useRef } from 'react';

import { media } from 'context';
import { ContentBlock, Grid, Layout, Text } from 'ds';

import Video from './Video';

interface Props {}

const Videos: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const videoRef1 = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);
  const videoRef3 = useRef<HTMLVideoElement>(null);
  const videoRef4 = useRef<HTMLVideoElement>(null);
  const videoRefs = [videoRef1, videoRef2, videoRef3, videoRef4];
  const onClick = (id: number) => {
    videoRefs[id - 1].current?.play();

    videoRefs
      .filter((_, index) => index !== id - 1)
      .forEach(videoRef => {
        videoRef.current?.pause();
      });
  };

  return (
    <Layout color="blue-50">
      <ContentBlock maxWidth={1134} paddingTop={120} paddingBottom={xs ? 60 : 120}>
        <Text size={xs ? 'h5' : 'h3'} color="blue-700" align={xs ? undefined : 'center'}>
          See what{xs ? <br /> : ' '}
          <Text inline color="blue-500" align="center">
            clients and landlords
          </Text>
          <br />
          have to say about Codi
        </Text>
        <Layout marginTop={xs ? 60 : 72}>
          <Grid gapX={32} gapY={xs ? 32 : 50} itemsPerRow={xs ? 1 : 2}>
            <Video
              avatarSrcKey="che-eppo_QpJmRpSMH.jpeg"
              srcKey="chetan-testimonial-out_PowrBNetb.mp4"
              title="Che, CEO at Eppo"
              subtitle="Early-stage • Tech"
              location="San Francisco, CA"
              posterSrcKey="che-video-poster_qrFLyk_O2.png"
              videoRef={videoRef1}
              id={1}
              onClick={onClick}
            />
            <Video
              avatarSrcKey="chinmay-ridepanda_bFaaq-0xl.jpeg"
              srcKey="chinmay-ridepanda-testimonial-compressed_MAQEYrkbF.mp4"
              title="Chinmay, CEO at Ridepanda"
              subtitle="Early-stage • Hardware"
              location="New York City, NY"
              posterSrcKey="chinmay-video-poster_G_HHdbV5G.png"
              videoRef={videoRef2}
              id={2}
              onClick={onClick}
            />
            <Video
              avatarSrcKey="krista-coder_dZW0lx4Og.jpeg"
              srcKey="krista-coder-testimonial-compressed_RpKn_4hiz.mp4"
              title="Krista, Head of Operations at Coder"
              subtitle="Mid-sized • Tech"
              location="Austin, TX"
              posterSrcKey="krista-video-poster__KHgDPyje.png"
              videoRef={videoRef3}
              id={3}
              onClick={onClick}
            />
            <Video
              avatarSrcKey="ross-rde-advisors_HNp9pMoyf.png"
              srcKey="ross-broker-testimonial-compressed_nkjVyAIHd.mp4"
              title="Ross, CEO at RDE Advisors, Inc."
              subtitle="Landlord Representative"
              location="New York City, NY"
              posterSrcKey="ross-video-poster_gaDWFqUIp.png"
              videoRef={videoRef4}
              id={4}
              onClick={onClick}
            />
          </Grid>
        </Layout>
      </ContentBlock>
    </Layout>
  );
};

export default Videos;
