import React from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

interface Props {
  count: number;
}

const MenuCount: React.FC<Props> = ({ count }) => {
  return (
    <Layout color="gray-25" paddingX={4} paddingY={2} borderRadius={4} minWidth={20} justify="center">
      <Text size="tag">{count}</Text>
    </Layout>
  );
};

export default MenuCount;
