import React, { useContext } from 'react';
import { A11y, EffectFade, Virtual } from 'swiper';

import { app, media } from 'context';
import { Grid, Layout, Text } from 'ds';
import { NO_SWIPING_CLASS } from 'ds/SwiperCarousel';
import { Swiper, SwiperSlide as Slide } from 'swiper/react';

import BrowseLocation from './BrowseLocation';

interface Props {}

const LOCATIONS = [
  {
    location: 'New York',
    search: 'New York, NY',
    srcKey: 'new-york-location_swk-WM56A.jpeg'
  },
  {
    location: 'San Francisco',
    search: 'San Francisco, CA',
    srcKey: 'san-francisco-location_mhkUuSZVo.jpeg'
  },
  {
    location: 'Los Angeles',
    search: 'Los Angeles, CA',
    srcKey: 'broker-los-angeles_zH6EkBzMw.jpeg'
  }
];

const Browse: React.FC<Props> = () => {
  const { isMobile, xs, md, lg } = useContext(media);
  const { contentPaddingX, windowWidth } = useContext(app);
  const isMouse = md || lg;

  return (
    <Layout>
      <Layout {...(isMobile ? { paddingX: contentPaddingX } : {})}>
        <Text
          size={isMobile ? 'headline-lg' : 'display-sm'}
          color="blue-500"
          {...(isMobile ? {} : { align: 'center' })}
        >
          Browse office spaces
        </Text>
        <Layout marginTop={32}>
          <Text size="body-md" color="gray-400" {...(isMobile ? {} : { align: 'center' })}>
            Find, tour, and move into your fully serviced office in as little as 3 weeks.
          </Text>
        </Layout>
      </Layout>
      {isMobile || md ? (
        <Layout width={windowWidth} marginTop={32} position="relative" left={xs ? undefined : -contentPaddingX}>
          <Swiper
            slidesPerView={md ? 1.6 : 1.2}
            spaceBetween={md ? 20 : 12}
            style={{ paddingLeft: contentPaddingX, paddingRight: contentPaddingX }}
            modules={[A11y, Virtual, EffectFade]}
            noSwiping={isMouse}
            noSwipingClass={NO_SWIPING_CLASS}
          >
            {LOCATIONS.map(({ location, srcKey, search }) => {
              return (
                <Slide key={location}>
                  <BrowseLocation location={location} srcKey={srcKey} search={search} />
                </Slide>
              );
            })}
          </Swiper>
        </Layout>
      ) : (
        <Layout marginTop={32}>
          <Grid itemsPerRow={3} gapX={24}>
            {LOCATIONS.map(({ location, srcKey, search }) => {
              return <BrowseLocation key={location} location={location} srcKey={srcKey} search={search} />;
            })}
          </Grid>
        </Layout>
      )}
    </Layout>
  );
};

export default Browse;
