import { ChangeEvent, FocusEvent, MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { ReactComponent as ClearSvg } from '@codiwork/codi/lib/icons/Clear.svg';
import classNames from 'classnames';

import Img from 'ds/Img';
import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import { BaseProps, InputImageIconProps, InputProps, InputType } from './types';
import Error from '../Error';
import Icon from '../Icon';
import { DS_CLASS_NAME, FIELD_CONFIG, INPUT_ERROR_BORDER_WIDTH } from '../constants';
import useUniqueId from '../helpers/useUniqueId';
import { ReactInputProps } from '../types';

export type Props = BaseProps &
  InputProps &
  InputImageIconProps &
  Pick<ReactInputProps, 'onKeyDown' | 'inputMode' | 'min' | 'max' | 'step' | 'autoComplete'> & {
    type?: InputType;
    prefix?: JSX.Element | string;
    suffix?: string;
    // TODO: type conditionally with presence of prefix?
    prefixWidth?: number;
    /** Used only to add CSS class, does not mean <input /> element is actually focused. */
    hasFocusClass?: boolean;
  };

const INNER_PADDING_X_WITH_ICON = {
  xxs: 31,
  xs: 35,
  sm: 48,
  md: 56,
  lg: 64
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      name = '',
      value,
      placeholder,
      label,
      bigLabel,
      onChange,
      icon,
      type = 'text',
      prefix,
      suffix,
      required,
      disabled,
      autoFocus,
      tabIndex,
      error,
      showErrorMessage = true,
      onBlur,
      onFocus,
      onClear,
      hasFocusClass = false,
      prefixWidth,
      step,
      min,
      max,
      onKeyDown,
      inputMode,
      autoComplete = 'off',
      hasErrorHeight = false,
      image,
      isReadOnly,
      ...props
    },
    ref
  ) => {
    const id = useUniqueId();
    const hasValue = !!value && value.length > 0;
    const hasError = !!error;
    const scaledSize = useFieldSize();
    const size = props.size || scaledSize;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || localRef;
    const [textPrefixWidth, setTextPrefixWidth] = useState<number>(0);
    const [suffixWidth, setSuffixWidth] = useState<number>(0);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const textPrefixRef = useRef<HTMLSpanElement>(null);
    const suffixRef = useRef<HTMLSpanElement>(null);
    const hasTextPrefix = typeof prefix === 'string';
    const textPrefixValue = hasTextPrefix ? prefix : '';
    const prefixWidthToUse = hasTextPrefix ? textPrefixWidth : prefix && prefixWidth ? prefixWidth : undefined;
    const prefixSuffixBasePadding =
      FIELD_CONFIG[size].fieldPaddingX - (hasError ? INPUT_ERROR_BORDER_WIDTH : INPUT_ERROR_BORDER_WIDTH) + 4;
    const paddingLeft = prefixWidthToUse
      ? prefixWidthToUse + (isFocused ? -1 : 0) + (!!icon ? INNER_PADDING_X_WITH_ICON[size] : prefixSuffixBasePadding)
      : undefined;
    const paddingRight = suffixWidth ? suffixWidth + prefixSuffixBasePadding : undefined;
    const hideLabel = props.hideLabel || size === 'xxs';
    const hasBigLabel = !(hasValue || hasFocusClass);

    useEffect(() => {
      const prefix = textPrefixRef.current;
      const suffix = suffixRef.current;

      if (prefix) {
        setTextPrefixWidth(prefix.getBoundingClientRect().width);
      }

      if (suffix) {
        setSuffixWidth(suffix.getBoundingClientRect().width);
      }
    }, [suffix, textPrefixValue]);

    return (
      <div>
        <div
          className={classNames(DS_CLASS_NAME, 'Input', `is-size-${size}`, {
            'has-error': hasError,
            'is-disabled': disabled,
            'has-value': hasValue,
            'is-read-only': isReadOnly
          })}
        >
          <input
            id={id}
            ref={inputRef}
            name={name}
            className={classNames(DS_CLASS_NAME, 'Input-input', {
              'has-icon': !!icon,
              'has-image': !!image,
              'has-prefix': !!prefix,
              'has-error': !!error,
              'is-disabled': disabled,
              'has-clear-button': !!onClear,
              'has-value': hasValue,
              'is-focused': hasFocusClass,
              'has-small-label': !hideLabel
            })}
            value={value || ''}
            placeholder={placeholder}
            autoComplete={autoComplete}
            type={type}
            onKeyDown={onKeyDown}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              /** Trim value if user is pasting */
              const updatedValue =
                e.target.value.length - (value?.length || 0) > 2 ? e.target.value.trim() : e.target.value;

              onChange({ event: e, value: updatedValue });
            }}
            aria-label={hideLabel ? label : undefined}
            disabled={disabled || isReadOnly}
            autoFocus={autoFocus}
            tabIndex={tabIndex}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              track('Element Interacted', {
                type: 'input',
                name,
                value: e.target.value || undefined,
                action: 'onBlur'
              });
              setIsFocused(false);
              onBlur && onBlur({ event: e, value: e.target.value });
            }}
            onFocus={(e: FocusEvent<HTMLInputElement>) => {
              track('Element Interacted', {
                type: 'input',
                name,
                value: e.target.value || undefined,
                action: 'onFocus'
              });
              onFocus && onFocus({ event: e, value: e.target.value });
              setIsFocused(true);

              const input = (inputRef as MutableRefObject<HTMLInputElement>).current;

              if (value && input) {
                input.select();
              }
            }}
            style={{
              paddingLeft,
              paddingRight
            }}
            step={step}
            min={min}
            max={max}
            inputMode={inputMode}
          />
          {hasTextPrefix ? (
            <span ref={textPrefixRef} className="Input-textPrefix">
              {prefix}
            </span>
          ) : !!prefix ? (
            <span className={classNames(DS_CLASS_NAME, 'Input-prefix')}>{prefix}</span>
          ) : null}
          {image && (
            <span className="Input-image">
              {image.srcKey ? (
                <Img {...image} srcKey={image.srcKey} width={128} />
              ) : (
                <img src={image.src} alt={image.alt} />
              )}
            </span>
          )}
          {!!icon && (
            <span className="Input-icon">
              <Icon name={icon} size={size} color={error ? 'gray-900' : hasFocusClass ? 'blue-500' : 'gray-900'} />
            </span>
          )}
          {suffix && (
            <span className="Input-suffix" ref={suffixRef}>
              {suffix}
            </span>
          )}
          <label className={classNames('Input-label')} htmlFor={id}>
            {hasBigLabel ? bigLabel || label : label}
            {required ? ' *' : ''}
          </label>
          {!(disabled || isReadOnly) && onClear && !!value && (
            <button
              onClick={() => {
                track('Element Interacted', { type: 'input', name, value, action: 'clear' });
                const input = (inputRef as MutableRefObject<HTMLInputElement>).current;
                onClear();

                if (input) {
                  input.focus();
                }
              }}
              className="Input-closeButton"
            >
              <span className={classNames('Icon', 'Input-closeSvg', 'is-color-gray-200')}>
                <ClearSvg />
              </span>
            </button>
          )}
        </div>
        {((!!error && showErrorMessage) || hasErrorHeight) && <Error error={error} inputSize={size} />}
      </div>
    );
  }
);

export default Input;
