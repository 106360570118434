import React from 'react';

import { ButterImg, Layout, Link, Text } from 'ds';
import { NavigationCtaSection } from 'lib/butter/types';

interface Props extends NavigationCtaSection {
  onImageLoad: VoidFunction;
  closeMenu: VoidFunction;
}

const CtaSection: React.FC<Props> = ({ title, image, subtitle, cta_text, cta_url, closeMenu, onImageLoad, blob }) => {
  return (
    <Layout>
      <Text size="body3" bold>
        {title}
      </Text>
      <Layout marginTop={32}>
        <Layout position="relative" display="inline-flex">
          <ButterImg
            src={image.src}
            alt={image.alt}
            width={200}
            style={{
              borderRadius: image.circular ? '50%' : 12,
              boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
              width: 100,
              marginLeft: 24
            }}
            onLoad={onImageLoad}
          />
          <span
            dangerouslySetInnerHTML={{ __html: blob.svg }}
            style={{ zIndex: -1, position: 'absolute', left: 0, top: blob.top }}
          />
        </Layout>
        <Layout marginTop={20}>
          <Text size="body3">{subtitle}</Text>
        </Layout>
        <Layout marginTop={16}>
          <Link
            href={cta_url}
            target={cta_url.startsWith('http') ? '_blank' : undefined}
            size="body2"
            color="black"
            onClick={closeMenu}
            opacity={false}
          >
            {cta_text}
          </Link>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CtaSection;
