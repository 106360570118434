import React from 'react';
import { titleize } from '@codiwork/codi';

import { SupportTicketStatus } from 'ux/Customer/Support/types';

import Layout from './Layout';
import StatusDisplay from './StatusDisplay';
import { CELL_PADDING } from './Table/constants';
import { CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR, CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR } from './constants';

interface Props {
  status: SupportTicketStatus;
  includeCaret?: boolean;
}

const CustomerSupportTicketStatusCell: React.FC<Props> = ({ status, includeCaret = false }) => {
  return (
    <Layout padding={CELL_PADDING}>
      <StatusDisplay
        color={CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR[status]}
        dotColor={CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR[status]}
        textColor={CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR[status]}
        text={titleize(status)}
        includeCaret={includeCaret}
      />
    </Layout>
  );
};

export default CustomerSupportTicketStatusCell;
