import React, { useContext } from 'react';

import { media } from 'context';
import { Button, Layout, Link, Text } from 'ds';
import { OFFICE_REQUEST_PATH, ROOT_PATH } from 'routes';
import { actions } from 'store/OfficeRequest';
import {
  selectLaunchPath,
  selectOfficeRequestLocations,
  selectOfficeRequestMarket
} from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { makeExitPath } from './utils';

interface Props {}

const ExitModalCTA: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { xs } = useContext(media);
  const launchPath = useAppSelector(selectLaunchPath);
  const exitPath = launchPath?.startsWith(OFFICE_REQUEST_PATH) ? ROOT_PATH : launchPath || ROOT_PATH;
  const locations = useAppSelector(selectOfficeRequestLocations);
  const market = useAppSelector(selectOfficeRequestMarket);

  return (
    <>
      <Layout flex={!xs}>
        <Button
          text="Browse offices"
          size={xs ? 'sm' : 'md'}
          type="primary"
          {...makeExitPath(market, locations)}
          fullWidth={xs}
          textSize={xs ? 'body2' : undefined}
        />
        <Layout {...(xs ? { marginTop: 12 } : { marginLeft: 12 })} marginLeft={xs ? undefined : 12}>
          <Button
            text="Continue request"
            size={xs ? 'sm' : 'md'}
            type="secondary"
            onClick={() => dispatch(actions.setExitModalIsVisible(false))}
            fullWidth={xs}
            textSize={xs ? 'body2' : undefined}
          />
        </Layout>
      </Layout>
      <Layout {...(xs ? { justify: 'center', align: 'center', marginTop: 12, height: 48 } : {})}>
        <Link href={exitPath} onClick={() => dispatch(actions.reset())} fullWidth>
          <Text size={xs ? 'body2' : 'body1'} semibold color="black" align="center">
            Exit without saving
          </Text>
        </Link>
      </Layout>
    </>
  );
};

export default ExitModalCTA;
