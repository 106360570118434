import React, { useRef } from 'react';
import { ReactComponent as Sparkle } from '@codiwork/codi/lib/icons/Sparkle.svg';
import classNames from 'classnames';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { track } from 'lib/analytics';

interface Props {
  onClick: VoidFunction;
  monthly_price: number;
  daysPerWeek: number;
  id: number;
  isActive: boolean;
  context?: string;
  isRecommended?: boolean;
}

const OfficeSearchMarker: React.FC<Props> = ({
  onClick,
  monthly_price,
  daysPerWeek,
  id,
  context,
  isActive,
  isRecommended
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const price = formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price, daysPerWeek });

  return (
    <button
      ref={buttonRef}
      onClick={() => {
        onClick();
        track('Element Interacted', {
          type: 'button',
          name: 'price marker',
          action: 'select',
          value: id,
          context
        });
      }}
      className={classNames('OfficeSearchMarker', { 'is-active': isActive, 'is-recommended': isRecommended })}
    >
      {isRecommended && (
        <span style={{ width: 16, height: 16 }}>
          <Sparkle />
        </span>
      )}
      <Layout marginLeft={isRecommended ? 8 : undefined} __className="OfficeSearchMarker-text">
        <Text size="body-sm" semibold>
          {price}
        </Text>
      </Layout>
    </button>
  );
};

export default OfficeSearchMarker;
