import React, { useContext } from 'react';

import { media } from 'context';
import { Grid, Icon, Layout, Text } from 'ds';

interface Props {}

const WhatsIncluded: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <Layout>
      <Text size={xs ? 'h6' : 'h5'}>What’s included</Text>
      <Layout marginTop={24} {...(xs ? {} : { color: 'blue-25', borderRadius: 8, paddingX: 24, paddingY: 32 })}>
        <Grid itemsPerRow={xs ? 1 : 2} gapY={16} gapX={xs ? undefined : 40}>
          <Layout flex>
            <Icon name="settings" size="sm" />
            <Layout marginLeft={12}>
              <Text size="body2" semibold>
                Rent
              </Text>
              <Layout marginTop={4}>
                <Text size="body2" color="gray-700">
                  Fully serviced; includes monthly rent and maintenance
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout flex>
            <Icon name="wifi" size="sm" />
            <Layout marginLeft={12}>
              <Text size="body2" semibold>
                Fast WiFi
              </Text>
              <Layout marginTop={4}>
                <Text size="body2" color="gray-700">
                  Be connected from day one and know that we’re only one call away
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout flex>
            <Icon name="chair" size="sm" />
            <Layout marginLeft={12}>
              <Text size="body2" semibold>
                Furniture
              </Text>
              <Layout marginTop={4}>
                <Text size="body2" color="gray-700">
                  Ergonomic workstations, lounges, and whiteboards are ready for use
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout flex>
            <Icon name="beverage" size="sm" />
            <Layout marginLeft={12}>
              <Text size="body2" semibold>
                Beverages
              </Text>
              <Layout marginTop={4}>
                <Text size="body2" color="gray-700">
                  Tea, coffee, and sparkling water, restocked monthly
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout flex>
            <Icon name="cleaning" size="sm" />
            <Layout marginLeft={12}>
              <Text size="body2" semibold>
                Cleaning
              </Text>
              <Layout marginTop={4}>
                <Text size="body2" color="gray-700">
                  Weekly cleaning, maintenance, and add-ons.
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout flex>
            <Icon name="clipboard" size="sm" />
            <Layout marginLeft={12}>
              <Text size="body2" semibold>
                Office management
              </Text>
              <Layout marginTop={4}>
                <Text size="body2" color="gray-700">
                  Fractional office management with on-site support
                </Text>
              </Layout>
            </Layout>
          </Layout>
        </Grid>
      </Layout>
    </Layout>
  );
};

export default WhatsIncluded;
