import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthResponseData, sendCode } from '@codiwork/codi';
import qs from 'qs';

import { logout, setToken } from 'helpers/auth';
import { isProduction } from 'helpers/environment';

import { Layout } from 'ds';
import { identify } from 'lib/analytics';
import { actions } from 'store/User';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Verify from 'ux/Auth/Verify';
import FourZeroFour from 'ux/Page/404';

interface Props {}

const QALink: React.FC<Props> = () => {
  const isProd = isProduction();
  const history = useHistory();
  const user = useAppSelector(selectUser);
  const { search } = useLocation();
  const { email, path, search: qsSearch } = qs.parse(search, { ignoreQueryPrefix: true });
  const dispatch = useAppDispatch();

  const handleSuccess = ({ token, user }: AuthResponseData) => {
    if (!user) {
      console.error('No user found');
      return;
    }

    setToken(token);
    identify(user);
    dispatch(actions.setUser(user));
    history.push({ pathname: path as string, search: qsSearch as string });
  };

  useEffect(() => {
    if (isProd) return;

    if (user) {
      logout({ history });
    }

    sendCode({ email: email as string });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isProd) {
    return <FourZeroFour />;
  }

  return (
    <Layout justify="center" marginTop={200}>
      <Verify email={email as string} handleVerificationSuccess={handleSuccess} />
    </Layout>
  );
};

export default QALink;
