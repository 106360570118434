import { CustomerTableRow } from './types';

export function defaultAccessor<T extends CustomerTableRow>(row: T, key: keyof T | string): string {
  const value = row[key as keyof T];

  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (value === null) {
    return '';
  } else {
    try {
      return ((value as unknown) as any).toString();
    } catch {
      return '';
    }
  }
}
