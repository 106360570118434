import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, Button, FullWidthBackground, HEADER_MAX_WIDTH, Icon, Layout, Text } from 'ds';

interface Props {
  setIsModalVisible: (isVisible: boolean) => void;
}

const Included: React.FC<Props> = ({ setIsModalVisible }) => {
  const { xs, sm } = useContext(media);

  const columnLayout = xs || sm;

  return (
    <Layout {...(xs ? {} : { marginTop: 92 })}>
      <FullWidthBackground paddingY={100} color="blue-50">
        <Layout align="center" direction="column" {...(columnLayout ? {} : { maxWidth: HEADER_MAX_WIDTH })}>
          <Animate>
            <Text size={xs ? 'h5' : 'h4'} color="blue-600" align="center" scale={!xs}>
              Included in the report
            </Text>
          </Animate>
          <Layout
            marginY={44}
            {...(columnLayout
              ? { direction: 'column', align: 'center', width: '100%' }
              : { direction: 'row', maxWidth: 992, justify: 'space-between', width: '100%' })}
          >
            <Layout
              direction="column"
              padding={40}
              color="white"
              borderRadius={24}
              boxShadow="0px 13px 23px rgba(0, 0, 0, 0.05)"
              align="center"
              {...(columnLayout ? { width: '100%' } : { width: '33%' })}
            >
              <Layout {...(columnLayout ? { width: 44, height: 44 } : { width: 56, height: 56 })}>
                <Icon size={columnLayout ? 44 : 56} name="desk" color="blue-gradient" />
              </Layout>
              <Layout marginTop={24} />
              <Text size="h6" scale={true} color="blue-500" align="center">
                Top 5 tips for choosing
                <br />
                the right office space
                <br />
                in a recession
              </Text>
            </Layout>
            <Layout
              direction="column"
              padding={40}
              color="white"
              borderRadius={24}
              boxShadow="0px 13px 23px rgba(0, 0, 0, 0.05)"
              align="center"
              {...(columnLayout ? { width: '100%', marginY: 24 } : { width: '33%', marginX: 24 })}
            >
              <Layout {...(columnLayout ? { width: 44, height: 44 } : { width: 56, height: 56 })}>
                <Icon size={columnLayout ? 44 : 56} name="page" color="blue-gradient" />
              </Layout>
              <Layout marginTop={24} />
              <Text size="h6" scale={true} color="blue-500" align="center">
                Truth about long-term
                <br />
                office leases
              </Text>
            </Layout>
            <Layout
              direction="column"
              padding={40}
              color="white"
              borderRadius={24}
              boxShadow="0px 13px 23px rgba(0, 0, 0, 0.05)"
              align="center"
              {...(columnLayout ? { width: '100%' } : { width: '33%' })}
            >
              <Layout {...(columnLayout ? { width: 44, height: 44 } : { width: 56, height: 56 })}>
                <Icon size={columnLayout ? 44 : 56} name="settings" color="blue-gradient" />
              </Layout>
              <Layout marginTop={24} />
              <Text size="h6" scale={true} color="blue-500" align="center">
                Strategies to increase
                <br />
                employee retention
              </Text>
            </Layout>
          </Layout>
          <Button
            size="lg"
            type="primary"
            text="Download report"
            icon="download"
            href=""
            iconPosition="left"
            iconSize="md"
            iconStroke={1.5}
            onClick={() => setIsModalVisible(true)}
          />
        </Layout>
      </FullWidthBackground>
    </Layout>
  );
};

export default Included;
