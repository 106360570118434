import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import ContactForm from 'components/ContactForm';

import { app, media } from 'context';
import { ContentContainer, Grid, Layout, Link, Text } from 'ds';
import { FooterPage } from 'lib/butter/types';
import { PRIVACY_PATH, SEARCH_PATH, SITEMAP_PATH, TERMS_PATH } from 'routes';
import { selectCmsPage } from 'store/App/selectors';
import { selectIsCustomerUser, selectUser, selectUserCalendar } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { calendarHasNoAvailability } from 'ux/Calendar/utils';

import FooterAppStoreSocialLinks from './FooterAppStoreSocialLinks';
import FooterEmailSubscription from './FooterEmailSubscription';
import LocationsFooterSection from './LocationsFooterSection';

interface Props {
  hasMargin?: boolean;
}

const LINK_MARGIN_Y = 12;
const TRACK_CONTEXT = 'footer';
const GLOBAL_VARIABLE_PATH = 'window.codi.butterVariables';

const Footer: React.FC<Props> = ({ hasMargin }) => {
  const { xs, sm, md, isMobile } = useContext(media);
  const user = useSelector(selectUser);
  const userCalendar = useSelector(selectUserCalendar);
  const userIsCustomer = useSelector(selectIsCustomerUser);
  const { footerMarginTop } = useContext(app);
  const { pathname } = useLocation();
  const page = useAppSelector(s => selectCmsPage(s, 'footer'));
  const [isContactFormVisible, setIsContactFormVisible] = useState<boolean>(false);

  const history = useHistory();
  const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const [isSupportFormVisible, setIsSupportFormVisible] = useState<boolean>(queryString.support !== undefined);

  const isSearchPage = pathname === SEARCH_PATH;

  if (!page || (isSearchPage && (xs || sm || md))) return null;

  window.codi.butterVariables.showFindWorkspaceLink =
    !!user && userIsCustomer && !!userCalendar && !calendarHasNoAvailability(userCalendar);

  const evalOrFail = (_variableName: string) => {
    try {
      // eslint-disable-next-line no-new-func
      const fn = Function(`return ${GLOBAL_VARIABLE_PATH}.${_variableName};`);
      return fn();
    } catch (e) {
      return false;
    }
  };

  const { sections } = (page as FooterPage).fields;

  return (
    <>
      <ContactForm isVisible={isContactFormVisible} onCloseModal={() => setIsContactFormVisible(false)} inbox="hello" />
      <ContactForm
        isVisible={isSupportFormVisible}
        onCloseModal={() => setIsSupportFormVisible(false)}
        inbox="support"
      />
      <Layout
        color="blue-700"
        marginTop={hasMargin ? footerMarginTop : undefined}
        position="relative"
        {...(xs ? { paddingTop: 36, paddingBottom: 44 } : { paddingY: 48 })}
      >
        <ContentContainer minHeight={0}>
          <FooterEmailSubscription />
          <Layout direction={xs ? 'column' : 'row'} wrap={sm}>
            {sections.map((section, index) => {
              const { section_title, links } = section.fields;
              return (
                <Layout
                  width={xs ? undefined : `${100 / (sections.length + (sm ? 0 : 1))}%`}
                  key={section_title}
                  marginTop={xs && index > 0 ? 36 : undefined}
                >
                  <Layout>
                    <Layout marginBottom={LINK_MARGIN_Y}>
                      <Text size="h5" color="white" scale>
                        {section_title}
                      </Text>
                    </Layout>
                    <Grid gapY={isMobile ? 24 : 20} itemsPerRow={xs ? 2 : 1}>
                      {links.map(link => {
                        const { text, href, condition } = link;

                        if (!!condition && !evalOrFail(condition)) return null;

                        if (text === 'Contact Us') {
                          return (
                            <Layout key={text}>
                              <Link
                                onClick={() => setIsContactFormVisible(true)}
                                context={TRACK_CONTEXT}
                                semibold={false}
                                color="white"
                                text={
                                  <Text size="body1" scale>
                                    {text}
                                  </Text>
                                }
                              />
                            </Layout>
                          );
                        }

                        return (
                          <Layout key={text}>
                            <Link
                              href={href}
                              context={TRACK_CONTEXT}
                              semibold={false}
                              color="white"
                              size="body1"
                              target={href.startsWith('http') ? '_blank' : undefined}
                              text={
                                <Text size="body1" scale>
                                  {text}
                                </Text>
                              }
                            />
                          </Layout>
                        );
                      })}
                    </Grid>
                  </Layout>
                </Layout>
              );
            })}
            <Layout width={xs || sm ? undefined : `${100 / (sections.length + 1)}%`}>
              <FooterAppStoreSocialLinks />
            </Layout>
          </Layout>
        </ContentContainer>
      </Layout>
      <Layout color="blue-800">
        <ContentContainer minHeight={0}>
          <LocationsFooterSection />
          <Layout paddingBottom={isMobile ? 44 : 48}>
            <Layout>
              <Text size="body1" color="white" scale>
                &copy; {new Date().getFullYear()} Codi. All rights reserved.
              </Text>
            </Layout>
            <Layout direction="row" align="center" marginTop={4}>
              <Link href={PRIVACY_PATH} context={TRACK_CONTEXT} color="white">
                <span style={{ fontSize: '14px' }}>Privacy</span>
              </Link>
              <Layout marginX={8}></Layout>
              <Link href={TERMS_PATH} context={TRACK_CONTEXT} color="white">
                <span style={{ fontSize: '14px' }}>Terms</span>
              </Link>
              <Layout marginX={8}></Layout>
              <Link href={SITEMAP_PATH} context={TRACK_CONTEXT} color="white">
                <span style={{ fontSize: '14px' }}>Sitemap</span>
              </Link>
            </Layout>
          </Layout>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default Footer;
