import React from 'react';
import { useLocation } from 'react-router-dom';

import Layout from 'ds/Layout';
import Link from 'ds/Link';
import Text from 'ds/Text';
import { HEADER_Z_INDEX, TERTIARY_MENU_WIDTH } from 'ds/constants';
import { MenuItem } from 'ds/types';
import { isNavigationItemActive } from 'ux/Layouts/Shared/utils';

import MenuCount from './MenuCount';
import { APP_PAGE_NAV_BAR_HEIGHT, APP_PAGE_PADDING_TOP } from './constants';

export interface Props {
  items: MenuItem[];
}

const TertiaryMenu: React.FC<Props> = ({ items }) => {
  const { pathname } = useLocation();

  return (
    <Layout
      zIndex={HEADER_Z_INDEX - 2}
      position="sticky"
      display="inline-flex"
      __style={{ verticalAlign: 'top' }}
      direction="column"
      width={TERTIARY_MENU_WIDTH}
      height={`calc(100vh - ${APP_PAGE_NAV_BAR_HEIGHT + APP_PAGE_PADDING_TOP}px)`}
      top={APP_PAGE_NAV_BAR_HEIGHT + APP_PAGE_PADDING_TOP}
      flexShrink={0}
    >
      {items.map(({ label, count, href, matchHrefs, exact }, index) => {
        const isActive = isNavigationItemActive({ href, matchHrefs, exact, pathname });
        const isFirst = index === 0;
        return (
          <Layout key={href} marginTop={isFirst ? undefined : 4}>
            <Link href={href} opacity={false} display="block" color="gray-900">
              <Layout
                align="center"
                justify="space-between"
                height={36}
                paddingX={16}
                hoverColor="gray-25"
                borderRadius={4}
              >
                <Text size="body3" semibold={isActive}>
                  {label}
                </Text>
                {typeof count === 'number' && <MenuCount count={count} />}
              </Layout>
            </Link>
          </Layout>
        );
      })}
    </Layout>
  );
};

export default TertiaryMenu;
