import React, { useContext } from 'react';

import { app, media } from 'context';
import { Button, FullWidthBackground, Img, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {}

const Hero: React.FC<Props> = () => {
  const { xs, sm, md, lg, xl, xxl } = useContext(media);
  const { windowWidth } = useContext(app);

  return (
    <FullWidthBackground
      color="blue-25"
      {...(xs ? { paddingTop: 0, paddingBottom: 48 } : md ? { paddingY: 64 } : { paddingY: 96 })}
    >
      <Layout align="center" {...(xs ? { direction: 'column-reverse' } : {})}>
        <Layout width={xxl ? 608 : xl ? 492 : undefined} equalWidth={lg || md || sm} marginTop={xs ? 48 : undefined}>
          <Text size={sm || md ? 'body2' : 'body1'} color="blue-700" semibold>
            FULL-TIME HQ
          </Text>
          <Layout marginTop={12}>
            <Text size={lg ? 'h3' : 'h4'} color="blue-700">
              Private office, flexible lease
            </Text>
          </Layout>
          <Layout marginTop={12}>
            <Text size="h5" scale bold={false}>
              Opening and managing your dream office is now easier than ever before
            </Text>
          </Layout>
          <Layout marginTop={40}>
            <Button
              text="Request an office"
              icon="rightArrow"
              iconStroke={2}
              size={lg ? 'lg' : 'md'}
              type="primary"
              href={OFFICE_REQUEST_PATH}
              fullWidth={xs}
            />
          </Layout>
        </Layout>
        <Layout equalWidth={lg || md || sm} marginLeft={xs ? undefined : lg ? 80 : 40}>
          <Img
            srcKey="b31b6296b048696e89dd245caa29511b_JBWZYmYzC.jpeg"
            alt="people working"
            width={1200}
            folder="public"
            style={{
              height: xl ? 500 : xs ? 216 : 288,
              objectFit: 'cover',
              borderRadius: xs ? undefined : 24,
              width: xs ? windowWidth : undefined
            }}
          />
        </Layout>
      </Layout>
    </FullWidthBackground>
  );
};

export default Hero;
