import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

import { ReactComponent as ArrowRight } from './playbook-arrow-right.svg';

interface Props {}

const SearchSteps: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout justify="center" overflow="hidden">
        <Layout>
          <Layout align="center" direction={xs ? 'column' : 'row'}>
            <Layout
              __style={{ alignSelf: 'stretch' }}
              equalWidth
              paddingTop={40}
              paddingX={36}
              paddingBottom={36}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              direction="column"
            >
              <Layout justify="center">
                <Icon size={60} color="blue-gradient" name="search" />
              </Layout>
              <Layout marginTop={24}>
                <Text size="h5" color="blue-600" align="center">
                  Search for spaces
                </Text>
              </Layout>
              <Layout marginTop={24}>
                <Text size="h6" color="gray-700" bold={false}>
                  You’ll need to begin by pounding the proverbial pavement. Identify a few properties that seem to fit
                  your requirements —Google is your friend when it comes to searching for available properties.
                </Text>
              </Layout>
            </Layout>
            {!xs && (
              <Layout marginX={32}>
                <ArrowRight width={81} />
              </Layout>
            )}
            <Layout
              __style={{ alignSelf: 'stretch' }}
              equalWidth
              paddingTop={40}
              paddingX={36}
              paddingBottom={36}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              direction="column"
              marginTop={xs ? 24 : undefined}
            >
              <Layout justify="center">
                <Icon size={60} color="blue-gradient" name="envelope" />
              </Layout>
              <Layout marginTop={24}>
                <Text size="h5" color="blue-600" align="center">
                  Contact the ones you like
                </Text>
              </Layout>
              <Layout marginTop={24}>
                <Text size="h6" color="gray-700" bold={false}>
                  When you find a listing that looks good to you, reach out to the contact listed there. Sometimes there
                  will be a short form you can fill out rather than a direct contact or email.
                </Text>
              </Layout>
            </Layout>
            {!xs && (
              <Layout marginX={32}>
                <ArrowRight width={81} />
              </Layout>
            )}
            <Layout
              __style={{ alignSelf: 'stretch' }}
              equalWidth
              paddingTop={40}
              paddingX={36}
              paddingBottom={36}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              direction="column"
              marginTop={xs ? 24 : undefined}
            >
              <Layout justify="center">
                <Icon size={60} color="blue-gradient" name="clipboard" />
              </Layout>
              <Layout marginTop={24}>
                <Text size="h5" color="blue-600" align="center">
                  Define your criteria
                </Text>
              </Layout>
              <Layout marginTop={24}>
                <Text size="h6" color="gray-700" bold={false}>
                  After making an inquiry, you’ll be contacted by a broker, (sometimes within minutes), who will ask you
                  more questions and begin to put a list together of possible properties for you to see.
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout marginTop={24}>
            <Text size="body1" italic color="white">
              * See notes about Tenant Rep Brokers on next page
            </Text>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default SearchSteps;
