import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const AboutCodi: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Layout>
          <Text size="h3" color="blue-500" scale={xs}>
            About Codi
          </Text>
          <Layout marginTop={12} paddingBottom={36} flex direction={xs ? 'column' : 'row'}>
            <Layout equalWidth>
              <Text size="body1" color="gray-700">
                Our goal at Codi is to reinvent the office model for the hybrid era. We make it as easy to find, rent,
                and upgrade your office as it is to upgrade any other part of your tech stack.{' '}
              </Text>
            </Layout>
            <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 24 : undefined} equalWidth>
              <Text size="body1" color="gray-700">
                To learn how we can help you find that perfect office, visit{' '}
                <Link size="body1" semibold={false} underline href="https://www.codi.com/">
                  www.codi.com
                </Link>
              </Text>
            </Layout>
          </Layout>
        </Layout>
        <Layout marginTop={36} flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <Layout flex>
              <Icon name="buildingNew" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Private, turnkey offices
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    All with the most flexible terms on the market.
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={24} flex>
              <Icon name="checkmark" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Quick, transparent processes
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    We negotiate with landlords beforehand so your agreement with Codi is simple and without hassle (or
                    legal fees).
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={24} flex>
              <Icon name="smart_lock" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  No long-term leases
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    You can stay in your Codi space as little as three months or as long as you want.
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={24} flex>
              <Icon name="calendar" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Incredible flexibility and savings
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    Take advantage of our “timeshare model” and use your office only on specific days. Great for hybrid
                    teams!
                  </Text>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
          <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 36 : undefined} equalWidth>
            <Layout flex>
              <Icon name="cleaning" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Maintenance is covered
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    Included office management, cleaning, snacks, and high-speed internet — everything you need without
                    any of the maintenance responsibilities.
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={24} flex>
              <Icon name="chair" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Make it your own
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    Customizable furniture, layouts, and amenities that will make your company’s new home feel like,
                    well, home.
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={24} flex>
              <Icon name="addressPinOutline" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Diverse locations
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    Many unique options in easily commutable locations — not just city centers.
                  </Text>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={24} flex>
              <Icon name="tv" size={48} color="blue-gradient" />
              <Layout marginLeft={24}>
                <Text size="h6" color="blue-600">
                  Host offsites, meetings & events
                </Text>
                <Layout marginTop={4}>
                  <Text size="h6" color="gray-700" bold={false}>
                    One- to three-day Offsite model for team offsites, events, or all-hands meetings — held in real
                    offices, not stuffy conference rooms.
                  </Text>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default AboutCodi;
