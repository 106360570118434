import React, { useContext } from 'react';
import { GRAY_50_HEX } from '@codiwork/codi';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

interface Props {}

const Coworking: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <Layout marginTop={36} flex direction={xs ? 'column' : 'row'}>
      <Layout maxWidth={xs ? contentWidth : 292}>
        <Layout align="center">
          <Layout
            width={44}
            height={44}
            borderColor="blue-gradient"
            borderRadius="circular"
            align="center"
            justify="center"
          >
            <Icon name="users" size="md" color="blue-gradient" />
          </Layout>
          <Layout marginLeft={12}>
            <Text size="h5" color="blue-gradient">
              Coworking Office
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={36}>
          <Text size="h6" bold={false} color="gray-700">
            The coworking option is a private office space rented from a coworking company and located within their
            floor or building.
            <br />
            <br />
            The space is often adjacent to a mix of employees from other companies.
          </Text>
        </Layout>
      </Layout>
      <Layout
        boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
        color="white"
        borderRadius={24}
        marginLeft={xs ? undefined : 24}
        marginTop={xs ? 24 : undefined}
      >
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '20px 40px 16px 40px', width: '50%' }}>
                <Text size="h6" color="blue-gradient">
                  PROS
                </Text>
              </th>
              <th style={{ textAlign: 'left', padding: '20px 40px 16px 72px', width: '50%' }}>
                <Text size="h6" color="gray-300">
                  CONS
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Easy, turnkey process
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Getting started with a coworking space can be as easy as walking in, taking a quick tour, and
                    signing a month-to-month agreement.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  No culture
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Coworking environments are designed to be blank canvases. Besides having your logo up on a glass
                    door, there’s no signal of ownership to your team or your clients. Many companies looking for their
                    first private space say they are trying to “graduate” from coworking.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  No long-term leases
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    While there may be a minimum number of months required, you won’t be required to enter into a
                    long-term lease.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  You’ll have to manage the space
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    With a coworking environment, what you see is what you get. You can make very few changes to
                    optimize productivity or comfort for your team.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Space if fully managed
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Cleaning and general space management are all taken care of in a coworking environment.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Little room for growth
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Coworking companies make their money by squeezing as many workers into a single space as they can.
                    They don’t reserve big spaces for single companies, so there are few options. If you’re growing
                    quickly, you may have to find yourself a new space.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Cost transparency
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    You know what you’re getting with the membership fee. You don’t need to worry about any tricks on
                    your agreement.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Higher costs
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Coworking spaces are generally located in high-priced, central urban areas and rent their spaces via
                    the traditional lease model. They pass their lease costs along to members. If your employees have
                    moved further out from city centers, this could mean longer commute times as well.
                  </Text>
                </Layout>
              </td>
            </tr>
          </tbody>
        </table>
      </Layout>
    </Layout>
  );
};

export default Coworking;
