import React, { PropsWithChildren, useContext } from 'react';

import { createTransformations, imageKitUrl } from 'helpers/images';

import { app } from 'context';

import Layout, { Props as LayoutProps } from '../ds/Layout';

type Props = LayoutProps & {
  folder?: string;
  srcKey: string;
  width?: number;
} & ({ height: number; videoHeight?: number } | { height: string; videoHeight: number });

const HeroVideo: React.FC<PropsWithChildren<Props>> = ({
  folder,
  srcKey,
  width,
  height,
  children,
  videoHeight,
  ...layoutProps
}) => {
  const { width: appWidth, maxContentWidth, contentPaddingX } = useContext(app);

  const difference =
    maxContentWidth && appWidth > maxContentWidth + contentPaddingX * 2 ? appWidth - maxContentWidth : undefined;
  const right = difference && difference > 0 ? difference / 2 : contentPaddingX;
  const baseUrl = imageKitUrl({
    folder: folder,
    key: srcKey
  });

  return (
    <Layout position="relative" width={appWidth} right={right} height={height} {...layoutProps}>
      <video
        muted
        loop
        autoPlay
        playsInline
        style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
        poster={`${baseUrl}/ik-thumbnail.jpg`}
      >
        <source
          src={imageKitUrl({
            folder: folder,
            key: srcKey,
            transformationChain: createTransformations({
              width,
              height: typeof height === 'string' ? videoHeight : videoHeight || height
            })
          })}
          type="video/mp4"
        />
      </video>
      {children}
    </Layout>
  );
};

export default HeroVideo;
