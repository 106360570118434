import React, { Children, PropsWithChildren, useContext, useRef, useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import SwiperClass, { A11y } from 'swiper';

import { app, getScreenSizeBase, media } from 'context';
import { Swiper } from 'swiper/react';

import Layout from '../Layout';
import PaginationControl from '../SwiperCarousel/PaginationControl';

const DEFAULT_PAGINATION_CONTROL_DIMENSION = 44;

export interface Props {
  imageRect?: DOMRect;
  config?: typeof CONFIG;
  ignoreTouch?: boolean;
}

const CONFIG = {
  lg: {
    slidesPerView: 4,
    spaceBetween: 24
  },
  md: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  sm: {
    slidesPerView: 2,
    spaceBetween: 24
  },
  xs: {
    slidesPerView: 2,
    spaceBetween: 24
  },
  phone: {
    slidesPerView: 1,
    spaceBetween: 24
  }
};

export const PHONE_QUERY = '(min-width: 0) and (max-width: 499px)';

const Carousel: React.FC<PropsWithChildren<Props>> = ({ children, imageRect, ignoreTouch, config = CONFIG }) => {
  const mediaContext = useContext(media);
  const { width } = useContext(app);
  const { xs, sm, md, lg } = mediaContext;
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const swiperRef = useRef<SwiperClass>();
  const isPhone = useMediaQuery(PHONE_QUERY);
  const screenSize = getScreenSizeBase(mediaContext);
  const isTouch = (xs || sm) && !ignoreTouch;
  const isMouse = md || lg || !!ignoreTouch;

  const configSize = isPhone ? 'phone' : screenSize;

  const { spaceBetween, slidesPerView } = config[configSize];

  const swiper = swiperRef.current;

  const slides = Children.toArray(children);

  return (
    <Layout position="relative" {...(isTouch ? { width: width - 84 } : {})}>
      <Swiper
        onInit={swiper => {
          swiperRef.current = swiper;
        }}
        modules={[A11y]}
        spaceBetween={spaceBetween}
        onSlideChange={({ activeIndex }) => {
          setActiveIndex(activeIndex);
        }}
        slidesPerView={slidesPerView}
        {...(isTouch ? { style: { overflow: 'visible' } } : {})}
      >
        {slides}
      </Swiper>
      {isMouse && activeIndex > 0 ? (
        <Layout
          position="absolute"
          top={imageRect ? (imageRect.height - (DEFAULT_PAGINATION_CONTROL_DIMENSION + 48)) / 2 : undefined}
          left={-DEFAULT_PAGINATION_CONTROL_DIMENSION}
          opacity={imageRect ? 1 : 0}
          zIndex={2}
        >
          <PaginationControl
            onClick={() => {
              const swiper = swiperRef.current;

              if (!swiper) return;

              swiper.slidePrev();
            }}
            direction="left"
            dimension={DEFAULT_PAGINATION_CONTROL_DIMENSION}
          />
        </Layout>
      ) : null}
      {isMouse && !swiper?.isEnd ? (
        <Layout
          position="absolute"
          top={imageRect ? (imageRect.height - (DEFAULT_PAGINATION_CONTROL_DIMENSION + 48)) / 2 : undefined}
          right={-DEFAULT_PAGINATION_CONTROL_DIMENSION}
          opacity={imageRect ? 1 : 0}
          zIndex={2}
        >
          <PaginationControl
            onClick={() => {
              const swiper = swiperRef.current;

              if (!swiper) return;

              swiper.slideNext();
            }}
            direction="right"
            dimension={DEFAULT_PAGINATION_CONTROL_DIMENSION}
          />
        </Layout>
      ) : null}
    </Layout>
  );
};

export default Carousel;
