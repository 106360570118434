import { LatLng, Market } from '@codiwork/codi';
import axios from '@codiwork/codi/lib/lib/axios';
import { AxiosResponse } from 'axios';

import { apiTrack } from 'lib/analytics';
import { isLatLngInBounds } from 'lib/location';
import { OFFICE_REQUEST_PATH, SEARCH_PATH } from 'routes';
import { OfficeRequestLocation, OfficeRequestState, OfficeRequestTour, OfficeRequestUser } from 'store/OfficeRequest';
import { generateSearch } from 'ux/Search/utils';

import { OfficeRequest } from './types';
import { ReferralInfo } from '../types';

export function generateStepPath({ step }: { step: number }) {
  return `${OFFICE_REQUEST_PATH}/${step}`;
}

export interface CreatableUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  callingCode: string;
}

export function isCreatableUser(user: OfficeRequestUser): user is CreatableUser {
  return !!user.firstName && !!user.lastName && !!user.email && !!user.phone && !!user.callingCode;
}

export interface CreatableOfficeRequest {
  market: string | null;
  locations: OfficeRequestLocation[];
  headCount: number;
  daysPerWeek: number;
  amenities: string[];
  otherAmenities?: string;
  maxMonthlyBudget: number;
  tour?: OfficeRequestTour;
  user: Required<OfficeRequestUser>;
  howHeard: string;
  searchTerms?: string;
  launchPath?: string;
  tourRequestWorkspaceId?: number;
  referralInfo?: ReferralInfo;
}

export function createOfficeRequest(officeRequest: CreatableOfficeRequest) {
  return axios.post<OfficeRequest, AxiosResponse<OfficeRequest>>(
    '/v1/office_requests',
    generateApiOfficeRequest(officeRequest)
  );
}

function generateApiOfficeRequest({
  market,
  locations,
  user: { firstName, lastName, callingCode, email, phone },
  headCount,
  daysPerWeek,
  maxMonthlyBudget,
  otherAmenities,
  amenities,
  howHeard,
  searchTerms,
  launchPath,
  tour,
  tourRequestWorkspaceId,
  referralInfo
}: CreatableOfficeRequest) {
  return {
    market,
    locations,
    first_name: firstName,
    last_name: lastName,
    calling_code: callingCode,
    email: email.toLowerCase(),
    phone,
    head_count: headCount,
    days_per_week: daysPerWeek,
    max_monthly_budget: maxMonthlyBudget,
    other_amenities: otherAmenities,
    amenities,
    how_heard: howHeard,
    search_terms: searchTerms,
    launch_path: launchPath,
    workspace_id: tourRequestWorkspaceId,
    referrer_id: referralInfo?.referrer.id,
    tour_time: !!tour ? `${tour?.startsAt}|${tour?.endsAt}` : null
  };
}

export function isCreatableOfficeRequest(
  officeRequest: Omit<OfficeRequestState, 'entry' | 'completedSteps' | 'exitModalIsVisible'>
): officeRequest is CreatableOfficeRequest {
  const {
    locations,
    user: { firstName, lastName, callingCode, email, phone },
    daysPerWeek,
    howHeard,
    referralInfo
  } = officeRequest;

  if (!locations.length) return false;

  return (
    !!locations.length &&
    !!firstName &&
    !!lastName &&
    !!callingCode &&
    !!email &&
    !!phone &&
    !!daysPerWeek &&
    (!!howHeard || !!referralInfo)
  );
}

export function marketFromLatLng(loc: LatLng, markets: Market[]) {
  for (let i = 0; i < markets.length; i++) {
    const market = markets[i];
    if (isLatLngInBounds(loc, market.bounds)) return market;
  }

  return null;
}

export function marketNameFromLatLng(loc: LatLng, markets: Market[]) {
  return marketFromLatLng(loc, markets)?.name || null;
}

export function centerOfMarket({ bounds }: Market) {
  const { north, south, east, west } = bounds;
  const lat = (south + north) / 2;
  const lng = (east + west) / 2;
  return { lat, lng };
}

export function makeExitPath(market: string | null, locations: OfficeRequestLocation[]) {
  if (!market && !locations.length) return { href: SEARCH_PATH };

  return {
    href: SEARCH_PATH,
    search: generateSearch({
      search: '',
      location: `${market || locations[0].city}, USA`
    })
  };
}

export function trackOfficeRequestSubmitted(request: CreatableOfficeRequest) {
  const {
    user: { email, firstName, lastName, callingCode, phone },
    amenities,
    market,
    locations,
    maxMonthlyBudget,
    ...rest
  } = request;
  const locationStrings = locations.map(loc => `${!!loc.neighborhood ? `${loc.neighborhood}, ` : ''}${loc.city}`);

  apiTrack('Office Request Submitted', {
    ...rest,
    market: market,
    locations: locationStrings,
    maxMonthlyBudget: maxMonthlyBudget,
    email: email.toLowerCase(),
    firstName,
    lastName,
    phone: `+${callingCode}${phone}`,
    amenities: amenities.join(', ')
  });
}
