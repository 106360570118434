import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, ButterImg, Icon, Layout, Link, Text } from 'ds';
import { ButterImage } from 'lib/butter/types';

interface Props {
  image: ButterImage;
  items: { title: string; subtitle: string; cta_text: string; cta_url: string; image: string }[];
  title: string;
}

const ValueProps: React.FC<Props> = ({ items, title, image }) => {
  const { xs, lg } = useContext(media);
  const { contentPaddingX, width } = useContext(app);

  const isRowLayout = lg;

  return (
    <Layout>
      <Animate {...(xs ? { width, position: 'relative', left: -contentPaddingX } : {})}>
        <ButterImg
          src={image.src}
          alt={image.alt}
          {...(xs
            ? { width: 750, height: 464, style: { height: 232, objectFit: 'cover' } }
            : { width: 1280, height: 480, style: { borderRadius: 24 } })}
        />
      </Animate>
      <Animate>
        <Layout marginTop={72}>
          <Text size="h4" color="blue-700" align="center">
            {title}
          </Text>
        </Layout>
      </Animate>
      <Layout {...(isRowLayout ? { direction: 'row' } : { direction: 'column', align: 'center' })}>
        {items.map(({ title, subtitle, image, cta_text, cta_url }, index) => (
          <Animate
            key={title}
            {...(isRowLayout
              ? { marginLeft: index === 0 ? undefined : 44, marginTop: 48, delay: index * 100 }
              : { marginTop: 48, maxWidth: 504 })}
            equalWidth
          >
            <Layout
              {...(isRowLayout
                ? {
                    boxShadow: '2px 0px 22px 0px #57496D1F',
                    padding: 44,
                    borderRadius: 24,
                    color: 'white',
                    direction: 'column',
                    justify: 'space-between',
                    align: 'center',
                    height: '100%',
                    width: '100%'
                  }
                : {})}
            >
              <Layout>
                <Layout justify="center">
                  <Layout height={44} width={44}>
                    <img src={image} alt="high five" />
                  </Layout>
                </Layout>
                <Layout marginTop={16}>
                  <Text size="h6" color="blue-700" align="center">
                    {title}
                  </Text>
                </Layout>
                <Layout marginTop={16}>
                  <Text size="body1" color="gray-500" align="center">
                    {subtitle}
                  </Text>
                </Layout>
              </Layout>
              <Layout marginTop={20} {...(isRowLayout ? {} : { justify: 'center' })}>
                <Link href={cta_url}>
                  <Layout inline align="center">
                    <Text color="blue-500" size="body1" semibold inline>
                      {cta_text}
                    </Text>
                    <Layout marginLeft={8} display="inline-flex">
                      <Icon name="rightArrow" stroke={2} size="sm" color="blue-500" />
                    </Layout>
                  </Layout>
                </Link>
              </Layout>
            </Layout>
          </Animate>
        ))}
      </Layout>
    </Layout>
  );
};

export default ValueProps;
