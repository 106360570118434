import React, { PropsWithChildren, useRef } from 'react';
import { BottomSheet, BottomSheetProps, BottomSheetRef } from 'react-spring-bottom-sheet';

import Pressable from './Pressable';
import Text from './Text';

export interface Props extends Pick<BottomSheetProps, 'onSpringStart' | 'onSpringEnd'> {
  isVisible: boolean;
  title?: string;
  onChange?: (params: { height: number }) => void;
  sheetRef?: React.MutableRefObject<BottomSheetRef | null>;
  onSnapPoints?: (params: { snapPoints: number[] }) => void;
}

const BottomDrawer: React.FC<PropsWithChildren<Props>> = ({
  isVisible,
  children,
  title,
  onSpringStart,
  onSpringEnd,
  onSnapPoints,
  ...props
}) => {
  const localRef = useRef<BottomSheetRef>(null);
  const sheetRef = props.sheetRef || localRef;

  return (
    <>
      <BottomSheet
        className="BottomDrawer"
        open={isVisible}
        ref={sheetRef}
        onSpringStart={onSpringStart}
        onSpringEnd={onSpringEnd}
        defaultSnap={({ snapPoints }) => snapPoints[1]}
        snapPoints={({ maxHeight }) => {
          const snapPoints = [52, maxHeight / 2.2, maxHeight - 72];

          onSnapPoints && onSnapPoints({ snapPoints });

          return snapPoints;
        }}
        blocking={false}
        header={
          <Pressable
            onPress={() => {
              let nextSnapPointIndex: number;

              if (!sheetRef.current) return;

              sheetRef.current.snapTo(({ snapPoints, lastSnap }) => {
                const lastSnapIndex = lastSnap === null ? 1 : snapPoints.indexOf(lastSnap);

                if (lastSnapIndex === 0) {
                  nextSnapPointIndex = 1;
                } else if (lastSnapIndex === 1) {
                  nextSnapPointIndex = 2;
                } else {
                  nextSnapPointIndex = 0;
                }

                return snapPoints[nextSnapPointIndex];
              });
            }}
            style={{ paddingTop: 24, paddingBottom: 8 }}
            activeColor="white"
            fullWidth
          >
            <Text size="body-sm" semibold>
              {title}
            </Text>
          </Pressable>
        }
      >
        {children}
      </BottomSheet>
    </>
  );
};

export default BottomDrawer;
