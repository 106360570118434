import React, { useContext } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';

import { media } from 'context';
import { Button } from 'ds';
import { PLAYBOOK_PATH } from 'routes';

import { WEB_PLAYBOOK_QUERY } from './constants';

interface Props {}

const PlaybookCTA: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const isWebPlaybook = useMediaQuery(WEB_PLAYBOOK_QUERY);

  return (
    <Button
      iconStroke={1.5}
      size={xs ? 'sm' : 'md'}
      type="primary"
      fullWidth={xs}
      {...(isWebPlaybook
        ? {
            href: `${PLAYBOOK_PATH}/intro`,
            text: 'Read the Playbook',
            iconPosition: 'right',
            icon: 'rightArrow'
          }
        : {
            href: 'https://codi-downloads.s3.amazonaws.com/Codi+HQ+Playbook.pdf',
            target: '_blank',
            text: 'Get the Playbook',
            iconPosition: 'left',
            icon: 'download'
          })}
    />
  );
};

export default PlaybookCTA;
