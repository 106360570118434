import React, { useContext } from 'react';

import { app, media } from 'context';
import { Icon, Img, Layout, Link, Text } from 'ds';

import { BROKER_FORM_LINK, BROKER_FORM_LINK_SCROLL_OFFSET } from './constants';

interface Props {}

const AboutCodiConnect: React.FC<Props> = () => {
  const { isMobile } = useContext(media);
  const { contentPaddingX } = useContext(app);

  return (
    <Layout {...(isMobile ? { paddingX: contentPaddingX } : { flex: true, justify: 'space-between' })}>
      {isMobile ? (
        <Img
          srcKey="broker-codi-connect_f-Bl7ffjP.jpg"
          folder="public"
          alt="bright lounge"
          width={1_000}
          style={{ borderRadius: 16, objectFit: 'cover', height: 238 }}
        />
      ) : (
        <Img
          srcKey="broker-codi-connect_f-Bl7ffjP.jpg"
          folder="public"
          alt="bright lounge"
          width={1600}
          style={{ borderRadius: 16, objectFit: 'cover', width: 514, height: 344 }}
        />
      )}

      <Layout {...(isMobile ? { marginTop: 24 } : { with: 490, marginLeft: 100 })}>
        <Text size={isMobile ? 'headline-lg' : 'display-sm'} color="blue-500">
          About CodiConnect
        </Text>
        <Layout marginY={32}>
          <Text size={isMobile ? 'body-md' : 'headline-xs'} bold={false} color="gray-400">
            CodiConnect revolutionizes how you find and secure fully managed private offices for clients. It's a
            win-win: your clients receive the flexibility and service they need, you earn the commission on the deal and
            a healthy referral bonus, and Codi does all the work!
          </Text>
        </Layout>
        <Link href={BROKER_FORM_LINK} scrollYOffset={BROKER_FORM_LINK_SCROLL_OFFSET}>
          <Layout align="center"></Layout>
          <Text size="body-md" color="blue-500" semibold>
            Become a broker partner
          </Text>
          <Layout marginLeft={8} display="inline-flex">
            <Icon name="rightArrow" color="blue-500" size="sm" stroke={2} />
          </Layout>
        </Link>
      </Layout>
    </Layout>
  );
};

export default AboutCodiConnect;
