import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUser, validateEmail } from '@codiwork/codi';

import { app, media } from 'context';
import { Animate, Layout, Text } from 'ds';
import { validateNumber } from 'ds/inputs/PhoneInput';
import { RequestState } from 'ds/types';
import { apiTrack } from 'lib/analytics';
import { createOfficeLead, createOfficeLeadTour, transformOfficeRequestStateToOfficeLead } from 'requests/office_leads';
import { AUTH_PATH, CUSTOMER_OFFICE_REQUEST_PATH } from 'routes';
import { actions as authActions } from 'store/Auth';
import { actions } from 'store/OfficeRequest';
import {
  selectDaysPerWeek,
  selectOfficeRequest,
  selectOfficeRequestLocations,
  selectOfficeRequestMarket,
  selectOfficeRequestTour,
  selectOfficeRequestUser,
  selectTourRequestWorkspaceId
} from 'store/OfficeRequest/selectors';
import { actions as uiActions } from 'store/UI';
import { actions as userActions } from 'store/User';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createTourDestinationRequest } from 'ux/Customer/OfficeRequest/requests';
import { getNextUpcomingTour } from 'ux/Customer/OfficeRequest/utils';

import OfficeRequestBottomSheet from './OfficeRequestBottomSheet';
import StepContainer from './StepContainer';
import StepDescription from './StepDescription';
import StepTitle from './StepTitle';
import UserFields from './UserFields';
import { createOfficeRequest, generateStepPath, isCreatableOfficeRequest, trackOfficeRequestSubmitted } from './utils';

interface Props {}

const Step7: React.FC<Props> = () => {
  const { xs, sm } = useContext(media);
  const { contentPaddingX, isSmallPhone } = useContext(app);
  const isMobile = xs || sm;
  const { firstName, lastName, email, phone, callingCode } = useAppSelector(selectOfficeRequestUser) || {};
  const { valid: emailIsValid } = validateEmail(email, true);
  const hasInputFocus = useRef<boolean>(false);
  const market = useAppSelector(selectOfficeRequestMarket);
  const locations = useAppSelector(selectOfficeRequestLocations);
  const officeRequest = useAppSelector(selectOfficeRequest);
  const history = useHistory();
  const { valid: isValidPhoneNumber } = validateNumber({
    phone,
    callingCode,
    country: locations[0]?.country || 'US',
    required: true
  });
  const isValidOfficeRequest = emailIsValid && !!isValidPhoneNumber && isCreatableOfficeRequest(officeRequest);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const tourRequestWorkspaceId = useAppSelector(selectTourRequestWorkspaceId);
  const officeRequestTour = useAppSelector(selectOfficeRequestTour);
  const daysPerWeek = useAppSelector(selectDaysPerWeek);

  const collapseSheet = () => {
    dispatch(uiActions.setOfficeRequestWizardHideFooter(false));

    window.scrollTo({ top: 0 });
  };

  const expandSheet = () => {
    dispatch(uiActions.setOfficeRequestWizardHideFooter(true));
  };

  useEffect(() => {
    if (firstName || lastName || email || phone || !user) return;

    dispatch(
      actions.setUser({
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
        phone: user.phone,
        callingCode: user.calling_code
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StepContainer
      step={7}
      ctaDisabled={!isValidOfficeRequest}
      backHref={generateStepPath({ step: !!market && daysPerWeek === 5 ? 6 : 5 })}
      onSubmit={() => {
        setRequestState('in_progress');

        if (!isCreatableOfficeRequest(officeRequest)) {
          setRequestState('ready');
          return;
        }

        const email = officeRequest.user.email.toLowerCase();

        createOfficeRequest(officeRequest)
          .then(async () => {
            dispatch(actions.setUser({ email }));
            trackOfficeRequestSubmitted(officeRequest);

            if (user) {
              const { data } = await createOfficeLead({
                userId: user.id,
                officeLead: transformOfficeRequestStateToOfficeLead(officeRequest)
              });

              let officeLead = data;

              const { data: updatedUser } = await getUser({ id: user.id });
              dispatch(userActions.setUser(updatedUser));

              let upcomingTour = getNextUpcomingTour(officeLead.tours);

              if (!upcomingTour && officeRequestTour && officeLead.id) {
                const { data } = await createOfficeLeadTour({
                  userId: user.id,
                  officeLeadId: officeLead.id,
                  officeRequestTour: officeRequestTour,
                  source: 'request'
                });

                officeLead = data;
              }

              upcomingTour = getNextUpcomingTour(officeLead.tours);

              if (tourRequestWorkspaceId && upcomingTour) {
                const { data } = await createTourDestinationRequest({
                  workspaceId: tourRequestWorkspaceId,
                  officeLeadId: officeLead.id,
                  tourId: upcomingTour.id,
                  userId: user.id
                });

                officeLead = data;

                apiTrack('Tour Requested', { workspaceId: tourRequestWorkspaceId });
              }

              dispatch(userActions.updateOfficeLead(data));

              const { data: userResponse } = await getUser({ id: user.id });
              dispatch(userActions.setUser(userResponse));

              history.push({
                pathname: `${CUSTOMER_OFFICE_REQUEST_PATH}/${officeLead.id}`,
                search: '?search=1'
              });

              // <hack> OfficeRequest was redirecting before history.push changed the path
              setTimeout(() => {
                dispatch(actions.reset());
              }, 200);
            } else {
              dispatch(authActions.setAuthFlowType('officeRequest'));
              history.push(AUTH_PATH);
            }
          })
          .catch(() => setRequestState('failed'));
      }}
      requestState={requestState}
      stepCompletedEventValue={{ firstName, lastName, email, phone }}
      textLayoutProps={isSmallPhone ? {} : { marginTop: 64 }}
      stepName="contact form"
    >
      <Layout>
        <StepTitle>You're almost there.</StepTitle>
        <Layout marginTop={isMobile ? 16 : 24}>
          <StepDescription>Your perfect office is just a few clicks away.</StepDescription>
        </Layout>
      </Layout>
      {isMobile ? (
        <OfficeRequestBottomSheet>
          <Layout paddingX={contentPaddingX}>
            <Text size="h5">Tell us how to contact you.</Text>
          </Layout>
          <Layout
            paddingBottom={24}
            paddingX={contentPaddingX}
            marginTop={isSmallPhone ? 12 : 24}
            maxHeight={!hasInputFocus.current ? 210 : undefined}
            overflow="auto"
          >
            <UserFields
              handleFocus={() => {
                hasInputFocus.current = true;
                expandSheet();
              }}
              onSelectFocus={() => {
                hasInputFocus.current = true;
                dispatch(uiActions.setOfficeRequestWizardHideFooter(false));
              }}
              onBlur={() => {
                hasInputFocus.current = false;

                setTimeout(() => {
                  if (hasInputFocus.current) return;

                  collapseSheet();
                }, 50);
              }}
            />
          </Layout>
        </OfficeRequestBottomSheet>
      ) : (
        <Layout width={524}>
          <Animate>
            <Text size="h5">Tell us how to contact you.</Text>
          </Animate>
          <Layout marginTop={24}>
            <UserFields />
          </Layout>
        </Layout>
      )}
    </StepContainer>
  );
};

export default Step7;
