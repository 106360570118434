import React from 'react';

import { Layout, OverlappingSections, Text } from 'ds';

interface Props {
  description: string;
  image: string;
  location: string;
}

const About: React.FC<Props> = ({ description, location, image }) => {
  return (
    <OverlappingSections
      image={{ src: image, alt: location }}
      text={
        <>
          <Text size="h4" color="blue-500">
            {location}
            <br />
            <Text color="blue-700" inline scale>
              Office Spaces
            </Text>
          </Text>
          <Layout marginTop={24}>
            <Text size="body1" inline scale>
              {description}
            </Text>
          </Layout>
        </>
      }
    />
  );
};

export default About;
