import React, { useContext } from 'react';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';

import InfoCards from './InfoCards';

import { ReactComponent as SquiggleGraphic } from './sf-tech-week-squiggle-graphic.svg';

interface Props {}

const EventDescription: React.FC<Props> = () => {
  const { windowWidth, contentPaddingX } = useContext(app);
  const { xs, sm, md, lg, xl } = useContext(media);

  if (xs) {
    return (
      <>
        <Layout
          paddingY={60}
          position="relative"
          paddingX={contentPaddingX}
          direction="column"
          align="center"
          color="blue-50"
          overflow="hidden"
          width={windowWidth}
        >
          <Text size="h5" color="blue-600">
            Join Us
          </Text>
          <Layout width="100%" paddingX={32}>
            <InfoCards />
          </Layout>
          <Layout marginTop={32}>
            <Button
              text="Register now"
              size="md"
              type="primary"
              href="https://lu.ma/4zeuu24b"
              icon="rightArrow"
              iconPosition="right"
              iconStroke={2}
            />
          </Layout>
        </Layout>
        <Layout paddingTop={108} paddingBottom={40} paddingX={contentPaddingX} color="blue-50">
          <Text size="h5" color="blue-700">
            TechWeek Coworking Hub
          </Text>
          <Layout marginTop={12}>
            <Text size="h6" color="blue-400">
              Join your TechWeek cohort in a Codi space in downtown SF. Location TBD.
            </Text>
          </Layout>
          <Layout marginTop={40} align="center">
            <Button
              text="Reserve your seat"
              size="md"
              type="secondary"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfQqA11FpxyviE8jkK6MX6J04pssNxXlGZqtrOSGxnxtarJvg/viewform"
            />
          </Layout>
        </Layout>
        <Img
          srcKey="sf-tech-week-office_pfUEsKSKS.jpeg"
          folder="public"
          alt="SF office"
          width={800}
          style={{
            width: windowWidth,
            height: 232,
            objectFit: 'cover'
          }}
        />
      </>
    );
  }

  return (
    <Layout color="blue-50" justify="center" paddingY={80} position="relative">
      <Layout maxWidth={1200} zIndex={1}>
        <Layout
          flex={lg}
          align={sm || md ? 'center' : undefined}
          direction={sm || md ? 'column' : undefined}
          justify={sm || md || lg ? 'center' : undefined}
        >
          <Layout width={xl ? 548 : lg ? 460 : 420} paddingRight={32}>
            <Text color="blue-700" size="h4" align={sm || md ? 'center' : undefined}>
              Join Us
            </Text>
            <Layout marginTop={24}>
              <Text size="h6" bold={false} align={sm || md ? 'center' : undefined}>
                Join us on May 30th for brunch and an exclusive chat with Andrew Chen, General Partner at a16z, as he
                shares insights on Series B fundraising. With a background leading Uber's Rider Growth teams and
                advising/investing in startups like Dropbox and Tinder, Andrew brings a wealth of experience relevant to
                your fundraising journey.
              </Text>
            </Layout>
            <Layout marginTop={40} justify={sm || md ? 'center' : undefined}>
              <Button text="Register now" size="md" type="primary" href="https://lu.ma/4zeuu24b" />
            </Layout>
          </Layout>
          <Layout
            marginLeft={sm || md ? undefined : xl ? 80 : 48}
            width={572}
            marginTop={sm || md ? 64 : undefined}
            height={204}
            flex
          >
            <InfoCards />
          </Layout>
        </Layout>
        <Layout marginTop={140} paddingTop={80} align="center" paddingX={xl ? undefined : contentPaddingX}>
          <Img
            srcKey="sf-tech-week-office_pfUEsKSKS.jpeg"
            folder="public"
            alt="SF office"
            width={1200}
            style={{
              maxWidth: xl ? 572 : lg ? 502 : md ? 420 : 380,
              height: xl ? 382 : lg ? 340 : 320,
              borderRadius: 24,
              objectFit: 'cover'
            }}
          />
          <Layout marginLeft={80}>
            <Layout borderRadius={24}>
              <Text size="h4" color="blue-700">
                TechWeek Coworking Hub
              </Text>
              <Layout marginTop={12}>
                <Text size="h6" color="blue-400">
                  Join your TechWeek cohort in a Codi space in downtown SF. Location TBD.
                </Text>
              </Layout>
            </Layout>
            <Layout marginTop={40} align={sm ? undefined : 'center'}>
              <Button
                text="Reserve your seat"
                size="md"
                type="secondary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfQqA11FpxyviE8jkK6MX6J04pssNxXlGZqtrOSGxnxtarJvg/viewform"
              />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <SquiggleGraphic
        preserveAspectRatio="none"
        style={{ position: 'absolute', height: '100%', width: windowWidth, top: 0, right: 0, zIndex: 0 }}
      />
    </Layout>
  );
};

export default EventDescription;
