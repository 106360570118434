import { Bounds } from '@codiwork/codi';

interface QueryLocationParams {
  input: string;
  callback: (results: google.maps.places.AutocompletePrediction[]) => void;
  origin?: google.maps.LatLngLiteral;
  types?: string[];
  locationRestriction?: google.maps.LatLngBoundsLiteral;
  country?: string[];
}

export function getLocations({
  input,
  callback,
  origin,
  types,
  locationRestriction,
  country = ['us', 'gb', 'ca', 'fr']
}: QueryLocationParams) {
  const { googleMapsAutocompleteService } = window.codi;

  if (input.length === 0 || !googleMapsAutocompleteService) {
    callback([]);
    return;
  }

  const predictionParams: google.maps.places.AutocompletionRequest = {
    input,
    componentRestrictions: { country },
    types,
    locationRestriction,
    origin
  };

  googleMapsAutocompleteService.getPlacePredictions(predictionParams, results => {
    callback(results === null ? [] : results);
  });
}
interface GetPlaceParams {
  place_id: string;
  callback: (result: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => void;
}
export function getPlaceDetails({ place_id, callback }: GetPlaceParams) {
  const { googleMapsPlacesService } = window.codi;

  if (!googleMapsPlacesService) {
    return;
  }

  googleMapsPlacesService.getDetails(
    { placeId: place_id, fields: ['address_component', 'formatted_address', 'geometry', 'name', 'vicinity'] },
    callback
  );
}

interface GeocodeParams {
  location: google.maps.LatLngLiteral;
  callback: (results: google.maps.GeocoderResult[] | null) => void;
}

export function geocodeLocation({ location, callback }: GeocodeParams) {
  return new google.maps.Geocoder().geocode({ location }, callback);
}

export function initializeGoogleMapsAutocompleteService() {
  if (!window.codi.googleMapsAutocompleteService) {
    window.codi.googleMapsAutocompleteService = new google.maps.places.AutocompleteService();
  }
}

export function initializeGoogleMapsPlacesService() {
  if (!window.codi.googleMapsPlacesService) {
    window.codi.googleMapsPlacesService = new google.maps.places.PlacesService(document.createElement('div'));
  }
}

export function extractCityFromResults(results: google.maps.GeocoderResult[] | null) {
  if (!results) return;

  const result = results[0];

  if (!result) return null;

  const { address_components } = result;

  const city =
    address_components.find(ac => ac.types.includes('locality'))?.long_name ||
    address_components.find(ac => ac.types.includes('sublocality'))?.long_name;
  const region = address_components.find(ac => ac.types.includes('administrative_area_level_1'))?.short_name;
  const country = address_components.find(ac => ac.types.includes('country'))?.short_name;

  return { city, region, country };
}

export const GOOGLE_API_URL = 'https://maps.google.com/maps/api';

export function googleBoundsToCodiBounds(bounds: google.maps.LatLngBounds): Bounds {
  return {
    ne: bounds.getNorthEast().toJSON(),
    sw: bounds.getSouthWest().toJSON()
  };
}

export function isLatLngInBounds(latLng: google.maps.LatLngLiteral, bounds: google.maps.LatLngBoundsLiteral) {
  const { lat, lng } = latLng;
  const { north, south, east, west } = bounds;

  return lat >= south && lat <= north && lng >= west && lng <= east;
}
