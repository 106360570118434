import React, { useContext } from 'react';
import { WHITE_HEX } from '@codiwork/codi';

import { media } from 'context';
import { Img, Layout, Text } from 'ds';

import { ReactComponent as GradientQuote } from './gradient-quote.svg';

interface Props {
  quote: string;
  name: string;
  title: string;
  avatarSrcKey: string;
  subtitle: string;
}

const Testimonial: React.FC<Props> = ({ quote, name, title, subtitle, avatarSrcKey }) => {
  const { xs, sm } = useContext(media);

  return (
    <Layout
      color="blue-700"
      borderRadius={36}
      padding={xs ? 36 : 32}
      height="100%"
      direction="column"
      justify="space-between"
    >
      <Layout>
        <GradientQuote height={xs || sm ? 32 : 48} />
        <Layout marginTop={xs ? 24 : 32}>
          <Text color="white" size={xs || sm ? 'h6' : 'h5'}>
            {quote}
          </Text>
        </Layout>
      </Layout>
      <Layout marginTop={xs ? 24 : 32} flex>
        <Img
          srcKey={avatarSrcKey}
          alt={`${name} avatar`}
          width={256}
          folder="public"
          style={{
            borderRadius: '50%',
            border: `4px solid ${WHITE_HEX}`,
            width: xs || sm ? 52 : 88,
            height: xs || sm ? 52 : 88,
            objectFit: 'cover'
          }}
        />
        <Layout
          {...(xs
            ? { marginLeft: 12 }
            : { paddingY: 3.5, justify: 'space-between', direction: 'column', marginLeft: 19 })}
        >
          {xs ? (
            <>
              <Text color="white" size="body1">
                {name}
              </Text>
              <Text color="white" size="body1">
                {title}
              </Text>
              <Text color="white" size="body1">
                {subtitle}
              </Text>
            </>
          ) : (
            <>
              <p style={{ color: WHITE_HEX, fontSize: 22 }}>{name}</p>
              <p style={{ color: WHITE_HEX, fontSize: 22, fontWeight: 700 }}>{title}</p>
              <p style={{ color: WHITE_HEX, fontSize: 18 }}>{subtitle}</p>
            </>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Testimonial;
