import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react';

import Select from 'ds/Select';
import AddressInput from 'ds/inputs/AddressInput';
import EmailInput from 'ds/inputs/EmailInput';
import LocationInput, { OnLocationChangeParams } from 'ds/inputs/Location/LocationInput';
import PhoneInput from 'ds/inputs/PhoneInput';
import PostalCodeInput from 'ds/inputs/PostalCodeInput';
import TextArea from 'ds/inputs/TextArea';
import TextInput from 'ds/inputs/TextInput';
import { BaseProps, ValidateProp } from 'ds/inputs/types';
import { toNumberValue } from 'helpers';

import { COUNTRY_DATA } from '..';
import { EditableAttributes, Field } from './types';
import { updateFields } from './utils';
import Layout, { Props as LayoutProps } from '../Layout';
import Typeahead from '../Typeahead';
import NumberInput from '../inputs/NumberInput';

const INPUTS = {
  address: AddressInput,
  location: LocationInput,
  email: EmailInput,
  select: Select,
  text: TextInput,
  textArea: TextArea,
  postalCode: PostalCodeInput
} as const;

interface Props<T, T1 extends string, T2 = string>
  extends Omit<Field<T, T1>, 'value'>,
    Omit<BaseProps, 'onChange' | 'value'>,
    ValidateProp {
  setFields: Dispatch<SetStateAction<Field<T, T1, T2>[]>>;
  fields: Field<T, T1>[];
  inputContainerProps?: LayoutProps;
  value?: T2;
}

const FormInput = <T, T1 extends string>({
  type,
  value,
  name,
  options,
  fields,
  setFields,
  size,
  label,
  placeholder,
  required,
  disabled,
  autoFocus,
  error,
  min,
  max,
  inputContainerProps,
  onValidate
}: PropsWithChildren<Props<T, T1>>) => {
  useEffect(() => {
    if (type !== 'callingCode') return;

    const valid = !!COUNTRY_DATA.find(c => c.callingCode === value);

    setFields(fields => updateFields({ name, attributes: { valid }, fields }));
  }, [name, type, value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type !== 'typeahead') return;

    const valid = required && !value ? false : true;

    setFields(fields => updateFields({ name, attributes: { valid }, fields }));
  }, [name, type, value, required]); // eslint-disable-line react-hooks/exhaustive-deps

  if (type === 'callingCode') return null;

  const handleUpdate = <T2,>(attributes: Partial<EditableAttributes<T2>>) => {
    // TODO: write function with correct generics.
    // @ts-expect-error
    setFields(fields => updateFields({ name, attributes, fields }));
  };

  const sharedProps = {
    onLocationChange: ({ location }: OnLocationChangeParams) => {
      const updatedAttributes: Partial<EditableAttributes> = { value: location };

      handleUpdate(updatedAttributes);
    },
    hideIcon: type === 'location' ? true : undefined,
    onValidate:
      onValidate ||
      (({ valid }: { valid: boolean }) => {
        handleUpdate({ valid });
      }),
    name: typeof name === 'string' ? name : '',
    label: typeof label === 'string' ? label : '',
    placeholder: typeof placeholder === 'string' ? placeholder : undefined,
    size: size,
    required,
    disabled,
    autoFocus,
    error
  };

  const callingCodeField = fields.find(f => f.type === 'callingCode');
  const InputType = INPUTS[type as keyof typeof INPUTS];
  const handleChange = ({ value }: { value: string }) => {
    const updatedAttributes: Partial<EditableAttributes> = { value };

    if (error) {
      updatedAttributes.error = undefined;
    }

    handleUpdate(updatedAttributes);
  };

  const Input =
    type === 'phone' && callingCodeField ? (
      <PhoneInput
        {...sharedProps}
        onChange={handleChange}
        value={value}
        callingCode={typeof callingCodeField.value === 'string' ? callingCodeField.value || '1' : '1'}
        onCallingCodeChange={callingCode => {
          setFields(fields =>
            updateFields({
              name: callingCodeField.name,
              attributes: { ...callingCodeField, value: callingCode },
              fields
            })
          );
        }}
        size="sm"
      />
    ) : type === 'typeahead' && (typeof value === 'string' || !value) ? (
      <Typeahead
        {...sharedProps}
        results={options || []}
        query={value || ''}
        onChange={handleChange}
        onSelect={handleChange}
        showResultsWhenEmpty
        size="sm"
      />
    ) : type === 'number' || type === 'money' ? (
      <NumberInput
        {...sharedProps}
        value={toNumberValue(value)}
        min={min}
        max={max}
        onChange={({ value }: { value?: number }) => {
          const updatedAttributes: Partial<EditableAttributes<number>> = { value };

          if (error) {
            updatedAttributes.error = undefined;
          }

          handleUpdate(updatedAttributes);
        }}
        size="sm"
      />
    ) : type === 'workEmail' ? (
      <EmailInput
        {...sharedProps}
        invalidateNonWorkEmail
        onChange={handleChange}
        value={typeof value === 'string' ? value : ''}
        size="sm"
      />
    ) : (
      <InputType
        {...sharedProps}
        options={options || []}
        onChange={handleChange}
        value={typeof value === 'string' ? value : ''}
        size="sm"
        fullWidth
      />
    );

  return inputContainerProps ? <Layout {...inputContainerProps}>{Input}</Layout> : Input;
};

export default FormInput;
