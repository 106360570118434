import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

import { ReactComponent as TourIllustration } from './tour-illustration.svg';

interface Props {}

const BrokersTours: React.FC<Props> = () => {
  const { xs, md } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout maxWidth={468}>
        <Text size="h3" color="white">
          Working With Your Broker
        </Text>
        <Layout marginTop={24}>
          <Text size="h6" color="white" bold={false}>
            In your conversation with a broker (or brokers — don’t be afraid to talk to a few) ask them to provide 5–10
            options and schedule tours to see the three or four most promising properties in person.
          </Text>
        </Layout>
      </Layout>
      {!xs && (
        <Layout marginLeft={72}>
          <TourIllustration width={md ? 480 : 702} />
        </Layout>
      )}
    </PlaybookContentContainer>
  );
};

export default BrokersTours;
