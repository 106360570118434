import React, { useContext } from 'react';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';

import InfoCards from './InfoCards';

import { ReactComponent as SquiggleGraphic } from './nyc-tech-week-squiggle-graphic.svg';

interface Props {}

const EventDescription: React.FC<Props> = () => {
  const { windowWidth, contentPaddingX } = useContext(app);
  const { xs, sm, md, lg, xl } = useContext(media);

  if (xs) {
    return (
      <>
        <Layout
          paddingY={60}
          position="relative"
          paddingX={contentPaddingX}
          direction="column"
          align="center"
          color="blue-50"
          overflow="hidden"
          width={windowWidth}
        >
          <Text size="h5" color="blue-600">
            Join us for an AI Mixer 🍸✨
          </Text>
          <Layout width="100%" paddingX={32}>
            <InfoCards />
          </Layout>
          <Layout marginTop={32}>
            <Button
              text="Register now"
              size="md"
              type="primary"
              href="https://lu.ma/xqrdq86h"
              icon="rightArrow"
              iconPosition="right"
              iconStroke={2}
            />
          </Layout>
        </Layout>
        <Layout paddingTop={108} paddingBottom={40} paddingX={contentPaddingX} color="blue-50">
          <Text size="h5" color="blue-700">
            Explore more #Techweek events!
          </Text>
          <Layout marginTop={12}>
            <Text size="h6" color="blue-400">
              Stay up to date with all Codi Techweek events, including a ClimateTech breakfast and a Fintech panel, by
              subscribing to our event calendar.
            </Text>
          </Layout>
          <Layout marginTop={40} align="center">
            <Button text="Subcribe to our calendar" size="md" type="secondary" href="https://lu.ma/codi?k=c" />
          </Layout>
        </Layout>
        <Img
          srcKey="nyc-tech-week-office_t1MfMnEOO.jpeg"
          folder="public"
          alt="NYC office"
          width={800}
          style={{
            width: windowWidth,
            height: 232,
            objectFit: 'cover'
          }}
        />
      </>
    );
  }

  return (
    <Layout color="blue-50" justify="center" paddingY={80} position="relative">
      <Layout maxWidth={1200} zIndex={1}>
        <Layout
          flex={lg}
          align={sm || md ? 'center' : undefined}
          direction={sm || md ? 'column' : undefined}
          justify={sm || md || lg ? 'center' : undefined}
        >
          <Layout width={xl ? 548 : lg ? 460 : 420} paddingRight={32}>
            <Text color="blue-700" size="h4" align={sm || md ? 'center' : undefined}>
              Join us for an AI Mixer 🍸✨
            </Text>
            <Layout marginTop={24}>
              <Text size="h6" bold={false} align={sm || md ? 'center' : undefined}>
                …where AI founders match with tech experts to brainstorm AI integration into their products. Sip on
                craft cocktails and shake up your tech game!
              </Text>
            </Layout>
            <Layout marginTop={40} justify={sm || md ? 'center' : undefined}>
              <Button text="Register now" size="md" type="primary" href="https://lu.ma/xqrdq86h" />
            </Layout>
          </Layout>
          <Layout
            marginLeft={sm || md ? undefined : xl ? 80 : 48}
            width={572}
            marginTop={sm || md ? 64 : undefined}
            height={204}
            flex
          >
            <InfoCards />
          </Layout>
        </Layout>
        <Layout marginTop={140} paddingTop={80} align="center" paddingX={xl ? undefined : contentPaddingX}>
          <Img
            srcKey="nyc-tech-week-office_t1MfMnEOO.jpeg"
            folder="public"
            alt="NYC office"
            width={1200}
            style={{
              maxWidth: xl ? 572 : lg ? 502 : md ? 420 : 380,
              height: xl ? 382 : lg ? 340 : 320,
              borderRadius: 24,
              objectFit: 'cover'
            }}
          />
          <Layout marginLeft={80}>
            <Layout borderRadius={24}>
              <Text size="h4" color="blue-700">
                Explore more #Techweek events!
              </Text>
              <Layout marginTop={12}>
                <Text size="h6" color="blue-400">
                  Stay up to date with all Codi Techweek events, including a ClimateTech breakfast and a Fintech panel,
                  by subscribing to our event calendar.
                </Text>
              </Layout>
            </Layout>
            <Layout marginTop={40} align={sm ? undefined : 'center'}>
              <Button text="Subcribe to our calendar" size="md" type="secondary" href="https://lu.ma/codi?k=c" />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <SquiggleGraphic
        preserveAspectRatio="none"
        style={{ position: 'absolute', height: '100%', width: windowWidth, top: 0, right: 0, zIndex: 0 }}
      />
    </Layout>
  );
};

export default EventDescription;
