import React from 'react';

import Img, { Props as ImgProps } from './Img';
import { OnMeasure } from './types';

interface Props extends Omit<ImgProps, 'width' | 'height'> {
  size: 'tiny' | 'thumbnail' | 'sm' | 'lg' | 'hero';
  onMeasure?: OnMeasure;
}

const SIZE_CONFIG = {
  tiny: { width: 24, height: 24 },
  thumbnail: { width: 320, height: 320 },
  sm: { width: 600, height: 600 },
  lg: { width: 1200, height: 1200 },
  hero: { width: 3000, height: 1080 }
} as const;

const ProductImg: React.FC<Props> = ({ size, ...props }) => {
  return <Img {...props} {...SIZE_CONFIG[size]} />;
};

export default ProductImg;
