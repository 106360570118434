import React from 'react';

import Layout from 'ds/Layout';
import Spinner from 'ds/Spinner';

interface Props {}

const LoadingScreen: React.FC<Props> = () => {
  return (
    <Layout height="100vh" justify="center" align="center" flexGrow={1}>
      <Spinner />
    </Layout>
  );
};

export default LoadingScreen;
