import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const WhatToRemember: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Text size="h3" color="blue-600" scale={xs}>
          What To Remember
        </Text>
        <Layout marginTop={48} flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <Text size="h6" bold={false} color="gray-700">
              There are three things to remember when working with a coworking company during the agreement phase:
            </Text>
            <Layout
              marginTop={36}
              paddingTop={36}
              paddingX={32}
              paddingBottom={32}
              borderRadius={24}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              align="center"
            >
              <Layout width={64} height={64} align="center" justify="center">
                <Text size="h3" color="blue-gradient">
                  1
                </Text>
              </Layout>
              <Layout marginLeft={36}>
                <Text size="body1" color="gray-700">
                  Coworking companies are almost always paying a traditional lease for their space, then making a profit
                  by charging more per square foot than they are paying the landlord.
                </Text>
              </Layout>
            </Layout>
            <Layout
              marginTop={16}
              paddingTop={36}
              paddingX={32}
              paddingBottom={32}
              borderRadius={24}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              align="center"
            >
              <Layout width={64} height={64} align="center" justify="center">
                <Text size="h3" color="blue-gradient">
                  2
                </Text>
              </Layout>
              <Layout marginLeft={36}>
                <Text size="body1" color="gray-700">
                  Coworking companies make the most money when they have the highest density, and setting aside more
                  space for a single member limits their ability to pack more members into that space.
                </Text>
              </Layout>
            </Layout>
            <Layout
              marginTop={16}
              paddingTop={36}
              paddingX={32}
              paddingBottom={32}
              borderRadius={24}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              color="white"
              align="center"
            >
              <Layout width={64} height={64} align="center" justify="center">
                <Text size="h3" color="blue-gradient">
                  3
                </Text>
              </Layout>
              <Layout marginLeft={36}>
                <Text size="body1" color="gray-700">
                  Customization is bad for their business — it means more work, more staff time, and it slows down their
                  process.
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 24 : undefined} equalWidth>
            <Text size="h6" color="gray-700" bold={false}>
              Taken together, if you’re looking for a large section of their space and/or you need a lot of
              customization, the costs will mount quickly, as will the time needed to have the space ready. These costs
              will be folded into your monthly payment.
              <br />
              <br />
              Just like any negotiation, you can push back on certain elements, although you will likely have to
              compromise in some places. The sales teams at a coworking company have some flexibility, but the tight
              margins in the coworking industry only allow for so much wiggle room.
            </Text>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default WhatToRemember;
