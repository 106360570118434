import React, { PropsWithChildren, useContext } from 'react';

import { app, media } from 'context';
import { Layout } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const PlaybookContentContainer: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const { xs } = useContext(media);
  const { contentWidth, windowHeight } = useContext(app);

  return (
    <Layout
      minHeight={playbookContentHeight({ windowHeight })}
      position="relative"
      top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
      align="center"
      paddingTop={48}
      maxWidth={xs ? contentWidth : undefined}
    >
      {children}
    </Layout>
  );
};

export default PlaybookContentContainer;
