import { DotType } from './types';

const ACTIVE_LG_DOT = { isActive: true, size: 6 };
const LG_DOT = { isActive: false, size: 6 };
const MED_DOT = { isActive: false, size: 4 };
const SM_DOT = { isActive: false, size: 2 };
const NO_DOT = { isActive: false, size: 0 };

export function generateDots(length: number, activeIndex: number = 0): DotType[] {
  if (length <= 5) {
    const dots = new Array(length).fill({ ...LG_DOT });
    dots.splice(activeIndex, 1, { ...ACTIVE_LG_DOT });

    return dots;
  } else if (activeIndex <= 1) {
    const dots = new Array(length).fill({ ...NO_DOT });
    dots.splice(activeIndex, 1, { ...ACTIVE_LG_DOT });
    activeIndex === 0 ? dots.splice(activeIndex + 1, 1, { ...LG_DOT }) : dots.splice(activeIndex - 1, 1, { ...LG_DOT });
    dots.splice(2, 1, { ...LG_DOT });
    dots.splice(3, 1, { ...MED_DOT });
    dots.splice(4, 1, { ...SM_DOT });
    return dots;
  } else if (activeIndex >= length - 2) {
    const dots = new Array(length).fill({ ...NO_DOT });
    dots.splice(activeIndex, 1, { ...ACTIVE_LG_DOT });
    activeIndex === length - 1 ? dots.splice(length - 2, 1, { ...LG_DOT }) : dots.splice(length - 1, 1, { ...LG_DOT });
    dots.splice(length - 3, 1, { ...LG_DOT });
    dots.splice(length - 4, 1, { ...MED_DOT });
    dots.splice(length - 5, 1, { ...SM_DOT });
    return dots;
  } else {
    const dots = new Array(length).fill({ ...NO_DOT });
    dots.splice(activeIndex, 1, { ...ACTIVE_LG_DOT });
    dots.splice(activeIndex - 2, 1, { ...MED_DOT });
    dots.splice(activeIndex + 2, 1, { ...MED_DOT });
    dots.splice(activeIndex - 1, 1, { ...LG_DOT });
    dots.splice(activeIndex + 1, 1, { ...LG_DOT });
    return dots;
  }
}
