import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { app, media } from 'context';

import Control from './Control';
import { CONTROL_DIMENSION, IMAGE_TO_CAROUSEL_WIDTH_RATIO, ITEM_SPACING } from './constants';
import Layout from '../Layout';
import { ANIMATION_DURATION, IS_TOUCH_DEVICE } from '../constants';

export interface Props {
  children: ReactNode[];
  visibleCount?: number;
  itemWidth?: number;
  itemSpacing?: number;
  useExternalControl?: boolean;
  externalControlIndex?: number;
  overflowHidden?: boolean;
  scrollRegardlessOfWidth?: boolean;
  onMoveStart?: (params: { direction: Direction }) => void;
  onMoveEnd?: VoidFunction;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  onUpdateFirstVisibleIndex?: (index: number) => void;
  borderRadius?: number;
}

type Direction = 'left' | 'right';

const CarouselOld: React.FC<Props> = ({
  children: items,
  visibleCount = 1,
  onMouseDown,
  onMouseUp,
  itemSpacing = ITEM_SPACING,
  useExternalControl = false,
  externalControlIndex,
  overflowHidden = true,
  scrollRegardlessOfWidth = false,
  onMoveStart,
  onMoveEnd,
  onUpdateFirstVisibleIndex,
  borderRadius = 20,
  ...props
}) => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);
  const [firstVisibleIndex, _setFirstVisibleIndex] = useState<number>(0);
  const [dragStartX, setDragStartX] = useState<number>();
  const [dragDistance, setDragDistance] = useState<number>(0);
  const [width, setWidth] = useState<number>();
  const itemWidth = props.itemWidth || `${(IMAGE_TO_CAROUSEL_WIDTH_RATIO * 100) / visibleCount}%`;
  const useManualWidth = !!props.itemWidth;
  const maxVisibleItems = props.itemWidth && width ? Math.ceil(width / (props.itemWidth + itemSpacing)) : visibleCount;
  const right = props.itemWidth
    ? firstVisibleIndex * (props.itemWidth + itemSpacing) + dragDistance
    : `calc(${IMAGE_TO_CAROUSEL_WIDTH_RATIO * 100 * firstVisibleIndex}% + ${
        firstVisibleIndex * itemSpacing
      }px + ${dragDistance}px)`;
  const firstItemActive = firstVisibleIndex === 0;
  const lastItemActive = firstVisibleIndex === items.length - 1;
  const carouselRef = useRef<HTMLDivElement>(null);
  const carousel = carouselRef.current;
  const overflowing = scrollRegardlessOfWidth
    ? true
    : width
    ? !props.itemWidth || (!!(typeof itemWidth === 'number') && width < items.length * (itemWidth + itemSpacing))
    : true;

  const dragThreshold = xs ? 70 : 220;
  const lastItemRevealed = firstVisibleIndex + maxVisibleItems > items.length;

  const setFirstVisibleIndex = (index: number) => {
    _setFirstVisibleIndex(index);
    onUpdateFirstVisibleIndex && onUpdateFirstVisibleIndex(index);
  };

  useEffect(() => {
    if (externalControlIndex !== undefined) {
      setFirstVisibleIndex(externalControlIndex);
    }
  }, [externalControlIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const moveToLeft = () => {
    if (useManualWidth && lastItemRevealed) {
      setFirstVisibleIndex(0);
      return;
    }

    if (lastItemActive) {
      setFirstVisibleIndex(0);
      return;
    }

    setFirstVisibleIndex(firstVisibleIndex + 1);
  };

  useEffect(() => {
    if (!carousel) return;

    setWidth(carousel.clientWidth);
  }, [carousel, contentWidth]);

  const moveToRight = () => {
    if (useManualWidth && firstItemActive) {
      setFirstVisibleIndex(items.length + 1 - maxVisibleItems);
      return;
    }

    if (firstItemActive) {
      setFirstVisibleIndex(items.length - 1);
      return;
    }

    setFirstVisibleIndex(firstVisibleIndex - 1);
  };

  const resetDrag = () => {
    setDragStartX(undefined);
    setDragDistance(0);
  };

  const handleDrag = (x: number) => {
    if (dragStartX === undefined) return;

    if (dragDistance < -dragThreshold) {
      moveToRight();
      resetDrag();
    } else if (dragDistance > dragThreshold) {
      moveToLeft();
      resetDrag();
    } else {
      setDragDistance(dragStartX - x);
    }

    const direction = dragStartX - x > 0 ? 'left' : 'right';
    onMoveStart && onMoveStart({ direction });
  };

  if (!overflowing) {
    return (
      <Layout flex>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              marginRight: itemSpacing,
              flexShrink: 0,
              width: itemWidth
            }}
          >
            {item}
          </div>
        ))}
      </Layout>
    );
  }

  return (
    <div
      className={classNames('Carousel', { 'is-overflow-hidden': overflowHidden })}
      onMouseDown={e => {
        setDragStartX(e.clientX);
        onMouseDown && onMouseDown(e);
      }}
      onMouseMove={e => handleDrag(e.clientX)}
      onMouseUp={e => {
        resetDrag();
        onMoveEnd && onMoveEnd();
        onMouseUp && onMouseUp(e);
      }}
      onTouchStart={e => setDragStartX(e.touches[0].clientX)}
      onTouchMove={e => handleDrag(e.touches[0].clientX)}
      onTouchEnd={resetDrag}
      ref={carouselRef}
      style={{ borderTopLeftRadius: borderRadius, borderBottomLeftRadius: borderRadius }}
    >
      <div
        style={{
          position: 'relative',
          transition: dragStartX ? undefined : `${ANIMATION_DURATION}ms right`,
          display: 'flex',
          right
        }}
        onTransitionEnd={() => onMoveEnd && onMoveEnd()}
      >
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              marginRight: itemSpacing,
              flexShrink: 0,
              width: itemWidth
            }}
          >
            {item}
          </div>
        ))}
      </div>
      {IS_TOUCH_DEVICE || useExternalControl ? null : (
        <Layout
          position="absolute"
          left={useManualWidth ? undefined : `calc(${itemWidth} - ${CONTROL_DIMENSION / 2 - itemSpacing / 2}px)`}
          right={useManualWidth ? 16 : undefined}
          top={`calc(50% - ${CONTROL_DIMENSION / 2}px)`}
        >
          <Control
            handleClick={moveToLeft}
            onKeyDown={e => {
              if (e.key === 'ArrowRight') {
                moveToLeft();
              } else if (e.key === 'ArrowLeft') {
                moveToRight();
              }
            }}
          />
        </Layout>
      )}
    </div>
  );
};

export default CarouselOld;
