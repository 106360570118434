import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';
import { WE_WORK_DISCOUNT_PATH } from 'routes';

interface Props {}

const WeWorkBanner: React.FC<Props> = () => {
  const { isMobile } = useContext(media);

  return (
    <Link href={WE_WORK_DISCOUNT_PATH} fullWidth>
      <Layout color="blue-50" {...(isMobile ? { height: 84, justify: 'center' } : { justify: 'center', height: 56 })}>
        <Layout {...(isMobile ? { justify: 'center', direction: 'column' } : { align: 'center' })}>
          <Text size="body-xs" semibold color="blue-600">
            Impacted by WeWork’s struggles?
          </Text>
          <Layout {...(isMobile ? { marginTop: 12 } : {})} align="center">
            <Layout
              {...(isMobile
                ? { display: 'inline-flex', marginRight: 4 }
                : { display: 'inline-flex', marginLeft: 12, marginRight: 4 })}
            >
              <Icon name="heart" color="blue-gradient" size="xs" />
            </Layout>
            <Text size="body-xs" semibold color="blue-500">
              Access our Relief Fund today
            </Text>
            <Layout marginLeft={4} {...(isMobile ? { display: 'inline-flex' } : { display: 'inline-flex' })}>
              <Icon name="rightChevron" size="xxs" color="blue-500" stroke={2} />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Link>
  );
};

export default WeWorkBanner;
