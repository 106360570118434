import React from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from 'helpers/auth';

import { Clickable, Icon, Layout, Link, Text } from 'ds';
import { ADMIN_PATH, APP_ROOT_PATH } from 'routes';
import { selectIsAdmin } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

interface Props {
  textSize: 'body2';
  setMenuOpen: (open: boolean) => void;
}

const AccountMenu: React.FC<Props> = ({ textSize, setMenuOpen }) => {
  const isAdmin = useAppSelector(selectIsAdmin);
  const history = useHistory();

  return (
    <Layout
      position="absolute"
      right={0}
      top={40}
      color="white"
      borderRadius={8}
      width={176}
      __style={{ boxShadow: 'rgba(87, 73, 109, 0.12) 0px 2px 12px 0px' }}
    >
      <Layout paddingY={8}>
        {isAdmin && (
          <Layout hoverColor="blue-50">
            <Link onClick={() => setMenuOpen(false)} href={ADMIN_PATH} opacity={false} fullWidth>
              <Layout align="center" paddingX={12} paddingY={12}>
                <Icon name="membership" size="xs" color="gray-900" />
                <Layout marginLeft={12}>
                  <Text color="black" size={textSize} inline>
                    Admin Portal
                  </Text>
                </Layout>
              </Layout>
            </Link>
          </Layout>
        )}
        <Link onClick={() => setMenuOpen(false)} href={APP_ROOT_PATH} opacity={false} fullWidth>
          <Layout align="center" hoverColor="blue-50" paddingX={12} paddingY={12}>
            <Icon name="dashboard" size="xs" color="gray-900" />
            <Layout marginLeft={12}>
              <Text color="black" size={textSize} inline>
                {isAdmin ? 'User ' : ''}Dashboard
              </Text>
            </Layout>
          </Layout>
        </Link>
      </Layout>
      <Layout borderColor="gray-50" paddingY={8} borderTop>
        <Clickable
          context="account menu"
          onClick={() => {
            setMenuOpen(false);
            logout({ history });
          }}
          style={{ width: '100%' }}
        >
          <Layout align="center" hoverColor="blue-50" paddingY={20} paddingX={12}>
            <Icon name="meetingRoom" size="xs" color="gray-900" />
            <Layout marginLeft={12}>
              <Text size={textSize}>Sign Out</Text>
            </Layout>
          </Layout>
        </Clickable>
      </Layout>
    </Layout>
  );
};

export default AccountMenu;
