import React, { useContext } from 'react';
import { IconName } from '@codiwork/codi';

import { media } from 'context';
import { Grid, Icon, Layout, Text } from 'ds';

interface Props {
  highlights: { icon: IconName; text: string }[];
}

const CaseStudyHighlights: React.FC<Props> = ({ highlights }) => {
  const { xs } = useContext(media);
  return (
    <Layout>
      <Text size="h5">Story Highlights</Text>
      <Layout marginTop={24}>
        <Grid gap={12} itemsPerRow={xs ? 1 : 2}>
          {highlights.map(({ icon, text }) => {
            return (
              <Layout
                key={text}
                color="white"
                padding={24}
                boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
                borderRadius={8}
                align="center"
              >
                <Icon name={icon} color="blue-gradient" size={xs ? 44 : 36} />
                <Layout marginLeft={20}>
                  <Text size="body2">{text}</Text>
                </Layout>
              </Layout>
            );
          })}
        </Grid>
      </Layout>
    </Layout>
  );
};

export default CaseStudyHighlights;
