import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Market } from '@codiwork/codi';

import OffsiteRequestForm from 'components/OffsiteRequestForm';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

import { SearchLocation } from './SearchEntry/types';
import { officeRequestLocationFromSearchLocation } from './SearchEntry/utils';

interface Props {
  searchLocation?: SearchLocation;
  market?: Market;
  offsitesOnly?: boolean;
}

const NoResults: React.FC<Props> = ({ searchLocation, market, offsitesOnly }) => {
  const { xs } = useContext(media);
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { contentPaddingX } = useContext(app);
  const [offsiteRequestFormIsVisible, setOffsiteRequestFormIsVisible] = useState<boolean>(false);

  const requestAnOffice = () => {
    dispatch(officeRequestActions.clearLocations());

    if (searchLocation && market) {
      dispatch(officeRequestActions.setMarket(market.name));
      officeRequestLocationFromSearchLocation({
        searchLocation,
        market
      }).then(officeRequestLocation => {
        if (!!officeRequestLocation) dispatch(officeRequestActions.addLocation(officeRequestLocation));
        dispatch(officeRequestActions.setLaunchPath(pathname));
        history.push(OFFICE_REQUEST_PATH);
      });
    } else {
      dispatch(officeRequestActions.setLaunchPath(pathname));
      history.push(OFFICE_REQUEST_PATH);
    }
  };

  return (
    <Layout>
      <Layout justify="center" align="center">
        <Layout position="relative" height="fit-content" zIndex={0} left={32}>
          <Img
            srcKey="no-results-2_YJn-qkog6.jpeg"
            folder="public"
            alt="bright office"
            width={500}
            style={{
              width: xs ? 114 : 160,
              height: xs ? 64 : 92,
              objectFit: 'cover',
              borderRadius: 12,
              filter: 'blur(4px)',
              transform: 'matrix(-1, 0, 0, 1, 0, 0)'
            }}
          />
          <Layout
            background="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            borderRadius={16}
          />
        </Layout>
        <Layout zIndex={1}>
          <Img
            srcKey="Embarcadero_57tTiMVbD.jpg"
            folder="public"
            alt="bright office"
            width={500}
            style={{
              width: xs ? 152 : 218,
              height: xs ? 96 : 126,
              objectFit: 'cover',
              borderRadius: 12
            }}
          />
        </Layout>
        <Layout position="relative" height="fit-content" zIndex={0} right={32}>
          <Img
            srcKey="Embarcadero-3_b8DlYujEU.jpg"
            folder="public"
            alt="bright office"
            width={500}
            style={{
              width: xs ? 114 : 160,
              height: xs ? 64 : 92,
              objectFit: 'cover',
              borderRadius: 12,
              filter: 'blur(4px)'
            }}
          />
          <Layout
            background="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            borderRadius={16}
          />
        </Layout>
      </Layout>
      <Layout marginTop={24}>
        <Text size={xs ? 'h6' : 'h5'} color="blue-700" align="center" scale>
          No results? No problem.
        </Text>
      </Layout>
      {offsitesOnly ? (
        <Layout>
          <OffsiteRequestForm
            isVisible={offsiteRequestFormIsVisible}
            onCloseModal={() => setOffsiteRequestFormIsVisible(false)}
          />
          <Layout marginTop={24} {...(xs ? { paddingX: contentPaddingX } : { maxWidth: 528 })}>
            <Text size="body1" color="gray-600" align="center" scale>
              We can find you the perfect location for an offsite anywhere.
            </Text>
          </Layout>
          <Layout justify="center" marginTop={24}>
            <Button
              type="primary"
              size="sm"
              icon="rightArrow"
              iconStroke={2}
              text="Request an offsite"
              onClick={() => setOffsiteRequestFormIsVisible(true)}
            />
          </Layout>
        </Layout>
      ) : (
        <>
          <Layout justify="center">
            <Layout marginTop={24} {...(xs ? { paddingX: contentPaddingX } : { maxWidth: 528 })}>
              <Text size="body1" color="gray-600" align="center" scale>
                We can unlock an office with unbeatable terms anywhere
                {searchLocation ? ` — even in ${searchLocation.name}` : ''}. Get started by sharing your preferences.
              </Text>
            </Layout>
          </Layout>
          <Layout marginTop={24} justify="center">
            <Button
              type="primary"
              size={xs ? 'sm' : 'md'}
              text="Request an office"
              onClick={requestAnOffice}
              icon="rightArrow"
              iconStroke={2}
            />
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default NoResults;
