import React, { useEffect, useState } from 'react';
import { validatePostalCode } from '@codiwork/codi';

import Input from './Input';
import { BaseProps, OnChangeParams, ValidateProp } from './types';

export interface Props extends BaseProps, ValidateProp {}

const PostalCodeInput: React.FC<Props> = ({
  value,
  onChange,
  error,
  size,
  onValidate,
  onBlur,
  required = false,
  disabled,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onChangeWrapper = ({ value: newValue, event }: OnChangeParams) => {
    const value = newValue.toUpperCase();

    onChange({ value, event });

    validate(value).then(r => {
      if (r.valid) {
        setErrorMessage(undefined);
      }

      onValidate && onValidate(r);
    });
  };

  const validate = async (value: string) => {
    const validationResult = await validatePostalCode(value, required);

    return validationResult;
  };

  // validate on mount if a value is passed in
  useEffect(() => {
    validate(value || '').then(r => {
      value && setErrorMessage(r.error);
      onValidate && onValidate(r);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Input
      {...props}
      onBlur={e => {
        const value: string = e.value;
        validate(value).then(r => setErrorMessage(r.error));
        onBlur && onBlur({ ...e, value });
      }}
      name="postalCode"
      value={value}
      label="Postal code"
      placeholder="Postal code"
      onChange={onChangeWrapper}
      size={size}
      error={error || errorMessage}
      required={required}
      disabled={disabled}
    />
  );
};

export default PostalCodeInput;
