import React from 'react';
import { OfficeLeadTour, formatDate } from '@codiwork/codi';

import { Icon, Layout, Text } from 'ds';

import { getNextUpcomingTour, getTourForWorkspace } from './utils';

interface Props {
  tours: OfficeLeadTour[];
  workspaceId: number;
  textColor?: 'white';
}

const TourStatusLarge: React.FC<Props> = ({ tours, workspaceId, textColor }) => {
  const tour = getTourForWorkspace({ tours, workspaceId: workspaceId });
  const upcomingTour = getNextUpcomingTour(tours);
  const isTourDestination = upcomingTour?.destinations.find(d => d.workspace.id === workspaceId);
  const isTourDestinationRequest = upcomingTour?.destination_requests.find(d => d.workspace.id === workspaceId);

  if (tour && ['pending', 'scheduled'].includes(tour.status)) {
    if (isTourDestination) {
      return (
        <Layout padding={16} justify="center" color="gold-300" borderRadius={8} align="center">
          <Layout marginRight={4} display="inline-flex">
            <Icon name="calendar" size="xs" />
          </Layout>
          <Text size="body-xs" semibold>
            Touring {formatDate({ date: tour.date, format: 'SHORTEST' })}
          </Text>
        </Layout>
      );
    } else if (isTourDestinationRequest) {
      return (
        <Layout paddingY={16} justify="center" borderRadius={8} align="center">
          <Layout marginRight={4} display="inline-flex">
            <Icon name="clock" color={textColor} size="xs" />
          </Layout>
          <Text size="body-xs" color={textColor} semibold>
            Tour requested
          </Text>
        </Layout>
      );
    } else {
      return null;
    }
  } else if (tour?.status === 'completed') {
    return (
      <Layout padding={16} justify="center" color="white" borderRadius={8}>
        <Layout marginRight={4} display="inline-flex">
          <Icon name="checkmark" size="xs" color="blue-600" />
        </Layout>
        <Text color="blue-600" size="body-xs" semibold>
          Toured {formatDate({ date: tour.date, format: 'SHORTEST' })}
        </Text>
      </Layout>
    );
  } else {
    return null;
  }
};

export default TourStatusLarge;
