import React from 'react';

import Icon from './Icon';
import Layout from './Layout';
import Text from './Text';

interface Props {}

const RecommendedTag: React.FC<Props> = () => {
  return (
    <Layout paddingY={4} color="blue-100" borderRadius={4} paddingX={10} align="center">
      <Icon name="sparkle" size="xs" color="blue-600" />
      <Layout marginLeft={4} display="inline-flex">
        <Text size="body-xs" color="blue-600" semibold>
          FOR YOU
        </Text>
      </Layout>
    </Layout>
  );
};

export default RecommendedTag;
