import React, { useContext } from 'react';

import { media } from 'context';
import { Button, Layout, Text } from 'ds';
import { replaceValues } from 'ds/utils';
import { COMPARE_PATH } from 'routes';

import { COMPARE_DETAIL_PREFIX } from '../constants';

interface Props {
  title: string;
  comparison_title: string;
  cta: string;
  comparisons: { competitor: string; description: string }[];
}

const CompareSection: React.FC<Props> = ({ title, comparison_title, cta, comparisons }) => {
  const { xs } = useContext(media);
  const itemGutterX = 24;

  return (
    <Layout>
      <Text size="h4" align="center" scale>
        {title}
      </Text>
      <Layout marginTop={xs ? 24 : 48} wrap>
        {comparisons.map(({ competitor, description }, index) => {
          const isEvenIndex = index % 2 === 0;
          return (
            <Layout
              marginRight={!xs && isEvenIndex ? 24 : 0}
              marginLeft={!xs && !isEvenIndex ? 24 : 0}
              width={xs ? undefined : `calc(50% - ${itemGutterX}px)`}
              marginTop={xs ? 24 : index > 1 ? 48 : 0}
              key={competitor}
            >
              <Text size="h5" scale>
                {replaceValues(comparison_title, [{ value: competitor, variable: 'competitor' }])}
              </Text>
              <Layout marginTop={12}>
                <Text size="body1" scale>
                  {description}
                </Text>
              </Layout>
              <Layout marginTop={12}>
                <Button
                  type="secondaryOutline"
                  text={cta}
                  href={`${COMPARE_PATH}/${COMPARE_DETAIL_PREFIX}${competitor}`}
                />
              </Layout>
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default CompareSection;
