import { FocusEventHandler, forwardRef, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BLUE_100_HEX } from '@codiwork/codi';

import { media } from 'context';
import Animate from 'ds/Animate';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import { selectInputFocusOrdinal } from 'store/Chatbot/selectors';

import { CHATBOT_THREAD_INPUT_HEIGHT } from './constants';

interface Props {
  handleSubmit: () => void;
  disabled: boolean;
  input: string;
  onChange: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const ChatbotThreadInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, input, handleSubmit, onFocus, onBlur }, ref) => {
    const { isMobile } = useContext(media);
    const inputFocusOrdinal = useSelector(selectInputFocusOrdinal);

    useEffect(() => {
      if (inputFocusOrdinal === 0) return;

      if (ref && 'current' in ref) {
        ref.current?.focus();
      }
    }, [inputFocusOrdinal]); // eslint-disable-line react-hooks/exhaustive-deps

    const Content = (
      <Layout align="center" borderRadius={1000} color="blue-100" paddingRight={8} flexGrow={1}>
        <input
          className="Chatbot-threadInput"
          autoFocus
          value={input}
          onChange={e => onChange(e.target.value)}
          onKeyDown={({ key }) => {
            if (ref && 'current' in ref) {
              if (document.activeElement !== ref.current) {
                return;
              }
            }

            if (!input) return;

            if (key === 'Enter') {
              handleSubmit();
            }
          }}
          onFocus={onFocus}
          autoComplete="off"
          ref={ref}
          spellCheck={false}
          style={{
            backgroundColor: BLUE_100_HEX,
            border: 'none',
            padding: '8px 20px',
            height: 48,
            flexGrow: 1,
            borderTopLeftRadius: 1000,
            borderBottomLeftRadius: 1000
          }}
          placeholder="Message..."
          onBlur={onBlur}
        />
        <IconButton size="sm" type="primaryBlue" name="upArrow" onClick={handleSubmit} stroke={2} disabled={!input} />
      </Layout>
    );

    return isMobile ? (
      <Layout color="blue-50" height={CHATBOT_THREAD_INPUT_HEIGHT} paddingX={24} align="center">
        {Content}
      </Layout>
    ) : (
      <Animate>
        <Layout color="blue-50" height={CHATBOT_THREAD_INPUT_HEIGHT} paddingX={24} align="center">
          {Content}
        </Layout>
      </Animate>
    );
  }
);

export default ChatbotThreadInput;
