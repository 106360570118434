import React from 'react';
import { BLUE_500_HEX } from '@codiwork/codi';
import hexToRgb from '@codiwork/codi/lib/utils/hexToRgb';
import { ChildComponentProps } from 'google-map-react';

import Layout from './Layout';

const GoogleMapsMarker: React.FC<ChildComponentProps> = () => {
  const { r, g, b } = hexToRgb(BLUE_500_HEX);

  return (
    <Layout
      position="relative"
      borderRadius="circular"
      width={96}
      height={96}
      align="center"
      justify="center"
      right={48}
      bottom={48}
      __style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.15)` }}
    >
      <Layout color="white" borderRadius="circular" width={24} height={24} align="center" justify="center">
        <Layout width={8} height={8} color="blue-500" borderRadius="circular"></Layout>
      </Layout>
    </Layout>
  );
};

export default GoogleMapsMarker;
