import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

interface Props {
  text: string;
}

const ValueProp: React.FC<Props> = ({ text }) => {
  const { xs } = useContext(media);

  return (
    // height: 90 is a <hack> to make sure all slides are the same height
    <Layout marginY={24} {...(xs ? { height: 90 } : {})}>
      <Text size={xs ? 'body-lg' : 'body-xl'} color="blue-500" {...(xs ? { maxLines: 3 } : {})}>
        {text}
      </Text>
    </Layout>
  );
};

export default ValueProp;
