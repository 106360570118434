import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { usePreviousValue } from 'beautiful-react-hooks';

interface Params {
  size: number;
  handleEnter: (focusedIndex: number) => void;
  divId: string;
  autoSelectFirstItem?: boolean;
  handleDownArrow?: VoidFunction;
  handleUpArrow?: VoidFunction;
}

function useListFocus({
  size,
  handleEnter,
  divId,
  autoSelectFirstItem = true,
  handleDownArrow,
  handleUpArrow
}: Params): [number, Dispatch<SetStateAction<number>>] {
  const [focusedIndex, setFocusedIndex] = useState<number>(autoSelectFirstItem ? 0 : -1);
  const div = document.getElementById(divId);
  const previousAutoSelectFirstItem = usePreviousValue(autoSelectFirstItem);

  const handleKeyDown = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (e.keyCode === 40) {
        // Down arrow
        e.preventDefault();
        setFocusedIndex(focusedIndex === size - 1 ? 0 : focusedIndex + 1);
        handleDownArrow && handleDownArrow();
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        setFocusedIndex(focusedIndex === 0 ? size - 1 : focusedIndex - 1);
        handleUpArrow && handleUpArrow();
      } else if (e.keyCode === 13) {
        // Enter
        handleEnter(focusedIndex);
      }
    },
    [focusedIndex, handleEnter, size] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!div) return;

    div.addEventListener('keydown', handleKeyDown, false);
    return () => {
      div.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [div, handleKeyDown]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (autoSelectFirstItem && !previousAutoSelectFirstItem) {
      setFocusedIndex(0);
    } else if (!autoSelectFirstItem && previousAutoSelectFirstItem) {
      setFocusedIndex(-1);
    }
  }, [autoSelectFirstItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return [focusedIndex, setFocusedIndex];
}

export default useListFocus;
