import React, { useContext } from 'react';

import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Grid, Icon, Img, Layout, Link, Text } from 'ds';
import { HOW_IT_WORKS_PATH } from 'routes';

interface Props {}

const AllInclusive: React.FC<Props> = () => {
  const { isMobile, sm, md } = useContext(media);
  const { contentPaddingX } = useContext(app);

  return isMobile ? (
    <Layout>
      <HeroImage
        imageKey="home-page-all-inclusive_Mhzrph_Oj.jpeg"
        imageKitFolder="public"
        imgWidth={1600}
        style={{
          height: 390,
          objectFit: 'cover'
        }}
        accountForPaddingX={sm}
      />
      <Layout paddingX={contentPaddingX} marginTop={64}>
        <Text size="headline-lg" color="blue-500">
          All-inclusive office
        </Text>
      </Layout>
      <Layout paddingX={contentPaddingX} marginTop={32}>
        <Text size="body-md" color="gray-400">
          Every Codi office comes standard with everything you need to get your best work done.
        </Text>
      </Layout>
      <Layout marginTop={24} padding={24} justify="center">
        <Layout flexGrow={1}>
          <Grid itemsPerRow={2} gapX={36} gapY={48}>
            <Layout direction="column" align="center">
              <Icon name="settings" size="xl" color="gray-400" />
              <Layout marginY={16}>
                <Text size="body-md" color="gray-400" align="center" semibold>
                  Rent
                </Text>
              </Layout>
            </Layout>
            <Layout direction="column" align="center">
              <Icon name="wifi" size="xl" color="gray-400" />
              <Layout marginY={16}>
                <Text size="body-md" color="gray-400" align="center" semibold>
                  Fast WiFi
                </Text>
              </Layout>
            </Layout>
            <Layout direction="column" align="center">
              <Icon name="chair" size="xl" color="gray-400" />
              <Layout marginY={16}>
                <Text size="body-md" color="gray-400" align="center" semibold>
                  Furniture
                </Text>
              </Layout>
            </Layout>
            <Layout direction="column" align="center">
              <Icon name="beverage" size="xl" color="gray-400" />
              <Layout marginY={16}>
                <Text size="body-md" color="gray-400" align="center" semibold>
                  Beverages
                </Text>
              </Layout>
            </Layout>
            <Layout direction="column" align="center">
              <Icon name="cleaning" size="xl" color="gray-400" />
              <Layout marginY={16}>
                <Text size="body-md" color="gray-400" align="center" semibold>
                  Cleaning
                </Text>
              </Layout>
            </Layout>
            <Layout direction="column" align="center">
              <Icon name="clipboard" size="xl" color="gray-400" />
              <Layout marginY={16}>
                <Text size="body-md" color="gray-400" align="center" semibold>
                  Office management
                </Text>
              </Layout>
            </Layout>
          </Grid>
        </Layout>
      </Layout>
      <Layout paddingX={contentPaddingX} justify="center" marginTop={24}>
        <Link href={HOW_IT_WORKS_PATH}>
          <Layout align="center">
            <Text size="body-md" color="blue-500" semibold>
              Learn more
            </Text>
            <Layout marginLeft={8} display="inline-flex">
              <Icon name="rightArrow" color="blue-500" size="sm" stroke={2} />
            </Layout>
          </Layout>
        </Link>
      </Layout>
    </Layout>
  ) : (
    <Layout position="relative">
      <Img
        srcKey="home-page-all-inclusive_Mhzrph_Oj.jpeg"
        folder="public"
        alt="office"
        width={1600}
        style={{
          borderRadius: 16,
          maxWidth: md ? 460 : 514,
          height: md ? 380 : 500,
          objectFit: 'cover',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 0
        }}
      />
      <Layout zIndex={1} position="relative">
        <Layout maxWidth={490}>
          <Text size="display-sm" color="blue-500">
            All-inclusive office
          </Text>
          <Layout marginTop={32}>
            <Text color="gray-400" size="body-md">
              Every Codi office comes standard with everything you need to get your best work done.
            </Text>
          </Layout>
          <Layout marginTop={32}>
            <Link href={HOW_IT_WORKS_PATH}>
              <Layout align="center">
                <Text size="body-sm" color="blue-500" semibold>
                  Learn more
                </Text>
                <Layout marginLeft={8} display="inline-flex">
                  <Icon name="rightArrow" color="blue-500" size="sm" stroke={2} />
                </Layout>
              </Layout>
            </Link>
          </Layout>
        </Layout>
        <Layout marginTop={36} maxWidth={668}>
          <Grid itemsPerRow={3} gapX={16} gapY={16}>
            <Layout
              color="white"
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              paddingX={24}
              paddingY={36}
              borderRadius={16}
            >
              <Icon name="settings" size="xl" color="blue-500" />
              <Layout marginY={16}>
                <Text size="body-md" color="blue-500" semibold>
                  Rent
                </Text>
              </Layout>
              <Text color="gray-400" size="body-sm">
                Fully serviced; includes monthly rent and maintenance
              </Text>
            </Layout>
            <Layout
              color="white"
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              paddingX={24}
              paddingY={36}
              borderRadius={16}
            >
              <Icon name="wifi" size="xl" color="blue-500" />
              <Layout marginY={16}>
                <Text size="body-md" color="blue-500" semibold>
                  Fast WiFi
                </Text>
              </Layout>
              <Text color="gray-400" size="body-sm">
                High-speed internet service and networking equipment
              </Text>
            </Layout>
            <Layout
              color="white"
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              paddingX={24}
              paddingY={36}
              borderRadius={16}
            >
              <Icon name="chair" size="xl" color="blue-500" />
              <Layout marginY={16}>
                <Text size="body-md" color="blue-500" semibold>
                  Furniture
                </Text>
              </Layout>
              <Text color="gray-400" size="body-sm">
                Lounges, ergonomic workstations, white boards, and more
              </Text>
            </Layout>
            <Layout
              color="white"
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              paddingX={24}
              paddingY={36}
              borderRadius={16}
            >
              <Icon name="beverage" size="xl" color="blue-500" />
              <Layout marginY={16}>
                <Text size="body-md" color="blue-500" semibold>
                  Beverages
                </Text>
              </Layout>
              <Text color="gray-400" size="body-sm">
                Tea, coffee, and sparkling water — restocked monthly
              </Text>
            </Layout>
            <Layout
              color="white"
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              paddingX={24}
              paddingY={36}
              borderRadius={16}
            >
              <Icon name="cleaning" size="xl" color="blue-500" />
              <Layout marginY={16}>
                <Text size="body-md" color="blue-500" semibold>
                  Cleaning
                </Text>
              </Layout>
              <Text color="gray-400" size="body-sm">
                Weekly cleaning, kitchen, bathroom supplies
              </Text>
            </Layout>
            <Layout
              color="white"
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              paddingX={24}
              paddingY={36}
              borderRadius={16}
            >
              <Icon name="clipboard" size="xl" color="blue-500" />
              <Layout marginY={16}>
                <Text size="body-md" color="blue-500" semibold>
                  Office management
                </Text>
              </Layout>
              <Text color="gray-400" size="body-sm">
                Fractional office management with on-site support
              </Text>
            </Layout>
          </Grid>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AllInclusive;
