import { EditableAttributes, Field } from './types';

export const updateFields = <T, T1 extends string, T2 = string>({
  name,
  attributes,
  fields
}: {
  name: T;
  attributes: Partial<EditableAttributes<T2>>;
  fields: Field<T, T1, T2>[];
}) => {
  const index = fields.findIndex(f => f.name === name);
  const updatedFields = [...fields];
  updatedFields[index] = { ...updatedFields[index], ...attributes };

  return updatedFields;
};

export const getField = <T, T1 extends string, T2, T3 extends T>(
  fields: Field<T, T1, T2>[],
  fieldName: T3
): Field<T, T1, T2> => {
  return fields.find(f => f.name === fieldName)!;
};

export const getValue = <T, T1 extends string, T2 = string>(
  fields: Field<T, T1, T2>[],
  fieldName: T
): T2 | undefined => {
  return getField(fields, fieldName).value;
};

export const getValues = <T extends string, T1 extends string, T2 = string>(
  fields: Field<T, T1, T2>[]
): Partial<Record<T, T2 | undefined>> => {
  return fields.reduce<Partial<Record<T, T2 | undefined>>>((response, field) => {
    response[field.name] = getValue(fields, field.name);

    return response;
  }, {});
};

export const formValid = (fields: Field<any, any>[]): boolean => {
  const requiredFieldValues = fields.filter(f => f.required).map(f => f.value);
  const validatedFieldValues = fields.filter(f => f.valid !== undefined).map(f => f.valid);

  return requiredFieldValues.every(Boolean) && validatedFieldValues.every(Boolean);
};

export const isFormPristine = (fields: Field<any, any>[]): boolean =>
  fields.every(field => field.value === field.initialValue);

export const setInitialValues = <T extends string, T1 extends string>(
  fields: Field<T, T1>[],
  values: Record<T, string>
): Field<T, T1>[] => {
  for (const fieldName in values) {
    const field = getField(fields, fieldName);
    const value = values[fieldName];

    if (value !== undefined) {
      field.value = value;
      field.initialValue = value;
    }
  }

  return fields;
};
