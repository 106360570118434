import { CreateableWorkspace } from '@codiwork/codi';

export function isCreatableProperty(property: Partial<CreateableWorkspace>): property is CreateableWorkspace {
  const { first_hosting_availability, address, square_feet, building_class, workspace_price } = property;

  return (
    !!first_hosting_availability && !!address && !!square_feet && !!workspace_price?.asking_rent && !!building_class
  );
}

export const rentPerSquareFootPerYearToAskingRent = (rentPerSquareFootPerYear?: number, squareFeet?: number) => {
  return !!rentPerSquareFootPerYear && !!squareFeet ? (rentPerSquareFootPerYear * squareFeet) / 12 : undefined;
};

export const askingRentToRentPerSquareFootPerYear = (askingRent?: number, squareFeet?: number) => {
  return !!askingRent && !!squareFeet ? (askingRent * 12) / squareFeet : undefined;
};
