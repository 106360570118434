import React from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

interface Props {
  label: string;
  image: JSX.Element;
}

const MediaTag: React.FC<Props> = ({ image, label }) => {
  return (
    <Layout
      align="center"
      background="rgba(20, 24, 29, 0.4)"
      borderRadius={4}
      __style={{ backdropFilter: 'blur(20px)' }}
      paddingY={3}
      paddingX={8}
    >
      {image}
      <Layout marginLeft={4}>
        <Text size="tag" color="gray-25">
          {label}
        </Text>
      </Layout>
    </Layout>
  );
};

export default MediaTag;
