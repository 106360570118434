import React, { useState } from 'react';
import { DateTimeFormat } from '@codiwork/codi';
import { Placement } from '@popperjs/core';

import { FieldSize } from 'ds/types';

import DatePickerContainer from './DatePickerContainer';
import DateRangePickerCalendar from './DateRangePickerCalendar';
import { CalendarDisplayState, DateRangePickerProps } from './types';

export interface Props extends DateRangePickerProps {
  size?: FieldSize;
  dateTimeFormat?: DateTimeFormat;
  placement?: Placement;
  setCalendarElement?: (calendar: HTMLDivElement | null) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

const DateRangePicker: React.FC<Props> = ({
  startDate,
  endDate,
  onChange,
  size,
  allowPastDates,
  minDate,
  maxDate,
  dateTimeFormat = 'MED_WITH_SHORT_WEEKDAY',
  setCalendarElement,
  excludeDateIntervals = [],
  placeholder = 'Date',
  disabled,
  label
}) => {
  const [calendarState, setCalendarState] = useState<CalendarDisplayState>('hidden');
  const [dateRangerPickerCalendarOrdinal, setDateRangePickerCalendarOrdinal] = useState<number>(0);
  const onClear = () => {
    setDateRangePickerCalendarOrdinal(dateRangerPickerCalendarOrdinal + 1);
    onChange([null, null]);
  };

  return (
    <DatePickerContainer
      label={label}
      startDate={startDate}
      endDate={endDate}
      dateTimeFormat={dateTimeFormat}
      size={size}
      calendarState={calendarState}
      setCalendarState={setCalendarState}
      placeholder={placeholder}
      onClear={onClear}
      calendarClassName="DateRangePickerCalendar"
      onClose={() => {
        if (startDate && !endDate) {
          onChange([startDate, startDate]);
        }
      }}
      setCalendarElement={setCalendarElement}
      disabled={disabled}
      isRange
    >
      <DateRangePickerCalendar
        key={dateRangerPickerCalendarOrdinal}
        excludeDateIntervals={excludeDateIntervals}
        startDate={startDate}
        endDate={endDate}
        onChange={dates => {
          onChange(dates);
          const [startDate, endDate] = dates;

          if (startDate && endDate) {
            setCalendarState('hidden');
          }
        }}
        minDate={allowPastDates ? undefined : minDate || new Date()}
        maxDate={maxDate}
      />
    </DatePickerContainer>
  );
};

export default DateRangePicker;
