import React, { useContext, useEffect, useState } from 'react';

import { app, media } from 'context';
import { ButterImg, FullWidthBackground, Layout, Link, SwiperCarousel, Text } from 'ds';
import { getLocationSlug, getLocations } from 'lib/butter';
import { ButterLocation, TopCity } from 'lib/butter/types';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';
import { SwiperSlide } from 'swiper/react';
import { generateLocationPath } from 'ux/Public/utils';

import {
  DEFAULT_PAGINATION_CONTROL_DIMENSION,
  DEFAULT_PAGINATION_CONTROL_PADDING
} from '../ds/SwiperCarousel/constants';

interface Props {
  excludedLocation?: { name: string; region: string };
}

const TopCities: React.FC<Props> = ({ excludedLocation }) => {
  const { contentWidth } = useContext(app);
  const { xs, lg } = useContext(media);
  const [locations, setLocations] = useState<ButterLocation[]>([]);
  const topCities = useAppSelector(state => selectCmsPage(state, 'top-cities'));
  const [imageRect, setImageRect] = useState<DOMRect>();

  const excludedSlug = excludedLocation ? getLocationSlug(excludedLocation) : '';

  useEffect(() => {
    getLocations().then(locations => {
      setLocations(locations);
    });
  }, [excludedSlug]);

  if (!topCities || !locations.length) return null;

  const transformedTopCities = (topCities.fields.cities as TopCity[])
    .filter(c => {
      if (!excludedSlug) return true;

      return excludedSlug !== c.slug;
    })
    .map(c => locations.find(l => getLocationSlug(l) === c.slug))
    .filter((c): c is ButterLocation => !!c)
    .slice(0, 5);

  return (
    <FullWidthBackground color="blue-50" paddingY={lg ? 100 : 80}>
      <Text tag="h4" color="blue-700">
        Explore Top Cities
      </Text>
      <Layout marginTop={36} width={contentWidth}>
        <SwiperCarousel
          controlLayoutProps={
            imageRect?.height
              ? {
                  top:
                    (imageRect.height - DEFAULT_PAGINATION_CONTROL_DIMENSION - DEFAULT_PAGINATION_CONTROL_PADDING * 2) /
                    2
                }
              : { opacity: 0 }
          }
        >
          {transformedTopCities.map(({ name, region, country, image }) => {
            return (
              <SwiperSlide key={name}>
                <Link
                  href={generateLocationPath({
                    location: name,
                    region,
                    country
                  })}
                  display="block"
                >
                  <Layout inline>
                    <ButterImg
                      onMeasure={rect => setImageRect(rect)}
                      src={image}
                      alt={name}
                      width={300}
                      height={200}
                      {...(xs
                        ? { style: { maxHeight: 260, objectFit: 'cover', borderRadius: 24 } }
                        : { style: { borderRadius: 24, maxHeight: 260 } })}
                    />
                    <Layout marginTop={12}>
                      <Text semibold size="body1" color="gray-900">
                        {name}, {region}
                      </Text>
                    </Layout>
                  </Layout>
                </Link>
              </SwiperSlide>
            );
          })}
        </SwiperCarousel>
      </Layout>
    </FullWidthBackground>
  );
};

export default TopCities;
