import React from 'react';

import { ButterImg, Layout, Link, Text } from 'ds';
import { ButterLocation } from 'lib/butter/types';
import { generateLocationPath } from 'ux/Public/utils';

interface Props {
  locations: ButterLocation[];
  onImageLoad: VoidFunction;
  closeMenu: VoidFunction;
}

const LocationColumn: React.FC<Props> = ({ locations, onImageLoad, closeMenu }) => {
  const featuredLocation = locations[0];
  const otherLocations = locations.slice(1);

  return (
    <Layout>
      <Link
        href={generateLocationPath({
          location: featuredLocation.name,
          region: featuredLocation.region,
          country: featuredLocation.country
        })}
        opacity={false}
        onClick={closeMenu}
      >
        <Layout inline>
          <ButterImg
            width={300}
            src={featuredLocation.landscape_thumbnail_image}
            alt={featuredLocation.name}
            style={{ borderRadius: 12, height: 100, objectFit: 'cover' }}
            onLoad={onImageLoad}
          />
          <Layout marginTop={8} inline>
            <Text size="body2" color="black" bold>
              {featuredLocation.name}, {featuredLocation.region}
            </Text>
          </Layout>
        </Layout>
      </Link>
      <Layout marginTop={32}>
        {otherLocations.map(({ name, region, country }, index) => {
          return (
            <Layout key={name} marginTop={index === 0 ? undefined : 16}>
              <Link
                href={generateLocationPath({
                  location: name,
                  region: region,
                  country: country
                })}
                text={
                  <Text size="body2">
                    {name}, {region}
                  </Text>
                }
                semibold={false}
                color="black"
                onClick={closeMenu}
              />
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default LocationColumn;
