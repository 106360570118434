import { axios } from '@codiwork/codi';

import { Product, ProductEnums } from './types';

export function getProductEnums() {
  return axios.get<ProductEnums>('/v1/product_enums');
}

export function getProducts(termLengthMonths: 3 | 6 | 12) {
  return axios.get<Product[]>(`/v1/products?term_length_months=${termLengthMonths}`);
}
