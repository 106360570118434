import React, { PropsWithChildren, useContext } from 'react';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

interface Props {}

const CaseStudy: React.FC<Props> = () => {
  const mediaContext = useContext(media);
  const { xs, sm, md } = mediaContext;
  const { sectionGutterY } = useContext(app);

  const maxWidth = sm ? '680' : md ? '800' : '1016';

  const titleSize = sm || md ? 'h3' : 'h2';
  const midTitleSize = sm || md ? 'h4' : 'h3';
  const subtitleSize = sm || md ? 'body1' : 'h6';

  return (
    <Layout marginTop={sectionGutterY} width="100%">
      <Layout
        direction="column"
        justify="center"
        align="center"
        __style={{ maxWidth: `${maxWidth}px`, margin: '0 auto' }}
      >
        <Layout justify="space-between" width="100%" direction={xs ? 'column' : 'row'}>
          <Cell>
            <Text size={titleSize} color="red-700">
              62%
            </Text>
            <Text size={subtitleSize} bold color="gray-600" align="center">
              real-estate costs reduced
            </Text>
          </Cell>
          <Cell marginTop={xs ? 32 : undefined}>
            <Text size={titleSize} color="aqua-500">
              4.84
              <Text size={midTitleSize} color="black" inline>
                /5
              </Text>
            </Text>
            <Text size={subtitleSize} bold color="gray-600" align="center">
              average employee rating
            </Text>
          </Cell>
          <Cell marginTop={xs ? 32 : undefined}>
            <Text size={titleSize} color="gold-500">
              100%
            </Text>
            <Text size={subtitleSize} bold color="gray-600" align="center">
              employees felt "more supported"
            </Text>
          </Cell>
        </Layout>
        <Layout marginTop={32}>
          <Text size="body1" color="gray-600" align="center" scale>
            Data from Case Study (mid-sized NYC-based company)
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};

const Cell: React.FC<PropsWithChildren<{ marginTop?: 32 | undefined }>> = ({ marginTop, children }) => {
  const mediaContext = useContext(media);
  const { xs, sm, md } = mediaContext;

  const cellWidth = xs ? '100%' : sm ? 200 : md ? 240 : 280;
  const cellHeight = sm || md ? 216 : 240;

  return (
    <Layout
      marginTop={marginTop}
      width={cellWidth}
      height={cellHeight}
      borderRadius={40}
      __style={{ boxShadow: '0 13px 23px rgba(0, 0, 0, 0.15)' }}
      justify="flex-start"
      align="center"
      direction="column"
      paddingTop={48}
    >
      {children}
    </Layout>
  );
};

export default CaseStudy;
