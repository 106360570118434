import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import { DS_CLASS_NAME, ICON_SIZE_SM, IS_TOUCH_DEVICE } from 'ds/constants';
import useFieldSize from 'hooks/useFieldSize';

import { FieldSize } from '../types';

export interface Props {
  // value that is selected
  value?: string;
  required?: boolean;
  options: Option[];
  onChange: OnChange;
  size?: FieldSize;
  disabled?: boolean;
  hasError?: boolean;
  onMeasure?: (width: number) => void;
}

export type OnChange = (params: OnChangeParams) => void;

interface OnChangeParams {
  event: React.ChangeEvent<HTMLSelectElement>;
  value: string;
}

export interface Option {
  value: string;
  label: string;
  display: string;
}

const NUMBER_WIDTHS: Record<FieldSize, number[]> = {
  xxs: [],
  xs: [],
  sm: [],
  md: [],
  lg: []
};
const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
// NOTE: can use different combinations other whitespace characters to make even more precise
const SPACE_CHARS = ['\u200A', '\u202F'];
const SPACE_CHAR_WIDTHS: Record<FieldSize, number[]> = {
  xxs: [],
  xs: [],
  sm: [],
  md: [],
  lg: []
};

const PhonePrefix: React.FC<Props> = ({
  options,
  onChange,
  value,
  disabled = false,
  required: _required,
  onMeasure,
  hasError: _hasError,
  ...props
}) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const hasValue = value !== undefined && value !== '';
  const measurerRef = useRef<HTMLDivElement>(null);
  const [displayWidth, setDisplayWidth] = useState<number>(0);
  const display = options.find(o => o.value === value)?.display;
  const [renderNumberMeasurer, setRenderNumberMeasurer] = useState<boolean>(false);
  const [allNumbersMeasured, setAllNumbersMeasured] = useState<boolean>(
    NUMBER_WIDTHS[size].filter(Boolean).length === NUMBERS.length
  );
  const [allCharsMeasured, setAllCharsMeasured] = useState<boolean>(
    SPACE_CHAR_WIDTHS[size].filter(Boolean).length === SPACE_CHARS.length
  );

  useEffect(() => {
    if (allNumbersMeasured && allCharsMeasured) return;

    setRenderNumberMeasurer(true);
  }, [allCharsMeasured, allNumbersMeasured, size]);

  useEffect(() => {
    const measurer = measurerRef.current;

    if (!measurer) return;

    const width = measurer.getBoundingClientRect().width;

    setDisplayWidth(width);
    onMeasure && onMeasure(width + ICON_SIZE_SM);
  }, [display, onMeasure]);

  const onChangeWrapper = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    onChange({ event: e, value });
  };

  return (
    <span className={classNames(DS_CLASS_NAME, 'PhonePrefix', `is-size-${size}`)}>
      <span className="PhonePrefix-iconContainer">
        <Icon name="downCaret" color="gray-900" size="sm" />
      </span>
      <span className="PhonePrefix-display" style={{ width: displayWidth }}>
        {display}
      </span>
      <select
        className={classNames('PhonePrefix-select', { 'has-value': hasValue })}
        onChange={e => {
          onChangeWrapper(e);
        }}
        style={{ width: ICON_SIZE_SM + displayWidth }}
        value={value}
        disabled={disabled}
        tabIndex={-1}
      >
        {options.map(({ label, value }) => {
          const match = label.match(/\d+/);

          if (!match) return null;

          const codeLength = match[0].length;

          const code = label.substring(1, codeLength + 1);
          const country = label.substring(codeLength + 2);
          const spaceString = generateSpaceString(code, size);

          return (
            <option key={value} value={value}>
              +{code}
              {spaceString}
              {country}
            </option>
          );
        })}
      </select>
      <span className="PhonePrefix-displayMeasurer" ref={measurerRef}>
        {display}
      </span>
      {renderNumberMeasurer && (
        <Layout visibility="hidden" position="absolute">
          {NUMBERS.map(number => (
            <CharMeasurer
              key={number}
              onMeasure={({ width }) => {
                const widths = NUMBER_WIDTHS[size];
                widths[number] = width;

                if (widths.filter(Boolean).length === NUMBERS.length) setAllNumbersMeasured(true);
              }}
            >
              {number}
            </CharMeasurer>
          ))}
          {SPACE_CHARS.map((char, index) => (
            <CharMeasurer
              key={char}
              onMeasure={({ width }) => {
                const chars = SPACE_CHAR_WIDTHS[size];
                chars[index] = width;

                if (chars.filter(Boolean).length === SPACE_CHARS.length) setAllCharsMeasured(true);
              }}
            >
              {char}
            </CharMeasurer>
          ))}
        </Layout>
      )}
    </span>
  );
};

interface NumberMeasurerProps {
  onMeasure: (params: { width: number; height: number }) => void;
}

function generateSpaceString(code: string, size: FieldSize): string {
  try {
    const numberWidths = NUMBER_WIDTHS[size];
    const spaceCharWidths = SPACE_CHAR_WIDTHS[size];

    if (
      IS_TOUCH_DEVICE ||
      numberWidths.filter(Boolean).length !== NUMBERS.length ||
      spaceCharWidths.filter(Boolean).length !== SPACE_CHARS.length
    ) {
      return '\u00A0';
    }

    const summedWidth = code.split('').reduce((sum, char) => sum + numberWidths[parseInt(char)], 0);
    const widestChar = Math.max(...numberWidths);
    const widestLength = 5 * widestChar;
    const spaceLeft = widestLength - summedWidth;
    const spaceCharWidth1 = spaceCharWidths[0];
    const spaceCharWidth2 = spaceCharWidths[1];
    const spaceChar1 = SPACE_CHARS[0];
    const spaceChar2 = SPACE_CHARS[1];

    const maxChar2 = Math.ceil(spaceLeft / spaceCharWidth2);
    let numberChar1 = 0;
    let numberChar2 = maxChar2;

    let bestDiffCount = { numberChar1, numberChar2 };
    let bestDiff = Math.abs(spaceLeft - (numberChar1 * spaceCharWidth1 + numberChar2 * spaceCharWidth2));

    while (numberChar2 > 0) {
      let diff = Math.abs(spaceLeft - (numberChar1 * spaceCharWidth1 + numberChar2 * spaceCharWidth2));
      numberChar2--;

      while (diff < spaceCharWidth1 + spaceCharWidth2 + 0.1) {
        numberChar1++;

        diff = Math.abs(spaceLeft - (numberChar1 * spaceCharWidth1 + numberChar2 * spaceCharWidth2));

        if (diff < bestDiff) {
          bestDiff = diff;
          bestDiffCount = { numberChar1, numberChar2 };
        }
      }
    }

    return (
      Array(bestDiffCount.numberChar1).fill(spaceChar1).join('') +
      Array(bestDiffCount.numberChar2).fill(spaceChar2).join('')
    );
  } catch {
    return '\u00A0';
  }
}

const CharMeasurer: React.FC<PropsWithChildren<NumberMeasurerProps>> = ({ children, onMeasure }) => {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const { width, height } = element.getBoundingClientRect();

    setTimeout(() => {
      onMeasure({ width, height });
    }, 100);
  }, [onMeasure]);

  return (
    <div>
      <span ref={ref}>{children}</span>
    </div>
  );
};

export default PhonePrefix;
