import React, { useContext } from 'react';

import { media } from 'context';
import { CarouselOld, Layout } from 'ds';
import VideoPlayer from 'ux/Public/VideoPlayer';

interface Props {
  images: {
    image: string;
    alt_text: string;
  }[];
  videos?: {
    url: string;
    thumbnail: string;
    show_border?: boolean;
  }[];
}

const Visual: React.FC<Props> = ({ images, videos = [] }) => {
  const mediaContext = useContext(media);
  const { xs } = mediaContext;

  if (videos.length === 1) {
    const { url, thumbnail, show_border } = videos[0];

    return (
      <Layout marginTop={xs ? 48 : undefined}>
        <VideoPlayer videoUrl={url} thumbnailUrl={thumbnail} showBorder={show_border} />
      </Layout>
    );
  } else if (images.length === 1) {
    const image = images[0];
    return (
      <Layout borderRadius={20} overflow="hidden" marginTop={xs ? 48 : undefined}>
        <img src={image.image} alt={image.alt_text} />
      </Layout>
    );
  } else if (images.length > 1) {
    return (
      <Layout marginTop={xs ? 48 : undefined}>
        <CarouselOld>
          {images.map(image => (
            <img key={image.image} alt={image.alt_text} src={image.image} />
          ))}
        </CarouselOld>
      </Layout>
    );
  }
  return null;
};

export default Visual;
