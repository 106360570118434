import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Link } from 'ds';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { ReactComponent as Facebook } from 'assets/images/footer/facebook-logo.svg';
import { ReactComponent as Instagram } from 'assets/images/footer/instagram-logo.svg';
import { ReactComponent as LinkedIn } from 'assets/images/footer/linkedin-logo.svg';
import { ReactComponent as X } from 'assets/images/footer/x-logo.svg';
import { ReactComponent as AppStoreLogo } from 'ds/app-store-logo.svg';
import { ReactComponent as PlayStoreLogo } from 'ds/play-store-logo.svg';

interface Props {}

const FooterAppStoreSocialLinks: React.FC<Props> = () => {
  const { isMobile, xs, sm } = useContext(media);
  const user = useAppSelector(selectUser);

  return (
    <>
      {user && (
        <Layout {...(isMobile ? { marginTop: 44, align: 'center' } : {})}>
          <Layout {...(xs ? { equalWidth: true } : sm ? { width: 172 } : {})}>
            <Link
              href="https://itunes.apple.com/us/app/codi-home-based-coworking/id1449647612"
              fullWidth
              target="_blank"
            >
              <Layout borderWidth={1} borderColor="white" borderRadius={8} height={56} align="center" justify="center">
                <AppStoreLogo width={116} />
              </Layout>
            </Link>
          </Layout>
          <Layout
            {...(xs ? { marginLeft: 12, equalWidth: true } : sm ? { width: 172, marginLeft: 12 } : { marginTop: 20 })}
          >
            <Link href="https://play.google.com/store/apps/details?id=org.hiveninc.Cobo" fullWidth target="_blank">
              <Layout borderWidth={1} borderColor="white" borderRadius={8} height={56} align="center" justify="center">
                <PlayStoreLogo width={116} />
              </Layout>
            </Link>
          </Layout>
        </Layout>
      )}
      <Layout marginTop={isMobile ? 56 : 44}>
        <Layout {...(isMobile ? { justify: 'flex-start', width: '100%' } : {})} flex>
          <Layout display="inline-flex">
            <Link href="https://www.linkedin.com/company/codiwork/about" target="_blank">
              <span className="Icon is-color-white">
                <LinkedIn width={24} height={24} />
              </span>
            </Link>
          </Layout>
          <Layout marginLeft={24} display="inline-flex">
            <Link href="https://twitter.com/codiwork" target="_blank">
              <span className="Icon is-color-white">
                <X width={24} height={24} />
              </span>
            </Link>
          </Layout>
          <Layout marginLeft={24} display="inline-flex">
            <Link href="https://www.facebook.com/codicommunity" target="_blank">
              <span className="Icon is-color-white">
                <Facebook width={24} height={24} />
              </span>
            </Link>
          </Layout>
          <Layout marginLeft={24} display="inline-flex">
            <Link href="https://www.instagram.com/codiwork" target="_blank">
              <span className="Icon is-color-white">
                <Instagram width={24} height={24} />
              </span>
            </Link>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default FooterAppStoreSocialLinks;
