import { ButterPage } from 'lib/butter/types';
import { SEARCH_META_TITLE } from 'routes/constants';
import { Path, SEARCH_PATH } from 'routes/paths';
import { ROUTES } from 'routes/routes';

export function getPageTitle({ path, pages }: { path: Path; pages: ButterPage[] }) {
  const route = ROUTES.find(r => r.path === path);

  if (!route) return;

  if (route.cmsSlug) {
    return pages.find(p => p.slug === route.cmsSlug)?.fields.seo.title;
  } else {
    switch (path) {
      case SEARCH_PATH:
        return SEARCH_META_TITLE;
    }
  }
}
