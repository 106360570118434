import React from 'react';
import { IconName } from '@codiwork/codi';

import { ButterImg, Button, Icon, Layout, Link, Text } from 'ds';
import { kebabToCamelCase } from 'ds/utils';
import { ButterLocation, NavigationCtaSection, NavigationMenuSection } from 'lib/butter/types';

import Locations from './Locations';

interface Props {
  sections: NavigationMenuSection[];
  ctaSection: NavigationCtaSection;
  locations: ButterLocation[];
  closeMenu: VoidFunction;
}

const MenuSections: React.FC<Props> = ({
  sections,
  ctaSection: { image_xs: ctaImageXs, image: ctaImage },
  ctaSection,
  closeMenu,
  ...props
}) => {
  const locations = props.locations.slice(0, 5);
  let delay = -40;

  return (
    <Layout>
      {locations.length ? (
        <Layout paddingTop={32}>
          <Locations locations={locations} closeMenu={closeMenu} />
        </Layout>
      ) : (
        sections.map(({ fields: { title, items } }) => {
          delay += 40;

          return (
            <Layout marginTop={36} key={title}>
              <Layout
                paddingBottom={16}
                __className="Navigation-mobileMenuSection"
                __style={{ animationDelay: `${delay}ms` }}
                borderColor="gray-50"
                borderBottom
              >
                <Text size="body3" bold>
                  {title}
                </Text>
              </Layout>
              {items.map(({ icon, name, description, page, link }, index) => {
                // TODO: relies on Butter /v2/public_page/ URL structure which is fragile; make better.
                const href = typeof page === 'string' ? page.split('/public_page').slice(-1)[0] : link;

                delay += 40;

                return href && name ? (
                  <Layout
                    marginTop={index === 0 ? 16 : 36}
                    __className="Navigation-mobileMenuSection"
                    __style={{ animationDelay: `${delay}ms` }}
                    key={name}
                  >
                    <Link
                      opacity={false}
                      href={href}
                      size="body2"
                      semibold={false}
                      color="black"
                      key={name}
                      onClick={closeMenu}
                      fullWidth
                    >
                      <Layout align="flex-start">
                        {icon && (
                          <Layout marginRight={24}>
                            <Icon name={kebabToCamelCase(icon) as IconName} size="md" color="black" />
                          </Layout>
                        )}
                        <Layout>
                          <Layout>
                            <Text size="body2" semibold>
                              {name}
                            </Text>
                            {description && (
                              <Layout marginTop={8}>
                                <Text size="body3">{description}</Text>
                              </Layout>
                            )}
                          </Layout>
                        </Layout>
                      </Layout>
                    </Link>
                  </Layout>
                ) : link ? (
                  <Layout key={name} marginTop={16} position="relative">
                    <div dangerouslySetInnerHTML={{ __html: link }} />
                  </Layout>
                ) : null;
              })}
            </Layout>
          );
        })
      )}
      <Layout
        marginTop={44}
        paddingTop={24}
        paddingBottom={32}
        borderColor="gray-50"
        __className="Navigation-mobileMenuSection"
        __style={{ animationDelay: `${locations.length ? locations.length * 40 : delay}ms` }}
        borderTop
      >
        <Layout paddingBottom={16}>
          <Text size="body3" bold>
            {ctaSection.title}
          </Text>
        </Layout>
        <Layout align="flex-end">
          <Layout position="relative" display="inline-flex" zIndex={1}>
            <ButterImg
              compression={false}
              width={200}
              src={ctaImageXs?.src || ctaImage.src}
              alt={ctaImageXs?.alt || ctaImage.alt}
              style={{
                borderRadius: ctaImageXs?.border_radius || 12,
                boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
                width: 88,
                marginTop: 8,
                marginLeft: typeof ctaImageXs?.margin_left === 'number' ? ctaImageXs?.margin_left : undefined
              }}
            />
            <span
              dangerouslySetInnerHTML={{ __html: ctaSection.blob_xs.svg }}
              style={{ zIndex: -1, position: 'absolute', left: 0, top: ctaSection.blob_xs.top }}
            />
          </Layout>
          <Layout marginLeft={40}>
            <Text size="body2">{ctaSection.subtitle}</Text>
            <Layout marginTop={16}>
              {ctaSection.cta_url ? (
                <Link color="black" href={ctaSection.cta_url}>
                  <Text size="body3" semibold inline>
                    {ctaSection.cta_text}
                  </Text>
                </Link>
              ) : (
                <Button size="sm" type="primary" text={ctaSection.cta_text} />
              )}
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MenuSections;
