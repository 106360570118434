import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, Button, Layout, Text } from 'ds';
import { ButterImage } from 'lib/butter/types';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {
  image: ButterImage;
  title: string;
  cta_text: string;
}

const CTA: React.FC<Props> = ({ image, title, cta_text }) => {
  const { contentPaddingX, contentWidth, width } = useContext(app);
  const { xs, xl, lg } = useContext(media);

  return (
    <Animate marginBottom={xs ? 0 : lg ? 80 : 48} position="relative">
      <img
        src={image.src}
        alt={image.alt}
        {...(xs
          ? {
              style: {
                width,
                left: -contentPaddingX,
                position: 'relative',
                objectFit: 'cover',
                height: 236
              }
            }
          : { style: { borderRadius: 24 } })}
      />
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%' }}>
        <Layout {...(xs ? { width: contentWidth, paddingX: 24 } : {})}>
          <Text size={xl ? 'h2' : lg ? 'h3' : 'h4'} color="white" align="center" wrap={xs}>
            {title}
          </Text>
        </Layout>
        <Layout justify="center" marginTop={36}>
          <Button size={lg ? 'lg' : 'md'} type="primary" text={cta_text} href={OFFICE_REQUEST_PATH} />
        </Layout>
      </div>
    </Animate>
  );
};

export default CTA;
