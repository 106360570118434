import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowResize } from 'beautiful-react-hooks';
import classNames from 'classnames';

import { media } from 'context';
import ClickableDiv from 'ds/ClickableDiv';
import { OFFICES_PATH } from 'routes';

import { SessionWorkspace } from './types';
import Layout from '../Layout';
import Link from '../Link';
import Text from '../Text';
import WorkspaceImg from '../WorkspaceImg';
import { OnMeasure } from '../types';

interface Props {
  workspace: SessionWorkspace;
  onImageMeasure?: OnMeasure;
  isMoving?: boolean;
}

const SessionContextCard: React.FC<Props> = ({
  workspace: { id, images, address, short_name },
  onImageMeasure,
  isMoving
}) => {
  const { xs } = useContext(media);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(false);
  const history = useHistory();

  const measureImage = () => {
    const imageContainer = imageContainerRef.current;

    if (!imageContainer || !onImageMeasure) return;

    onImageMeasure(imageContainer.getBoundingClientRect());
  };

  const onWindowResize = useWindowResize();
  onWindowResize(measureImage);

  return xs ? (
    <Link
      href={`${OFFICES_PATH}/${id}`}
      opacity={false}
      key={id}
      draggable={false}
      onClick={e => {
        if (isMoving) {
          e.preventDefault();
        }
      }}
      fullWidth
    >
      <Layout display="inline-flex" __ref={imageContainerRef} position="relative" overflow="hidden" borderRadius={16}>
        <WorkspaceImg
          srcKey={images[0].key}
          alt={short_name as string}
          style={{ borderRadius: 16, userSelect: 'none' }}
          draggable={false}
          size="lg"
          className="WorkspaceCarousel-sessionContextCard-image"
          onLoad={measureImage}
        />
        <span
          style={{
            background:
              'radial-gradient(122.46% 71.53% at 10.95% 81.39%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 57.75%)',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 16
          }}
        />
        <Layout position="absolute" left={24} bottom={24} paddingRight={32}>
          <Text size="h6" color="white" maxLines={2} wordBreak="break-word">
            {short_name}
          </Text>
          <Text size="body2" color="white" ellipsis>
            {address.city}, {address.region}
          </Text>
        </Layout>
      </Layout>
    </Link>
  ) : (
    <ClickableDiv onClick={() => history.push(`${OFFICES_PATH}/${id}`)}>
      <div
        className={classNames('WorkspaceCarousel-sessionContextCard', { 'is-active': active })}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onMouseLeave={() => setActive(false)}
      >
        <Layout display="inline-flex" __ref={imageContainerRef} position="relative" overflow="hidden" borderRadius={16}>
          <WorkspaceImg
            srcKey={images[0].key}
            alt={short_name as string}
            style={{ borderRadius: 16, userSelect: 'none' }}
            draggable={false}
            size="sm"
            className="WorkspaceCarousel-sessionContextCard-image"
            onLoad={() => {
              const imageContainer = imageContainerRef.current;

              if (!imageContainer || !onImageMeasure) return;

              onImageMeasure(imageContainer.getBoundingClientRect());
            }}
          />
          <span
            style={{
              background:
                'radial-gradient(122.46% 71.53% at 10.95% 81.39%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 57.75%)',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: 16
            }}
          />
        </Layout>
        <Layout position="absolute" left={32} bottom={24} paddingRight={32}>
          <Text size="h5" color="white" maxLines={2} wordBreak="break-word" scale>
            {short_name}
          </Text>
          <Text size="h6" color="white" scale ellipsis>
            {address.city}, {address.region}
          </Text>
        </Layout>
      </div>
    </ClickableDiv>
  );
};

export default SessionContextCard;
