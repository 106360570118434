import React from 'react';
import { Color, IconName, IconSize, IconStroke } from '@codiwork/codi';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import Icon from './Icon';
import Link, { Props as LinkProps } from './Link';
import { DS_CLASS_NAME, IS_TOUCH_DEVICE } from './constants';

export interface Props extends Pick<LinkProps, 'scrollYOffset'> {
  name: IconName;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string | null;
  search?: string;
  /** xs: 24px | sm: 32px | md: 40px | lg: 48px */
  size: 'xs' | 'sm' | 'md' | 'lg';
  type: Type;
  disabled?: boolean;
  context?: string;
  stroke?: IconStroke;
  iconColor?: Color;
  value?: string | number;
  iconSize?: IconSize | number;
}

export type Type =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'primaryBlue'
  | 'noBackground'
  | 'transparentDark'
  | 'transparentLight'
  | 'blue'
  | 'gray'
  | 'blueLight';

interface Config {
  color?: Color;
  hoverColor?: Color;
  activeColor?: Color;
  iconColor: Color;
  hasShadow: boolean;
  hoverIconColor?: Color;
  background?: string;
}

export const ICON_BUTTON_CONFIG: Record<Type, Config> = {
  primaryBlue: {
    color: 'blue-500',
    hoverColor: 'blue-600',
    activeColor: 'blue-700',
    iconColor: 'white',
    hasShadow: false
  },
  primary: {
    color: 'blue-50',
    hoverColor: 'blue-200',
    activeColor: 'blue-300',
    iconColor: 'black',
    hasShadow: false
  },
  secondary: {
    color: 'gray-100',
    hoverColor: 'gray-300',
    activeColor: 'gray-400',
    iconColor: 'black',
    hasShadow: false
  },
  white: {
    color: 'white',
    hoverColor: 'gray-100',
    activeColor: 'gray-200',
    iconColor: 'gray-900',
    hasShadow: true,
    hoverIconColor: 'black'
  },
  noBackground: {
    // background colors are declared in IconButton.scss
    iconColor: 'black',
    hasShadow: false
  },
  transparentDark: {
    iconColor: 'white',
    hasShadow: false,
    background: 'rgba(0, 18, 68, 0.1)'
  },
  transparentLight: {
    background: 'rgba(255, 255, 255, 0.1)',
    iconColor: 'white',
    hasShadow: false
  },
  blue: {
    color: 'blue-50',
    hoverColor: 'blue-200',
    activeColor: 'blue-200',
    iconColor: 'black',
    hasShadow: false
  },
  gray: {
    color: 'gray-25',
    hoverColor: 'gray-50',
    activeColor: 'gray-50',
    iconColor: 'black',
    hasShadow: false
  },
  blueLight: {
    color: 'blue-50',
    hoverColor: 'blue-200',
    activeColor: 'blue-300',
    iconColor: 'blue-500',
    hasShadow: false,
    hoverIconColor: 'blue-500'
  }
} as const;

const ICON_SIZES: IconSize[] = ['xxs', 'xs', 'sm', 'md', 'lg'];

const IconButton: React.FC<Props> = ({
  name,
  size,
  stroke = 1.5,
  type,
  onClick,
  context,
  disabled = false,
  href,
  scrollYOffset,
  iconColor = ICON_BUTTON_CONFIG[type].iconColor,
  value,
  search,
  ...props
}) => {
  const { color, hoverColor, activeColor, hasShadow, hoverIconColor, background } = ICON_BUTTON_CONFIG[type];
  const matchedSize = ICON_SIZES.findIndex(i => i === size);
  const iconSize = props.iconSize || ICON_SIZES[matchedSize - 1];
  const className = classNames(
    'IconButton',
    DS_CLASS_NAME,
    `is-size-${size}`,
    `bg-color-${color}`,
    `is-${type}`,
    IS_TOUCH_DEVICE || disabled ? undefined : `hover-bg-color-${hoverColor}`,
    `active-bg-color-${activeColor}`,
    {
      'is-disabled': disabled,
      'has-shadow': hasShadow,
      [`hover-icon-color-${hoverIconColor}`]: hoverIconColor
    }
  );

  return onClick ? (
    <button
      onClick={e => {
        onClick(e);
        track('Element Interacted', { context, name, type: 'iconButton', value });
      }}
      style={{ background }}
      disabled={disabled}
      className={className}
    >
      <Icon name={name} size={iconSize} stroke={stroke} color={iconColor} />
    </button>
  ) : (
    <Link
      href={href || undefined}
      search={search}
      name={name}
      context={context}
      disabled={disabled}
      opacity={false}
      scrollYOffset={scrollYOffset}
    >
      <span style={{ background }} className={className}>
        <Icon name={name} size={iconSize} stroke={stroke} color={iconColor} />
      </span>
    </Link>
  );
};

export default IconButton;
