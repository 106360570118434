import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { media } from 'context';
import { Button, Layout } from 'ds';
import { Field, FormInput, formValid, getValues } from 'ds/forms';
import { selectUser } from 'store/User/selectors';

import { postContactForm } from './requests';
import { ContactFormInbox } from './types';

interface Props {
  onSubmit: () => void;
  inbox: ContactFormInbox;
}

const TRACK_CONTEXT = 'contact form';

const Form: React.FC<Props> = ({ onSubmit, inbox }) => {
  const user = useSelector(selectUser);
  const { xs, sm, xl } = useContext(media);
  const cta = 'Submit';

  const FIELDS = [
    {
      name: 'firstname',
      label: 'First name',
      required: true,
      value: user?.firstname || '',
      type: 'text'
    },
    {
      name: 'lastname',
      label: 'Last name',
      required: true,
      value: user?.lastname || '',
      type: 'text'
    },
    { name: 'email', label: 'Work email', required: true, value: user?.email || '', type: 'email' },
    { name: 'message', label: 'Description', value: '', type: 'textArea', required: true }
  ] as const;

  const FIELD_NAME_TO_WIDTH = {
    firstname: xs ? '100%' : sm ? '49%' : '48%',
    lastname: xs ? '100%' : sm ? '49%' : '48%',
    email: '100%',
    message: '100%'
  };

  const [fields, setFields] = useState<Field<typeof FIELDS[number]['name']>[]>([...FIELDS]);

  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const paddingX = xs ? undefined : undefined;

  const disabled = !formValid(fields) || !!requestLoading;

  return (
    <Layout color="white">
      <Layout flex={!xs} wrap={!xs} justify={!xs || sm ? 'space-between' : undefined}>
        {fields.map(field => (
          <FormInput
            {...field}
            key={field.name}
            inputContainerProps={{
              width: FIELD_NAME_TO_WIDTH[field.name],
              paddingX,
              paddingY: 8
            }}
            size={xs ? 'sm' : xl ? 'lg' : 'md'}
            fields={fields}
            setFields={setFields}
            disabled={!!requestLoading}
          />
        ))}
      </Layout>
      <Layout marginTop={xs ? 24 : 8} paddingX={paddingX} width={undefined}>
        <Button
          type="primary"
          context={TRACK_CONTEXT}
          onClick={() => {
            setRequestLoading(true);

            postContactForm({ ...getValues(fields), inbox }).then(() => {
              setRequestLoading(false);
              onSubmit();
            });
          }}
          disabled={disabled}
          showSpinner={!!requestLoading}
          text={cta}
          size={xs ? 'sm' : 'lg'}
          fullWidth
        />
      </Layout>
    </Layout>
  );
};

export default Form;
