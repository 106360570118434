import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiErrorResponse } from '@codiwork/codi';

import { showSnackbar } from 'helpers/snackbar';

import { Icon, Layout, Link, PillButton, Text, TextArea } from 'ds';
import { CUSTOMER_SUPPORT_PATH } from 'routes';
import { actions as chatbotActions } from 'store/Chatbot';
import { selectCustomerSupportTicketByInteractionId } from 'store/Chatbot/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resolveIntent } from 'ux/Chatbot/requests';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

interface Props extends ChatbotIntentProps {}

const SupportTicket: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const supportTicket = useAppSelector(selectCustomerSupportTicketByInteractionId(chatbotInteraction.id));
  const { id: chatbotInteractionId, llm_response, resolved_at } = chatbotInteraction;

  const [description, setDescription] = React.useState<string>(chatbotInteraction.input);
  const [requestInProgress, setRequestInProgress] = useState<boolean>();

  useEffect(() => {
    if (!!supportTicket?.id) {
      history.push(`${CUSTOMER_SUPPORT_PATH}/${supportTicket?.id}`);
    }
  }, [history, supportTicket?.id]);

  const createSupportTicket = () => {
    setRequestInProgress(true);
    const slots = { description };

    resolveIntent({ chatbot_interaction_id: chatbotInteractionId, slots })
      .then(({ data }) => {
        dispatch(chatbotActions.updateInteraction({ threadId: threadId, interaction: data }));
      })
      .catch((error: ApiErrorResponse) => {
        showSnackbar({ message: error.message, negative: true });
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  return (
    <Layout borderRadius={16} paddingY={12} paddingX={16} color="white">
      <Layout marginY={12} color="white">
        <Text size="body-sm">{!!llm_response && <div dangerouslySetInnerHTML={{ __html: llm_response }} />}</Text>
      </Layout>
      {resolved_at ? (
        <Layout direction="row" gap={4} marginTop={16}>
          <Icon name="checkmark" size="xs" color="green-700" />
          <Link href={`/app/support/${supportTicket?.id}`} onClick={() => dispatch(chatbotActions.setIsVisible(false))}>
            <Text size="body-xs" italic>
              View support ticket
            </Text>
          </Link>
        </Layout>
      ) : (
        <Layout marginTop={16} gap={4} direction="column">
          <TextArea
            size="sm"
            value={description}
            label={`Description of ${chatbotInteraction.ticket_category} issue`}
            placeholder={`Please describe details about the ${chatbotInteraction.ticket_category} issue`}
            onChange={({ value }) => setDescription(value)}
            minHeight={100}
          />
          <Layout marginTop={16}>
            <PillButton
              size="md"
              type="purpleGradient"
              onClick={createSupportTicket}
              text="Submit support ticket"
              fullWidth
              showSpinner={requestInProgress}
              disabled={requestInProgress || !description}
            />
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default SupportTicket;
