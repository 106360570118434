import React from 'react';
import { formatDate } from '@codiwork/codi';

import { PaymentStatus } from 'ux/Payments/types';

import Layout from './Layout';
import Text from './Text';

type SupportedPaymentStatus = keyof typeof CONFIG;
const PAYMENT_TYPES: SupportedPaymentStatus[] = ['paid', 'open', 'void'];

interface Props {
  payment_status: PaymentStatus;
  due_date?: string | null;
  paid_at?: string | null;
}

const CONFIG = {
  paid: {
    backgroundColor: 'green-100',
    textColor: 'green-900'
  },
  open: {
    backgroundColor: 'gold-100',
    textColor: 'gold-900'
  },
  void: {
    backgroundColor: 'red-100',
    textColor: 'red-900'
  }
} as const;

const PaymentStatusTag: React.FC<Props> = ({ payment_status, due_date, paid_at }) => {
  if (!PAYMENT_TYPES.includes(payment_status as SupportedPaymentStatus)) return null;

  const { backgroundColor, textColor } = CONFIG[payment_status as SupportedPaymentStatus];

  let text = '';

  if (payment_status === 'paid' && paid_at) {
    text = `Paid ${formatDate({ date: paid_at, format: 'MED' })}`;
  } else if (payment_status === 'open' && due_date) {
    text = `Due ${formatDate({ date: due_date, format: 'MED' })}`;
  } else if (payment_status === 'void') {
    text = 'Voided';
  }

  return (
    <Layout paddingY={2} paddingX={8} color={backgroundColor} borderRadius={4}>
      <Text size="body-xs" color={textColor} align="center" semibold>
        {text}
      </Text>
    </Layout>
  );
};

export default PaymentStatusTag;
