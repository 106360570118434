import React, { useContext } from 'react';

import { HELP_URL } from 'helpers/constants';

import { media } from 'context';
import { Button, Layout } from 'ds';
import { homePath } from 'routes/utils';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

const ErrorPage: React.FC = () => {
  const page = useAppSelector(state => selectCmsPage(state, '500'));

  const { xs } = useContext(media);

  if (!page) return null;

  const {
    fields: { image, text }
  } = page;

  return (
    <Layout marginTop={xs ? 24 : 72} align={xs ? undefined : 'center'} direction={xs ? 'column-reverse' : undefined}>
      <Layout
        equalWidth
        justify={xs ? 'center' : 'flex-end'}
        marginRight={xs ? undefined : 32}
        marginTop={xs ? 48 : undefined}
      >
        <img
          style={{ width: image.width, maxHeight: image.max_height, marginRight: image.margin_right }}
          src={image.image}
          alt={image.alt_text}
        />
      </Layout>
      <Layout equalWidth marginLeft={xs ? undefined : 32}>
        <div className="CmsContent" style={{ maxWidth: 504 }} dangerouslySetInnerHTML={{ __html: text }} />
        <Layout marginTop={40} marginBottom={16} maxWidth={xs ? undefined : 288}>
          <Button text="Home" type="primary" href={homePath()} size="sm" fullWidth />
        </Layout>
        <Layout maxWidth={xs ? undefined : 288}>
          <Button text="Help" type="secondaryOutline" href={HELP_URL} size="sm" fullWidth />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ErrorPage;
