import React, { useContext } from 'react';
import { IconName } from '@codiwork/codi';

import { media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';

export interface Props {
  icon: IconName;
  title: string;
  description: string;
  link: { href: string; text: string };
}

const Solution: React.FC<Props> = ({ icon, title, description, link: { href, text } }) => {
  const { isMobile } = useContext(media);

  return (
    <Layout
      color="white"
      direction="column"
      justify="space-between"
      {...(isMobile
        ? { boxShadow: '2px 0px 22px rgba(87, 73, 109, 0.12)', padding: 36, borderRadius: 8 }
        : { paddingX: 36, paddingY: 44, borderRadius: 24 })}
    >
      <Layout>
        <Layout {...(isMobile ? { align: 'center' } : {})}>
          <Icon name={icon} size="xl" color="blue-600" />
          <Layout {...(isMobile ? { marginLeft: 12 } : { marginTop: 12 })}>
            <Text size="headline-xs" color="blue-600">
              {title}
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={36}>
          <Text size="body-md" color="gray-400">
            {description}
          </Text>
        </Layout>
      </Layout>
      <Layout marginTop={36}>
        <Link href={href}>
          <Layout align="center">
            <Text size="body-md" color="blue-500" semibold>
              {text}
            </Text>
            <Layout display="inline-flex" marginLeft={4}>
              <Icon name="rightChevron" size="xs" color="blue-500" stroke={2} />
            </Layout>
          </Layout>
        </Link>
      </Layout>
    </Layout>
  );
};

export default Solution;
