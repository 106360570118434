import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

const GUTTER = 48;

interface Props {}

const TermSheetsAndLeases: React.FC<Props> = () => {
  const { windowHeight } = useContext(app);
  const { xs, md } = useContext(media);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={md ? 160 : 48}
        >
          <Text size="h3" color="blue-600">
            Term Sheets & Leases
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                Once you’ve found an office that you like, your broker will draft a “term sheet” — a document that
                expresses your interest in renting the space, sets out your offering for rent, and proposes terms —
                though, not all — for the final lease agreement.
                <br />
                <br />
                Note: your broker might recommend a simple term sheet rather than something more detailed. Here’s why:
                <br />
                <br />
              </Text>
              <ul style={{ marginLeft: 32 }}>
                <li>
                  <Text size="h6" bold={false} color="gray-700">
                    A simple term sheet will help get the deal "into lease" faster, meaning that the landlord can’t
                    offer the property to anyone else while you're negotiating
                  </Text>
                </li>
                <br />
                <li>
                  <Text size="h6" bold={false} color="gray-700">
                    However, an oversimplified term sheet means that more time may be necessary as the lease is
                    negotiated — potentially driving up legal fees
                  </Text>
                </li>
              </ul>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="contract-signing_tjJYspqff.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default TermSheetsAndLeases;
