import React, { useRef } from 'react';
import { ReactComponent as Heart } from '@codiwork/codi/lib/icons/Heart.svg';
import { ReactComponent as HeartOutline } from '@codiwork/codi/lib/icons/HeartOutline.svg';
import classNames from 'classnames';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { track } from 'lib/analytics';

interface Props {
  onClick: VoidFunction;
  monthly_price: number;
  daysPerWeek: number;
  id: number;
  isActive: boolean;
  context?: string;
  type: 'heart' | 'heart-outline';
  isRecommended?: boolean;
}

const HeartMarker: React.FC<Props> = ({ onClick, monthly_price, daysPerWeek, id, context, isActive, type }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const price = formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price, daysPerWeek });
  const isLiked = type === 'heart';

  return (
    <button
      ref={buttonRef}
      onClick={() => {
        onClick();
        track('Element Interacted', {
          type: 'button',
          name: 'price marker',
          action: 'select',
          value: id,
          context
        });
      }}
      className={classNames('HeartMarker', { 'is-active': isActive, 'is-liked': isLiked })}
    >
      <span style={{ width: 16, height: 16 }}>{isLiked || isActive ? <Heart /> : <HeartOutline />}</span>
      <Layout marginLeft={8} __className="HeartMarker-text">
        <Text size="body-sm" semibold>
          {price}
        </Text>
      </Layout>
    </button>
  );
};

export default HeartMarker;
