import React from 'react';
import classNames from 'classnames';

import useFieldSize from 'hooks/useFieldSize';

import Layout from './Layout';
import { DS_CLASS_NAME } from './constants';
import NumberStepperControl from './controls/NumberStepperControl';
import { FieldSize } from './types';

export interface Props {
  label: string;
  value: number;
  min?: number;
  max?: number;
  size?: FieldSize;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const NumberStepper: React.FC<Props> = ({ label, value, min, max, onChange, disabled, ...props }) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  return (
    <Layout
      __className={classNames(DS_CLASS_NAME, 'NumberStepper', `is-size-${size}`)}
      direction="row"
      justify="space-between"
      align="center"
    >
      <span>{label}</span>
      <NumberStepperControl value={value} min={min} max={max} onChange={onChange} size={size} disabled={disabled} />
    </Layout>
  );
};

export default NumberStepper;
