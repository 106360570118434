import { Media } from 'context';
import { Size } from 'ds/Text';
import { LOCATION_PATH } from 'routes';

interface Values {
  textSize: Size;
  textMargin: number;
  headingMargin: number;
  subSectionMargin: number;
  sectionMargin: number;
  contentMarginY: number;
}

export const getPolicyPageValues = (mediaContext: Media): Values => {
  const { xs } = mediaContext;

  return {
    textSize: 'body2',
    textMargin: xs ? 12 : 16,
    headingMargin: xs ? 32 : 48,
    subSectionMargin: xs ? 32 : 48,
    sectionMargin: xs ? 40 : 48,
    contentMarginY: xs ? 48 : 128
  };
};

export function generateLocationPath({
  location,
  country,
  region
}: {
  location: string;
  region: string;
  country: string;
}): string {
  return `${LOCATION_PATH}/${country.toLowerCase()}/${region.toLowerCase()}/${location
    .replaceAll(/\s/g, '-')
    .trim()
    .toLowerCase()}`;
}

export const HOW_HEARD_OPTIONS = [
  { label: 'Search engine (Google, Yahoo, etc.)', value: 'Search engine (Google, Yahoo, etc.)' },
  { label: 'Recommended by friend or colleague', value: 'Recommended by friend or colleague' },
  { label: 'Social media', value: 'Social media' },
  { label: 'Blog or publication', value: 'Blog or publication' },
  { label: 'Event', value: 'Event' },
  { label: 'Other', value: 'Other' }
];
