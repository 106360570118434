import React, { useContext, useRef, useState } from 'react';
import { EditableImage, colorToRgba } from '@codiwork/codi';
import { ReactComponent as UploadSvg } from '@codiwork/codi/lib/assets/upload.svg';
import pluralize from 'pluralize';
import Dropzone from 'react-dropzone';

import { app, media } from 'context';

import SortablePhotoContainer from './SortablePhotoContainer';
import { OnPhotoChange } from './types';
import { getThumbnailValues, importPhotos } from './utils';
import Layout from '../Layout';
import Spinner from '../Spinner';
import Text from '../Text';
import { FOOTER_HEIGHT, IS_TOUCH_DEVICE, WORKSPACE_PHOTO_W_TO_H_RATIO } from '../constants';

const TRANSITION_DURATION = 150;
export const PHOTO_IMPORT_HEIGHT = 200;

export interface Props {
  images: EditableImage[];
  onChange: OnPhotoChange;
  maxPhotoCount: number;
  fullWidth?: boolean;
  width?: number;
  disabled?: boolean;
  minRequired?: number;
  minWidth?: number;
  minHeight?: number;
  maxFileSize?: number;
  suppressHeightCalculation?: boolean;
}

const PhotoImport_DEPRECATED: React.FC<Props> = ({
  onChange,
  maxPhotoCount,
  fullWidth,
  disabled = false,
  minRequired = 0,
  minWidth = 3500,
  minHeight = 2500,
  maxFileSize = 50000000,
  suppressHeightCalculation = false,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { contentWidth, windowHeight } = useContext(app);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { xs } = useContext(media);
  const [dropzoneRect, setDropzoneRect] = useState<DOMRect>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [photoGridRect, setPhotoGridRect] = useState<DOMRect>();
  const [containerRect, setContainerRect] = useState<DOMRect>();
  const photoGridRef = useRef<HTMLDivElement>(null);

  const images = [...props.images];
  const containerWidth = fullWidth ? contentWidth : props.width || 0;
  const { width: thumbnailWidth, photosPerRow } = getThumbnailValues({
    containerWidth
  });
  const thumbnailHeight = thumbnailWidth / WORKSPACE_PHOTO_W_TO_H_RATIO;
  const uploadableCount = maxPhotoCount - images.length;
  const [hasTransitioned, setHasTransitioned] = useState<boolean>(false);
  const [transitionDuration, setTransitionDuration] = useState<number>(TRANSITION_DURATION);

  const handleDragStart = () => {
    setIsDragging(true);
    setTimeout(() => {
      setHasTransitioned(true);
    }, transitionDuration / 3);
  };

  const handleDragStop = () => {
    setIsDragging(false);
    setHasTransitioned(false);
  };

  const gridHeight = photoGridRect ? photoGridRect.height + (errorMessage ? 48 : 0) : undefined;
  let containerHeight: number | undefined;
  let dropzoneHeight: number | undefined;

  if (isDragging && dropzoneRect && containerRect) {
    dropzoneHeight = Math.min(520, windowHeight - FOOTER_HEIGHT - 24);
    containerHeight = containerRect.height + (gridHeight || 0);
  } else {
    dropzoneHeight = dropzoneRect?.height;
    containerHeight = containerRect?.height && gridHeight ? containerRect?.height + gridHeight : undefined;
  }

  return (
    <Layout
      __style={{
        ...(IS_TOUCH_DEVICE
          ? undefined
          : {
              width: containerWidth,
              height: containerHeight
            })
      }}
      position="relative"
      opacity={disabled ? 0.4 : undefined}
      onMeasure={rect => {
        if (containerRect || suppressHeightCalculation) return;

        setContainerRect(rect);
      }}
      zIndex={1}
    >
      {uploadableCount > 0 && (
        <Dropzone
          disabled={disabled}
          onDrop={files => {
            setIsUploading(true);
            importPhotos({
              files,
              images,
              maxPhotoCount,
              setErrorMessage,
              minWidth,
              minHeight,
              maxFileSize
            })
              .then(response => {
                const { images } = response;
                onChange({ images });
              })
              .finally(() => {
                setIsUploading(false);
                setTransitionDuration(0);
                setIsDragging(false);
                setTimeout(() => {
                  setTransitionDuration(TRANSITION_DURATION);
                }, 10);
              });
          }}
          accept="image/png, image/jpeg, image/heic"
          onDragEnter={handleDragStart}
          onDragLeave={handleDragStop}
          style={{}}
        >
          <Layout
            borderWidth={2}
            borderRadius={12}
            borderColor={isDragging ? 'blue-500' : 'gray-300'}
            paddingY={36}
            borderStyle="dashed"
            cursor="pointer"
            __style={{
              height: dropzoneHeight,
              background: isDragging ? colorToRgba('blue-50', 1) : colorToRgba('blue-50', 0),
              transitionProperty: 'height, background, border-color',
              transitionDuration: `${transitionDuration}ms`,
              transitionTimingFunction: 'ease-in-out'
            }}
            onMeasure={rect => {
              setDropzoneRect(rect);
            }}
          >
            <Layout align="center" justify="center" direction="column" height="100%">
              {isUploading && isDragging ? (
                <Spinner color="blue-500" />
              ) : (
                <>
                  <Layout width={64}>
                    <UploadSvg />
                  </Layout>
                  {xs ? (
                    <Text size="body1" inline scale>
                      <Text inline bold color="blue-500" scale>
                        Browse
                      </Text>{' '}
                      to choose a file
                    </Text>
                  ) : (
                    <>
                      <Text size="h6" scale>
                        {isDragging || uploadableCount === maxPhotoCount
                          ? 'Drop photos to instantly upload'
                          : `Drag and drop up to ${uploadableCount} more ${pluralize('photos', uploadableCount)}`}
                      </Text>
                      {isDragging ? null : (
                        <Text size="body1" color="gray-600" scale>
                          or{' '}
                          <Text inline bold color="blue-500" scale>
                            browse
                          </Text>{' '}
                          to choose a file
                        </Text>
                      )}
                    </>
                  )}
                </>
              )}
            </Layout>
          </Layout>
        </Dropzone>
      )}
      <Layout
        {...(containerRect && dropzoneRect?.height && !IS_TOUCH_DEVICE
          ? { top: (uploadableCount === 0 ? 0 : dropzoneRect.height) + (errorMessage ? 48 : 0) }
          : undefined)}
        zIndex={isDragging && hasTransitioned ? -1 : undefined}
        opacity={isDragging ? 0 : 1}
        transition={`opacity ${transitionDuration}ms ease-in-out`}
        __ref={photoGridRef}
        onMeasure={rect => {
          if (photoGridRect) return;

          setPhotoGridRect(rect);
        }}
        wrap
        flex
      >
        <SortablePhotoContainer
          onSortEnd={({ oldIndex, newIndex }) => {
            const updatedPhotos = [...images];
            updatedPhotos.splice(newIndex, 0, updatedPhotos.splice(oldIndex, 1)[0]);

            onChange({
              images: updatedPhotos
            });
          }}
          distance={1}
          axis="xy"
          photos={images}
          onChange={params => {
            onChange(params);
          }}
          thumbnailWidth={thumbnailWidth}
          photosPerRow={photosPerRow}
          thumbnailHeight={thumbnailHeight}
          disabled={disabled || images.length <= minRequired}
        />
      </Layout>
      <Layout marginY={12}>
        {errorMessage !== null && (
          <Text size="body2" color="red-700" scale>
            {errorMessage}
          </Text>
        )}
      </Layout>
    </Layout>
  );
};

export default PhotoImport_DEPRECATED;
