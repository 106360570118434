import React, { useContext } from 'react';
import { GRAY_50_HEX } from '@codiwork/codi';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

interface Props {}

const FlexOffice: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <Layout marginTop={36} flex direction={xs ? 'column' : 'row'}>
      <Layout maxWidth={xs ? contentWidth : 292}>
        <Layout align="center">
          <Layout
            width={44}
            height={44}
            borderColor="blue-gradient"
            borderRadius="circular"
            align="center"
            justify="center"
          >
            <Icon name="users" size="md" color="blue-gradient" />
          </Layout>
          <Layout marginLeft={12}>
            <Text size="h5" color="blue-gradient">
              Flex Office
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={36}>
          <Text size="h6" bold={false} color="gray-700">
            Thanks to the widespread adoption of hybrid work, new workplace models are beginning to open up as the world
            of commercial real estate adapts to the shift in demand. A number of new “Flex Office” companies have been
            founded over the past few years.
            <br />
            <br />
            The Flex Office model offers a best-of-all-worlds approach to commercial office space: you can rent private
            offices, furnish them how you like, and make no long-term commitments. There are a few emerging players in
            this space. Codi is among them, offering the most flexible terms on the market along with ongoing office
            management.
          </Text>
        </Layout>
      </Layout>
      <Layout
        boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
        color="white"
        borderRadius={24}
        marginLeft={xs ? undefined : 24}
        marginTop={xs ? 24 : undefined}
      >
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '20px 40px 16px 40px', width: '50%' }}>
                <Text size="h6" color="blue-gradient">
                  PROS
                </Text>
              </th>
              <th style={{ textAlign: 'left', padding: '20px 40px 16px 72px', width: '50%' }}>
                <Text size="h6" color="gray-300">
                  CONS
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Easy, turnkey process
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Just like a coworking experience, getting started is designed to be a breeze and you can be working
                    in your space in just 1-4 weeks.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Limited stock
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    The commercial real estate industry is just now starting to innovate. As a result, you may not yet
                    find these more flexible options in your city, or in exactly the neighborhoods you’d like to be in.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  No long-term commitment
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    While there may be a minimum number of months required, you won’t be required to enter into a
                    long-term lease.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Less customization
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    You can make your office feel like home with tailored decor and hand-picked furniture, but it’s
                    unlikely you’ll be able to do major construction to change the floorplan.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Cost transparency & management
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    You know what you’re getting with the monthly fee. Cleaning and general space management are all
                    taken care of. You don’t need to worry about complicated agreements and the space is managed for
                    you.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Monthly costs can be higher than other options
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Because flexible spaces work by a different model, taxes can be higher depending on the state you’re
                    in.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Timeshare option
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Some flexible platforms allow you to choose a pay-for-only-the-days-you-need “timeshare“ model.
                    Perfect for hybrid teams that might not need to come into the office every day.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}></td>
            </tr>
          </tbody>
        </table>
      </Layout>
    </Layout>
  );
};

export default FlexOffice;
