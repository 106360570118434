import React, { useRef } from 'react';

import { OnMeasure } from './types';

export interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  alt: string;
  src: string;
  width?: number;
  height?: number;
  progressiveJpg?: boolean;
  compression?: boolean;
  onMeasure?: OnMeasure;
}

const Img: React.FC<Props> = ({ src, width, height, alt, progressiveJpg = true, compression, onMeasure, ...props }) => {
  const ref = useRef<HTMLImageElement | null>(null);
  return (
    <img
      ref={ref}
      {...props}
      src={transformSrcToButterUrl({ src, width, height, progressiveJpg, compression })}
      alt={alt}
      onLoad={() => {
        if (!onMeasure) return;

        const element = ref.current;

        if (!element) return;

        onMeasure(element.getBoundingClientRect());
      }}
    />
  );
};

export const transformSrcToButterUrl = ({
  src,
  width,
  height,
  progressiveJpg = true,
  compression = true
}: {
  src: string;
  width?: number;
  height?: number;
  progressiveJpg?: boolean;
  compression?: boolean;
}) => {
  let url;

  try {
    url = new URL(src);
  } catch {
    return src;
  }

  if (!url.hostname.includes('buttercms')) return src;

  const imageKey = url.pathname.split('/').slice(-1)[0];
  const pathComponents = compression ? ['/output=compress:true'] : [];

  if (compression && progressiveJpg) {
    pathComponents.push('/pjpg=quality:85');
  } else if (compression) {
    pathComponents[0] = pathComponents[0] + ',quality:85';
  }

  let pathname = `${pathComponents.join('')}/${imageKey}`;

  const resizeParams = [];

  if (width) {
    resizeParams.push(`width:${width}`);
  }

  if (height) {
    resizeParams.push(`height:${height}`);
  }

  if (resizeParams.length) {
    resizeParams.push('fit:crop');
    pathname = `/resize=${resizeParams.join(',')}` + pathname;
  }

  url.pathname = pathname;

  return url.toString();
};

export default Img;
