import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';
import { COMMERCIAL_REAL_ESTATE_PATH, OFFICE_REQUEST_PATH } from 'routes';

interface Props {}

const CTAs: React.FC<Props> = () => {
  const { xs, isMobile } = useContext(media);

  return (
    <Layout
      color="blue-700"
      borderRadius={24}
      {...(xs ? { paddingX: 36, paddingY: 48 } : { paddingY: 48, flex: true })}
    >
      <Layout
        {...(xs
          ? {}
          : { equalWidth: true, paddingX: 71, __style: { borderRight: '0.50px rgba(255, 255, 255, 0.55) solid' } })}
      >
        <Layout {...(xs ? {} : { paddingY: 68 })}>
          <Text size={isMobile ? 'headline-lg' : 'display-sm'} color="white">
            Unlock your ideal workspace today.
          </Text>
          <Layout marginTop={32}>
            <Link href={OFFICE_REQUEST_PATH}>
              <Layout align="center">
                <Text size="headline-xs" color="white" semibold>
                  Get started
                </Text>
                <Layout marginLeft={8} display="inline-flex">
                  <Icon name="rightArrow" color="white" size="md" stroke={2} />
                </Layout>
              </Layout>
            </Link>
          </Layout>
        </Layout>
      </Layout>
      {xs && (
        <Layout
          marginY={36}
          opacity={0.55}
          __style={{
            borderTop: '1px rgba(255, 255, 255, 0.55) solid'
          }}
        />
      )}
      <Layout {...(xs ? {} : { equalWidth: true, paddingX: 71 })}>
        <Layout {...(xs ? {} : { paddingY: 68 })}>
          <Text size={isMobile ? 'headline-lg' : 'display-sm'} color="white">
            Looking for a tenant?
          </Text>
          <Layout marginTop={32}>
            <Link href={COMMERCIAL_REAL_ESTATE_PATH}>
              <Layout align="center">
                <Text size="headline-xs" color="white" semibold>
                  List with Codi
                </Text>
                <Layout marginLeft={8} display="inline-flex">
                  <Icon name="rightArrow" color="white" size="md" stroke={2} />
                </Layout>
              </Layout>
            </Link>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CTAs;
