import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const LeaseTermsForNegotiation: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Text size="h3" color="blue-600" scale={xs}>
          Lease Terms For Negotiation
        </Text>
        <Layout marginTop={48} flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <Text size="h6" bold={false} color="gray-700">
              When renting a commercial property, your lease dictates your responsibilities and outlines your
              protections. Make sure it includes everything you need or think you may need in the future from your
              landlord. Here are some things to consider in your negotiations:
            </Text>
            <br />
            <ul>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Asking landlord to do work
                  </Text>
                  {'  '}
                  You may need work done on the property. Be sure to have all the necessary work spelled out
                  specifically in the lease — what needs to be done: wiring, FFE (furniture, fixtures, equipment), etc.
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Specify free-rent dates
                  </Text>
                  {'  '}
                  You may negotiate days of free rent based on the need for repairs, move in dates, etc.
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Leverage Tenant Improvement Allowance
                  </Text>
                  {'  '}
                  Wherever possible, see if your landlord will contribute to a Tenant Improvement Allowance — a budget
                  for improvements to the space, especially in areas where your work will raise the value of the space
                  for the long term.
                </Text>
              </li>
            </ul>
          </Layout>
          <Layout marginLeft={xs ? undefined : 80} equalWidth>
            <ul>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Custom renovations
                  </Text>
                  {'  '}
                  Determine what, if any, build-outs, design, or structural changes you are allowed to make and include
                  any and all permissions you’ll need.
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Ask for furnishings
                  </Text>
                  {'  '}
                  In more renter-friendly markets, landlords may be willing to cover some furnishings to get you in the
                  door. Some spaces may even be offered fully furnished.
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Negotiated security deposit
                  </Text>
                  {'  '}
                  Security deposits can vary greatly and often depend on your financials. A standard deposit can be
                  equal to 3–6 months of rent depending on terms/concessions negotiated.
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Termination options
                  </Text>
                  {'  '}
                  The landlord may push for a high cost of breaking the lease, usually on longer-term contracts.
                  Negotiate that down wherever possible.
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Sublet & assignment
                  </Text>
                  {'  '}
                  Should you want to defray costs or move out before your lease ends, you’ll need to negotiate your
                  options ahead of time. Take note: some landlords may try to heavily restrict sublets, or find ways to
                  slow the process.
                </Text>
              </li>
            </ul>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default LeaseTermsForNegotiation;
