import React from 'react';

import { Layout, Text } from 'ds';

interface Props {}

const HeaderText: React.FC<Props> = () => {
  return (
    <>
      <Layout>
        <Text size="h3" scale>
          <Text inline color="blue-700" scale>
            Codi
          </Text>
          <Text inline size="h3" color="blue-500" scale>
            {' '}
            Add-On Catalog
          </Text>
        </Text>
      </Layout>
      <Layout marginTop={24}>
        <Text size="h6" bold={false} scale>
          Every Codi comes with our standard set of workstations, conference tables, lounge seating and more. We offer
          upgrades for select items so your team can add that extra personalization to their space.
          <br />
          <br />
          Monthly cost per item varies by contract length.
        </Text>
      </Layout>
    </>
  );
};

export default HeaderText;
