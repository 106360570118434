import React from 'react';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import { OnChangeParams } from 'ds/inputs/types';
import { FieldSize } from 'ds/types';

export interface Props {
  value: number;
  onChange: (value: number) => void;
  size: FieldSize;
  min?: number;
  max?: number;
  disabled?: boolean;
}

export type OnChange = (params: OnChangeParams) => void;

const SIZE_TO_WIDTH: Record<FieldSize, number> = {
  xxs: 40,
  xs: 64,
  sm: 80,
  md: 104,
  lg: 120
};

const NumberStepperControl: React.FC<Props> = ({ value, onChange, min = 0, size, max = undefined, disabled }) => {
  const increment = () => {
    if (canIncrement) {
      onChange(value + 1);
    }
  };

  const decrement = () => {
    if (canDecrement) {
      onChange(value - 1);
    }
  };

  const canIncrement = !max || value < max;
  const canDecrement = value > min;

  return (
    <Layout
      __className="NumberStepperControl"
      direction="row"
      justify="space-between"
      align="center"
      width={SIZE_TO_WIDTH[size]}
    >
      <div
        onClick={() => (disabled ? undefined : decrement())}
        style={{
          cursor: canDecrement ? 'pointer' : 'default',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Icon name="minus" size={size} color={canDecrement ? 'blue-500' : 'gray-300'} />
      </div>
      <span color="black">{value}</span>
      <div
        onClick={() => (disabled ? undefined : increment())}
        style={{
          cursor: canIncrement ? 'pointer' : 'default',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Icon name="plus" size={size} color={canIncrement ? 'blue-500' : 'gray-300'} />
      </div>
    </Layout>
  );
};

export default NumberStepperControl;
