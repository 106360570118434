import React from 'react';
import type { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

import { Layout, Text } from 'ds';

import { SearchLocation } from './types';

interface Props {
  searchLocation: SearchLocation;
}

const Breadcrumbs: React.FC<Props> = ({ searchLocation }) => {
  let items: ListItem[] = [];

  let position = 1;

  items.push({
    '@type': 'ListItem',
    position: position,
    item: {
      '@id': `${window.location.origin}/#website`,
      name: 'Codi',
      url: window.location.origin
    }
  });
  position++;

  if (searchLocation.type === 'Market') {
    items.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': window.location.href,
        name: `${searchLocation.name} Office Space for Rent`,
        url: window.location.href
      }
    });
    position++;
  } else if (searchLocation.type === 'Submarket') {
    items.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': `${window.location.origin}${searchLocation.market?.url}`,
        name: searchLocation.market?.name,
        url: `${window.location.origin}${searchLocation.market?.url}`
      }
    });
    position++;
    items.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': window.location.href,
        name: `${searchLocation.name} Office Space for Rent`,
        url: window.location.href
      }
    });
    position++;
  } else if (searchLocation.type === 'Neighborhood') {
    items.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': `${window.location.origin}${searchLocation.market?.url}`,
        name: searchLocation.market?.name,
        url: `${window.location.origin}${searchLocation.market?.url}`
      }
    });
    position++;
    items.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': `${window.location.origin}${searchLocation.submarket?.url}`,
        name: searchLocation.submarket?.name,
        url: `${window.location.origin}${searchLocation.submarket?.url}`
      }
    });
    position++;
    items.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': window.location.href,
        name: `${searchLocation.name} Office Space for Rent`,
        url: window.location.href
      }
    });
    position++;
  }

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items
  };

  return (
    <Layout align="center">
      <script type="application/ld+json">{JSON.stringify(breadcrumbs)}</script>
      <Text tag="h2" size="body-sm" wrap>
        {!!searchLocation.market && (
          <Text color="blue-500" inline>
            <a href={`${searchLocation.market?.url}`}>{searchLocation.market?.name}</a>
          </Text>
        )}
        {!!searchLocation.market && !!searchLocation.submarket && (
          <Text size="body-sm" inline>
            &nbsp;/&nbsp;
          </Text>
        )}
        {!!searchLocation.submarket && (
          <Text color="blue-500" inline>
            <a href={`${searchLocation.submarket?.url}`}>{searchLocation.submarket?.name}</a>
          </Text>
        )}
        {searchLocation.type !== 'Market' && (
          <Text size="body-sm" inline>
            &nbsp;/&nbsp;
          </Text>
        )}
        <Text size="body-sm" inline>
          {searchLocation.name} Office Space for Rent
        </Text>
      </Text>
    </Layout>
  );
};

export default Breadcrumbs;
