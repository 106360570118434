import React, { useContext } from 'react';
import { GRAY_25_HEX } from '@codiwork/codi';

import { app, media } from 'context';
import { ContentBlock, Img, Layout, Text } from 'ds';

interface Props {}

const Quotes: React.FC<Props> = () => {
  const { contentPaddingX, contentWidth } = useContext(app);
  const { isMobile } = useContext(media);
  const quotes = (
    <>
      <Layout
        paddingTop={88}
        paddingBottom={36}
        borderRadius={12}
        color="white"
        boxShadow="0 13px 23px 0 rgba(0, 0, 0, 0.1500)"
        paddingX={36}
        position="relative"
        {...(isMobile ? { width: contentWidth, flexShrink: 0, marginTop: 80, marginBottom: 60 } : { equalWidth: true })}
      >
        <Layout direction="column" justify="space-around" height="100%">
          <Img
            srcKey="che-eppo_QpJmRpSMH.jpeg"
            folder="public"
            alt="Che from Eppo"
            width={340}
            style={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              objectFit: 'cover',
              border: `2px solid ${GRAY_25_HEX}`,
              position: 'absolute',
              left: 24,
              top: -24
            }}
          />
          <Layout align="center" flexGrow={1}>
            <Text size={isMobile ? 'body1' : 'h6'} color="gray-600" bold={false}>
              At WeWork or Industrious you cannot feel ownership of the space. Codi is different. We get turnkey offices
              in various locations and have everything taken care of.
            </Text>
          </Layout>
          <Layout marginTop={32} direction="column" justify="flex-end" flexGrow={1}>
            <Text size={isMobile ? 'body2' : 'body1'} bold>
              Che
            </Text>
            <Layout marginTop={4}>
              <Text size={isMobile ? 'tag' : 'body3'} notSemibold>
                CEO at Eppo
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <Layout
        paddingTop={88}
        paddingBottom={36}
        borderRadius={12}
        color="blue-500"
        boxShadow="0 13px 23px 0 rgba(0, 0, 0, 0.1500)"
        paddingX={36}
        position="relative"
        marginLeft={32}
        {...(isMobile ? { width: contentWidth, flexShrink: 0, marginTop: 80, marginBottom: 60 } : { equalWidth: true })}
      >
        <Layout direction="column" justify="space-around" height="100%">
          <Img
            srcKey="michelle-merman_Fq6Y4Ms5g.jpeg"
            folder="public"
            alt="Michelle from Merman"
            width={340}
            style={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              objectFit: 'cover',
              border: `2px solid ${GRAY_25_HEX}`,
              position: 'absolute',
              left: 24,
              top: -24
            }}
          />
          <Layout align="center" flexGrow={1}>
            <Text size={isMobile ? 'body1' : 'h6'} color="white" bold={false}>
              From start to finish the Codi team was attentive, professional, and efficient. They really took the time
              to understand my office needs and provided me with tailored solutions that met my requirements perfectly.
              I couldn't be happier with the results.
            </Text>
          </Layout>
          <Layout marginTop={32} direction="column" justify="flex-end" flexGrow={1}>
            <Text size={isMobile ? 'body2' : 'body1'} color="white" bold>
              Michelle
            </Text>
            <Layout marginTop={4}>
              <Text size={isMobile ? 'tag' : 'body3'} color="white" notSemibold>
                In-House Bidder at Merman
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <Layout
        paddingTop={88}
        paddingBottom={36}
        borderRadius={12}
        color="white"
        boxShadow="0 13px 23px 0 rgba(0, 0, 0, 0.1500)"
        paddingX={36}
        position="relative"
        marginLeft={32}
        {...(isMobile ? { width: contentWidth, flexShrink: 0, marginTop: 80, marginBottom: 60 } : { equalWidth: true })}
      >
        <Layout direction="column" justify="space-around" height="100%">
          <Img
            srcKey="karina-mastercard_I8j60RmXc.png"
            folder="public"
            alt="Karina from Mastercard"
            width={340}
            style={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              objectFit: 'cover',
              border: `2px solid ${GRAY_25_HEX}`,
              position: 'absolute',
              left: 24,
              top: -24
            }}
          />
          <Layout align="center" flexGrow={1}>
            <Text size={isMobile ? 'body1' : 'h6'} color="gray-600" bold={false}>
              Codi spaces are beautiful and in prime locations. What we like most about working with them is that they
              are always very accommodating. When we ask for some customization or an add-on — they make it happen. It’s
              been a smooth experience.
            </Text>
          </Layout>
          <Layout marginTop={32} direction="column" justify="flex-end" flexGrow={1}>
            <Text size={isMobile ? 'body2' : 'body1'} bold>
              Karina
            </Text>
            <Layout marginTop={4}>
              <Text size={isMobile ? 'tag' : 'body3'} notSemibold>
                Program Executive at Mastercard
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </>
  );

  return (
    <>
      <Layout color="blue-500" paddingY={120}>
        <ContentBlock maxWidth={1118}>
          <Layout {...(isMobile ? {} : { align: 'center' })}>
            <Layout direction="column" align="center">
              <Img
                srcKey="chinmay-ridepanda_bFaaq-0xl.jpeg"
                folder="public"
                alt="Chinmay from RidePanda"
                width={340}
                style={
                  isMobile
                    ? { width: 128, height: 128, borderRadius: '50%', objectFit: 'cover' }
                    : { width: 176, height: 176, borderRadius: '50%', objectFit: 'cover' }
                }
              />
              <Layout marginTop={40}>
                <Text size={isMobile ? 'h6' : 'h4'} color="white" wrap={false}>
                  Chinmay Malaviya
                </Text>
                <Text size={isMobile ? 'h6' : 'h4'} color="white" bold={false} wrap={false}>
                  CEO at RidePanda
                </Text>
              </Layout>
            </Layout>
            <Layout {...(isMobile ? { marginTop: 48 } : { marginLeft: 96 })}>
              <Text size={isMobile ? 'h5' : 'h3'} color="white" align={isMobile ? 'center' : undefined}>
                “We are a very happy customer of Codi. We talked to a brokerage firm and it was a big hassle, really
                archaic. Codi was seamless and easy.”
              </Text>
            </Layout>
          </Layout>
        </ContentBlock>
      </Layout>
      {isMobile ? (
        <Layout
          paddingX={contentPaddingX}
          marginTop={isMobile ? undefined : 160}
          {...(isMobile ? { flex: true, overflow: 'scroll' } : { flex: true, maxWidth: 1280 })}
        >
          {quotes}
        </Layout>
      ) : (
        <ContentBlock maxWidth={1280} paddingX={contentPaddingX} marginTop={160} direction="row" flex>
          {quotes}
        </ContentBlock>
      )}
    </>
  );
};

export default Quotes;
