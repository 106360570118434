import React, { PropsWithChildren } from 'react';

import Popover, { Props as PopoverProps } from './Popover';

export interface Props extends Omit<PopoverProps, 'content' | 'isVisible'> {
  text: string | JSX.Element;
  hidden?: boolean;
}

const Tooltip: React.FC<PropsWithChildren<Props>> = ({ text, color = 'black', children, hidden, ...props }) => {
  if (hidden) return <>{children}</>;

  let arrowPadding = 16;

  if (props.placement && ['left', 'right'].includes(props.placement)) {
    arrowPadding = 0;
  }

  return (
    <Popover color={color} arrowPadding={arrowPadding} {...props} content={text} isTooltip>
      {children}
    </Popover>
  );
};

export default Tooltip;
