import { Cart, CartItem, CustomerAddOnOrder, axios } from '@codiwork/codi';

export const createCart = ({ userId, planId }: { userId: number; planId: number }) => {
  return axios.post<Cart>(`/v1/users/${userId}/carts`, { plan_id: planId });
};

export const getCart = ({ userId, cartId }: { userId: number; cartId: number }) => {
  return axios.get<Cart>(`/v1/users/${userId}/carts/${cartId}`);
};

export const createCartItem = ({
  cartId,
  date,
  userId,
  productId,
  quantity,
  days_to_add,
  days_to_remove,
  product_to_remove_id
}: {
  cartId: number;
  productId: number;
  userId: number;
  days_to_add?: number[];
  days_to_remove?: number[];
  date?: string;
  quantity?: number;
  product_to_remove_id?: number;
}) => {
  return axios.post<CartItem>(`/v1/users/${userId}/carts/${cartId}/items`, {
    date,
    product_id: productId,
    quantity,
    days_to_add,
    days_to_remove,
    product_to_remove_id
  });
};

export const deleteCartItem = ({
  userId,
  cartId,
  cartItemId
}: {
  userId: number;
  cartId: number;
  cartItemId: number;
}) => {
  return axios.delete<void>(`/v1/users/${userId}/carts/${cartId}/items/${cartItemId}`);
};

export const changeCartItemQuantity = ({
  userId,
  cartId,
  cartItemId,
  quantity
}: {
  userId: number;
  cartId: number;
  cartItemId: number;
  quantity: number;
}) => {
  return axios.patch<void>(`/v1/users/${userId}/carts/${cartId}/items/${cartItemId}`, { item: { quantity } });
};

export const checkoutCart = ({ userId, cartId, planId }: { userId: number; cartId: number; planId: number }) => {
  return axios.post<CustomerAddOnOrder>(`/v1/users/${userId}/carts/${cartId}/checkout`, { plan_id: planId });
};
