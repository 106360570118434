import React, { useContext, useRef, useState } from 'react';
import { useWindowScroll } from 'beautiful-react-hooks';
import classNames from 'classnames';

import { app, media } from 'context';
import { Layout } from 'ds';
import useIntersectionObserver from 'ds/helpers/useInteractionObserver';

import { ReactComponent as BottomLogosXs } from './bottom-logos-xs.svg';
import { ReactComponent as BottomLogos } from './bottom-logos.svg';
import { ReactComponent as TopLogosXs } from './top-logos-xs.svg';
import { ReactComponent as TopLogos } from './top-logos.svg';

interface Props {}

const LogosMarquee: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowWidth } = useContext(app);
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, { threshold: 0 });
  const isVisible = entry?.isIntersecting;
  const [translatePercent, setTranslatePercent] = useState<number>(0);
  const bottomTranslatePercent = -120 + translatePercent;

  const updateTranslatePercent = () => {
    if (!isVisible) {
      return;
    }

    const top = ref.current?.getBoundingClientRect().top || 0;
    const translatePercent = ((top / window.innerHeight) * 100) / (xs ? 4 : 6);
    setTranslatePercent(translatePercent);
  };
  const onWindowScroll = useWindowScroll();
  onWindowScroll(updateTranslatePercent);

  const transformTop = `translateX(calc(-${translatePercent}% - ${xs ? 64 : 200}px))`;
  const transformBottom = `translateX(calc(${bottomTranslatePercent}% - ${xs ? 64 : 200}px))`;

  return (
    <Layout
      __className={classNames('Home-marqueeContainer', { 'is-xs': xs })}
      width={windowWidth}
      overflow="hidden"
      __ref={ref}
    >
      <Layout flex gap={xs ? 64 : 200}>
        <Layout __className="Home-logoMarqueeTop" transform={transformTop}>
          {xs ? <TopLogosXs /> : <TopLogos />}
        </Layout>
        <Layout __className="Home-logoMarqueeTop" transform={transformTop} aria-hidden="true">
          {xs ? <TopLogosXs /> : <TopLogos />}
        </Layout>
      </Layout>
      <Layout flex gap={xs ? 64 : 200} marginTop={48}>
        <Layout __className="Home-logoMarqueeBottom" transform={transformBottom}>
          {xs ? <BottomLogosXs /> : <BottomLogos />}
        </Layout>
        <Layout __className="Home-logoMarqueeBottom" aria-hidden="true" transform={transformBottom}>
          {xs ? <BottomLogosXs /> : <BottomLogos />}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LogosMarquee;
