import React, { useContext } from 'react';

import { media } from 'context';
import { Layout } from 'ds';

import Airbyte from 'assets/images/customer-logos/Airbyte.png';
import Eppo from 'assets/images/customer-logos/Eppo.png';
import Ridepanda from 'assets/images/customer-logos/Ridepanda.png';
import Siteline from 'assets/images/customer-logos/Siteline.png';
import TaskRabbit from 'assets/images/customer-logos/TaskRabbit.png';

interface Props {}

const Logos: React.FC<Props> = () => {
  const { xs, sm, md, lg, xxl } = useContext(media);

  const logos = [
    <img src={TaskRabbit} alt="TaskRabbit logo" style={{ objectFit: 'contain', width: 224 }} key="TaskRabbit" />,
    <img src={Ridepanda} alt="Ridepanda logo" style={{ objectFit: 'contain', width: 220 }} key="Ridepanda" />,
    <img src={Eppo} alt="Eppo logo" style={{ objectFit: 'contain', width: 115 }} key="Eppo" />,
    <img src={Airbyte} alt="Airbyte logo" style={{ objectFit: 'contain', width: 166 }} key="Airbyte" />,
    <img src={Siteline} alt="Siteline logo" style={{ objectFit: 'contain', width: 172 }} key="Siteline" />
  ];

  if (xxl) {
    return (
      <Layout flex justify="space-between" padding={32}>
        <img src={TaskRabbit} alt="TaskRabbit logo" style={{ objectFit: 'contain', width: 224 }} />
        <img src={Eppo} alt="Eppo logo" style={{ objectFit: 'contain', width: 115 }} />
        <img src={Airbyte} alt="Airbyte logo" style={{ objectFit: 'contain', width: 166 }} />
        <img src={Siteline} alt="Siteline logo" style={{ objectFit: 'contain', width: 172 }} />
        <img src={Ridepanda} alt="Ridepanda logo" style={{ objectFit: 'contain', width: 220 }} />
      </Layout>
    );
  } else if (lg) {
    return (
      <>
        <Layout flex justify="space-between" padding={32}>
          {logos.slice(0, 3)}
        </Layout>
        <Layout flex justify="space-around" padding={32}>
          {logos.slice(3)}
        </Layout>
      </>
    );
  } else if (md) {
    return (
      <Layout align="center" direction="column">
        <Layout flex justify="space-between" paddingY={32} width={572}>
          {logos.slice(0, 2)}
        </Layout>
        <Layout flex justify="space-between" paddingY={32} width={572}>
          {logos.slice(2, 4)}
        </Layout>
        <Layout flex justify="center" paddingY={32} width={572}>
          {logos[4]}
        </Layout>
      </Layout>
    );
  } else if (sm) {
    return (
      <Layout align="center" direction="column">
        <Layout flex justify="space-between" paddingY={32} width={508}>
          {logos.slice(0, 2)}
        </Layout>
        <Layout flex justify="center" paddingY={32} width={508}>
          {logos[2]}
        </Layout>
      </Layout>
    );
  } else if (xs) {
    return (
      <Layout align="center" direction="column">
        <Layout>{logos[0]}</Layout>
        <Layout marginTop={56}>{logos[1]}</Layout>
        <Layout marginTop={56}>{logos[2]}</Layout>
      </Layout>
    );
  }

  return <Layout></Layout>;
};

export default Logos;
