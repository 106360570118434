import React, { PropsWithChildren } from 'react';

import { Layout, PurpleGradientCircle, Text } from 'ds';

interface Props {
  isLastMessageInGroup: boolean;
}

const ChatbotThreadMessage: React.FC<PropsWithChildren<Props>> = ({ children, isLastMessageInGroup }) => {
  return (
    <Layout align="flex-end">
      <PurpleGradientCircle dimension={40} />
      <Layout
        color="white"
        paddingX={12}
        paddingY={12}
        marginLeft={12}
        borderTopRightRadius={16}
        borderBottomRightRadius={16}
        borderTopLeftRadius={16}
        {...(isLastMessageInGroup ? {} : { borderBottomLeftRadius: 16 })}
      >
        <Text size="body-sm">{children}</Text>
      </Layout>
    </Layout>
  );
};

export default ChatbotThreadMessage;
