import { useRef } from 'react';
import classNames from 'classnames';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import { IS_TOUCH_DEVICE } from 'ds/constants';
import { track } from 'lib/analytics';

import { ACTIVE_SORT_FILTER_COLOR, HEADER_CONTROL_SIZE, INACTIVE_SORT_FILTER_COLOR } from './constants';
import { ColumnWidthProps, FilterState, Filters, HeaderWidthProps, Row } from './types';

interface Props<T extends Row> extends HeaderWidthProps, ColumnWidthProps {
  filters: Filters<T>;
  searchInputOpen: boolean;
  setSearchInputOpen: (open: boolean) => void;
  filterInputOpen: boolean;
  setFilterInputOpen: (open: boolean) => void;
  dateRangeOpen: boolean;
  setDateRangeOpen: (open: boolean) => void;
  moneyRangeOpen: boolean;
  setMoneyRangeOpen: (open: boolean) => void;
  numberRangeOpen: boolean;
  setNumberRangeOpen: (open: boolean) => void;
  filter?: FilterState;
}

const FilterIcon = <T extends Row>({
  filters,
  filter,
  searchInputOpen,
  setSearchInputOpen,
  filterInputOpen,
  setFilterInputOpen,
  dateRangeOpen,
  setDateRangeOpen,
  moneyRangeOpen,
  setMoneyRangeOpen,
  numberRangeOpen,
  setNumberRangeOpen
}: Props<T>) => {
  const textSearchRef = useRef<HTMLButtonElement>(null);
  const oneOfRef = useRef<HTMLButtonElement>(null);
  const dateRef = useRef<HTMLButtonElement>(null);
  const moneyRangeRef = useRef<HTMLButtonElement>(null);
  const numberRangeRef = useRef<HTMLButtonElement>(null);
  const classes = classNames('Table-searchButton', { 'is-active': !!filter, 'is-touch': IS_TOUCH_DEVICE });

  if (!!filters.textSearch) {
    return (
      <button
        style={{ marginLeft: 4, display: 'inline-flex' }}
        ref={textSearchRef}
        className={classes}
        onClick={() => {
          track('Element Interacted', { type: 'iconButton', name: 'table text search' });
          setSearchInputOpen(!searchInputOpen);
        }}
      >
        <Icon
          name="search"
          size={HEADER_CONTROL_SIZE}
          color={!!filter ? ACTIVE_SORT_FILTER_COLOR : INACTIVE_SORT_FILTER_COLOR}
        />
      </button>
    );
  }
  if (!!filters.oneOf) {
    return (
      <button
        style={{ marginLeft: 4, display: 'inline-flex' }}
        ref={oneOfRef}
        className={classes}
        onClick={() => {
          track('Element Interacted', { type: 'iconButton', name: 'table oneOf' });
          setFilterInputOpen(!filterInputOpen);
        }}
      >
        <Icon
          name="search"
          size={HEADER_CONTROL_SIZE}
          color={!!filter ? ACTIVE_SORT_FILTER_COLOR : INACTIVE_SORT_FILTER_COLOR}
        />
      </button>
    );
  }
  if (!!filters.moneyRange) {
    return (
      <button
        style={{ marginLeft: 4, display: 'inline-flex' }}
        ref={moneyRangeRef}
        className={classes}
        onClick={() => {
          track('Element Interacted', { type: 'iconButton', name: 'table moneyRange' });
          setMoneyRangeOpen(!moneyRangeOpen);
        }}
      >
        <Icon
          name="search"
          size={HEADER_CONTROL_SIZE}
          color={!!filter ? ACTIVE_SORT_FILTER_COLOR : INACTIVE_SORT_FILTER_COLOR}
        />
      </button>
    );
  }
  if (!!filters.numberRange) {
    return (
      <button
        style={{ marginLeft: 4, display: 'inline-flex' }}
        ref={numberRangeRef}
        className={classes}
        onClick={() => {
          track('Element Interacted', { type: 'iconButton', name: 'table numberRange' });
          setNumberRangeOpen(!numberRangeOpen);
        }}
      >
        <Icon
          name="search"
          size={HEADER_CONTROL_SIZE}
          color={!!filter ? ACTIVE_SORT_FILTER_COLOR : INACTIVE_SORT_FILTER_COLOR}
        />
      </button>
    );
  }
  if (!!filters.date) {
    return (
      <button
        style={{ marginLeft: 4, display: 'inline-flex' }}
        ref={dateRef}
        className={classes}
        onClick={() => {
          track('Element Interacted', { type: 'iconButton', name: 'date filter' });
          setDateRangeOpen(!dateRangeOpen);
        }}
      >
        <Icon
          name="search"
          size={HEADER_CONTROL_SIZE}
          color={!!filter ? ACTIVE_SORT_FILTER_COLOR : INACTIVE_SORT_FILTER_COLOR}
        />
      </button>
    );
  }

  return (
    <Layout marginLeft={8} opacity={0}>
      <Icon name="search" size={HEADER_CONTROL_SIZE} color="gray-100" />
    </Layout>
  );
};

export default FilterIcon;
