import React, { useContext } from 'react';
import { AboutPage, BreadcrumbList, WithContext } from 'schema-dts';

import Head from 'components/Head';

import { app, media } from 'context';
import { Button, HangingImage, Layout, Text } from 'ds';
import Accordion from 'ds/Accordion';
import { CMSRouteProps } from 'routes/types';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

import VideoPlayer from '../VideoPlayer';

interface Props extends CMSRouteProps {}

const AboutUs: React.FC<Props> = ({ cmsSlug }) => {
  const { xxs, xs, sm, md, lg, xl } = useContext(media);
  const { contentPaddingX } = useContext(app);

  const page = useAppSelector(state => selectCmsPage(state, cmsSlug));

  if (!page) return <Layout minHeight="100vh" />;

  const {
    fields: {
      seo: { title: seoTitle, description: seoDescription, keywords: seoKeywords },
      hero: {
        title: heroTitle,
        body: heroBody,
        video: [{ url: videoUrl, thumbnail: videoThumbnail }]
      },
      featured: {
        title: featuredTitle,
        body: featuredBody,
        collage: [{ image: collageImage, alt_text: collageImageAltText }],
        banner: [{ image: bannerImage, alt_text: bannerImageAltText }]
      },
      values: {
        title: valuesTitle,
        image: [{ image: valuesImage, alt_text: valuesImageAltText }],
        value: values
      },
      recruiting: { title: recruitingTitle, cta: recruitingCta, href: recruitingHref }
    }
  } = page;

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${window.location.origin}/#website`,
          name: 'Codi',
          url: window.location.origin
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${window.location.origin}/#about-us`,
          name: seoTitle,
          url: window.location.href
        }
      }
    ]
  };

  const schema: WithContext<AboutPage> = {
    '@context': 'https://schema.org',
    '@type': 'AboutPage',
    breadcrumb: breadcrumbs,
    name: 'About Codi',
    url: window.location.href,
    speakable: { '@type': 'SpeakableSpecification', xpath: ["/html/head/meta[@name='description']/@content"] },
    description: seoDescription,
    significantLink: recruitingHref,
    publisher: {
      '@type': 'Corporation',
      name: 'Codi',
      legalName: 'Codi Inc.',
      logo: 'https://ik.imagekit.io/r2atwkj92wo/public/Codi-logo_icb15iqnP.png',
      image: 'https://ik.imagekit.io/r2atwkj92wo/public/tr:w-600/e6a421371e38c8d343831983605f0629_yu9zLLeag.jpeg',
      sameAs: [
        'https://www.linkedin.com/company/codiwork',
        'https://twitter.com/codiwork',
        'https://www.facebook.com/codicommunity',
        'https://www.instagram.com/codiwork/',
        'https://www.crunchbase.com/organization/codi-2000'
      ],
      brand: {
        '@type': 'Brand',
        name: 'Codi'
      },
      funder: [
        {
          '@type': 'Corporation',
          '@id': 'https://a16z.com/',
          legalName: 'AH Capital Management, LLC',
          name: 'Andreessen Horowitz',
          url: 'https://a16z.com/',
          sameAs: 'https://en.wikipedia.org/wiki/Andreessen_Horowitz'
        }
      ]
    }
  };

  return (
    <>
      <Head title={seoTitle} description={seoDescription} keywords={seoKeywords} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <Layout marginTop={sm || lg ? 80 : 16}>
        {/* HERO */}
        <>
          <Layout direction={!xl ? 'column' : 'row'}>
            <Layout width={!xl ? '100%' : '50%'} paddingRight={xl ? 32 : undefined}>
              <Text size={xs ? 'h1' : 'h2'} scale={xs}>
                <span dangerouslySetInnerHTML={{ __html: heroTitle }} />
              </Text>
            </Layout>
            <Layout
              width={!xl ? '100%' : '50%'}
              marginTop={!xl ? 32 : undefined}
              paddingLeft={xl ? 32 : undefined}
              paddingTop={xl ? 16 : undefined}
            >
              <Text size="h5" scale={xs} notBold>
                {heroBody}
              </Text>
            </Layout>
          </Layout>
          <Layout marginTop={xxs ? 32 : xs ? 40 : 48} width="100%">
            <VideoPlayer videoUrl={videoUrl} thumbnailUrl={videoThumbnail} rounded={false} fullWidth={!xl} />
          </Layout>
        </>
        {/* FEATURED */}
        <Layout marginTop={120} __className="CmsContent">
          <Text size="h2" align="center" scale>
            <span dangerouslySetInnerHTML={{ __html: featuredTitle }} />
          </Text>
          <Layout __className="CmsContent" direction="row">
            {!xs && (
              <Layout width="50%">
                {!xl && (
                  <Layout position="relative" left={-96}>
                    <HangingImage side="left" src={collageImage} alt={collageImageAltText} />
                  </Layout>
                )}
                {xl && (
                  <Layout paddingRight={48} borderRadius={40}>
                    <img src={collageImage} alt={collageImageAltText} style={{ borderRadius: 40 }} />
                  </Layout>
                )}
              </Layout>
            )}
            <Layout
              width={xs ? undefined : '50%'}
              paddingLeft={lg ? 64 : md ? 88 : undefined}
              position="relative"
              align="center"
            >
              <Layout position={xs ? undefined : 'relative'}>
                <Text size="paragraph" scale>
                  <span dangerouslySetInnerHTML={{ __html: featuredBody }} />
                </Text>
              </Layout>
            </Layout>
          </Layout>
          {xs && (
            <Layout marginTop={xxs ? 32 : 48} position="relative" width="100vw" left={-contentPaddingX}>
              <img src={bannerImage} alt={bannerImageAltText} />
            </Layout>
          )}
        </Layout>
        {/* VALUES */}
        <Layout width="100%" marginTop={120}>
          <Layout __className="CmsContent">
            <Text size="h2" align="center" scale>
              <span dangerouslySetInnerHTML={{ __html: valuesTitle }} />
            </Text>
          </Layout>
          <Layout marginTop={40} direction={xs || sm ? 'column' : 'row'} width="100%" justify="space-between">
            <Layout direction="column" width={xs || sm ? '100%' : '49%'}>
              <Accordion columns={1} items={values} />
            </Layout>
            <Layout
              width={xs || sm ? '100vw' : '49%'}
              marginLeft={xs || sm ? -contentPaddingX : undefined}
              justify="center"
              align="center"
              marginTop={xs || sm ? 40 : undefined}
            >
              <Layout>
                <img src={valuesImage} alt={valuesImageAltText} style={{ borderRadius: xs || sm ? undefined : 40 }} />
              </Layout>
            </Layout>
          </Layout>
        </Layout>
        {/* RECRUITING */}
        <Layout __className="CmsContent">
          <Text size={xs ? 'h4' : 'h5'} scale={xs} align="center">
            <span dangerouslySetInnerHTML={{ __html: recruitingTitle }} />
          </Text>
          <Layout justify="center" width="100%" marginTop={32}>
            <Button
              type="primary"
              size="lg"
              text={recruitingCta}
              href={recruitingHref}
              target="_blank"
              fullWidth={xs}
            />
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AboutUs;
