import React, { useContext } from 'react';

import { media } from 'context';
import { Grid, Layout, Text } from 'ds';
import { HOW_IT_WORKS_PATH, OFFICE_REQUEST_PATH, OFFSITES_PATH } from 'routes';

import Solution, { Props as SolutionProps } from './Solution';

interface Props {}

const CARDS: SolutionProps[] = [
  {
    icon: 'buildingNew',
    title: 'Full-Time HQ',
    description:
      'Move-in ready offices with flexible leases from 6 months, offering the freedom to customize your workspace and scale your business effortlessly.',
    link: { href: OFFICE_REQUEST_PATH, text: 'Get started' }
  },
  {
    icon: 'calendar',
    title: 'Hybrid Office',
    description:
      'Your cost-effective solution for thriving in the hybrid work era with private offices, shared costs, and premium amenities.',
    link: { href: HOW_IT_WORKS_PATH, text: 'Learn more' }
  },
  {
    icon: 'users',
    title: 'Offsites',
    description:
      'The full-service event solution that delivers seamless planning and execution for unforgettable corporate retreats, team-building activities, and more.',
    link: { href: OFFSITES_PATH, text: 'Book now' }
  }
];

const Solutions: React.FC<Props> = () => {
  const { isMobile, xs, md } = useContext(media);

  return (
    <Layout>
      <Text
        size={isMobile ? 'overline-md' : md ? 'overline-lg' : 'display-sm'}
        color={isMobile ? 'blue-300' : 'blue-600'}
        align="center"
        caps={isMobile}
      >
        Our office solutions
      </Text>
      {isMobile ? (
        <Layout justify="center">
          <Layout marginTop={24} {...(xs ? {} : { maxWidth: 580 })}>
            <Grid itemsPerRow={1} gapY={24}>
              {CARDS.map(c => {
                return <Solution {...c} key={c.title} />;
              })}
            </Grid>
          </Layout>
        </Layout>
      ) : (
        <Layout marginTop={32} padding={64} color="blue-100" borderRadius={24}>
          <Grid itemsPerRow={3} gapX={48}>
            {CARDS.map(c => {
              return <Solution {...c} key={c.title} />;
            })}
          </Grid>
        </Layout>
      )}
    </Layout>
  );
};

export default Solutions;
