export const GALLERY_WIDTH_LARGE = 322;
export const GALLERY_WIDTH_SMALL = 155;
export const GALLERY_HEIGHT_LARGE = 192;
export const GALLERY_HEIGHT_SMALL = 116;
export const GALLERY_SM_WIDTH = 180;
export const GALLERY_SM_HEIGHT = 120;
export const IMAGE_WIDTH = 988;
export const IMAGE_HEIGHT = 628;
export const TOUR_VIDEO_WIDTH = 1344;
export const TOUR_VIDEO_HEIGHT = 706;
