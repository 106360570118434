import React, { useContext, useEffect, useRef } from 'react';

import { featureEnabled } from 'helpers/features';
import { showSnackbar } from 'helpers/snackbar';

import { app } from 'context';
import { Layout, Modal } from 'ds';
import { isMobile } from 'helpers';
import { actions } from 'store/Chatbot';
import { selectChatbotIsVisible, selectChatbotScreen } from 'store/Chatbot/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ChatbotBottomNavigation from './ChatbotBottomNavigation';
import ScreenContainer from './ChatbotScreenContainer';
import CustomerChatbotThreadInput from './CustomerChatbotThreadInput';
import { CHATBOT_BUTTON_DIMENSION, CHATBOT_DESKTOP_WIDTH, CHATBOT_THREADS_SCREEN } from './constants';
import { fetchThreads } from './requests';
import useChatbotHeight from './useChatbotHeight';

interface Props {}

const Chatbot: React.FC<Props> = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectChatbotIsVisible);
  const { windowWidth } = useContext(app);
  const height = useChatbotHeight();
  const isThreadScreen = useAppSelector(selectChatbotScreen).value.startsWith(`${CHATBOT_THREADS_SCREEN}/`);
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setThreads = () =>
      fetchThreads()
        .then(({ data }) => {
          dispatch(actions.setThreads(data));
        })
        .catch(() => {
          showSnackbar({ message: 'Uh oh! There was an error connecting. Please try again later.', negative: true });
        })
        .finally(() => {});

    setThreads();
  }, [dispatch]);

  if (!user) return null;

  if (!featureEnabled({ user, name: 'chatbotCustomer' })) return null;

  return isMobile ? (
    <Modal
      isVisible={isVisible}
      onClose={() => dispatch(actions.setIsVisible(false))}
      showCloseButton={false}
      footer={isThreadScreen ? <CustomerChatbotThreadInput /> : <ChatbotBottomNavigation />}
      width={windowWidth}
      bottomSheetBackgroundColor="blue-50"
      containerRef={bottomSheetRef}
      isFullScreen
      hasBottomSheetFooterPadding={false}
    >
      <ScreenContainer bottomSheetRef={bottomSheetRef} />
    </Modal>
  ) : (
    <Layout
      display={isVisible ? undefined : 'none'}
      zIndex={9998}
      position="fixed"
      right={24}
      bottom={24 + 8 + CHATBOT_BUTTON_DIMENSION}
      width={CHATBOT_DESKTOP_WIDTH}
      height={height}
      direction="column"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
      borderRadius={8}
    >
      <ScreenContainer />
    </Layout>
  );
};

export default Chatbot;
