import React, { useContext } from 'react';
import { colorClassName } from '@codiwork/codi';
import classNames from 'classnames';

import { media } from 'context';
import { Icon, Img, Layout, Link, Text } from 'ds';
import { CASE_STUDIES_PATH } from 'routes';

import { ReactComponent as TaskRabbitLogo } from 'ds/task-rabbit-logo-2.svg';

interface Props {}

const CaseStudy: React.FC<Props> = () => {
  const { isMobile, md, xs } = useContext(media);

  return (
    <Layout {...(isMobile ? {} : { flex: true })}>
      <Layout {...(isMobile ? {} : { maxWidth: md ? 460 : 520 })}>
        <Text size={isMobile ? 'overline-md' : 'overline-lg'} color="blue-500" semibold>
          SEE WHAT CLIENTS SAY
        </Text>
        <Layout marginTop={12}>
          <Text size={isMobile ? 'headline-md' : 'headline-lg'} color="blue-700">
            Taskrabbit improves collaboration for over 200 employees
          </Text>
        </Layout>
        <Layout marginTop={32}>
          <Text size="body-md" color="gray-400">
            Codi helped TaskRabbit host 8 offsites across the span of 12 months. Discover how Codi’s workspaces and
            managed services helped drive this success.
          </Text>
        </Layout>
        <Layout marginTop={32}>
          <Link href={`${CASE_STUDIES_PATH}/taskrabbit`}>
            <Layout align="center">
              <Text size={isMobile ? 'body-md' : 'body-sm'} color="blue-500" semibold>
                Read full story
              </Text>
              <Layout marginLeft={8} display="inline-flex">
                <Icon name="rightArrow" color="blue-500" size="sm" stroke={2} />
              </Layout>
            </Layout>
          </Link>
        </Layout>
      </Layout>
      <Layout {...(isMobile ? { marginTop: 48 } : { marginLeft: 80 })} position="relative">
        <Img
          srcKey={isMobile ? 'home-page-testiomonial-xs_tDsxURa4Q' : 'home-page-case-study_NC6bLcu5G.jpeg'}
          alt="office"
          {...(isMobile ? { width: 684 } : { width: 1000 })}
          folder="public"
          style={{
            borderRadius: 16,
            height: xs ? 236 : 360,
            objectFit: 'cover'
          }}
        />
        <Layout
          position="absolute"
          {...(isMobile
            ? { paddingY: 16, paddingX: 14, left: 20, bottom: 20 }
            : { paddingY: 12, paddingX: md ? 20 : 28, bottom: 24, left: -16 })}
          borderRadius={8}
          background="rgba(255, 255, 255, 0.80)"
          boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
          align="center"
        >
          <span className={classNames('Icon', colorClassName('gray-400'))}>
            <TaskRabbitLogo width={isMobile ? 142 : md ? 154 : 225} />
          </span>
          <Layout marginLeft={20}>
            <Text size="body-sm" color="gray-400">
              Client since
              <br />
              March 2022
            </Text>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CaseStudy;
