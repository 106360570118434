import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { getLocationSlug, getLocations } from 'lib/butter';
import { FOUR_ZERO_FOUR_PATH } from 'routes';

import { generateLocationPath } from '../utils';

interface RouteMatchProps {
  slug: string;
}

interface Props extends RouteComponentProps<RouteMatchProps> {}

const CityPage: React.FC<Props> = ({
  match: {
    params: { slug }
  }
}) => {
  const [redirect, setRedirect] = useState<string>();

  useEffect(() => {
    getLocations().then(locations => {
      const matchedLocation = locations.find(l => getLocationSlug(l) === slug);

      const redirect = matchedLocation
        ? generateLocationPath({
            location: matchedLocation.name,
            region: matchedLocation.region,
            country: matchedLocation.country
          })
        : FOUR_ZERO_FOUR_PATH;

      setRedirect(redirect);
    });
  }, [slug]);

  if (!redirect) return null;

  return <Redirect to={redirect} />;
};

export default CityPage;
