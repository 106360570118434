import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useWindowScroll } from 'beautiful-react-hooks';

import { app } from 'context';
import { HEADER_Z_INDEX, Layout } from 'ds';

interface Props {}

const CaseStudyStickyFooter: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { contentPaddingX } = useContext(app);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [height, setHeight] = useState<number>();

  const handleScroll = () => {
    const element = ref.current;

    if (!element || !height) return;

    if (window.scrollY >= height) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  const onWindowScroll = useWindowScroll();
  onWindowScroll(handleScroll);

  useEffect(() => {
    window.document.body.style.paddingBottom = `${height}px`;

    return () => {
      window.document.body.style.paddingBottom = 'unset';
    };
  }, [height]);

  return (
    <Layout
      {...(isSticky
        ? {
            bottom: 0,
            position: 'fixed',
            boxShadow: '2px 0px 22px rgba(87, 73, 109, 0.12)',
            zIndex: HEADER_Z_INDEX - 1
          }
        : {})}
      paddingX={contentPaddingX}
      paddingY={12}
      onMeasure={({ height }) => setHeight(height)}
      __ref={ref}
      color="white"
      width="100vw"
      transform={`translateX(${-contentPaddingX}px)`}
    >
      {children}
    </Layout>
  );
};

export default CaseStudyStickyFooter;
