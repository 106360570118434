import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

interface Props {
  step: string;
  text: string;
}

const HowItWorksText: React.FC<Props> = ({ text, step }) => {
  const { xs } = useContext(media);

  return (
    // height: 212 is a <hack> to make sure all slides are the same height
    <Layout marginY={24} {...(xs ? { height: 212 } : {})}>
      <Text size="headline-lg" color="blue-500">
        {step}
      </Text>
      <Layout {...(xs ? {} : {})} marginTop={xs ? 24 : 32}>
        <Text size={xs ? 'headline-sm' : 'headline-xs'} color="gray-400" bold={false}>
          {text}
        </Text>
      </Layout>
    </Layout>
  );
};

export default HowItWorksText;
