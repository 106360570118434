import { LatLng } from '@codiwork/codi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ReferralInfo } from 'ux/Public/types';

export type OfficeRequestState = {
  referralInfo?: ReferralInfo;
  id?: number;
  market: string | null; // TODO: type this better?
  locations: OfficeRequestLocation[];
  entry?: OfficeRequestEntry;
  headCount?: number;
  daysPerWeek?: number;
  // TODO: remove [DEPRECATED]
  amenities: string[];
  otherAmenities?: string;
  maxMonthlyBudget?: number;
  tour?: OfficeRequestTour;
  user: OfficeRequestUser;
  completedSteps: number[];
  howHeard: string | null;
  searchTerms?: string;
  launchPath?: string;
  exitModalIsVisible: boolean;
  tourRequestWorkspaceId?: number;
};

export interface OfficeRequestUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  callingCode: string;
}

export interface OfficeRequestLocation {
  neighborhood?: string;
  city: string;
  region: string;
  country: string;
  place_id: string;
  loc?: LatLng;
}

type OfficeRequestEntry = 'home-page-search' | 'home-page-get-started' | 'location-page-primary-cta';

export type OfficeRequestTour = {
  startsAt: string; // ISO string
  endsAt: string; // ISO string
};

const initialState: OfficeRequestState = {
  market: null,
  locations: [],
  amenities: [],
  user: { callingCode: '1' },
  completedSteps: [],
  daysPerWeek: 5,
  headCount: 10,
  howHeard: null,
  exitModalIsVisible: false
};

const officeRequestSlice = createSlice({
  name: 'OfficeRequest',
  initialState,
  reducers: {
    setReferralInfo: (state, action: PayloadAction<ReferralInfo>) => {
      state.referralInfo = action.payload;
    },
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setMarket: (state, action: PayloadAction<string | null>) => {
      state.market = action.payload;
    },
    clearLocations: state => {
      state.locations = [];
    },
    addLocation: (state, action: PayloadAction<OfficeRequestLocation>) => {
      state.locations.unshift(action.payload);
    },
    removeLocation: (state, action: PayloadAction<OfficeRequestLocation>) => {
      state.locations = state.locations.filter(
        location =>
          location.place_id !== action.payload.place_id ||
          location.neighborhood !== action.payload.neighborhood ||
          location.country !== action.payload.country ||
          location.region !== action.payload.region ||
          location.city !== action.payload.city ||
          location.loc?.lat !== action.payload.loc?.lat ||
          location.loc?.lng !== action.payload.loc?.lng
      );
    },
    setEntry: (state, action: PayloadAction<OfficeRequestEntry>) => {
      state.entry = action.payload;
    },
    setHeadCount: (state, action: PayloadAction<number | undefined>) => {
      state.headCount = action.payload;
    },
    setDaysPerWeek: (state, action: PayloadAction<number>) => {
      state.daysPerWeek = action.payload;
    },
    addAmenity: (state, action: PayloadAction<string>) => {
      state.amenities = state.amenities.concat(action.payload);
    },
    removeAmenity: (state, action: PayloadAction<string>) => {
      state.amenities = state.amenities.filter(a => a !== action.payload);
    },
    setOtherAmenities: (state, action: PayloadAction<string | undefined>) => {
      state.otherAmenities = action.payload;
    },
    setMaxMonthlyBudget: (state, action: PayloadAction<number | undefined>) => {
      state.maxMonthlyBudget = action.payload;
    },
    setUser: (state, action: PayloadAction<Partial<OfficeRequestUser>>) => {
      state.user = { ...state.user, ...action.payload };
    },
    addStepComplete: (state, action: PayloadAction<number>) => {
      state.completedSteps.push(action.payload);
    },
    setHowHeard: (state, action: PayloadAction<string>) => {
      state.howHeard = action.payload;
    },
    setSearchTerms: (state, action: PayloadAction<string>) => {
      state.searchTerms = action.payload;
    },
    setLaunchPath: (state, action: PayloadAction<string>) => {
      state.launchPath = action.payload;
    },
    setExitModalIsVisible: (state, action: PayloadAction<boolean>) => {
      state.exitModalIsVisible = action.payload;
    },
    setTourRequestWorkspaceId: (state, action: PayloadAction<number | undefined>) => {
      state.tourRequestWorkspaceId = action.payload;
    },
    setTour: (state, action: PayloadAction<OfficeRequestTour>) => {
      state.tour = action.payload;
    },
    reset: () => initialState
  }
});

export const { actions } = officeRequestSlice;

export default officeRequestSlice.reducer;
