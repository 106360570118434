import React, { useContext, useState } from 'react';
import { AuthResponseData, isApiErrorResponse, sendCode, validate } from '@codiwork/codi';

import { media } from 'context';
import { Layout, Text } from 'ds';
import VerificationCode from 'ds/VerificationCode';

export interface Props {
  handleVerificationSuccess: (data: AuthResponseData) => void;
  email: string;
  updateEmail?: boolean;
  onError?: (error: Error) => void;
  disabled?: boolean;
}

const Verify: React.FC<Props> = ({ handleVerificationSuccess, email, updateEmail, onError, disabled }) => {
  const { xs } = useContext(media);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [sendingCode, setSendingCode] = useState<boolean>(false);
  const [code, setCode] = useState<string | undefined>();
  const [verificationFailed, setVerificationFailed] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const sendCodeToEmail = ({ email }: { email: string }) => {
    setSendingCode(true);
    sendCode({ email })
      .then(() => {
        setTimeout(() => {
          setSendingCode(false);
          setVerificationFailed(false);
          setCode(undefined);
        }, 2750);
      })
      .catch(error => {
        if (isApiErrorResponse(error)) {
          setError(error.message);
          setVerificationFailed(true);
          setSendingCode(false);
        }
      });
  };

  const handleSubmitVerificationCode = (code: string) => {
    setSubmitting(true);

    validate({ token: code, updateEmail, email })
      .then(({ data }) => {
        setVerificationFailed(false);
        setCode(undefined);
        handleVerificationSuccess(data);
        setSubmitting(false);
      })
      .catch(error => {
        // Displayed when logged in user is changing email on account page
        if (isApiErrorResponse(error) && error.type === 'HivenException::AccountAlreadyExists') {
          setError(`Account for ${email} already exists. Please try a different email address.`);
        } else if (isApiErrorResponse(error)) {
          setError(error.message);
        }
        setVerificationFailed(true);
        setSubmitting(false);
        setCode(undefined);
        onError && onError(error);
      });
  };

  return (
    <Layout direction="column" align={xs ? 'center' : undefined}>
      <Text size={xs ? 'h6' : 'h5'} align={xs ? 'center' : undefined}>
        Enter your security code.
      </Text>
      <Layout marginTop={24} />
      <Text size={xs ? 'body2' : 'body1'} align={xs ? 'center' : undefined}>
        We sent a security code to {xs && <br />}
        <Text size={xs ? 'body2' : 'body1'} semibold inline>
          {email}
        </Text>
        .
      </Text>
      <Layout marginTop={24} position="relative">
        <VerificationCode
          setCode={setCode}
          failed={verificationFailed}
          sendCode={sendCodeToEmail}
          email={email}
          handleSubmit={handleSubmitVerificationCode}
          code={code}
          sendingCode={sendingCode}
          submitting={submitting || disabled}
          error={error}
        />
      </Layout>
    </Layout>
  );
};

export default Verify;
