import React, { useContext, useEffect, useRef, useState } from 'react';
import { useWindowScroll } from 'beautiful-react-hooks';
import groupBy from 'lodash/groupBy';

import Head from 'components/Head';

import { app, media } from 'context';
import { Animate, Clickable, Img, Layout, Text } from 'ds';
import { EnumOption, Product } from 'requests/products/types';
import { actions } from 'store/UI';
import { selectPublicHeaderHasShadow } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CatalogCard from 'ux/Admin/Products/CatalogCard';

import HeaderText from './HeaderText';
import { CATEGORY_TYPE_ORDER } from './constants';

interface Props {
  categories: EnumOption[];
  products: Product[];
}

const Page: React.FC<Props> = ({ categories, products }) => {
  const { contentPaddingX, navBarHeight, windowWidth, contentWidth, width, maxWidth, setOverflowX } = useContext(app);
  const { xs, sm, md, xl, ...context } = useContext(media);
  const lg = !xl && context.lg;
  const leftContentWidth = xl ? 680 : lg ? 616 : 552;
  const height = xl ? 506 : 334;

  const [selectedCategory, setSelectedCategory] = useState<EnumOption>(categories[0]);
  const [categoryPillsRect, setCategoryPillsRect] = useState<DOMRect>();
  const categoryPillsRef = useRef<HTMLDivElement>(null);
  const stackedDescriptionRef = useRef<HTMLDivElement>(null);
  const headerHasShadow = useAppSelector(selectPublicHeaderHasShadow);

  const stackedLayout = !lg && !xl;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const categoryPillsContainer = categoryPillsRef.current;

    if (!categoryPillsContainer || !categoryPillsRect) return;

    let categoryPillsTop = categoryPillsRect.top;

    if (stackedLayout) {
      const scrollY = window.scrollY;
      const isPositionFixed = scrollY >= categoryPillsTop - navBarHeight;
      dispatch(actions.setPublicHeaderHasShadow(isPositionFixed ? false : true));
    } else {
      dispatch(actions.setPublicHeaderHasShadow(true));
    }

    return () => {
      dispatch(actions.setPublicHeaderHasShadow(true));
    };
  }, [stackedLayout, windowWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOverflowX && setOverflowX('visible');

    return () => {
      setOverflowX && setOverflowX('hidden');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = () => {
    const categoryPillsTop = categoryPillsRect?.top;

    if (!categoryPillsTop || !stackedLayout) return;

    const scrollY = window.scrollY;
    const shouldBeFixed = scrollY >= categoryPillsTop - navBarHeight;

    if (shouldBeFixed) {
      dispatch(actions.setPublicHeaderHasShadow(false));
    } else {
      dispatch(actions.setPublicHeaderHasShadow(true));
    }
  };

  const onWindowScroll = useWindowScroll();
  onWindowScroll(handleScroll);

  const filteredCategories = categories.filter(c => products.filter(i => i.category === c.value).length > 0);
  const groupedItems = groupBy(products, 'category');
  const displayedItems = groupedItems[selectedCategory.value];
  const itemsByType = groupBy(displayedItems, 'type');
  const typeOrder = CATEGORY_TYPE_ORDER.find(c => c.category === selectedCategory.value)?.typeOrder || [];
  const types = Object.keys(itemsByType).sort((t1, t2) => typeOrder.indexOf(t1) - typeOrder.indexOf(t2));
  const HeadTag = (
    <Head
      title="Codi Add-On Catalog"
      description="Every Codi comes with a standard set of workstations, conference tables, lounge seating, and more. We offer upgrades for select items so your team can add that extra personalization to their space."
    />
  );
  const stackedImageHeight = xs ? 188 : 402;

  if (stackedLayout) {
    return (
      <Layout>
        {HeadTag}
        <Animate delay={200}>
          <Img
            folder="public"
            srcKey="333_Kearny_St_suite_6-8_fdpyHB_6z.jpg"
            alt="workspace"
            width={1800}
            style={{
              objectFit: 'cover',
              position: 'relative',
              width: `calc(100% + ${contentPaddingX * 2}px)`,
              height: stackedImageHeight,
              left: -contentPaddingX
            }}
          />
        </Animate>
        <Animate marginTop={36} marginBottom={64} __ref={stackedDescriptionRef}>
          <HeaderText />
        </Animate>
        <Layout
          color="white"
          top={navBarHeight}
          position="sticky"
          zIndex={100}
          overflow="auto"
          width={windowWidth}
          paddingX={contentPaddingX}
          marginLeft={-contentPaddingX}
          justify={xs ? undefined : 'center'}
          __ref={categoryPillsRef}
          onMeasure={rect => {
            if (categoryPillsRect) return;

            setCategoryPillsRect(rect);
          }}
          {...(headerHasShadow ? {} : { boxShadow: 'rgba(87, 73, 109, 0.12) 2px 0 22px 0' })}
          flex
          flexShrink={0}
          paddingY={12}
          transition="box-shadow 100ms"
        >
          {filteredCategories.map((category, index) => {
            const isSelected = category.value === selectedCategory.value;
            const isFirst = index === 0;
            const isLast = index === filteredCategories.length - 1;

            return (
              <Layout
                marginLeft={isFirst ? undefined : 12}
                paddingRight={isLast ? contentPaddingX : undefined}
                key={category.value}
              >
                <Clickable
                  onClick={() => {
                    setSelectedCategory(category);
                    window.scrollTo({
                      top:
                        (stackedDescriptionRef?.current?.getBoundingClientRect().height || 0) + stackedImageHeight + 64,
                      behavior: 'smooth'
                    });
                  }}
                >
                  <Layout
                    {...(isSelected
                      ? { color: 'blue-500', borderWidth: 1, borderColor: 'transparent' }
                      : { borderWidth: 1, borderColor: 'gray-50' })}
                    borderRadius={100}
                    paddingX={sm ? 16 : 20}
                    paddingY={12}
                  >
                    <Text size={sm ? 'body3' : 'body2'} color={isSelected ? 'white' : 'gray-900'} wrap={false}>
                      {category.label}
                    </Text>
                  </Layout>
                </Clickable>
              </Layout>
            );
          })}
        </Layout>
        <Layout borderTop marginTop={16} paddingX={md ? 56 : undefined} borderColor="gray-100">
          <Layout paddingTop={24}>
            <Text size="h4">{selectedCategory.label}</Text>
          </Layout>
          <Layout>
            <Layout marginTop={80}>
              <Layout flex wrap>
                {types.map(type => {
                  return (
                    <>
                      {itemsByType[type].map(({ id, name, description, display_price, images }, index) => {
                        return (
                          <Animate
                            animation="fade-up"
                            threshold={0}
                            delay={index * 80}
                            duration={600}
                            width={xs ? '50%' : '33.33%'}
                            marginTop={24}
                            paddingRight={16}
                            key={id}
                          >
                            <CatalogCard
                              name={name}
                              description={description}
                              displayPrice={display_price}
                              imageKey={images[0]?.key}
                              {...(xs ? { imageDimensions: { width: 88, height: 84 } } : {})}
                            />
                          </Animate>
                        );
                      })}
                    </>
                  );
                })}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout>
      {HeadTag}
      <Layout height={height} justify="space-between" position="relative" marginTop={64} marginBottom={64}>
        <Layout height="100%" align="center" position="relative" left={lg ? -40 : undefined}>
          <Animate flexShrink={0} width={leftContentWidth}>
            <Layout>
              <HeaderText />
            </Layout>
          </Animate>
        </Layout>
        <Animate
          width={Math.min(860, contentWidth - (leftContentWidth + 64))}
          position="absolute"
          top={0}
          right={-contentPaddingX}
          zIndex={-1}
          delay={300}
        >
          <Img
            folder="public"
            srcKey="333_Kearny_St_suite_6-8_fdpyHB_6z.jpg"
            alt="workspace"
            width={1800}
            style={{
              objectFit: 'cover',
              height: height,
              borderTopLeftRadius: 24,
              borderBottomLeftRadius: 24,
              borderTopRightRadius: maxWidth && width > maxWidth ? 24 : 0,
              borderBottomRightRadius: maxWidth && width > maxWidth ? 24 : 0
            }}
          />
        </Animate>
      </Layout>
      <Layout paddingTop={80} align="flex-start" flex>
        <Layout width={200} flexShrink={0} position="sticky" top={navBarHeight + 48}>
          <Layout paddingX={16} paddingY={6}>
            <Text color="gray-300" size="body3" semibold>
              CATEGORIES
            </Text>
          </Layout>
          {filteredCategories.map(category => (
            <Clickable
              onClick={() => {
                setSelectedCategory(category);

                window.scrollTo({ top: height + navBarHeight + 64, behavior: 'smooth' });
              }}
              style={{ marginTop: 24, display: 'block', width: '100%' }}
              key={category.value}
            >
              <Layout
                color={category.value === selectedCategory.value ? 'gray-50' : undefined}
                key={category.label}
                paddingY={8}
                paddingX={16}
                borderRadius={8}
              >
                <Text size="body1" align="left">
                  {category.label}
                </Text>
              </Layout>
            </Clickable>
          ))}
        </Layout>
        <Layout borderTop marginLeft={80} flexGrow={1} borderColor="gray-100">
          <Layout paddingTop={24}>
            <Text size="h4">{selectedCategory.label}</Text>
          </Layout>
          <Layout>
            <Layout marginTop={80}>
              <Layout flex wrap>
                {types.map(type => {
                  return (
                    <>
                      {itemsByType[type].map(({ id, name, description, display_price, images }, index) => {
                        return (
                          <Animate
                            animation="fade-up"
                            threshold={0}
                            width="33.33%"
                            marginTop={24}
                            paddingRight={32}
                            key={id}
                            delay={index * 80}
                            duration={600}
                          >
                            <CatalogCard
                              name={name}
                              description={description}
                              displayPrice={display_price}
                              imageKey={images[0]?.key}
                            />
                          </Animate>
                        );
                      })}
                    </>
                  );
                })}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Page;
