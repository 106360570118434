import { useEffect } from 'react';

export default function useKeyDown(handleKeyPress: (event: KeyboardEvent) => void) {
  useEffect(() => {
    const downHandler = (ev: KeyboardEvent) => handleKeyPress(ev);

    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [handleKeyPress]);
}
