import React from 'react';
import { PartialPick } from '@codiwork/codi';

import { Clickable, Icon, Layout, Link, NavItem, Text } from 'ds';
import { Size } from 'ds/Text';

interface Props extends Omit<NavItem, 'iconName'>, PartialPick<NavItem, 'iconName'> {
  setMenuOpen: (open: boolean) => void;
  paddingY: number;
  textSize: Size;
}

const NavatarMenuItem: React.FC<Props> = ({
  onClick,
  label,
  setMenuOpen,
  href,
  target,
  paddingY,
  textSize,
  iconName
}) => {
  return onClick ? (
    <Clickable
      onClick={() => {
        onClick();
        setMenuOpen(false);
      }}
      style={{ width: '100%' }}
    >
      <Layout paddingY={paddingY} paddingX={12} align="center" hoverColor="blue-50">
        {iconName && (
          <Layout marginRight={12} display="inline-flex">
            <Icon name={iconName} size="xs" color="gray-900" />
          </Layout>
        )}
        <Text size={textSize} align="left">
          {label}
        </Text>
      </Layout>
    </Clickable>
  ) : href ? (
    <Link
      target={target}
      href={href}
      display="block"
      onClick={() => {
        setMenuOpen(false);
      }}
      color="gray-900"
      opacity={false}
    >
      <Layout paddingY={paddingY} paddingX={12} align="center" hoverColor="blue-50">
        {iconName && (
          <Layout marginRight={12} display="inline-flex">
            <Icon name={iconName} size="xs" color="gray-900" />
          </Layout>
        )}
        <Text size={textSize}>{label}</Text>
      </Layout>
    </Link>
  ) : null;
};

export default NavatarMenuItem;
