import React from 'react';

import { Layout, PurpleGradientCircle } from 'ds';

interface Props {}

const CodiAIAvatar: React.FC<Props> = () => {
  return (
    <Layout>
      <Layout color="white" borderRadius="circular">
        <PurpleGradientCircle dimension={36} />
      </Layout>
    </Layout>
  );
};

export default CodiAIAvatar;
