import React, { ComponentType, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import FilterControls from './FilterControls';
import FilterIcon from './FilterIcon';
import SortControls from './SortControls';
import { CELL_PADDING } from './constants';
import {
  ColumnWidthProps,
  Direction,
  FilterState,
  Filters,
  HeaderWidthProps,
  Row,
  SetFilter,
  SetSortState
} from './types';
import Layout from '../Layout';
import Text from '../Text';
import { useKeyPress } from '../helpers';

interface Props<T extends Row> extends HeaderWidthProps, ColumnWidthProps {
  columnKey: string;
  value: string;
  activeSortDirection?: Direction;
  sortable: boolean;
  setSortState: SetSortState;
  filter?: FilterState;
  setFilter: SetFilter;
  filters: Filters<T>;
  alignRight: boolean;
  CustomHeader?: ComponentType;
}

const Header = <T extends Row>({
  value,
  sortable,
  activeSortDirection,
  setSortState,
  filter,
  setFilter,
  filters,
  setColumnWidths,
  getColumnWidth,
  columnKey,
  shrinkWidth,
  flexGrow,
  alignRight,
  width,
  minWidth,
  maxWidth,
  CustomHeader
}: Props<T>) => {
  const [searchInputOpen, setSearchInputOpen] = useState<boolean>(false);
  const [filterInputOpen, setFilterInputOpen] = useState<boolean>(false);
  const [dateRangeOpen, setDateRangeOpen] = useState<boolean>(false);
  const [moneyRangeOpen, setMoneyRangeOpen] = useState<boolean>(false);
  const [numberRangeOpen, setNumberRangeOpen] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const measuredWidth = getColumnWidth(columnKey);
  const isMeasured = measuredWidth !== undefined;

  useKeyPress((e: KeyboardEvent) => {
    if (['Escape', 'Enter'].includes(e.key)) {
      searchInputOpen && setSearchInputOpen(false);
      filterInputOpen && setFilterInputOpen(false);
      dateRangeOpen && setDateRangeOpen(false);
      numberRangeOpen && setNumberRangeOpen(false);
      moneyRangeOpen && setMoneyRangeOpen(false);
    }
  });

  const hideFilterInputs = () => {
    setSearchInputOpen(false);
    setFilterInputOpen(false);
    setDateRangeOpen(false);
    setNumberRangeOpen(false);
    setMoneyRangeOpen(false);
  };

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    const clientWidth = headerRef.current.clientWidth;

    if (measuredWidth === clientWidth) {
      return;
    }

    setColumnWidths({ [columnKey]: clientWidth });
  }, [columnKey, setColumnWidths, isMeasured]); // eslint-disable-line react-hooks/exhaustive-deps

  const contentWidth = contentRef.current?.getBoundingClientRect().width;
  const isAsc = activeSortDirection === 'asc';

  return (
    <div
      className={classNames('Table-header')}
      ref={headerRef}
      style={{
        width: measuredWidth || width,
        minWidth,
        maxWidth,
        alignItems: 'center',
        position: 'relative',
        flexGrow: isMeasured || width ? undefined : shrinkWidth ? 0 : flexGrow || 1,
        flexShrink: isMeasured || width ? undefined : flexGrow !== undefined ? 0 : 1,
        paddingRight: CELL_PADDING,
        display: 'flex'
      }}
    >
      <div
        ref={contentRef}
        role="button"
        style={{
          cursor: sortable ? 'pointer' : undefined,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: CELL_PADDING,
          paddingTop: CELL_PADDING,
          paddingBottom: CELL_PADDING
        }}
        onClick={() => {
          if (!sortable) return;

          if (!activeSortDirection) {
            track('Element Interacted', { type: 'iconButton', name: 'table sort desc' });
            setSortState('desc');
          } else if (isAsc) {
            track('Element Interacted', { type: 'iconButton', name: 'table sort asc' });
            setSortState(null);
          } else {
            track('Element Interacted', { type: 'iconButton', name: 'table sort asc' });
            setSortState('asc');
          }
        }}
      >
        {CustomHeader ? (
          <CustomHeader />
        ) : (
          <Text size="body3" color="gray-300" semibold wrap={false} inline>
            {value}
          </Text>
        )}
        <Layout display="inline-flex">
          <SortControls sortable={sortable} activeSortDirection={activeSortDirection} />
        </Layout>
      </div>
      <Layout align="center">
        <FilterIcon
          hideFilterInputs={hideFilterInputs}
          // @ts-expect-error
          filters={filters}
          filter={filter}
          searchInputOpen={searchInputOpen}
          setSearchInputOpen={setSearchInputOpen}
          filterInputOpen={filterInputOpen}
          setFilterInputOpen={setFilterInputOpen}
          dateRangeOpen={dateRangeOpen}
          setDateRangeOpen={setDateRangeOpen}
          moneyRangeOpen={moneyRangeOpen}
          setMoneyRangeOpen={setMoneyRangeOpen}
          numberRangeOpen={numberRangeOpen}
          setNumberRangeOpen={setNumberRangeOpen}
        />
        {contentWidth ? (
          <FilterControls
            hideFilterInputs={hideFilterInputs}
            headerWidth={contentWidth}
            searchInputOpen={searchInputOpen}
            filterInputOpen={filterInputOpen}
            dateRangeOpen={dateRangeOpen}
            moneyRangeOpen={moneyRangeOpen}
            numberRangeOpen={numberRangeOpen}
            filters={filters}
            filter={filter}
            setFilter={setFilter}
            alignRight={alignRight}
          />
        ) : null}
      </Layout>
    </div>
  );
};

export default Header;
