import { ListChildComponentProps } from 'react-window';

import Header from './Header';
import { BORDER_WIDTH } from './constants';
import { Column, ColumnWidthProps, Row, SortFilterProps } from './types';
import Layout from '../Layout';

interface Props<T extends Row> extends SortFilterProps, Omit<ListChildComponentProps, 'data'>, ColumnWidthProps {
  columns: Column<T>[];
}

const HeaderRow = <T extends Row>({
  style,
  columns,
  sortState,
  setSortState,
  filterState,
  setFilterState,
  setColumnWidths,
  getColumnWidth
}: Props<T>) => {
  const top = 0;
  return (
    <Layout
      position="sticky"
      zIndex={2}
      align="center"
      color="white"
      __style={{ ...style, top }}
      borderBottom
      borderColor="gray-100"
    >
      {/** This element covers up tiny gap between header and table where a crack of text can appear. */}
      <Layout position="absolute" top={-BORDER_WIDTH} color="white" __style={{ height: BORDER_WIDTH }} width="100%" />
      {columns.map(
        (
          {
            header,
            key: columnKey,
            sortBy,
            filters,
            shrinkWidth,
            flexGrow,
            minWidth,
            maxWidth,
            width,
            Header: CustomHeader
          },
          columnIndex
        ) => {
          const key = columnKey as string;
          const sortStateIndex = sortState.findIndex(sort => sort.key === key);
          const sort = sortState[sortStateIndex];
          const filterStateIndex = filterState.findIndex(filter => filter.key === key);
          const filter = filterState[filterStateIndex];

          return (
            <Header
              key={key}
              columnKey={key}
              value={header}
              setSortState={direction => {
                const updatedSortState = direction === null ? [] : [{ key, direction }];

                setSortState(updatedSortState);
              }}
              setColumnWidths={setColumnWidths}
              getColumnWidth={getColumnWidth}
              flexGrow={flexGrow}
              CustomHeader={CustomHeader}
              minWidth={minWidth}
              maxWidth={maxWidth}
              width={width}
              alignRight={columns.length - columnIndex < 3}
              shrinkWidth={!!shrinkWidth}
              activeSortDirection={sort ? sort.direction : undefined}
              sortable={!!sortBy}
              filter={filter}
              filters={filters || {}}
              setFilter={({ substring, oneOf, startDate, endDate, minValue, maxValue }) => {
                const updatedFilterState = [...filterState];

                if (
                  substring === '' ||
                  (oneOf && oneOf.length === 0) ||
                  ((filter?.startDate || filter?.endDate) && !endDate && !startDate) ||
                  ((filter?.minValue || filter?.maxValue) &&
                    (!minValue || minValue === 0) &&
                    (!maxValue || maxValue === 0))
                ) {
                  updatedFilterState.splice(filterStateIndex, 1);
                } else if (filter) {
                  updatedFilterState[filterStateIndex] = {
                    ...filter,
                    substring,
                    oneOf,
                    startDate,
                    endDate,
                    minValue,
                    maxValue
                  };
                } else {
                  updatedFilterState.push({ key, substring, oneOf, startDate, endDate, minValue, maxValue });
                }

                setFilterState(updatedFilterState);
              }}
            />
          );
        }
      )}
    </Layout>
  );
};

export default HeaderRow;
