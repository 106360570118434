import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, FullWidthBackground, HEADER_MAX_WIDTH, Icon, Layout, Text } from 'ds';

interface Props {
  steps: { title: string; description: string }[];
  title: string;
}

const STEP_GUTTER_WIDTH = 80;

const HowItWorks: React.FC<Props> = ({ steps, title }) => {
  const { xs, sm } = useContext(media);

  const columnLayout = xs || sm;

  return (
    <FullWidthBackground paddingY={100} color="blue-50">
      <Layout {...(columnLayout ? { align: 'center', direction: 'column' } : { maxWidth: HEADER_MAX_WIDTH })}>
        <Animate>
          <Text size="h4" color="blue-700" align="center">
            {title}
          </Text>
        </Animate>
        <Layout
          paddingTop={28}
          {...(columnLayout ? { direction: 'column', align: 'center', maxWidth: 528 } : { direction: 'row' })}
        >
          {steps.map(({ title, description }, index) => (
            <React.Fragment key={title}>
              <Animate
                {...(columnLayout
                  ? { marginTop: index === 0 ? undefined : 56 }
                  : { delay: index * 100, width: `calc((100% - ${STEP_GUTTER_WIDTH * 2}px) / 3)` })}
              >
                <Text size="h2" color="blue-300" align={columnLayout ? 'center' : undefined}>
                  {index + 1}
                </Text>
                <Layout paddingTop={4}>
                  <Text size="h6" color="blue-700" align={columnLayout ? 'center' : undefined}>
                    {title}
                  </Text>
                </Layout>
                <Layout {...(columnLayout ? { justify: 'center' } : {})}>
                  <Layout marginY={24} width={50} height={4} color="blue-300"></Layout>
                </Layout>
                <Text size="body1" color="gray-500" align={columnLayout ? 'center' : undefined}>
                  {description}
                </Text>
              </Animate>
              {!columnLayout && index < 2 && (
                <Animate width={STEP_GUTTER_WIDTH} delay={index * 100 + 50}>
                  <Layout visibility="hidden">
                    <Text size="h2" color="blue-300">
                      {index + 1}
                    </Text>
                  </Layout>
                  <Layout
                    marginTop={2}
                    padding={8}
                    display="inline-flex"
                    align="center"
                    justify="center"
                    color="blue-700"
                    borderRadius="circular"
                  >
                    <Icon size="xs" name="rightArrow" color="white" />
                  </Layout>
                </Animate>
              )}
            </React.Fragment>
          ))}
        </Layout>
      </Layout>
    </FullWidthBackground>
  );
};

export default HowItWorks;
