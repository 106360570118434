import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ListingWorkspace, getListing } from '@codiwork/codi';
import qs from 'qs';

import { Layout, Link, Spinner, Text } from 'ds';
import { LISTING_PATH, SEARCH_PATH } from 'routes';

import ListingUI from './ListingUI';
import { slugsToRedirect } from './redirects';

interface MatchProps {
  slug: string;
}

interface Props extends RouteComponentProps<MatchProps> {}

const Listing: React.FC<Props> = ({ history, location: { search }, match }) => {
  const [workspaceNotFound, setWorkspaceNotFound] = useState<boolean>(false);
  const [workspace, setWorkspace] = useState<ListingWorkspace>();
  const { workspace_id: qsWorkspaceId } = qs.parse(search, { ignoreQueryPrefix: true });
  let slug = typeof qsWorkspaceId === 'string' ? parseInt(qsWorkspaceId) : match.params.slug;

  useEffect(() => {
    const redirectSlug = slugsToRedirect.find(slug => slug.from === match.params.slug);

    if (redirectSlug) {
      history.replace({ pathname: `${LISTING_PATH}/${redirectSlug}` });
      return;
    }

    if (workspace && (slug === workspace.id || slug === workspace.slug)) {
      return;
    } else if (workspace) {
      setWorkspace(undefined);
    }

    getListing({
      slug
    })
      .then(({ data: workspace }) => {
        setWorkspace(workspace);

        const { workspace_id } = qs.parse(search, { ignoreQueryPrefix: true });
        const searchParams = new URLSearchParams(search);

        if (workspace_id) {
          searchParams.delete('workspace_id');
        }

        history.replace({
          pathname: `${LISTING_PATH}/${workspace.slug}`,
          search: searchParams.toString()
        });
      })
      .catch(() => setWorkspaceNotFound(true));
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  if (workspaceNotFound) {
    return (
      <Layout direction="column" width="100%" justify="center" align="center" marginTop={48}>
        <Text size="body1" bold align="center" scale>
          Workspace not found or no longer available
        </Text>
        <Link href={SEARCH_PATH}>Go back</Link>
      </Layout>
    );
  } else if (!workspace) {
    return (
      <Layout width="100vw" height="100vh" justify="center" align="center">
        <Spinner size="lg" />
      </Layout>
    );
  }

  return <ListingUI workspace={workspace} />;
};

export default Listing;
