import React from 'react';
import classNames from 'classnames';

import Layout from 'ds/Layout';
import { SpinnerColor } from 'ds/types';

import { ReactComponent as SpinnerSvg } from './Spinner.svg';

export interface Props {
  color?: SpinnerColor;
  size?: Size;
  center?: boolean;
}

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

const Spinner: React.FC<Props> = ({ color = 'gray-600', size = 'md', center }) => {
  const classes = classNames('Spinner', `is-color-${color}`, `is-size-${size}`);
  const spinner = (
    <Layout __className={classes}>
      <SpinnerSvg />
    </Layout>
  );

  if (center) {
    return (
      <Layout width="100%" position="absolute" top="50%" justify="center">
        {spinner}
      </Layout>
    );
  } else {
    return spinner;
  }
};

export default Spinner;
