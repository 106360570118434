import React, { useContext } from 'react';

import HeroVideo from 'components/HeroVideo';

import { app, media } from 'context';
import { Button, Layout, Link, Text } from 'ds';
import { formatUsd } from 'helpers';
import { OFFICE_REQUEST_PATH } from 'routes';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

interface Props {}

const PERCENTAGE_DISCOUNT = 25;
const MAX_AMOUNT = 2500;

const Hero: React.FC<Props> = () => {
  const { xs, sm } = useContext(media);
  const { contentPaddingX, windowWidth, navBarHeight } = useContext(app);
  const dispatch = useAppDispatch();

  return xs || sm ? (
    <Layout height={`calc(100vh - ${navBarHeight}px)`}>
      <HeroVideo
        srcKey="home-page-video_tvfOtuHwV.mp4"
        height="100vh"
        videoHeight={2000}
        folder="public"
        position="absolute"
        right={0}
        top={-navBarHeight}
        zIndex={0}
      >
        <div
          style={{
            background: '#262626',
            opacity: 0.6,
            width: '100%',
            height: '100%',
            position: 'absolute',
            mixBlendMode: 'multiply'
          }}
        />
        <div
          style={{
            background: 'radial-gradient(circle at 52% 88%, rgba(0, 67, 206, 0) 16%, rgba(0, 67, 206, 0.6) 89%)',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      </HeroVideo>
      <Layout paddingX={contentPaddingX} width={windowWidth} zIndex={1} position="absolute" marginTop={134} left={0}>
        <Layout height="100%" direction="column" justify="center">
          <Text size={xs ? 'h5' : 'h4'} color="white">
            Hey WeWork members,
            <br />
            <br />
            Codi gifted you {PERCENTAGE_DISCOUNT}% off your first month.
          </Text>
          <Layout height={xs ? 36 : 24} />
          <Text size={xs ? 'body2' : 'body1'} color="white">
            Codi spaces are private, furnished, and fully serviced. If you're currently in a WeWork, apply to get up to{' '}
            {formatUsd(MAX_AMOUNT, false)} off your first month's rent with Codi.
          </Text>
          <Layout height={xs ? 36 : 40} />
          <Layout>
            <Button
              text="Apply"
              size="md"
              type="primary"
              onClick={() =>
                dispatch(
                  actions.setReferralInfo({
                    id: -1,
                    code: 'wework-discount',
                    max_amount: MAX_AMOUNT,
                    referrer: { name: 'we-work-discount', id: -1, logo_url: null },
                    percentage_discount: PERCENTAGE_DISCOUNT
                  })
                )
              }
              href={OFFICE_REQUEST_PATH}
              icon="rightArrow"
              iconPosition="right"
              fullWidth
            />
          </Layout>
          <Layout height={xs ? 36 : 40} />
          <Text size="body3" color="white">
            Available only to the first 50 WeWork members moving into a Codi space. Restrictions apply. See{' '}
            <Link href="/terms#referrals" target="_blank">
              <Text size="body3" color="white" inline semibold>
                Referral Program terms
              </Text>
            </Link>{' '}
            for details.
          </Text>
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Layout height={700}>
      <HeroVideo
        srcKey="home-page-video_tvfOtuHwV.mp4"
        height={700 + navBarHeight}
        folder="public"
        position="absolute"
        left={0}
        top={-navBarHeight}
        zIndex={0}
      >
        <div
          style={{
            background: '#262626',
            opacity: 0.6,
            width: '100%',
            height: '100%',
            position: 'absolute',
            mixBlendMode: 'multiply'
          }}
        />
        <div
          style={{
            background: 'radial-gradient(circle at 120% 0%, rgba(0, 67, 206, 0) 36%, rgba(0, 67, 206, 0.60) 85%)',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      </HeroVideo>
      <Layout zIndex={1} position="absolute" justify="center" marginTop={134}>
        <Layout height="100%" maxWidth={700} direction="column" justify="center">
          <Text size={xs ? 'h5' : 'h4'} color="white">
            Hey WeWork members,
            <br />
            <br />
            Codi gifted you {PERCENTAGE_DISCOUNT}% off your first month.
          </Text>
          <Layout height={xs ? 36 : 24} />
          <Text size={xs ? 'body2' : 'body1'} color="white">
            Codi spaces are private, furnished, and fully serviced. If you're currently in a WeWork, apply to get up to{' '}
            {formatUsd(MAX_AMOUNT, false)} off your first month's rent with Codi.
          </Text>
          <Layout height={xs ? 36 : 40} />
          <Layout>
            <Button
              text="Apply"
              size="md"
              type="primary"
              onClick={() =>
                dispatch(
                  actions.setReferralInfo({
                    id: -1,
                    code: 'wework-discount',
                    max_amount: MAX_AMOUNT,
                    referrer: { name: 'we-work-discount', id: -1, logo_url: null },
                    percentage_discount: PERCENTAGE_DISCOUNT
                  })
                )
              }
              href={OFFICE_REQUEST_PATH}
              icon="rightArrow"
              iconPosition="right"
              fullWidth
            />
          </Layout>
          <Layout height={xs ? 36 : 40} />
          <Text size="body3" color="white">
            Available only to the first 50 WeWork members moving into a Codi space. Restrictions apply. See{' '}
            <Link href="/terms#referrals" target="_blank">
              <Text size="body3" color="white" inline semibold>
                Referral Program terms
              </Text>
            </Link>{' '}
            for details.
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Hero;
