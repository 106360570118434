import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconName } from '@codiwork/codi';

import { app, media } from 'context';
import { Animate, Grid, Layout, TextArea } from 'ds';
import { actions } from 'store/OfficeRequest';
import {
  selectOfficeRequestAmenities,
  selectOfficeRequestMarket,
  selectOtherAmenities
} from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import AdditionalAmenity from './AdditionalAmenity';
import StandardAmenity from './StandardAmenity';
import StepContainer from './StepContainer';
import StepDescription from './StepDescription';
import StepTitle from './StepTitle';
import { MOBILE_PROGRESS_FOOTER_HEIGHT } from './constants';
import { generateStepPath } from './utils';

interface Props {}

const STANDARD_AMENITIES: Amenity[] = [
  { iconName: 'wifi', label: 'Fast WiFi' },
  { iconName: 'desk', label: 'Furniture' },
  { iconName: 'beverage', label: 'Beverages' },
  { iconName: 'cleaning', label: 'Cleaning' }
];

const ADDITIONAL_AMENITIES: Amenity[] = [
  { iconName: 'meetingRoom', label: 'Conference rooms', align: 'flex-start' },
  { iconName: 'monitor', label: 'Monitors' },
  { iconName: 'standing_desk', label: 'Standing desks' },
  { iconName: 'tv', label: 'TVs' }
];

interface Amenity {
  iconName: IconName;
  label: string;
  labelBottom?: string;
  align?: 'flex-start';
}

const Step4: React.FC<Props> = () => {
  const amenities = useAppSelector(selectOfficeRequestAmenities);
  const otherAmenities = useAppSelector(selectOtherAmenities);
  const [otherAmenitiesSelected, setOtherAmenitiesSelected] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { xs, sm, md } = useContext(media);
  const { contentPaddingX } = useContext(app);
  const isMobile = xs || sm;
  const handleOtherClick = () => setOtherAmenitiesSelected(!otherAmenitiesSelected);
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const allAmenities = amenities.concat(otherAmenities?.length ? otherAmenities : []);
  const market = useAppSelector(selectOfficeRequestMarket);

  const isNewYorkMarket = !!market && market.startsWith('New York');

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0 });
    };
  }, []);

  return isMobile ? (
    <StepContainer
      step={4}
      nextHref={generateStepPath({ step: 5 })}
      backHref={generateStepPath({ step: isNewYorkMarket ? 2 : 3 })}
      stepName="amenities"
      stepCompletedEventValue={allAmenities}
      textLayoutProps={{ paddingBottom: 24, align: 'center', flexGrow: 1 }}
      mobileLayout="long"
      skippable
    >
      <Layout>
        <StepTitle>Do you need any additional amenities?</StepTitle>
        <Layout marginTop={24}>
          <StepDescription>
            Every Codi office comes fully serviced with our standard amenities. We can also customize our offering to
            fit your needs.
          </StepDescription>
        </Layout>
      </Layout>
      <Animate
        color="white"
        borderTopLeftRadius={16}
        borderTopRightRadius={16}
        paddingX={contentPaddingX}
        paddingTop={contentPaddingX}
        paddingBottom={MOBILE_PROGRESS_FOOTER_HEIGHT + 64}
      >
        <Grid itemsPerRow={1} gapY={12}>
          {STANDARD_AMENITIES.map(({ iconName, label }) => {
            return <StandardAmenity iconName={iconName} label={label} key={label} />;
          })}
          {ADDITIONAL_AMENITIES.map(({ iconName, label, labelBottom }) => {
            const value = label + (labelBottom ? ` ${labelBottom}` : '');
            const isSelected = amenities.includes(value);

            return (
              <AdditionalAmenity
                iconName={iconName}
                isSelected={isSelected}
                key={label}
                label={label}
                onClick={() => {
                  if (isSelected) {
                    dispatch(actions.removeAmenity(value));
                  } else {
                    dispatch(actions.addAmenity(value));
                  }
                }}
              />
            );
          })}
          <AdditionalAmenity
            iconName="ellipsisH"
            isSelected={otherAmenitiesSelected}
            label="Other"
            onClick={() => {
              handleOtherClick();
              window.scrollTo(0, document.body.scrollHeight + 108);
            }}
          />
        </Grid>
        {otherAmenitiesSelected && (
          <Layout marginTop={12}>
            <TextArea
              label="Other amenities"
              value={otherAmenities}
              onChange={({ value }) => dispatch(actions.setOtherAmenities(value))}
              placeholder="What else do you need?"
              size="lg"
              minHeight={80}
              autoFocus
            />
          </Layout>
        )}
      </Animate>
    </StepContainer>
  ) : (
    <StepContainer
      step={4}
      nextHref={generateStepPath({ step: 5 })}
      backHref={generateStepPath({ step: 3 })}
      stepName="amenities"
      stepCompletedEventValue={allAmenities}
      skippable
    >
      <Layout>
        <StepTitle>Do you need any additional amenities?</StepTitle>
        <Layout marginTop={24}>
          <StepDescription>
            Every Codi office comes fully serviced with our standard amenities. We can also customize our offering to
            fit your needs.
          </StepDescription>
        </Layout>
      </Layout>
      <Layout maxWidth={536} flexGrow={1}>
        <Grid itemsPerRow={3} gapX={12} gapY={12}>
          {STANDARD_AMENITIES.map(({ iconName, label }, index) => {
            return (
              <Animate delay={80 + index * 10} key={label} __style={{ flex: `0 1 ${md ? 10 : 20}%` }}>
                <StandardAmenity iconName={iconName} label={label} />
              </Animate>
            );
          })}
          {ADDITIONAL_AMENITIES.map(({ iconName, label, align }, index) => {
            const isSelected = amenities.includes(label);

            return (
              <Animate
                delay={80 + STANDARD_AMENITIES.length * 10 + index * 10}
                key={label}
                __style={{ flex: `0 1 ${md ? 10 : 20}%` }}
              >
                <AdditionalAmenity
                  iconName={iconName}
                  isSelected={isSelected}
                  align={align}
                  label={label}
                  onClick={() => {
                    if (isSelected) {
                      dispatch(actions.removeAmenity(label));
                    } else {
                      dispatch(actions.addAmenity(label));
                    }
                  }}
                />
              </Animate>
            );
          })}
          <Animate delay={80 + STANDARD_AMENITIES.length * 10 + ADDITIONAL_AMENITIES.length * 10}>
            <AdditionalAmenity
              iconName="ellipsisH"
              isSelected={otherAmenitiesSelected}
              label="Other"
              onClick={() => {
                handleOtherClick();

                setTimeout(() => {
                  const container = contentContainerRef.current;

                  if (!container) return;

                  container.scrollTo({ top: container.getBoundingClientRect().height });
                }, 100);
              }}
            />
          </Animate>
        </Grid>
        {otherAmenitiesSelected && (
          <Layout marginTop={12}>
            <TextArea
              label="Other amenities"
              value={otherAmenities}
              onChange={({ value }) => dispatch(actions.setOtherAmenities(value))}
              placeholder="What else do you need?"
              size="lg"
              minHeight={64}
              autoFocus
            />
          </Layout>
        )}
      </Layout>
    </StepContainer>
  );
};

export default Step4;
