import React from 'react';

import PlaybookStepPage from './PlaybookStepPage';

interface Props {}

const HandyTools: React.FC<Props> = () => {
  return <PlaybookStepPage activeStep={5} title="Closing Thoughts & Handy Tools" />;
};

export default HandyTools;
