import React, { useContext } from 'react';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const AllInTheDetails: React.FC<Props> = () => {
  const { xs, xl } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout flex direction={xs ? 'column' : 'row'}>
        <Layout maxWidth={xs ? contentWidth : xl ? 470 : 392}>
          <Text size="h3" color="blue-600">
            It’s ALL in the Details
          </Text>
          <Layout marginTop={48}>
            <Text size="h6" color="gray-700" bold={false}>
              Keep in mind, your office will be your home base, and the more comfortable you make the experience, the
              happier your team will be. That said, make sure you’re considering the little things that provide comfort
              and ease — just like you would at home.
            </Text>
          </Layout>
        </Layout>
        <Layout
          {...(xs
            ? { marginTop: 36 }
            : xl
            ? { marginLeft: 80 }
            : { position: 'relative', left: 24, marginLeft: 64, overflow: 'hidden', paddingRight: 24, paddingY: 24 })}
          equalWidth
        >
          <Layout flex direction={xs ? 'column' : 'row'}>
            <Layout
              paddingTop={36}
              paddingX={36}
              paddingBottom={40}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              display="inline-flex"
              direction="column"
              align="center"
              color="white"
              equalWidth
            >
              <Icon name="hamburger" color="blue-gradient" size={48} />
              <Layout height={120} marginTop={24} align="center">
                <Text size="body1" color="blue-600" align="center" semibold>
                  Cord covers, cord maintenance, and other ways to curb trip hazards
                </Text>
              </Layout>
            </Layout>
            <Layout
              paddingTop={36}
              paddingX={36}
              paddingBottom={40}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              display="inline-flex"
              direction="column"
              align="center"
              color="white"
              equalWidth
              marginLeft={xs ? undefined : 36}
              marginTop={xs ? 36 : undefined}
            >
              <Icon name="cleaning" color="blue-gradient" size={48} />
              <Layout height={120} marginTop={24} align="center">
                <Text size="body1" color="blue-600" align="center" semibold>
                  Female bathroom needs, hand sanitizers, lotions, and tissues
                </Text>
              </Layout>
            </Layout>
            <Layout
              paddingTop={36}
              paddingX={36}
              paddingBottom={40}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              display="inline-flex"
              direction="column"
              align="center"
              color="white"
              equalWidth
              marginLeft={xs ? undefined : 36}
              marginTop={xs ? 36 : undefined}
            >
              <Icon name="trashcan" color="blue-gradient" size={48} />
              <Layout height={120} marginTop={24} align="center">
                <Text size="body1" color="blue-600" align="center" semibold>
                  Garbage cans and recycling
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout marginTop={32} flex direction={xs ? 'column' : 'row'}>
            <Layout
              paddingTop={36}
              paddingX={36}
              paddingBottom={40}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              display="inline-flex"
              direction="column"
              align="center"
              color="white"
              equalWidth
            >
              <Icon name="parking" color="blue-gradient" size={48} />
              <Layout height={120} marginTop={24} align="center">
                <Text size="body1" color="blue-600" align="center" semibold>
                  Bicycle, scooter, or e-bike parking
                </Text>
              </Layout>
            </Layout>
            <Layout
              paddingTop={36}
              paddingX={36}
              paddingBottom={40}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              display="inline-flex"
              direction="column"
              align="center"
              color="white"
              equalWidth
              marginLeft={xs ? undefined : 36}
              marginTop={xs ? 36 : undefined}
            >
              <Icon name="meetingRoom" color="blue-gradient" size={48} />
              <Layout height={120} marginTop={24} align="center">
                <Text size="body1" color="blue-600" align="center" semibold>
                  Places for coats and personal items
                </Text>
              </Layout>
            </Layout>
            <Layout
              paddingTop={36}
              paddingX={36}
              paddingBottom={40}
              borderRadius={12}
              boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
              display="inline-flex"
              direction="column"
              align="center"
              color="white"
              equalWidth
              marginLeft={xs ? undefined : 36}
              marginTop={xs ? 36 : undefined}
            >
              <Icon name="outdoorsFlower" color="blue-gradient" size={48} />
              <Layout height={120} marginTop={24} align="center">
                <Text size="body1" color="blue-600" align="center" semibold>
                  Artwork and decorations
                </Text>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default AllInTheDetails;
