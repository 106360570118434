import React, { useContext } from 'react';

import Head from 'components/Head';
import TopCities from 'components/TopCities';

import { app } from 'context';
import { Layout, Text } from 'ds';
import { CMSRouteProps } from 'routes/types';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';
import CaseStudy from 'ux/Public/CaseStudy';
import Hero from 'ux/Public/Hero';
import Quotes from 'ux/Public/Quotes';

import CompareSection from './CompareSection';
import ClientCTA from '../CmsPage/ClientCTA';

interface Props extends CMSRouteProps {}

const Compare: React.FC<Props> = ({ cmsSlug }) => {
  const { sectionGutterY } = useContext(app);
  const page = useAppSelector(state => selectCmsPage(state, cmsSlug));

  if (!page) return null;

  const {
    seo: { title, description, keywords },
    sections
  } = page.fields;

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      {sections.map((section: any, index: number) => {
        return <Section key={index} section={section} marginTop={sectionGutterY} />;
      })}
    </>
  );
};

interface SectionProps {
  section: any;
  marginTop: number;
}

const Section: React.FC<SectionProps> = ({ section: { type, fields }, marginTop }) => {
  switch (type) {
    case 'hero':
      return (
        <Hero
          {...fields}
          title={
            <Text size="h3" scale>
              <span dangerouslySetInnerHTML={{ __html: fields.title }} />
            </Text>
          }
        />
      );
    case 'compare':
      return (
        <Layout marginTop={marginTop}>
          <CompareSection {...fields} />
        </Layout>
      );
    case 'top_cities':
      return (
        <Layout align="center" marginTop={marginTop}>
          <TopCities />
        </Layout>
      );
    case 'case_study_section':
      return <CaseStudy />;
    case 'quotes_section':
      return <Quotes fields={fields} />;
    case 'client_cta_section':
      return <ClientCTA {...fields} />;

    default:
      return null;
  }
};

export default Compare;
