import React, { useState } from 'react';
import { NotificationCategory, NotificationPreferences, NotificationType } from '@codiwork/codi';

import { Layout, Toggle } from 'ds';

import { SectionValues, UpdatePreference } from './types';

interface Props {
  notificationPreferences: Pick<NotificationPreferences, 'reminders' | 'support' | 'community'>;
  label: string;
  notificationCategory: NotificationCategory;
  notificationType: NotificationType;
  sectionValues: SectionValues;
  updatePreference: UpdatePreference;
}

const PreferenceToggle: React.FC<Props> = ({
  label,
  notificationCategory,
  notificationType,
  sectionValues,
  ...props
}) => {
  const [disabled, setDisabled] = useState<boolean>(notificationCategory === 'support' && notificationType === 'email');
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const updatePreference = (value: boolean) => {
    setDisabled(true);
    setShowSpinner(true);

    props.updatePreference({ notificationType, value, sectionValues, notificationCategory }).finally(() => {
      setDisabled(false);
      setShowSpinner(false);
    });
  };

  return (
    <Layout marginTop={16}>
      <Toggle
        size="md"
        label={label}
        checked={sectionValues[notificationType]}
        disabled={disabled}
        showSpinner={showSpinner}
        onChange={updatePreference}
        border={false}
      />
    </Layout>
  );
};

export default PreferenceToggle;
