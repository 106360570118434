import React, { useEffect, useState } from 'react';

import { getProductEnums, getProducts } from 'requests/products';
import { EnumOption, Product } from 'requests/products/types';

import Page from './Page';
import { CATEGORY_TYPE_ORDER } from './constants';

interface Props {}

const Catalog: React.FC<Props> = () => {
  const [categories, setCategories] = useState<EnumOption[]>();
  const [products, setProducts] = useState<Product[]>();

  const categoryOrder = CATEGORY_TYPE_ORDER.map(obj => obj.category);

  useEffect(() => {
    Promise.all([getProducts(6), getProductEnums()]).then(([{ data: items }, { data: enums }]) => {
      setCategories(
        [...enums.categories].sort((c1, c2) => categoryOrder.indexOf(c1.value) - categoryOrder.indexOf(c2.value))
      );
      setProducts(items);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!products || !categories) return null;

  return <Page products={products} categories={categories} />;
};

export default Catalog;
