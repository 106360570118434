import React, { useContext, useRef, useState } from 'react';

import { createTransformations, imageKitUrl } from 'helpers/images';

import { app } from 'context';
import Clickable from 'ds/Clickable';
import Layout from 'ds/Layout';

import { HEADER_HEIGHT, TOUR_VIDEO_HEIGHT, TOUR_VIDEO_WIDTH } from './constants';

import { ReactComponent as Play } from '../Play.svg';

interface Props {
  tourVideoKey: string;
}

const VideoTour: React.FC<Props> = ({ tourVideoKey }) => {
  const [ordinal, setOrdinal] = useState<number>(1);
  const { windowHeight, windowWidth } = useContext(app);
  const tourVideoRef = useRef<HTMLVideoElement>(null);
  const playTourVideo = () => {
    tourVideoRef.current?.play();
  };
  const isVideoPlaying = !!tourVideoRef.current && !tourVideoRef.current.paused;

  return (
    <Layout
      position="relative"
      marginTop={HEADER_HEIGHT}
      width="100%"
      minHeight={windowHeight - HEADER_HEIGHT}
      height={windowHeight - HEADER_HEIGHT}
      justify="center"
      align="center"
    >
      <Layout width="100%" position="relative">
        <Clickable
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            display: isVideoPlaying ? 'none' : undefined
          }}
          onClick={() => playTourVideo()}
          children={<Play />}
        />
        <Layout position="relative" justify="center" align="center">
          <video
            ref={tourVideoRef}
            muted
            loop
            controls={isVideoPlaying}
            controlsList="nodownload"
            style={{
              width: windowWidth,
              height: windowWidth / (TOUR_VIDEO_WIDTH / TOUR_VIDEO_HEIGHT),
              position: 'relative'
            }}
            onPlay={() => setOrdinal(ordinal + 1)}
          >
            <source
              src={`${imageKitUrl({
                key: tourVideoKey,
                transformationChain: createTransformations({
                  width: TOUR_VIDEO_WIDTH,
                  height: TOUR_VIDEO_HEIGHT
                })
              })}#t=0.1`}
              type="video/mp4"
            />
          </video>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default VideoTour;
