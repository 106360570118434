import React, { PropsWithChildren } from 'react';

import Layout from 'ds/Layout';

import { APP_PAGE_NAV_BAR_HEIGHT, APP_PAGE_PADDING_X } from './constants';

interface Props {
  hasPaddingX?: boolean;
}

const AppPageTitleContainer: React.FC<PropsWithChildren<Props>> = ({ hasPaddingX, children }) => {
  return (
    <Layout
      height={APP_PAGE_NAV_BAR_HEIGHT}
      paddingTop={24}
      paddingBottom={12}
      paddingX={hasPaddingX === false ? undefined : APP_PAGE_PADDING_X}
      flexGrow={1}
    >
      <Layout height={52} align="center" flexGrow={1} justify="space-between">
        {children}
      </Layout>
    </Layout>
  );
};

export default AppPageTitleContainer;
