import React, { useContext, useRef, useState } from 'react';
import SwiperClass, { A11y, EffectFade, Virtual } from 'swiper';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';
import { NO_SWIPING_CLASS } from 'ds/SwiperCarousel';
import { Swiper, SwiperSlide as Slide } from 'swiper/react';

interface Props {}

const TESTIMONIALS = [
  {
    quote:
      "“Partnering with Codi has been an incredible experience thus far. Whether I've connected them with landlords or brought tenants to their spaces, their understanding of speed-to-occupancy, transparency, and genuine care for clients has made it easy to continue partnering with them.”",
    name: 'Danny',
    title: 'Danny Trainor, Director at Newmark',
    subtitle: 'CodiConnect Partner since 2023',
    avatarSrcKey: 'danny-trainor_33AQXC6iX.png'
  }
];

const BrokerTestimonials: React.FC<Props> = () => {
  const { isMobile, xs, sm, md, lg } = useContext(media);
  const { contentPaddingX, contentWidth, windowWidth } = useContext(app);
  const isMouse = md || lg;
  const textSwiperRef = useRef<SwiperClass>();
  const imgSwiperRef = useRef<SwiperClass>();
  const [ordinal, setOrdinal] = useState<number>(0);
  const realIndex = textSwiperRef.current?.realIndex || 0;

  // const moveSlides = (direction: 'left' | 'right') => {
  //   if (direction === 'right') {
  //     textSwiperRef.current?.slideNext();
  //     if (!isMobile) {
  //       imgSwiperRef.current?.slideNext();
  //     }
  //   } else {
  //     textSwiperRef.current?.slidePrev();

  //     if (!isMobile) {
  //       imgSwiperRef.current?.slidePrev();
  //     }
  //   }
  // };

  const testimonialAuthor = TESTIMONIALS[realIndex];

  return (
    <Layout
      __className={isMobile ? undefined : 'Home-testimonialsCarousel'}
      {...(isMobile ? { width: sm ? contentWidth : windowWidth } : { flex: true })}
    >
      {!isMobile && (
        <Swiper
          slidesPerView={1}
          spaceBetween={24}
          onInit={swiper => {
            imgSwiperRef.current = swiper;
          }}
          onActiveIndexChange={() => {
            setOrdinal(ordinal + 1);
          }}
          speed={200}
          style={{ borderRadius: 16 }}
          className="Home-imgCarousel"
          modules={[A11y, Virtual, EffectFade]}
          allowTouchMove={isMobile}
          noSwiping={isMouse}
          noSwipingClass={NO_SWIPING_CLASS}
          loop
        >
          <Slide>
            <Img
              srcKey="home-page-testimonial-1_QlrAWI1Jq.jpeg"
              alt="office"
              folder="public"
              width={800}
              style={{ borderRadius: 16, width: md ? '100%' : 364, height: 456, objectFit: 'cover' }}
            />
          </Slide>
          <Slide>
            <Img
              srcKey="home-page-testimonial-2_LG8aH55HH.jpeg"
              alt="office"
              folder="public"
              width={800}
              style={{ borderRadius: 16, width: md ? '100%' : 364, height: 456, objectFit: 'cover' }}
            />
          </Slide>
          <Slide>
            <Img
              srcKey="home-page-testimonial-3_kcXWtRZM5.jpeg"
              alt="office"
              folder="public"
              width={800}
              style={{ borderRadius: 16, width: md ? '100%' : 364, height: 456, objectFit: 'cover' }}
            />
          </Slide>
        </Swiper>
      )}
      <Layout
        {...(isMobile ? {} : { maxWidth: md ? 480 : 676, marginLeft: 80, height: 456 })}
        direction="column"
        justify="space-between"
      >
        <Layout {...(xs ? { paddingX: contentPaddingX } : {})}>
          <Text size={isMobile ? 'overline-sm' : 'overline-lg'} color="blue-500">
            WHAT OUR PARTNERS SAY
          </Text>
        </Layout>
        <Layout {...(isMobile ? { marginY: 64 } : {})}>
          <Swiper
            slidesPerView={1}
            spaceBetween={isMobile ? 24 : 0}
            speed={240}
            onInit={swiper => {
              textSwiperRef.current = swiper;
            }}
            onActiveIndexChange={() => {
              setOrdinal(ordinal + 1);
            }}
            style={xs ? { paddingLeft: contentPaddingX, paddingRight: contentPaddingX } : undefined}
            modules={[A11y, Virtual]}
            noSwiping={isMouse}
            allowTouchMove={isMobile}
            noSwipingClass={NO_SWIPING_CLASS}
            className={isMouse ? 'Home-textCarousel' : undefined}
            loop
          >
            {TESTIMONIALS.map(testimonial => (
              <Slide key={testimonial.title}>
                <Text size={lg ? 'headline-lg' : 'headline-md'} color="blue-500">
                  {testimonial.quote}
                </Text>
              </Slide>
            ))}
          </Swiper>
        </Layout>
        <Layout {...(isMobile ? { paddingX: xs ? contentPaddingX : undefined } : { justify: 'space-between' })}>
          <Layout align="center">
            <Img
              srcKey={testimonialAuthor.avatarSrcKey}
              alt={testimonialAuthor.title}
              width={128}
              style={{
                width: 48,
                height: 48,
                objectFit: 'cover',
                borderRadius: '50%'
              }}
              folder="public"
            />
            <Layout marginLeft={24}>
              <Text size="body-md" color="gray-300" bold>
                {testimonialAuthor.title}
              </Text>
              <Layout marginTop={4}>
                <Text size="body-sm" color="gray-300">
                  {testimonialAuthor.subtitle}
                </Text>
              </Layout>
            </Layout>
          </Layout>
          {/* will comment back in when there are more than 1 slide */}
          {/* <Layout {...(isMobile ? { marginTop: 64 } : {})} align="center">
            <IconButton size="lg" type="blueLight" onClick={() => moveSlides('left')} name="leftChevron" stroke={2} />
            <Layout marginLeft={12}>
              <IconButton
                size="lg"
                type="blueLight"
                onClick={() => moveSlides('right')}
                name="rightChevron"
                stroke={2}
              />
            </Layout>
          </Layout> */}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BrokerTestimonials;
