import React, { useContext } from 'react';
import { ListingWorkspace } from '@codiwork/codi';

import { media } from 'context';
import { Layout, Text } from 'ds';
import WorkspaceCarousel from 'ds/WorkspaceCarousel/WorkspacePromoCarousel';

import NearbyWorkspace from './NearbyWorkspace';

interface Props {
  workspaces: ListingWorkspace[];
}

const NearbyWorkspaces: React.FC<Props> = ({ workspaces }) => {
  const { md, lg } = useContext(media);

  return (
    <Layout>
      <Text size="h5" color="gray-900" scale>
        Nearby office spaces
      </Text>
      {md || lg ? (
        <Layout marginTop={32} flex position="relative" left={-12} width="calc(100% + 24px)">
          {workspaces.map(workspace => {
            const { name, slug, id } = workspace;

            if (name && slug) {
              return (
                <Layout width="33.33%" paddingX={12} key={id}>
                  <NearbyWorkspace workspace={workspace} />
                </Layout>
              );
            }

            return null;
          })}
        </Layout>
      ) : (
        <Layout marginTop={32}>
          <WorkspaceCarousel
            paginationEnabled={false}
            workspaces={workspaces
              .filter(({ name, images }) => !!name && images.length > 0)
              .map(w => ({
                ...w,
                name: w.name || '',
                slug: w.slug || '',
                image: w.images[0]
              }))}
          />
        </Layout>
      )}
    </Layout>
  );
};
export default NearbyWorkspaces;
