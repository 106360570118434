import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const FlexibleSpaceModel: React.FC<Props> = () => {
  const { windowHeight } = useContext(app);
  const { xs, md } = useContext(media);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={md ? 96 : 48}
        >
          <Text size="h3" color="blue-600">
            Flexible Space Model Using Codi
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                Codi, for example, offers a marketplace of available spaces, but streamlines the process even further.
                Before a property is made available in the marketplace, Codi has already pre-negotiated terms with the
                landlords; making the process easier and faster.
                <br />
                <br />
                The final agreement will be signed with Codi — not the landlord directly — so that the terms can be kept
                simple and easy, will require no involvement from the Legal team (unless desired), and can be completed
                in a matter of days.
                <br />
                <br />
                Also, once there’s a signed contract, there’s no need to find furniture rental, cleaning services,
                snacks vendors, or internet providers — Codi handles all of those for the length of your term.
              </Text>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="codi-hq_o6kYTW0SQ.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default FlexibleSpaceModel;
