export const CATEGORY_TYPE_ORDER = [
  {
    category: 'workstation',
    typeOrder: ['Monitors', 'Desk Chairs', 'Desks', 'Keyboards & Mice', 'Laptop Stands', 'Accessories']
  },
  { category: 'conference', typeOrder: ['Tables', 'Conference Chairs', 'TVs', 'Whiteboard'] },
  { category: 'lounge', typeOrder: ['Lounge Chairs', 'Coffee Tables', 'Side Tables'] },
  {
    category: 'kitchen_and_dining',
    typeOrder: [
      'Coffee & Espresso Makers',
      'Electric Kettles',
      'Mini Fridge',
      'Microwave',
      'Buffet Cabinet',
      'Appliances'
    ]
  },
  { category: 'decor', typeOrder: ['Plants', 'Lighting', 'Art'] },
  { category: 'services', typeOrder: ['Wifi', 'Cleaning'] }
];
