import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { kebabCase } from 'lodash';

import { app } from 'context';
import { Layout, Link, Text } from 'ds';

import { CaseStudyPageType } from './types';

interface Props {
  name: string;
  pageType: CaseStudyPageType;
  scrollYOffset?: number;
}

const CaseStudyNavigationButton: React.FC<Props> = ({ name, pageType, scrollYOffset }) => {
  const { navBarHeight } = useContext(app);
  const location = useLocation();
  const { pathname } = location;
  const locationHash = location.hash.replace('#', '');
  const hash = kebabCase(name);
  const newPathname = pathname + `#${hash}`;
  const isActive = locationHash === hash;

  switch (pageType) {
    case 'phone':
      return (
        <Link href={newPathname} color="gray-900" scrollYOffset={scrollYOffset} fullWidth>
          <Layout
            paddingX={16}
            paddingY={12}
            borderRadius={100}
            borderWidth={1}
            color={isActive ? 'gray-50' : undefined}
          >
            <Text size="body3" wrap={false}>
              {name}
            </Text>
          </Layout>
        </Link>
      );
    case 'top-nav':
      return (
        <Link href={newPathname} color="gray-900" scrollYOffset={-128 - navBarHeight}>
          <Layout
            paddingX={20}
            paddingY={12}
            borderRadius={100}
            borderWidth={1}
            color={isActive ? 'gray-50' : undefined}
          >
            <Text size="body2">{name}</Text>
          </Layout>
        </Link>
      );
    case 'side-nav':
      return (
        <Link href={newPathname} color="gray-900" scrollYOffset={-24 - navBarHeight} fullWidth>
          <Layout paddingX={16} paddingY={8} borderRadius={8} color={isActive ? 'gray-50' : undefined}>
            <Text size="body1">{name}</Text>
          </Layout>
        </Link>
      );
  }
};

export default CaseStudyNavigationButton;
