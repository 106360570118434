import React, { useContext } from 'react';
import { IconName } from '@codiwork/codi';

import { media } from 'context';
import { Icon, Layout, Text } from 'ds';

interface Props {
  iconName: IconName;
  label: string;
}

const StandardAmenity: React.FC<Props> = ({ label, iconName }) => {
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;

  if (isMobile) {
    return (
      <Layout borderRadius={8} color="gray-25" padding={24} align="center" direction="column" height={72}>
        <Layout align="center" justify="space-between" width="100%">
          <Layout align="center">
            <Layout justify="center" display="inline-flex">
              <Icon name={iconName} size="lg" color="gray-900" />
            </Layout>
            <Layout marginLeft={24}>
              <Text size="body-sm">{label}</Text>
            </Layout>
          </Layout>
          <Layout color="blue-100" paddingY={4} paddingX={6} align="center" borderRadius={4} display="inline-flex">
            <Layout marginRight={4}>
              <Icon name="lock" size="xxs" color="blue-500" />
            </Layout>
            <Text color="blue-500" size="tag" semibold>
              INCLUDED
            </Text>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout borderRadius={8} color="gray-25" padding={24} align="center" direction="column" height={136}>
      <Layout>
        <Layout justify="center">
          <Icon name={iconName} size="lg" color="gray-900" />
        </Layout>
        <Layout marginTop={8}>
          <Text size="body-md" align="center">
            {label}
          </Text>
        </Layout>
      </Layout>
      <Layout color="blue-100" paddingY={4} paddingX={6} align="center" borderRadius={4} marginTop={8}>
        <Layout marginRight={4}>
          <Icon name="lock" size="xxs" color="blue-500" />
        </Layout>
        <Text color="blue-500" size="tag" semibold>
          INCLUDED
        </Text>
      </Layout>
    </Layout>
  );
};

export default StandardAmenity;
