import React from 'react';

import ButtonPrivate, { Props as ButtonPrivateProps } from 'ds/ButtonPrivate';

import Icon from './Icon';

export interface Props extends Pick<ButtonPrivateProps, 'onClick' | 'showSpinner' | 'disabled'> {
  type: 'primary' | 'primarySmall' | 'secondary';
}

const BUTTON_TYPE_CONFIG = {
  primary: {
    color: 'white',
    hoverColor: 'blue-50',
    activeColor: 'white',
    textColor: 'blue-500',
    spinnerColor: 'blue-500',
    disabledColor: 'blue-300',
    hasShadow: true,
    size: 'xxs',
    borderRadius: 100,
    text: 'Join session'
  },
  primarySmall: {
    color: 'white',
    hoverColor: 'blue-50',
    activeColor: 'white',
    textColor: 'blue-500',
    spinnerColor: 'blue-500',
    disabledColor: 'gray-50',
    hasShadow: true,
    size: 'joinCircle',
    borderRadius: 16,
    text: <Icon name="plus" stroke={2} size="sm" color="blue-500" />
  },
  secondary: {
    color: 'gray-100',
    hoverColor: 'gray-300',
    disabledColor: 'gray-50',
    activeColor: 'gray-300',
    textColor: 'black',
    spinnerColor: 'gray-600',
    size: 'md',
    borderRadius: 12,
    text: 'Join session'
  }
} as const;

const JoinButton: React.FC<Props> = ({ type, ...props }) => {
  return <ButtonPrivate {...props} {...BUTTON_TYPE_CONFIG[type]} />;
};

export default JoinButton;
