import React, { createContext } from 'react';
import { VariableSizeList as List, VariableSizeList, VariableSizeListProps } from 'react-window';

import RowWrapper from './RowWrapper';
import {
  Column,
  DimensionProps,
  ListItemRendererProps,
  Row,
  SortFilterProps,
  TableContext as TableContextType,
  TableProps
} from './types';

interface Props<T extends Row>
  extends Omit<VariableSizeListProps, 'children'>,
    TableProps<T>,
    SortFilterProps,
    DimensionProps {
  headerIndices: number[];
  width: number;
  minWidth?: number;
  children: React.ComponentType<ListItemRendererProps<T>>;
  listRef: React.MutableRefObject<VariableSizeList | null>;
  rowOnClick?: (row: Row) => void;
  rowHref?: (row: Row) => string;
}

export const TableContext = createContext<TableContextType<any>>({ headerIndices: [], columns: [], width: 0 });
TableContext.displayName = 'TableContext';

const TableProvider = <T extends Row>({
  children,
  headerIndices,
  columns,
  rows,
  sortState,
  setSortState,
  filterState,
  setFilterState,
  listRef,
  width,
  setRowHeights,
  setRowHeightsById,
  getRowHeightById,
  rowHasBeenMeasured,
  setColumnWidths,
  getColumnWidth,
  dynamicRowHeight,
  minWidth,
  rowHeight,
  rowOnClick,
  rowHref,
  ...rest
}: Props<T>) => {
  return (
    <TableContext.Provider
      value={{
        ItemRenderer: children,
        headerIndices,
        sortState,
        setSortState,
        filterState,
        setFilterState,
        columns: columns as Column<Row>[],
        setColumnWidths,
        getColumnWidth,
        width,
        minWidth
      }}
    >
      <List
        itemData={{
          ItemRenderer: children,
          headerIndices,
          rows,
          columns,
          width,
          minWidth,
          setRowHeights,
          setRowHeightsById,
          getRowHeightById,
          rowHasBeenMeasured,
          getColumnWidth,
          setColumnWidths,
          dynamicRowHeight,
          rowHeight,
          rowOnClick,
          rowHref
        }}
        ref={listRef}
        width={width}
        {...rest}
      >
        {RowWrapper}
      </List>
    </TableContext.Provider>
  );
};

export default TableProvider;
