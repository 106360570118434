import React, { useContext } from 'react';

import { media } from 'context';
import { Img, Layout, Text } from 'ds';

interface Props {
  name: string;
  srcKey: string;
}

const CustomerSuccess: React.FC<Props> = ({ name, srcKey }) => {
  const { xs } = useContext(media);

  return (
    <>
      <Text size="h5" scale>
        Meet {name}
      </Text>
      <Layout marginTop={24} />
      <Layout direction="row" justify="flex-start" align="flex-start">
        <Img
          srcKey={srcKey}
          style={{ width: 72, height: 72, borderRadius: '50%' }}
          alt={name}
          width={200}
          folder="ux"
        />
        <Layout marginLeft={xs ? 20 : 32} />
        <Text size="body1" color="gray-900" scale>
          Our Customer Success team is committed to help every step of the way. Get hands-on professional support no
          matter what your needs.
        </Text>
      </Layout>
    </>
  );
};
export default CustomerSuccess;
