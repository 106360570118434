import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import SwiperClass, { A11y, EffectFade, Virtual } from 'swiper';

import { app, media } from 'context';
import { Clickable, Grid, Layout, Text } from 'ds';
import { NO_SWIPING_CLASS } from 'ds/SwiperCarousel';
import useIntersectionObserver from 'ds/helpers/useInteractionObserver';
import { Swiper, SwiperSlide as Slide } from 'swiper/react';

import HowItWorksText from './HowItWorksText';

import { ReactComponent as CloseIllustration } from './broker-close-illustration.svg';
import { ReactComponent as CollaborateIllustration } from './broker-collaborate-illustration.svg';
import { ReactComponent as CommissionsIllustration } from './broker-commissions-illustration.svg';
import { ReactComponent as ConnectIllustration } from './broker-connect-illustration.svg';
import { ReactComponent as SupportIllustration } from './broker-support-illustration.svg';

interface Props {}

const HowItWorksCarousel: React.FC<Props> = () => {
  const { isMobile, xs, sm, md, lg } = useContext(media);
  const { contentPaddingX, contentWidth, windowWidth } = useContext(app);
  const isMouse = md || lg;
  const textSwiperRef = useRef<SwiperClass>();
  const imgSwiperRef = useRef<SwiperClass>();
  const [ordinal, setOrdinal] = useState<number>(0);
  const activeIndex = textSwiperRef.current?.activeIndex || 0;
  const [hasManuallySelected, setHasManuallySelected] = useState<boolean>(false);
  const [selectedIndices, setSelectedIndices] = useState<boolean[]>(new Array(4).fill(false));
  const [hasScrolledTo, setHasScrolledTo] = useState<boolean>(false);
  const intervalIdRef = useRef<number>();
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, { threshold: 1 });
  const isVisible = entry?.isIntersecting;

  const tabs = ['1. Connect', '2. Collaborate', '3. Close', '4. Commissions', '5. Continuous support'];

  useEffect(() => {
    if (!isVisible || typeof intervalIdRef.current === 'number') return;

    setHasScrolledTo(true);

    const id = window.setInterval(() => {
      if (!textSwiperRef.current || !imgSwiperRef.current) return;

      const activeIndex = textSwiperRef.current?.activeIndex || 0;

      const nextIndex = activeIndex === 4 ? 0 : activeIndex + 1;

      try {
        textSwiperRef.current.slideTo(nextIndex);
        imgSwiperRef.current.slideTo(nextIndex);
        setOrdinal(ordinal + nextIndex);
      } catch (e) {
        // sometimes this errors when changing screen sizes
        console.error(e);
      }
    }, 5000);

    intervalIdRef.current = id;
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  useEffect(() => {
    if (!hasManuallySelected) return;

    clearInterval(intervalIdRef.current);
  }, [hasManuallySelected]);

  return (
    <Layout __className="Home-valueProps">
      <Layout width={md ? contentWidth : undefined} flex>
        <Layout {...(isMobile ? { width: windowWidth } : { maxWidth: lg ? 500 : 400 })}>
          <Layout {...(xs ? { paddingX: contentPaddingX } : {})} marginBottom={isMobile ? 24 : 12}>
            <Text size={xs ? 'headline-lg' : md || sm ? 'headline-xl' : 'display-sm'} color="blue-600">
              How CodiConnect works
            </Text>
          </Layout>
          <Swiper
            slidesPerView={1}
            spaceBetween={24}
            onActiveIndexChange={() => {
              setOrdinal(ordinal + 1);
            }}
            onInit={swiper => {
              textSwiperRef.current = swiper;
            }}
            {...(xs
              ? { style: { paddingLeft: contentPaddingX, paddingRight: contentPaddingX } }
              : sm
              ? { style: { width: contentWidth } }
              : {})}
            speed={230}
            className={xs ? undefined : 'Home-textCarousel'}
            modules={[A11y, Virtual]}
            noSwiping={isMouse}
            allowTouchMove={isMobile}
            noSwipingClass={NO_SWIPING_CLASS}
          >
            <Slide>
              <HowItWorksText step="1. Connect" text="You make the introduction. We take it from there." />
              {isMobile && <ConnectIllustration width={contentWidth} height={224} />}
            </Slide>
            <Slide>
              <HowItWorksText
                step="2. Collaborate"
                text="We handle space sourcing, tours, design, and setup. You can be as involved as much or as little as you would like to be, getting updates throughout the process either way."
              />
              {isMobile && <CollaborateIllustration width={contentWidth} height={224} />}
            </Slide>
            <Slide>
              <HowItWorksText
                step="3. Close"
                text="Unlock operational agility with flexible leases starting from only 6 months."
              />
              {isMobile && <CloseIllustration width={contentWidth} height={224} />}
            </Slide>
            <Slide>
              <HowItWorksText
                step="4. Commissions"
                text="You earn the 5% tenant rep commission from the landlord and up to a $6,000 referral bonus. We pay your referral bonus within 10 days of the deal closing."
              />
              {isMobile && <CommissionsIllustration width={contentWidth} height={224} />}
            </Slide>
            <Slide>
              <HowItWorksText
                step="5. Continuous support"
                text="We offer expansion solutions for your clients ready to scale and a smooth referral process back to you should their needs change."
              />
              {isMobile && <SupportIllustration width={contentWidth} height={224} />}
            </Slide>
          </Swiper>
        </Layout>
        {!isMobile && <Layout color="white" flexGrow={1} height="100%" />}
        {!isMobile && (
          <Layout {...(lg ? { maxWidth: 520 } : { maxWidth: contentWidth - 400 - 80 })}>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              onInit={swiper => {
                imgSwiperRef.current = swiper;
              }}
              className="Home-imgCarousel"
              style={{ borderRadius: 16 }}
              speed={200}
              modules={[A11y, Virtual, EffectFade]}
              noSwiping={isMouse}
              allowTouchMove={isMobile}
              noSwipingClass={NO_SWIPING_CLASS}
            >
              <Slide>
                <ConnectIllustration width={520} height={344} />
              </Slide>
              <Slide>
                <CollaborateIllustration width={520} height={344} />
              </Slide>
              <Slide>
                <CloseIllustration width={520} height={344} />
              </Slide>
              <Slide>
                <CommissionsIllustration width={520} height={344} />
              </Slide>
              <Slide>
                <SupportIllustration width={520} height={344} />
              </Slide>
            </Swiper>
          </Layout>
        )}
      </Layout>
      {isMobile ? (
        <Layout marginTop={36} paddingX={xs ? contentPaddingX : undefined}>
          <Grid itemsPerRow={5} gapX={12}>
            {tabs.map((tab, index) => {
              const isActive = index === activeIndex;
              return (
                <Clickable
                  onClick={() => {
                    if (!textSwiperRef.current) return;

                    setOrdinal(ordinal + 1);

                    textSwiperRef.current.slideTo(index);
                  }}
                  fullWidth
                  key={index}
                >
                  <Layout height={6} color={isActive ? 'blue-500' : 'blue-200'} borderRadius={2} />
                </Clickable>
              );
            })}
          </Grid>
        </Layout>
      ) : (
        <Layout marginTop={64} position="relative">
          <Grid itemsPerRow={5} gapX={12} alignItems="flex-start">
            {tabs.map((tab, index) => {
              const isActive = index === activeIndex;
              const hasBeenSelected = selectedIndices[index];
              return (
                <Clickable
                  onClick={() => {
                    if (!textSwiperRef.current || !imgSwiperRef.current) return;

                    setHasManuallySelected(true);

                    setOrdinal(ordinal + 1);
                    const updatedSelectedIndices = [...selectedIndices];
                    updatedSelectedIndices[index] = true;
                    setSelectedIndices(updatedSelectedIndices);

                    textSwiperRef.current.slideTo(index);
                    imgSwiperRef.current.slideTo(index);
                  }}
                  fullWidth
                  key={index}
                >
                  <Layout position="relative">
                    <Layout height={8} borderRadius={2} color="blue-200" marginBottom={8} />
                    <Layout
                      height={8}
                      borderRadius={2}
                      color="blue-500"
                      position="absolute"
                      width="100%"
                      top={0}
                      left={0}
                      __className={classNames('Home-valueProps-tab', {
                        'is-active': isActive && (hasScrolledTo || hasManuallySelected),
                        'has-been-selected': hasBeenSelected,
                        'is-manual': hasManuallySelected,
                        'can-animate': !hasManuallySelected
                      })}
                    />
                    <Layout __ref={ref} position="absolute" opacity={0} bottom={0} width={1} height={1} />
                  </Layout>
                  <Text size="body-sm" color="blue-500" align="left" semibold>
                    {tab}
                  </Text>
                </Clickable>
              );
            })}
          </Grid>
        </Layout>
      )}
    </Layout>
  );
};

export default HowItWorksCarousel;
