import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Cell from './Cell';
import { CELL_PADDING, HEADER_HEIGHT } from './constants';
import { ColumnWidths, ListItemRendererProps, Row as RowType } from './types';
import { defaultAccessor } from './utils';
import Layout from '../Layout';

interface Props<T extends RowType> extends ListItemRendererProps<T> {
  rowOnClick?: (row: T) => void;
  rowHref?: (row: T) => string;
}

const Row = <T extends RowType>({
  style,
  data: { columns, row },
  index,
  getColumnWidth,
  setRowHeights,
  setRowHeightsById,
  getRowHeightById,
  rowHasBeenMeasured,
  minWidth,
  dynamicRowHeight,
  rowOnClick,
  rowHref,
  rowHeight
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const columnWidths = columns.reduce<ColumnWidths>((widths, column) => {
    widths[column.key as string] = getColumnWidth(column.key as string);

    return widths;
  }, {});

  const allColumnWidthsMeasured = Object.values(columnWidths).every(Boolean);

  useEffect(() => {
    if (!setRowHeights || !ref.current) {
      return;
    }

    const measuredHeight = ref.current.clientHeight;
    const height = Math.max(measuredHeight, rowHeight);

    setRowHeights({ [index]: height });
    setRowHeightsById({ [row.id]: height });
  }, [index, setRowHeights, allColumnWidthsMeasured, row.id, setRowHeightsById, rowHeight]);

  const top = (style.top as number) + HEADER_HEIGHT - rowHeight;

  if (!allColumnWidthsMeasured) {
    return null;
  }

  const storedRowHeight = dynamicRowHeight ? getRowHeightById(row.id) : undefined;
  const height = dynamicRowHeight ? (rowHasBeenMeasured(row.id) ? '100%' : undefined) : '100%';

  return (
    <div
      className={classNames('Table-row', { 'is-clickable': rowOnClick || rowHref })}
      {...(rowHref
        ? {
            onClick: e => {
              if (e.metaKey) {
                window.open(rowHref(row), '_blank');
              } else {
                history.push(rowHref(row));
              }
            }
          }
        : rowOnClick
        ? { onClick: () => rowOnClick(row), role: 'button' }
        : {})}
      style={{ ...style, minWidth, top, height: storedRowHeight || style.height }}
    >
      <Layout __ref={ref} align="center" height={height}>
        {columns.map(({ key, accessor, Cell: CellComponent }) => {
          const width = columnWidths[key as string];

          return CellComponent ? (
            <Layout __style={{ width: width, height: '100%' }} key={key as string} align="center">
              <CellComponent {...row} width={width} />
            </Layout>
          ) : (
            <Layout padding={CELL_PADDING} key={key as string} __style={{ width: width }}>
              <Cell value={accessor ? accessor(row) : defaultAccessor(row, key)} />
            </Layout>
          );
        })}
      </Layout>
    </div>
  );
};

export default Row;
