import React, { useContext } from 'react';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const OrganizingNewOffice: React.FC<Props> = () => {
  const { xs, xl } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout flex direction={xs ? 'column' : 'row'}>
        <Layout
          direction="column"
          {...(xl ? { justify: 'center' } : xs ? {} : { paddingTop: 192 })}
          maxWidth={xs ? contentWidth : 420}
        >
          <Text size="h3" color="blue-600">
            Organizing Your New Office
          </Text>
          <Layout marginTop={24}>
            <Text size="h6" color="gray-700" bold={false}>
              With flex offices, you’re generally not going to go through a big build-out like you might with a
              traditional office, but you do have a fair amount of customization that can be done. You’ll want to do
              some thorough planning of the space and determine the following:
            </Text>
          </Layout>
        </Layout>
        <Layout marginLeft={xs ? undefined : 56} paddingTop={48}>
          <Layout paddingBottom={32} align="flex-start" flex direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="trashcan" color="blue-500" size="md" />
              <Layout marginLeft={16} width={220}>
                <Text size="body2" color="blue-500" semibold>
                  Conference Rooms
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="h6" color="gray-700" bold={false}>
                Which spaces are going to be set aside for meetings? Which ones might be offices for individuals?
              </Text>
            </Layout>
          </Layout>
          <Layout paddingY={32} align="flex-start" borderTop flex direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="coffee" color="blue-500" size="md" />
              <Layout marginLeft={16} width={220}>
                <Text size="body2" color="blue-500" semibold>
                  Common Spaces
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="h6" color="gray-700" bold={false}>
                Are you going to set aside a cafe area or a lounge for your team to eat or just congregate together
                (think: culture-building)
              </Text>
            </Layout>
          </Layout>
          <Layout paddingY={32} align="flex-start" borderTop flex direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="tableLarge" color="blue-500" size="md" />
              <Layout marginLeft={16} width={220}>
                <Text size="body2" color="blue-500" semibold>
                  Layout
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="h6" color="gray-700" bold={false}>
                How can you make sure there are adequate work stations? What will the overall floor plan and flow look
                like?
              </Text>
            </Layout>
          </Layout>
          <Layout paddingY={32} align="flex-start" borderTop flex direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="whiteboard" color="blue-500" size="md" />
              <Layout marginLeft={16} width={220}>
                <Text size="body2" color="blue-500" semibold>
                  Collaboration
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="h6" color="gray-700" bold={false}>
                If you’re trying to foster more collaboration, you’ll want desk pods without design walls. You’ll want
                those lounge spaces and focus rooms, white boards, etc.
              </Text>
            </Layout>
          </Layout>
          <Layout paddingY={32} align="flex-start" borderTop flex direction={xs ? 'column' : 'row'}>
            <Layout align="center">
              <Icon name="standing_desk" color="blue-500" size="md" />
              <Layout marginLeft={16} width={220}>
                <Text size="body2" color="blue-500" semibold>
                  Focus Work Areas
                </Text>
              </Layout>
            </Layout>
            <Layout marginLeft={16}>
              <Text size="h6" color="gray-700" bold={false}>
                For more focused siloed work, you may want more AV options in meeting rooms, division walls, cubicles,
                or dividers between desks/pods. Perhaps fewer lounges.
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default OrganizingNewOffice;
