import React, { CSSProperties, PropsWithChildren, useContext } from 'react';

import { imageKitUrl } from 'helpers/images';

import { app } from 'context';

import { transformSrcToButterUrl } from '../ds/ButterImg';

interface Props {
  img?: string;
  imageKey?: string;
  imageKitFolder?: string;
  overlay?: boolean;
  filter?: string;
  parallax?: boolean;
  height?: number | string;
  style?: CSSProperties;
  backgroundPosition?: string;
  imgWidth?: number;
  imgHeight?: number;
  accountForPaddingX?: boolean;
  paddingX?: number;
}

const HeroImage: React.FC<PropsWithChildren<Props>> = ({
  overlay,
  filter = 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)',
  img,
  imageKey,
  imageKitFolder,
  height,
  parallax,
  children,
  style,
  backgroundPosition = 'center',
  accountForPaddingX = true,
  imgWidth = 1920,
  imgHeight = 1280,
  ...props
}) => {
  const { width, maxContentWidth, contentPaddingX } = useContext(app);
  const paddingX = typeof props.paddingX === 'number' ? props.paddingX : contentPaddingX;

  if (!img && !imageKey) return null;

  const difference = maxContentWidth && width > maxContentWidth + paddingX * 2 ? width - maxContentWidth : undefined;
  const right = accountForPaddingX ? (difference && difference > 0 ? difference / 2 : paddingX) : undefined;

  const imageUrl = !!imageKey
    ? imageKitUrl({
        key: imageKey,
        transformationChain: [`w-${imgWidth},h-${imgHeight}`],
        folder: imageKitFolder
      })
    : img
    ? transformSrcToButterUrl({ src: img, width: 1920 })
    : undefined;

  return (
    <div
      style={{
        backgroundImage: overlay ? `linear-gradient(${filter}), url(${imageUrl})` : `url(${imageUrl})`,
        width,
        right,
        height,
        backgroundAttachment: parallax ? 'fixed' : undefined,
        backgroundPosition,
        backgroundRepeat: 'norepeat',
        backgroundSize: 'cover',
        position: 'relative',
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default HeroImage;
