import React, { useState } from 'react';
import { formatDate, getUser } from '@codiwork/codi';
import { DateTime } from 'luxon';

import { Layout, PillButton, Select, Text } from 'ds';
import DatePickerMultiple from 'ds/DatePicker/DatePickerMultiple';
import Tag from 'ds/Tag';
import { formatUsd } from 'helpers';
import { actions } from 'store/Chatbot';
import { actions as userActions } from 'store/User';
import { selectLoggedInUserId, selectOffices, selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resolveIntent } from 'ux/Chatbot/requests';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

interface Props extends ChatbotIntentProps {
  goBack: () => void;
}

const OrderOneOffCleaning: React.FC<Props> = ({ chatbotInteraction, goBack, threadId }) => {
  const user = useAppSelector(selectUser);
  const userId = useAppSelector(selectLoggedInUserId);
  const offices = useAppSelector(selectOffices);
  const dispatch = useAppDispatch();

  const {
    slots,
    slots: { cleaningDates },
    id: chatbotInteractionId
  } = chatbotInteraction;

  const [dates, setDates] = useState<Date[]>(
    !cleaningDates
      ? []
      : ['string', 'object'].includes(typeof cleaningDates)
      ? [DateTime.fromISO(cleaningDates).toJSDate()]
      : cleaningDates
  );

  const [workspaceId, setWorkspaceId] = useState<number>(user?.customer_admin_plans?.active_workspace_ids[0]!);

  const selectedOffice = offices.find(office => office.id === workspaceId);

  // TODO: what should we do about this?
  if (!user || !selectedOffice || !user.customer_admin_plans) {
    return (
      <Layout>
        <Text size="body-sm">Order Cleaning Widget</Text>
        <Text size="body-sm">No active plan found</Text>
      </Layout>
    );
  }

  const { start_date: reservationStartDate, end_date: reservationEndDate } = selectedOffice;
  const plan = user.customer_admin_plans.long_term_plans.find(plan => plan.workspace_id === workspaceId);

  if (!plan) {
    return (
      <Layout>
        <Text size="body-sm">Order Cleaning Widget</Text>
        <Text size="body-sm">No plan found for workspace</Text>
      </Layout>
    );
  }

  const activeWorkspaceIds = user.customer_admin_plans.active_workspace_ids;
  const hasMultipleWorkspaces = activeWorkspaceIds.length > 1;
  const workspaceOptions = offices
    .filter(office => activeWorkspaceIds.includes(office.id))
    .map(office => ({ label: office.address.line1, value: office.id }));
  const { id: planId, deep_cleaning_price, recent_and_upcoming_cleanings } = plan;

  if (!deep_cleaning_price) {
    return (
      <Layout>
        <Text size="body-sm">Order Cleaning Widget</Text>
        <Text size="body-sm">No deep cleaning price found</Text>
      </Layout>
    );
  }

  const now = DateTime.now();
  const reservationStart = reservationStartDate ? DateTime.fromISO(reservationStartDate) : now;
  const reservationEnd = reservationEndDate ? DateTime.fromISO(reservationEndDate) : null;

  const excludeDates = () => {
    const today = DateTime.now();
    const daysToExclude = [today, today.plus({ days: 1 })];
    // exclude sat and sun if day of week is thursday or greater
    // always excluded today and tomorrow
    // if date is Friday or greater, exclude monday
    const saturday = today.startOf('week').plus({ days: 5 });
    const sunday = today.startOf('week').plus({ days: 6 });

    if (today.weekday >= 4) {
      daysToExclude.push(saturday, sunday);
    }

    if (today.weekday >= 5) {
      daysToExclude.push(today.startOf('week').plus({ days: 7 }));
    }

    const bookedCleanings = recent_and_upcoming_cleanings.map(cleaning => DateTime.fromISO(cleaning.date));
    daysToExclude.push(...bookedCleanings);

    return daysToExclude.map(date => {
      const newDate = date.toJSDate();
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    });
  };

  const excludedDates = excludeDates();
  const excludedDatesAsTimes = excludedDates.map(date => date.getTime());
  const validDates = dates.filter(date => excludedDatesAsTimes.indexOf(date.getTime()) === -1);
  if (dates.length !== validDates.length) {
    setDates(validDates);
  }

  const startDate = reservationStart > now ? reservationStart : now;
  const maxDate = reservationEnd
    ? startDate.plus({ months: 1 }) < reservationEnd
      ? startDate.plus({ months: 1 })
      : reservationEnd
    : startDate.plus({ months: 1 });

  const handleSave = async () => {
    const updatedSlots = {
      ...slots,
      planId,
      cleaningDates: dates.map(d => d.toISOString())
    };

    const { data: updatedInteraction } = await resolveIntent({
      chatbot_interaction_id: chatbotInteractionId,
      slots: updatedSlots
    });
    dispatch(actions.updateInteraction({ interaction: updatedInteraction, threadId }));
    const { data: user } = await getUser({ id: userId });
    dispatch(userActions.setUser(user));
  };

  return (
    <Layout padding={16}>
      {hasMultipleWorkspaces && (
        <Layout>
          <Select
            label="Select office"
            size="xxs"
            value={workspaceId}
            options={workspaceOptions}
            onChange={({ value }) => setWorkspaceId(value)}
            fullWidth
          />
        </Layout>
      )}
      <Layout marginTop={12}>
        <Text size="body-sm" color="gray-900" semibold>
          Select cleaning date(s)
        </Text>
        {dates.length === 0 && (
          <Layout marginTop={12} marginBottom={12} height={26}>
            <Text size="body-sm" color="gray-400">
              No dates selected.
            </Text>
          </Layout>
        )}
        {dates.length > 0 && (
          <Layout flex wrap paddingBottom={12}>
            {dates.map(date => {
              return (
                <Layout marginTop={12} marginRight={12} key={date.toString()}>
                  <Tag
                    value={formatDate({ format: 'SHORT', date })}
                    onRemove={() => setDates(dates.filter(d => d !== date))}
                  ></Tag>
                </Layout>
              );
            })}
          </Layout>
        )}
      </Layout>
      <Layout>
        <DatePickerMultiple
          maxDate={maxDate.toJSDate()}
          minDate={startDate.toJSDate()}
          excludeDates={excludeDates()}
          onChange={dates => setDates(dates)}
          selected={dates}
        />
      </Layout>
      <Layout marginY={12} justify="space-between" gap={8} paddingY={16} paddingX={12} borderTop>
        <PillButton type="secondary" size="md" text="Back" onClick={goBack} icon="leftArrow" iconPosition="left" />
        <PillButton
          type="primary"
          size="md"
          disabled={dates.length === 0}
          text={`Confirm order • ${formatUsd(deep_cleaning_price * dates.length)}`}
          onClick={handleSave}
          fullWidth
        />
      </Layout>
    </Layout>
  );
};

export default OrderOneOffCleaning;
