import { COORDS, LatLng, Market } from '@codiwork/codi';

import { LOCATION_TYPES, filteredResults } from 'helpers/map';

import { boundsToLiteral, getBoundsZoomLevel } from 'ds/map/utils';
import { OfficeRequestLocation } from 'store/OfficeRequest';
import { centerOfMarket } from 'ux/Public/OfficeRequest/utils';

import { SearchLocation } from './types';

export const officeRequestLocationFromSearchLocation = async ({
  searchLocation,
  market
}: {
  searchLocation: SearchLocation;
  market: Market;
}): Promise<OfficeRequestLocation | null> => {
  const { googleMapsAutocompleteService } = window.codi;
  const { googleMapsPlacesService } = window.codi;

  if (!googleMapsAutocompleteService || !googleMapsPlacesService) {
    return null;
  }

  const parts = [];
  parts.push(searchLocation.name);
  if (searchLocation.submarket) {
    parts.push(searchLocation.submarket.name);
  }
  if (searchLocation.market) {
    parts.push(searchLocation.market.name);
  }
  parts.push(searchLocation.state);

  const origin = centerOfMarket(market!);

  const predictionParams: google.maps.places.AutocompletionRequest = {
    input: parts.join(', '),
    componentRestrictions: { country: ['us'] },
    types: [...LOCATION_TYPES],
    locationRestriction: market.bounds,
    origin
  };

  const response = await googleMapsAutocompleteService.getPlacePredictions(predictionParams);
  const results = response.predictions;

  const filtered = filteredResults(
    results.filter(r => r.description.toLowerCase().startsWith(searchLocation.name.toLowerCase()))
  );
  const firstResult = filtered[0];
  const placeId = firstResult.place_id;

  const placeResult: google.maps.places.PlaceResult = await new Promise<google.maps.places.PlaceResult>(function (
    resolve
  ) {
    googleMapsPlacesService.getDetails(
      {
        placeId: placeId,
        fields: ['address_component', 'formatted_address', 'geometry', 'name', 'vicinity']
      },
      place => {
        resolve(place!);
      }
    );
  });

  const location = placeResult.geometry?.location;

  const officeRequestLocation = {
    ...firstResult,
    loc: { lat: location?.lat()!, lng: location?.lng()! }
  };

  return officeRequestLocation;
};

export const transformStateToSearch = ({
  zoom,
  center,
  location: { state, name }
}: {
  zoom?: number;
  center?: LatLng;
  location: SearchLocation;
}) => {
  const searchParams = new URLSearchParams();

  if (zoom) {
    searchParams.set('zoom', zoom.toString());
  }

  if (center) {
    COORDS.forEach(coord => {
      searchParams.set(`center_${coord}`, center[coord].toString());
    });
  }

  if (state) {
    searchParams.set('location', `${name}, ${state}`);
  }

  return searchParams.toString();
};

export const makeSearchState = ({
  location: { name, state, centroid, coordinates, workspaces },
  isMobile
}: {
  location: SearchLocation;
  isMobile: boolean;
}) => {
  const googleBounds = new google.maps.LatLngBounds();

  if (workspaces.length) {
    workspaces.forEach(({ address: { lat, lon } }) => {
      googleBounds.extend({ lat, lng: lon });
    });
  } else if (coordinates.length) {
    coordinates.forEach(latLng => {
      googleBounds.extend(latLng);
    });
  } else if (!!centroid) {
    googleBounds.extend(centroid);
  }

  const bounds = boundsToLiteral(googleBounds);
  let zoom = getBoundsZoomLevel({
    bounds,
    width: isMobile ? window.innerWidth : window.innerWidth / 2 - 200,
    height: isMobile ? window.innerHeight - 100 : window.innerHeight - 200
  });

  zoom = Math.min(zoom, 14);

  const center = googleBounds.getCenter().toJSON();
  let location = undefined;

  if (state) {
    location = name;
  }

  return {
    bounds,
    zoom,
    center,
    location
  };
};
