import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, Button, FullWidthBackground, Layout, Text } from 'ds';
import { SEARCH_PATH } from 'routes';

interface Value {
  image: string;
  title: string;
  prop1: string;
  prop2: string;
  prop3: string;
  prop4: string;
}

interface Props {
  title: string;
  subtitle: string;
  values: Value[];
  cta_text: string;
}

const VERTICAL_SPACING = 48;

const Hero: React.FC<Props> = ({ title, subtitle, cta_text, values }) => {
  const { xs, media500, sm, md, lg, xl } = useContext(media);

  return (
    <FullWidthBackground color="blue-100">
      <Animate>
        <Layout
          paddingY={xs || sm ? 96 : undefined}
          height={lg ? 1080 : md ? 900 : undefined}
          direction="column"
          align="center"
          justify="center"
        >
          <Text size={xs || sm ? 'h4' : 'h3'} color="blue-700" align="center">
            {title}
          </Text>
          <Layout marginTop={12} />
          <Layout maxWidth={xs ? 606 : undefined}>
            <Text size={xs || sm ? 'h6' : 'h5'} color="blue-700" notBold align="center">
              {subtitle}
            </Text>
          </Layout>
          <Layout marginTop={VERTICAL_SPACING} />
          <Layout width="100%" direction={xs || sm ? 'column' : 'row'} justify="space-around">
            {values.map((value, index) => {
              const { image, title: valueTitle, prop1, prop2, prop3, prop4 } = value;
              const iconSize = lg ? 56 : 44;

              return (
                <Layout key={valueTitle} width={xs || sm ? '100%' : 'calc((100% - 48px) / 3)'}>
                  {index > 0 && <Layout marginLeft={xs || sm ? undefined : 24} marginTop={xs || sm ? 24 : undefined} />}
                  <Layout
                    paddingY={48}
                    paddingX={32}
                    color="white"
                    borderRadius={24}
                    height="100%"
                    direction="column"
                    justify="flex-start"
                    align="center"
                  >
                    <Layout width={iconSize} height={iconSize}>
                      <img src={image} alt={valueTitle} />
                    </Layout>
                    <Layout marginTop={24} />
                    <Text size={md ? 'h6' : 'h5'} color="blue-700">
                      {valueTitle}
                    </Text>
                    <Layout marginTop={24} />
                    {[prop1, prop2, prop3, prop4].map(prop => (
                      <Layout paddingY={12} key={prop}>
                        <Text color="blue-700" size={xl ? 'h5' : md || lg ? 'body1' : 'h6'} scale={!(xs || sm)} notBold>
                          {prop}
                        </Text>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
              );
            })}
          </Layout>
          <Layout marginTop={VERTICAL_SPACING} />
          <Layout width={xs ? '100%' : 274} maxWidth={media500 ? undefined : 328} justify="center">
            <Button
              size="lg"
              type="primary"
              text={cta_text}
              href={SEARCH_PATH}
              search="?offsites_only=1"
              fullWidth={media500}
            />
          </Layout>
        </Layout>
      </Animate>
    </FullWidthBackground>
  );
};

export default Hero;
