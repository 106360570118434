import React, { useContext, useEffect, useState } from 'react';

import { app, media } from 'context';
import { Animate, Grid, Icon, Layout, Text } from 'ds';
import { actions } from 'store/OfficeRequest';
import { selectHeadCount, selectMaxMonthlyBudget } from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import OfficeRequestBottomSheet from './OfficeRequestBottomSheet';
import OfficeRequestNumberStepper from './OfficeRequestNumberStepper';
import StepContainer from './StepContainer';
import StepDescription from './StepDescription';
import StepTitle from './StepTitle';
import { generateStepPath } from './utils';

interface Props {}

const Step5: React.FC<Props> = () => {
  const maxMonthlyBudget = useAppSelector(selectMaxMonthlyBudget);
  const headCount = useAppSelector(selectHeadCount);
  const dispatch = useAppDispatch();
  const { xs, sm } = useContext(media);
  const { isSmallPhone } = useContext(app);
  const isMobile = xs || sm;
  const [maxMonthlyBudgetOriginal, setMaxMonthlyBudgetOriginal] = useState<number>();

  const BudgetInput = (
    <OfficeRequestNumberStepper
      onChange={value => {
        dispatch(actions.setMaxMonthlyBudget(value || 0));
      }}
      value={maxMonthlyBudget}
      label="max monthly budget"
      max={10000000}
      stepValue={1000}
      isCurrency
    />
  );

  useEffect(() => {
    if (maxMonthlyBudget === undefined && headCount) {
      let budget = headCount * 1000;
      budget = Math.ceil(budget / 1000) * 1000;
      budget = Math.max(budget, 2000);

      setMaxMonthlyBudgetOriginal(budget);
      dispatch(actions.setMaxMonthlyBudget(budget));
    }
  }, [maxMonthlyBudget, headCount, dispatch]);

  return (
    <StepContainer
      step={5}
      backHref={generateStepPath({ step: 4 })}
      nextHref={generateStepPath({ step: 7 })}
      ctaDisabled={!maxMonthlyBudget}
      stepCompletedEventValue={{ maxMonthlyBudget, maxMonthlyBudgetOriginal }}
      {...(isSmallPhone ? { textLayoutProps: { marginTop: 32 } } : {})}
      stepName="budget"
      skippable
    >
      <Layout>
        <StepTitle>What’s your max monthly office budget?</StepTitle>
        {!isSmallPhone && (
          <Layout marginTop={isMobile ? 16 : 24}>
            <StepDescription>This helps us narrow in on fully serviced offices that fit your spend.</StepDescription>
          </Layout>
        )}
        {isMobile && (
          <Animate
            paddingY={24}
            marginTop={isSmallPhone ? 24 : 32}
            marginBottom={isSmallPhone ? 280 : 180}
            borderRadius={16}
            paddingX={28}
            background="rgba(236, 239, 241, 0.10)"
          >
            <Text size="body2" color="white" semibold>
              What’s included
            </Text>
            {!isSmallPhone && (
              <Layout marginTop={4}>
                <Text size="body2" color="white">
                  All Codi offices come fully serviced and include our standard amenities, as well as our dedicated
                  success team.
                </Text>
              </Layout>
            )}
            <Layout {...(isSmallPhone ? { marginTop: 12 } : { marginTop: 24 })}>
              <Grid itemsPerRow={2} gapY={16}>
                <Layout align="center">
                  <Icon name="settings" size="md" color="white" />
                  <Layout marginLeft={12}>
                    <Text size="body3" color="white">
                      Rent
                    </Text>
                  </Layout>
                </Layout>
                <Layout align="center">
                  <Icon name="wifi" size="md" color="white" />
                  <Layout marginLeft={12}>
                    <Text size="body3" color="white">
                      Fast WiFi
                    </Text>
                  </Layout>
                </Layout>
                <Layout align="center">
                  <Icon name="chair" size="md" color="white" />
                  <Layout marginLeft={12}>
                    <Text size="body3" color="white">
                      Furniture
                    </Text>
                  </Layout>
                </Layout>
                <Layout align="center">
                  <Icon name="beverage" size="md" color="white" />
                  <Layout marginLeft={12}>
                    <Text size="body3" color="white">
                      Beverages
                    </Text>
                  </Layout>
                </Layout>
                <Layout align="center">
                  <Icon name="cleaning" size="md" color="white" />
                  <Layout marginLeft={12}>
                    <Text size="body3" color="white">
                      Cleaning
                    </Text>
                  </Layout>
                </Layout>
              </Grid>
            </Layout>
          </Animate>
        )}
      </Layout>
      {isMobile ? (
        <OfficeRequestBottomSheet>
          <Layout justify="center" paddingBottom={isSmallPhone ? 36 : 48}>
            {BudgetInput}
          </Layout>
        </OfficeRequestBottomSheet>
      ) : (
        <Animate>
          <Layout justify="center">{BudgetInput}</Layout>
          <Layout
            paddingTop={24}
            marginTop={48}
            paddingBottom={12}
            borderRadius={8}
            color="blue-25"
            paddingX={16}
            maxWidth={524}
          >
            <Text size="body2" align="center" semibold>
              What’s included
            </Text>
            <Layout marginTop={4}>
              <Text size="body3" align="center">
                All Codi offices come fully serviced and include our standard amenities, as well as our dedicated
                success team.
              </Text>
            </Layout>
            <Layout align="center" paddingY={24} marginTop={12}>
              <Layout align="center" direction="column" equalWidth>
                <Icon name="settings" size="md" />
                <Layout marginTop={8}>
                  <Text size="body3">Rent</Text>
                </Layout>
              </Layout>
              <Layout align="center" direction="column" equalWidth>
                <Icon name="wifi" size="md" />
                <Layout marginTop={8}>
                  <Text size="body3">Fast WiFi</Text>
                </Layout>
              </Layout>
              <Layout align="center" direction="column" equalWidth>
                <Icon name="chair" size="md" />
                <Layout marginTop={8}>
                  <Text size="body3">Furniture</Text>
                </Layout>
              </Layout>
              <Layout align="center" direction="column" equalWidth>
                <Icon name="beverage" size="md" />
                <Layout marginTop={8}>
                  <Text size="body3">Beverages</Text>
                </Layout>
              </Layout>
              <Layout align="center" direction="column" equalWidth>
                <Icon name="cleaning" size="md" />
                <Layout marginTop={8}>
                  <Text size="body3">Cleaning</Text>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Animate>
      )}
    </StepContainer>
  );
};

export default Step5;
