import React, { PropsWithChildren } from 'react';

import { Layout } from 'ds';
import { selectOfficeRequestWizardHideFooter } from 'store/UI/selectors';
import { useAppSelector } from 'store/hooks';

import { BOTTOM_SHEET_PADDING_TOP, MOBILE_PROGRESS_FOOTER_HEIGHT } from './constants';

interface Props {}

const OfficeRequestBottomSheet: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const footerHidden = useAppSelector(selectOfficeRequestWizardHideFooter);

  return (
    <Layout
      position="fixed"
      bottom={0}
      color="white"
      width="100%"
      paddingTop={BOTTOM_SHEET_PADDING_TOP}
      borderTopLeftRadius={16}
      borderTopRightRadius={16}
      paddingBottom={footerHidden ? undefined : MOBILE_PROGRESS_FOOTER_HEIGHT}
      __className="OfficeRequestBottomSheet"
    >
      {children}
    </Layout>
  );
};

export default OfficeRequestBottomSheet;
