import React, { useEffect, useRef } from 'react';
import { Color, ICON_NAME_TO_ROTATION, IconName, IconSize } from '@codiwork/codi';
import classNames from 'classnames';

import { ICON_NAME_TO_SVG } from './Icons';

import { ReactComponent as IconBlueGradient } from './IconBlueGradient.svg';
import { ReactComponent as IconPurpleGradient } from './IconPurpleGradient.svg';

export interface Props {
  /** xxs: 12px | xs: 16px | sm: 20px | md: 24px | lg: 28px | xl: 32px | xxl: 36px | xxxl: 66px  */
  size: IconSize | number;
  name: IconName;
  stroke?: 1.5 | 2;
  color?: Color | 'blue-gradient' | 'purple-gradient';
}

const Icon: React.FC<Props> = ({ size, name, color = 'gray-900', stroke = 1.5 }) => {
  const Svg = ICON_NAME_TO_SVG[stroke][name];
  const classes = ['Icon'];
  const rotation = ICON_NAME_TO_ROTATION[name as keyof typeof ICON_NAME_TO_ROTATION];
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const spanElement = ref.current;

    if (!spanElement) return;

    if (!['blue-gradient', 'purple-gradient'].includes(color)) return;

    const fillElements = spanElement.querySelectorAll('.Icon-fill-color');

    Array.from(fillElements).forEach(element => {
      (element as SVGPathElement).style.fill = `url('#icon-${color}')`;
    });
  }, [color]);

  if (rotation) {
    classes.push(`is-rotation-${rotation}`);
  }

  if (!Svg) return null;

  const isStandardSize = typeof size === 'string';

  return (
    <span
      ref={ref}
      {...(isStandardSize ? {} : { style: { width: size, height: size } })}
      className={classNames(classes, { [`is-size-${size}`]: isStandardSize }, `is-color-${color}`)}
    >
      <Svg />
      {color === 'blue-gradient' && <IconBlueGradient />}
      {color === 'purple-gradient' && <IconPurpleGradient />}
    </span>
  );
};

export default Icon;
