import React from 'react';

import WorkspaceCard, { Props as WorkspaceCardProps } from 'ds/WorkspaceCard';

import Layout from './Layout';
import SearchMapWorkspaceCard from './SearchMapWorkspaceCard';
import { DEMO_SEARCH_WORKSPACE } from './workspace-data';

interface Props extends Pick<WorkspaceCardProps, 'width' | 'landscape' | 'size'> {
  useEffectDependency?: boolean;
  type?: 'search';
  onMeasure: (rect: DOMRect) => void;
}

const GhostWorkspaceCard = ({ width, onMeasure, landscape, size, type }: Props) => {
  return (
    <Layout visibility="hidden" position="absolute" onMeasure={onMeasure}>
      {type ? (
        <SearchMapWorkspaceCard workspace={{ ...DEMO_SEARCH_WORKSPACE }} daysPerWeek={3} />
      ) : (
        <WorkspaceCard width={width} landscape={landscape} size={size} workspace={{ ...DEMO_SEARCH_WORKSPACE }} />
      )}
    </Layout>
  );
};

export default GhostWorkspaceCard;
