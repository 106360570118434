import React from 'react';

import { Clickable, Icon, Layout, PurpleGradientCircle, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_NEW_SCREEN } from './constants';

interface Props {}

const ChatbotAskButton: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigateToNewThread = () => dispatch(actions.pushScreen({ value: CHATBOT_NEW_SCREEN }));
  return (
    <Clickable onClick={navigateToNewThread} style={{ borderRadius: 8 }} fullWidth>
      <Layout
        color="white"
        borderRadius={8}
        boxShadow="2px 0 22px 0 rgba(34.1176, 28.6275, 42.7451, 0.1200)"
        paddingX={16}
        paddingY={16}
        justify="space-between"
        align="center"
      >
        <Text size="body-sm" semibold>
          Make a request
        </Text>
        <Layout gap={12} align="center" flex>
          <PurpleGradientCircle dimension={32} />
          <Icon stroke={2} name="rightChevron" size="xs" />
        </Layout>
      </Layout>
    </Clickable>
  );
};

export default ChatbotAskButton;
