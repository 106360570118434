import React, { useContext, useState } from 'react';
import { kebabCase } from 'lodash';

import Head from 'components/Head';
import OffsiteRequestForm from 'components/OffsiteRequestForm';

import { app, media } from 'context';
import { CarouselOld, Img, Layout, Text, WorkspaceImg } from 'ds';

import CaseStudyCompanyInfo from './CaseStudyCompanyInfo';
import CaseStudyContainer from './CaseStudyContainer';
import CaseStudyHero from './CaseStudyHero';
import CaseStudyHighlights from './CaseStudyHighlights';
import CaseStudyNavigation from './CaseStudyNavigation';
import { CaseStudyPageType } from './types';

import { ReactComponent as TaskRabbitLogo } from './task-rabbit-logo.svg';

interface Props {
  pageType: CaseStudyPageType;
  title: string;
  description: string;
}

const HIGHLIGHTS = 'Highlights';
const SUMMARY = 'Summary';
const GALLERY = 'Gallery';
const FULL_STORY = 'Full Story';

const TaskRabbit: React.FC<Props> = ({ pageType, title, description }) => {
  const { xs, sm, md, lg, xl, xxl } = useContext(media);
  const { contentPaddingX, windowWidth } = useContext(app);
  const imageSectionHeight = xxl ? 388 : xl ? 254 : lg ? 286 : md ? 302 : sm ? windowWidth / 1.39 : windowWidth / 1.44;
  const [offsiteRequestFormIsVisible, setOffsiteRequestFormIsVisible] = useState<boolean>(false);
  const isSideNav = pageType === 'side-nav';
  const isPhone = pageType === 'phone';

  return (
    <>
      <Head title={title} description={description} />
      <OffsiteRequestForm
        isVisible={offsiteRequestFormIsVisible}
        onCloseModal={() => setOffsiteRequestFormIsVisible(false)}
      />
      <CaseStudyHero
        imageKey="case-study-hero_9UgxsPybK.jpeg"
        imageKeyXs="case-study-hero-xs_JLSqgqC3C.jpeg"
        imageKitFolder="public"
        title="How TaskRabbit outsourced in-person collaboration for 200 employees using Codi Offsites"
      />
      <CaseStudyContainer pageType={pageType} setOffsiteRequestFormIsVisible={setOffsiteRequestFormIsVisible}>
        <CaseStudyNavigation
          sections={[{ name: HIGHLIGHTS }, { name: SUMMARY }, { name: GALLERY }, { name: FULL_STORY }]}
          pageType={pageType}
          setOffsiteRequestFormIsVisible={setOffsiteRequestFormIsVisible}
        />
        <Layout>
          <Layout __id={kebabCase(HIGHLIGHTS)} {...(isPhone ? { marginTop: 40 } : {})}>
            <CaseStudyHighlights
              highlights={[
                { icon: 'addressPinOutline', text: '5 teams across 3 cities experienced a Codi-led offsite' },
                { icon: 'clipboard', text: 'Consistent standard of event and space across cities and teams' },
                { icon: 'clock', text: 'Offsite events launched using less time and costs than before' },
                { icon: 'whiteboard', text: 'Greater employee satisfaction and workplace efficiency' }
              ]}
            />
          </Layout>
          <Layout __id={kebabCase(SUMMARY)}>
            <Layout marginTop={40}>
              <Text size="body1" color="gray-400" semibold>
                THE CHALLENGE
              </Text>
            </Layout>
            <Layout marginTop={4}>
              <Text size="h5">Reimagining the role of the office </Text>
            </Layout>
            <Layout marginTop={12}>
              <Text size="h6" bold={false}>
                TaskRabbit wanted to host one offsite per month to bring their remote teams together. But the
                difficulties of finding a suitable space and planning offsite logistics got in the way of focusing on
                what mattered: culture building and improving employee engagement.
              </Text>
            </Layout>
            <Layout marginTop={40}>
              <Text size="body1" color="gray-400" semibold>
                THE SOLUTION
              </Text>
            </Layout>
            <Layout marginTop={4}>
              <Text size="h5">Organizing offsites with limited resources</Text>
            </Layout>
            <Layout marginTop={12}>
              <Text size="h6" bold={false}>
                TaskRabbit leveraged Codi Offsites as a one-stop shop for their entire North American team-building
                activities. Codi helped TaskRabbit prepare for each offsite by sourcing locations, customizing
                floor-plans and setting up the venue, before providing IT support, catering and concierge staff on the
                day.
              </Text>
            </Layout>
            <Layout marginTop={40}>
              <Text size="body1" color="gray-400" semibold>
                THE OUTCOME
              </Text>
            </Layout>
            <Layout marginTop={4}>
              <Text size="h5">Happier teams through in-person collaboration</Text>
            </Layout>
            <Layout marginTop={12}>
              <Text size="h6" bold={false}>
                TaskRabbit leveraged Codi Offsites as a one-stop shop for their entire North American team-building
                activities. Codi helped TaskRabbit prepare for each offsite by sourcing locations, customizing
                floor-plans and setting up the venue, before providing IT support, catering and concierge staff on the
                day.
              </Text>
            </Layout>
            <Layout paddingX={xs ? 24 : 36} paddingY={12} marginTop={isSideNav ? 80 : 40}>
              <Text {...(xs ? { size: 'h5' } : { size: 'h4' })} color="blue-gradient">
                “Allowing flexibility while providing opportunities to meet in person creates happier, stronger teams.”
              </Text>
              <Layout marginTop={24}>
                <Text size="h6" color="blue-700">
                  Jamie O’Malley
                </Text>
                <Text size="h6" color="blue-700" bold={false}>
                  EA to the CEO
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout __id={kebabCase(GALLERY)} justify={lg && !xl ? 'center' : undefined} marginTop={lg ? 80 : 40}>
            <Layout width={lg && !xl ? 700 : undefined} flex={!(xs || sm)}>
              <Img
                srcKey="task-rabbit-office-map_j5Fc9h4DV.png"
                folder="public"
                alt="Task Rabbit office map"
                width={1050}
                style={
                  xs || sm
                    ? {
                        width: windowWidth,
                        height: imageSectionHeight,
                        objectFit: 'cover',
                        position: 'relative',
                        left: xs ? -40 : -contentPaddingX
                      }
                    : { width: imageSectionHeight, height: imageSectionHeight, objectFit: 'cover' }
                }
              />
              <Layout
                {...(xs || sm
                  ? {
                      position: 'relative',
                      left: xs ? -40 : -contentPaddingX,
                      marginTop: 8,
                      width: windowWidth
                    }
                  : { marginLeft: 24 })}
                height={imageSectionHeight}
              >
                <CarouselOld visibleCount={1} itemSpacing={xs || sm ? 8 : 12} borderRadius={xs || sm ? 0 : 8}>
                  <WorkspaceImg
                    size="lg"
                    srcKey="55mtpkso2osbdzfuja8tnr7w2jxl"
                    folder="production"
                    style={{ height: imageSectionHeight, objectFit: 'cover', borderRadius: xs || sm ? 0 : 8 }}
                  />
                  <WorkspaceImg
                    size="lg"
                    srcKey="ytwvn1r4fb5s6skf2nqwp0v1lk1q"
                    folder="production"
                    style={{ height: imageSectionHeight, objectFit: 'cover', borderRadius: xs || sm ? 0 : 8 }}
                  />
                  <WorkspaceImg
                    size="lg"
                    srcKey="bzjqnk9onvgi23st1ikkqdgdrl7s"
                    folder="production"
                    style={{ height: imageSectionHeight, objectFit: 'cover', borderRadius: xs || sm ? 0 : 8 }}
                  />
                  <WorkspaceImg
                    size="lg"
                    srcKey="xbpcjqvf5g5wozb8trhwwwbhmtpt"
                    folder="production"
                    style={{ height: imageSectionHeight, objectFit: 'cover', borderRadius: xs || sm ? 0 : 8 }}
                  />
                </CarouselOld>
              </Layout>
            </Layout>
          </Layout>
          <Layout __id={kebabCase(FULL_STORY)} marginTop={lg ? 80 : 40}>
            <Text size="h5">Outsourcing offsites for 200 employees across 5 teams</Text>
            <Layout marginTop={24}>
              <Text size="h6" bold={false}>
                Before the pandemic, TaskRabbit had four corporate hubs in San Francisco, Austin, New York, and London.
                However, like many companies, the COVID-19 pandemic changed how TaskRabbit and its employees felt about
                remote work.
                <br />
                <br />
                Leadership surveyed their team and found that the majority of employees wanted flexible work schedules.
                Despite the consensus to stay remote, many also highlighted the desire to maintain opportunities for
                purposeful, face-to-face collaboration; whether that entailed workshops, fostering team development, or
                attending leadership meetings.
                <br />
                <br />
                Based on the survey findings, TaskRabbit closed their offices and began offering week-long ‘offsites’
                (referred to internally as 'Team Weeks') once a month.
                <br />
                <br />
                TaskRabbit had a few essential criteria to foster a collaborative and inclusive environment for
                employees, though finding spaces that met these standards took a lot of work. According to Ania Smith,
                CEO of TaskRabbit, "it was hard to find the type of space we wanted consistently across cities." In
                addition to the baseline criteria, TaskRabbit needed to arrange catering, IT integration, and setup, all
                within a set timeframe. They spent hours researching spaces and going back-and-forth with venue owners
                before discovering Codi Offsites.
                <br />
                <br />
                The Codi team spent time understanding TaskRabbit’s unique needs and, according to Jamie O'Malley,
                Executive Assistant to the CEO, provided a “one-stop solution,” that offered “more amenities, cost
                transparency, and layout flexibility” than any other option.
              </Text>
            </Layout>
            <Layout marginTop={24} borderRadius={8} color="blue-50" paddingY={36} paddingX={xs ? 24 : 36}>
              <Text size="h6" color="blue-700">
                Codi helped TaskRabbit prepare for each offsite by:
              </Text>
              <ul style={{ paddingLeft: 24 }} className="is-bullet-color-blue-700 CaseStudy-offsite-list">
                <br />
                <li style={{ fontSize: 18 }}>
                  <Text size="h6" color="blue-700" bold={false} inline>
                    Sourcing locations
                  </Text>
                </li>
                <br />
                <li style={{ fontSize: 18 }}>
                  <Text size="h6" color="blue-700" bold={false} inline>
                    Customizing layouts for each team’s size
                  </Text>
                </li>
                <br />
                <li style={{ fontSize: 18 }}>
                  <Text size="h6" color="blue-700" bold={false} inline>
                    Planning with the knowledgeable support staff
                  </Text>
                </li>
                <br />
                <li style={{ fontSize: 18 }}>
                  <Text size="h6" color="blue-700" bold={false} inline>
                    Providing gourmet coffee and snacks, venue setup, and IT support
                  </Text>
                </li>
              </ul>
            </Layout>
            <Layout marginTop={24}>
              <Text size="h6" bold={false}>
                With logistics taken care of and a set timeline for activation, TaskRabbit was able to focus solely on
                running successful team building activities. By outsourcing this logistical burden, TaskRabbit also
                reported saving time and resources that they would have otherwise used for event planning.
                <br />
                <br />
                TaskRabbit hosted eight successful offsites in Austin and San Francisco from May to November 2022, with
                over 200 employees across more than five teams flying in to collaborate with their colleagues. "Feedback
                from employees about Codi has been positive, with teams leveraging each space for their needs," said
                O'Malley.
                <br />
                <br />
                TaskRabbit will continue conducting surveys and tailor its approach as needed. For now, "we will
                continue to host Team Weeks — one city per month" said O'Malley. "Allowing this flexibility, while
                providing opportunities to meet in person, creates happier, stronger teams."
              </Text>
            </Layout>
          </Layout>
        </Layout>
        <CaseStudyCompanyInfo
          logo={<TaskRabbitLogo width="204" height="41" />}
          industry="Technology, Marketplace"
          location="Hubs in San Francisco, Austin, NYC, and London"
          numEmployees="300–500"
          workplaceStrategy="Remote-first as of May 2022"
          pageType={pageType}
        />
      </CaseStudyContainer>
    </>
  );
};

export default TaskRabbit;
