import { Button, Layout, Text } from 'ds';
import React, { useContext } from 'react';

import { OFFICE_REQUEST_PATH } from 'routes';
import Section from './Section';
import Visual from './Visual';
import { media } from 'context';
import { Replacement, replaceValues } from 'ds/utils';

interface Props {
  title: string | JSX.Element;
  body: string;
  form_cta: string;
  search_cta?: string;
  video?: {
    url: string;
    thumbnail: string;
    show_border?: boolean;
  }[];
  images?: {
    image: string;
    alt_text: string;
  }[];
  replacements: Replacement[];
}

const Hero: React.FC<Props> = ({ title, body, form_cta, video: videos, images = [], replacements }) => {
  const mediaContext = useContext(media);
  const { xs, sm, md, lg, xl } = mediaContext;

  return (
    <>
      <Layout align="center" direction={xs ? 'column-reverse' : 'row'}>
        <Section
          Visual={<Visual videos={videos} images={images} />}
          heading={
            <Layout __style={{ marginTop: xs ? -48 : undefined }}>
              {typeof title === 'string' ? (
                <Text size={xl ? 'h1' : lg ? 'h2' : md ? 'h3' : sm ? 'h3' : 'h1'} tag="h1" scale>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </Text>
              ) : (
                title
              )}
            </Layout>
          }
          subHeading={
            <Layout marginTop={24}>
              <Text size="body1" scale>
                {replaceValues(body, replacements)}
              </Text>
              <Layout marginTop={40} flex wrap={!lg}>
                <Layout inline width={xs || sm || md ? '100%' : '50%'} display="inline-flex">
                  <Button
                    type="primary"
                    text={form_cta}
                    context="hero"
                    href={OFFICE_REQUEST_PATH}
                    size="lg"
                    fullWidth
                  ></Button>
                </Layout>
              </Layout>
            </Layout>
          }
          links={[]}
          reverse
          reverseVisual={xs}
        />
      </Layout>
    </>
  );
};

export default Hero;
