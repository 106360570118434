import React, { MouseEvent, PropsWithChildren } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Color, colorClassName } from '@codiwork/codi';
import classNames from 'classnames';
import { Location } from 'history';
import { HashLink } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';

import { getBaseUrl } from 'helpers/environment';

import { track } from 'lib/analytics';

import Text, { HEADING_TAGS, HeadingTag, Props as TextProps } from './Text';
import { DS_CLASS_NAME, IS_TOUCH_DEVICE } from './constants';
import { LinkTarget } from './types';
import { HASH_LINK_REGEXP, extractText } from './utils';

export interface Props extends LinkProps, Pick<TextProps, 'size' | 'tag' | 'bold'> {
  onClick?: (event: MouseEvent<HTMLLinkElement, MouseEvent>) => void;
  underline?: boolean;
  semibold?: boolean;
  hoverSemibold?: boolean;
  text?: JSX.Element;
  scroll?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  color?: Color;
  opacity?: boolean;
  name?: string;
  draggable?: boolean;
  disabled?: boolean;
  display?: 'inline' | 'inline-flex' | 'block';
  tabIndex?: -2 | -1 | 0 | 1 | 2;
  /** Used for hash links */
  scrollBehavior?: ScrollBehavior;
  scrollYOffset?: number;
}

export interface LinkProps {
  href?: string;
  target?: LinkTarget;
  context?: string;
  state?: object;
  search?: string;
  /** Used as 'value' in track event */
  value?: string | number;
}

const Link: React.FC<PropsWithChildren<Props>> = ({
  href,
  children,
  text,
  target,
  scroll,
  underline = false,
  semibold = true,
  hoverSemibold = false,
  fullWidth = false,
  fullHeight = false,
  color = 'blue-500',
  size = 'link',
  opacity = true,
  draggable,
  disabled,
  bold,
  tag,
  context,
  display = 'inline-flex',
  tabIndex,
  state,
  scrollYOffset = -24,
  scrollBehavior = 'smooth',
  search,
  ...rest
}) => {
  const isRouterLink = href && href[0] === '/';
  const className = classNames('Link', DS_CLASS_NAME, colorClassName(color), {
    'is-fullWidth': fullWidth,
    'is-underlined': underline,
    'has-opacity': opacity && !IS_TOUCH_DEVICE,
    'is-fullHeight': fullHeight,
    'is-hover-semibold': hoverSemibold,
    [`layout-display-${display}`]: display
  });
  const isHeadingSize = HEADING_TAGS.includes(size as HeadingTag) || HEADING_TAGS.includes(tag as HeadingTag);
  const { pathname } = useLocation();

  const handleTrack = () => {
    track('Element Interacted', {
      context,
      name: rest.name || extractText(text || (children as JSX.Element | string)),
      type: 'link',
      href
    });
  };

  const handleScroll = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = scrollYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: scrollBehavior });
  };

  const props = {
    className,
    onClick: (e: any) => {
      rest.onClick && rest.onClick(e);
      handleTrack();
    },
    rel: !isRouterLink && target === '_blank' ? 'noopener noreferrer' : undefined,
    draggable,
    disabled,
    tabIndex
  };

  const linkText =
    typeof children === 'string' ? (
      <Text
        tag={tag}
        color={color}
        size={isHeadingSize && tag ? tag : size}
        bold={isHeadingSize && bold === undefined ? false : bold}
        semibold={bold ? false : semibold && !hoverSemibold}
        inline
      >
        {children}
      </Text>
    ) : (
      text || children
    );

  if (scroll && href) {
    return (
      <ScrollLink className={className} to={href} onClick={() => props.onClick({})} smooth>
        {linkText}
      </ScrollLink>
    );
  } // For in app links, use react-router-dom <Link>
  else if (isRouterLink) {
    const isHashLink = HASH_LINK_REGEXP.test(href);
    const LinkComponent = isHashLink ? HashLink : RouterLink;

    return (
      <LinkComponent
        to={
          isHashLink
            ? href
            : process.env.STORYBOOK
            ? (location: Location) => ({ ...location, path: getBaseUrl() + href })
            : { pathname: href, state: { prevPath: pathname, ...state }, search }
        }
        target={target}
        {...(isHashLink ? { scroll: handleScroll } : {})}
        {...props}
      >
        {linkText}
      </LinkComponent>
    );
  } else {
    return (
      <a {...props} href={href} target={target || '_blank'}>
        {linkText}
      </a>
    );
  }
};

export default Link;
