import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChildComponentProps } from 'google-map-react';

import { MapWorkspace } from 'ds/types';
import { LISTING_PATH } from 'routes';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import HeartMarker from './HeartMarker';
import OfficeSearchMarker from './OfficeSearchMarker';
import PinMarker from './PinMarker';
import PriceMarker from './PriceMarker';
import { MapProps } from './types';
import { getNewCenter } from './utils';

interface Props
  extends ChildComponentProps,
    Pick<
      MapProps,
      | 'workspaces'
      | 'selectedWorkspaceId'
      | 'offsitesOnly'
      | 'daysPerWeek'
      | 'onMarkerClickCenterChange'
      | 'onMarkerClick'
      | 'markers'
      | 'markerType'
      | 'setSelectedWorkspaceId'
    > {
  workspace: MapWorkspace;
  visitedWorkspaceIds: number[];
  cardWidth?: number;
  cardHeight?: number;
  mapRef: React.MutableRefObject<google.maps.Map | null>;
}

const MapMarker: React.FC<Props> = ({
  offsitesOnly,
  daysPerWeek,
  markerType,
  onMarkerClick,
  markers = [],
  visitedWorkspaceIds,
  selectedWorkspaceId,
  cardWidth,
  cardHeight,
  workspace,
  workspace: {
    id,
    monthly_price,
    daily_rate,
    address: { lat, lon: lng }
  },
  setSelectedWorkspaceId,
  onMarkerClickCenterChange,
  mapRef
}) => {
  const history = useHistory();
  const isSelected = id === selectedWorkspaceId;
  const isLoggedIn = !!useAppSelector(selectUser);

  const visited = !isSelected && visitedWorkspaceIds.includes(id);
  const marker = markers.find(m => m.workspaceId === id);
  const type = marker?.type || 'price';

  const handleMarkerClick = () => {
    if (isSelected) return;

    const map = mapRef.current;

    if (!map || !cardHeight || !cardWidth) {
      return;
    }

    if (onMarkerClick) {
      onMarkerClick({
        map,
        marker: { lat, lng },
        card: { width: cardWidth, height: cardHeight },
        workspace
      });
    }
  };

  if (['heart', 'heart-outline'].includes(type)) {
    return (
      <HeartMarker
        // @ts-expect-error
        type={type}
        onClick={handleMarkerClick}
        monthly_price={monthly_price || 0}
        daysPerWeek={daysPerWeek || 5}
        id={id}
        isActive={selectedWorkspaceId === id}
        isRecommended={marker?.isRecommended}
      />
    );
  } else if (isLoggedIn && type === 'office-search') {
    return (
      <OfficeSearchMarker
        onClick={handleMarkerClick}
        monthly_price={monthly_price || 0}
        daysPerWeek={daysPerWeek || 5}
        id={id}
        isActive={selectedWorkspaceId === id}
        isRecommended={marker?.isRecommended}
      />
    );
  } else if (isLoggedIn && type !== 'office' && markerType === 'price') {
    return (
      <PriceMarker
        type={type}
        visited={visited}
        onClick={handleMarkerClick}
        monthly_price={monthly_price || 0}
        daysPerWeek={daysPerWeek || 5}
        offsitesOnly={offsitesOnly}
        daily_rate={daily_rate || 0}
        id={id}
        isActive={selectedWorkspaceId === id}
      />
    );
  } else {
    return (
      <PinMarker
        onClick={() => {
          if (onMarkerClick) {
            handleMarkerClick();
            return;
          }
          if (type === 'office') {
            history.push(`${LISTING_PATH}/${id}`);
            return;
          }

          setSelectedWorkspaceId && setSelectedWorkspaceId(workspace.id);
          const map = mapRef.current;

          if (!map || !cardHeight || !cardWidth) {
            return;
          }

          const newCenter = getNewCenter({ map, latLng: { lat, lng }, cardWidth, cardHeight });

          if (newCenter && onMarkerClickCenterChange) {
            onMarkerClickCenterChange({ center: newCenter.latLng });
          }
        }}
        id={id}
        lat={lat}
        lng={lng}
        isActive={selectedWorkspaceId === id}
        type={type === 'office' ? 'office' : undefined}
      />
    );
  }
};

export default MapMarker;
