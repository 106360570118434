import { Bounds } from '@codiwork/codi';
import Supercluster, { ClusterProperties } from 'supercluster';

import { MapWorkspace } from 'ds/types';

import {
  MAP_CONTROL_HEIGHT,
  MAP_CONTROL_PADDING,
  MAP_MARKER_CARD_SPACE,
  MAP_MARKER_DIMENSION,
  MAX_CLUSTER_ZOOM,
  PRICE_MARKER_HEIGHT
} from './constants';
import { GroupedWorkspaces } from './types';

interface PixelXY {
  x: number;
  y: number;
}

export function getNewCenter({
  map,
  latLng,
  cardWidth,
  cardHeight
}: {
  map: google.maps.Map;
  latLng: google.maps.LatLngLiteral;
  cardWidth: number;
  cardHeight: number;
}): { latLng: google.maps.LatLngLiteral; xy: { x: number; y: number } } | false {
  const coords = latLngToPixelXY(new google.maps.LatLng(latLng.lat, latLng.lng), map);

  if (!coords) {
    return false;
  }

  const { x, y } = coords;
  const width = map.getDiv().clientWidth;
  const height = map.getDiv().clientHeight;

  const centerX = width / 2;
  const centerY = height / 2;

  const leftCardEdge = x - cardWidth / 2;
  const rightCardEdge = x + cardWidth / 2;
  const topCardEdge = y - (MAP_MARKER_DIMENSION / 2 + cardHeight);
  const bottomCardEdge = y + MAP_MARKER_DIMENSION / 2;

  let updatedCenterX: number | null = null;
  let updatedCenterY: number | null = null;

  const outOfBoundsLeft = -(leftCardEdge - MAP_CONTROL_PADDING);
  const outOfBoundsRight = -(width - MAP_CONTROL_PADDING - rightCardEdge);
  const outOfBoundsTop = -(
    topCardEdge -
    MAP_CONTROL_PADDING * 2 -
    MAP_CONTROL_HEIGHT -
    MAP_MARKER_CARD_SPACE -
    MAP_MARKER_DIMENSION / 2
  );
  const outOfBoundsBottom = -(height - MAP_CONTROL_PADDING * 2 - bottomCardEdge);

  // move center to the left
  if (outOfBoundsLeft > 0) {
    updatedCenterX = centerX - outOfBoundsLeft;
    // move center to the right
  } else if (outOfBoundsRight > 0) {
    updatedCenterX = centerX + outOfBoundsRight;
  }

  // move center above
  if (outOfBoundsTop > 0) {
    updatedCenterY = centerY - outOfBoundsTop;
    // move center below
  } else if (outOfBoundsBottom > 0) {
    updatedCenterY = centerY + outOfBoundsBottom;
  }

  if (updatedCenterX === null && updatedCenterY === null) {
    return false;
  }

  const xy = {
    x: updatedCenterX || centerX,
    y: updatedCenterY || centerY
  };

  const newLatLng = pixelXYToLatLng(map, xy);

  return newLatLng ? { latLng: newLatLng, xy } : false;
}

export function determineCardLatLng({
  map,
  latLng,
  cardWidth,
  cardHeight,
  overlappingGroups,
  selectedWorkspaceId
}: {
  map: google.maps.Map;
  latLng: google.maps.LatLngLiteral;
  cardWidth: number;
  cardHeight: number;
  overlappingGroups: GroupedWorkspaces;
  selectedWorkspaceId: number;
}): google.maps.LatLngLiteral {
  const coords = latLngToPixelXY(new google.maps.LatLng(latLng.lat, latLng.lng), map);

  if (!coords) {
    return latLng;
  }

  const groupPosition = getGroupPosition({ groups: overlappingGroups, selectedWorkspaceId: selectedWorkspaceId });

  const { x, y } = coords;
  const mapWidth = map.getDiv().clientWidth;
  const mapHeight = map.getDiv().clientHeight;

  const mapCenterY = mapHeight / 2;

  const cardPosition = y + PRICE_MARKER_HEIGHT + 16 > mapCenterY ? 'top' : 'bottom';

  let positionedX = x - cardWidth / 2;
  if (positionedX < 8) positionedX = 8;
  if (positionedX + cardWidth + 8 > mapWidth) positionedX = mapWidth - cardWidth - 8;

  let yOffset = 0;

  if (cardPosition === 'top') {
    yOffset = -cardHeight + (PRICE_MARKER_HEIGHT + 4 + (PRICE_MARKER_HEIGHT + 4) * groupPosition) * -1;
  } else {
    yOffset = 4 - (PRICE_MARKER_HEIGHT + 4) * groupPosition;
  }

  const positionedY = y + yOffset;

  return (
    pixelXYToLatLng(map, {
      x: positionedX,
      y: positionedY
    }) || latLng
  );
}

export function latLngToPixelXY(latLng: google.maps.LatLng, map: google.maps.Map): undefined | PixelXY {
  const projection = map.getProjection();
  const bounds = map.getBounds();

  if (!projection || !bounds) {
    return;
  }

  const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
  const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
  const scale = Math.pow(2, map.getZoom() || 14);
  const worldPoint = projection.fromLatLngToPoint(latLng);

  if (!worldPoint || !bottomLeft || !topRight) return;

  return { x: Math.floor((worldPoint.x - bottomLeft.x) * scale), y: Math.floor((worldPoint.y - topRight.y) * scale) };
}

export function pixelXYToLatLng(map: google.maps.Map, { x, y }: PixelXY): google.maps.LatLngLiteral | undefined {
  const projection = map.getProjection();
  const bounds = map.getBounds();

  if (!projection || !bounds) {
    return;
  }

  const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
  const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
  const scale = Math.pow(2, map.getZoom() || 14);

  if (!bottomLeft || !topRight) return;

  const worldPoint = new google.maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y);
  const latLng = projection.fromPointToLatLng(worldPoint);

  if (!latLng) return;

  return { lat: latLng.lat(), lng: latLng.lng() };
}

interface ClusterProps extends Partial<ClusterProperties> {
  workspace: MapWorkspace;
}

export const generateSuperCluster = ({ workspaces }: { workspaces: MapWorkspace[] }) => {
  const superCluster = new Supercluster<ClusterProps>({
    radius: 40,
    maxZoom: MAX_CLUSTER_ZOOM
  });

  superCluster.load(
    workspaces.map(ws => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [ws.address.lon, ws.address.lat]
      },
      properties: { workspace: ws }
    }))
  );

  return superCluster;
};

export const boundsToLiteral = (bounds: google.maps.LatLngBounds): Bounds => {
  return { ne: bounds.getNorthEast().toJSON(), sw: bounds.getSouthWest().toJSON() };
};

export const shiftOverlappingWorkspaces = (workspaces: MapWorkspace[], map: google.maps.Map) => {
  const markerPixels: { id: number; coords: { x: number; y: number } }[] = [];
  const overlappingGroups: { id: number; workspaces: MapWorkspace[] }[] = [];

  workspaces.forEach(ws => {
    const {
      id,
      address: { lat, lon }
    } = ws;
    const googleLatLng = new google.maps.LatLng(lat, lon);
    const wsXY = latLngToPixelXY(googleLatLng, map);

    if (!wsXY) return ws;

    let markerIndex = 0;
    let shifted = false;

    while (markerIndex < markerPixels.length) {
      const markerPixel = markerPixels[markerIndex];
      const {
        id,
        coords: { x, y }
      } = markerPixel;
      const pixelDistance = Math.hypot(x - wsXY.x, y - wsXY.y);

      if (pixelDistance < 12) {
        const groupIndex = overlappingGroups.findIndex(g => g.id === id);
        const group = overlappingGroups[groupIndex];

        if (group) {
          overlappingGroups[groupIndex] = { ...group, workspaces: [...group.workspaces, ws] };
        } else {
          overlappingGroups.push({ id, workspaces: [ws] });
        }

        shifted = true;
        break;
      }

      markerIndex++;
    }

    if (!shifted) {
      markerPixels.push({ id, coords: wsXY });
    }
  });

  const shiftedWorkspaces = [...workspaces];

  overlappingGroups.forEach(({ workspaces }) => {
    workspaces.forEach((ws, groupIndex) => {
      const { lat, lon: lng } = ws.address;

      const xy = latLngToPixelXY(new google.maps.LatLng(lat, lng), map);

      if (xy) {
        const shiftedXy = { x: xy.x, y: xy.y - 32 * (groupIndex + 1) };
        const shiftedLatLng = pixelXYToLatLng(map, shiftedXy);

        if (shiftedLatLng) {
          const index = shiftedWorkspaces.findIndex(({ id }) => id === ws.id);
          shiftedWorkspaces[index] = {
            ...ws,
            address: { ...ws.address, lat: shiftedLatLng.lat, lon: shiftedLatLng.lng }
          };
        }
      }
    });
  });

  return { workspaces: shiftedWorkspaces, groups: overlappingGroups };
};

export const EMPTY_BOUNDS = {
  ne: {
    lat: undefined,
    lng: undefined
  },
  sw: {
    lat: undefined,
    lng: undefined
  }
};

/** 0 is bottom, 1 above 0, 2 above 1, etc. */
export function getGroupPosition({
  groups,
  selectedWorkspaceId
}: {
  groups: GroupedWorkspaces;
  selectedWorkspaceId: number;
}) {
  const groupedMatch = groups.filter(
    g => g.id === selectedWorkspaceId || g.workspaces.map(ws => ws.id).includes(selectedWorkspaceId)
  )[0];
  const position = groupedMatch
    ? groupedMatch.id === selectedWorkspaceId
      ? 0
      : groupedMatch.workspaces.findIndex(ws => ws.id === selectedWorkspaceId) + 1
    : 0;

  return position;
}

// from https://stackoverflow.com/a/13274361/4224942
export function getBoundsZoomLevel({
  bounds: { ne, sw },
  width,
  height
}: {
  bounds: Bounds;
  width: number;
  height: number;
}) {
  const mapDim = { width: width, height: height };
  var WORLD_DIM = { height: 256, width: 256 };
  var ZOOM_MAX = 21;

  var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

  var lngDiff = ne.lng - sw.lng;
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom({ mapPx: mapDim.height, worldPx: WORLD_DIM.height, fraction: latFraction });
  var lngZoom = zoom({ mapPx: mapDim.width, worldPx: WORLD_DIM.width, fraction: lngFraction });

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

function latRad(lat: number): number {
  var sin = Math.sin((lat * Math.PI) / 180);
  var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

function zoom({ mapPx, worldPx, fraction }: { mapPx: number; worldPx: number; fraction: number }): number {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
}

export function cornerBoundsToGoogleLiteralBounds(bounds: Bounds): google.maps.LatLngBoundsLiteral {
  return { east: bounds.ne.lng, west: bounds.sw.lng, north: bounds.ne.lat, south: bounds.sw.lat };
}

export function generateLocationKey({ address: { lat, lon: lng } }: MapWorkspace) {
  return lat.toFixed(4) + '-' + lng.toFixed(4);
}
