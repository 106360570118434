import React, { useContext } from 'react';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Layout } from 'ds';

import AboutCodiConnect from './AboutCodiConnect';
import BrokerLogos from './BrokerLogos';
import BrokerReachOutForm from './BrokerReachOutForm';
import BrokerTestimonials from './BrokerTestimonials';
import Browse from './Browse';
import Commission from './Commission';
import Hero from './Hero';
import HowItWorksCarousel from './HowItWorksCarousel';
import WhyPartner from './WhyPartner';
import { BROKERS_PAGE_MAX_WIDTH } from './constants';

import { ReactComponent as CirclesLeft } from './circles-left.svg';

interface Props {}

const HOME_TITLE = 'Office for Rent | New York, San Francisco, Los Angeles';
const HOME_DESCRIPTION =
  'Private office space for rent in New York City, San Francisco Bay Area, and Los Angeles. Move-in ready and furnished, with meeting rooms and flexible terms that grow with your business.';
const HOME_KEYWORDS =
  'office for rent, office for rent new york, office for rent san francisco, office for rent los angeles, office for lease, office space, flexible workspace, space rent, office space rent, flexible office spaces, office space new york, office space san francisco';

const Brokers: React.FC<Props> = () => {
  const { xs, sm, isMobile } = useContext(media);
  const { contentPaddingX, windowWidth, contentWidth } = useContext(app);

  let paddingX: number | undefined;
  let usingMaxWidth = false;
  if (windowWidth - contentPaddingX * 2 > BROKERS_PAGE_MAX_WIDTH) {
    paddingX = (windowWidth - BROKERS_PAGE_MAX_WIDTH) / 2;
    usingMaxWidth = true;
  } else {
    paddingX = contentPaddingX;
  }

  const containerProps = {
    maxWidth: usingMaxWidth ? BROKERS_PAGE_MAX_WIDTH : contentWidth + contentPaddingX * 2,
    flexGrow: 1,
    paddingX: usingMaxWidth ? undefined : contentPaddingX
  };

  return (
    <>
      <Head title={HOME_TITLE} description={HOME_DESCRIPTION} keywords={HOME_KEYWORDS} />
      <Hero />
      <Layout justify="center" color="blue-25" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Layout marginTop={isMobile ? 96 : 172}>
            <Commission />
          </Layout>
          <Layout marginTop={isMobile ? 128 : 360}>
            <AboutCodiConnect />
          </Layout>
          <Layout marginTop={isMobile ? 128 : 278} {...(isMobile ? {} : { paddingBottom: 160 })}>
            <HowItWorksCarousel />
          </Layout>
        </Layout>
        {!isMobile && <CirclesLeft style={{ position: 'absolute', left: 0, top: 520 }} />}
      </Layout>
      <Layout justify="center" color="white" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Layout {...(isMobile ? { padding: contentPaddingX } : {})} marginTop={isMobile ? 128 : 350}>
            <WhyPartner />
          </Layout>
          <Layout {...(xs ? { position: 'relative' } : {})} marginTop={128}>
            <HeroImage
              imageKey="home-page-kearny_F7JjKdo4p.jpeg"
              imageKitFolder="public"
              height={xs ? 244 : sm ? 380 : 436}
              {...(xs ? { imgWidth: 1600, imgHeight: 2000 } : { paddingX: paddingX, imgWidth: 3000, imgHeight: 1200 })}
              accountForPaddingX={!xs}
              parallax={!isMobile}
            />
          </Layout>
        </Layout>
      </Layout>
      <Layout justify="center" color="blue-25" position="relative" paddingBottom={xs ? 100 : 160}>
        <Layout {...(xs ? {} : containerProps)}>
          <Layout {...(xs ? { marginTop: 64 } : { marginTop: 264 })}>
            <BrokerLogos />
          </Layout>
          <Layout marginTop={xs ? 88 : 264}>
            <BrokerTestimonials />
          </Layout>
        </Layout>
      </Layout>
      <Layout justify="center" color="white" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Layout {...(isMobile ? { paddingX: contentPaddingX } : {})} marginTop={xs ? 100 : 264}>
            <BrokerReachOutForm />
          </Layout>
        </Layout>
      </Layout>
      <Layout
        marginTop={isMobile ? 128 : 160}
        {...(isMobile ? { paddingY: 64 } : { paddingY: 100, justify: 'center' })}
        color="blue-25"
        justify="center"
      >
        <Layout {...(xs ? {} : containerProps)}>
          <Browse />
        </Layout>
      </Layout>
    </>
  );
};

export default Brokers;
