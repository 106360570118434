import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';
import Accordion from 'ds/Accordion';

interface Props {
  /** Questions and answers */
  qas: {
    question: string;
    answer: string;
  }[];
}

const FAQs: React.FC<Props> = ({ qas }) => {
  const { xs, lg, xl } = useContext(media);

  return (
    <Layout>
      <Text size={xs ? 'h4' : 'h3'} color="blue-700" align={lg ? 'center' : 'left'}>
        Your Questions Answered
      </Text>
      <Layout paddingX={xl ? 80 : undefined} marginY={80}>
        <Accordion
          columns={2}
          items={qas.map(qa => {
            return { title: qa.question, body: qa.answer };
          })}
        />
      </Layout>
    </Layout>
  );
};

export default FAQs;
