import React, { useRef } from 'react';
import classNames from 'classnames';

interface Props {
  dayOfMonth: number;
  isStartDate: boolean;
  isEndDate: boolean;
  isDisabled: boolean;
}

const CalendarDay: React.FC<Props> = ({ dayOfMonth, isStartDate, isEndDate, isDisabled }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={classNames('Calendar-dayInner', {
        'is-start-date': isStartDate,
        'is-end-date': isEndDate,
        'is-disabled': isDisabled
      })}
    >
      {dayOfMonth}
    </div>
  );
};

export default CalendarDay;
