import React, { PropsWithChildren } from 'react';

import { Animate, HEADER_Z_INDEX } from 'ds';

interface Props {}

const LocationResultsContainer: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <Animate
      position="absolute"
      zIndex={HEADER_Z_INDEX + 1}
      boxShadow="2px 0px 22px 0px #57496D1F"
      borderRadius={12}
      top={58}
      overflow="hidden"
      color="white"
      width="100%"
      animation="fade-in-from-top"
      duration={300}
    >
      {children}
    </Animate>
  );
};

export default LocationResultsContainer;
