import React, { useContext, useEffect, useRef, useState } from 'react';

import { app } from 'context';
import { Animate, ButterImg, Layout } from 'ds';

import { SectionImage } from './types';

interface Props extends Omit<SectionImage, 'width'> {
  width: number;
}

const CustomImage: React.FC<Props> = ({ src, alt, top, bottom, left, right, width }) => {
  const ref = useRef<HTMLImageElement>(null);
  const [height, setHeight] = useState<number>();
  const { maxWidth, width: screenWidth, contentWidth } = useContext(app);

  const widthToScale = maxWidth ? Math.min(maxWidth, screenWidth) : screenWidth;
  const scale1440 = widthToScale / 1440;

  useEffect(() => {
    const image = ref.current;

    if (!image) return;

    const { height } = image.getBoundingClientRect();
    setHeight(height);
  }, [contentWidth]);

  const style = {
    position: 'absolute',
    top: typeof top === 'number' ? top * scale1440 : undefined,
    bottom: typeof bottom === 'number' ? bottom * scale1440 : undefined,
    left: typeof left === 'number' ? left * scale1440 : undefined,
    right: typeof right === 'number' ? right * scale1440 : undefined,
    opacity: height ? 1 : 0
  } as const;

  const props = {
    src,
    alt,
    style,
    ref
  };

  return (
    <Layout
      position="relative"
      __style={{ bottom: height ? height / 2 : undefined, right: 0, width: width * scale1440 }}
    >
      {height ? (
        <Animate>
          <ButterImg {...props} />
        </Animate>
      ) : (
        <ButterImg
          {...props}
          onLoad={() => {
            const image = ref.current;

            if (!image) return;

            const { height } = image.getBoundingClientRect();
            setHeight(height);
          }}
        />
      )}
    </Layout>
  );
};

export default CustomImage;
