import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { IconButton, Img, Layout, Text } from 'ds';

interface Props {
  imageKey: string;
  imageKeyXs: string;
  imageKitFolder: string;
  title: string;
}

const CaseStudyHero: React.FC<Props> = ({ imageKey, imageKeyXs, imageKitFolder, title }) => {
  const { xs, md, lg, xl, xxl } = useContext(media);
  const { contentPaddingX, maxWidth, navBarHeight } = useContext(app);
  const location = useLocation();
  const { pathname } = location;
  const iconHref = pathname + '#company-info';

  if (xs) {
    return (
      <Layout
        position="relative"
        __style={{
          width: '100vw',
          height: `calc(100vh - ${navBarHeight}px)`,
          position: 'relative',
          left: -contentPaddingX
        }}
      >
        <Img
          srcKey={imageKeyXs}
          folder={imageKitFolder}
          width={1600}
          style={{ objectFit: 'cover', width: '100%', height: '100%', objectPosition: '-60px' }}
          alt="office"
        />
        <Layout
          position="absolute"
          top={0}
          left={0}
          height="100%"
          width="100%"
          __style={{ background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.4) 31.59%, rgba(255, 255, 255, 0) 72.2%)' }}
        />
        <Layout position="absolute" bottom={160} paddingX={contentPaddingX}>
          <Text size="h4" color="white">
            {title}
          </Text>
        </Layout>
        <Layout position="absolute" bottom={92} left="50vw" transform="translateX(-50%)">
          <IconButton
            type="transparentDark"
            name="downChevron"
            stroke={2}
            href={iconHref}
            scrollYOffset={-(140 + navBarHeight)}
            size="md"
          />
        </Layout>
      </Layout>
    );
  }

  return (
    <HeroImage
      imageKey={imageKey}
      imageKitFolder={imageKitFolder}
      height={xxl ? 500 : xl ? 408 : lg ? 340 : md ? 254 : 218}
    >
      <Layout
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        __style={{ background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.4) 31.59%, rgba(255, 255, 255, 0) 72.2%)' }}
      />
      <Layout position="absolute" bottom={lg ? 36 : 24} justify="center" width="100vw">
        <Layout maxWidth={maxWidth} paddingX={contentPaddingX}>
          <Text size={xl ? 'h3' : lg ? 'h4' : 'h5'} color="white">
            {title}
          </Text>
        </Layout>
      </Layout>
    </HeroImage>
  );
};

export default CaseStudyHero;
