import React from 'react';

import { Layout, Pressable, Text } from 'ds';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

interface Props {
  value: number;
  label: string;
  sublabel?: string;
  isSelected: boolean;
}

const DayPerWeekMobile: React.FC<Props> = ({ value, label, sublabel, isSelected }) => {
  const dispatch = useAppDispatch();

  return (
    <Pressable
      onPress={() => {
        dispatch(actions.setDaysPerWeek(value));
      }}
      style={{ width: '100%' }}
      hoverColor="blue-50"
    >
      <Layout
        borderRadius={8}
        {...(isSelected
          ? { borderColor: 'blue-500', borderWidth: 2, color: 'blue-50', paddingY: 15 }
          : { borderColor: 'gray-50', borderWidth: 1, paddingY: 16 })}
      >
        <Text size="body1" bold={false} align="center" scale>
          {label}
        </Text>
        {sublabel && (
          <Layout marginTop={8}>
            <Text size="body3" color="gray-700" align="center">
              {sublabel}
            </Text>
          </Layout>
        )}
      </Layout>
    </Pressable>
  );
};

export default DayPerWeekMobile;
