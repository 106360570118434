import React, { useContext, useState } from 'react';

import { getScreenSize, media } from 'context';
import { Layout, Modal, Text } from 'ds';

import Form from './Form';
import { ContactFormInbox } from './types';

import LeftImage from './LeftImage.jpg';

interface Props {
  title?: string;
  onCloseModal?: VoidFunction;
  isVisible: boolean;
  inbox: ContactFormInbox;
}

const SIZE_TO_CONTENT_WIDTH = {
  xl: '704px',
  lg: '568px',
  md: '450px',
  sm: '100%',
  xs: '100%',
  xxs: '100%'
};

const SIZE_TO_CONTENT_PADDING_X = {
  xl: 80,
  lg: 56,
  md: 32,
  sm: 32,
  xs: 24,
  xxs: 24
};

const SIZE_TO_IMAGE_HEIGHT = {
  xl: '100%',
  lg: '100%',
  md: '100%',
  sm: '272px',
  xs: '220px',
  xxs: '152px'
};

const IMAGE_WIDTH = 1064;
const IMAGE_HEIGHT = 812;

const SUBTITLE = 'Submit your inquiry here and we’ll get back to you as soon as we can.';

const ContactForm: React.FC<Props> = ({ title = 'Get in Touch', onCloseModal = () => {}, isVisible, inbox }) => {
  const mediaContext = useContext(media);
  const { xs, sm } = mediaContext;
  const size = getScreenSize(mediaContext);
  const [successState, setSuccessState] = useState<boolean>(false);

  const onSubmit = () => {
    setSuccessState(true);
  };

  return (
    <Modal isFullScreen size="lg" onClose={onCloseModal} isVisible={isVisible}>
      {successState ? (
        <Layout
          position="absolute"
          top={0}
          width="100vw"
          height="100vh"
          color="blue-50"
          justify="center"
          align="center"
          direction="column"
          __style={{ paddingLeft: SIZE_TO_CONTENT_PADDING_X[size], paddingRight: SIZE_TO_CONTENT_PADDING_X[size] }}
        >
          <Text size="h3">
            <span role="img" aria-label="Waving emoji">
              👍
            </span>
          </Text>
          <Layout marginTop={24} />
          <Text size={xs ? 'h4' : 'h3'} color="blue-800">
            All set!
          </Text>
          <Layout marginTop={24} />
          <Text size="body1" color="blue-800" align="center" scale>
            Thanks for reaching out. A member of our team will be in contact soon.
          </Text>
        </Layout>
      ) : (
        <Layout position="absolute" top={0} width="100%" direction={xs || sm ? 'column' : 'row'}>
          {!(xs || sm) && (
            <Layout position="relative" height="100vh" width="100%" overflow="hidden">
              <img
                src={LeftImage}
                style={{
                  height: '100%',
                  width: 'auto'
                }}
                alt="workspace"
              />
            </Layout>
          )}
          <Layout
            height="100vh"
            minWidth={SIZE_TO_CONTENT_WIDTH[size]}
            maxWidth={SIZE_TO_CONTENT_WIDTH[size]}
            overflow="auto"
            position="relative"
            color="white"
            right={xs || sm ? undefined : 0}
          >
            {(xs || sm) && (
              <Layout height={SIZE_TO_IMAGE_HEIGHT[size]} width="100vw" overflow="hidden">
                <img
                  src={LeftImage}
                  style={{
                    height: 'auto',
                    width: '100%',
                    marginTop: `calc((${SIZE_TO_IMAGE_HEIGHT[size]} - 100vw * ${IMAGE_HEIGHT / IMAGE_WIDTH}) / 2)`
                  }}
                  alt="workspace"
                />
              </Layout>
            )}
            <Layout
              marginTop={xs ? 24 : sm ? 32 : 120}
              __style={{ paddingLeft: SIZE_TO_CONTENT_PADDING_X[size], paddingRight: SIZE_TO_CONTENT_PADDING_X[size] }}
              // Push content above bottom browser bar
              paddingBottom={xs ? 64 : undefined}
            >
              <Layout marginTop={xs ? 24 : sm ? 32 : 120}>
                <Text size="h3" color="blue-800" scale>
                  {title}
                </Text>
              </Layout>
              <Layout marginTop={xs || sm ? 16 : 24}>
                <Text size="body1" color="gray-600" scale>
                  {SUBTITLE}
                </Text>
                {!successState && (
                  <Layout marginTop={24} paddingBottom={64}>
                    <Form onSubmit={onSubmit} inbox={inbox} />
                  </Layout>
                )}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      )}
    </Modal>
  );
};

export default ContactForm;
