import React, { useContext } from 'react';
import { GRAY_900_HEX } from '@codiwork/codi';

import { media } from 'context';
import { IconButton, Layout, Text } from 'ds';
import { formatUsd } from 'helpers';
import { actions as uiActions } from 'store/UI';
import { useAppDispatch } from 'store/hooks';

interface Props {
  placeholder?: string;
  value?: number;
  onChange: (value?: number) => void;
  label: string;
  max: number;
  isCurrency?: boolean;
  stepValue?: number;
}

const NUMBER_REGEX = /\D/g;

const OfficeRequestNumberStepper: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  label,
  max,
  isCurrency = false,
  stepValue = 1
}) => {
  const { xs, sm } = useContext(media);
  const isBottomSheet = xs || sm;
  const dispatch = useAppDispatch();
  return (
    <Layout>
      <Layout align="center">
        <IconButton
          size="lg"
          onClick={() => {
            if (!value) return;

            onChange(Math.max(0, value - stepValue));
          }}
          type="gray"
          name="minus"
          disabled={!value}
        />
        <Layout width={164} marginX={36}>
          <input
            autoComplete="off"
            placeholder={placeholder}
            autoFocus={!isBottomSheet}
            inputMode="numeric"
            style={{
              borderStyle: 'none',
              fontSize: isBottomSheet ? 16 : 20,
              lineHeight: isBottomSheet ? '24px' : '30px',
              outline: 'none',
              background: 'none',
              color: GRAY_900_HEX,
              zIndex: 1,
              textAlign: 'center',
              width: '100%'
            }}
            onFocus={() => {
              if (!isBottomSheet) return;

              dispatch(uiActions.setOfficeRequestWizardHideFooter(true));
            }}
            onBlur={() => {
              if (!isBottomSheet) return;

              dispatch(uiActions.setOfficeRequestWizardHideFooter(false));
            }}
            value={value === undefined ? '' : isCurrency ? formatUsd(value, false) : value.toLocaleString()}
            onChange={({ target: { value } }) => {
              const number = parseInt(value.replaceAll(NUMBER_REGEX, ''));

              if (isNaN(number)) {
                onChange(undefined);
              } else if (number > max) {
                return;
              } else {
                onChange(number);
              }
            }}
          />
          <Layout
            __style={{
              background: 'linear-gradient(94.99deg, #1AB2D9 28.48%, #0078FF 141.74%)'
            }}
            height={2}
            marginTop={8}
          />
        </Layout>
        <IconButton
          size="lg"
          onClick={() => {
            onChange(Math.min(max, (value || 0) + stepValue));
          }}
          type="gray"
          name="plus"
          disabled={(value || 0) >= max}
        />
      </Layout>
      <Layout marginTop={24}>
        <Text size="body1" align="center">
          {label}
        </Text>
      </Layout>
    </Layout>
  );
};

export default OfficeRequestNumberStepper;
