import React, { useContext, useState } from 'react';

import { media } from 'context';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { formatNumberK } from 'ds/utils';
import { apiTrack } from 'lib/analytics';

import RangeFilterContainer, { RangeFilter } from './RangeFilter';

interface Props {
  values: number[];
  minSqft?: number;
  maxSqft?: number;
  onChange?: (params: { minSqft?: number; maxSqft?: number }) => void;
  onSubmit?: (params: { minSqft?: number; maxSqft?: number }) => void;
  align?: 'left' | 'right';
}

const SLIDER_MIN = 200;
const SLIDER_MAX = 20000;
const INPUT_MAX = 100000;
const INTERVAL = 50;

const SizeFilter: React.FC<Props> = ({ values, minSqft, maxSqft, onChange, onSubmit, align }) => {
  const { xs, sm, md } = useContext(media);
  const buttonText = generateButtonText({ minSqft, maxSqft });
  const [sliderMinValue, setSliderMinValue] = useState<number | undefined>(
    typeof minSqft === 'number' ? minSqft : SLIDER_MIN
  );
  const [sliderMaxValue, setSliderMaxValue] = useState<number | undefined>(
    typeof maxSqft === 'number' ? maxSqft : SLIDER_MAX
  );
  const [inputMinValue, setInputMinValue] = useState<number | undefined>(minSqft);
  const [inputMaxValue, setInputMaxValue] = useState<number | undefined>(maxSqft);

  const handleClear = () => {
    if (!onSubmit) return;

    setSliderMinValue(SLIDER_MIN);
    setSliderMaxValue(SLIDER_MAX);
    setInputMinValue(undefined);
    setInputMaxValue(undefined);
    onSubmit({ minSqft: undefined, maxSqft: undefined });
    apiTrack('Filters Set', { minSqft: null, maxSqft: null });
  };

  const resetToInitialValues = () => {
    setSliderMinValue(typeof minSqft === 'number' ? minSqft : SLIDER_MIN);
    setSliderMaxValue(typeof maxSqft === 'number' && maxSqft <= SLIDER_MAX ? maxSqft : SLIDER_MAX);
    setInputMinValue(minSqft);
    setInputMaxValue(maxSqft);
  };

  if (xs && onChange) {
    return (
      <Layout>
        <Layout align="center" marginBottom={16}>
          <Icon name="ruler" size="md" color="gray-900" />
          <Layout marginLeft={8}>
            <Text size="h6" scale>
              Size (ft²)
            </Text>
          </Layout>
        </Layout>
        <RangeFilter
          shortLabel="Size"
          values={values}
          sliderMin={SLIDER_MIN}
          sliderMax={SLIDER_MAX}
          inputMax={INPUT_MAX}
          inputInterval={INTERVAL}
          sliderMinValue={sliderMinValue}
          sliderMaxValue={sliderMaxValue}
          onSliderMinChange={value => {
            setInputMinValue(value);
            setSliderMinValue(value);
            onChange({ minSqft: value });
          }}
          onSliderMaxChange={value => {
            setInputMaxValue(value);
            setSliderMaxValue(value);
            onChange({ maxSqft: value });
          }}
          onInputMinChange={value => {
            setInputMinValue(value);
            onChange({ minSqft: value });
          }}
          onInputMaxChange={value => {
            setInputMaxValue(value);
            onChange({ maxSqft: value });
          }}
          inputMinValue={inputMinValue}
          inputMaxValue={inputMaxValue}
          type="number"
        />
      </Layout>
    );
  }

  if (!onSubmit) return null;

  return (
    <RangeFilterContainer
      align={align || (sm || md ? 'right' : 'left')}
      buttonText={buttonText}
      label="Size (ft²)"
      shortLabel="Size"
      icon="ruler"
      values={values}
      sliderMin={SLIDER_MIN}
      sliderMax={SLIDER_MAX}
      inputMax={INPUT_MAX}
      inputInterval={INTERVAL}
      inputMinValue={inputMinValue}
      inputMaxValue={inputMaxValue}
      sliderMinValue={sliderMinValue}
      sliderMaxValue={sliderMaxValue}
      onInputMinChange={value => setInputMinValue(value)}
      onInputMaxChange={value => setInputMaxValue(value)}
      onSliderMinChange={value => {
        setInputMinValue(value);
        setSliderMinValue(value);
      }}
      onSliderMaxChange={value => {
        setInputMaxValue(value);
        setSliderMaxValue(value);
      }}
      onClear={handleClear}
      onClickOutside={resetToInitialValues}
      type="number"
      isDefaultSelected={minSqft === undefined && maxSqft === undefined}
      onSubmit={() => {
        let minSqft = inputMinValue === undefined ? undefined : inputMinValue;
        let maxSqft = inputMaxValue === undefined ? undefined : inputMaxValue;

        if (typeof minSqft === 'number' && typeof maxSqft === 'number' && maxSqft <= minSqft) {
          setInputMaxValue(undefined);
          maxSqft = undefined;
        }

        const sliderMax = maxSqft ? Math.min(maxSqft, SLIDER_MAX) : SLIDER_MAX;
        setSliderMinValue(minSqft || 0);
        setSliderMaxValue(sliderMax);

        onSubmit({ minSqft, maxSqft });

        apiTrack('Filters Set', { minSqft, maxSqft });
      }}
    />
  );
};

const generateButtonText = ({ minSqft, maxSqft }: { minSqft?: number; maxSqft?: number }) => {
  if (typeof minSqft === 'number' && !isNaN(minSqft) && typeof maxSqft === 'number' && !isNaN(maxSqft)) {
    return `${formatNumberK(minSqft)}-${formatNumberK(maxSqft)} sqft`;
  } else if (typeof minSqft === 'number' && !isNaN(minSqft)) {
    return `${formatNumberK(minSqft)}+ sqft`;
  } else if (typeof maxSqft === 'number' && !isNaN(maxSqft)) {
    return `${formatNumberK(maxSqft)} sqft max`;
  } else {
    return 'Size';
  }
};

export default SizeFilter;
