/*
 * This react hook tracks page visibility using browser page visibility api.
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 *
 * Use: usePageVisibility(isVisible => doSomething(isVisible));
 * Return type: boolean
 */

import { useEffect } from 'react';

export default function usePageVisibility(onVisibilityChange: (isVisible: boolean) => void) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      onVisibilityChange(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
