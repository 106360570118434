import React, { useContext } from 'react';
import { GRAY_200 } from '@codiwork/codi';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';
import ContentBlock from 'ds/ContentBlock';

import { ReactComponent as Airbyte } from 'ds/airbyte-logo.svg';
import { ReactComponent as Eppo } from 'ds/eppo-logo.svg';
import { ReactComponent as Ridepanda } from 'ds/ridepanda-logo.svg';
import { ReactComponent as TaskRabbit } from 'ds/task-rabbit-logo.svg';

interface Props {}

const Hero: React.FC<Props> = () => {
  const { isMobile } = useContext(media);
  const { contentPaddingX } = useContext(app);

  if (isMobile) {
    return (
      <>
        <Layout paddingX={contentPaddingX} paddingTop={60} paddingBottom={64}>
          <Text size="h4" color="blue-700">
            Codi has modernized the{' '}
            <Text color="blue-500" inline>
              office experience
            </Text>
          </Text>
          <Layout marginTop={24}>
            <Text size="h6" bold={false}>
              Hear how our clients, landlords, and brokers have benefited from partnering with Codi.
            </Text>
          </Layout>
          <Layout marginTop={60} align="center" justify="space-around">
            <span className={`Icon is-color-${GRAY_200}`}>
              <Ridepanda width={153} />
            </span>
            <span className={`Icon is-color-${GRAY_200}`}>
              <Eppo width={99} />
            </span>
          </Layout>
          <Layout marginTop={24} justify="center">
            <span className={`Icon is-color-${GRAY_200}`}>
              <TaskRabbit width={163} />
            </span>
          </Layout>
        </Layout>
        <Img
          alt="office"
          srcKey="testimonials-hero_d90LzfImh.jpeg"
          folder="public"
          width={1000}
          style={{ height: 500, width: '100%', objectFit: 'cover' }}
        />
      </>
    );
  }

  return (
    <>
      <Img
        alt="office"
        srcKey="testimonials-hero_d90LzfImh.jpeg"
        folder="public"
        width={3000}
        style={{ height: 468, width: '100%', objectFit: 'cover' }}
      />
      <ContentBlock maxWidth={974}>
        <Layout paddingY={64} align="center">
          <Layout width={594} flexShrink={0}>
            <Text size="h3" color="blue-700">
              Codi has modernized the
              <br />
              <Text color="blue-500" inline>
                office experience
              </Text>
            </Text>
          </Layout>
          <Layout marginLeft={80}>
            <Text size="h6" bold={false}>
              Hear how our clients, landlords, and brokers have benefited from partnering with Codi.
            </Text>
          </Layout>
        </Layout>
        <Layout align="center" justify="space-around" wrap paddingTop={32} paddingBottom={64}>
          <span className={`Icon is-color-${GRAY_200}`}>
            <TaskRabbit width={224} />
          </span>
          <span className={`Icon is-color-${GRAY_200}`}>
            <Airbyte width={158} />
          </span>
          <span className={`Icon is-color-${GRAY_200}`}>
            <Ridepanda width={210} />
          </span>
          <span className={`Icon is-color-${GRAY_200}`}>
            <Eppo width={136} />
          </span>
        </Layout>
      </ContentBlock>
    </>
  );
};

export default Hero;
