import React, { useEffect, useState } from 'react';
import { usePreviousValue } from 'beautiful-react-hooks';

import Layout from 'ds/Layout';

import Dot from './Dot';
import { DotType } from './types';
import { generateDots } from './utils';

interface Props {
  activeIndex: number;
  length: number;
  type?: 'light' | 'dark';
}

const Dots: React.FC<Props> = ({ activeIndex, length, type = 'light' }) => {
  const previousActiveIndex = usePreviousValue(activeIndex);
  const [className, setClassName] = useState<string>();
  const [dots, setDots] = useState<DotType[]>(generateDots(length));
  const [left, setLeft] = useState<number>(0);
  const DOT_WIDTH = 8;
  const ANIMATION_DURATION = 500;

  useEffect(() => {
    if (length <= 5) {
      setLeft(-16);
      const newDots = generateDots(length, activeIndex);
      setDots(newDots);
      return;
    }
    const newDots = generateDots(length, activeIndex);
    newDots[activeIndex].isActive = true;
    if (activeIndex === 0) setLeft(-4 + (length - DOT_WIDTH) * 4);
    if (activeIndex === length - 1) setLeft(-28 - (length - DOT_WIDTH) * 4);
    if (previousActiveIndex < activeIndex) {
      if (activeIndex < length - 2 && activeIndex > 2) setLeft(left - DOT_WIDTH);
    } else if (previousActiveIndex > activeIndex) {
      if (activeIndex < length - 3 && activeIndex > 1) setLeft(left + DOT_WIDTH);
    }
    setDots(newDots);
    setTimeout(() => setClassName(undefined), 500);
  }, [activeIndex, previousActiveIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout overflow="hidden" width={DOT_WIDTH * 5} align="center" justify="flex-end">
      <Layout width={DOT_WIDTH} direction="column" align="center">
        <Layout
          position="relative"
          left={left}
          direction="row"
          align="center"
          __className={className}
          transition={`all, ${ANIMATION_DURATION}ms`}
        >
          {dots.map((dot, index) => (
            <Dot {...dot} key={index} type={type} />
          ))}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dots;
