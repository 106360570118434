export const PLAYBOOK_META: Record<string, { title: string; description: string }> = {
  '/playbook': {
    title: 'Private Office Space Playbook',
    description: "Check out Codi's step-by-step guide to finding and managing a private office space."
  },
  '/playbook/intro': {
    title: "Introduction to Codi's Private Office Space Playbook",
    description: "Introducing Codi's step-by-step guide to finding and managing a private office space."
  },
  '/playbook/forward': {
    title: "Codi's Cofounder On Flexible Offices | Playbook",
    description:
      "Christelle Rohaut, Codi's Co-founder and CEO, discusses a new era of work: one that's flexible, private, and productive."
  },
  '/playbook/review-your-options': {
    title: 'Review Your Office Space Options | Playbook',
    description:
      "Codi's step-by-step playbook for finding and managing an office space shows you how to navigate the complexity of reviewing your office space options."
  },
  '/playbook/decision-factors': {
    title: 'Decision Factors When Reviewing Offices | Playbook',
    description:
      'Take a look at several key factors when deciding on which office option is right for you, including priorities and budget.'
  },
  '/playbook/know-your-options': {
    title: 'Knowing Your Office Space Options | Playbook',
    description: 'Know your options when finding and managing a private office space: traditional, coworking, or flex.'
  },
  '/playbook/traditional': {
    title: 'Pros and Cons of Traditional Office Space',
    description:
      'Learn the pros and cons of renting traditional office space and discover why traditional leases are the most common model for renting commercial real estate.'
  },
  '/playbook/coworking': {
    title: 'Pros and Cons of Coworking Office Space',
    description:
      'Learn the pros and cons of renting coworking office spaces and why the model has undergone a rapid rise in popularity in recent years.'
  },
  '/playbook/flex-office': {
    title: 'Pros and Cons of Flexible Office Space',
    description:
      'Flexible office spaces are the latest leasing model for commercial real estate. Learn the pros and cons of renting flexible office spaces.'
  },
  '/playbook/the-search': {
    title: 'Finding the right office space for your company',
    description:
      'Learn the best methods to search for an office space and the right questions to ask before signing a lease or signing up to a coworking membership.'
  },
  '/playbook/searching-for-traditional-office': {
    title: 'Finding the right traditional office space',
    description:
      'Learn how to search for the right traditional office space for your company and how to protect yourself from the most common pitfalls.'
  },
  '/playbook/search-steps': {
    title: 'Searching for an office with a traditional lease ',
    description:
      'Understand the steps you should take when searching for commercial office space with a traditional lease.'
  },
  '/playbook/tenant-brokers': {
    title: 'Understanding the role of Tenant Rep Brokers',
    description:
      "Discover how to work with tenant rep brokers and why it's important that they understand your business and the needs of your team."
  },
  '/playbook/reputable-brokers': {
    title: 'Tenant Rep Brokers and exclusivity agreements',
    description:
      "Find out the right questions to ask a tenant rep broker to ensure you're working with the right one and what to do before signing an exclusivity agreement."
  },
  '/playbook/broker-tours': {
    title: 'Working with Tenant Rep Brokers',
    description:
      'Understand how to work with a tenant rep broker and why you should request multiple office locations and tour the most promising options in person.'
  },
  '/playbook/red-flags': {
    title: 'Identifying red flags in commercial office spaces',
    description:
      'Learn what to look for when assessing an office space and understand the key red flags to look out for that could cause problems in the future.'
  },
  '/playbook/searching-for-coworking': {
    title: 'Searching for a coworking office space',
    description: 'Understand the steps you should take when searching for office space within a coworking company.'
  },
  '/playbook/searching-for-coworking-intro': {
    title: 'Considerations for finding a coworking office space',
    description: 'Discover the key things to remember when looking for office space within a coworking company.'
  },
  '/playbook/transience-of-coworking': {
    title: 'Understanding the economics of coworking offices',
    description:
      'Understand the importance of conducting due diligence on a coworking company due to their often transient nature.'
  },
  '/playbook/searching-for-a-flexible-office': {
    title: 'Searching for a flexible office space',
    description:
      'Understand the steps you should take when searching for commercial office space with a flexible lease.'
  },
  '/playbook/searching-for-a-flexible-office-intro': {
    title: 'Understanding the flexible office space model',
    description:
      'Learn more about the flexible office lease model and why it is the fastest growing segment in commercial real estate.'
  },
  '/playbook/flexible-space-model': {
    title: "Codi's flexible office space offering",
    description:
      "Find out about Codi's flexible space model and how it has made renting commercial office space fast and easy."
  },
  '/playbook/flexible-space-company': {
    title: 'Engaging a flexible office space company',
    description:
      'Learn how to engage a flexible office space company and what to expect from the process when compared to more traditional methods.'
  },
  '/playbook/negotiations-agreements': {
    title: 'Negotiating and signing an office agreement',
    description:
      'See how to negotiate commercial office space agreements and what to look out for when reviewing the terms of the lease.'
  },
  '/playbook/traditional-offices': {
    title: 'Negotiating and signing a traditional office lease',
    description: 'Understand how to negotiate and sign an office space under the traditional lease model.'
  },
  '/playbook/term-sheets-and-leases': {
    title: 'Term sheets and leases of traditional offices',
    description:
      'Learn the differences between term sheets and leases, and why your broker might recommend a simpler version.'
  },
  '/playbook/lease-terms-for-negotiation': {
    title: 'Negotiating lease terms of traditional office spaces',
    description: 'Find out the things to consider when negotiating the lease terms of your traditional office space.'
  },
  '/playbook/lease-terms-to-avoid': {
    title: 'Office lease terms to avoid for businesses',
    description:
      'See the lease terms to avoid when negotiating a commercial office lease and how to provide an alternative.'
  },
  '/playbook/unwanted-negotiation': {
    title: 'Methods of breaking an office lease',
    description: 'Discover how to navigate breaking a lease to minimize the impact on you and your business.'
  },
  '/playbook/coworking-offices': {
    title: 'Negotiating and agreeing to a coworking office space',
    description: 'Understand how to negotiate and sign up for an office space under the coworking model.'
  },
  '/playbook/carving-out-new-space': {
    title: 'Customizing a coworking space to your business needs',
    description:
      "Learn what to do after finding a coworking space and how you can customize it to match you and your team's needs."
  },
  '/playbook/what-to-remember': {
    title: 'Negotiating an agreement for a coworking office space',
    description:
      'Know the three things to remember when working with a coworking company during the membership agreement phase.'
  },
  '/playbook/growth-1': {
    title: 'Managing business growth in a coworking office space',
    description: 'See how to manage company growth when working within the coworking office environment.'
  },
  '/playbook/flex-offices': {
    title: 'Negotiating and agreeing to flexible office space',
    description: 'Understand how to negotiate and sign up for an office space under the flexible lease model.'
  },
  '/playbook/signing-up-flex-offices': {
    title: 'Negotiating lease terms of a flexible office space',
    description: 'See the elements you need to finalize when signing up for a flexible office lease.'
  },
  '/playbook/growth-2': {
    title: 'Managing business growth in a flexible office space',
    description: 'See how to manage company growth when working under a flexible office lease.'
  },
  '/playbook/moving-in': {
    title: 'Moving into and managing office spaces',
    description: 'Learn more about the steps needed to move in and manage offices under all lease models.'
  },
  '/playbook/the-traditional-office': {
    title: 'Moving into and managing a traditional office space',
    description: 'Learn more about the steps needed to move in and manage an office under a traditional lease.'
  },
  '/playbook/now-what': {
    title: 'Customizing a traditional office space to your needs',
    description:
      "Understand how to review your traditional lease to determine what elements of your office can be customized to fit your company's needs."
  },
  '/playbook/before-team-arrives': {
    title: 'Setting up a traditional office space ',
    description:
      'See the core features of a traditional office that will need to be arranged before your team can start working onsite.'
  },
  '/playbook/the-coworking-office': {
    title: 'Moving into and managing a coworking office space',
    description: 'Learn more about the steps needed to move in and manage an office within a coworking company.'
  },
  '/playbook/designing-your-space': {
    title: 'Customizing a coworking office space',
    description: "See how you can design your coworking space to fit your company's needs."
  },
  '/playbook/the-flex-office': {
    title: 'Moving into and managing a flexible office space',
    description: 'Learn more about the steps needed to move in and manage an office under a flexible lease.'
  },
  '/playbook/organizing-new-office': {
    title: 'Organizing your new flexible office space',
    description:
      'Discover the key elements to address when getting ready to open your new flexible office for the first time.'
  },
  '/playbook/day-to-day': {
    title: 'Managing your new flexible office space ',
    description: 'Discover the key elements to address when managing your new flexible office on a day-to-day basis.'
  },
  '/playbook/all-in-the-details': {
    title: 'Maximizing the benefit of your flexible office space',
    description:
      'Learn how to transform your flexible office space to maximize the experience and ensure a happy team environment.'
  },
  '/playbook/handy-tools': {
    title: 'Tools to help you choose the right office space ',
    description:
      'Check out the essential tools and processes for choosing the right office lease model for your company and growth trajectory.'
  },
  '/playbook/closing-thoughts': {
    title: 'Cultivating the right culture in your office space ',
    description:
      'Understand why culture is the most important element of any office and how to avoid common pitfalls associated with opening an office.'
  },
  '/playbook/comparison': {
    title: 'Comparing traditional, coworking and flexible offices',
    description:
      'Compare the pros and cons of opening an office under a traditional, coworking, or flexible leasing model.'
  },
  '/playbook/decision-tree': {
    title: 'Choosing a traditional, coworking or flexible office',
    description:
      'Discover a handy decision tree that you can use when trying to understand which office space leasing model is right for you.'
  },
  '/playbook/about-codi': {
    title: "Learn about Codi's flexible office spaces",
    description:
      'Discover Codi and how we are making finding, leasing and opening an office space faster and easier than ever before.'
  }
};
