import React from 'react';

import { HEADER_Z_INDEX, Layout } from 'ds';
import { useListFocus } from 'hooks';

import LocationResults from './LocationResults';
import { Result } from './types';

interface Props {
  results: Result[];
  searchValue: string;
  onLocationSelect: (result: Result | null) => void;
}

const SearchResults: React.FC<Props> = ({ results, onLocationSelect, searchValue }) => {
  const [focusedIndex] = useListFocus({
    divId: 'SearchInput',
    autoSelectFirstItem: searchValue.length > 0,
    size: results.length,
    handleEnter: index => {
      onLocationSelect(results[index]);
    }
  });

  return (
    <Layout>
      <Layout
        position="absolute"
        zIndex={HEADER_Z_INDEX + 1}
        boxShadow="2px 0px 22px 0px #57496D1F"
        borderRadius={24}
        overflow="hidden"
        color="white"
        width="100%"
        __className="HomeSearchInput-locationResults"
      >
        <LocationResults
          key={results.length}
          size="lg"
          results={results}
          onLocationSelect={onLocationSelect}
          searchValue={searchValue}
          isBottomSheet={false}
          focusedIndex={focusedIndex}
        />
      </Layout>
    </Layout>
  );
};

export default SearchResults;
