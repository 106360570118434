import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

export interface Props {
  title: string;
  omitTitleSuffix?: boolean;
  description?: string;
  keywords?: string;
  ogDescription?: string;
  ogTitle?: string;
}

const Head: React.FC<PropsWithChildren<Props>> = ({
  title,
  omitTitleSuffix,
  description,
  keywords,
  ogDescription,
  ogTitle,
  children
}) => {
  const titleWithSuffix = title.endsWith(' | Codi') || omitTitleSuffix ? title : `${title} | Codi`;

  const { origin, pathname } = window.location;
  // NOTE: this removes trailing slashes
  const canonicalUrl = `${origin}${pathname}`.replace(/\/$/, '');

  return (
    // @ts-expect-error
    <Helmet>
      <title>{titleWithSuffix}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      <link rel="canonical" href={canonicalUrl} />
      {children}
    </Helmet>
  );
};

export default Head;
