import React, { useContext } from 'react';
import { formatDate } from '@codiwork/codi';

import { media } from 'context';
import { BOOKING_EMAIL, Icon, Layout, Link, Text } from 'ds';
import { formatUsd } from 'helpers';
import { SaveableShortTermBooking } from 'ux/Admin/ShortTermBookings/types';

interface Props extends Required<Pick<SaveableShortTermBooking, 'start_date' | 'end_date' | 'amount'>> {}

const STBRequestCardInfo: React.FC<Props> = ({ start_date, end_date, amount }) => {
  const { xs } = useContext(media);
  const isSingleDay = start_date === end_date;
  const dateFormat = isSingleDay && !xs ? 'MED_WITH_YEAR' : 'MED';

  return (
    <>
      <Layout marginTop={24} align="center" justify="space-between">
        <Layout align="center">
          <Icon name="calendar" size="xs" color="gray-900" />
          <Layout margin={8}>
            <Text size={xs ? 'body3' : 'body2'} semibold>
              Reservation
            </Text>
          </Layout>
        </Layout>
        <Text size={xs ? 'body3' : 'body2'}>
          {formatDate({ date: start_date, format: dateFormat })}
          {isSingleDay ? '' : ` – ${formatDate({ date: end_date, format: dateFormat })}`}
        </Text>
      </Layout>
      <Layout {...(xs ? { marginTop: 16, paddingTop: 16 } : { marginTop: 24, paddingTop: 24 })} borderTop>
        <Layout justify="space-between">
          <Text size="body1" semibold>
            Total
          </Text>
          <Text size="body1" semibold>
            {formatUsd(amount)}
          </Text>
        </Layout>
      </Layout>
      <Layout borderRadius={8} color="blue-25" padding={12} marginTop={xs ? 16 : 24}>
        <Text size="body3">
          Each office comes fully furnished with high-speed WiFi and includes one cleaning after the booking has ended.
          For additional cleaning or add-on requests, email us at{' '}
          <Link size="body3" color="blue-500" semibold={false} href={`mailto:${BOOKING_EMAIL}`} target="_blank">
            {BOOKING_EMAIL}
          </Link>
          .
        </Text>
      </Layout>
    </>
  );
};

export default STBRequestCardInfo;
