import React from 'react';

import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import Avatar from './Avatar';
import Layout from './Layout';
import Text from './Text';
import Tooltip from './Tooltip';
import { namePartsToFirstLastInitial } from './utils';

export const AVATAR_SIZE = 32;
export const MARGIN_LEFT = 8;
export const AVATAR_OVERLAP = 8;
const ADDITIONAL_AVATARS_MARGIN = 8;

type Props = {
  maxAvatar?: number;
  avatarSize?: number;
  marginLeft?: number;
} & (
  | {
      users: {
        id: number;
        avatar_url: string;
        firstname: string | null;
        lastname: string | null;
        email?: string | null;
      }[];
      avatarUrls?: never;
    }
  | {
      users?: never;
      avatarUrls: (string | null | undefined)[];
    }
);

const FacePile: React.FC<Props> = ({ maxAvatar, avatarSize = AVATAR_SIZE, marginLeft = MARGIN_LEFT, ...props }) => {
  const users = !!maxAvatar && props.users ? props.users.slice(0, maxAvatar) : props.users || [];
  const rawAvatarUrls = users.length ? users.map(u => u.avatar_url) : props.avatarUrls || [];
  const avatarUrls = maxAvatar === undefined ? rawAvatarUrls : rawAvatarUrls.slice(0, maxAvatar);
  const user = useAppSelector(selectUser);

  return (
    <Layout height={avatarSize} marginLeft={marginLeft} align="center">
      <Layout width={avatarUrls.length * avatarSize - (avatarUrls.length - 1) * AVATAR_OVERLAP} align="center">
        <Layout width={avatarUrls.length * avatarSize - (avatarUrls.length - 1) * AVATAR_OVERLAP} align="center">
          {users.length
            ? users.map(({ id, avatar_url, firstname, lastname, email }, idx) => {
                return (
                  <Layout key={idx} position="relative" left={idx * -AVATAR_OVERLAP} zIndex={avatarUrls.length - idx}>
                    <Tooltip
                      text={
                        id === user?.id
                          ? 'You'
                          : namePartsToFirstLastInitial({ firstname: firstname || email || null, lastname })
                      }
                      placement="bottom"
                      preventOverflow={false}
                      strategy="fixed"
                    >
                      <Avatar avatarUrl={avatar_url} borderColor="white" borderWidth={1} size={avatarSize} />
                    </Tooltip>
                  </Layout>
                );
              })
            : avatarUrls.map((url, idx) => {
                return (
                  <Layout key={idx} position="relative" left={idx * -AVATAR_OVERLAP} zIndex={avatarUrls.length - idx}>
                    <Avatar avatarUrl={url} borderColor="white" borderWidth={1} size={avatarSize} />
                  </Layout>
                );
              })}
        </Layout>
      </Layout>
      {maxAvatar !== undefined && rawAvatarUrls.length > maxAvatar && (
        <Layout marginLeft={ADDITIONAL_AVATARS_MARGIN}>
          <Text size="body2" color="gray-600" scale>
            +{rawAvatarUrls.length - maxAvatar}
          </Text>
        </Layout>
      )}
    </Layout>
  );
};

export default FacePile;
