import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatAddress, formatPublicAddress } from '@codiwork/codi';
import defaultImage from '@codiwork/codi/lib/assets/default-image.svg';
import classNames from 'classnames';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import { formatUsd } from 'helpers';
import { track } from 'lib/analytics';
import { LISTING_PATH, STB_REQUEST_PATH } from 'routes';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { SwiperSlide } from 'swiper/react';

import Layout from './Layout';
import CardCarousel from './SwiperCarousel/CardCarousel';
import Text from './Text';
import WorkspaceImg from './WorkspaceImg';
import { CardWorkspace } from './types';

export interface Props {
  workspace: CardWorkspace;
  daysPerWeek?: number;
  offsitesOnly?: boolean;
}

const SearchMapWorkspaceCard: React.FC<Props> = ({
  workspace: { short_name, slug, images, name, address, id, square_feet, monthly_price, daily_rate },
  daysPerWeek,
  offsitesOnly
}) => {
  const carouselPhotos = [...images];
  const borderRadius = 16;
  const isLoggedIn = !!useAppSelector(selectUser);

  const pathname = useHistory().location.pathname;
  const price = offsitesOnly ? daily_rate : monthly_price;

  const linkPath = offsitesOnly ? `${STB_REQUEST_PATH}/${id}` : `${LISTING_PATH}/${slug}?days_per_week=${daysPerWeek}`;

  return (
    <Layout
      __className={classNames('WorkspaceCard has-hover-arrows')}
      borderRadius={16}
      overflow="hidden"
      boxShadow="0 13px 23px rgba(0, 0, 0, 0.15)"
      width={264}
    >
      <Link
        key={slug}
        to={{
          pathname: linkPath,
          state: { from: pathname }
        }}
        onClick={() => {
          track('Element Interacted', {
            type: 'link',
            context: 'Workspace map',
            name: name || formatAddress(address),
            value: id || ''
          });
        }}
        target="_blank"
      >
        <Layout color="white" inline>
          <Layout
            position="relative"
            justify="center"
            align="center"
            __style={{
              // Hack for mobile browsers
              WebkitMaskImage: ' -webkit-radial-gradient(white, black)'
            }}
            borderTopLeftRadius={borderRadius}
            borderTopRightRadius={borderRadius}
            flexShrink={0}
            overflow="hidden"
            inline
          >
            <Layout color="blue-50" __style={{ width: 264, height: 152 }} flexShrink={0} inline position="relative">
              {!!images.length ? (
                <CardCarousel>
                  {carouselPhotos.map((photo, index) => (
                    <SwiperSlide key={photo.key}>
                      <WorkspaceImg
                        srcKey={photo.key}
                        size="sm"
                        style={{ width: 264, height: 152, objectFit: 'cover' }}
                        alt={index.toString()}
                      />
                    </SwiperSlide>
                  ))}
                </CardCarousel>
              ) : (
                <Layout height="100%" justify="center" align="center" display="inline-flex" inline>
                  <img src={defaultImage} alt="default" />
                </Layout>
              )}
            </Layout>
          </Layout>
          <Layout direction="column" color="white" justify="space-between" height={120} padding={12} flexGrow={1}>
            <Layout>
              <Text size="body2" color="gray-600" ellipsis>
                {formatPublicAddress(address)}
              </Text>
              <Layout marginTop={4}>
                <Text size="body1" color="gray-900" bold={false} maxLines={2} scale>
                  {short_name || name}
                </Text>
              </Layout>
            </Layout>
            <Layout justify="space-between" marginTop={4}>
              <Text size="body2" color="gray-700" scale>
                {(square_feet || ' ').toLocaleString()}
                {square_feet ? ' sqft' : ''}
              </Text>
              <Layout>
                {isLoggedIn && price && price > 0 && (
                  <>
                    <Text size="body1" color="gray-900" bold inline scale>
                      {offsitesOnly
                        ? formatUsd(price, false)
                        : formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price || 0, daysPerWeek })}
                    </Text>
                    <Text size="body2" color="gray-900" inline scale>
                      {' '}
                      /{offsitesOnly ? 'day' : 'month'}
                    </Text>
                  </>
                )}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Link>
    </Layout>
  );
};

export default SearchMapWorkspaceCard;
