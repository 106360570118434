import React from 'react';

import Billing from './Billing';
import Conversation from './Conversation';
import Fallback from './Fallback';
import OrderCleaning from './OrderCleaning';
import OrderFurniture from './OrderFurniture';
import SupportTicket from './SupportTicket';
import { ChatbotIntentProps } from '../types';

interface Props extends ChatbotIntentProps {}

const ChatbotWidget: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  switch (chatbotInteraction.intent) {
    case 'OrderCleaningIntent':
      return <OrderCleaning chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'OrderFurnitureIntent':
      return <OrderFurniture chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'BillingIntent':
      return <Billing chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'ConversationIntent':
      return <Conversation chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'CleaningFeedbackIntent':
    case 'InternetIntent':
    case 'HvacIntent':
    case 'PestIntent':
    case 'ElectricalIntent':
    case 'PlumberIntent':
    case 'AccessIntent':
    case 'SecurityIntent':
    case 'HandymanIntent':
    case 'ApplianceRepairIntent':
    case 'SupportTicketIntent':
      return <SupportTicket chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    default:
      return <Fallback chatbotInteraction={chatbotInteraction} threadId={threadId} />;
  }
};

export default ChatbotWidget;
