import React, { useContext, useState } from 'react';

import { media } from 'context';
import { BOTTOM_NAVIGATION_HEIGHT, Icon, Layout, Link, NavItem, Text } from 'ds';

import { NAV_ICON_CONFIG } from './constants';

export interface Props extends Omit<NavItem, 'key'> {
  theme: 'light' | 'dark';
  isActive: boolean;
  displayDot?: boolean;
  isExpanded?: boolean;
  index?: number;
}

const NavIcon: React.FC<Props> = ({
  isActive,
  href,
  theme,
  iconName,
  label,
  onClick,
  displayDot,
  isExpanded,
  index
}) => {
  const {
    hoverColor,
    activeColor,
    activeHoverColor,
    iconColor,
    hoverIconColor,
    iconActiveColor,
    mouseDownColor,
    mouseDownIconColor
  } = NAV_ICON_CONFIG[theme];
  const { isMobile } = useContext(media);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const textIconColor = isActive ? iconActiveColor : mouseDown ? mouseDownIconColor : iconColor;

  return (
    <Link href={href} name={JSON.stringify(label)} opacity={false} display="block" onClick={onClick}>
      {isMobile ? (
        <Layout inline paddingTop={8} align="center" direction="column" height={BOTTOM_NAVIGATION_HEIGHT}>
          <Layout position="relative" justify="center" align="center">
            <Icon name={iconName} size="lg" {...(isActive ? { color: 'blue-500' } : { color: iconColor })} />
            {!!displayDot && (
              <Layout
                position="absolute"
                top={0}
                right={-4}
                width={8}
                height={8}
                borderRadius="circular"
                color="blue-500"
              />
            )}
          </Layout>
          <Layout marginTop={4}>
            <Text size="tag" color={isActive ? 'blue-500' : 'gray-500'} bold scale>
              {label}
            </Text>
          </Layout>
        </Layout>
      ) : (
        <Layout
          {...(isMobile ? {} : isExpanded ? { padding: 8 } : { padding: 8 })}
          color={isActive ? activeColor : mouseDown ? mouseDownColor : undefined}
          align="center"
          borderRadius={8}
          justify="flex-start"
          hoverColor={isMobile || mouseDown ? undefined : isActive ? activeHoverColor : hoverColor}
          hoverIconColor={mouseDown || isActive ? undefined : hoverIconColor}
          hoverTextColor={mouseDown || isActive ? undefined : hoverIconColor}
          onMouseDown={() => setMouseDown(true)}
          onMouseUp={() => setMouseDown(false)}
          onMouseLeave={() => setMouseDown(false)}
          inline
        >
          <Layout position="relative" justify="center" align="center">
            <Icon name={iconName} size="sm" color={textIconColor} />
            {!!displayDot && !isExpanded && (
              <Layout
                position="absolute"
                top={-4}
                right={-4}
                width={12}
                height={12}
                borderRadius="circular"
                borderWidth={2}
                borderColor={isActive ? activeColor : 'blue-25'}
                color="blue-500"
              />
            )}
          </Layout>
          {isExpanded && (
            <Layout
              marginLeft={12}
              __className="NavIcon-label"
              __style={index ? { animationDuration: '120ms' } : {}}
              align="center"
            >
              <Text size="body3" color={textIconColor} ellipsis>
                {label}
              </Text>
              {!!displayDot && !!isExpanded && (
                <Layout marginLeft={6} width={8} height={8} borderRadius="circular" color="blue-500" />
              )}
            </Layout>
          )}
        </Layout>
      )}
    </Link>
  );
};

export default NavIcon;
