import React, { useRef, useState } from 'react';
import { IconName, IconSize, IconStroke } from '@codiwork/codi';
import classNames from 'classnames';

import { Type as IconButtonType } from 'ds/IconButton';
import { track } from 'lib/analytics';

import Icon from './Icon';
import IconButton from './IconButton';
import Layout from './Layout';
import Link from './Link';
import Text from './Text';
import { DS_CLASS_NAME, HEADER_Z_INDEX } from './constants';
import { useOnClickOutside } from './helpers';

export type EllipsisMenuItem = {
  label: string;
  icon?: IconName;
  iconElement?: JSX.Element;
} & (
  | {
      onClick: VoidFunction;
      href?: never;
    }
  | {
      href: string;
      onClick?: never;
    }
);

export interface Props {
  buttonType?: IconButtonType;
  size?: 'sm' | 'md' | 'lg';
  iconSize?: IconSize;
  iconStroke?: IconStroke;
  iconPosition?: 'left' | 'right';
  menuItems: EllipsisMenuItem[];
  disabled?: boolean;
  iconName?: IconName;
  menuItemHoverColor?: 'gray-50' | 'blue-50';
  onMenuChange?: (menuOpen: boolean) => void;
}

const EllipsisMenu: React.FC<Props> = ({
  buttonType = 'noBackground',
  size = 'lg',
  menuItems,
  disabled,
  iconSize = 'xs',
  iconStroke = 1.5,
  iconPosition = 'right',
  iconName = 'ellipsisH',
  menuItemHoverColor = 'blue-50',
  onMenuChange
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, event => {
    if (ref && ref.current && ref.current.contains(event.target as HTMLElement)) {
      return;
    }

    setMenuOpen(false);
    onMenuChange && onMenuChange(false);
  });

  const rect = ref.current?.getBoundingClientRect();
  const alignLeft = rect ? rect.x < window.innerWidth / 3 : false;

  return (
    <Layout __className={classNames(DS_CLASS_NAME, 'EllipsisMenu', `is-size-${size}`)} __ref={ref} position="relative">
      <IconButton
        type={buttonType}
        size={size}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          const newState = !menuOpen;
          setMenuOpen(newState);

          if (onMenuChange) {
            onMenuChange(newState);
          }
        }}
        name={iconName}
        disabled={disabled}
      />
      {menuOpen && (
        <Layout position="absolute" zIndex={HEADER_Z_INDEX + 1} {...(alignLeft ? { left: 0 } : { right: 0 })}>
          <Layout
            marginTop={4}
            paddingY={12}
            color="white"
            borderRadius={12}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            direction="column"
            flex
          >
            {menuItems.map(({ label, icon, iconElement, href, onClick }, index) => {
              const Content = (
                <Layout
                  paddingY={12}
                  paddingLeft={16}
                  paddingRight={64}
                  align="center"
                  flexGrow={1}
                  {...(iconPosition === 'left'
                    ? { direction: 'row-reverse', justify: 'flex-end' }
                    : { justify: 'space-between' })}
                  __className={href ? `EllipsisMenu-menuItem is-hover-color-${menuItemHoverColor}` : undefined}
                >
                  <Text size="body2" wrap={false}>
                    {label}
                  </Text>
                  {(icon || iconElement) && (
                    <Layout
                      {...(iconPosition === 'left' ? { marginRight: 12 } : { marginLeft: 24 })}
                      display="inline-flex"
                    >
                      {icon ? <Icon name={icon} size={iconSize} stroke={iconStroke} color="black" /> : iconElement}
                    </Layout>
                  )}
                </Layout>
              );

              return onClick ? (
                <button
                  key={index}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    track('Element Interacted', { type: 'button', value: label, name: 'ellipsis' });
                    onClick();
                    setMenuOpen(false);
                    onMenuChange && onMenuChange(false);
                  }}
                  className={classNames('EllipsisMenu-menuItem', `is-hover-color-${menuItemHoverColor}`)}
                  style={{ display: 'block' }}
                >
                  {Content}
                </button>
              ) : (
                <Link
                  key={index}
                  href={href}
                  color="gray-900"
                  onClick={() => {
                    track('Element Interacted', { type: 'link', value: label, name: 'ellipsis' });
                    setMenuOpen(false);
                    onMenuChange && onMenuChange(false);
                  }}
                >
                  {Content}
                </Link>
              );
            })}
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default EllipsisMenu;
