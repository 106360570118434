import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, ButterImg, Button, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import Blob from './Blob';
import { HeroSection } from './types';

interface Props extends HeroSection {}

const Hero: React.FC<Props> = ({
  name,
  title,
  midtitle,
  subtitle,
  image,
  image_xs = {} as any,
  blob,
  blob_xs,
  cta
}) => {
  const mediaContext = useContext(media);
  const { xs, lg } = mediaContext;
  const { maxWidth, width, contentWidth, contentPaddingX } = useContext(app);
  const widthToScale = maxWidth ? Math.min(maxWidth, width) : width;
  const scale1440 = widthToScale / 1440;
  const scale375 = width / 375;
  const imageXs = {
    src: image_xs.src?.length ? image_xs.src : image?.src,
    alt: image_xs.alt?.length ? image_xs.alt : image?.alt,
    margin_top: typeof image_xs.margin_top === 'number' ? image_xs.margin_top : 128,
    position: image_xs.position?.length ? image_xs.position : 'left'
  };
  const label = xs ? image_xs?.label : image?.label;

  return (
    <Layout align="center" direction={xs ? 'column' : undefined}>
      <Animate equalWidth={!xs} marginRight={xs ? undefined : 24}>
        <Layout marginBottom={lg ? 24 : 16}>
          <Text tag="h6" bold={false} scale>
            <span dangerouslySetInnerHTML={{ __html: name }}></span>
          </Text>
        </Layout>
        <Layout marginBottom={lg ? 24 : 16} maxWidth={xs ? undefined : 528}>
          <Text tag={xs ? 'h4' : 'h3'} scale={!xs}>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
          </Text>
        </Layout>
        {!!midtitle && (
          <Layout marginBottom={lg ? 24 : 16} maxWidth={xs ? undefined : 528}>
            <Text tag={xs ? 'h6' : 'h4'} scale={!xs}>
              <span dangerouslySetInnerHTML={{ __html: midtitle }}></span>
            </Text>
          </Layout>
        )}
        <Layout maxWidth={xs ? undefined : 452} marginBottom={lg ? 48 : 24}>
          <Text tag="h6" bold={false} scale>
            <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
          </Text>
        </Layout>
        <Button
          text={cta.text}
          type={cta.type}
          size={cta.size}
          href={!!cta.onClick ? undefined : OFFICE_REQUEST_PATH}
          onClick={cta.onClick}
          fullWidth={xs}
          icon={cta.iconName}
          iconSize={cta.iconSize}
          iconPosition={cta.iconPosition}
          iconStroke={cta.iconStroke}
        />
      </Animate>
      <Layout
        equalWidth={!xs}
        marginLeft={xs ? undefined : 24}
        __style={{ marginTop: xs ? imageXs.margin_top * scale375 : undefined }}
        position="relative"
      >
        {xs ? (
          <Animate position="relative">
            {imageXs.position === 'fullWidth' ? (
              <img
                src={image.src}
                alt={image.alt}
                style={{
                  objectFit: 'cover',
                  position: 'relative',
                  width: `calc(100% + ${contentPaddingX * 2}px)`,
                  left: -contentPaddingX
                }}
              />
            ) : (
              <img
                src={imageXs.src}
                alt={imageXs.alt}
                style={{
                  borderTopLeftRadius: imageXs.position === 'center' || imageXs.position === 'right' ? 24 : 0,
                  borderBottomLeftRadius: imageXs.position === 'center' || imageXs.position === 'right' ? 24 : 0,
                  borderTopRightRadius: imageXs.position === 'center' || imageXs.position === 'left' ? 24 : 0,
                  borderBottomRightRadius: imageXs.position === 'center' || imageXs.position === 'left' ? 24 : 0,
                  boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
                  width: imageXs.position === 'center' ? undefined : contentWidth + contentPaddingX,
                  position: 'relative',
                  left:
                    imageXs.position === 'center'
                      ? undefined
                      : (contentPaddingX / 2) * (imageXs.position === 'right' ? 1 : -1)
                }}
              />
            )}
            {label ? (
              <span
                style={{
                  position: 'absolute',
                  padding: '8px 12px',
                  left: imageXs.position === 'left' ? 12 : undefined,
                  right: imageXs.position === 'left' ? undefined : 12,
                  bottom: 24,
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderRadius: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)'
                }}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            ) : null}
          </Animate>
        ) : (
          <Animate position="relative" delay={150}>
            <ButterImg
              progressiveJpg={false}
              width={1600}
              src={image.src}
              alt={image.alt}
              style={{ borderRadius: 44, boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)' }}
            />
            {label ? (
              <span
                style={{
                  position: 'absolute',
                  padding: '8px 12px',
                  right: 28,
                  bottom: 28,
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderRadius: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)'
                }}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            ) : null}
          </Animate>
        )}
        {blob && !xs && <Blob {...blob} scale={scale1440} />}
        {blob_xs && xs && <Blob {...blob_xs} scale={scale375} />}
      </Layout>
    </Layout>
  );
};

export default Hero;
