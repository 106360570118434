import React from 'react';

import { ButterImg, Layout, Link, Text } from 'ds';
import { ButterLocation } from 'lib/butter/types';
import { generateLocationPath } from 'ux/Public/utils';

interface Props {
  locations: ButterLocation[];
  closeMenu: VoidFunction;
}

const Locations: React.FC<Props> = ({ locations, closeMenu }) => {
  return (
    <Layout>
      <Layout paddingBottom={16} borderColor="gray-50" borderBottom>
        <Text size="body3" bold>
          TOP CITIES
        </Text>
      </Layout>
      {locations.map(({ name, region, country, thumbnail_image }, index) => {
        return (
          <Layout key={name} __className="Navigation-mobileMenuSection" __style={{ animationDelay: `${index * 40}ms` }}>
            <Link
              href={generateLocationPath({
                location: name,
                region: region,
                country: country
              })}
              onClick={closeMenu}
              opacity={false}
              fullHeight
              fullWidth
            >
              <Layout paddingY={16} align="center">
                <ButterImg
                  width={100}
                  src={thumbnail_image}
                  alt={name}
                  style={{ width: 52, height: 52, objectFit: 'cover', borderRadius: 12 }}
                />
                <Layout marginTop={8} marginLeft={20}>
                  <Text size="body2" color="black" bold>
                    {name}, {region}
                  </Text>
                </Layout>
              </Layout>
            </Link>
          </Layout>
        );
      })}
    </Layout>
  );
};

export default Locations;
