import React, { useContext } from 'react';

import HeroImage from 'components/HeroImage';

import { media } from 'context';
import { Button, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {}

const Try: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <HeroImage
      imageKey="kearny-office_3vKbg3u0m.jpeg"
      imageKitFolder="public"
      height={xs ? 500 : 568}
      filter="0deg, rgba(65, 67, 74, 0.3), rgba(65, 67, 74, 0.3)"
      accountForPaddingX={false}
      overlay
    >
      <Layout direction="column" align="center" justify="center" height="100%">
        <Text size={xs ? 'h5' : 'h2'} color="white">
          Try Codi for your team
        </Text>
        <Layout marginTop={xs ? 24 : 36}>
          <Button text="Request an office" size={xs ? 'sm' : 'lg'} type="primary" href={OFFICE_REQUEST_PATH} />
        </Layout>
      </Layout>
    </HeroImage>
  );
};

export default Try;
