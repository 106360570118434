import React, { Children, PropsWithChildren, useContext } from 'react';

import { app } from 'context';
import { HEADER_Z_INDEX, Layout } from 'ds';

import CaseStudyFooter from './CaseStudyFooter';
import { CaseStudyPageType } from './types';

interface Props {
  pageType: CaseStudyPageType;
  setOffsiteRequestFormIsVisible: (isVisible: boolean) => void;
}

const CaseStudyContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  pageType,
  setOffsiteRequestFormIsVisible
}) => {
  const { navBarHeight } = useContext(app);
  const [navigation, content, companyInfo] = Children.toArray(children);

  switch (pageType) {
    case 'phone':
      return (
        <Layout>
          {navigation}
          <Layout marginTop={24} paddingX={16}>
            {companyInfo}
          </Layout>
          <Layout paddingX={16}>{content}</Layout>
          <Layout marginTop={120}>
            <CaseStudyFooter setOffsiteRequestFormIsVisible={setOffsiteRequestFormIsVisible} />
          </Layout>
        </Layout>
      );
    case 'top-nav':
      return (
        <>
          {navigation}
          <Layout marginTop={24}>{companyInfo}</Layout>
          <Layout marginTop={40}>{content}</Layout>
          <Layout marginTop={120}>
            <CaseStudyFooter setOffsiteRequestFormIsVisible={setOffsiteRequestFormIsVisible} />
          </Layout>
        </>
      );
    case 'side-nav':
      return (
        <>
          <Layout marginTop={80} flex>
            <Layout
              position="sticky"
              top={24 + navBarHeight}
              zIndex={HEADER_Z_INDEX - 10}
              __style={{ alignSelf: 'start' }}
            >
              {navigation}
            </Layout>
            <Layout marginX={80}>{content}</Layout>
            <Layout
              position="sticky"
              top={24 + navBarHeight}
              zIndex={HEADER_Z_INDEX - 10}
              __style={{ alignSelf: 'start' }}
            >
              {companyInfo}
            </Layout>
          </Layout>
          <Layout marginTop={120}>
            <CaseStudyFooter setOffsiteRequestFormIsVisible={setOffsiteRequestFormIsVisible} />
          </Layout>
        </>
      );
  }
};

export default CaseStudyContainer;
