import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, Layout, Text } from 'ds';

import { Amenity as AmenityType } from './types';

interface Props extends AmenityType {}

const Amenity: React.FC<Props> = ({ margin_top, text, blob, blob_xs, image }) => {
  const mediaContext = useContext(media);
  const { xs } = mediaContext;
  const { maxWidth, width, contentWidth, contentPaddingX } = useContext(app);

  const widthToScale = maxWidth ? Math.min(maxWidth, width) : width;
  const scale1440 = widthToScale / 1440;
  const scale375 = width / 375;
  const scale = xs ? scale375 : scale1440;
  const marginTop = typeof margin_top === 'number' ? margin_top : 144;
  const isImageLeft = image.position === 'left';
  const isImageRight = image.position === 'right';

  return (
    <Layout __style={{ marginTop: xs ? 96 * scale375 : marginTop * scale1440 }}>
      <Layout position="relative">
        {xs ? (
          <span
            style={{
              position: 'absolute',
              width: blob_xs.width * scale375,
              top: typeof blob_xs.top === 'number' ? blob_xs.top * scale375 : undefined,
              left: typeof blob_xs.left === 'number' ? blob_xs.left * scale375 : undefined,
              zIndex: -1
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: blob_xs.svg }} />
          </span>
        ) : (
          <span
            style={{
              position: 'absolute',
              width: blob.width * scale,
              top: typeof blob.top === 'number' ? blob.top * scale : undefined,
              left: typeof blob.left === 'number' ? blob.left * scale : undefined,
              zIndex: -1
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: blob.svg }} />
          </span>
        )}
        {xs ? (
          <Animate position="relative">
            <img
              src={image.src}
              alt={image.alt}
              style={{
                borderTopLeftRadius: isImageRight ? 24 : 0,
                borderBottomLeftRadius: isImageRight ? 24 : 0,
                borderTopRightRadius: isImageLeft ? 24 : 0,
                borderBottomRightRadius: isImageLeft ? 24 : 0,
                boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
                width: contentWidth + contentPaddingX,
                position: 'relative',
                left: isImageRight ? contentPaddingX / 2 : -contentPaddingX
              }}
            />
          </Animate>
        ) : (
          <Animate
            __style={{
              marginLeft: isImageLeft ? undefined : scale * 320,
              marginRight: isImageLeft ? scale * 320 : undefined
            }}
          >
            <img
              src={image.src}
              alt={image.alt}
              style={{ boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)', borderRadius: 44 }}
            />
          </Animate>
        )}
        {xs ? (
          <Animate
            position="relative"
            top={xs ? -48 : text.top ? text.top * scale : 120}
            left={isImageLeft ? contentPaddingX / 2 : -contentPaddingX}
            __style={{
              width: xs ? contentWidth + 24 : 500 * scale,
              borderTopLeftRadius: isImageLeft ? 24 : 0,
              borderBottomLeftRadius: isImageLeft ? 24 : 0,
              borderTopRightRadius: isImageRight ? 24 : 0,
              borderBottomRightRadius: isImageRight ? 24 : 0
            }}
            color="white"
            boxShadow="0px 13px 23px rgba(0, 0, 0, 0.05)"
            padding={16}
          >
            <Layout>
              <Text size="h4" scale>
                {text.title}
              </Text>
            </Layout>
            <Layout marginTop={16}>
              <Text size="body1" scale>
                {text.subtitle}
              </Text>
            </Layout>
          </Animate>
        ) : (
          <Animate
            position="absolute"
            top={text.top ? text.top * scale : 120}
            left={isImageLeft ? undefined : 0}
            right={isImageLeft ? 0 : undefined}
            __style={{ width: 500 * scale }}
            color="white"
            borderRadius={24}
            boxShadow="0px 13px 23px rgba(0, 0, 0, 0.05)"
            padding={52}
          >
            <Layout>
              <Text size="h4" scale>
                {text.title}
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <Text size="body1" scale>
                {text.subtitle}
              </Text>
            </Layout>
          </Animate>
        )}
      </Layout>
    </Layout>
  );
};

export default Amenity;
