import { useState } from 'react';
import { MaskedNumber, MaskedPattern } from 'imask';

export default function useMask({
  value,
  ...params
}: {
  mask: MaskedPattern<string> | MaskedNumber;
  value: string;
  numberValue?: number | null;
}) {
  params.mask.resolve(value);

  const [mask, _] = useState(params.mask);

  return [mask];
}
