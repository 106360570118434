import React from 'react';
import classNames from 'classnames';

import { Layout } from 'ds';

import { Blob as BlobType, BlobShape } from './types';

import { ReactComponent as Blob1 } from 'assets/images/blobs/1.svg';
import { ReactComponent as Blob10 } from 'assets/images/blobs/10.svg';
import { ReactComponent as Blob11 } from 'assets/images/blobs/11.svg';
import { ReactComponent as Blob2 } from 'assets/images/blobs/2.svg';
import { ReactComponent as Blob3 } from 'assets/images/blobs/3.svg';
import { ReactComponent as Blob4 } from 'assets/images/blobs/4.svg';
import { ReactComponent as Blob5 } from 'assets/images/blobs/5.svg';
import { ReactComponent as Blob6 } from 'assets/images/blobs/6.svg';
import { ReactComponent as Blob7 } from 'assets/images/blobs/7.svg';
import { ReactComponent as Blob8 } from 'assets/images/blobs/8.svg';
import { ReactComponent as Blob9 } from 'assets/images/blobs/9.svg';

interface Props extends BlobType {
  scale: number;
}

const SHAPE_TO_SVG: Record<BlobShape, React.FunctionComponent> = {
  '1': Blob1,
  '2': Blob2,
  '3': Blob3,
  '4': Blob4,
  '5': Blob5,
  '6': Blob6,
  '7': Blob7,
  '8': Blob8,
  '9': Blob9,
  '10': Blob10,
  '11': Blob11
} as const;

const Blob: React.FC<Props> = ({ color, width, svg, shape, scale, top, bottom, left, right, rotation }) => {
  const Svg = shape ? SHAPE_TO_SVG[shape] : null;

  return (
    <Layout
      position="absolute"
      zIndex={-1}
      top={typeof top === 'number' ? top * scale : undefined}
      bottom={typeof bottom === 'number' ? bottom * scale : undefined}
      left={typeof left === 'number' ? left * scale : undefined}
      right={typeof right === 'number' ? right * scale : undefined}
      __style={{
        width: width * scale,
        transform: rotation ? `rotate(${rotation}deg)` : undefined
      }}
    >
      {Svg ? (
        <span className={classNames('Blob', `is-color-${color}`)}>
          <Svg />
        </span>
      ) : svg ? (
        <span dangerouslySetInnerHTML={{ __html: svg }} />
      ) : null}
    </Layout>
  );
};

export default Blob;
