import React from 'react';
import { NotificationCategory, NotificationPreferences, NotificationType } from '@codiwork/codi';

import { Layout, Text } from 'ds';

import PreferenceToggle from './PreferenceToggle';
import { UpdatePreference } from './types';

interface Section {
  notificationCategory: NotificationCategory;
  label: string;
  description: string;
}

const SECTIONS: Section[] = [
  {
    notificationCategory: 'reminders',
    label: 'Session Reminders',
    description: 'Receive reminders about your upcoming sessions.'
  },
  {
    notificationCategory: 'community',
    label: 'Community & Tips',
    description:
      'Receive communication about new Codis, lunch talks, networking events, promotions, and product updates.'
  },
  {
    notificationCategory: 'support',
    label: 'Support',
    description:
      'We may need to contact you about your account, your bookings, legal information and replies to your support requests. For your security, you can’t deactivate email notifications and we may contact you by phone or other means if need be.'
  }
];

const NOTIFICATION_TYPES: NotificationType[] = ['email', 'sms', 'push'];
const NOTIFICATION_TYPE_LABELS: { [key in NotificationType]: string } = {
  email: 'Email',
  sms: 'Text Messages',
  push: 'Push Notifications'
};

interface Props {
  updatePreference: UpdatePreference;
  notificationPreferences: Pick<NotificationPreferences, 'reminders' | 'support' | 'community'>;
}

const Sections: React.FC<Props> = ({ updatePreference, notificationPreferences }) => {
  return (
    <>
      {SECTIONS.map((section, i) => (
        <Layout direction="column" key={`section${i}`} borderTop={!!i} paddingTop={36} paddingBottom={24}>
          <Text size="body1" semibold>
            {section.label}
          </Text>
          <Layout marginTop={4}>
            <Text size="body2" color="gray-400">
              {section.description}
            </Text>
          </Layout>
          <Layout marginTop={16}>
            {NOTIFICATION_TYPES.map((notificationType, j) => {
              const notificationCategory = section.notificationCategory;
              const sectionValues = notificationPreferences[section.notificationCategory];

              return (
                <PreferenceToggle
                  updatePreference={({ notificationType, value }) => {
                    return updatePreference({
                      notificationCategory,
                      value,
                      sectionValues,
                      notificationType
                    });
                  }}
                  key={`section${i}type${j}`}
                  notificationPreferences={notificationPreferences}
                  label={NOTIFICATION_TYPE_LABELS[notificationType]}
                  notificationCategory={notificationCategory}
                  notificationType={notificationType}
                  sectionValues={sectionValues}
                />
              );
            })}
          </Layout>
        </Layout>
      ))}
    </>
  );
};

export default Sections;
