import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import { media } from 'context';
import { Clickable, Icon, Layout, PurpleGradientCircle, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_DESKTOP_WIDTH, CHATBOT_THREADS_SCREEN } from './constants';
import { ChatbotThread } from './types';

interface Props {
  thread: ChatbotThread;
}

const ChatbotHomeRecentMessage: React.FC<Props> = ({ thread: { id: threadId, chatbot_interactions, created_at } }) => {
  const { isMobile } = useContext(media);
  const dispatch = useAppDispatch();
  const navigateToThread = (threadId: number) => {
    dispatch(actions.pushScreen({ value: `${CHATBOT_THREADS_SCREEN}/${threadId}` }));
  };

  return (
    <Clickable
      onClick={() => navigateToThread(threadId)}
      style={{ width: isMobile ? undefined : CHATBOT_DESKTOP_WIDTH - 24 * 2 }}
      fullWidth
    >
      <Layout
        color="white"
        paddingTop={12}
        paddingBottom={16}
        borderRadius={8}
        boxShadow="2px 0 22px 0 rgba(34.1176, 28.6275, 42.7451, 0.1200)"
        paddingX={16}
      >
        <Text size="body-sm" align="left" semibold>
          Recent message
        </Text>
        <Layout align="center" marginTop={12}>
          <PurpleGradientCircle dimension={36} />
          <Layout marginLeft={16} flexGrow={1} minWidth={0}>
            <Text size="body-sm" align="left" ellipsis>
              {chatbot_interactions[0].input}
            </Text>
            <Layout align="center">
              <Text size="body-sm" color="gray-400">
                Codi
              </Text>
              <Layout paddingX={8}>
                <Text size="body-sm" color="gray-400">
                  •
                </Text>
              </Layout>
              <Text size="body-sm" color="gray-400" wrap={false}>
                {DateTime.fromISO(created_at).toRelative()}
              </Text>
            </Layout>
          </Layout>
          <Layout marginLeft={12}>
            <Icon stroke={2} name="rightChevron" color="gray-400" size="xs" />
          </Layout>
        </Layout>
      </Layout>
    </Clickable>
  );
};

export default ChatbotHomeRecentMessage;
