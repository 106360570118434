import React, { useContext, useEffect, useState } from 'react';

import { app } from 'context';
import { Button, HEADER_Z_INDEX, Layout, Text } from 'ds';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import CaseStudyNavigationButton from './CaseStudyNavigationButton';
import CaseStudyStickyFooter from './CaseStudyStickyFooter';
import { CaseStudyPageType } from './types';

interface Props {
  sections: { name: string }[];
  pageType: CaseStudyPageType;
  setOffsiteRequestFormIsVisible: (isVisible: boolean) => void;
}

const CaseStudyNavigation: React.FC<Props> = ({ sections, pageType, setOffsiteRequestFormIsVisible }) => {
  const { setOverflowX, maxContentWidth, contentPaddingX, navBarHeight } = useContext(app);
  const [xsHeaderHeight, setXsHeaderHeight] = useState<number>(0);

  useEffect(() => {
    if (!setOverflowX) return;

    setOverflowX('visible');

    return () => {
      setOverflowX('hidden');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch (pageType) {
    case 'phone':
      return (
        <>
          <CaseStudyStickyFooter>
            <Button
              text="Book an offsite"
              size="xs"
              type="blueGradient"
              onClick={() => setOffsiteRequestFormIsVisible(true)}
              fullWidth
            />
          </CaseStudyStickyFooter>
          <StickyHeader
            marginTop={12}
            zIndex={HEADER_Z_INDEX - 2}
            top={navBarHeight}
            isScreenWidth
            removePublicHeaderShadow
          >
            <Layout paddingY={12} overflow="scroll" onMeasure={({ height }) => setXsHeaderHeight(height)} flex>
              {sections.map(({ name }, index) => {
                return (
                  <Layout
                    key={name}
                    marginLeft={index ? 8 : 24}
                    marginRight={index === sections.length - 1 ? contentPaddingX : undefined}
                  >
                    <CaseStudyNavigationButton
                      pageType={pageType}
                      name={name}
                      scrollYOffset={-(xsHeaderHeight + 24 + navBarHeight)}
                    />
                  </Layout>
                );
              })}
            </Layout>
          </StickyHeader>
        </>
      );
    case 'top-nav':
      return (
        <StickyHeader
          zIndex={HEADER_Z_INDEX - 1}
          top={navBarHeight}
          justify="center"
          paddingY={24}
          removePublicHeaderShadow
          isScreenWidth
        >
          <Layout
            maxWidth={maxContentWidth}
            align="center"
            justify="space-between"
            flexGrow={1}
            paddingX={contentPaddingX}
          >
            <Layout flex>
              {sections.map(({ name }, index) => {
                return (
                  <Layout key={name} marginLeft={index ? 8 : undefined}>
                    <CaseStudyNavigationButton pageType={pageType} name={name} />
                  </Layout>
                );
              })}
            </Layout>
            <Button
              text="Book an offsite"
              size="sm"
              type="blueGradient"
              onClick={() => setOffsiteRequestFormIsVisible(true)}
            />
          </Layout>
        </StickyHeader>
      );
    case 'side-nav':
      return (
        <Layout width={200}>
          <Button
            text="Book an offsite"
            size="sm"
            type="blueGradient"
            onClick={() => setOffsiteRequestFormIsVisible(true)}
            fullWidth
          />
          <Layout marginTop={24} paddingX={16} paddingY={6}>
            <Text size="body3" color="gray-400" semibold>
              ON THIS PAGE
            </Text>
          </Layout>
          {sections.map(({ name }) => {
            return (
              <Layout key={name} marginTop={24}>
                <CaseStudyNavigationButton pageType={pageType} name={name} />
              </Layout>
            );
          })}
        </Layout>
      );
  }
};

export default CaseStudyNavigation;
