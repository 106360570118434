import React from 'react';

import Icon from './Icon';
import Layout from './Layout';
import Text from './Text';

interface Props {}

const HotTag: React.FC<Props> = () => {
  return (
    <Layout paddingY={4} color="red-100" borderRadius={4} paddingX={10} align="center">
      <Icon name="flame" color="red-700" size="xs" />
      <Layout marginLeft={4} display="inline-flex">
        <Text size="body-xs" color="red-700" semibold>
          HOT
        </Text>
      </Layout>
    </Layout>
  );
};

export default HotTag;
