import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { media } from 'context';
import { CASE_STUDY_SLUGS_WITH_TITLES } from 'routes';
import Page404 from 'ux/Page/404';

import TaskRabbit from './TaskRabbit';

interface Props extends RouteComponentProps<RouteParams> {}

interface RouteParams {
  slug: string;
}

const CaseStudies: React.FC<Props> = ({ match }) => {
  const slug = match.params.slug.replace(/#.+/, '');
  const caseStudy = CASE_STUDY_SLUGS_WITH_TITLES.find(cs => cs.slug === slug);
  const { xs, xl } = useContext(media);

  if (!caseStudy) {
    return <Page404 />;
  }

  const pageType = xl ? 'side-nav' : xs ? 'phone' : 'top-nav';

  switch (caseStudy.slug) {
    case 'taskrabbit':
      return <TaskRabbit pageType={pageType} title={caseStudy.title} description={caseStudy.description} />;
    default:
      return <Page404 />;
  }
};

export default CaseStudies;
