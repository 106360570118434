import React from 'react';
import { useSelector } from 'react-redux';

import Snackbar from 'ds/Snackbar';
import { getSnackbarMessages } from 'store/UI/selectors';

interface Props {}

const SnackbarContainer: React.FC<Props> = () => {
  const snackbarMessages = useSelector(getSnackbarMessages);

  return (
    <>
      {snackbarMessages.map((snackbarMessage, index) => {
        return <Snackbar key={snackbarMessage.id} {...snackbarMessage} index={index} />;
      })}
    </>
  );
};

export default SnackbarContainer;
