import React, { PropsWithChildren, useContext } from 'react';

import { media } from 'context';
import { IconButton, Layout, SCROLLABLE_BOX_SHADOW } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_NAVIGATION_HEADER_HEIGHT } from './constants';

interface Props {
  backScreenValue: string;
  showBackButton?: boolean;
  hasShadow?: boolean;
}

const ChatbotNavigationHeader: React.FC<PropsWithChildren<Props>> = ({
  backScreenValue,
  children,
  showBackButton = true,
  hasShadow = false
}) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useContext(media);

  return (
    <Layout
      height={CHATBOT_NAVIGATION_HEADER_HEIGHT}
      color="blue-500"
      align="center"
      paddingX={16}
      paddingY={8}
      zIndex={1}
      boxShadow={hasShadow ? SCROLLABLE_BOX_SHADOW : undefined}
      {...(isMobile ? {} : { borderTopLeftRadius: 8, borderTopRightRadius: 8 })}
    >
      <Layout align="center" flexGrow={1} gap={8}>
        {showBackButton ? (
          <IconButton
            size="lg"
            type="noBackground"
            name="leftChevron"
            iconColor="white"
            onClick={() => dispatch(actions.pushScreen({ value: backScreenValue }))}
            stroke={2}
          />
        ) : isMobile ? (
          <Layout width={48} height={48}></Layout>
        ) : null}
        {children}
      </Layout>
      {isMobile && (
        <IconButton
          size="lg"
          type="noBackground"
          name="close"
          onClick={() => dispatch(actions.setIsVisible(false))}
          iconColor="white"
          stroke={2}
        />
      )}
    </Layout>
  );
};

export default ChatbotNavigationHeader;
