import React, { useContext } from 'react';

import { app, media } from 'context';
import { Icon, Layout, Logo, Text } from 'ds';

interface Props {}

const Compare: React.FC<Props> = () => {
  const { isMobile, xs, sm, md } = useContext(media);
  const isCompact = isMobile || md;
  const { windowWidth, contentPaddingX } = useContext(app);

  return (
    <>
      <Layout {...(isCompact ? { paddingX: 24 } : {})}>
        <Text
          {...(xs ? { size: 'headline-lg' } : { size: sm || md ? 'headline-lg' : 'display-sm', align: 'center' })}
          color="blue-500"
        >
          Expect more from your office
        </Text>
      </Layout>
      {isCompact ? (
        <Layout {...(xs ? { width: windowWidth } : { justify: 'center' })} overflow="scroll" paddingX={contentPaddingX}>
          <Layout
            background="radial-gradient(circle at top right, rgba(255, 255, 255, 0) 0%, rgba(214.62, 230.09, 255, 0.3) 100%)"
            borderRadius={16}
            paddingLeft={24}
            paddingBottom={24}
            position="relative"
            marginTop={24}
            width="fit-content"
            flex
          >
            <Categories />
            <Codi />
            <Layout flexGrow={1} flex>
              <Layout>
                <CoworkingSpaces />
              </Layout>
              <Layout>
                <TraditionalLeasing />
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      ) : (
        <Layout
          background="radial-gradient(circle at top right, rgba(255, 255, 255, 0) 0%, rgba(214.62, 230.09, 255, 0.3) 100%)"
          borderRadius={16}
          paddingLeft={48}
          paddingBottom={24}
          position="relative"
          marginTop={isCompact ? 24 : 48}
          flex
        >
          <Categories />
          <Layout flexGrow={1} flex>
            <Layout {...(isCompact ? {} : { maxWidth: 253 })} equalWidth>
              <CoworkingSpaces />
            </Layout>
            <Layout {...(isCompact ? {} : { maxWidth: 253 })} equalWidth>
              <TraditionalLeasing />
            </Layout>
          </Layout>
          <Layout position="absolute" right={0} top={-14}>
            <Codi />
          </Layout>
        </Layout>
      )}
    </>
  );
};

const Categories: React.FC<Props> = () => {
  const { isMobile, md } = useContext(media);
  const isCompact = isMobile || md;

  return (
    <Layout {...(isCompact ? { width: 140, marginTop: 48, flexShrink: 0 } : { flexShrink: 0, marginTop: 88 })}>
      <Layout paddingY={9}>
        <Text size={isCompact ? 'body-md' : 'headline-xs'} color="blue-600" semibold>
          Fully serviced
        </Text>
        <Layout marginTop={4}>
          <Text size={isCompact ? 'body-xs' : 'body-sm'} color="gray-400">
            Includes rent, furniture, and more
          </Text>
        </Layout>
      </Layout>
      <Layout paddingY={9}>
        <Text size={isCompact ? 'body-md' : 'headline-xs'} color="blue-600" semibold>
          Flexible
        </Text>
        <Layout marginTop={4}>
          <Text size={isCompact ? 'body-xs' : 'body-sm'} color="gray-400">
            Open an office for as little as 6-months
          </Text>
        </Layout>
      </Layout>
      <Layout paddingY={9}>
        <Text size={isCompact ? 'body-md' : 'headline-xs'} color="blue-600" semibold>
          Hybrid
        </Text>
        <Layout marginTop={4}>
          <Text size={isCompact ? 'body-xs' : 'body-sm'} color="gray-400">
            Get a twice-a-week office
          </Text>
        </Layout>
      </Layout>
      <Layout paddingY={9}>
        <Text size={isCompact ? 'body-md' : 'headline-xs'} color="blue-600" semibold>
          Private
        </Text>
        <Layout marginTop={4}>
          <Text size={isCompact ? 'body-xs' : 'body-sm'} color="gray-400">
            Standalone offices on your terms
          </Text>
        </Layout>
      </Layout>
      <Layout paddingY={9}>
        <Text size={isCompact ? 'body-md' : 'headline-xs'} color="blue-600" semibold>
          Scalable
        </Text>
        <Layout marginTop={4}>
          <Text size={isCompact ? 'body-xs' : 'body-sm'} color="gray-400">
            Open an office anywhere as you grow
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};
const Check: React.FC<Props> = () => {
  return (
    <Layout width={32} height={32} color="blue-500" align="center" justify="center" borderRadius="circular">
      <Icon name="checkmark" size="xs" color="white" />
    </Layout>
  );
};

const Minus: React.FC<Props> = () => {
  return (
    <Layout width={32} height={32} color="blue-200" align="center" justify="center" borderRadius="circular">
      <Icon name="minus" size="xs" color="white" />
    </Layout>
  );
};

const CoworkingSpaces: React.FC<Props> = () => {
  const { isMobile, md } = useContext(media);
  const isCompact = isMobile || md;

  return (
    <Layout {...(isCompact ? { width: 180, flexShrink: 0 } : {})} direction="column" align="center">
      <Layout {...(isCompact ? { marginTop: 12 } : { marginTop: 24 })} paddingY={isCompact ? 9 : 14}>
        <Text size={isCompact ? 'headline-xs' : 'headline-sm'} color="blue-600" wrap={false}>
          Coworking spaces
        </Text>
      </Layout>
      <Layout paddingY={16} {...(isCompact ? { marginTop: 12 } : {})} marginTop={12}>
        <Check />
      </Layout>
      <Layout paddingY={16} {...(isCompact ? { marginTop: 12 } : {})}>
        <Check />
      </Layout>
      <Layout paddingY={16} {...(isCompact ? { marginTop: 12 } : {})}>
        <Minus />
      </Layout>
      <Layout paddingY={16} {...(isCompact ? { marginTop: 12 } : {})}>
        <Minus />
      </Layout>
      <Layout paddingY={16} {...(isCompact ? { marginTop: 12 } : {})}>
        <Minus />
      </Layout>
    </Layout>
  );
};

const TraditionalLeasing: React.FC<Props> = () => {
  const { isMobile, md } = useContext(media);
  const isCompact = isMobile || md;

  return (
    <Layout {...(isCompact ? { width: 180, flexShrink: 0 } : {})} direction="column" align="center">
      <Layout {...(isCompact ? { marginTop: 12 } : { marginTop: 24 })} paddingY={isCompact ? 9 : 14}>
        <Text size={isCompact ? 'headline-xs' : 'headline-sm'} color="blue-600" wrap={false}>
          Traditional leasing
        </Text>
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : { marginTop: 12 })} paddingY={16}>
        <Minus />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Minus />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Minus />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Check />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Minus />
      </Layout>
    </Layout>
  );
};

const Codi: React.FC<Props> = () => {
  const { isMobile, md } = useContext(media);
  const isCompact = isMobile || md;

  return (
    <Layout
      direction="column"
      align="center"
      {...(isCompact
        ? { width: 180, flexShrink: 0, marginTop: 8 }
        : {
            background: 'linear-gradient(0deg, white 0%, white 100%)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 27px, rgba(0, 120, 255, .15) 0px 0px 30px',
            borderRadius: 16,
            paddingY: 38,
            width: 234
          })}
    >
      <Layout {...(isCompact ? { height: 46 } : { height: 52 })} align="center">
        <Layout position="relative" top={2}>
          <Logo size="sm" />
        </Layout>
      </Layout>
      <Layout paddingY={16} {...(isCompact ? { marginTop: 12 } : { marginTop: 12 })}>
        <Check />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Check />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Check />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Check />
      </Layout>
      <Layout {...(isCompact ? { marginTop: 12 } : {})} paddingY={16}>
        <Check />
      </Layout>
    </Layout>
  );
};

export default Compare;
