import React, { PropsWithChildren, useRef, useState } from 'react';
import { IconName } from '@codiwork/codi';

import Animate from './Animate';
import Button from './Button';
import ButtonPrivate from './ButtonPrivate';
import Layout from './Layout';
import TextButton from './TextButton';
import { HEADER_Z_INDEX } from './constants';
import { FIELD_HEIGHT_SM } from './constants';
import { useOnClickOutside } from './helpers';

export interface Props {
  icon: IconName;
  buttonText?: string;
  isDefaultSelected: boolean;
  initialState?: 'open' | 'closed';
  width?: number;
  disabled?: boolean;
  onClear?: VoidFunction;
  align?: 'left' | 'right';
  onClickOutside?: VoidFunction;
  onSubmit?: VoidFunction;
  doneDisabled?: boolean;
}

const BUTTON_CONFIG = {
  borderRadius: 8,
  paddingX: 16,
  height: 48,
  iconGutter: 8
} as const;

type State = 'open' | 'closed';

const FilterContainer: React.FC<PropsWithChildren<Props>> = ({
  icon,
  buttonText,
  children,
  isDefaultSelected,
  initialState = 'closed',
  width,
  disabled,
  onClear,
  align = 'left',
  onClickOutside,
  onSubmit,
  doneDisabled
}) => {
  const [state, setState] = useState<State>(initialState);
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    if (state === 'closed') return;

    setState('closed');
    onClickOutside && onClickOutside();
  });

  const activeColor = isDefaultSelected ? 'blue-50' : 'blue-200';
  const color =
    state === 'open' ? (isDefaultSelected ? 'blue-50' : 'blue-200') : isDefaultSelected ? 'white' : 'blue-50';
  const textColor = isDefaultSelected ? 'gray-900' : 'blue-500';

  return (
    <Layout __ref={containerRef} position="relative" display="inline-flex">
      <ButtonPrivate
        {...BUTTON_CONFIG}
        iconStroke={1.5}
        semibold={!isDefaultSelected}
        textColor={textColor}
        color={color}
        activeColor={activeColor}
        hoverColor={state === 'open' ? color : isDefaultSelected ? 'gray-50' : 'blue-100'}
        text={buttonText}
        textSize="body2"
        disabled={disabled}
        onClick={() => {
          if (state === 'closed') {
            setState('open');
          } else {
            setState('closed');
          }
        }}
        activeBorderColor={activeColor}
        borderColor={isDefaultSelected ? 'gray-50' : 'transparent'}
        borderWidth={isDefaultSelected && state !== 'open' ? 1 : 1}
        icon={icon}
        iconSize="sm"
        iconPosition="left"
      />
      {state === 'open' && (
        <Animate
          animation="fade-in-from-top"
          position="absolute"
          top={FIELD_HEIGHT_SM + 8}
          {...(align === 'left' ? { left: 0 } : { right: 0 })}
          borderRadius={8}
          borderColor="gray-50"
          borderWidth={1}
          boxShadow="2px 0 22px 0 rgba(34.1176, 28.6275, 42.7451, 0.1200)"
          padding={16}
          zIndex={HEADER_Z_INDEX + 1}
          width={width}
          color="white"
          duration={240}
          __style={{ whiteSpace: 'nowrap' }}
        >
          {children}
          <Layout justify="space-between" align="center">
            <Button
              size="xxs"
              type="primary"
              text="Done"
              onClick={() => {
                setState('closed');

                onSubmit && onSubmit();
              }}
              disabled={doneDisabled}
            />
            {onClear && (
              <TextButton
                scale
                onClick={onClear}
                color="gray-500"
                text="Reset"
                textSize="body3"
                semibold={false}
                paddingY={false}
              />
            )}
          </Layout>
        </Animate>
      )}
    </Layout>
  );
};

export default FilterContainer;
