import React, { useRef } from 'react';
import { EditableImage } from '@codiwork/codi';

import Clickable from 'ds/Clickable';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text, { Size } from 'ds/Text';

import MessageImageUploadThumbnail from './MessageImageUploadThumbnail';

interface Props {
  images: EditableImage[];
  onChange: (files: File[]) => void;
  onDeleteClick?: (image: EditableImage) => void;
  disabled?: boolean;
  maxImages?: number;
  label?: string;
  labelSize?: Size;
  imageDimension?: number;
}

const MessageImageUploader: React.FC<Props> = ({
  onChange,
  onDeleteClick,
  disabled,
  maxImages = 12,
  label,
  labelSize = 'body2',
  imageDimension = 98,
  ...props
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const showFileChooser = () => hiddenFileInputRef.current?.click();

  const images = props.images.filter(i => !i._destroy);
  const gap = 6;

  return (
    <Layout>
      {images.length ? (
        <Layout flex wrap position="relative" left={-gap} width="calc(100% + 12px)">
          {images.map(image => {
            return (
              <MessageImageUploadThumbnail
                key={image.id || image.url}
                containerProps={{
                  margin: gap,
                  width: imageDimension,
                  height: imageDimension
                }}
                disabled={disabled}
                image={image}
                onDeleteClick={!!onDeleteClick ? () => onDeleteClick(image) : undefined}
              />
            );
          })}
          {images.length < maxImages && (
            <Clickable onClick={showFileChooser} style={{ borderRadius: 8 }} disabled={disabled}>
              <Layout
                align="center"
                justify="center"
                color="gray-25"
                width={imageDimension}
                height={imageDimension}
                borderRadius={8}
              >
                <Icon name="plus" size="md" color="gray-200" />
              </Layout>
            </Clickable>
          )}
        </Layout>
      ) : (
        <Clickable onClick={showFileChooser} style={{ display: 'flex', alignItems: 'center' }} disabled={disabled}>
          <Icon name="attachment" size="xs" color="blue-500" />
          <Text size={labelSize} color="blue-500">
            {label || 'Attach image'}
          </Text>
        </Clickable>
      )}
      <input
        ref={hiddenFileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/png, image/jpg, image/heic"
        multiple={maxImages !== 1}
        onChange={event => {
          const files = event.target.files;

          if (!files) return;

          onChange(Array.from(files).slice(0, maxImages - images.length));
        }}
      />
    </Layout>
  );
};

export default MessageImageUploader;
