import React, { MutableRefObject } from 'react';
import SwiperClass, { A11y } from 'swiper';

import { CardWorkspace } from 'ds';
import { Swiper, SwiperSlide } from 'swiper/react';

import SearchWorkspaceCarouselCard from './SearchWorkspaceCarouselCard';

type Props = {
  swiperRef: MutableRefObject<SwiperClass | undefined>;
  onSlideChange: (workspaceId: number) => void;
  daysPerWeek: number;
  offsitesOnly?: boolean;
  ctaCard?: JSX.Element;
  workspaces: CardWorkspace[];
} & {};

const MapCarousel: React.FC<Props> = ({ workspaces, daysPerWeek, offsitesOnly, onSlideChange, swiperRef, ctaCard }) => {
  if (!workspaces.length) return null;

  return (
    <Swiper
      onInit={swiper => {
        swiperRef.current = swiper;
      }}
      modules={[A11y]}
      onSlideChange={({ activeIndex }) => {
        const workspaceId = workspaces[activeIndex]?.id;

        if (!workspaceId) return;

        onSlideChange(workspaceId);
      }}
      spaceBetween={12}
      slidesPerView={1}
      style={{ overflow: 'visible' }}
    >
      {workspaces.map(ws => {
        return (
          <SwiperSlide key={ws.id}>
            <SearchWorkspaceCarouselCard workspace={ws} daysPerWeek={daysPerWeek} offsitesOnly={offsitesOnly} />
          </SwiperSlide>
        );
      })}
      {ctaCard && <SwiperSlide>{ctaCard}</SwiperSlide>}
    </Swiper>
  );
};

export default MapCarousel;
