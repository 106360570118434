import React from 'react';
import Supercluster, { ClusterProperties } from 'supercluster';

import { MapWorkspace } from 'ds/types';
import { track } from 'lib/analytics';

import ClusterMarker from './ClusterMarker';
import MapMarker from './MapMarker';
import { BuildingGroup, MapProps, OnClusterClick } from './types';
import { generateLocationKey } from './utils';

interface Props
  extends Pick<
    MapProps,
    | 'workspaces'
    | 'selectedWorkspaceId'
    | 'offsitesOnly'
    | 'daysPerWeek'
    | 'onMarkerClickCenterChange'
    | 'onMarkerClick'
    | 'markers'
    | 'markerType'
    | 'setSelectedWorkspaceId'
    | 'zoom'
  > {
  useClusters: boolean;
  superCluster: Supercluster<{ workspace: MapWorkspace }, ClusterProperties>;
  clusters: Supercluster.ClusterFeature<{ workspace: MapWorkspace }>[];
  onClusterClick?: OnClusterClick;
  shiftedWorkspaces: MapWorkspace[];
  mapRef: React.MutableRefObject<google.maps.Map | null>;
  workspaceGroups: BuildingGroup[];
  cardWidth?: number;
  cardHeight?: number;
  visitedWorkspaceIds: number[];
}

const MapMarkers = ({
  useClusters,
  clusters,
  superCluster,
  shiftedWorkspaces,
  onClusterClick,
  mapRef,
  workspaceGroups,
  ...props
}: Props) => {
  if (useClusters) {
    return clusters.map(({ properties: { cluster_id, cluster, ...props }, geometry: { coordinates } }) => {
      const [lng, lat] = coordinates;

      const workspace = props.workspace as MapWorkspace;
      const workspaceId = workspace?.id;
      const key = workspaceId ? `workspace${workspaceId}` : `cluster${cluster_id}`;
      const pointCount = cluster && props.point_count ? props.point_count : 1;

      return (
        <ClusterMarker
          key={key}
          count={pointCount}
          lat={lat}
          lng={lng}
          onClick={() => {
            const map = mapRef.current;

            if (!map || !onClusterClick) return;

            const workspaces = cluster_id
              ? superCluster.getLeaves(cluster_id).map(c => c.properties.workspace)
              : [props.workspace as MapWorkspace];

            onClusterClick({ workspaces, map });
          }}
        />
      );
    });
  }

  return [
    ...shiftedWorkspaces.map(workspace => {
      return (
        <MapMarker
          lat={workspace.address.lat}
          lng={workspace.address.lon}
          workspace={workspace}
          key={workspace.id}
          {...props}
          mapRef={mapRef}
        />
      );
    }),
    ...workspaceGroups.map(({ workspaces, lat, lng }) => {
      return (
        <ClusterMarker
          key={generateLocationKey(workspaces[0])}
          count={workspaces.length}
          lat={lat}
          lng={lng}
          onClick={() => {
            const map = mapRef.current;

            if (!map || !onClusterClick) return;

            onClusterClick({ workspaces, map });

            track('Element Interacted', {
              type: 'map',
              action: 'cluster clicked',
              name: 'line1-group',
              value: `${lat},${lng}`
            });
          }}
        />
      );
    })
  ];
};

export default MapMarkers;
