import React from 'react';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';

const LeftArrow: React.FC = () => (
  <Layout
    position="absolute"
    cursor="pointer"
    color="white"
    width={48}
    height={48}
    borderRadius="circular"
    borderWidth={1}
    borderColor="white"
    justify="center"
    align="center"
    top={0}
    left={-290}
  >
    <Icon size="lg" color="black" name="rightChevron" />
  </Layout>
);

export default LeftArrow;
