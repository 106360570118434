import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

import { ReactComponent as Flags } from './flags.svg';

interface Props {}

const RedFlags: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Layout align="center">
          <Text size="h4" color="blue-700">
            Assess whether each property will meet your needs and keep an eye out for any red flags.
          </Text>
          {!xs && (
            <Layout paddingX={36}>
              <Flags width={400} />
            </Layout>
          )}
        </Layout>
        <Layout paddingY={24}>
          <Text size="h5" color="gray-700">
            Here are some things to scope out:
          </Text>
        </Layout>
        <Layout flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <ul>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    The HVAC system
                  </Text>
                  {'  '}
                  How new is it? Is it under warranty? Generally its lifetime is 10–15 years. If it breaks, who is
                  responsible for repairs?
                </Text>
              </li>
              <br />
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Elevators
                  </Text>
                  {'  '}
                  If the space requires elevators, are they modernized?
                </Text>
              </li>
              <br />
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Acoustics
                  </Text>
                  {'  '}
                  This is especially important for areas that will be used as conference rooms.
                </Text>
              </li>
            </ul>
          </Layout>
          <Layout marginTop={xs ? 24 : undefined} marginLeft={xs ? undefined : 100} equalWidth>
            <ul>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Plumbing
                  </Text>
                  {'  '}
                  Check the faucets and toilets to make sure they are in working order (don’t be afraid to flush). Look
                  for water damage.
                </Text>
              </li>
              <br />
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    IT Capabilities
                  </Text>
                  {'  '}Will the space meet your IT needs? Is there fiber installed in the building? If fiber internet
                  is something that your team requires, be aware that if your building doesn’t have it, it can take
                  significant time to have it installed. Ask about local ISPs as well.
                </Text>
              </li>
            </ul>
          </Layout>
          <Layout marginTop={xs ? 24 : undefined} marginLeft={xs ? undefined : 100} equalWidth>
            <ul>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    Security & Access
                  </Text>
                  {'  '}
                  What kind of security does the building have? Is it accessed with keys or is there a key card or FOB
                  system in place?
                </Text>
              </li>
              <br />
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  <Text size="h6" color="gray-700" inline>
                    On-Site Management
                  </Text>
                  {'  '}
                  Is there a full-time superintendent? Is the owner’s or manager’s office also in the building? In other
                  words—if something needs fixing, is there someone who will be on-site to help immediately?
                </Text>
              </li>
            </ul>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default RedFlags;
