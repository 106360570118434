import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const ReputableBrokers: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout flex direction={xs ? 'column' : 'row'}>
        <Layout equalWidth>
          <Text size="h6" color="gray-700">
            To make sure you’re working with a reputable broker, be sure to:
          </Text>
          <br />
          <ul style={{ marginLeft: 32 }}>
            <li>
              <Text size="h6" bold={false} color="gray-700">
                Ask about their experience and find out if they service a certain niche. For example, a broker who has
                worked with a lot of Finance and Fintech companies will be better versed in building security. Ones
                familiar with MedTech might know properties with good R&D facilities
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" bold={false} color="gray-700">
                Find out whether they are a junior or senior broker, and the size of their team
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" bold={false} color="gray-700">
                Have them send you a few property options as a trial
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" bold={false} color="gray-700">
                Ask them to provide testimonials and/or references from previous clients
              </Text>
            </li>
          </ul>
        </Layout>
        <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 80 : undefined} equalWidth>
          <Text size="h6" color="gray-700">
            A broker will likely ask you to sign an exclusivity agreement.
          </Text>
          <br />
          <ul style={{ marginLeft: 32 }}>
            <li>
              <Text size="h6" bold={false} color="gray-700">
                You do not have to, but such an agreement incentivizes them to devote time and resources to your search.
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" bold={false} color="gray-700">
                If you do decide to sign exclusively, just be sure to do the due diligence and make sure you feel that
                they truly understand what you are looking for.
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" bold={false} color="gray-700">
                Also, be sure to include a protection clause —termination at any time — in case you later feel that
                they’re not performing for you.
              </Text>
            </li>
          </ul>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default ReputableBrokers;
