import React, { useContext, useState } from 'react';
import { GRAY_900_HEX, Image } from '@codiwork/codi';

import { app } from 'context';
import Clickable from 'ds/Clickable';
import Img from 'ds/Img';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import useKeyDown from 'ds/helpers/useKeyDown';

import { HEADER_HEIGHT } from './constants';

import { ReactComponent as Cube } from '../Cube.svg';
import { ReactComponent as VideoCamera } from '../VideoCamera.svg';

interface Props {
  images: Image[];
  imageIndex: number;
  matterportId: string | null;
  tourVideoKey: string | null;
  show3DWalkthroughTab: VoidFunction;
  showVideoTourTab: VoidFunction;
}

const WIDTH_TO_HEIGHT = 1.5;

const Photos: React.FC<Props> = ({
  images,
  imageIndex,
  matterportId,
  tourVideoKey,
  show3DWalkthroughTab,
  showVideoTourTab
}) => {
  const { windowWidth } = useContext(app);
  const [activeIndex, _setActiveIndex] = useState<number>(imageIndex);
  const [inputIndex, setInputIndex] = useState<number>();

  useKeyDown((e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      if (inputIndex === images.length - 1) setInputIndex(0);
      else setInputIndex(activeIndex + 1);
    } else if (e.key === 'ArrowLeft') {
      if (inputIndex === 0) setInputIndex(images.length - 1);
      else setInputIndex(activeIndex - 1);
    }
  });

  if (!images.length) return null;

  const imagesArray: (Image | string)[] = [...images];
  if (!!matterportId) {
    imagesArray.splice(2, 0, matterportId);
  } else if (!!tourVideoKey) {
    imagesArray.splice(2, 0, tourVideoKey);
  }

  return (
    <Layout>
      <Layout position="relative" marginTop={HEADER_HEIGHT} wrap __style={{ gap: 4 }}>
        {imagesArray.map((image, index) => {
          const isMatterportThumbnail = index === 2 && !!matterportId;
          const isTourVideoThumbnail = index === 2 && !!tourVideoKey && !isMatterportThumbnail;

          return (
            <Layout
              color="gray-50"
              minHeight={windowWidth / WIDTH_TO_HEIGHT}
              minWidth={windowWidth}
              position="relative"
              overflow="hidden"
              key={`thumbnail${index}`}
              direction="column"
            >
              {isMatterportThumbnail ? (
                <Clickable onClick={() => show3DWalkthroughTab()}>
                  <img
                    src={`https://my.matterport.com/api/v2/player/models/${matterportId}/thumb`}
                    alt="Matterport Thumbnail"
                    style={{ minHeight: windowWidth / WIDTH_TO_HEIGHT }}
                  />
                  <Layout
                    position="absolute"
                    top={0}
                    width="100%"
                    height="100%"
                    justify="center"
                    align="center"
                    direction="column"
                    zIndex={1}
                  >
                    <Layout
                      width={90}
                      height={90}
                      borderRadius="circular"
                      justify="center"
                      align="center"
                      __style={{ backgroundColor: `${GRAY_900_HEX}B3` }}
                    >
                      <Cube width={32} height={32} />
                    </Layout>
                    <Layout marginTop={16} />
                    <Text size="h6" color="white">
                      3D Walkthrough
                    </Text>
                  </Layout>
                </Clickable>
              ) : isTourVideoThumbnail ? (
                <Clickable onClick={() => showVideoTourTab()}>
                  <Img
                    width={windowWidth}
                    height={windowWidth / WIDTH_TO_HEIGHT}
                    alt={'video tour'}
                    srcKey={`${tourVideoKey}/ik-thumbnail.jpg`}
                  />
                  <Layout
                    position="absolute"
                    top={0}
                    width="100%"
                    height="100%"
                    justify="center"
                    align="center"
                    direction="column"
                    zIndex={1}
                  >
                    <Layout
                      width={90}
                      height={90}
                      borderRadius="circular"
                      justify="center"
                      align="center"
                      __style={{ backgroundColor: `${GRAY_900_HEX}B3` }}
                    >
                      <VideoCamera width={32} height={32} />
                    </Layout>
                    <Layout marginTop={16} />
                    <Text size="h6" color="white">
                      Video Tour
                    </Text>
                  </Layout>
                </Clickable>
              ) : (
                <Img
                  width={windowWidth}
                  height={windowWidth / WIDTH_TO_HEIGHT}
                  alt={`workspace image ${index}`}
                  srcKey={(image as Image).key}
                />
              )}
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default Photos;
