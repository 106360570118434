import React, { useContext } from 'react';

import { app } from 'context';
import Layout, { Props as LayoutProps } from 'ds/Layout';

interface Props extends LayoutProps {
  type?: 'block';
  height?: number;
}

const MobileDivider: React.FC<Props> = ({ type, height = 8, ...props }) => {
  const { contentPaddingX, width } = useContext(app);

  if (type === 'block') {
    return (
      <Layout
        height={height}
        color={props.color || 'gray-25'}
        position="relative"
        left={-contentPaddingX}
        width="100vw"
        {...props}
      />
    );
  }

  return (
    <Layout overflow="hidden">
      <Layout
        borderTop
        borderColor={props.color || 'gray-300'}
        width={width}
        position="relative"
        left={-contentPaddingX}
        {...props}
      />
    </Layout>
  );
};

export default MobileDivider;
