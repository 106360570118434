import React, { PropsWithChildren, useContext } from 'react';

import { app } from 'context';
import Layout, { Props as LayoutProps } from 'ds/Layout';

interface Props extends LayoutProps {
  maxWidth?: number;
}

const ContentBlock: React.FC<PropsWithChildren<Props>> = ({ maxWidth, children, ...props }) => {
  const { maxContentWidth, contentPaddingX } = useContext(app);

  return (
    <Layout justify="center">
      <Layout
        maxWidth={maxWidth || maxContentWidth}
        marginX={contentPaddingX}
        direction="column"
        align="stretch"
        flexGrow={1}
        {...props}
      >
        {children}
      </Layout>
    </Layout>
  );
};

export default ContentBlock;
