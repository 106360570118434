import React, { useContext } from 'react';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

import { ReactComponent as Illustration } from './tenant-rep-broker-illustration.svg';

interface Props {}

const TenantBrokers: React.FC<Props> = () => {
  const { xs, md } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout maxWidth={xs ? contentWidth : 590}>
        <Text size="h3" color="blue-600">
          About Tenant Rep Brokers
        </Text>
        <Layout marginTop={48}>
          <Text size="h6" color="gray-700" bold={false}>
            A Tenant Rep Broker will help you find an office that’s right for you and then handle the negotiations with
            landlord — all the way through to the final lease agreement.
          </Text>
        </Layout>
        <Layout marginTop={48}>
          <Text size="h6" color="gray-700" bold={false}>
            A good broker will take time to understand your business and the needs of your team, and they will do a
            significant amount of the legwork in the process.
          </Text>
        </Layout>
        <Layout marginTop={48}>
          <Text size="h6" color="gray-700" bold={false}>
            However, remember that Commercial Real Estate is a commission-based business and Tenant Rep Brokers earn
            their commissions from landlords. This industry arrangement incentivizes them to get you into an office that
            is at the top of your budget and lock you in for the longest lease period possible.
          </Text>
        </Layout>
      </Layout>
      {!xs && (
        <Layout marginLeft={xs ? undefined : 72}>
          <Illustration width={md ? 480 : 617} />
        </Layout>
      )}
    </PlaybookContentContainer>
  );
};

export default TenantBrokers;
