import React, { useContext } from 'react';

import { media } from 'context';
import { Grid, Layout, Link, Text } from 'ds';

interface Props {}

const LocationsFooterSection: React.FC<Props> = () => {
  const { isMobile, xs, sm, md } = useContext(media);

  return (
    <>
      <Layout {...(isMobile ? { paddingY: 44 } : { paddingTop: 48, paddingBottom: 44 })}>
        <Layout direction="row" align="center" __style={{ gap: 8 }} wrap>
          <Link href="/s/office-space/ny/new-york/2262314c-abee-4963-8b53-5266914d0967">
            <Text size="body3" color="white" bold wrap={false}>
              OFFICE SPACE IN NEW YORK CITY
            </Text>
          </Link>
          <Layout>
            <Text size="body3" color="white" bold wrap>
              /
            </Text>
          </Layout>
          <Link href="/s/office-space/ny/new-york/downtown-manhattan/261d9a30-0388-4c1b-a4dd-b2c00123afee">
            <Text size="body3" color="white" bold wrap={false}>
              DOWNTOWN MANHATTAN
            </Text>
          </Link>
          <Layout>
            <Text size="body3" color="white" bold wrap>
              /
            </Text>
          </Layout>
          <Link href="/s/office-space/ny/new-york/midtown-manhattan/dad29428-ae62-457f-aa96-53376fc3e7ae">
            <Text size="body3" color="white" bold wrap={false}>
              MIDTOWN MANHATTAN
            </Text>
          </Link>
          <Layout>
            <Text size="body3" color="white" bold>
              /
            </Text>
          </Layout>
          <Link href="/s/office-space/ny/new-york/brooklyn/79bf279f-b8d2-4efc-ad88-edd6eb3a172d">
            <Text size="body3" color="white" bold wrap={false}>
              BROOKLYN
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Grid itemsPerRow={xs ? 2 : sm || md ? 3 : 6} gapY={20}>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/chelsea/bb9bf27b-3720-4b70-8b22-359087d7f029"
                color="white"
                size="body3"
                semibold={false}
              >
                Chelsea
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/financial-district/85904e1b-f174-473c-bc33-fbc5f536a4c2"
                color="white"
                size="body3"
                semibold={false}
              >
                Financial District
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/flatiron-district/bae3bbff-acc7-4256-9f7d-6db2d69c7ec1"
                color="white"
                size="body3"
                semibold={false}
              >
                Flatiron District
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/garment-district/19af26aa-deef-4b3a-998f-5eca40a96d31"
                color="white"
                size="body3"
                semibold={false}
              >
                Garment District
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/gramercy-park/3dfd1841-463a-419d-bb55-93cb689d5721"
                color="white"
                size="body3"
                semibold={false}
              >
                Gramercy Park
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/grand-central-station/905117f9-a966-437c-a7fd-21987f185aef"
                color="white"
                size="body3"
                semibold={false}
              >
                Grand Central Station
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/greenwich-village/034e18d4-1aa4-413b-a4f0-2fc88fe2660f"
                color="white"
                size="body3"
                semibold={false}
              >
                Greenwich Village
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/noho/eb3c9cd8-2cc1-4b0c-a9da-06a42e4d195d"
                color="white"
                size="body3"
                semibold={false}
              >
                NoHo
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/nomad/dbda0829-00b5-4f43-b25e-f7fcf1d98f9f"
                color="white"
                size="body3"
                semibold={false}
              >
                NoMad
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/penn-station/33f4a8fc-f18d-42fc-a994-1fb261dc24a5"
                color="white"
                size="body3"
                semibold={false}
              >
                Penn Station
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/soho/cd281096-43cb-4efe-ac00-d3c4a831252c"
                color="white"
                size="body3"
                semibold={false}
              >
                SoHo
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ny/new-york/union-square/5f1384cb-464a-4b3f-986e-1570126029ef"
                color="white"
                size="body3"
                semibold={false}
              >
                Union Square
              </Link>
            </Layout>
          </Grid>
        </Layout>
      </Layout>
      <Layout width="100%" direction="column" paddingY={44}>
        <Layout direction="row" align="center" __style={{ gap: 8 }} wrap>
          <Link href="/s/office-space/ca/san-francisco/dc57ffde-0407-4b1e-a760-2e9c059336c8">
            <Text size="body3" color="white" bold wrap={false}>
              OFFICE SPACE IN SAN FRANCISCO BAY AREA
            </Text>
          </Link>
          <Layout>
            <Text size="body3" color="white" bold wrap>
              /
            </Text>
          </Layout>
          <Link href="/s/office-space/ca/san_francisco/50c6c0e6-7d73-4c8a-bdfc-d7b004fdc580">
            <Text size="body3" color="white" bold wrap={false}>
              SAN FRANCISCO
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Grid itemsPerRow={xs ? 2 : sm || md ? 3 : 6} gapY={20}>
            <Layout>
              <Link
                href="/s/office-space/ca/san-francisco/chinatown/76420b22-4611-4876-9ff4-5d2bcb936560"
                color="white"
                size="body3"
                semibold={false}
              >
                Chinatown
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/san-francisco/financial-district/37e2cc51-b8ef-40a4-88a6-43896be16be2"
                color="white"
                size="body3"
                semibold={false}
              >
                Financial District
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/san-francisco/mission/0c6cc358-2ab6-4de4-af79-5368d7d24b7c"
                color="white"
                size="body3"
                semibold={false}
              >
                Mission District
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/san-francisco/north-beach/7d516936-ae43-4e77-9cef-1c7adc832799"
                color="white"
                size="body3"
                semibold={false}
              >
                North Beach
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/san-francisco/south-of-market/4c24337e-9f3e-44cc-8611-64147cb11d0a"
                color="white"
                size="body3"
                semibold={false}
              >
                SoMa
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/san-francisco/union-square/b27a8136-9628-4913-8765-48e171f10973"
                color="white"
                size="body3"
                semibold={false}
              >
                Union Square
              </Link>
            </Layout>
          </Grid>
        </Layout>
      </Layout>
      <Layout width="100%" direction="column" paddingY={44}>
        <Layout direction="row" align="center" __style={{ gap: 8 }} wrap>
          <Link href="/s/office-space/ca/los-angeles/e1112852-8900-4546-9f17-8897f9f20d93">
            <Text size="body3" color="white" bold wrap={false}>
              OFFICE SPACE IN LOS ANGELES
            </Text>
          </Link>
        </Layout>
        <Layout marginTop={20}>
          <Grid itemsPerRow={xs ? 2 : sm || md ? 3 : 6} gapY={20}>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/santa-monica/4ea7e3c9-e5fc-4b71-808f-b2db8fc6270c"
                color="white"
                size="body3"
                semibold={false}
              >
                Santa Monica
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/brentwood/e73f47c4-32ff-447a-b482-bdfa1c21bf32"
                color="white"
                size="body3"
                semibold={false}
              >
                Brentwood
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/mar-vista/a1cb43d6-71e5-4076-9c5c-fb26e90a5197"
                color="white"
                size="body3"
                semibold={false}
              >
                Mar Vista
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/culver-city/78b0d973-ee24-4e78-a024-6d49da393273"
                color="white"
                size="body3"
                semibold={false}
              >
                Culver City
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/beverly-hills/1895cf68-742f-4ef8-b180-857d87b3f7ee"
                color="white"
                size="body3"
                semibold={false}
              >
                Beverly Hills
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/west-hollywood/2e3caf08-e573-4144-a0f0-cdb9bfe4d8af"
                color="white"
                size="body3"
                semibold={false}
              >
                West Hollywood
              </Link>
            </Layout>
            <Layout>
              <Link
                href="/s/office-space/ca/los-angeles/el-segundo/fd962fce-e423-4ca0-b64c-7e4f32e035cd"
                color="white"
                size="body3"
                semibold={false}
              >
                El Segundo
              </Link>
            </Layout>
          </Grid>
        </Layout>
      </Layout>
    </>
  );
};

export default LocationsFooterSection;
