import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Link, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const SearchingForCoworkingIntro: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout flex direction={xs ? 'column' : 'row'}>
        <Layout equalWidth>
          <Text size="h6" bold={false} color="gray-700">
            If you’re looking for office space in almost any major city, you’ll find a coworking space there. According
            to a{' '}
            <Link
              href="https://www.statista.com/statistics/554273/number-of-coworking-spaces-worldwide/"
              semibold={false}
              underline
              size="h6"
            >
              2022 report
            </Link>{' '}
            from Statista Research Department, projections indicate that there will be some 41,975 coworking spaces
            worldwide by the end of 2024.
            <br />
            <br />A solo entrepreneur, or a small business of 5-10 employees can simply choose a location, take a tour,
            select an office with the right amount of space, and sign a month-to-month agreement. However, a growing
            company looking for a more private experience will be directed to work with a dedicated sales team instead
            of site managers.
          </Text>
        </Layout>
        <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 24 : undefined} equalWidth>
          <Text size="h6" color="gray-700">
            Here’s what you’ll need to remember:
          </Text>
          <br />
          <ul>
            <li>
              <Text size="h6" color="gray-700" bold={false}>
                The coworking model, by design, works best for owners when they maximize density in their spaces. As a
                result, you can expect to find limited options for separate office spaces dedicated to your team only.
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" color="gray-700" bold={false}>
                Because you’ll be sharing space with other companies, security may be a factor
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" color="gray-700" bold={false}>
                You’ll likely still share common areas and amenities with other members: conference rooms, entrances,
                waiting areas, kitchens, bathrooms, etc.
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" color="gray-700" bold={false}>
                Because coworking companies make the most when they keep density high, expect to pay a premium for your
                own dedicated space
              </Text>
            </li>
          </ul>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default SearchingForCoworkingIntro;
