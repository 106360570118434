import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { media } from 'context';
import { Button, Icon, Layout, Modal, Text } from 'ds';
import { ADD_PROPERTY_PATH, COMMERCIAL_REAL_ESTATE_PATH } from 'routes';
import { actions } from 'store/AddProperty';
import { useAppDispatch } from 'store/hooks';

interface Props {
  isVisible: boolean;
}

const AddPropertyExitModal: React.FC<Props> = ({ isVisible }) => {
  const dispatch = useAppDispatch();
  const { xs } = useContext(media);
  const history = useHistory();

  const continueButton = (
    <Button
      text="Continue"
      size="sm"
      type="primary"
      onClick={() => dispatch(actions.setWorkspace({}))}
      href={`${ADD_PROPERTY_PATH}/1`}
      fullWidth
    />
  );
  const exitButton = (
    <Button
      text="Exit"
      size="sm"
      type="secondaryOutline"
      onClick={() => {
        dispatch(actions.reset());
        history.push(COMMERCIAL_REAL_ESTATE_PATH);
      }}
      fullWidth
    />
  );

  return (
    <Modal
      onClose={() => {
        dispatch(actions.setExitModalIsVisible(false));
      }}
      isVisible={isVisible}
      footer={
        xs ? (
          <>
            <Layout>{continueButton}</Layout>
            <Layout marginTop={12}>{exitButton}</Layout>
          </>
        ) : undefined
      }
      hasBottomSheetPaddingX
    >
      <Layout justify="center" marginTop={52}>
        <Layout
          background="linear-gradient(94.99deg, #1AB2D9 28.48%, #0078FF 141.74%)"
          width={84}
          height={84}
          borderRadius="circular"
          justify="center"
          align="center"
        >
          <Icon stroke={2} name="building" color="white" size="xxl" />
        </Layout>
      </Layout>
      <Layout align="center" direction="column" marginTop={32}>
        <Layout {...(xs ? {} : { maxWidth: 380 })}>
          <Text size="h5" color="blue-700" align="center">
            Leaving so soon?
          </Text>
          <Layout marginTop={16}>
            <Text size="body1" align="center">
              If you’re prefer to speak with someone first, let us know how to contact you.
            </Text>
          </Layout>
        </Layout>
      </Layout>
      {!xs && (
        <Layout paddingY={32} justify="center">
          <Layout {...(xs ? {} : { width: 314 })} flex>
            <Layout {...(xs ? {} : { equalWidth: true })}>{continueButton}</Layout>
            <Layout marginLeft={12} {...(xs ? {} : { equalWidth: true })}>
              {exitButton}
            </Layout>
          </Layout>
        </Layout>
      )}
    </Modal>
  );
};

export default AddPropertyExitModal;
