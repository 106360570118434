import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const DayToDay: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowHeight } = useContext(app);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={48}
        >
          <Text size="h3" color="blue-600">
            Getting the Day-to-Day Going
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                After you’ve decided how your office will work, you’ll need to fill it. If your flex office provider
                handles the setup and furnishings for your office, then you’ll have a chance to select desks, chairs,
                and furniture that sets the right tone for your office.
                <br />
                <br />
                Likely, they’ll work with a furniture rental company who will have a catalog of options. Simply review
                what’s available and choose what you need. The flex office provider should handle delivery and assembly.
                <br />
                <br />
                If snacks will be ordered, you’ll need to decide what you’re providing. The flex office provider will
                set up a delivery schedule for you (depending on how many days a week personnel are in the office and
                how hungry your staff gets).
              </Text>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="women-laughing_HnczZiqmQ.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default DayToDay;
