import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Listing, isApiErrorResponse } from '@codiwork/codi';

import Head from 'components/Head';

import { app, media } from 'context';
import { Layout } from 'ds';
import { RequestState } from 'ds/types';
import { isGoogleUserAgent } from 'helpers';
import { ROOT_PATH, SEARCH_ENTRY_PATH, SEARCH_PATH } from 'routes';
import { selectGoogleMapsLoaded } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';
import Footer from 'ux/Layouts/Shared/Footer';
import Header from 'ux/Layouts/Shared/Header';

import HeaderTags from './HeaderTags';
import MarketNavigationTree from './MarketNavigationTree';
import MobileUI from './MobileUI';
import MobileUIGoogle from './MobileUIGoogle';
import SearchResultsSchema from './SearchResultsSchema';
import SeoContent from './SeoContent';
import UI from './UI';
import { getSearchLocation } from './requests';
import { SearchLocation, SearchState } from './types';
import { makeSearchState } from './utils';

interface Props extends RouteComponentProps<{}, any, RouteState> {}

interface RouteState {
  from?: { pathname: string };
}

const SearchEntry: React.FC<Props> = ({ history }) => {
  const { navBarHeight } = useContext(app);
  const { xs, isMobile } = useContext(media);
  const [searchLocation, setSearchLocation] = useState<SearchLocation>();
  const [backPathname, _setBackPathname] = useState<string>(history.location.state?.from?.pathname || ROOT_PATH);
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const location = useLocation();
  const slugSection = location.pathname.split(SEARCH_ENTRY_PATH)[1];
  const slugSplit = slugSection.split('/');
  const guid = slugSplit.length ? slugSplit[slugSplit.length - 1] : null;
  const { contentPaddingX } = useContext(app);
  const [workspaces, setWorkspaces] = useState<Listing[]>([]);
  const [searchState, setSearchState] = useState<SearchState | null>(null);
  const googleMapsLoaded = useAppSelector(selectGoogleMapsLoaded);

  useEffect(() => {
    setSearchLocation(undefined);
    setSearchState(null);
  }, [guid]);

  useEffect(() => {
    if (requestState === 'in_progress') return;

    if (!guid) {
      history.replace(`${SEARCH_PATH}${location.search}`);
      return;
    }

    setRequestState('in_progress');
    getSearchLocation({ guid })
      .then(({ data }) => {
        setSearchLocation(data);

        let locationArray = [data.name];

        if (data.submarket) {
          locationArray.push(data.submarket.name);
        }
        if (data.market) {
          locationArray.push(data.market.name);
          locationArray.push(data.market.name === 'New York' ? 'NY' : 'CA');
        } else {
          locationArray.push(data.state);
        }

        setWorkspaces(data.workspaces);
        setRequestState('ready');
      })
      .catch(error => {
        if (isApiErrorResponse(error)) {
          if (error.type === 'HivenException::RecordNotFound') {
            history.replace(`${SEARCH_PATH}${location.search}`);
          }
        }
      });
  }, [guid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!searchLocation || !googleMapsLoaded) return;

    if (!(isGoogleUserAgent() && isMobile)) {
      setSearchState({ ...makeSearchState({ location: searchLocation, isMobile }), selectedWorkspaceId: null });
    }
  }, [searchLocation?.guid, googleMapsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (xs && isGoogleUserAgent()) {
    if (!searchLocation) {
      return null;
    } else {
      return <MobileUIGoogle workspaces={workspaces} searchLocation={searchLocation} backPathname={backPathname} />;
    }
  }

  if (!searchLocation) {
    return <Layout height={`calc(100vh - ${navBarHeight}px)`} />;
  }

  if (!!searchLocation && (!searchState || requestState === 'in_progress')) {
    return (
      <>
        <Header />
        <Head title={searchLocation.seo_title} description={searchLocation.seo_description} />
        <SearchResultsSchema searchLocation={searchLocation} workspaces={workspaces} />
        <Layout
          paddingX={contentPaddingX}
          paddingTop={xs ? contentPaddingX : undefined}
          paddingBottom={xs ? contentPaddingX : 8}
        >
          <HeaderTags numResults={0} searchLocation={searchLocation} />
        </Layout>
        <SeoContent searchLocation={searchLocation} />
        <MarketNavigationTree market={searchLocation.type === 'Market' ? searchLocation : searchLocation.market!} />
        <Footer />
      </>
    );
  }

  if (!searchState || requestState === 'in_progress') {
    return <Layout height={`calc(100vh - ${navBarHeight}px)`} />;
  }

  const { center, zoom, selectedWorkspaceId, bounds } = searchState;

  return (
    <>
      <Header />
      <Head title={searchLocation.seo_title} description={searchLocation.seo_description} />
      <SearchResultsSchema searchLocation={searchLocation} workspaces={workspaces} />
      {xs ? (
        <MobileUI
          center={center}
          selectedWorkspaceId={selectedWorkspaceId}
          zoom={zoom}
          setSearchState={setSearchState}
          workspaces={workspaces}
          searchLocation={searchLocation}
          backPathname={backPathname}
        />
      ) : (
        <UI
          center={center}
          selectedWorkspaceId={selectedWorkspaceId}
          zoom={zoom}
          setSearchState={setSearchState}
          workspaces={workspaces}
          searchLocation={searchLocation}
          bounds={bounds}
        />
      )}
      <Footer />
    </>
  );
};

export default SearchEntry;
