import React, { useContext, useState } from 'react';

import OffsiteRequestForm from 'components/OffsiteRequestForm';

import { media } from 'context';
import { Button, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {
  onClick?: VoidFunction;
  offsitesOnly?: boolean;
}

const RequestOfficePrompt: React.FC<Props> = ({ onClick, offsitesOnly }) => {
  const { xs, lg, xl } = useContext(media);
  const [offsiteRequestFormIsVisible, setOffsiteRequestFormIsVisible] = useState<boolean>(false);

  return offsitesOnly ? (
    <>
      <OffsiteRequestForm
        isVisible={offsiteRequestFormIsVisible}
        onCloseModal={() => setOffsiteRequestFormIsVisible(false)}
      />
      <Layout paddingY={32} paddingX={24} justify="space-between" align="center" color="blue-50" borderRadius={12}>
        <Layout maxWidth={xl ? 364 : lg ? 332 : undefined}>
          <Text size="h5" color="blue-700">
            Not seeing what you need?
          </Text>
          <Layout marginTop={4}>
            <Text size="body1">We can find you the perfect location for an offsite anywhere.</Text>
          </Layout>
        </Layout>
        <Button
          type="primary"
          size="sm"
          icon="rightArrow"
          iconStroke={2}
          text="Request an offsite"
          onClick={() => setOffsiteRequestFormIsVisible(true)}
        />
      </Layout>
    </>
  ) : (
    <Layout
      direction={xs ? 'column' : 'row'}
      paddingY={32}
      paddingX={24}
      justify={'space-between'}
      align="center"
      color="blue-50"
      borderRadius={12}
    >
      <Layout maxWidth={xl ? 364 : lg ? 332 : undefined}>
        <Text size="h5" color="blue-700" align={xs ? 'center' : undefined}>
          Not seeing what you need?
        </Text>
        <Layout marginTop={4}>
          <Text size="body1" align={xs ? 'center' : undefined}>
            Share your preferences and we’ll unlock an office with unbeatable terms, anywhere.
          </Text>
        </Layout>
      </Layout>
      {xs && <Layout marginTop={12} />}
      <Button
        type="primary"
        size="sm"
        icon="rightArrow"
        iconStroke={2}
        text="Request an office"
        href={!!onClick ? undefined : OFFICE_REQUEST_PATH}
        onClick={!!onClick ? onClick : undefined}
      />
    </Layout>
  );
};

export default RequestOfficePrompt;
