import React, { useContext } from 'react';

import { media } from 'context';
import { FullWidthBackground, Layout } from 'ds';

interface Props {
  logos: { name: string; svg: string }[];
}

const LogosMarquee: React.FC<Props> = ({ logos }) => {
  const mediaContext = useContext(media);
  const { xs, sm } = mediaContext;

  return xs || sm ? (
    <FullWidthBackground paddingY={68} color="blue-700">
      <Layout justify="space-around" align="center" wrap>
        {logos.map(({ name, svg }) => (
          <Layout key={name} margin={12}>
            <span dangerouslySetInnerHTML={{ __html: svg }} />
          </Layout>
        ))}
      </Layout>
    </FullWidthBackground>
  ) : (
    <FullWidthBackground paddingY={128} color="blue-700">
      <Layout justify="space-between">
        {logos.map(({ name, svg }) => (
          <span key={name} dangerouslySetInnerHTML={{ __html: svg }} />
        ))}
      </Layout>
    </FullWidthBackground>
  );
};

export default LogosMarquee;
