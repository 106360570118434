import React from 'react';
import { IconName } from '@codiwork/codi';

import { Icon, Layout, Link, Text } from 'ds';
import { kebabToCamelCase } from 'ds/utils';
import { NavigationMenuSection } from 'lib/butter/types';

interface Props extends NavigationMenuSection {
  closeMenu: VoidFunction;
}

const MenuSection: React.FC<Props> = ({ fields: { title, items }, closeMenu }) => {
  return (
    <Layout>
      <Text size="body3" bold>
        {title}
      </Text>
      {items.map(({ icon, name, description, page, link }, index) => {
        // TODO: relies on Butter /v2/public_page/ URL structure which is fragile; make better.
        const href = typeof page === 'string' ? page.split('/public_page').slice(-1)[0] : link;

        return href && name ? (
          <Link
            opacity={false}
            href={href}
            size="body2"
            semibold={false}
            color="black"
            key={name}
            onClick={closeMenu}
            target={href.startsWith('http') ? '_blank' : undefined}
            fullWidth
          >
            <Layout
              align="flex-start"
              marginTop={24}
              hoverColor="gray-50"
              padding={12}
              borderRadius={12}
              position="relative"
              left={-12}
            >
              {icon && (
                <Layout marginRight={24}>
                  <Icon name={kebabToCamelCase(icon) as IconName} size="md" color="black" />
                </Layout>
              )}
              <Layout>
                <Layout>
                  <Text size="body2" scale>
                    {name}
                  </Text>
                  {description && (
                    <Layout marginTop={8}>
                      <Text size="body3">{description}</Text>
                    </Layout>
                  )}
                </Layout>
              </Layout>
            </Layout>
          </Link>
        ) : link ? (
          <Layout key={index} marginTop={index === 0 ? 36 : 16}>
            <div dangerouslySetInnerHTML={{ __html: link }} />
          </Layout>
        ) : null;
      })}
    </Layout>
  );
};

export default MenuSection;
