import React, { ForwardedRef, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { app } from 'context';
import { DESKTOP_MODAL_BORDER_RADIUS } from 'ds/constants';
import { useOnClickOutside } from 'ds/helpers';
import { getDatePickerPortalElement } from 'ds/utils';

import ModalContent from './ModalContent';
import { ModalProps } from './types';
import Layout from '../Layout';

const SIZE_TO_WIDTH = {
  md: 576,
  lg: 640,
  xl: 888
} as const;

const MARGIN_X = 24;
const MARGIN_Y = 48;

interface Props extends ModalProps {
  scrollContainerRef: ForwardedRef<HTMLDivElement>;
  className?: string;
}

const ModalContainer: React.FC<PropsWithChildren<Props>> = props => {
  const {
    footer,
    children,
    onClose,
    overflow,
    isFullScreen,
    isVisible,
    size = 'md',
    closeOnClickOutside = true,
    className
  } = props;
  const { windowWidth, windowHeight } = useContext(app);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, event => {
    const modal = ref.current;

    if (
      !isVisible ||
      isFullScreen ||
      !closeOnClickOutside ||
      !modal ||
      (props.isSideDrawer && !props.showSideDrawerScrim)
    )
      return;

    const datePickerElement = getDatePickerPortalElement();

    if (datePickerElement && datePickerElement.contains(event.target as HTMLElement)) return;

    // <Hack> that makes elements inside the modal using Popper with
    // their own useOnClickOutside (e.g. <DatePicker>) fire the useOnClickOutside callback properly
    setTimeout(() => {
      onClose();
    }, 1);
  });

  const hasFooter = !!footer;

  useEffect(() => {
    if (document.hasFocus() && document!.activeElement!.tagName === 'INPUT') {
      return;
    }

    if (footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight);
    } else {
      setFooterHeight(0);
    }

    ref?.current?.focus();
  }, [footerRef.current?.innerHTML, hasFooter]);

  const width = props.width || SIZE_TO_WIDTH[size];
  const borderRadius = isFullScreen ? undefined : DESKTOP_MODAL_BORDER_RADIUS;
  const rect = ref.current?.getBoundingClientRect();
  const content = (
    <ModalContent
      {...props}
      borderRadius={borderRadius}
      containerWidth={rect?.width}
      containerHeight={rect?.height}
      width={width}
      footerHeight={footerHeight}
      footerRef={footerRef}
    >
      {children}
    </ModalContent>
  );

  if (props.isSideDrawer) {
    return (
      <Layout
        boxShadow="0px 13px 23px rgba(0, 0, 0, 0.15)"
        color="white"
        __className={className}
        __ref={ref}
        minHeight="100%"
        position="fixed"
        right={0}
        width={width}
      >
        {content}
      </Layout>
    );
  }

  return (
    <Layout
      position="relative"
      tabIndex={1}
      {...(isFullScreen
        ? { width: windowWidth, height: windowHeight }
        : {
            width,
            maxHeight: `calc(100vh - ${MARGIN_Y * 2}px)`,
            marginX: MARGIN_X,
            marginY: MARGIN_Y
          })}
      borderRadius={borderRadius}
      color="white"
      visibility={footer && footerHeight === 0 ? 'hidden' : undefined}
      overflow={overflow}
      __ref={ref}
      __className={classNames('Modal-content', className)}
    >
      {content}
    </Layout>
  );
};

export default ModalContainer;
