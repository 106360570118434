import React from 'react';

import PlaybookStepPage from './PlaybookStepPage';

interface Props {}

const NegotiationsAgreements: React.FC<Props> = () => {
  return <PlaybookStepPage title="Negotiations & Agreements" activeStep={3} />;
};

export default NegotiationsAgreements;
