import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { media } from 'context';
import { HEADER_Z_INDEX } from 'ds';
import { BROKERS_PATH, ROOT_PATH, SEARCH_PATH } from 'routes';
import { selectPublicHeaderHasShadow } from 'store/UI/selectors';
import { selectUser } from 'store/User/selectors';
import WeWorkBanner from 'ux/Public/Home/WeWorkBanner';

import Navigation from './Navigation';
import NavigationMobile from './Navigation/Mobile';
import StickyHeader from './StickyHeader';
import { PUBLIC_NAV_HEIGHT } from './constants';

interface Props {
  hideLinks?: boolean;
  showCta?: boolean;
}

const PATHS_WITH_TRANSPARENT_HEADER = [ROOT_PATH, BROKERS_PATH] as string[];

const Header: React.FC<Props> = ({ showCta }) => {
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const reduxHasShadow = useSelector(selectPublicHeaderHasShadow);
  const isHomePage = pathname === ROOT_PATH;
  const [isTransparentMode, setIsTransparentMode] = useState<boolean>(PATHS_WITH_TRANSPARENT_HEADER.includes(pathname));
  const [isPublicNavOpen, setIsPublicNavOpen] = useState<boolean>(false);
  const [isAccountNavOpen, setIsAccountNavOpen] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>();
  const [openMenuIndex, setOpenMenuIndex] = useState<number>(-1);

  useEffect(() => {
    if (PATHS_WITH_TRANSPARENT_HEADER.includes(pathname)) {
      setIsTransparentMode(true);
    } else {
      setOpenMenuIndex(-1);
      setOpacity(1);
      setIsTransparentMode(false);
      setIsAccountNavOpen(false);
      setIsAccountNavOpen(false);
    }
  }, [pathname]);

  const { lg } = useContext(media);

  const isSearchPage = pathname === SEARCH_PATH;
  const isWorkspacesOrSearchPage = ['workspaces'].includes(pathname.split('/')[1]) || isSearchPage;
  const menuIsOpen = isPublicNavOpen || isAccountNavOpen || openMenuIndex !== -1;

  const hasShadow = isTransparentMode ? false : isWorkspacesOrSearchPage ? false : reduxHasShadow;

  const handleScrollY = (scrollY: number) => {
    if (scrollY > PUBLIC_NAV_HEIGHT) {
      setIsTransparentMode(false);
      setOpacity(1);
    } else {
      if (scrollY === 0) {
        setOpacity(0);
      } else {
        const pct_scrolled = scrollY / PUBLIC_NAV_HEIGHT;
        setOpacity(0.85 * pct_scrolled + 0.15);
      }
      setIsTransparentMode(true);
    }
  };

  return (
    <StickyHeader
      top={0}
      zIndex={HEADER_Z_INDEX}
      shadow={hasShadow}
      backgroundColor={isTransparentMode ? 'transparent' : undefined}
      background={`rgba(255, 255, 255, ${menuIsOpen ? 1 : opacity})`}
      transition="background 200ms"
      onMouseEnter={isTransparentMode ? () => setOpacity(1) : undefined}
      onMouseLeave={
        isTransparentMode
          ? () => {
              handleScrollY(window.scrollY);
            }
          : undefined
      }
      onScroll={isTransparentMode ? scrollY => handleScrollY(scrollY) : undefined}
    >
      {isHomePage && (lg || !(isPublicNavOpen || isAccountNavOpen)) && <WeWorkBanner />}
      {lg ? (
        <Navigation
          showCta={showCta}
          isSearchPage={isSearchPage}
          isTransparentMode={isTransparentMode}
          textOpacity={menuIsOpen ? 1 : opacity}
          user={user}
          openMenuIndex={openMenuIndex}
          setOpenMenuIndex={setOpenMenuIndex}
        />
      ) : (
        <NavigationMobile
          showCta={showCta}
          isSearchPage={isSearchPage}
          isTransparentMode={isTransparentMode}
          setIsAccountNavOpen={setIsAccountNavOpen}
          isAccountNavOpen={isAccountNavOpen}
          setIsPublicNavOpen={setIsPublicNavOpen}
          isPublicNavOpen={isPublicNavOpen}
          textOpacity={opacity}
          user={user}
        />
      )}
    </StickyHeader>
  );
};

export default Header;
