import React, { useContext } from 'react';
import { Image } from '@codiwork/codi';

import { media } from 'context';
import { ListingCTAProps } from 'ux/Public/Listing/types';

import Desktop from './Desktop';
import Mobile from './Mobile';

type Props = {
  images: Image[];
  imageIndex: number;
  floorPlans: Image[];
  matterportId: string | null;
  tourVideoKey: string | null;
  onClose: VoidFunction;
  setImageIndex: (value: number) => void;
  name?: String;
  modalZIndex?: number;
  isOffsite?: boolean;
} & ListingCTAProps;

const MediaModal: React.FC<Props> = ({
  images,
  imageIndex,
  floorPlans,
  matterportId,
  tourVideoKey,
  setImageIndex,
  onClose,
  name,
  ...props
}) => {
  const { xs } = useContext(media);

  return (
    <>
      {xs ? (
        <Mobile
          images={images}
          imageIndex={imageIndex}
          floorPlans={floorPlans}
          matterportId={matterportId}
          tourVideoKey={tourVideoKey}
          onClose={onClose}
          setImageIndex={setImageIndex}
          name={name}
          {...props}
        />
      ) : (
        <Desktop
          images={images}
          imageIndex={imageIndex}
          floorPlans={floorPlans}
          matterportId={matterportId}
          tourVideoKey={tourVideoKey}
          onClose={onClose}
          setImageIndex={setImageIndex}
          {...props}
        />
      )}
    </>
  );
};

export default MediaModal;
