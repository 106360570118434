import React, { useMemo, useState } from 'react';
import { getUser } from '@codiwork/codi';

import { showSnackbar } from 'helpers/snackbar';

import { DaysOfWeekSelector, Icon, Layout, PillButton, RequestState, Select, Text } from 'ds';
import { formatUsd } from 'helpers';
import { selectWeeklyCleaningProduct, selectWeeklyTouchUpProduct } from 'store/App/selectors';
import { actions as chatbotActions } from 'store/Chatbot';
import { actions } from 'store/User';
import { selectCustomerAdminPlans, selectLoggedInUserId, selectOffices, selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resolveIntent } from 'ux/Chatbot/requests';
import { ChatbotIntentProps } from 'ux/Chatbot/types';
import { checkoutCart, createCart, createCartItem, deleteCartItem, getCart } from 'ux/Customer/Cart/requests';
import { makeCartItemsfromCleaningSchedules } from 'ux/Customer/ServicesAndAmenities/Cleaning/EditCleaningSchedule/utils';

interface Props extends ChatbotIntentProps {
  goBack: () => void;
}

const EditRecurringCleanings: React.FC<Props> = ({ chatbotInteraction, goBack, threadId }) => {
  const { id: chatbotInteractionId } = chatbotInteraction;
  const offices = useAppSelector(selectOffices);
  const user = useAppSelector(selectUser);
  const userId = useAppSelector(selectLoggedInUserId);
  const activeWorkspaceIds = user?.customer_admin_plans?.long_term_plans.map(p => p.active_workspace_id) || [];
  const [workspaceId, setWorkspaceId] = useState<number>(activeWorkspaceIds[0] || -1);
  const plan = useAppSelector(selectCustomerAdminPlans)?.long_term_plans.find(p =>
    p.active_workspace_ids.includes(workspaceId)
  );
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const weeklyCleaningSchedule = plan?.weekly_cleaning_and_touch_up_schedule;
  const cleaningSchedule = weeklyCleaningSchedule?.filter(s => s.value === 'cleaning') || [];
  const touchUpSchedule = weeklyCleaningSchedule?.filter(s => s.value === 'touch-up') || [];
  const numCleaningDays = cleaningSchedule.length;
  const numTouchUpDays = touchUpSchedule.length;
  const [cleaningDaysOfWeek, setCleaningDays] = useState<number[]>(cleaningSchedule.map(s => s.wday));
  const [touchUpDaysOfWeek, setTouchupDays] = useState<number[]>(touchUpSchedule.map(s => s.wday));
  const dispatch = useAppDispatch();
  const weeklyCleaningProduct = useAppSelector(selectWeeklyCleaningProduct);
  const weeklyTouchUpProduct = useAppSelector(selectWeeklyTouchUpProduct);
  const cleaningScheduleCartItems = useMemo(
    () =>
      plan?.cart?.items.filter(
        item => item.product.id === weeklyCleaningProduct?.id || item.product.id === weeklyTouchUpProduct?.id
      ),
    [plan?.cart?.items, weeklyCleaningProduct?.id, weeklyTouchUpProduct?.id]
  );

  const hasMultipleWorkspaces = activeWorkspaceIds.length > 1;

  const workspaceOptions = offices
    .filter(o => activeWorkspaceIds.includes(o.id))
    .map(office => ({ label: office.address.line1, value: office.id }));
  const cart = plan?.cart;

  const handleSave = async () => {
    if (!(plan && weeklyCleaningProduct && weeklyTouchUpProduct && weeklyCleaningSchedule)) return;

    setRequestState('in_progress');

    // if there's already weekly cleaning change cart items, we need to remove them first
    if (cart && cart.id && cleaningScheduleCartItems && cleaningScheduleCartItems.length > 0) {
      await Promise.all(
        cleaningScheduleCartItems.map(item => deleteCartItem({ userId, cartId: cart.id, cartItemId: item.id }))
      );
    }
    let cartId: number;

    if (plan.cart?.id) {
      cartId = plan.cart.id;
    } else {
      const cart = {
        userId,
        planId: plan.id
      };
      const { data } = await createCart(cart);

      cartId = data.id;
    }

    const newSchedule = weeklyCleaningSchedule.map(d => {
      const isCleaning = cleaningDaysOfWeek.includes(d.wday);
      const isTouchup = touchUpDaysOfWeek.includes(d.wday);
      const isInNewSchedule = isCleaning || isTouchup;

      const wasInOldSchedule = !!d.value;
      let price = 0;

      if ((isInNewSchedule && !wasInOldSchedule) || (isInNewSchedule && wasInOldSchedule && d.price > 0)) {
        price = (isCleaning
          ? plan.monthly_once_per_week_cleaning_price
          : plan.monthly_once_per_week_touch_up_price) as number;
      }

      return {
        ...d,
        value: isCleaning ? ('cleaning' as const) : isTouchup ? ('touch-up' as const) : null,
        price
      };
    });

    const cartItems = makeCartItemsfromCleaningSchedules({
      newSchedule,
      existingSchedule: weeklyCleaningSchedule,
      cartId,
      userId,
      weeklyCleaningProductId: weeklyCleaningProduct.id,
      weeklyTouchUpProductId: weeklyTouchUpProduct.id
    });

    if (!cartItems.length) {
      showSnackbar({ negative: true, message: 'Something went wrong' });
      setRequestState('ready');
      return;
    }

    await Promise.all(cartItems.map(item => createCartItem(item)));

    const { data: updatedCart } = await getCart({ userId, cartId });

    const { data: addOnOrder } = await checkoutCart({ userId, cartId: updatedCart.id, planId: plan.id });

    const { data: updatedInteraction } = await resolveIntent({
      chatbot_interaction_id: chatbotInteractionId,
      slots: {
        planId: plan.id,
        cleaningDaysOfWeek,
        touchUpDaysOfWeek,
        recurrenceType: 'recurring',
        addOnOrderId: addOnOrder.id
      }
    });
    dispatch(chatbotActions.updateInteraction({ interaction: updatedInteraction, threadId }));
    const { data: user } = await getUser({ id: userId });
    dispatch(actions.setUser(user));
    setRequestState('ready');
  };

  if (!plan) return null;

  const calculatePrice = ({
    cleaningDaysOfWeek,
    touchUpDaysOfWeek
  }: {
    cleaningDaysOfWeek: number[];
    touchUpDaysOfWeek: number[];
  }) => {
    return (
      (cleaningDaysOfWeek.length - numCleaningDays) * (plan.monthly_once_per_week_cleaning_price || 0) +
      // Do not allow a negative touch-up price here even if the user is upgrading touch-ups to cleanings
      // because we do not represent this price change (a recurring line item stopping) in the cart.
      Math.max(touchUpDaysOfWeek.length - numTouchUpDays, 0) * (plan.monthly_once_per_week_touch_up_price || 0)
    );
  };

  const price = calculatePrice({ cleaningDaysOfWeek, touchUpDaysOfWeek });

  return (
    <Layout paddingX={16} paddingTop={24}>
      {hasMultipleWorkspaces && (
        <Layout>
          <Select
            label="Select office"
            size="xxs"
            value={workspaceId}
            options={workspaceOptions}
            onChange={({ value }) => setWorkspaceId(value)}
            fullWidth
          />
        </Layout>
      )}
      <Text size="body-md" semibold>
        Select your desired schedule
      </Text>
      <Layout align="center" marginTop={16}>
        <Icon name="cleaningDeep" size="lg" />
        <Layout marginLeft={20}>
          <DaysOfWeekSelector
            onSelect={days => {
              if (days.length < numCleaningDays) return;

              setCleaningDays([...days]);
              setTouchupDays([...touchUpDaysOfWeek.filter(d => !days.includes(d))]);
            }}
            selected={cleaningDaysOfWeek}
            excludedDays={[0, 6]}
          />
        </Layout>
      </Layout>
      <Layout align="center" marginTop={16}>
        <Icon name="featherDuster" size="lg" />
        <Layout marginLeft={20}>
          <DaysOfWeekSelector
            onSelect={days => {
              const updatedCleaningDaysOfWeek = cleaningDaysOfWeek.filter(d => !days.includes(d));

              const isSwitching = days.filter(d => cleaningDaysOfWeek.includes(d));

              if (isSwitching && updatedCleaningDaysOfWeek.length < numCleaningDays) {
                return;
              }

              setTouchupDays([...days]);
              setCleaningDays([...updatedCleaningDaysOfWeek]);
            }}
            selected={touchUpDaysOfWeek}
            excludedDays={[0, 6]}
          />
        </Layout>
      </Layout>
      <Layout justify="space-between" gap={8} paddingY={16} paddingX={12}>
        <PillButton
          type="secondary"
          size="md"
          text="Back"
          onClick={goBack}
          icon="leftArrow"
          iconPosition="left"
          showSpinner={requestState === 'in_progress'}
        />
        <PillButton
          type="purpleGradient"
          size="md"
          text={`Confirm • ${formatUsd(price)}/mo`}
          onClick={handleSave}
          showSpinner={requestState === 'in_progress'}
          fullWidth
        />
      </Layout>
    </Layout>
  );
};

export default EditRecurringCleanings;
