import React, { useRef, useState } from 'react';
import { ValidationResult, validateNumber } from '@codiwork/codi';
import { MaskedNumber, MaskedPattern, createMask } from 'imask';

import Input from './Input';
import { BasePropsNumber, InputImageIconProps, InputProps, OnChangeParams, ValidateProp } from './types';
import useMask from './useMask';
import useValidate from './useValidate';
import { ReactInputProps } from '../types';

export type Props = BasePropsNumber &
  InputProps &
  InputImageIconProps &
  ValidateProp & {
    min?: number;
    max?: number;
    step?: number;
    precision?: number;
    suffix?: string;
  };

const NumberInput: React.FC<Props> = ({
  min = 0,
  max = Number.MAX_SAFE_INTEGER / 10,
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  size,
  onValidate,
  required = true,
  onBlur,
  step = 1,
  disabled,
  validateOnBlur = true,
  precision = 0,
  hasErrorHeight,
  suffix,
  icon,
  hideLabel
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const maskInputValue = numberValueToString({ value, precision });
  const [mask] = useMask({
    mask: createMask({ mask: Number, signed: min < 0, thousandsSeparator: ',', radix: '.', scale: precision }),
    value: maskInputValue,
    numberValue: value
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const [valueIsMinusSign, setValueIsMinusSign] = useState<boolean>(false);
  const [valueIsDecimalPoint, setValueIsDecimalPoint] = useState<boolean>(false);
  const [valueIsZeroWithDecimalPoint, setValueIsZeroWithDecimalPoint] = useState<boolean>(false);

  const validate = (value?: number): ValidationResult => {
    if (value === undefined) {
      return required ? { valid: false, error: 'Please enter a valid number' } : { valid: true };
    } else {
      return validateNumber({ num: Number(value), min, max });
    }
  };

  useValidate({ value: value === null ? undefined : value, onValidate, validate, setErrorMessage });

  const onChangeWrapper = ({ event, ...params }: OnChangeParams) => {
    let value = params.value;
    mask.value = value;

    if (value === '-') {
      setValueIsMinusSign(true);
      return;
    } else {
      setValueIsMinusSign(false);
    }

    if (value.endsWith('.') && value.split('.').length === 2) {
      setValueIsDecimalPoint(true);
    } else {
      setValueIsDecimalPoint(false);
    }

    if (value.endsWith('0') && value.split('.').length === 2 && value.split('.')[1].length === 1) {
      setValueIsZeroWithDecimalPoint(true);
    } else {
      setValueIsZeroWithDecimalPoint(false);
    }

    const unmaskedValue = mask.unmaskedValue;
    let numberValue = inputValueToNumber({ value: unmaskedValue });

    if (typeof max === 'number' && typeof numberValue === 'number' && numberValue > max) {
      return;
    }

    const validationResult = validate(numberValue);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onValidate && onValidate(validationResult);

    onChange({ event, value: numberValue });
  };

  const setInputValue = (value: string) => {
    const input = inputRef.current;

    if (!input) return;

    const valueSetter = Object.getOwnPropertyDescriptor(input, 'value')?.set;
    const prototype = Object.getPrototypeOf(input);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

    if (valueSetter && valueSetter !== prototypeValueSetter && prototypeValueSetter) {
      prototypeValueSetter.call(input, value);
    } else if (valueSetter) {
      valueSetter.call(input, value);
    }
  };

  const handleKeyDown: ReactInputProps['onKeyDown'] = ({ key }) => {
    const input = inputRef.current;

    if (!input) return;

    if (key !== 'ArrowUp' && key !== 'ArrowDown') return;

    const unmaskedValue = mask.unmaskedValue;
    const numberValue = inputValueToNumber({ value: unmaskedValue }) || 0;

    let newValue = key === 'ArrowUp' ? numberValue + step : numberValue - step;
    if (typeof max === 'number' && newValue > max) {
      newValue = max;
    } else if (typeof min === 'number' && newValue < min) {
      newValue = min;
    }

    const unformatted = numberValueToString({ value: newValue, precision });
    mask.resolve(unformatted);
    const newInputValue = mask.value;
    setInputValue(newInputValue);

    input.dispatchEvent(new Event('input', { bubbles: true }));
  };

  const maskedValue = getMaskedValue({ mask, value, precision });
  const inputValue = valueIsMinusSign
    ? '-'
    : valueIsDecimalPoint
    ? `${maskedValue}.`
    : valueIsZeroWithDecimalPoint
    ? `${maskedValue}.0`
    : value === undefined
    ? ''
    : maskedValue;

  return (
    <Input
      onBlur={e => {
        if (e.value === '-') {
          setInputValue('');
          return;
        }

        mask.value = e.value;
        const unmaskedValue = mask.unmaskedValue;
        let numberValue: number | undefined = Number(unmaskedValue);

        if (unmaskedValue === '') {
          numberValue = undefined;
        }

        if (validateOnBlur) setErrorMessage(validate(numberValue).error);

        onBlur && onBlur({ ...e, value: numberValue });
      }}
      name={name}
      value={inputValue}
      label={label}
      placeholder={placeholder}
      onChange={onChangeWrapper}
      size={size}
      error={error || errorMessage}
      required={required}
      disabled={disabled}
      min={min}
      max={max}
      onKeyDown={handleKeyDown}
      inputMode="numeric"
      ref={inputRef}
      hasErrorHeight={hasErrorHeight}
      suffix={suffix}
      icon={icon}
      hideLabel={hideLabel}
    />
  );
};

const numberValueToString = ({ value, precision }: { value?: number | null; precision?: number }) => {
  return value === undefined || value === null ? '' : value.toFixed(precision);
};

const inputValueToNumber = ({ value }: { value: string }) => {
  if (value === '') return undefined;

  const numberValue = Number(value);

  return isNaN(numberValue) ? undefined : numberValue;
};

const getMaskedValue = ({
  mask,
  value,
  precision
}: {
  value?: number | null;
  precision?: number;
  mask: MaskedPattern<string> | MaskedNumber;
}) => {
  const maskInputValue = numberValueToString({ value, precision });
  mask.resolve(maskInputValue);

  return mask.value;
};

export default NumberInput;
