import Layout from 'ds/Layout';

interface Props {
  size: number;
  isActive: boolean;
  type: 'light' | 'dark';
}

const Dot: React.FC<Props> = ({ size, isActive, type }) => {
  const ANIMATION_DURATION = 500;

  return (
    <Layout width={8} direction="column" align="center">
      <Layout
        marginX={1}
        borderRadius="circular"
        color={type === 'light' ? 'white' : 'gray-900'}
        width={size}
        height={size}
        opacity={type === 'light' ? (isActive ? 1 : 0.6) : isActive ? 0.7 : 0.3}
        transition={`opacity, width, height, ${ANIMATION_DURATION}ms`}
      />
    </Layout>
  );
};

export default Dot;
