import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { apiTrack } from 'lib/analytics';
import { OFFICE_REQUEST_PATH } from 'routes';
import { actions } from 'store/OfficeRequest';
import {
  selectExitModalIsVisible,
  selectLaunchPath,
  selectOfficeRequestLocations,
  selectOfficeRequestMarket
} from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ExitModal from './ExitModal';
import Step0 from './Step0';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step7 from './Step7';
import { generateStepPath } from './utils';

interface Props {}

const OfficeRequest: React.FC<Props> = () => {
  const market = useAppSelector(selectOfficeRequestMarket);
  const locations = useAppSelector(selectOfficeRequestLocations);
  const { pathname, state } = useLocation<{ prevPath?: string }>();
  let step = parseInt(pathname.split(OFFICE_REQUEST_PATH + '/').slice(-1)[0]);
  step = isNaN(step) ? 0 : step;
  const _launchPath = useAppSelector(selectLaunchPath);
  const launchPath = state?.prevPath || _launchPath;
  const dispatch = useAppDispatch();
  const exitModalIsVisible = useAppSelector(selectExitModalIsVisible);

  useEffect(() => {
    const initialStep = !locations ? 0 : step;
    if (!!market && market !== 'San Francisco Bay Area') {
      dispatch(actions.setDaysPerWeek(5));
    }

    apiTrack('Office Request Started', {
      launchPath: launchPath || null,
      market: market,
      initialStep
    });

    if (!!launchPath) {
      dispatch(actions.setLaunchPath(launchPath));
    }

    return () => {
      dispatch(actions.setExitModalIsVisible(false));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!locations.length && step && step !== 0) {
    return <Redirect to={generateStepPath({ step: 0 })} />;
  }

  return (
    <>
      <ExitModal isVisible={exitModalIsVisible} />
      <Switch>
        <Route path={generateStepPath({ step: 0 })} exact>
          <Step0 />
        </Route>
        <Route path={generateStepPath({ step: 2 })} exact>
          <Step2 />
        </Route>
        <Route path={generateStepPath({ step: 3 })} exact>
          <Step3 />
        </Route>
        <Route path={generateStepPath({ step: 4 })} exact>
          <Step4 />
        </Route>
        <Route path={generateStepPath({ step: 5 })} exact>
          <Step5 />
        </Route>
        <Route path={generateStepPath({ step: 7 })} exact>
          <Step7 />
        </Route>
        <Redirect to={generateStepPath({ step: 0 })}></Redirect>
      </Switch>
    </>
  );
};

export default OfficeRequest;
