import React, { Children, useContext, useRef, useState } from 'react';
import SwiperClass, { A11y, Virtual } from 'swiper';

import { media } from 'context';
import { Swiper, SwiperProps } from 'swiper/react';

import { NO_SWIPING_CLASS } from '.';
import Dots from './Dots';
import CarouselArrow from '../CarouselArrow';
import Layout from '../Layout';

export const PHONE_QUERY = '(min-width: 0) and (max-width: 499px)';

export interface Props extends SwiperProps {
  arrowOffset?: number;
}

const CardCarousel: React.FC<Props> = ({ children, arrowOffset = -4, ...props }) => {
  const mediaContext = useContext(media);
  const swiperRef = useRef<SwiperClass>();
  const [index, setIndex] = useState<number>(0);
  const { xs, sm, md, lg } = mediaContext;
  const isTouch = xs || sm;
  const isMouse = md || lg;
  const itemLength = Children.toArray(children).length;

  return (
    <Layout position="relative">
      <Swiper
        {...(isTouch ? { style: { overflow: 'visible' } } : {})}
        virtual
        {...props}
        modules={[A11y, Virtual]}
        onInit={swiper => {
          swiperRef.current = swiper;
        }}
        onActiveIndexChange={params => {
          setIndex(params.realIndex);

          props.onActiveIndexChange && props.onActiveIndexChange(params);
        }}
        className="CardCarousel"
        noSwiping={isMouse}
        noSwipingClass={NO_SWIPING_CLASS}
        slidesPerView={1}
      >
        {children}
      </Swiper>
      {isMouse && (
        <>
          <Layout position="absolute" left={arrowOffset} top="50%" transform="translateY(-50%)" zIndex={1} padding={8}>
            <CarouselArrow
              direction="left"
              onClick={() => {
                const swiper = swiperRef.current;

                if (!swiper) return;

                swiper.slidePrev();
              }}
            />
          </Layout>
          <Layout position="absolute" right={arrowOffset} top="50%" transform="translateY(-50%)" zIndex={1} padding={8}>
            <CarouselArrow
              direction="right"
              onClick={() => {
                const swiper = swiperRef.current;

                if (!swiper) return;

                swiper.slideNext();
              }}
            />
          </Layout>
        </>
      )}
      <Layout bottom={16} position="absolute" left="50%" __style={{ transform: 'translateX(-50%)' }} zIndex={1} flex>
        <Dots length={itemLength} activeIndex={index} />
      </Layout>
    </Layout>
  );
};

export default CardCarousel;
