import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import { app } from 'context';
import { Layout } from 'ds';
import { track } from 'lib/analytics';

import { ReactComponent as Play } from 'assets/images/enterprise/play.svg';

interface Props {
  videoUrl: string;
  thumbnailUrl: string;
  showBorder?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
}

const VideoPlayer: React.FC<Props> = ({
  videoUrl,
  thumbnailUrl,
  showBorder = false,
  rounded = true,
  fullWidth = false
}) => {
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const [playPressed, setPlayPressed] = useState<boolean>(false);

  const { contentPaddingX } = useContext(app);

  return (
    <Layout
      position="relative"
      left={fullWidth ? -contentPaddingX : undefined}
      width={fullWidth ? '100vw' : '100%'}
      height="100%"
      borderRadius={rounded ? 40 : undefined}
      borderColor="gray-600"
      borderWidth={showBorder ? 1 : undefined}
      overflow="hidden"
    >
      <Layout
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        borderRadius={rounded ? 40 : undefined}
        overflow="hidden"
        zIndex={1}
        color="white"
      >
        <ReactPlayer
          width="100%"
          height="100%"
          url={videoUrl}
          playing={videoPlaying}
          onPlay={() => {
            playPressed && track('Element Interacted', { context: 'vimeo player', name: 'play', type: 'button' });
            !playPressed && setPlayPressed(true);
          }}
          onPause={() => {
            track('Element Interacted', { context: 'vimeo player', name: 'pause', type: 'button' });
          }}
        />
      </Layout>
      <Layout
        position="relative"
        visibility={videoPlaying ? 'hidden' : 'visible'}
        justify="center"
        align="center"
        zIndex={2}
      >
        <Layout borderRadius={rounded ? 40 : undefined} overflow="hidden" color="white" opacity={videoPlaying ? 0 : 1}>
          <img src={thumbnailUrl} alt="Video thumbnail" />
        </Layout>
        <PlayIcon setVideoPlaying={setVideoPlaying} />
      </Layout>
    </Layout>
  );
};

export default VideoPlayer;

interface PlayIconProps {
  setVideoPlaying: Dispatch<SetStateAction<boolean>>;
}

const PlayIcon: React.FC<PlayIconProps> = ({ setVideoPlaying }) => {
  return (
    <div
      style={{ position: 'absolute', width: '100%', height: '100%', cursor: 'pointer', zIndex: 2 }}
      onClick={() => {
        setVideoPlaying(true);
        track('Element Interacted', { context: 'video player', name: 'play', type: 'button' });
      }}
      className="VideoPlayer-control"
    >
      <Layout width="100%" height="100%" justify="center" align="center">
        <Layout justify="center" align="center" __className={classNames('VideoPlayer-playIcon')}></Layout>
        <Layout position="absolute" paddingLeft={4} paddingTop={4}>
          <Play />
        </Layout>
      </Layout>
    </div>
  );
};
