import axios from '@codiwork/codi/lib/lib/axios';

import { ContactFormInbox } from './types';

const POST_CONTACT_FORM_PATH = '/v1/contact' as const;

interface ContactFormParams {
  firstname?: string;
  lastname?: string;
  email?: string;
  message?: string;
  inbox: ContactFormInbox;
}

export function postContactForm(params: ContactFormParams) {
  return axios.post(POST_CONTACT_FORM_PATH, params);
}
