import React from 'react';

import PlaybookStepPage from './PlaybookStepPage';

interface Props {}

const ReviewYourOptions: React.FC<Props> = () => {
  return <PlaybookStepPage title="Review Your Options" activeStep={1} />;
};

export default ReviewYourOptions;
