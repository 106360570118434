import { Reducer, UnknownAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { combineReducers } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AddProperty from 'store/AddProperty';
import Admin from 'store/Admin';
import App from 'store/App';
import OfficeRequest from 'store/OfficeRequest';
import Properties from 'store/Properties';
import Search, { SearchState } from 'store/Search';
import UI from 'store/UI';
import User, { USER_INITIAL_STATE } from 'store/User';

import Auth, { AUTH_INITIAL_STATE, LOGOUT } from './Auth';
import Chatbot from './Chatbot';
import Offices from './Offices';
import STBRequest from './STBRequest';

// Never run the input stability check.
// setGlobalDevModeChecks({ identityFunctionCheck: 'never' });

const appPersistConfig = {
  key: 'App',
  whitelist: ['countries', 'cmsPages', 'locations'],
  storage
};

const whiteListSearch = createTransform<SearchState, Partial<SearchState>, SearchState>((inboundState, key) => {
  if (key === 'filters') {
    return pick(inboundState, ['daysPerWeek']);
  }

  return inboundState;
});

const searchPersistConfig = {
  key: 'Search',
  whitelist: ['filters'],
  transforms: [whiteListSearch],
  storage
};

const rootPersistConfig = {
  key: 'root',
  whitelist: [],
  storage
};

const uiPersistConfig = {
  key: 'UI',
  whitelist: ['defaultTypeaheadResults', 'selectedOfficeId'],
  storage
};

const combinedReducer = combineReducers({
  Admin,
  App: persistReducer(appPersistConfig, App),
  Auth,
  UI: persistReducer(uiPersistConfig, UI),
  Properties,
  Search: persistReducer(searchPersistConfig, Search),
  User,
  OfficeRequest,
  STBRequest,
  AddProperty,
  Chatbot,
  Offices
});

type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer<RootState, UnknownAction> = (state, action) => {
  if (action.type === LOGOUT) {
    state = {
      App: state?.App,
      Search: state?.Search,
      User: { ...USER_INITIAL_STATE, user: null },
      Auth: { ...AUTH_INITIAL_STATE }
    } as RootState;
  }

  return combinedReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
