import React, { MutableRefObject, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useViewportSpy } from 'beautiful-react-hooks';
import classNames from 'classnames';

import Layout, { Props as LayoutProps } from './Layout';
import { DS_CLASS_NAME, IS_TOUCH_DEVICE } from './constants';

interface Props extends LayoutProps {
  threshold?: number;
  delay?: number | string;
  rootMargin?: number | string;
  animation?: 'fade-in' | 'fade-up' | 'fade-down' | 'fade-in-from-left' | 'fade-in-from-right' | 'fade-in-from-top';
  fadeOut?: boolean;
  keyframes?: Keyframe[];
  duration?: number;
  easing?: EffectTiming['easing'];
  visible?: boolean;
}

const DEFAULT_DURATION = 800;
const Animate: React.FC<PropsWithChildren<Props>> = ({
  children,
  rootMargin,
  animation = 'fade-up',
  delay,
  keyframes = [],
  __style,
  easing = 'cubic-bezier(0.4, 0, 0.2, 1)',
  visible,
  fadeOut,
  __ref,
  ...props
}) => {
  const localRef = useRef<HTMLDivElement>(null);
  const ref = __ref || localRef;
  const threshold = typeof props.threshold === 'number' ? props.threshold : IS_TOUCH_DEVICE ? 0.1 : 0.3;
  const duration = typeof props.duration === 'number' ? props.duration : IS_TOUCH_DEVICE ? 600 : DEFAULT_DURATION;
  const isInViewport = useViewportSpy(ref as MutableRefObject<HTMLDivElement>, {
    threshold,
    rootMargin: typeof rootMargin === 'number' ? `${rootMargin}px` : rootMargin
  });
  const isVisible = visible === undefined ? isInViewport : visible;
  const [hasAppeared, setHasAppeared] = useState<boolean>(isVisible);

  useEffect(() => {
    const element = ref.current;

    if (!keyframes.length || !element || !isVisible || hasAppeared) return;

    element.animate(keyframes, { duration, easing });
  }, [keyframes.length, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasAppeared || !isVisible) return;

    setHasAppeared(true);
  }, [isVisible, hasAppeared]);

  const style = { ...__style, animationDelay: typeof delay === 'number' ? `${delay}ms` : delay };

  if (duration !== DEFAULT_DURATION) {
    style.animationDuration = `${duration}ms`;
  }

  return (
    <Layout
      __className={classNames(
        `${DS_CLASS_NAME}-animate`,
        keyframes.length ? undefined : `${DS_CLASS_NAME}-${fadeOut && !isVisible ? 'fade-out' : animation}`,
        {
          'is-visible': isVisible || hasAppeared
        }
      )}
      __ref={ref}
      __style={style}
      {...props}
    >
      {children}
    </Layout>
  );
};

export default Animate;
