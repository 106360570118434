import React, { useEffect, useRef, useState } from 'react';
import { usePreviousValue } from 'beautiful-react-hooks';
import classNames from 'classnames';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { formatUsd } from 'helpers';
import { track } from 'lib/analytics';

import { MapMarkerConfigType } from './types';

interface Props {
  onClick: VoidFunction;
  monthly_price: number;
  daysPerWeek: number;
  offsitesOnly?: boolean;
  daily_rate: number;
  id: number;
  visited?: boolean;
  isActive: boolean;
  context?: string;
  type: MapMarkerConfigType;
}

const PriceMarker: React.FC<Props> = ({
  onClick,
  monthly_price,
  daysPerWeek,
  offsitesOnly,
  daily_rate,
  visited,
  id,
  context,
  type,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const priceContainerRef = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect>();
  const [priceWidth, setPriceWidth] = useState<number>();

  const isActive = props.isActive || type === 'active' || type === 'active-liked';
  const isLiked = type === 'liked' || type === 'active-liked';

  const price = offsitesOnly
    ? formatUsd(daily_rate, false)
    : formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price, daysPerWeek });
  const previousPrice = usePreviousValue(price);

  useEffect(() => {
    const button = buttonRef.current;
    const priceContainer = priceContainerRef.current;

    if (!button || !priceContainer) return;

    setRect(button.getBoundingClientRect());
    setPriceWidth(priceContainer.getBoundingClientRect().width);
  }, [price]);

  return (
    <button
      ref={buttonRef}
      onClick={() => {
        onClick();
        track('Element Interacted', {
          type: 'button',
          name: 'price marker',
          action: 'select',
          value: id,
          context
        });
      }}
      className={classNames('PriceMarker', { 'is-active': isActive, 'is-liked': isLiked })}
      style={{
        position: 'relative',
        overflow: 'visible',
        display: 'inline-flex',
        ...(rect ? { left: rect.width / -2, top: -rect.height } : { opacity: 0 })
      }}
    >
      <Layout
        borderWidth={visited ? 1 : 0}
        borderColor="gray-100"
        boxShadow="0px 2px 4px 0px #00000021"
        display="inline-flex"
        __className={classNames('PriceMapMarker', {
          'is-active': isActive,
          'is-liked': isLiked,
          'is-visited': visited,
          'is-active-liked': type === 'active-liked'
        })}
        align="center"
        __ref={priceContainerRef}
        width={price !== previousPrice ? undefined : priceWidth ? priceWidth + (isLiked ? 24.15 : 0) : undefined}
        transition="width 0.3s"
        inline
      >
        <Layout
          position="absolute"
          top={6}
          left={12}
          inline
          marginRight={7}
          display="inline-flex"
          opacity={type === 'liked' || type === 'active-liked' ? 1 : 0}
          transition="opacity 0.2s"
          zIndex={4}
        >
          <Icon name="checkmark" size="xs" color={isActive ? 'blue-500' : 'white'} stroke={2} />
        </Layout>
        <span className="PriceMarker-price">
          <Text size="body2" color={isActive ? 'blue-500' : visited ? 'gray-900' : 'white'} semibold>
            {price}
          </Text>
        </span>
      </Layout>
    </button>
  );
};

export default PriceMarker;
