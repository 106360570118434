import React, { useState } from 'react';
import { Message } from '@codiwork/codi';

import Button from 'ds/Button';
import Layout from 'ds/Layout';
import RichTextEditor from 'ds/RichTextEditor';

import MessageElement from './Message';
import MessageImageUploader from './MessageImageUploader';
import { CreatableMessage } from './types';

interface Props {
  messages: Message[];
  onSave: (message: CreatableMessage) => void;
  requestInProgress: boolean;
}

const FIELD_GAP = 48;

const NEW_MESSAGE: CreatableMessage = {
  body: '<p><br></p>',
  images: []
};

const MessageThread: React.FC<Props> = ({ messages, onSave, requestInProgress }) => {
  const [newMessage, setNewMessage] = useState<CreatableMessage>(NEW_MESSAGE);

  const handleSave = () => {
    onSave(newMessage);
    setNewMessage(NEW_MESSAGE);
  };

  const { body, images } = newMessage;
  const isValid = !!body && body !== '<p><br></p>';
  const imagesOrEmptyArray = images || [];

  return (
    <Layout direction="column">
      <Layout marginTop={FIELD_GAP} direction="column">
        <RichTextEditor
          value={body}
          onChange={value => {
            setNewMessage({ ...newMessage, body: value });
          }}
          placeholder={!!messages.length ? 'Add to the conversation...' : 'Write a comment...'}
          readOnly={requestInProgress}
        />
        <Layout marginTop={8} direction="row" justify="space-between" minHeight={98}>
          <MessageImageUploader
            images={imagesOrEmptyArray}
            onChange={files => {
              setNewMessage({
                ...newMessage,
                images: [
                  ...imagesOrEmptyArray,
                  ...files.map(file => {
                    return { url: URL.createObjectURL(file), file };
                  })
                ]
              });
            }}
            onDeleteClick={image => {
              setNewMessage({ ...newMessage, images: imagesOrEmptyArray.filter(i => i.url !== image.url) });
            }}
            disabled={requestInProgress}
          />
          <Layout minWidth={120} justify="flex-end">
            <Button
              type="primary"
              size="sm"
              text="Comment"
              onClick={handleSave}
              disabled={!isValid || requestInProgress}
              showSpinner={requestInProgress}
            />
          </Layout>
        </Layout>
      </Layout>
      {messages.map(message => (
        <MessageElement message={message} />
      ))}
    </Layout>
  );
};

export default MessageThread;
