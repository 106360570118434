import React, { useContext, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { media } from 'context';
import { CHATBOT_THREAD_INPUT_HEIGHT, Layout, ScrollShadowContainer } from 'ds';
import usePageVisibility from 'hooks/usePageVisibility';
import { actions } from 'store/Chatbot';
import {
  selectActiveChatbotThread,
  selectChatbotPreviousScreen,
  selectPendingThreadInteraction
} from 'store/Chatbot/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createOrUpdateSupportTicketView } from 'ux/Customer/Support/requests';

import ChatbotInteractionDisplay from './ChatbotInteractionDisplay';
import ChatbotNavigationHeader from './ChatbotNavigationHeader';
import ChatbotThinkingDisplay from './ChatbotThinkingDisplay';
import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import ChatbotThreadMessage from './ChatbotThreadMessage';
import CodiAIAvatar from './CodiAIAvatar';
import CustomerChatbotThreadInput from './CustomerChatbotThreadInput';
import { CHATBOT_HOME_SCREEN, CHATBOT_NAVIGATION_HEADER_HEIGHT } from './constants';
import useChatbotHeight from './useChatbotHeight';

interface Props {
  bottomSheetRef?: React.RefObject<HTMLDivElement>;
}

const ChatbotThread: React.FC<Props> = ({ bottomSheetRef }) => {
  const user = useAppSelector(selectUser);
  const thread = useAppSelector(selectActiveChatbotThread);
  const threadId = thread?.id || -1;
  const { isMobile } = useContext(media);
  const pendingInteraction = useAppSelector(selectPendingThreadInteraction(threadId));
  const lastInteraction = thread?.chatbot_interactions.slice(-1)[0];
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const previousScreen = useAppSelector(selectChatbotPreviousScreen);
  const height = useChatbotHeight();
  const supportTicket = lastInteraction?.support_ticket;
  const updateKey =
    (thread
      ? Math.max(...thread.chatbot_interactions.map(i => DateTime.fromISO(i.updated_at).toMillis()))
      : 0
    ).toString() +
    (pendingInteraction ? 'isPending' : '') +
    lastInteraction?.support_ticket?.messages.length;

  const handleSupportTicketView = async () => {
    if (supportTicket?.id) {
      const { data } = await createOrUpdateSupportTicketView({ supportTicketId: supportTicket.id });

      dispatch(actions.updateSupportTicket(data));
    }
  };

  usePageVisibility(isVisible => {
    if (isVisible) {
      handleSupportTicketView();
    }
  });

  const scrollToBottom = () => {
    const container = isMobile ? bottomSheetRef?.current : containerRef.current;

    if (!container) return;

    container.scrollTo({ top: container.scrollHeight });
  };

  useEffect(() => {
    scrollToBottom();
  }, [updateKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!supportTicket?.id) return;

    handleSupportTicketView();
  }, [supportTicket?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useAppDispatch();

  if (!user) return null;

  const header = (
    <ChatbotNavigationHeader backScreenValue={previousScreen.value || CHATBOT_HOME_SCREEN} hasShadow={hasScrolled}>
      <CodiAIAvatar />
    </ChatbotNavigationHeader>
  );

  return (
    <>
      {isMobile ? (
        <Layout position="sticky" top={0} zIndex={1}>
          {header}
        </Layout>
      ) : (
        header
      )}
      <ScrollShadowContainer
        setHasScrolled={setHasScrolled}
        position="relative"
        color="blue-50"
        paddingX={24}
        __ref={containerRef}
        {...(isMobile
          ? { paddingTop: 24 }
          : {
              height: height - CHATBOT_NAVIGATION_HEADER_HEIGHT - CHATBOT_THREAD_INPUT_HEIGHT,
              overflowY: 'scroll',
              paddingY: 20
            })}
      >
        <Layout marginRight={24}>
          <ChatbotThreadMessage isLastMessageInGroup>
            Hi, {user.firstname}. How can I help you today?
          </ChatbotThreadMessage>
        </Layout>
        <Layout>
          {thread?.chatbot_interactions.map(ci => {
            return <ChatbotInteractionDisplay key={ci.id} chatbotInteraction={ci} threadId={threadId} />;
          })}
          {!!pendingInteraction && (
            <Layout marginTop={16} marginBottom={16}>
              <ChatbotThreadCustomerMessage isLastMessageInGroup>
                {pendingInteraction.input}
              </ChatbotThreadCustomerMessage>
            </Layout>
          )}
          {!!pendingInteraction && (
            <Layout marginTop={16}>
              <ChatbotThinkingDisplay />
            </Layout>
          )}
        </Layout>
      </ScrollShadowContainer>
      {!isMobile && <CustomerChatbotThreadInput />}
    </>
  );
};

export default ChatbotThread;
