import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ApiErrorResponse } from '@codiwork/codi';

import Head from 'components/Head';

import { app, media } from 'context';
import { Button, Icon, Img, Layout, Link, Text } from 'ds';
import { formatMoney } from 'helpers';
import { OFFICE_REQUEST_PATH } from 'routes';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

import { getReferralInfo } from './requests';
import { ReferralInfo } from '../types';

interface MatchProps {
  referralCode: string;
}

interface Props extends RouteComponentProps<MatchProps> {}

const ReferralLanding: React.FC<Props> = ({ match }) => {
  const { xs } = useContext(media);
  const { contentPaddingX, windowWidth } = useContext(app);
  const [referralInfo, setReferralInfo] = useState<ReferralInfo | null>();
  const dispatch = useAppDispatch();

  let referralCode = match.params.referralCode;

  useEffect(() => {
    getReferralInfo({ referralCode: referralCode })
      .then(({ data: referralInfo }) => {
        setReferralInfo(referralInfo);
      })
      .catch((_error: ApiErrorResponse) => {
        setReferralInfo(null);
      });
  }, [referralCode]);

  const imgSrcKey = !referralInfo ? 'referral-landing-404_8TurUpm-l.jpeg' : 'fidi-office-sf_2wjLmH2sM.jpeg';

  return (
    <>
      {!!referralInfo ? (
        <Head
          title={`${referralInfo.referrer.name} invites you to join Codi`}
          description={`Use this link to get ${Math.round(
            referralInfo.percentage_discount
          )}% off your first month of a private office space.`}
          omitTitleSuffix
        />
      ) : (
        <Head title="Referral Page" description={'Get a discount off your first month of private office space.'} />
      )}
      <Layout position="relative" width={windowWidth} height={xs ? 740 : 628} direction={xs ? 'column' : 'row'}>
        <Layout width={xs ? undefined : '50%'} height={xs ? 540 : undefined}>
          {referralInfo === undefined ? (
            <></>
          ) : referralInfo === null ? (
            <Layout height="100%" direction="column" justify="center" marginX={contentPaddingX}>
              <Text size={xs ? 'h5' : 'h3'} color="blue-700">
                Let's refer you
                <br />
                to another page...
              </Text>
              <Layout height={xs ? 36 : 24} />
              <Text size="body1" color="blue-700">
                Looks like the referral page you’re looking for doesn't exist. That's okay. Let’s find you an office!
              </Text>
              <Layout height={xs ? 36 : 40} />
              <Layout width={206}>
                <Button
                  text="Request an office"
                  size="md"
                  type="primary"
                  href={OFFICE_REQUEST_PATH}
                  icon="rightArrow"
                  iconPosition="right"
                />
              </Layout>
            </Layout>
          ) : (
            <Layout height="100%" direction="column" justify="center" marginX={contentPaddingX}>
              {!!referralInfo.referrer.logo_url && (
                <Layout marginBottom={12} width={56} height={56} borderRadius="circular" overflow="hidden">
                  <img alt={`${referralInfo.referrer.name} logo`} src={referralInfo.referrer.logo_url} />
                </Layout>
              )}
              <Text size={xs ? 'h5' : 'h4'} color="blue-700">
                {referralInfo.referrer.name} gifted you {Math.round(referralInfo.percentage_discount)}% off your first
                month.
              </Text>
              <Layout height={xs ? 36 : 24} />
              <Text size={xs ? 'body2' : 'body1'} color="blue-700">
                Codi spaces are private, furnished, fully serviced, and come with the most flexible lease terms. Move
                into an office and get {Math.round(referralInfo.percentage_discount)}% off your first month’s rent up to
                ${formatMoney(referralInfo.max_amount, 0)}.
              </Text>
              <Layout height={xs ? 36 : 40} />
              <Layout width={206}>
                <Button
                  text="Get started"
                  size="md"
                  type="primary"
                  onClick={() => dispatch(actions.setReferralInfo(referralInfo))}
                  href={OFFICE_REQUEST_PATH}
                  icon="rightArrow"
                  iconPosition="right"
                />
              </Layout>
              <Layout height={xs ? 36 : 40} />
              <Text size="body3" color="gray-700">
                Restrictions apply. See{' '}
                <Link href="/terms#referrals" target="_blank">
                  <Text size="body3" color="gray-700" semibold>
                    Referral Program terms
                  </Text>
                </Link>{' '}
                for details.
              </Text>
            </Layout>
          )}
        </Layout>
        <Layout width={xs ? undefined : '50%'} height={xs ? (!referralInfo ? 332 : 200) : undefined}>
          {referralInfo !== undefined && (
            <Layout position="absolute">
              <Img
                srcKey={imgSrcKey}
                folder="referral_landing"
                alt="office"
                height={xs ? (!referralInfo ? 332 : 200) : 628}
                width={xs ? windowWidth : windowWidth / 2}
                quality={100}
                style={{
                  objectFit: 'cover',
                  width: xs ? windowWidth : undefined,
                  position: 'relative'
                }}
              />
              {!!referralInfo && (
                <Layout
                  position="absolute"
                  bottom={xs ? 16 : 24}
                  left={24}
                  paddingX={12}
                  paddingY={8}
                  borderRadius={4}
                  background="rgba(255, 255, 255, 0.8)"
                  direction="row"
                  justify="center"
                  align="center"
                  width={238}
                >
                  <Icon name="addressPinOutline" size="xs" color="gray-900" />
                  <Layout marginLeft={4} />
                  <Text size="body3" semibold>
                    Financial District, San Francisco
                  </Text>
                </Layout>
              )}
            </Layout>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default ReferralLanding;
