import { useContext } from 'react';

import { media } from 'context';
import { useListFocus } from 'hooks';

import LocationResultsContainer from './LocationResultsContainer';
import LocationResultsList from './LocationResultsList';
import { BaseLocationResult } from './types';

interface Props<T extends BaseLocationResult> {
  results: T[];
  searchValue: string;
  onLocationSelect: (result: T | null) => void;
  showGoogleAttribution?: boolean;
  divId: string;
  isBottomSheet?: boolean;
}

const LocationResults = <T extends BaseLocationResult>({
  results,
  onLocationSelect,
  searchValue,
  showGoogleAttribution = true,
  divId,
  isBottomSheet
}: Props<T>) => {
  const { xs, sm } = useContext(media);

  const [focusedIndex] = useListFocus({
    divId,
    autoSelectFirstItem: searchValue.length > 0,
    size: results.length,
    handleEnter: index => {
      onLocationSelect(results[index]);
    }
  });

  const isMobile = xs || sm;

  const List = (
    <LocationResultsList
      key={results.length}
      results={results}
      onLocationSelect={onLocationSelect}
      searchValue={searchValue}
      isBottomSheet={isBottomSheet}
      focusedIndex={focusedIndex}
      showGoogleAttribution={showGoogleAttribution}
    />
  );

  return isMobile ? List : <LocationResultsContainer>{List}</LocationResultsContainer>;
};

export default LocationResults;
