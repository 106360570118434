import React, { useState } from 'react';
import { Image } from '@codiwork/codi';

import { generateImgSrc } from 'helpers/images';

import { Clickable, Icon, Layout, SwiperCarousel, Text, WORKSPACE_PHOTO_W_TO_H_RATIO, WorkspaceImg } from 'ds';
import MediaModal from 'ds/MediaModal';
import { NO_SWIPING_CLASS } from 'ds/SwiperCarousel';
import useKeyDown from 'ds/helpers/useKeyDown';
import { SwiperSlide } from 'swiper/react';

import { ListingCTAProps } from './types';

import { ReactComponent as Cube } from 'ds/MediaModal/Cube.svg';
import { ReactComponent as VideoCamera } from 'ds/MediaModal/VideoCamera.svg';

const RESPONSIVE_CONFIG = {
  lg: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  md: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  sm: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  xs: {
    slidesPerView: 1,
    spaceBetween: 24
  },
  phone: {
    slidesPerView: 1,
    spaceBetween: 24
  }
};

type Props = {
  images: Image[];
  floorPlans: Image[];
  matterportId: string | null;
  tourVideoKey: string | null;
} & ListingCTAProps;

const PhotoCarousel: React.FC<Props> = ({ images, floorPlans, matterportId, tourVideoKey, ...props }) => {
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [_imageWidth, setImageWidth] = useState<number>(0);

  const [imageIndex, setImageIndex] = useState<number>(-1);

  const launchLightbox = ({ startingIndex }: { startingIndex: number }) => {
    setImageIndex(startingIndex);
  };

  const imagesArray: (Image | string)[] = [...images];
  if (!!matterportId) {
    imagesArray.splice(2, 0, matterportId);
  } else if (!!tourVideoKey) {
    imagesArray.splice(2, 0, tourVideoKey);
  }

  useKeyDown(({ key }) => {
    if (imageIndex !== -1) return;

    switch (key) {
      case 'ArrowLeft':
        launchLightbox({ startingIndex: images.length - 1 });
        break;
      case 'ArrowRight':
        launchLightbox({ startingIndex: 0 });
        break;
      default:
        return;
    }
  });

  return (
    <>
      {imageIndex > -1 && (
        <MediaModal
          images={images}
          imageIndex={imageIndex}
          floorPlans={floorPlans}
          matterportId={matterportId}
          tourVideoKey={tourVideoKey}
          onClose={() => {
            setImageIndex(-1);
          }}
          setImageIndex={setImageIndex}
          {...props}
        />
      )}
      <SwiperCarousel
        config={RESPONSIVE_CONFIG}
        controlLayoutProps={{ left: -64, right: -64 }}
        leftArrow={<Icon size="xl" color="black" name="leftChevron" />}
        rightArrow={<Icon size="xl" color="black" name="rightChevron" />}
        virtual={false}
        lazy
        loop
      >
        {imagesArray.map((image, index) => {
          const isMatterportThumbnail = index === 2 && !!matterportId;
          const isTourVideoThumbnail = index === 2 && !!tourVideoKey && !isMatterportThumbnail;

          return (
            <SwiperSlide key={index} className={NO_SWIPING_CLASS}>
              <Clickable
                onClick={() => {
                  launchLightbox({ startingIndex: index });
                }}
                fullWidth
                name={`Image ${index + 1}`}
                value={
                  isMatterportThumbnail
                    ? '3D Walkthrough'
                    : isTourVideoThumbnail
                    ? 'Video Tour'
                    : generateImgSrc({ srcKey: (image as Image).key })
                }
              >
                {isMatterportThumbnail ? (
                  <Layout borderRadius={12} overflow="hidden">
                    <img
                      src={`https://my.matterport.com/api/v2/player/models/${matterportId}/thumb`}
                      alt="Matterport Thumbnail"
                      style={{ minHeight: imageHeight, objectFit: 'cover' }}
                    />
                    <Layout
                      position="absolute"
                      left={20}
                      bottom={18}
                      borderRadius={4}
                      color="gray-900"
                      paddingY={6}
                      paddingX={10}
                      align="center"
                    >
                      <Cube />
                      <Layout marginLeft={8} />
                      <Text size="body3" color="white" semibold>
                        3D Walkthrough
                      </Text>
                    </Layout>
                  </Layout>
                ) : isTourVideoThumbnail ? (
                  <Layout borderRadius={12} overflow="hidden">
                    <WorkspaceImg
                      size="sm"
                      srcKey={`${tourVideoKey}/ik-thumbnail.jpg`}
                      alt={`workspace ${index + 1}`}
                      style={{ borderRadius: 12 }}
                      onMeasure={({ height, width }) => {
                        setImageHeight(height);
                        setImageWidth(width);
                      }}
                    />
                    <Layout
                      position="absolute"
                      left={20}
                      bottom={18}
                      borderRadius={4}
                      color="gray-900"
                      paddingY={6}
                      paddingX={10}
                      align="center"
                    >
                      <VideoCamera />
                      <Layout marginLeft={8} />
                      <Text size="body3" color="white" semibold>
                        Video Tour
                      </Text>
                    </Layout>
                  </Layout>
                ) : (
                  <WorkspaceImg
                    size="sm"
                    srcKey={(image as Image).key}
                    alt={`workspace ${index + 1}`}
                    style={{
                      borderRadius: 12,
                      objectFit: 'cover',
                      height: imageHeight || undefined
                    }}
                    onMeasure={({ width }) => {
                      setImageWidth(width);
                      setImageHeight(width / WORKSPACE_PHOTO_W_TO_H_RATIO);
                    }}
                  />
                )}
              </Clickable>
            </SwiperSlide>
          );
        })}
      </SwiperCarousel>
    </>
  );
};

export default PhotoCarousel;
