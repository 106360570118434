import React from 'react';

import Head from 'components/Head';

import EventDescription from './EventDescription';
import Hero from './Hero';
import SFTechWeekWorkspaces from './SFTechWeekWorkspaces';

interface Props {}

const SFTechWeek: React.FC<Props> = () => {
  return (
    <>
      <Head
        title="#SFTechWeek Live Event: Learn about fundraising for Series B"
        description="Join us live on May 30th: Andrew Chen, A16Z, will be speaking with Christelle Rohaut, CEO @ Codi, about best practices when fundraising for Series B."
      />
      <Hero />
      <EventDescription />
      <SFTechWeekWorkspaces />
    </>
  );
};

export default SFTechWeek;
