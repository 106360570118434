import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { media } from 'context';
import { Clickable, Layout, Text } from 'ds';

interface Props {
  step: string;
  path: string;
  isSelected: boolean;
}

const PlaybookStepButton: React.FC<Props> = ({ step, path, isSelected }) => {
  const history = useHistory();
  const { xs } = useContext(media);

  return (
    <Clickable onClick={() => history.push(path)} key={1}>
      <Layout
        align="center"
        justify="center"
        borderWidth={2}
        {...(isSelected && step === '...'
          ? {
              borderColor: 'blue-gradient',
              width: 52,
              height: 52,
              borderRadius: 'circular'
            }
          : isSelected
          ? { borderRadius: 1000, borderColor: 'blue-gradient', height: 52, paddingX: 32 }
          : {
              borderColor: 'blue-600',
              width: 52,
              height: 52,
              borderRadius: 'circular'
            })}
      >
        <Text size="h6" color="white" scale={xs}>
          {isSelected && step !== '...' ? `Step ${step}` : step}
        </Text>
      </Layout>
    </Clickable>
  );
};

export default PlaybookStepButton;
