import Butter from 'buttercms';
import qs from 'qs';

import { ButterLocation, ButterPage, Comparison } from './types';
import { track } from '../analytics';

const butter = Butter('7464a336ad204707fac4cefc10cb589dca4157d3', false, 10000);

export async function getPage({ slug }: { slug: string }) {
  try {
    const { preview } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const resp = await butter.page.retrieve('*', slug, { preview: preview ? 1 : 0 });

    return resp.data?.data;
  } catch (error) {
    return handleError({ slug, error });
  }
}

export async function getLocations(): Promise<ButterLocation[]> {
  try {
    const resp = await butter.content.retrieve(['locations']);

    return resp.data?.data.locations || [];
  } catch (error) {
    return handleError({ slug: 'Locations', error });
  }
}

export async function getComparisons(): Promise<Comparison[]> {
  try {
    const resp = await butter.content.retrieve(['comparisons']);

    return resp.data?.data.comparisons || [];
  } catch (error) {
    return handleError({ slug: 'comparisons', error });
  }
}

export async function getAllPages(): Promise<ButterPage[]> {
  try {
    const { preview } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    // @ts-expect-error
    return Promise.all([
      butter.page.list('*', { preview: preview ? 1 : 0, page_size: 9999 }),
      butter.page.list('public_page', { preview: preview ? 1 : 0, page_size: 9999 })
    ]).then(([resp, dynamicPages]) => {
      if (resp.data && dynamicPages.data) {
        return [...resp.data.data, ...dynamicPages.data.data];
      } else {
        return [];
      }
    });
  } catch (error) {
    return handleError({ slug: 'all-pages', error });
  }
}

export function handleError({ slug, error }: { slug: string; error: any }) {
  const errorObject = error.toJSON();

  track('Butter Error', {
    slug,
    message: errorObject.message,
    url: errorObject.config.url,
    authToken: errorObject.config.params.auth_token
  });

  return Promise.reject(errorObject);
}

export function getLocationSlug(location: { name: string }): string {
  return location.name.toLowerCase().replaceAll(' ', '-');
}

export default butter;
