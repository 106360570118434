import React from 'react';
import classNames from 'classnames';

import { FieldSize } from './types';

interface Props {
  error?: string;
  inputSize: FieldSize;
}

const Error: React.FC<Props> = ({ error, inputSize }) => {
  if (!error) {
    // This allows for vertically aligned side-by-side inputs when only one input has error.
    return <p className={classNames('Input-error', `is-size-${inputSize}`)}>&nbsp;</p>;
  } else {
    return <p className={classNames('Input-error', `is-size-${inputSize}`)}>{error}</p>;
  }
};

export default Error;
