import React, { useContext } from 'react';

import { app } from 'context';
import { Layout, Link } from 'ds';
import { SEARCH_ENTRY_PATH } from 'routes';

import { SearchLocation } from './types';

interface Props {
  market: SearchLocation;
}

const MarketNavigationTree: React.FC<Props> = ({ market }) => {
  const { contentPaddingX } = useContext(app);

  const hrefFromLocation = (location: SearchLocation) => {
    return `${SEARCH_ENTRY_PATH}/${location.slug}/${location.guid}`;
  };

  return (
    <Layout flexGrow={1} paddingX={contentPaddingX} paddingY={24}>
      <details>
        <summary style={{ paddingBottom: 8 }}>
          See more office space for rent in{' '}
          <Link href={hrefFromLocation(market)} semibold={false}>
            {market.name}
          </Link>
        </summary>
        {market.submarkets?.map(submarket => {
          return (submarket.neighborhoods || []).length ? (
            <details style={{ paddingLeft: 8 }} key={submarket.guid}>
              <summary style={{ paddingBottom: 8 }}>
                <Link href={hrefFromLocation(submarket)} semibold={false}>
                  {submarket.name}
                </Link>
              </summary>
              {submarket.neighborhoods?.map(neighborhood => (
                <Layout paddingLeft={24} paddingBottom={8} key={neighborhood.guid}>
                  <Link href={hrefFromLocation(neighborhood)} semibold={false}>
                    {neighborhood.name}
                  </Link>
                </Layout>
              ))}
            </details>
          ) : (
            <Layout key={submarket.guid} paddingLeft={8}>
              &bull;{' '}
              <Link href={hrefFromLocation(submarket)} semibold={false}>
                {submarket.name}
              </Link>
            </Layout>
          );
        })}
      </details>
    </Layout>
  );
};

export default MarketNavigationTree;
