import React, { useContext } from 'react';

import { media } from 'context';
import { Button, HEADER_Z_INDEX, Layout } from 'ds';
import { RequestState } from 'ds/types';
import { apiTrack } from 'lib/analytics';
import { actions } from 'store/OfficeRequest';
import { selectOfficeRequestCompletedSteps, selectOfficeRequestMarket } from 'store/OfficeRequest/selectors';
import { selectOfficeRequestWizardHideFooter } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { MOBILE_PROGRESS_FOOTER_HEIGHT, PROGRESS_FOOTER_HEIGHT } from './constants';

interface Props {
  step: number;
  stepCompletedEventValue?: string | number | string[] | number[] | object;
  stepCompletedEventValues?: object;
  stepName: string;
  nextHref?: string;
  onSubmit?: VoidFunction;
  ctaDisabled?: boolean;
  backHref?: string;
  skippable?: boolean;
  requestState?: RequestState;
}

const ProgressFooter: React.FC<Props> = ({
  step,
  nextHref,
  ctaDisabled,
  skippable,
  backHref,
  onSubmit,
  requestState,
  stepCompletedEventValue,
  stepCompletedEventValues,
  stepName
}) => {
  const market = useAppSelector(selectOfficeRequestMarket);
  const requestInProgress = requestState === 'in_progress';
  const progressPercentage = !!market ? (step / 8) * 100 : (Math.max(0, step - 1) / 7) * 100;
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;
  const hideFooter = useAppSelector(selectOfficeRequestWizardHideFooter);
  const completedSteps = useAppSelector(selectOfficeRequestCompletedSteps);
  const dispatch = useAppDispatch();

  const trackStep = ({ skipped }: { skipped: boolean }) => {
    if (!completedSteps.includes(step)) {
      apiTrack('Office Request Step Completed', {
        step,
        value: stepCompletedEventValue || '',
        ...stepCompletedEventValues,
        name: stepName,
        ...(skipped ? { skipped: true } : {})
      });
      dispatch(actions.addStepComplete(step));
    }
  };

  return (
    <Layout
      bottom={0}
      {...(isMobile
        ? { position: 'fixed', color: 'white', zIndex: hideFooter ? -1 : HEADER_Z_INDEX, width: '100%' }
        : { color: 'white', zIndex: HEADER_Z_INDEX })}
    >
      <Layout flex>
        <Layout height={2} color="blue-500" width={`${progressPercentage}%`} />
        <Layout height={2} color="gray-50" width={`${100 - progressPercentage}%`} />
      </Layout>
      <Layout
        {...(isMobile
          ? { height: MOBILE_PROGRESS_FOOTER_HEIGHT, paddingTop: 12 }
          : { align: 'center', height: PROGRESS_FOOTER_HEIGHT })}
        justify="space-between"
        paddingX={isMobile ? 24 : 40}
      >
        <Layout>
          {backHref && (
            <Button size="sm" type="secondaryOutline" text="Back" href={backHref} disabled={requestInProgress} />
          )}
        </Layout>
        <Layout flex>
          {skippable && (
            <Layout marginRight={12}>
              <Button
                size="sm"
                type="secondaryOutline"
                text="Skip"
                href={nextHref}
                onClick={() => trackStep({ skipped: true })}
              />
            </Layout>
          )}
          <Button
            size="sm"
            type="primary"
            {...(onSubmit
              ? {
                  text: 'Submit',
                  onClick: () => {
                    onSubmit();
                    trackStep({ skipped: false });
                  }
                }
              : {
                  text: 'Next',
                  href: nextHref,
                  onClick: () => {
                    trackStep({ skipped: false });
                  }
                })}
            disabled={ctaDisabled || requestInProgress}
            showSpinner={requestInProgress}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ProgressFooter;
