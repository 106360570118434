import { useEffect } from 'react';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';
type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type Handler = (event: PossibleEvent) => void;

// from: https://usehooks.com/useOnClickOutside/
export default function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: Handler | null,
  useMouseUpTouchEndEvent: boolean = false
): void {
  useEffect(
    () => {
      const listener = (event: PossibleEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler && handler(event);
      };

      document.addEventListener(useMouseUpTouchEndEvent ? 'mouseup' : 'mousedown', listener);
      document.addEventListener(useMouseUpTouchEndEvent ? 'touchend' : 'touchstart', listener);

      return () => {
        document.removeEventListener(useMouseUpTouchEndEvent ? 'mouseup' : 'mousedown', listener);
        document.removeEventListener(useMouseUpTouchEndEvent ? 'touchend' : 'touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, useMouseUpTouchEndEvent]
  );
}
