import React, { useContext } from 'react';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';
import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';

import { ReactComponent as Illustration } from './decision-tree.svg';

interface Props {}

const DecisionTree: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth, windowHeight } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout flexGrow={1}>
        <Text size="h3" color="blue-gradient" scale={xs}>
          A Handy Decision Tree
        </Text>
        <Layout marginTop={48} justify="center" width={xs ? contentWidth : undefined}>
          <Illustration height={windowHeight - PLAYBOOK_FOOTER_HEIGHT - PLAYBOOK_HEADER_HEIGHT - 64 - 80} />
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default DecisionTree;
