import React, { useContext, useState } from 'react';
import { Image } from '@codiwork/codi';

import { app, media } from 'context';
import Button from 'ds/Button';
import Clickable from 'ds/Clickable';
import Img from 'ds/Img';
import Layout from 'ds/Layout';
import SwiperCarousel from 'ds/SwiperCarousel';
import Text from 'ds/Text';
import WorkspaceImg from 'ds/WorkspaceImg';
import useKeyDown from 'ds/helpers/useKeyDown';
import { SwiperSlide } from 'swiper/react';
import TourCTA from 'ux/Public/Listing/TourCTA';
import { ListingCTAProps } from 'ux/Public/Listing/types';

import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import {
  GALLERY_HEIGHT_LARGE,
  GALLERY_HEIGHT_SMALL,
  GALLERY_SM_HEIGHT,
  GALLERY_SM_WIDTH,
  GALLERY_WIDTH_LARGE,
  GALLERY_WIDTH_SMALL,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from './constants';
import { Dimensions } from '../types';

type Props = {
  floorPlans: Image[];
  setImageIndex: (value: number) => void;
} & ListingCTAProps;

const FloorPlans: React.FC<Props> = ({ floorPlans, setImageIndex, officeLead, workspace, CTA }) => {
  const { sm } = useContext(media);
  const { windowHeight, windowWidth } = useContext(app);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [inputIndex, setInputIndex] = useState<number>(0);
  const imageIndex = 0;

  useKeyDown((e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      if (inputIndex === floorPlans.length - 1) setInputIndex(0);
      else setInputIndex(activeIndex + 1);
    } else if (e.key === 'ArrowLeft') {
      if (inputIndex === 0) setInputIndex(floorPlans.length - 1);
      else setInputIndex(activeIndex - 1);
    }
  });

  if (!floorPlans.length) return null;

  const floorPlansArray: (Image | string)[] = [...floorPlans];

  const carouselDimensions = (): Dimensions => {
    const allowedWidthOfCarousel = windowWidth - (sm ? 308 : 450);
    const allowedHeightOfCarousel = windowHeight - 244;

    if (allowedWidthOfCarousel / allowedHeightOfCarousel > 1.57) {
      return {
        height: windowHeight - 244,
        width: (windowHeight - 244) * 1.5
      };
    }
    return {
      height: allowedWidthOfCarousel / 1.5,
      width: allowedWidthOfCarousel
    };
  };

  const { height: carouselHeight, width: carouselWidth } = carouselDimensions();

  const imageCount = floorPlans.length;

  return (
    <Layout direction="row" width="100%" position="relative" justify="flex-start" align="flex-start" color="white">
      <Layout direction="column">
        <Layout
          marginLeft={24}
          direction="row"
          width={sm ? GALLERY_SM_WIDTH : GALLERY_WIDTH_LARGE}
          align="flex-start"
          overflow="scroll"
          minWidth={sm ? GALLERY_SM_WIDTH : GALLERY_WIDTH_LARGE}
        >
          <Layout wrap __style={{ gap: 12 }} paddingTop={42}>
            {floorPlans.map((image, index) => {
              return (
                <Layout position="relative" key={`thumbnail${index}`}>
                  <Layout
                    zIndex={0}
                    overflow="hidden"
                    key={image.id}
                    direction="row"
                    maxWidth={sm ? GALLERY_SM_WIDTH : index % 3 === 2 ? GALLERY_WIDTH_LARGE : GALLERY_WIDTH_SMALL}
                    maxHeight={sm ? GALLERY_SM_HEIGHT : index % 3 === 2 ? GALLERY_HEIGHT_LARGE : GALLERY_HEIGHT_SMALL}
                    borderColor="blue-500"
                    borderRadius={8}
                    color={activeIndex === index ? 'white' : undefined}
                    opacity={activeIndex === index ? 0.5 : 1}
                  >
                    <Clickable
                      onClick={() => setInputIndex(index)}
                      children={
                        <Img width={IMAGE_WIDTH} height={IMAGE_HEIGHT} alt={`floor plan ${index}`} srcKey={image.key} />
                      }
                    />
                  </Layout>
                  {activeIndex === index && (
                    <Layout
                      top={0}
                      position="absolute"
                      zIndex={1}
                      key={image.id}
                      direction="row"
                      width={sm ? GALLERY_SM_WIDTH : index % 3 === 2 ? GALLERY_WIDTH_LARGE : GALLERY_WIDTH_SMALL}
                      height={
                        sm ? GALLERY_SM_HEIGHT - 4 : index % 3 === 2 ? GALLERY_HEIGHT_LARGE : GALLERY_HEIGHT_SMALL - 16
                      }
                      borderWidth={4}
                      borderColor="blue-500"
                      borderRadius={8}
                    ></Layout>
                  )}
                </Layout>
              );
            })}
            <Layout height={42} width="100%" />
          </Layout>
        </Layout>
        {workspace && (
          <>
            <Layout justify="center">
              <Text size="body3" semibold>
                Tour soon before it's gone!
              </Text>
            </Layout>
            <Layout justify="center">
              <Layout marginTop={12} width="auto">
                <TourCTA workspace={workspace} officeLead={officeLead} />
              </Layout>
            </Layout>
          </>
        )}
      </Layout>
      <Layout width="100%" justify="center">
        <Layout
          direction="column"
          justify="center"
          align="center"
          width={carouselWidth}
          borderRadius={8}
          marginLeft={52}
          marginRight={52}
          paddingY={42}
          overflow="hidden"
        >
          <SwiperCarousel
            ignoreTouch
            config={{ md: { slidesPerView: 1, spaceBetween: 0 } }}
            controlLayoutProps={{ width: 48, height: 48, left: -246, right: -246 }}
            leftArrow={<LeftArrow />}
            rightArrow={<RightArrow />}
            height={carouselHeight}
            width={carouselWidth}
            onSlideChange={({ realIndex }) => {
              setActiveIndex(realIndex);
              setInputIndex(realIndex);
            }}
            initialSlide={imageIndex}
            inputIndex={inputIndex}
            virtual={false}
            lazy
            loop
          >
            {floorPlansArray.map((image, index) => {
              return (
                <SwiperSlide key={`slide${index}`}>
                  <Layout width="100%" direction="column" justify="center" align="center" position="relative">
                    {
                      <WorkspaceImg
                        padResize
                        srcKey={(image as Image).key}
                        size="lg"
                        alt={`workspace ${index + 1}`}
                        style={{
                          userSelect: 'none',
                          objectFit: 'cover',
                          borderRadius: 8,
                          width: '100%',
                          height: '100%'
                        }}
                      />
                    }
                  </Layout>
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
          <Layout direction="column" width="100%" height={100} justify="space-evenly" align="center">
            <Text color="gray-900" size="body1">
              {activeIndex + 1} / {imageCount}
            </Text>
            {workspace ? (
              <TourCTA workspace={workspace} officeLead={officeLead} />
            ) : CTA ? (
              <Button
                text={CTA.text}
                onClick={e => {
                  setImageIndex(-1);
                  CTA.onClick && CTA.onClick(e);
                }}
                type="primary"
                size="sm"
              />
            ) : null}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default FloorPlans;
