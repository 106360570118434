import React from 'react';

import { Clickable, Icon, Layout, Text } from 'ds';

interface Props {
  value: string;
  onRemove: () => void;
}

const Tag: React.FC<Props> = ({ value, onRemove }) => {
  return (
    <Layout paddingLeft={10} paddingRight={8} paddingY={1} flex color="gray-50" borderRadius={1000} align="center">
      <Text size="body-md" color="gray-900" semibold>
        {value}
      </Text>
      <Clickable onClick={() => onRemove()} style={{ marginLeft: 2, display: 'inline-flex' }}>
        <Icon name="close" color="gray-900" size="xs" />
      </Clickable>
    </Layout>
  );
};

export default Tag;
