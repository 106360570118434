import React, { useContext } from 'react';
import { GRAY_50_HEX } from '@codiwork/codi';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

interface Props {}

const Traditional: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <Layout marginTop={36} flex direction={xs ? 'column' : 'row'}>
      <Layout maxWidth={xs ? contentWidth : 292}>
        <Layout align="center">
          <Layout
            width={44}
            height={44}
            borderColor="blue-gradient"
            borderRadius="circular"
            align="center"
            justify="center"
          >
            <Icon name="building" size="md" color="blue-gradient" />
          </Layout>
          <Layout marginLeft={12}>
            <Text size="h5" color="blue-gradient">
              Traditional Office
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={36}>
          <Text size="h6" bold={false} color="gray-700">
            The “traditional” office is the most common, (and often most complex), model for renting office space.
            <br />
            <br />
            This is defined as commercial building space rented from a landlord or a management company in one of the
            following classes:
          </Text>
          <br />
          <br />
          <ul>
            <li>
              <Text size="h6" color="gray-700" inline>
                Class A
              </Text>
              <Text size="h6" color="gray-700" bold={false} inline>
                &nbsp;&nbsp;High-end, modern
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" color="gray-700" inline>
                Class B
              </Text>
              <Text size="h6" color="gray-700" bold={false} inline>
                &nbsp;&nbsp;Older but well-maintained, updated
              </Text>
            </li>
            <br />
            <li>
              <Text size="h6" color="gray-700" inline>
                Class C
              </Text>
              <Text size="h6" color="gray-700" bold={false} inline>
                &nbsp;&nbsp;Bare-bone or in disrepair, commercial building
              </Text>
            </li>
          </ul>
        </Layout>
      </Layout>
      <Layout
        boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
        color="white"
        borderRadius={24}
        marginLeft={xs ? undefined : 24}
        marginTop={xs ? 24 : undefined}
      >
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '20px 40px 16px 40px', width: '50%' }}>
                <Text size="h6" color="blue-gradient">
                  PROS
                </Text>
              </th>
              <th style={{ textAlign: 'left', padding: '20px 40px 16px 72px', width: '50%' }}>
                <Text size="h6" color="gray-300">
                  CONS
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  It’s all yours
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Design and build out your office as you see fit. Make any changes that your landlord will allow and
                    optimize productivity and comfort for your team. Make it your own.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Long-term lease
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Most landlords will try to lock you in for as long as they can: 3, 5, or 10-year leases are the
                    norm. If anything changes with your business, getting out of your lease can pose a serious
                    challenge.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Maximize company culture
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Because you can design your office any way you like, the traditional office is a good long-term
                    solution for defining and building your company’s culture in your new “home.”
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  You’ll have to manage the space
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Aside from some shared building amenities, your space is your responsibility. Plan on finding an
                    architect, project manager, attorney, IT vendor, cleaning staff, and food deliveries all on your
                    own.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Located anywhere there are businesses
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Because the traditional office has been the most common model for decades, you can find an office
                    anywhere that landlords have open space. You have plenty of options.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Long process
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    Negotiating and signing a standard office lease requires input from multiple players. It can take
                    months to get into your company’s new digs.
                  </Text>
                </Layout>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '24px 0 24px 40px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Protections
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    If you’ve signed a long-term lease, you’re offered protections under most state laws. For example,
                    if the building is sold, you are still entitled to stay until the end of your lease. However,
                    smaller tenants might not get this protection.
                  </Text>
                </Layout>
              </td>
              <td style={{ padding: '24px 40px 24px 72px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                <Text size="body1" color="gray-700" bold>
                  Little transparency
                </Text>
                <Layout marginTop={8}>
                  <Text size="body3" color="gray-400">
                    The commercial real estate market is rife with tricks and fine print. You’ll have to be careful and
                    ask all the right questions through the entire process.
                  </Text>
                </Layout>
              </td>
            </tr>
          </tbody>
        </table>
      </Layout>
    </Layout>
  );
};

export default Traditional;
