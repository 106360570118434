import React, { useRef } from 'react';
import { COLORS } from '@codiwork/codi';

import { Avatar, DS_CLASS_NAME, Icon, Layout } from 'ds';
import { track } from 'lib/analytics';

import { ReactComponent as Camera } from 'assets/images/Camera.svg';

export interface Props {
  onChange: (file: File) => void;
  avatarUrl?: string | null;
  avatarSize?: number;
  defaultAvatar?: JSX.Element;
  showSmallCamera?: boolean;
  alt?: string;
}

const AvatarUpdate: React.FC<Props> = ({
  onChange,
  avatarUrl,
  avatarSize = 128,
  showSmallCamera = false,
  alt,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const hasUploadedAvatar = !!avatarUrl && !avatarUrl.includes('avatar/blank');
  const defaultAvatar = props.defaultAvatar || (
    <Camera style={{ width: '50%', height: '50%' }} fill={COLORS['gray-300']} />
  );

  return (
    <Layout __className="AvatarUpdate">
      <Layout position="relative" display="inline-flex">
        <input
          type="file"
          className={DS_CLASS_NAME}
          id="profile-avatar-upload"
          style={{ display: 'none' }}
          ref={ref}
          onClick={() => track('Element Interacted', { type: 'input', name: 'avatar update' })}
          onChange={() => {
            if (!ref.current) {
              return;
            }

            const files = ref.current.files;
            !!files && files.length > 0 && onChange(files[0]);
          }}
          accept="image/png, image/jpeg"
        />
        <label htmlFor={hasUploadedAvatar ? '' : 'profile-avatar-upload'}>
          <Layout __className={!hasUploadedAvatar ? 'AvatarUpdate-emptyAvatar' : undefined}>
            <Avatar
              size={avatarSize}
              avatarUrl={hasUploadedAvatar ? avatarUrl : null}
              defaultAvatar={defaultAvatar}
              alt={alt}
            />
            {(!!hasUploadedAvatar || showSmallCamera) && (
              <Layout>
                <label htmlFor="profile-avatar-upload">
                  <Layout
                    __className="AvatarUpdate-editButton"
                    cursor="pointer"
                    inline
                    position="absolute"
                    bottom={-6}
                    right={-10}
                    width={44}
                    height={44}
                    align="center"
                    justify="center"
                    borderRadius="circular"
                    borderWidth={2}
                    borderColor="white"
                  >
                    <Icon name="camera" size="md" color="gray-900" />
                  </Layout>
                </label>
              </Layout>
            )}
          </Layout>
        </label>
      </Layout>
    </Layout>
  );
};

export default AvatarUpdate;
