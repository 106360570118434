import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import chunk from 'lodash/chunk';

import { app } from 'context';
import { Layout } from 'ds';
import { NavigationMenu } from 'lib/butter/types';

import CtaSection from './CtaSection';
import Locations from './Locations';
import MenuSection from './MenuSection';
import { TRANSITION_DELAY, TRANSITION_OFFSET } from './constants';

interface Props {
  menu: NavigationMenu;
  openMenuIndex: number;
  index: number;
  isHiding: boolean;
  isOpening: boolean;
  /** Used only to trigger a re-render */
  resizeOrdinal: number;
  setHeight: (height?: number) => void;
  closeMenu: VoidFunction;
  measuredHeight?: number;
}

const Menu: React.FC<Props> = ({ menu, isHiding, closeMenu, setHeight, openMenuIndex, isOpening, index }) => {
  const { navBarHeight, contentWidth } = useContext(app);
  const ctaSection = menu ? menu.cta_section.fields : null;
  const locations = menu?.locations || [];
  const columns = chunk(locations, 3);
  const sectionsLength = menu?.sections?.length || columns.length;
  const ref = useRef<HTMLDivElement>(null);
  const thisMenuIsOpen = openMenuIndex === index;
  const someMenuIsOpen = openMenuIndex > -1;

  useEffect(() => {
    const container = ref.current;

    if (!container) return;

    const { height } = container.getBoundingClientRect();

    setHeight(height);
  }, [thisMenuIsOpen, navBarHeight, contentWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const sectionTransform = thisMenuIsOpen
    ? 'translateX(0) translateY(0)'
    : someMenuIsOpen
    ? `translateX(${index < openMenuIndex ? -20 : 20}px) translateY(0)`
    : 'translateX(0) translateY(-64px)';

  const sectionStyle = {
    transform: sectionTransform
  };

  const onImageLoad = () => {
    const container = ref.current;

    if (!container) return;

    const { height } = container.getBoundingClientRect();

    setHeight(height);
  };

  return (
    <Layout
      __className={classNames('NavigationMenu')}
      __style={{
        maxWidth: contentWidth
      }}
      __ref={ref}
      flex
      flexGrow={1}
      visibility={thisMenuIsOpen || isHiding ? undefined : 'hidden'}
      opacity={thisMenuIsOpen ? 1 : 0.18}
      transition={`transform 0.35s cubic-bezier(0, 0, 0.16, 0.96), opacity 0.35s cubic-bezier(0, 0, 0.16, 0.96) ${
        isOpening || isHiding ? 160 : 100
      }ms`}
    >
      <Layout flexGrow={1} flex>
        {locations && locations.length ? (
          <Locations
            columns={columns}
            sectionStyle={{ ...sectionStyle }}
            onImageLoad={onImageLoad}
            closeMenu={closeMenu}
          />
        ) : (
          menu.sections.map((menuSection, index) => (
            <Layout
              __className={classNames('NavigationMenu-menuSection', { 'is-open': thisMenuIsOpen })}
              key={menuSection.name}
              marginRight={40}
              __style={{
                transitionDelay: `${TRANSITION_DELAY + index * TRANSITION_OFFSET}ms`,
                ...sectionStyle
              }}
              equalWidth
            >
              <MenuSection {...menuSection} closeMenu={closeMenu} />
            </Layout>
          ))
        )}
      </Layout>
      {ctaSection && (
        <Layout
          width="16%"
          flexShrink={0}
          __className={classNames('NavigationMenu-ctaSection', { 'is-open': thisMenuIsOpen })}
          __style={{
            transitionDelay: `${TRANSITION_DELAY + 30 + (sectionsLength - 1) * TRANSITION_OFFSET}ms`,
            ...sectionStyle
          }}
        >
          <CtaSection {...ctaSection} onImageLoad={onImageLoad} closeMenu={closeMenu} />
        </Layout>
      )}
    </Layout>
  );
};

export default Menu;
