import { useContext, useEffect, useState } from 'react';

import { media } from 'context';
import { FieldSize } from 'ds/types';

function useFieldSize(): FieldSize {
  const { xs, sm, md, lg } = useContext(media);
  const [fieldSize, setFieldSize] = useState<FieldSize>(getFieldSize({ xs, sm, md, lg }));

  useEffect(() => {
    setFieldSize(getFieldSize({ xs, sm, md, lg }));
  }, [xs, sm, md, lg]);

  return fieldSize;
}

export function getFieldSize({ xs, sm, md, lg }: { xs: boolean; sm: boolean; md: boolean; lg: boolean }): FieldSize {
  if (xs) {
    return 'sm';
  } else if (sm || md) {
    return 'md';
  } else if (lg) {
    return 'lg';
  }

  return 'md';
}

export default useFieldSize;
