import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';

import { Button, Layout, Text } from 'ds';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { createSetupIntent, deletePaymentMethod, listPaymentMethods } from './requests';

// NOTE: This is a test key. We need to get a real one for production.
const stripePromise = loadStripe('pk_test_IKX09H9MA4Vpme8wKsNUqrjQ');

const PaymentMethods: React.FC = () => {
  const user = useAppSelector(selectUser);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();

  useEffect(() => {
    listPaymentMethods().then(({ data }) => setPaymentMethods(data));
  }, []);

  if (!user || !paymentMethods) return null;

  const handleSubmit = async () => {
    // event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const bankAccountElement = elements.getElement(PaymentElement);
      if (!bankAccountElement) {
        setError('Bank account element not found');
        return;
      }

      const { error: setupError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              name: user.customer?.name || '',
              email: user.customer?.email || ''
            },
            allow_redisplay: 'always'
          }
        }
      });

      if (setupError) {
        setError(setupError.message || 'An error occurred');
        return;
      }

      setSuccess(true);
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <Layout padding={24} color="green-500">
        <Text>Your bank account has been successfully added for future payments.</Text>
      </Layout>
    );
  }

  return (
    <Layout padding={24} direction="column">
      {paymentMethods.length > 0 && (
        <Layout marginBottom={24}>
          <Text size="body-md">Your Payment Method</Text>
          <Layout direction="column">
            {paymentMethods.map(paymentMethod => (
              <Layout direction="row" gap={8} justify="space-between" align="center">
                <Layout direction="row" gap={8}>
                  <Text>Bank Account ending in {paymentMethod.us_bank_account?.last4}</Text>
                  <Text color="gray-500">•••• {paymentMethod.us_bank_account?.last4}</Text>
                </Layout>
                <Button
                  size="sm"
                  type="secondary"
                  text="Delete"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this payment method?')) {
                      deletePaymentMethod(paymentMethod.id)
                        .then(({ data }) => {
                          setPaymentMethods(data);
                        })
                        .catch(err => {
                          console.error('Error deleting payment method:', err);
                          alert('Failed to delete payment method. Please try again.');
                        });
                    }
                  }}
                />
              </Layout>
            ))}
          </Layout>
        </Layout>
      )}
      {paymentMethods.length === 0 && (
        <>
          <Text size="body-md">Add Payment Method</Text>

          <Layout maxWidth={480}>
            <form onSubmit={handleSubmit}>
              <Text size="body-sm">Please enter your US bank account information below:</Text>

              <Layout borderRadius={4} padding={16} marginBottom={24}>
                <PaymentElement
                  options={{
                    business: {
                      name: user?.customer?.name || ''
                    },
                    fields: {
                      billingDetails: {
                        name: 'never',
                        email: 'never'
                      }
                    },
                    paymentMethodOrder: ['us_bank_account']
                  }}
                />
              </Layout>

              {error && (
                <Layout padding={16} marginBottom={16} borderRadius={4}>
                  <Text color="red-500">{error}</Text>
                </Layout>
              )}

              <Button
                size="md"
                type="primary"
                text="Add Bank Account"
                disabled={!stripe || loading}
                showSpinner={loading}
                onClick={handleSubmit}
              />
            </form>
          </Layout>
        </>
      )}
    </Layout>
  );
};

const PaymentMethodsWrapper: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    createSetupIntent().then(({ data }) => setClientSecret(data.clientSecret));
  }, []);

  if (!clientSecret) {
    return null; // or loading spinner
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: 'stripe'
        }
      }}
    >
      <PaymentMethods />
    </Elements>
  );
};

export default PaymentMethodsWrapper;
