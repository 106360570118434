import {
  CASE_STUDY_SLUGS_WITH_TITLES,
  COMMERCIAL_REAL_ESTATE_PATH,
  HOW_IT_WORKS_PATH,
  OFFSITES_PATH,
  PLAYBOOK_PATH,
  ROOT_PATH,
  SEARCH_PATH
} from 'routes/paths';
import { ABOUT_US_PATH, CASE_STUDIES_PATH, PRESS_PATH, Path } from 'routes/paths';

interface IndexedPage {
  href: Path;
  title?: string;
}

export const INDEXED_PAGES: IndexedPage[] = [
  { href: ROOT_PATH },
  { href: ABOUT_US_PATH },
  { href: PRESS_PATH },
  { href: SEARCH_PATH },
  { href: HOW_IT_WORKS_PATH, title: 'How it Works' },
  { href: OFFSITES_PATH, title: 'Book Offsite Meeting Spaces' },
  { href: COMMERCIAL_REAL_ESTATE_PATH, title: 'List Your Space' },
  ...CASE_STUDY_SLUGS_WITH_TITLES.map(slugWithTitle => ({
    title: `Case Study: ${slugWithTitle.title}`,
    href: `${CASE_STUDIES_PATH}/${slugWithTitle.slug}` as const
  })),
  { href: PLAYBOOK_PATH, title: 'Free Codi Playbook - The Best Way to Find and Manage a Private Office' }
];
