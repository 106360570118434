import React, { useContext, useRef, useState } from 'react';
import { Listing, formatPublicAddress } from '@codiwork/codi';
import classNames from 'classnames';
import { uniq } from 'lodash';
import pluralize from 'pluralize';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import { app, media } from 'context';
import { DESKTOP_WORKSPACE_CARD_HEIGHT, HotTag, Layout, Link, NewTag, Text, TextButton, WorkspaceImg } from 'ds';
import CardCarousel from 'ds/SwiperCarousel/CardCarousel';
import { formatUsd } from 'helpers';
import { AUTH_PATH, LISTING_PATH, STB_REQUEST_PATH } from 'routes/paths';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { SwiperSlide } from 'swiper/react';

export interface Props {
  workspace: Pick<
    Listing,
    | 'id'
    | 'name'
    | 'short_name'
    | 'monthly_price'
    | 'daily_rate'
    | 'address'
    | 'square_feet'
    | 'slug'
    | 'images'
    | 'is_new'
    | 'is_hot'
    | 'num_meeting_rooms'
  >;
  daysPerWeek: number;
  onMeasure?: (rect: DOMRect) => void;
  offsitesOnly: boolean;
  setPriceExplanationModalIsVisible: (isVisible: boolean) => void;
  useCarousel?: boolean;
}

const WorkspaceListCard: React.FC<Props> = ({
  workspace: {
    id,
    short_name,
    name,
    address,
    monthly_price,
    daily_rate,
    slug,
    square_feet,
    images,
    is_new,
    is_hot,
    num_meeting_rooms
  },
  useCarousel = true,
  onMeasure,
  daysPerWeek,
  offsitesOnly,
  setPriceExplanationModalIsVisible
}) => {
  const [loadedIndices, setLoadedIndices] = useState<number[]>([0]);
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);
  const ref = useRef<HTMLDivElement>(null);
  const linkPath = offsitesOnly
    ? `${STB_REQUEST_PATH}/${id}`
    : `${LISTING_PATH}/${slug}${!!daysPerWeek && daysPerWeek < 5 ? `?days_per_week=${daysPerWeek}` : ''}`;
  const isLoggedIn = !!useAppSelector(selectUser);
  const price = offsitesOnly
    ? formatUsd(daily_rate, false)
    : isLoggedIn && monthly_price
    ? formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price, daysPerWeek })
    : null;

  return (
    <Link href={linkPath} opacity={false} draggable={false} target="_blank" fullWidth>
      <Layout direction={xs ? 'column' : 'row'} __className="WorkspaceListCard" __ref={ref}>
        <Layout
          {...(xs ? { width: contentWidth } : { width: 264, height: DESKTOP_WORKSPACE_CARD_HEIGHT })}
          borderRadius={12}
          overflow="hidden"
          __style={{
            // Hack for mobile browsers
            WebkitMaskImage: ' -webkit-radial-gradient(white, black)'
          }}
          display="block"
          position="relative"
          flexShrink={0}
          inline
        >
          {useCarousel ? (
            <CardCarousel
              style={{ borderRadius: 12 }}
              onActiveIndexChange={({ realIndex }) => {
                setLoadedIndices(uniq([...loadedIndices, realIndex]));
              }}
              virtual={false}
              loop
            >
              {images.map((photo, index) => (
                <SwiperSlide key={photo.key} className={classNames({ 'swiper-no-swiping': !xs })}>
                  {/* Implementing virtualization this way because loop and virtual don't work together for the version
            of Swiper in use */}
                  <WorkspaceImg
                    srcKey={photo.key}
                    size="sm"
                    {...(xs
                      ? { style: { objectFit: 'cover', height: 244 } }
                      : { style: { objectFit: 'cover', height: 152 } })}
                    alt={index.toString()}
                    onLoad={() => {
                      const container = ref.current;
                      if (index === 0 && onMeasure && container) {
                        onMeasure(container.getBoundingClientRect());
                      }
                    }}
                  />
                </SwiperSlide>
              ))}
              {is_new && (
                <Layout position="absolute" top={12} left={12} zIndex={1} gap={8} flex>
                  {is_new && <NewTag />}
                  {is_hot && <HotTag />}
                </Layout>
              )}
            </CardCarousel>
          ) : (
            <>
              <WorkspaceImg
                srcKey={images[0].key}
                size="sm"
                {...(xs
                  ? { style: { objectFit: 'cover', height: 244 } }
                  : { style: { objectFit: 'cover', height: 152 } })}
                alt="office"
              />
              {is_new && (
                <Layout position="absolute" top={12} left={12} zIndex={1}>
                  <NewTag />
                </Layout>
              )}
            </>
          )}
        </Layout>
        <Layout
          direction="column"
          justify="space-between"
          {...(xs ? { paddingTop: 12 } : { paddingY: 16, paddingX: 24 })}
          flexGrow={1}
          minWidth={0}
        >
          <Layout>
            <Text size="body2" color="gray-600" ellipsis>
              {formatPublicAddress(address)}
            </Text>
            <Layout marginTop={4}>
              <Text size="h6" color="gray-900" bold={false} ellipsis scale>
                {short_name || name}
              </Text>
            </Layout>
          </Layout>
          <Layout justify="space-between" marginTop={4}>
            <Text size="body2" align="left" color="gray-700" scale>
              {square_feet?.toLocaleString()} ft²
              {(num_meeting_rooms || 0) > 0 && ` • ${pluralize('meeting room', num_meeting_rooms || 0, true)}`}
            </Text>
            <Layout>
              {offsitesOnly ? (
                <Text color="gray-900" size="body-sm" semibold>
                  {price}/day
                </Text>
              ) : price ? (
                <TextButton
                  textSize="body-sm"
                  color="gray-900"
                  paddingY={false}
                  onClick={() => setPriceExplanationModalIsVisible(true)}
                  text={<>{price}/mo</>}
                  underline
                  semibold
                />
              ) : (
                <Layout __className={xs ? undefined : 'WorkspaceListCard-seePrice'}>
                  <Link
                    href={AUTH_PATH}
                    search={`redirect=${encodeURIComponent(linkPath)}`}
                    semibold={false}
                    size="body-sm"
                  >
                    See pricing
                  </Link>
                </Layout>
              )}
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Link>
  );
};

export default WorkspaceListCard;
