export const FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG = {
  xxs: {
    height: 50,
    imageSize: 32,
    labelFontSize: 'body3'
  },
  xs: {
    height: 50,
    imageSize: 32,
    labelFontSize: 'body3'
  },
  sm: {
    height: 50,
    imageSize: 32,
    labelFontSize: 'body3'
  },
  md: {
    height: 54,
    imageSize: 36,
    labelFontSize: 'body2'
  },
  lg: {
    height: 58,
    imageSize: 40,
    labelFontSize: 'body1'
  }
} as const;
