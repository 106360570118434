import React, { useContext } from 'react';
import { WebSite, WithContext } from 'schema-dts';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Button, Layout } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import AllInclusive from './AllInclusive';
import Browse from './Browse';
import CTAs from './CTAs';
import CaseStudy from './CaseStudy';
import Compare from './Compare';
import Hero from './Hero';
import Insights from './Insights';
import LogosMarquee from './LogosMarquee';
import Press from './Press';
import PriceExplanation from './PriceExplanation';
import Solutions from './Solutions';
import Testimonials from './Testimonials';
import ValueProps from './ValueProps';
import { HOME_PAGE_MAX_WIDTH } from './constants';

import { ReactComponent as CirclesBottom } from './circles-bottom.svg';
import { ReactComponent as CirclesLeft } from './circles-left.svg';

interface Props {}

const HOME_META_TITLE = 'Office for Rent | New York, San Francisco, Los Angeles';
const HOME_META_DESCRIPTION =
  'Private, move-in ready offices for rent. Fully furnished, managed office spaces with flexible terms in New York City, San Francisco Bay Area, and Los Angeles.';
const HOME_KEYWORDS =
  'office for rent, office for rent new york, office for rent san francisco, office for rent los angeles, office for lease, office space, flexible workspace, space rent, office space rent, flexible office spaces, office space new york, office space san francisco';

const Home: React.FC<Props> = () => {
  const { xs, sm, isMobile } = useContext(media);
  const { contentPaddingX, windowWidth, contentWidth } = useContext(app);

  let paddingX: number | undefined;
  let usingMaxWidth = false;
  if (windowWidth - contentPaddingX * 2 > HOME_PAGE_MAX_WIDTH) {
    paddingX = (windowWidth - HOME_PAGE_MAX_WIDTH) / 2;
    usingMaxWidth = true;
  } else {
    paddingX = contentPaddingX;
  }

  const containerProps = {
    maxWidth: usingMaxWidth ? HOME_PAGE_MAX_WIDTH : contentWidth + contentPaddingX * 2,
    flexGrow: 1,
    paddingX: usingMaxWidth ? undefined : contentPaddingX
  };

  const schema: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@id': `${window.location.origin}/#website`,
    '@type': 'WebSite',
    name: 'Codi',
    url: window.location.origin,
    description: HOME_META_DESCRIPTION,
    keywords: HOME_KEYWORDS,
    hasPart: {
      '@type': 'WebPage',
      speakable: { '@type': 'SpeakableSpecification', xpath: ["/html/head/meta[@name='description']/@content"] },
      significantLink: `${window.location.origin}${OFFICE_REQUEST_PATH}`
    },
    publisher: {
      '@type': 'Corporation',
      name: 'Codi',
      legalName: 'Codi Inc.',
      logo: 'https://ik.imagekit.io/r2atwkj92wo/public/Codi-logo_icb15iqnP.png',
      image: 'https://ik.imagekit.io/r2atwkj92wo/public/tr:w-600/e6a421371e38c8d343831983605f0629_yu9zLLeag.jpeg',
      sameAs: [
        'https://www.linkedin.com/company/codiwork',
        'https://twitter.com/codiwork',
        'https://www.facebook.com/codicommunity',
        'https://www.instagram.com/codiwork/',
        'https://www.crunchbase.com/organization/codi-2000'
      ],
      brand: {
        '@type': 'Brand',
        name: 'Codi'
      },
      funder: [
        {
          '@type': 'Corporation',
          '@id': 'https://a16z.com/',
          legalName: 'AH Capital Management, LLC',
          name: 'Andreessen Horowitz',
          url: 'https://a16z.com/',
          sameAs: 'https://en.wikipedia.org/wiki/Andreessen_Horowitz'
        }
      ]
    }
  };

  return (
    <>
      <Layout justify="center" color="blue-25" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Head title={HOME_META_TITLE} description={HOME_META_DESCRIPTION} keywords={HOME_KEYWORDS} />
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
          <Hero />
          <Layout marginTop={isMobile ? 96 : 100}>
            <Compare />
          </Layout>
          <Layout marginTop={isMobile ? 24 : 46} paddingX={contentPaddingX} justify="center">
            <Layout {...(xs ? { flexGrow: 1 } : { width: 242 })}>
              <Button
                text="Request an office"
                size="md"
                type="primary"
                textSize="body-sm"
                fullWidth
                href={OFFICE_REQUEST_PATH}
              />
            </Layout>
          </Layout>
          <Layout marginTop={isMobile ? 128 : 278}>
            <ValueProps />
          </Layout>
          <Layout marginTop={isMobile ? 128 : 350}>
            <Testimonials />
          </Layout>
        </Layout>
        {!isMobile && <CirclesLeft style={{ position: 'absolute', left: 0, top: 1148 }} />}
      </Layout>
      <Layout color="blue-25" paddingTop={xs ? 128 : 236}>
        <LogosMarquee />
      </Layout>
      <Layout justify="center" color="blue-25" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Layout {...(xs ? { position: 'relative' } : {})} marginTop={128}>
            <HeroImage
              imageKey="home-page-kearny_F7JjKdo4p.jpeg"
              imageKitFolder="public"
              height={xs ? 244 : sm ? 380 : 436}
              {...(xs ? { imgWidth: 1600, imgHeight: 2000 } : { paddingX: paddingX, imgWidth: 3000, imgHeight: 1200 })}
              accountForPaddingX={!xs}
              parallax={!isMobile}
            />
          </Layout>
          <Layout {...(xs ? { paddingX: contentPaddingX, marginTop: 64 } : { marginTop: 264 })}>
            <PriceExplanation />
          </Layout>
          <Layout marginTop={xs ? 88 : 264}>
            <AllInclusive />
          </Layout>
          <Layout marginTop={xs ? 100 : 264}>
            <Browse />
          </Layout>
          <Layout marginTop={isMobile ? 128 : 160}>
            <Press />
          </Layout>
          <Layout
            {...(xs
              ? { paddingX: contentPaddingX, marginTop: 64 }
              : sm
              ? { marginTop: 100 }
              : {
                  marginTop: 160,
                  paddingBottom: 160
                })}
            position="relative"
            zIndex={1}
          >
            <CaseStudy />
          </Layout>
        </Layout>

        {!isMobile && <CirclesBottom style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 0 }} />}
      </Layout>
      <Layout
        {...(isMobile
          ? { paddingX: contentPaddingX, color: 'blue-25', paddingTop: 64 }
          : sm
          ? { color: 'blue-25', paddingTop: 64 }
          : { color: 'white', marginTop: 132 })}
        justify="center"
      >
        <Layout {...(xs ? {} : containerProps)}>
          <Solutions />
        </Layout>
      </Layout>
      <Layout
        {...(isMobile
          ? { paddingX: contentPaddingX, color: 'blue-25', paddingY: 64 }
          : { color: 'blue-50', marginTop: 92, paddingY: 100 })}
        justify="center"
      >
        <Layout {...(xs ? {} : containerProps)}>
          <Insights />
          <Layout marginTop={isMobile ? 64 : 100}>
            <CTAs />
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Home;
