import { DateTimeFormat, formatDate } from '@codiwork/codi';

import { isSameDate } from 'helpers/time';

export const formatDates = ({
  date,
  format = 'MED_WITH_SHORT_WEEKDAY',
  startDate,
  endDate
}: {
  date?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  format?: DateTimeFormat;
}) => {
  if (date) {
    return formatDate({ date, format });
  } else if (startDate && endDate && isSameDate(startDate, endDate)) {
    return formatDate({ date: startDate, format });
  } else if (startDate && endDate) {
    return `${formatDate({ date: startDate, format })} - ${formatDate({ date: endDate, format })}`;
  } else if (startDate) {
    return formatDate({ date: startDate, format });
  }
};
