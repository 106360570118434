import React from 'react';

import { Layout } from 'ds';

import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import ChatbotWidget from './ChatbotWidgets/ChatbotWidget';
import { ChatbotIntentProps } from './types';

interface Props extends ChatbotIntentProps {}

const ChatbotInteractionDisplay: React.FC<Props> = ({
  chatbotInteraction,
  chatbotInteraction: { input },
  threadId
}) => {
  return (
    <Layout marginTop={16}>
      <Layout marginLeft={24}>
        <ChatbotThreadCustomerMessage isLastMessageInGroup>{input}</ChatbotThreadCustomerMessage>
      </Layout>
      <Layout marginTop={16}>
        <ChatbotWidget chatbotInteraction={chatbotInteraction} threadId={threadId} />
      </Layout>
    </Layout>
  );
};

export default ChatbotInteractionDisplay;
