import React from 'react';

import PlaybookStepPage from './PlaybookStepPage';

interface Props {}

const MovingIn: React.FC<Props> = () => {
  return <PlaybookStepPage title="Moving In & Managing Your Office" activeStep={4} />;
};

export default MovingIn;
