import { PartialPick } from '@codiwork/codi';

import { ERROR_MESSAGE, SAVE_MESSAGE } from 'ds/constants';
import { SnackbarMessage, actions } from 'store/UI';
import { store } from 'store/store';

interface ShowSnackbarProps
  extends PartialPick<SnackbarMessage, 'duration' | 'negative' | 'link' | 'CTA' | 'img' | 'position'> {
  message?: string;
}

export function showSnackbar({
  duration,
  negative,
  link,
  img,
  CTA,
  position = 'center',
  ...props
}: ShowSnackbarProps | undefined = {}) {
  const message = props.message || (negative ? ERROR_MESSAGE : SAVE_MESSAGE);

  store.dispatch(
    actions.addSnackbarMessage({ value: message, negative: !!negative, duration, link, img, CTA, position })
  );
}
