import React, { DetailedHTMLProps, HTMLAttributes, MouseEventHandler } from 'react';
import { Color } from '@codiwork/codi';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import { extractText } from './utils';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onClick: MouseEventHandler<HTMLDivElement>;
  /** Use in analytics track call */
  context?: string;
  name?: string;
  value?: string | number;
  activeColor?: Color;
  color?: Color;
}

// NOTE: This component should only be used if <Clickable> cannot be used.
// This component is typically used to get around an HTML structure limitation
// e.g. nested clickable elements.
// <Clickable> is more accessible and has more features.
const ClickableDiv: React.FC<Props> = ({ onClick, children, context, name, value, activeColor, color, ...props }) => {
  let className = props.className;

  if (activeColor) {
    className = classNames(className, `active-bg-color-${activeColor}`);
  }

  if (color) {
    className = classNames(className, `bg-color-${color}`);
  }

  return (
    <div
      {...props}
      className={classNames('ClickableDiv', className)}
      onClick={e => {
        onClick(e);
        track('Element Interacted', {
          context,
          name: name || extractText(children as JSX.Element),
          value,
          type: 'clickable'
        });
      }}
      role="button"
    >
      {children}
    </div>
  );
};

export default ClickableDiv;
