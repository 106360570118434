import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import pluralize from 'pluralize';

import { priceAdjustedForDaysPerWeek } from 'helpers/price';

import { app } from 'context';
import {
  ANIMATION_DURATION,
  Button,
  Icon,
  Layout,
  Modal,
  Pressable,
  PriceFilter,
  SizeFilter,
  Text,
  TextButton,
  ToggleControl
} from 'ds';
import MeetingRoomsFilter from 'ds/Filters/MeetingRoomsFilter';
import { apiTrack } from 'lib/analytics';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { DAYS_PER_WEEK_OPTIONS } from './constants';
import { FilterState, SearchFilters, SearchSortLabel } from './types';
import { FilterableWorkspace, filterWorkspaces, generateSearch } from './utils';

interface Props extends SearchFilters {
  onClose: VoidFunction;
  isVisible: boolean;
  daysPerWeek: number;
  offsitesOnly?: number;
  allWorkspaces: FilterableWorkspace[];
  onSubmit: VoidFunction;
  clearFilters: VoidFunction;
  sortLabel: SearchSortLabel;
  showOffsitesToggle?: boolean;
}

const FiltersBottomSheet: React.FC<Props> = ({
  onClose,
  isVisible,
  daysPerWeek,
  allWorkspaces,
  onSubmit,
  clearFilters,
  minPrice,
  maxPrice,
  minSqft,
  maxSqft,
  offsitesOnly,
  showOffsitesToggle,
  numMeetingRooms
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { contentPaddingX } = useContext(app);
  const handleClose = () => {
    onClose();

    setTimeout(() => {}, ANIMATION_DURATION);
  };
  const isLoggedIn = !!useAppSelector(selectUser);
  const [filterState, setFilterState] = useState<FilterState>({
    daysPerWeek,
    minPrice,
    maxPrice,
    minSqft,
    maxSqft,
    offsitesOnly,
    numMeetingRooms
  });
  const [filterOrdinal, setFilterOrdinal] = useState<number>(0);
  const filteredLength = filterWorkspaces({ workspaces: allWorkspaces, filters: filterState }).length;

  return (
    <Modal
      isVisible={isVisible}
      onClose={handleClose}
      animate={false}
      header="Filter and Sort"
      isFullScreen
      hasBottomSheetPaddingX
      footer={
        <Layout align="center" justify="space-between">
          <Layout equalWidth>
            <TextButton
              scale
              text="Reset"
              textSize="body1"
              semibold
              color="gray-900"
              onClick={() => {
                clearFilters();
                setFilterState({
                  minPrice: undefined,
                  maxPrice: undefined,
                  minSqft: undefined,
                  maxSqft: undefined,
                  numMeetingRooms: undefined,
                  daysPerWeek: 5
                });
                setFilterOrdinal(filterOrdinal + 1);
              }}
            />
          </Layout>
          <Layout equalWidth>
            <Button
              type="primary"
              size="sm"
              text={`See ${pluralize('workspace', filteredLength, true)}`}
              onClick={() => {
                let { minPrice, maxPrice, minSqft, maxSqft } = filterState;

                if (typeof minPrice === 'number' && typeof maxPrice === 'number' && maxPrice <= minPrice) {
                  // @ts-expect-error
                  maxPrice = null;
                }
                if (typeof minSqft === 'number' && typeof maxSqft === 'number' && maxSqft <= minSqft) {
                  // @ts-expect-error
                  maxSqft = null;
                }

                history.replace({
                  search: generateSearch({
                    search,
                    minPrice,
                    maxPrice,
                    minSqft,
                    maxSqft,
                    numMeetingRooms: filterState.numMeetingRooms
                  })
                });

                apiTrack('Filters Set', {
                  minPrice,
                  maxPrice,
                  minSqft,
                  maxSqft,
                  numMeetingRooms: filterState.numMeetingRooms
                });
                onSubmit();
              }}
              fullWidth
            />
          </Layout>
        </Layout>
      }
    >
      {showOffsitesToggle && (
        <Layout align="center" marginTop={16} marginBottom={52} justify="space-between">
          <Text size="body1">Show only offsite availability</Text>
          <ToggleControl
            size="sm"
            checked={!!offsitesOnly}
            onChange={value => {
              history.replace({
                search: generateSearch({
                  search,
                  daysPerWeek: null,
                  minPrice: null,
                  maxPrice: null,
                  minSqft: null,
                  maxSqft: null,
                  offsitesOnly: !!value ? 1 : null
                })
              });
              onClose();
            }}
          />
        </Layout>
      )}
      {!offsitesOnly && (
        <Layout marginTop={showOffsitesToggle ? 24 : undefined}>
          <Text size="h6" scale>
            Days needed per week
          </Text>
          <Layout marginTop={8}>
            <Text size="body3">
              Select the number of days you’ll need a workspace each week. Prices shown will be based on this number.
            </Text>
          </Layout>
          <Layout marginTop={24}>
            {DAYS_PER_WEEK_OPTIONS.map(({ value, label, sublabel }) => {
              const isSelected = value === daysPerWeek;

              return (
                <Pressable
                  key={value}
                  onPress={() => {
                    history.replace({ search: generateSearch({ search, daysPerWeek: value }) });
                  }}
                  style={{
                    position: 'relative',
                    left: -contentPaddingX,
                    width: `calc(100% + ${contentPaddingX}px * 2)`,
                    height: 56,
                    alignItems: 'center',
                    display: 'block'
                  }}
                >
                  <Layout justify="space-between" align="center" paddingX={24} inline>
                    <Layout inline>
                      <Text size="body2" semibold={isSelected} align="left">
                        {label}
                      </Text>
                      {sublabel && (
                        <Layout marginTop={4} inline>
                          <Text size="body3" color="gray-500" align="left">
                            {sublabel}
                          </Text>
                        </Layout>
                      )}
                    </Layout>
                    {isSelected && <Icon name="checkmark" size="md" stroke={2} color="gray-900" />}
                  </Layout>
                </Pressable>
              );
            })}
          </Layout>
          <Layout marginY={52} borderWidth={1} borderColor="gray-50" height={1} />
        </Layout>
      )}
      {isLoggedIn && (
        <>
          <PriceFilter
            values={allWorkspaces.map(ws =>
              priceAdjustedForDaysPerWeek({ monthlyPrice: ws.monthly_price || 0, daysPerWeek })
            )}
            minPrice={minPrice}
            maxPrice={maxPrice}
            onChange={values => setFilterState({ ...filterState, ...values })}
            onSubmit={values => {
              history.replace({
                search: generateSearch({ search, ...values })
              });
            }}
            key={filterOrdinal}
            offsitesOnly={!!offsitesOnly}
          />
          <Layout marginY={52} borderWidth={1} borderColor="gray-50" height={1} />
        </>
      )}
      <Layout>
        <SizeFilter
          values={allWorkspaces.map(ws => ws.square_feet || 0)}
          minSqft={minSqft}
          maxSqft={maxSqft}
          onChange={values => setFilterState({ ...filterState, ...values })}
          key={filterOrdinal}
          onSubmit={values =>
            history.replace({
              search: generateSearch({ search, ...values })
            })
          }
        />
      </Layout>
      <Layout marginY={52} borderWidth={1} borderColor="gray-50" height={1} />
      <Layout paddingBottom={96}>
        <MeetingRoomsFilter
          numMeetingRooms={filterState.numMeetingRooms || 0}
          onChange={value => setFilterState({ ...filterState, ...value })}
          key={filterOrdinal}
        />
      </Layout>
    </Modal>
  );
};

export default FiltersBottomSheet;
