import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { app, media } from 'context';
import { Animate, Button, Layout, Modal, Text } from 'ds';
import { Field, FormInput } from 'ds/forms';
import { track } from 'lib/analytics';
import { selectUser } from 'store/User/selectors';

interface Props {
  onClose: VoidFunction;
  isVisible: boolean;
}

const ShareDetailsModal: React.FC<Props> = ({ onClose, isVisible }) => {
  const { ipLocation, windowWidth } = useContext(app);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const { xs, sm, xl } = useContext(media);
  const columnLayout = xs || sm;
  const paddingX = xs ? undefined : undefined;

  const FIELDS = [
    {
      name: 'firstname',
      label: 'First name',
      required: true,
      value: user?.firstname || '',
      type: 'text'
    },
    {
      name: 'lastname',
      label: 'Last name',
      required: true,
      value: user?.lastname || '',
      type: 'text'
    },
    { name: 'email', label: 'Work email', required: true, value: user?.email || '', type: 'email' }
  ] as const;

  const [fields, setFields] = useState<Field<typeof FIELDS[number]['name']>[]>([...FIELDS]);
  const [isEmailValid, setIsEmailValid] = useState<boolean>();

  const FIELD_NAME_TO_WIDTH = {
    firstname: xs ? '100%' : sm ? '49%' : '48%',
    lastname: xs ? '100%' : sm ? '49%' : '48%',
    email: xs ? '100%' : sm ? '100%' : '100%'
  };
  const email = fields.find(field => field.name === 'email')?.value || '';
  const firstName = fields.find(field => field.name === 'firstname')?.value || '';
  const lastName = fields.find(field => field.name === 'lastname')?.value || '';
  const location = !!ipLocation ? `${ipLocation.city}, ${ipLocation.region}, ${ipLocation.country_code}` : undefined;
  const isValid = !!email && !!firstName && !!lastName && isEmailValid;

  const handleSubmit = () => {
    if (isValid) {
      track('Annual Report Form Submitted', { firstName, lastName, email, location });
      setIsSubmitted(true);
    }
  };

  return (
    <Layout>
      <Modal
        paddingX={0}
        isFullScreen={xs}
        paddingTop={0}
        onClose={onClose}
        isVisible={isVisible}
        {...(isSubmitted ? { size: 'md' } : { size: 'lg' })}
        overflow="hidden"
        footer={
          xs && !isSubmitted ? (
            <Button
              disabled={!isValid}
              fullWidth={true}
              target="_blank"
              size="lg"
              type="primary"
              text="Download report"
              icon="download"
              href={isValid ? 'https://codi-downloads.s3.amazonaws.com/Codi+2023+Annual+Report.pdf' : undefined}
              iconPosition="left"
              iconSize="md"
              iconStroke={1.5}
              onClick={handleSubmit}
            />
          ) : xs && isSubmitted ? (
            <Layout direction="column">
              <Button
                fullWidth={xs}
                target="_blank"
                size="sm"
                type="primary"
                text="Retry Download"
                href="https://codi-downloads.s3.amazonaws.com/Codi+2023+Annual+Report.pdf"
              />
              <Layout marginTop={12} />
              <Button target="_blank" size="sm" type="secondaryOutline" text="Close" onClick={onClose} fullWidth={xs} />
            </Layout>
          ) : undefined
        }
      >
        {isSubmitted ? (
          <Layout paddingX={32} marginTop={72}>
            <Layout>
              <Layout marginBottom={24}>
                <Text size="h4" color="blue-800" align="left">
                  Access granted.
                </Text>
              </Layout>
              <Text size="body1" color="gray-800" scale={true} align="left">
                The report should download automatically. If it doesn't, feel free to retry the download.
              </Text>
            </Layout>
            {!xs && (
              <Layout marginTop={40} justify="space-between" flex>
                <Layout {...(xs ? { equalWidth: true } : {})} marginRight={12}>
                  <Button
                    fullWidth={xs}
                    target="_blank"
                    size="sm"
                    type="primary"
                    text="Retry Download"
                    href="https://codi-downloads.s3.amazonaws.com/Codi+2023+Annual+Report.pdf"
                  />
                </Layout>
                <Layout {...(xs ? { equalWidth: true } : {})}>
                  <Button
                    target="_blank"
                    size="sm"
                    type="secondaryOutline"
                    text="Close"
                    onClick={onClose}
                    fullWidth={xs}
                  />
                </Layout>
              </Layout>
            )}
          </Layout>
        ) : (
          <>
            <Layout>
              <img
                alt="desks"
                src={`https://assets.codi.com/public/tr:w-${xs ? windowWidth : 768},h-${
                  xs ? 220 : 272
                }/annual-report-form-background_-U3yfUFl1.png`}
              ></img>
            </Layout>
            <Layout
              paddingX={32}
              marginTop={32}
              align="center"
              direction="column"
              {...(columnLayout ? {} : { maxWidth: 768 })}
            >
              <Animate>
                <Text size="h4" color="blue-800" align="left">
                  Share details to view the report
                </Text>
                <Layout marginY={24}>
                  <Text size="body1" color="gray-800" scale={true} align="left">
                    Complete the form to gain access to the report.
                  </Text>
                </Layout>
                <Layout flex={!xs} wrap={!xs} justify={!xs || sm ? 'space-between' : undefined}>
                  {fields.map(field => (
                    <FormInput
                      {...field}
                      key={field.name}
                      inputContainerProps={{
                        width: FIELD_NAME_TO_WIDTH[field.name],
                        paddingX,
                        paddingY: 8
                      }}
                      size={xs ? 'sm' : xl ? 'lg' : 'md'}
                      fields={fields}
                      setFields={setFields}
                      onValidate={({ valid }) => {
                        if (field.name === 'email') {
                          setIsEmailValid(valid);
                        }
                      }}
                    />
                  ))}
                  {!xs && (
                    <Layout marginTop={24} flexGrow={1}>
                      <Button
                        disabled={!isValid}
                        fullWidth={true}
                        target="_blank"
                        size="lg"
                        type="primary"
                        text="Download report"
                        icon="download"
                        href="https://codi-downloads.s3.amazonaws.com/Codi+2023+Annual+Report.pdf"
                        iconPosition="left"
                        iconSize="md"
                        iconStroke={1.5}
                        onClick={handleSubmit}
                      />
                    </Layout>
                  )}
                </Layout>
              </Animate>
            </Layout>
          </>
        )}
      </Modal>
    </Layout>
  );
};

export default ShareDetailsModal;
