import React from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

import { CommonPageLayoutProps } from './types';

interface Props extends Pick<CommonPageLayoutProps, 'titleColor'> {
  CTA?: JSX.Element;
  title: string;
}

const AppPageTitle: React.FC<Props> = ({ title, CTA, titleColor }) => {
  return (
    <Layout justify="space-between">
      <Text size="headline-md" color={titleColor}>
        {title}
      </Text>
      {CTA}
    </Layout>
  );
};

export default AppPageTitle;
