import React from 'react';

import Icon from './Icon';
import Layout from './Layout';
import Text from './Text';

interface Props {}

const NewTag: React.FC<Props> = () => {
  return (
    <Layout paddingY={4} color="green-100" borderRadius={4} paddingX={10} align="center">
      <Icon name="star" color="green-700" size="xs" />
      <Layout marginLeft={4} display="inline-flex">
        <Text size="body-xs" color="green-700" semibold>
          NEW
        </Text>
      </Layout>
    </Layout>
  );
};

export default NewTag;
