import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const CarvingOutNewSpace: React.FC<Props> = () => {
  const { windowHeight } = useContext(app);
  const { xs, md, lg } = useContext(media);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={md ? 80 : 48}
        >
          <Text size="h3" color="blue-600">
            Carving Out Your New Space
          </Text>
          <Layout marginTop={md || lg ? 24 : 48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                Once you’ve found a coworking office location that’s best for your team, what happens next depends
                greatly on the following factors:
                <br />
                <br />
                <ul>
                  <li>
                    <Text size="h6" color="gray-700" bold={false}>
                      How large your team is — i.e., how much space you need
                    </Text>
                  </li>
                  <li>
                    <Text size="h6" color="gray-700" bold={false}>
                      How much customization you’re requesting
                    </Text>
                  </li>
                  <li>
                    <Text size="h6" color="gray-700" bold={false}>
                      If your business requires additional security or a separate IT network
                    </Text>
                  </li>
                </ul>
                <br />
                The sales team rep you’re working with will take these requirements into consideration while drafting
                your agreement. In the best cases, the process can be pretty transactional and is generally much easier
                than developing a direct lease with a traditional landlord. They recognize that most companies want some
                kind of customization and they are prepared with a pre-set menu of options and upgrades for larger
                tenants.
                <br />
                <br />
                However, larger spaces within a coworking property may be in short supply. If the building you’d like to
                be in doesn’t have ample room, it could be months before space opens up.
              </Text>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="coworking_r7G0HU4je.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default CarvingOutNewSpace;
