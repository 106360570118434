import {
  DEVELOP_URL,
  Environment,
  PRODUCTION_HOSTNAME,
  PRODUCTION_URL,
  STAGING_HOSTNAME,
  STAGING_URL
} from '@codiwork/codi';

export function isProduction(): boolean {
  return window.location.hostname === PRODUCTION_HOSTNAME;
}

export function isStaging(): boolean {
  return window.location.hostname === STAGING_HOSTNAME;
}

export function isDevelop(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function getDomain(): string {
  if (isDevelop()) {
    return 'localhost';
  }

  return window.location.hostname;
}

export function getBaseUrl() {
  switch (getEnvironment()) {
    case 'development':
      return DEVELOP_URL;
    case 'staging':
      return STAGING_URL;
    default:
      return PRODUCTION_URL;
  }
}

export function getEnvironment(): Environment {
  if (isProduction()) {
    return 'production';
  } else if (isStaging()) {
    return 'staging';
  } else {
    return 'development';
  }
}
