import React, { useState } from 'react';
import { DateTimeFormat, yyyyMmDdToDate } from '@codiwork/codi';
import { Placement } from '@popperjs/core';

import { FieldSize } from 'ds/types';

import DatePickerCalendar from './DatePickerCalendar';
import DatePickerContainer from './DatePickerContainer';
import { CalendarDisplayState, DatePickerProps } from './types';

export interface Props extends DatePickerProps {
  size?: FieldSize;
  label?: string;
  placeholder?: string;
  dateTimeFormat?: DateTimeFormat;
  disabled?: boolean;
  isBottomSheet?: boolean;
  type?: 'marketing';
  required?: boolean;
  placement?: Placement;
  setCalendarElement?: (calendar: HTMLDivElement | null) => void;
  bottomSheetZIndexPosition?: number;
  isReadOnly?: boolean;
  excludeDates?: Date[];
}

const DatePicker: React.FC<Props> = ({
  label,
  onChange,
  size,
  placeholder,
  allowPastDates,
  dateTimeFormat = 'MED_WITH_SHORT_WEEKDAY',
  disabled,
  isBottomSheet,
  onlyWeekdays,
  required,
  type,
  placement,
  setCalendarElement,
  bottomSheetZIndexPosition,
  isReadOnly,
  excludeDates,
  ...props
}) => {
  const [calendarState, setCalendarState] = useState<CalendarDisplayState>('hidden');
  const onClear = () => {
    onChange(null);
  };
  const selected = typeof props.selected === 'string' ? yyyyMmDdToDate(props.selected) : props.selected;
  const minDate = typeof props.minDate === 'string' ? yyyyMmDdToDate(props.minDate) : props.minDate;
  const maxDate = typeof props.maxDate === 'string' ? yyyyMmDdToDate(props.maxDate) : props.maxDate;

  return (
    <DatePickerContainer
      selected={selected}
      dateTimeFormat={dateTimeFormat}
      size={size}
      calendarState={calendarState}
      setCalendarState={setCalendarState}
      placeholder={placeholder}
      onClear={onClear}
      calendarClassName="DatePickerCalendar"
      label={label}
      isBottomSheet={isBottomSheet}
      type={type}
      required={required}
      placement={placement}
      setCalendarElement={setCalendarElement}
      bottomSheetZIndexPosition={bottomSheetZIndexPosition}
      disabled={disabled && !isReadOnly}
      isReadOnly={isReadOnly}
    >
      <DatePickerCalendar
        selected={selected}
        onChange={date => {
          onChange(date);
          setCalendarState('hidden');
        }}
        minDate={allowPastDates ? undefined : minDate || new Date()}
        maxDate={maxDate}
        onlyWeekdays={onlyWeekdays}
        excludeDates={excludeDates}
      />
    </DatePickerContainer>
  );
};

export default DatePicker;
