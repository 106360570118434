import React, { useContext, useRef, useState } from 'react';

import { createTransformations, imageKitUrl } from 'helpers/images';

import { app } from 'context';
import Clickable from 'ds/Clickable';
import Layout from 'ds/Layout';

import { TOUR_VIDEO_HEIGHT, TOUR_VIDEO_WIDTH } from './constants';
import { Dimensions } from '../types';

import { ReactComponent as Play } from '../Play.svg';

interface Props {
  tourVideoKey: string;
}

const VideoTour: React.FC<Props> = ({ tourVideoKey }) => {
  const [ordinal, setOrdinal] = useState<number>(1);
  const { windowHeight, windowWidth } = useContext(app);
  const tourVideoRef = useRef<HTMLVideoElement>(null);
  const playTourVideo = () => {
    tourVideoRef.current?.play();
  };
  const isVideoPlaying = !!tourVideoRef.current && !tourVideoRef.current.paused;

  const tourVideoMaxDimensions = { height: windowHeight - 204, width: windowWidth - 96 };

  const tourVideoDimensions = (): Dimensions => {
    const allowedWidthOfTourVideo = tourVideoMaxDimensions.width;
    const allowedHeightOfTourVideo = tourVideoMaxDimensions.height;

    const aspectRatio = TOUR_VIDEO_WIDTH / TOUR_VIDEO_HEIGHT;

    if (allowedWidthOfTourVideo / allowedHeightOfTourVideo > aspectRatio) {
      return {
        height: allowedHeightOfTourVideo,
        width: allowedHeightOfTourVideo * aspectRatio
      };
    }

    return {
      height: allowedWidthOfTourVideo / aspectRatio,
      width: allowedWidthOfTourVideo
    };
  };
  const { height: tourVideoHeight, width: tourVideoWidth } = tourVideoDimensions();

  return (
    <Layout justify="center" align="flex-start">
      <Layout
        marginTop={40}
        maxWidth={tourVideoMaxDimensions.width}
        maxHeight={tourVideoMaxDimensions.height}
        width={tourVideoWidth}
        height={tourVideoHeight}
        borderRadius={12}
        overflow="hidden"
        position="relative"
      >
        <Clickable
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            display: isVideoPlaying ? 'none' : undefined
          }}
          onClick={() => playTourVideo()}
          children={<Play />}
        />
        <Layout position="relative" justify="center" align="center">
          <video
            ref={tourVideoRef}
            controlsList="nodownload"
            muted
            loop
            controls={isVideoPlaying}
            style={{
              height: '100%',
              width: '100%',
              position: 'relative'
            }}
            onPlay={() => setOrdinal(ordinal + 1)}
          >
            <source
              src={imageKitUrl({
                key: tourVideoKey,
                transformationChain: createTransformations({
                  width: TOUR_VIDEO_WIDTH,
                  height: TOUR_VIDEO_HEIGHT
                })
              })}
              type="video/mp4"
            />
          </video>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default VideoTour;
