import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const Growth2: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowHeight } = useContext(app);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex direction={xs ? 'column' : 'row'}>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={48}
        >
          <Text size="h3" color="blue-600">
            Growth
          </Text>
          <Layout marginTop={48}>
            <Layout>
              <Text size="h6" bold={false} color="gray-700">
                One of the great advantages to working with a flex office company is that growth is always a good thing
                to have — and an easy challenge to solve.
                <br />
                <br />
                Because you’re not locked into a multi-year term like with a traditional lease, or even a year-long
                agreement that coworking might require, you’re able to upgrade just as soon as you identify suitable
                space in your market.
                <br />
                <br />
                Your rep already knows your needs, your monthly agreement can be updated based on your new space, and
                you can be in your new home in a matter of days.
                <br />
                <br />
                If the inverse is true — if you find you have to downsize — the same process applies. Flexible really
                means “Flexible.”
              </Text>
            </Layout>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="chairs-and-plants_tyJNX0747.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default Growth2;
