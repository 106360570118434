import { axios } from '@codiwork/codi';

import { CreatableBrokerForm } from './types';

export function createBrokerForm({ firstName, lastName, email, phone, brokerage, city }: CreatableBrokerForm) {
  return axios.post('/v1/broker_forms', {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone,
    brokerage: brokerage,
    city: city
  });
}
