import React, { PropsWithChildren } from 'react';

import { TABLE_CELL_FONT_SIZE } from './constants';
import Text from '../Text';

interface Props {
  value: string | null;
}

const Cell: React.FC<PropsWithChildren<Props>> = ({ value }) => {
  return (
    <Text size={TABLE_CELL_FONT_SIZE} ellipsis>
      {value}
    </Text>
  );
};

export default Cell;
