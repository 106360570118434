import React, { PropsWithChildren, useContext, useEffect } from 'react';

import { media } from 'context';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { CUSTOMER_TABLE_PADDING_BOTTOM } from 'ds/constants';
import { setRootBackgroundColor } from 'ds/utils';

import AppPageNavBar from './AppPageNavBar';
import AppPageTitleContainer from './AppPageTitleContainer';
import SecondaryMenu from './SecondaryMenu';
import { APP_CONTENT_GUTTER_BOTTOM, APP_PAGE_PADDING_X, APP_PAGE_PADDING_X_MOBILE } from './constants';
import { CommonPageLayoutProps, CustomerTablePageProps } from './types';

interface Props extends CustomerTablePageProps, Pick<CommonPageLayoutProps, 'titleColor'> {}

const CustomerTableLayout: React.FC<PropsWithChildren<Props>> = ({ children, titleColor, ...props }) => {
  const { isMobile } = useContext(media);

  useEffect(() => {
    if (isMobile) {
      setRootBackgroundColor('blue-25');
    } else {
      setRootBackgroundColor(null);
    }

    return () => {
      setRootBackgroundColor(null);
    };
  }, [isMobile]);

  return isMobile ? (
    <Layout flexGrow={1}>
      <AppPageNavBar {...props} mobileColor="blue-25" />
      <Layout paddingX={APP_PAGE_PADDING_X_MOBILE} marginTop={36} paddingBottom={APP_CONTENT_GUTTER_BOTTOM}>
        {children}
      </Layout>
    </Layout>
  ) : (
    <>
      <SecondaryMenu {...props.secondaryMenuProps} />
      <Layout flexGrow={1} justify="center" paddingBottom={CUSTOMER_TABLE_PADDING_BOTTOM}>
        <Layout flexGrow={1} maxWidth={1448}>
          <AppPageTitleContainer>
            <Text size="headline-md" color={titleColor}>
              {props.title}
            </Text>
          </AppPageTitleContainer>
          <Layout paddingX={APP_PAGE_PADDING_X} marginTop={44}>
            {children}
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default CustomerTableLayout;
