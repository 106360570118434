import React, { useContext } from 'react';

import Head from 'components/Head';

import { media } from 'context';
import { Layout } from 'ds';

import CaseStudies from './CaseStudies';
import Hero from './Hero';
import Quotes from './Quotes';
import Try from './Try';
import Videos from './Videos';

interface Props {}

const Testimonials: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <>
      <Head
        title="See how companies are embracing workplace flexibility | Codi"
        description="Hear firsthand how innovative companies are leveraging Codi to unlock flexible, private offices on their terms."
      />
      <Hero />
      <Videos />
      <CaseStudies />
      <Quotes />
      <Layout marginTop={xs ? undefined : 160}>
        <Try />
      </Layout>
    </>
  );
};

export default Testimonials;
