import React, { useContext } from 'react';

import { media } from 'context';
import { Grid, Layout, Text } from 'ds';

import WhyPartnerCard, { Props as CardProps } from './WhyPartnerCard';

interface Props {}

const CARDS: CardProps[] = [
  {
    icon: 'starOutline',
    title: 'Exclusive deals',
    description: 'Access exclusive deals customized for your success.',
    ctaText: 'Learn more'
  },
  {
    icon: 'dollarSign',
    title: 'Effortless earnings',
    description:
      'Retain full ownership of your client relationships and earn recurring, reliable commissions. Get your referral bonus within 10 days of closing!',
    ctaText: 'Start earning'
  },
  {
    icon: 'tv',
    title: 'Exponential growth',
    description: 'Build a sustainable book of business with Codi.',
    ctaText: 'Get started'
  }
];

const WhyPartner: React.FC<Props> = () => {
  const { isMobile, xs, md } = useContext(media);

  return (
    <Layout>
      <Text size={isMobile ? 'headline-lg' : md ? 'overline-lg' : 'display-sm'} color={'blue-600'} align="center">
        Why partner with Codi
      </Text>
      {isMobile ? (
        <Layout justify="center">
          <Layout marginTop={24} {...(xs ? {} : { maxWidth: 580 })}>
            <Grid itemsPerRow={1} gapY={24}>
              {CARDS.map(c => {
                return <WhyPartnerCard {...c} key={c.title} />;
              })}
            </Grid>
          </Layout>
        </Layout>
      ) : (
        <Layout marginTop={32} padding={64} color="blue-100" borderRadius={24}>
          <Grid itemsPerRow={3} gapX={48}>
            {CARDS.map(c => {
              return <WhyPartnerCard {...c} key={c.title} />;
            })}
          </Grid>
        </Layout>
      )}
    </Layout>
  );
};

export default WhyPartner;
