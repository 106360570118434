import React, { useContext, useState } from 'react';
import { useThrottledCallback, useWindowResize } from 'beautiful-react-hooks';

import { media } from 'context';

import Item, { Props as ItemProps } from './Item';
import Layout from '../Layout';

interface Props {
  items: ItemProps[];
  columns: 1 | 2;
}

const Accordion: React.FC<Props> = ({ items, columns }) => {
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const { xs, sm, md, lg } = useContext(media);
  const onWindowResize = useWindowResize();

  onWindowResize(
    useThrottledCallback(
      _event => {
        setWindowWidth(window.innerWidth);
      },
      [],
      250
    )
  );

  const midLength = Math.round(items.length / 2);

  const leftSide = items.slice(0, midLength);
  const rightSide = items.slice(midLength);
  const spaceX = lg ? 88 : md ? 64 : sm ? 40 : undefined;

  const twoColumns = columns === 2;

  return (
    <Layout>
      <Layout direction={xs ? 'column' : twoColumns ? 'row' : 'column'}>
        <Layout marginRight={twoColumns ? spaceX : undefined} equalWidth>
          {leftSide.map(({ title, body }, i) => (
            <Item title={title} body={body} key={i + windowWidth} />
          ))}
        </Layout>
        <Layout marginLeft={twoColumns ? spaceX : undefined} equalWidth>
          {rightSide.map(({ title, body }, i) => (
            <Item title={title} body={body} key={i + windowWidth} />
          ))}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Accordion;
