import React, { useContext, useState } from 'react';
import { BLUE_500_HEX, GRAY_50_HEX } from '@codiwork/codi';

import { media } from 'context';
import Clickable from 'ds/Clickable';
import FilterContainer from 'ds/FilterContainer';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';

interface Props {
  numMeetingRooms: number;
  onChange?: (params: { numMeetingRooms: number }) => void;
  onSubmit?: (params: { numMeetingRooms: number }) => void;
  align?: 'left' | 'right';
  initialState?: 'open' | 'closed';
}

const OPTIONS = [
  { value: 0, label: 'Any' },
  { value: 1, label: '1+' },
  { value: 2, label: '2+' },
  { value: 3, label: '3+' },
  { value: 4, label: '4+' }
];

const MeetingRoomsFilter: React.FC<Props> = ({
  numMeetingRooms,
  align,
  onSubmit,
  onChange,
  initialState = 'closed'
}) => {
  const { xs } = useContext(media);
  const [unsubmittedValue, setUnsubmittedValue] = useState<number>(numMeetingRooms);

  const resetToInitialValues = () => {
    setUnsubmittedValue(0);
  };

  const selectedIndex =
    unsubmittedValue > 4
      ? 4
      : OPTIONS.findIndex(option => option.value === (xs ? numMeetingRooms : unsubmittedValue)) || 0;

  if (xs && onChange) {
    return (
      <Layout>
        <Layout align="center" marginBottom={16}>
          <Icon name="meetingRoom" size="md" color="gray-900" />
          <Layout align="center" marginLeft={8}>
            <Text size="h6" scale>
              Meeting rooms
            </Text>
          </Layout>
        </Layout>
        <MeetingRoomsSelector selectedIndex={selectedIndex} onChange={value => onChange({ numMeetingRooms: value })} />
      </Layout>
    );
  }

  if (!onSubmit) return null;

  return (
    <FilterContainer
      initialState={initialState}
      icon="meetingRoom"
      isDefaultSelected={!numMeetingRooms}
      buttonText={numMeetingRooms > 0 ? OPTIONS[Math.min(4, numMeetingRooms)].label : 'Meeting rooms'}
      align={align}
      onClickOutside={resetToInitialValues}
      onSubmit={() => onSubmit({ numMeetingRooms: unsubmittedValue })}
    >
      <Layout marginBottom={16}>
        <Text size="body-sm" semibold>
          Meeting rooms
        </Text>
      </Layout>
      <Layout paddingBottom={16}>
        <MeetingRoomsSelector selectedIndex={selectedIndex} onChange={value => setUnsubmittedValue(value)} />
      </Layout>
    </FilterContainer>
  );
};

interface SelectorProps {
  selectedIndex: number;
  onChange: (value: number) => void;
}

const MeetingRoomsSelector: React.FC<SelectorProps> = ({ selectedIndex, onChange }) => {
  return (
    <Layout marginTop={16} align="center">
      {OPTIONS.map(({ value, label }, index) => {
        const isSelected = index === selectedIndex;
        const isFirst = index === 0;
        const isLast = index === OPTIONS.length - 1;

        let leftBorderColor = GRAY_50_HEX;

        if (isSelected) {
          leftBorderColor = BLUE_500_HEX;
        } else if (isSelected || selectedIndex === index - 1) {
          leftBorderColor = 'transparent';
        }

        return (
          <Clickable onClick={() => onChange(value)} key={label}>
            <Layout
              width={64}
              paddingY={12}
              justify="center"
              borderTop
              borderBottom
              __style={{
                borderLeft: `1px solid ${leftBorderColor}`
              }}
              borderRight={isLast}
              {...(isSelected ? { color: 'blue-500', borderColor: 'blue-500' } : {})}
              {...(isFirst ? { borderBottomLeftRadius: 8, borderTopLeftRadius: 8 } : {})}
              {...(isLast ? { borderTopRightRadius: 8, borderBottomRightRadius: 8 } : {})}
            >
              <Text size="body-sm" color={isSelected ? 'white' : 'gray-900'}>
                {label}
              </Text>
            </Layout>
          </Clickable>
        );
      })}
    </Layout>
  );
};

export default MeetingRoomsFilter;
