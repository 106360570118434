export const BORDER_WIDTH = 1;
export const HEADER_HEIGHT = 48;
export const CELL_HEIGHT = 84;
export const CELL_PADDING = 12;
export const TABLE_CELL_FONT_SIZE = 'body-sm';
export const HEADER_CONTROL_SIZE = 'xxs';
export const HEADER_INPUT_SIZE = 'sm';
export const INACTIVE_SORT_FILTER_COLOR = 'gray-600';
export const ACTIVE_SORT_FILTER_COLOR = 'blue-500';

export const DATE_COLUMN_WIDTH = 120;
export const ISO_DATE_WIDTH = 200;
