import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { media } from 'context';
import { Button, Icon, Layout, Pressable, Text } from 'ds';
import { ADD_PROPERTY_PATH } from 'routes';

import { TenantLead } from './types';

interface Props {
  tenantLead: TenantLead;
  index: number;
  tenantLeadsLength: number;
}

const TenantLeadRow: React.FC<Props> = ({
  tenantLead: { id, client, industry, location, size, monthly_budget, start_date },
  index,
  tenantLeadsLength
}) => {
  const { xs } = useContext(media);
  const history = useHistory();

  const row = (
    <Layout
      paddingX={24}
      height={64}
      direction="row"
      align="center"
      color={index % 2 === 0 ? 'gray-25' : 'white'}
      borderBottomLeftRadius={index === tenantLeadsLength - 1 ? 12 : undefined}
      borderBottomRightRadius={index === tenantLeadsLength - 1 ? 12 : undefined}
    >
      <Layout width={116} direction="row" align="center">
        <Layout
          color="gray-50"
          borderRadius="circular"
          padding={8}
          height={28}
          width={28}
          justify="center"
          align="center"
        >
          <Icon name="avatar" size="xxs" color="gray-400" />
        </Layout>
        <Layout width={12} />
        <Text size="body2" color="gray-700">
          {client}
        </Text>
      </Layout>
      <Layout width={232}>
        <Text size="body2" color="gray-700">
          {industry}
        </Text>
      </Layout>
      <Layout width={232}>
        <Text size="body2" color="gray-700">
          {location}
        </Text>
      </Layout>
      <Layout width={144}>
        <Text size="body2" color="gray-700">
          {size}
        </Text>
      </Layout>
      <Layout width={168}>
        <Text size="body2" color="gray-700">
          {monthly_budget}
        </Text>
      </Layout>
      <Layout width={114}>
        <Text size="body2" color="gray-700">
          {start_date}
        </Text>
      </Layout>
      {!xs && (
        <Layout width={112}>
          <Button text="Add property" size="xs" type="secondaryOutline" href={ADD_PROPERTY_PATH} value={id} />
        </Layout>
      )}
    </Layout>
  );

  if (xs) {
    return (
      <Pressable onPress={() => history.push(ADD_PROPERTY_PATH)} value={id}>
        {row}
      </Pressable>
    );
  }

  return row;
};

export default TenantLeadRow;
