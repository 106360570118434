import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, Button, Layout, Text } from 'ds';
import { SEARCH_PATH } from 'routes';

interface Props {
  image: string;
  title: string;
  text: string;
  cta_text: string;
}

const VERTICAL_SPACING = 48;

const Hero: React.FC<Props> = ({ image, title, text, cta_text }) => {
  const { xs, media500, sm, md, lg, xl } = useContext(media);

  return (
    <Animate>
      <Layout
        color="white"
        height={xl ? 900 : lg ? 800 : md ? 700 : undefined}
        direction="column"
        align="center"
        justify="center"
        paddingY={xs || sm ? 80 : undefined}
        paddingX={xs ? undefined : sm ? 40 : md ? 40 : 80}
      >
        <Layout width={lg ? 96 : 72} height={lg ? 96 : 72}>
          <img src={image} alt={cta_text} />
        </Layout>
        <Layout marginTop={VERTICAL_SPACING} />
        <Text size={xs || sm ? 'h4' : 'h3'} align="center">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Text>
        <Layout marginTop={VERTICAL_SPACING} />
        <Layout maxWidth={xs ? 606 : undefined}>
          <Text size={lg ? 'h4' : sm || md ? 'h5' : 'h6'} color="gray-500" notBold align="center">
            {text}
          </Text>
        </Layout>
        <Layout marginTop={VERTICAL_SPACING} />
        <Layout width={xs ? '100%' : 274} maxWidth={media500 ? undefined : 328} justify="center">
          <Button
            size="lg"
            type="primary"
            text={cta_text}
            href={SEARCH_PATH}
            search="?offsites_only=1"
            fullWidth={media500}
          />
        </Layout>
      </Layout>
    </Animate>
  );
};

export default Hero;
