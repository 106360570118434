import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatAddress } from '@codiwork/codi';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import { CardWorkspace, Layout, NewTag, Text, WorkspaceImg } from 'ds';
import { formatUsd } from 'helpers';
import { track } from 'lib/analytics';
import { LISTING_PATH, STB_REQUEST_PATH } from 'routes';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

export interface Props {
  workspace: CardWorkspace;
  daysPerWeek?: number;
  offsitesOnly?: boolean;
}

const SearchWorkspaceCarouselCard: React.FC<Props> = ({
  workspace: { slug, images, name, id, is_new, square_feet, monthly_price, daily_rate, address },
  daysPerWeek,
  offsitesOnly
}) => {
  const coverPhoto = images[0];

  const pathname = useHistory().location.pathname;
  const price = offsitesOnly ? daily_rate : monthly_price;
  const isLoggedIn = !!useAppSelector(selectUser);
  const linkPath = offsitesOnly ? `${STB_REQUEST_PATH}/${id}` : `${LISTING_PATH}/${slug}?days_per_week=${daysPerWeek}`;

  return (
    <Layout borderRadius={12} boxShadow="0 13px 23px rgba(0, 0, 0, 0.15)">
      <Link
        key={slug}
        to={{
          pathname: linkPath,
          state: { from: pathname }
        }}
        onClick={() => {
          track('Element Interacted', {
            type: 'link',
            context: 'Workspace map',
            name: name || formatAddress(address),
            value: id || ''
          });
        }}
        style={{ display: 'block' }}
        target="_blank"
      >
        <Layout flex>
          <Layout position="relative" display="inline-flex">
            <WorkspaceImg
              size="thumbnail"
              srcKey={coverPhoto?.key}
              alt={name}
              style={{
                objectFit: 'cover',
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
                width: 124,
                height: 100
              }}
            />
            {is_new && (
              <Layout position="absolute" top={12} left={12} zIndex={1}>
                <NewTag />
              </Layout>
            )}
          </Layout>
          <Layout
            direction="column"
            color="white"
            justify="space-between"
            paddingY={12}
            paddingX={16}
            borderBottomRightRadius={12}
            borderTopRightRadius={12}
            flexGrow={1}
            minWidth={0}
          >
            <Text size="body1" color="gray-900" bold={false} maxLines={2} wordBreak="break-word" scale>
              {name}
            </Text>
            <Layout justify="space-between" marginTop={4} align="center">
              <Text size="body2" color="gray-700" scale>
                {(square_feet || ' ').toLocaleString()}
                {square_feet ? ' sqft' : ''}
              </Text>
              <Layout display="inline-flex">
                {isLoggedIn && price && price > 0 && (
                  <Layout inline>
                    <Text size="body1" color="gray-900" semibold inline scale>
                      {offsitesOnly
                        ? formatUsd(price, false)
                        : formatPriceAdjustedForDaysPerWeek({ monthlyPrice: price, daysPerWeek })}
                    </Text>
                    <Text size="body2" color="gray-900" whiteSpace="pre" inline scale>
                      {' '}
                      /{offsitesOnly ? 'day' : 'month'}
                    </Text>
                  </Layout>
                )}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Link>
    </Layout>
  );
};

export default SearchWorkspaceCarouselCard;
