import React from 'react';
import classNames from 'classnames';

import Layout from 'ds/Layout';

import DatePickerCalendar, { Props as DatePickerCalendarProps } from './DatePickerCalendar';

export interface Props
  extends Pick<DatePickerCalendarProps, 'minDate' | 'maxDate' | 'onlyWeekdays' | 'allowPastDates' | 'excludeDates'> {
  onChange: (dates: Date[]) => void;
  selected: Date[];
}

const DatePickerMultiple: React.FC<Props> = ({
  selected,
  onChange,
  minDate,
  maxDate,
  onlyWeekdays,
  allowPastDates,
  excludeDates
}) => {
  return (
    <Layout>
      <Layout __className={classNames('DatePickerMultiple')}>
        <DatePickerCalendar
          selectedDates={selected}
          onChange={dates => {
            // @ts-expect-error
            onChange(dates);
          }}
          excludeDates={excludeDates}
          minDate={allowPastDates ? undefined : minDate || new Date()}
          maxDate={maxDate}
          onlyWeekdays={onlyWeekdays}
          selectsMultiple
        />
      </Layout>
    </Layout>
  );
};

export default DatePickerMultiple;
