import React, { PropsWithChildren } from 'react';

import Layout, { Props as LayoutProps } from './Layout';

interface Props extends LayoutProps {
  setHasScrolled: (hasScrolled: boolean) => void;
}

const ScrollShadowContainer: React.FC<PropsWithChildren<Props>> = ({ children, setHasScrolled, ...props }) => {
  return (
    <Layout
      {...props}
      onScroll={e => {
        const { scrollTop } = e.currentTarget;

        if (scrollTop > 2) {
          setHasScrolled(true);
        } else if (scrollTop < 2) {
          setHasScrolled(false);
        }
      }}
    >
      {children}
    </Layout>
  );
};

export default ScrollShadowContainer;
