import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { media } from 'context';
import { IconButton, Layout, Logo } from 'ds';

interface Props {}

const STBRequestHeader: React.FC<Props> = () => {
  const history = useHistory();
  const { xs } = useContext(media);

  return (
    <Layout
      justify="space-between"
      {...(xs ? { height: 68, paddingTop: 12, align: 'center' } : { align: 'center', height: 96 })}
    >
      <Logo size="sm" color="blue-500" />
      <IconButton name="close" size={xs ? 'sm' : 'lg'} type="gray" onClick={() => history.goBack()} stroke={2} />
    </Layout>
  );
};

export default STBRequestHeader;
