import React, { useContext } from 'react';
import { ApiErrorResponse, AuthResponseData, authenticateGoogle, isApiErrorResponse } from '@codiwork/codi';
import { useGoogleLogin } from '@react-oauth/google';
import classNames from 'classnames';

import { media } from 'context';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { BUTTON_SIZE_CONFIG, DS_CLASS_NAME } from 'ds/constants';
import { FieldSize } from 'ds/types';
import { useFieldSize } from 'hooks';
import { selectGoogleOAuthLoaded } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

import ButtonPrivate from '../ButtonPrivate';

import GoogleLogo from './google.svg';

export interface Props {
  setSubmitting: (submitting: boolean) => void;
  submitting: boolean;
  handleError?: (error: ApiErrorResponse) => void;
  handleSuccess?: (data: AuthResponseData) => void;
  size?: FieldSize;
  fullWidth?: boolean;
  createListing?: boolean;
}

const GoogleAuth: React.FC<Props> = ({
  handleError,
  setSubmitting,
  submitting,
  fullWidth,
  handleSuccess,
  ...props
}) => {
  const { xs } = useContext(media);
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const isLoaded = useAppSelector(selectGoogleOAuthLoaded);

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      handleAuthentication({ code });
    },
    flow: 'auth-code'
  });

  const handleAuthentication = ({ code }: { code: string }) => {
    setSubmitting(true);

    authenticateGoogle({ code })
      .then(({ data }) => handleSuccess && handleSuccess(data))
      .catch(error => {
        if (isApiErrorResponse(error) && handleError) {
          handleError(error);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Layout __className={classNames('GoogleButton', DS_CLASS_NAME)}>
      <ButtonPrivate
        color="white"
        hoverColor="gray-25"
        activeColor="gray-100"
        activeBorderColor="gray-50"
        disabledColor="gray-50"
        textColor="gray-900"
        focusBorderColor="blue-300"
        spinnerColor="gray-600"
        borderWidth={1}
        borderColor="gray-50"
        onClick={() => login()}
        disabled={!isLoaded || submitting}
        {...BUTTON_SIZE_CONFIG[size]}
        size={size}
        text={
          <Layout flex justify="space-between" direction="row" align="center" width="100%">
            <Layout width={24} height={24} align="center">
              <img alt="Continue with Google" src={GoogleLogo} style={{ width: 24, height: 24 }} />
            </Layout>
            <Layout paddingLeft={24} align="center">
              <Text size={xs ? 'body2' : 'body1'} semibold={true} align="center" scale>
                Continue with Google
              </Text>
            </Layout>
            <Layout inline width={xs ? 24 : 32} />
          </Layout>
        }
        fullWidth={fullWidth}
      />
    </Layout>
  );
};

export default GoogleAuth;
