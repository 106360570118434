import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

import ErrorPage from './ErrorPage';

interface Props {}

const ErrorBoundary: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
