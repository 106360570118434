import React, { useContext, useState } from 'react';
import { Image, ListingWorkspace } from '@codiwork/codi';

import { generateImgSrc } from 'helpers/images';

import { app } from 'context';
import { Clickable, Layout, SwiperCarousel, WorkspaceImg } from 'ds';
import MediaModal from 'ds/MediaModal';
import { SwiperSlide } from 'swiper/react';

interface Props {
  images: Image[];
  floorPlans: Image[];
  matterportId: string | null;
  tourVideoKey: string | null;
  backLabel?: string;
  isOffsite?: boolean;
  workspace: Pick<
    ListingWorkspace,
    'id' | 'monthly_price' | 'address' | 'availability_status' | 'availability_start_date'
  >;
  onClick?: VoidFunction;
}

const CONFIG = {
  xs: {
    slidesPerView: 1,
    spaceBetween: 0,
    isFullScreen: true
  }
};

const MobileCarousel: React.FC<Props> = ({
  images,
  floorPlans,
  backLabel,
  matterportId,
  tourVideoKey,
  isOffsite,
  workspace
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(-1);
  const { windowWidth } = useContext(app);

  const launchLightbox = ({ startingIndex }: { startingIndex: number }) => {
    setImageIndex(startingIndex);
  };

  const imagesArray: (Image | string)[] = [...images];

  return (
    <Layout position="relative" borderRadius={12}>
      <Layout position="relative">
        {isModalVisible && (
          <MediaModal
            name={backLabel}
            images={images}
            imageIndex={imageIndex}
            floorPlans={floorPlans}
            matterportId={matterportId}
            tourVideoKey={tourVideoKey}
            onClose={() => {
              setIsModalVisible(false);
            }}
            setImageIndex={setImageIndex}
            isOffsite={isOffsite}
            workspace={workspace}
          />
        )}
        <SwiperCarousel config={CONFIG} virtual={false} loop={true}>
          {imagesArray.map((image, index) => {
            return (
              <SwiperSlide key={index} className="swiper-no-swiping">
                <Clickable
                  onClick={() => {
                    launchLightbox({ startingIndex: index });
                    setIsModalVisible(true);
                  }}
                  name={`Image ${index + 1}`}
                  value={generateImgSrc({ srcKey: (image as Image).key })}
                >
                  <WorkspaceImg
                    size="lg"
                    srcKey={(image as Image).key}
                    alt={`workspace ${index + 1}`}
                    style={{ height: 180, width: windowWidth, objectFit: 'cover' }}
                  />
                </Clickable>
              </SwiperSlide>
            );
          })}
        </SwiperCarousel>
      </Layout>
    </Layout>
  );
};

export default MobileCarousel;
