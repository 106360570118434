import { BOUNDS, Bounds, COORDS, LatLng, STATE_NAME_TO_ABBREVIATION, country3to2Alpha } from '@codiwork/codi';
import qs from 'qs';

interface SearchBounds {
  ne?: Partial<LatLng>;
  sw?: Partial<LatLng>;
}

export function parseBounds(params: qs.ParsedQs): Bounds | undefined {
  const bounds: SearchBounds = {};
  let valid = true;

  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      const paramBound = params[`${bound}_${coord}`];
      if (typeof paramBound === 'string') {
        bounds[bound] = { ...(bounds[bound] || {}), [coord]: parseFloat(paramBound) };
      } else {
        valid = false;
      }
    });
  });

  if (valid) {
    return bounds as Bounds;
  }
}

export function validBounds(bounds?: SearchBounds): boolean {
  if (!bounds || Object.keys(bounds).length !== 2) {
    return false;
  }

  let valid = true;

  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      const boundValue = bounds[bound];

      if (!boundValue) {
        valid = false;
        return;
      }

      if (boundValue[coord] === undefined) {
        valid = false;
      }
    });
  });

  return valid;
}

export function equivalentBounds(bounds1: Bounds, bounds2: Bounds): boolean {
  let equivalent = true;
  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      if (bounds1[bound][coord].toFixed(8) !== bounds2[bound][coord].toFixed(8)) {
        equivalent = false;
      }
    });
  });

  return equivalent;
}

export function roundBounds(bounds?: Bounds): Bounds | undefined {
  if (!bounds) return bounds;

  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      bounds[bound][coord] = parseFloat(bounds[bound][coord].toFixed(8));
    });
  });

  return bounds;
}

export function parseCenter(params: qs.ParsedQs): LatLng | undefined {
  const center: Partial<LatLng> = {};
  let valid = true;

  COORDS.forEach(coord => {
    const paramBound = params[`center_${coord}`];
    if (typeof paramBound === 'string') {
      center[coord] = parseFloat(paramBound);
    } else {
      valid = false;
    }
  });

  if (valid) {
    return center as LatLng;
  }
}

export function filteredResults(
  results: Pick<google.maps.places.AutocompletePrediction, 'types' | 'terms' | 'place_id'>[]
) {
  return results
    .filter(r => r.types.some(t => ALLOWED_LOCATION_TYPES.includes(t)))
    .filter(r => {
      let { terms } = r;

      if (
        terms.length >= 4 &&
        terms[terms.length - 3].value === 'New York' &&
        terms[terms.length - 4].value === 'Manhattan'
      ) {
        terms.splice(terms.length - 3, 1);
      }
      // Transforms Manhattan, New York, NY, USA to Manhattan, NY, USA
      // if (terms.length === 4) {
      //   terms.splice(1, 1);
      // }

      let region = terms[terms.length - 2].value;
      const stateNames = Object.keys(STATE_NAME_TO_ABBREVIATION);
      if (stateNames.includes(region)) {
        region = STATE_NAME_TO_ABBREVIATION[region as keyof typeof STATE_NAME_TO_ABBREVIATION];
      }
      return region.match(ALLOWED_REGIONS_REGEX);
      // return r.terms[1]?.value.match(ALLOWED_REGIONS_REGEX);
    })
    .map(r => {
      let { terms } = r;

      if (
        terms.length >= 4 &&
        terms[terms.length - 3].value === 'New York' &&
        terms[terms.length - 4].value === 'Manhattan'
      ) {
        terms.splice(terms.length - 3, 1);
      }

      let neighborhood =
        r.types.includes('neighborhood') || r.types.includes('colloquial_area') ? r.terms[0]?.value : undefined;
      const cityTerm = !!neighborhood ? terms[terms.length - 3].value : terms[0].value;
      const city = transformCity(cityTerm);
      neighborhood = neighborhood === city ? undefined : neighborhood;
      const region = terms[terms.length - 2]?.value;
      const countryRaw = terms[terms.length - 1]?.value;
      const country = countryRaw ? country3to2Alpha(countryRaw) : 'US';

      return {
        neighborhood,
        city,
        region,
        country,
        place_id: r.place_id,
        display: `${city}, ${region}`
      };
    });
}

function transformCity(city: string) {
  const transformation = CITY_TRANSFORMATIONS.find(t => t.from === city);
  return !!transformation ? transformation.to : city;
}

export const LOCATION_TYPES = ['colloquial_area', 'locality', 'sublocality_level_1', 'neighborhood'] as const;
export const ALLOWED_LOCATION_TYPES = ['colloquial_area', 'locality', 'sublocality_level_1', 'neighborhood'];
export const CITY_TRANSFORMATIONS = [
  { from: 'New York City', to: 'New York' },
  { from: 'NYC', to: 'New York' },
  { from: 'SF', to: 'San Francisco' }
];
export const ALLOWED_REGIONS_REGEX = /^[A-Z][A-Z]$/;

export const SUGGESTED_CITIES = [
  {
    city: 'Los Angeles',
    region: 'CA',
    country: 'USA',
    place_id: 'ChIJE9on3F3HwoAR9AhGJW_fL-I',
    loc: {
      lat: 34.0201613,
      lng: -118.6919133
    }
  },
  {
    city: 'New York',
    region: 'NY',
    country: 'USA',
    place_id: 'ChIJOwg_06VPwokRYv534QaPC8g',
    loc: {
      lat: 40.6974034,
      lng: -74.1197634
    }
  },
  {
    city: 'San Francisco',
    region: 'CA',
    country: 'USA',
    place_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
    loc: {
      lat: 37.7576948,
      lng: -122.4726194
    }
  }
] as const;
