import React, { useContext } from 'react';

import appContext from 'context/app';
import { media } from 'context';

import Layout, { Props as LayoutProps } from './Layout';

export interface Props {
  src: string;
  alt: string;
  maxHeight?: string | number;
  minHeight?: string | number;
  /** page width <= pageMaxWidth: image hangs
   *  page width > pageMaxWidth: image snaps to content bounds
   */
  minWidth?: string | number;
  pageMaxWidth?: number;
  side: 'left' | 'right';
  containerProps?: LayoutProps;
  hangingContainerProps?: LayoutProps;
}

const HangingImage: React.FC<Props> = ({
  src,
  alt,
  maxHeight,
  minHeight,
  minWidth,
  pageMaxWidth,
  side,
  containerProps = {},
  hangingContainerProps = {}
}) => {
  const app = useContext(appContext);
  const { xxs, xs } = useContext(media);
  const leftCoefficient = xxs ? -0.15 : xs ? -0.12 : -0.1;

  return !pageMaxWidth || app.width <= pageMaxWidth ? (
    <Layout
      left={side === 'left' ? leftCoefficient * app.width : app.width * 0.5 - app.contentPaddingX}
      position="relative"
      {...hangingContainerProps}
    >
      <Layout __style={{ width: 0.6 * app.width }}>
        <img src={src} className="HangingImage-img" alt={alt} style={{ maxHeight, minHeight, minWidth }} />
      </Layout>
    </Layout>
  ) : (
    <Layout {...containerProps}>
      <img src={src} className="HangingImage-img" alt={alt} style={{ maxHeight, minHeight }} />
    </Layout>
  );
};

export default HangingImage;
