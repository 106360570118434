import React from 'react';

import { track } from 'lib/analytics';

import Icon from './Icon';
import Layout from './Layout';

interface Props {
  direction: 'left' | 'right';
  onClick?: VoidFunction;
}

const CarouselArrow: React.FC<Props> = ({ onClick, direction }) => {
  const iconName = direction === 'left' ? 'leftChevron' : 'rightChevron';

  return (
    <button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick();
        track('Element Interacted', { type: 'button', name: `carousel arrow ${direction}` });
      }}
    >
      <Layout
        borderRadius="circular"
        boxShadow="2px 0px 22px 0px #57496D1F"
        align="center"
        justify="center"
        __className="CarouselArrow"
        display="inline-flex"
        color="white"
        padding={4}
      >
        <Icon name={iconName} size="sm" color="gray-900" />
      </Layout>
    </button>
  );
};

export default CarouselArrow;
