import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Link, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const FlexibleSpaceCompany: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Text size="h3" color="blue-600">
          Engaging a Flexible Space Company
        </Text>
        <Layout marginTop={48} flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <Text size="h6" bold={false} color="gray-700">
              With Codi and most other flexible space providers, the process begins in much the same way as in the
              traditional model. You’ll perform a{' '}
              <Link semibold={false} href={OFFICE_REQUEST_PATH} underline size="h6">
                search
              </Link>{' '}
              for properties in your area and note the ones that seem to fit your needs.
            </Text>
            <Layout marginY={36} color="blue-50" paddingY={24} paddingX={36} borderRadius={8}>
              <Text size="h6" color="gray-700" bold={false}>
                Even if you don’t see the perfect office listed in the marketplace, it’s always worth submitting a
                request. With Codi specifically, property listings are not added to the online portal until negotiations
                are completed — so there may be properties that will be added soon that will meet your needs.
              </Text>
            </Layout>
          </Layout>
          <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 24 : undefined} equalWidth>
            <Text size="h6" color="gray-700" bold={false}>
              You’ll choose the best option(s) for your company and send a request for more information.
            </Text>
            <br />
            <Text size="h6" color="gray-700" bold={false}>
              A representative will get in touch with you to gather additional information, including:
            </Text>
            <br />
            <ul>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  How many people you’ll have in the space
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  Your projected move-in date
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  Full week access, or only on select days
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  Internet speeds needed
                </Text>
              </li>
              <li>
                <Text size="h6" color="gray-700" bold={false}>
                  Building security requirements
                </Text>
              </li>
            </ul>
            <br />
            <Text size="h6" color="gray-700" bold={false}>
              Your rep will then come back with options and offer tours.
            </Text>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default FlexibleSpaceCompany;
