export const APP_PAGE_PADDING_TOP = 16;
export const APP_PAGE_PADDING_X = 40;
export const APP_PAGE_PADDING_X_MOBILE = 24;
export const APP_PAGE_NAV_BAR_HEIGHT = 84;
export const APP_PAGE_TITLE_HEIGHT_MOBILE = 68;
export const TABLE_TITLE_HEIGHT_MOBILE = 68;
export const TABLE_FILTER_SORT_HEIGHT_MOBILE = 76;
export const APP_MOBILE_BACK_LINK_HEIGHT = 60;
export const APP_CONTENT_GUTTER_TOP = 40;
export const APP_CONTENT_GUTTER_TOP_MOBILE = 24;
export const APP_CONTENT_GUTTER_BOTTOM = 64;
export const BACK_LINK_AND_TITLE_HEIGHT = APP_MOBILE_BACK_LINK_HEIGHT + APP_PAGE_TITLE_HEIGHT_MOBILE;
