import React, { PropsWithChildren, useContext } from 'react';

import { app } from 'context';

import Layout from './Layout';

interface Props {
  minHeight?: string | number;
}

const ContentContainer: React.FC<PropsWithChildren<Props>> = ({ children, minHeight }) => {
  const { maxWidth, contentPaddingX, overflowY, navBarHeight } = useContext(app);

  return (
    <Layout
      justify="center"
      overflowY={overflowY}
      minHeight={minHeight === undefined ? `calc(100vh - ${navBarHeight}px` : minHeight}
    >
      <Layout __style={{ maxWidth }} paddingX={contentPaddingX} width="100%">
        {children}
      </Layout>
    </Layout>
  );
};

export default ContentContainer;
