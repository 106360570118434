import React, { useEffect, useState } from 'react';
import { Validator, validatePresence } from '@codiwork/codi';
import pluralize from 'pluralize';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

import Input from './Input';
import { BaseProps, InputImageIconProps, InputProps, OnChangeParams, ValidateProp } from './types';
import useValidate from './useValidate';
import { ReactInputProps } from '../types';

export type Props = BaseProps &
  InputProps &
  InputImageIconProps &
  ValidateProp &
  Pick<ReactInputProps, 'onKeyDown'> & {
    validate?: Validator;
    maxLength?: number;
    showMaxLength?: boolean;
    suppressValidation?: boolean;
    width?: number;
    textSize?: 'body1' | 'body2' | 'body3';
  };

const TextInput: React.FC<Props> = ({
  onChange,
  onValidate,
  onBlur,
  maxLength,
  required = false,
  error,
  label,
  value,
  showMaxLength = true,
  onClear,
  suppressValidation,
  width,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const enforceMaxLength = typeof maxLength === 'number';

  useEffect(() => {
    const validationResult = validate(value || '');
    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onValidate && onValidate(validationResult);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeWrapper = (e: OnChangeParams) => {
    const value = handleValue(e.value);
    onChange({ event: e.event, value });

    const validationResult = validate(value);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onValidate && onValidate(validationResult);
  };

  const handleValue = (value: string) => {
    return enforceMaxLength ? (value = value.substr(0, maxLength)) : value;
  };

  const validate = (text?: string) => {
    return props.validate
      ? props.validate({ fieldName: label, value: text, required })
      : validatePresence({ fieldName: label, value: text, required });
  };

  useValidate({ value: value || undefined, onValidate, validate, setErrorMessage });

  const charactersRemaining = enforceMaxLength ? maxLength! - (value ? value.length : 0) : undefined;

  return (
    <Layout width={width}>
      <Input
        {...props}
        onBlur={e => {
          const value = handleValue(e.value);
          if (!suppressValidation) {
            setErrorMessage(validate(value).error);
          }

          onBlur && onBlur({ ...e, value });
        }}
        value={value || ''}
        type="text"
        onChange={onChangeWrapper}
        error={error || errorMessage}
        required={required}
        label={label}
        onClear={onClear}
      />
      {enforceMaxLength && showMaxLength && (
        <Layout marginTop={8}>
          <Text color="gray-600" size="body2" scale>
            {pluralize('character', charactersRemaining, true)} remaining
          </Text>
        </Layout>
      )}
    </Layout>
  );
};

export default TextInput;
