import { ButtonHTMLAttributes, CSSProperties, DetailedHTMLProps, MouseEvent, forwardRef } from 'react';
import { Color } from '@codiwork/codi';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import { extractText } from './utils';

export interface Props
  extends Pick<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onMouseLeave'> {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  context?: string;
  name?: string;
  /** Use in analytics track call */
  value?: string;
  style?: CSSProperties;
  children: React.ReactNode;
  hoverColor?: Color;
  activeColor?: Color;
  color?: Color;
  fullWidth?: boolean;
  fullHeight?: boolean;
  disabled?: boolean;
  className?: string;
}

const Clickable = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick,
      children,
      context,
      name,
      value,
      hoverColor,
      activeColor,
      color,
      fullWidth,
      fullHeight,
      disabled,
      className,
      onMouseLeave,
      ...props
    },
    ref
  ) => {
    let style = { ...props.style };

    if (fullWidth) {
      style = { ...style, display: 'block', width: '100%' };
    }
    if (fullHeight) {
      style = { ...style, height: '100%' };
    }

    return (
      <button
        onClick={e => {
          onClick(e);
          track('Element Interacted', {
            context,
            name: name || extractText(children as JSX.Element),
            value,
            type: 'clickable'
          });
        }}
        style={style}
        ref={ref}
        className={classNames(
          `active-bg-color-${activeColor}`,
          {
            [`hover-bg-color-${hoverColor}`]: !!hoverColor,
            [`bg-color-${color}`]: !!color
          },
          className
        )}
        disabled={disabled}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </button>
    );
  }
);

export default Clickable;
