import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, Layout } from 'ds';
import { selectMarkets } from 'store/App/selectors';
import { actions } from 'store/OfficeRequest';
import { selectHeadCount, selectOfficeRequestMarket } from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import OfficeRequestBottomSheet from './OfficeRequestBottomSheet';
import OfficeRequestNumberStepper from './OfficeRequestNumberStepper';
import StepContainer from './StepContainer';
import StepDescription from './StepDescription';
import StepTitle from './StepTitle';
import { generateStepPath } from './utils';

interface Props {}

const Step2: React.FC<Props> = () => {
  const markets = useAppSelector(selectMarkets);
  const market = useAppSelector(selectOfficeRequestMarket);
  const hybridAvailable = !market || markets.find(m => m.name === market)?.hybrid_available;
  const headCount = useAppSelector(selectHeadCount);
  const dispatch = useAppDispatch();
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;
  const { isSmallPhone } = useContext(app);

  const NumberStepper = (
    <OfficeRequestNumberStepper
      onChange={value => {
        dispatch(actions.setHeadCount(value));
      }}
      value={headCount}
      label="people"
      max={10000000}
    />
  );

  return (
    <StepContainer
      step={2}
      nextHref={generateStepPath({ step: !!hybridAvailable ? 3 : 4 })}
      backHref={generateStepPath({ step: !!market ? 1 : 0 })}
      ctaDisabled={!headCount}
      stepCompletedEventValue={headCount}
      stepName="head count"
      {...(isSmallPhone ? { textLayoutProps: { marginTop: 32 } } : {})}
    >
      <Layout>
        <StepTitle>How many people will use the office each day?</StepTitle>
        <Layout marginTop={24}>
          <StepDescription>Your office will be completely private to your team.</StepDescription>
        </Layout>
      </Layout>
      {isMobile ? (
        <OfficeRequestBottomSheet>
          <Layout justify="center" paddingBottom={isSmallPhone ? 36 : 48}>
            {NumberStepper}
          </Layout>
        </OfficeRequestBottomSheet>
      ) : (
        <Animate>{NumberStepper}</Animate>
      )}
    </StepContainer>
  );
};

export default Step2;
