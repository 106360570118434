import React from 'react';

import { Layout, Text, TextButton } from 'ds';
import { actions } from 'store/OfficeRequest';
import { selectOfficeRequestMarket } from 'store/OfficeRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const ChangeMarket: React.FC = () => {
  const dispatch = useAppDispatch();
  const market = useAppSelector(selectOfficeRequestMarket);

  if (!market) return null;

  return (
    <Layout align="center">
      <Text size="body-sm" color="gray-600">
        You’re searching within {market}.&nbsp;
        <TextButton
          text="Start over"
          textSize="body-sm"
          color="blue-500"
          semibold={false}
          onClick={() => {
            dispatch(actions.clearLocations());
            dispatch(actions.setMarket(null));
          }}
        />
      </Text>
    </Layout>
  );
};

export default ChangeMarket;
