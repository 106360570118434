import React from 'react';
import { EditableImage } from '@codiwork/codi';

import IconButton from 'ds/IconButton';
import Img from 'ds/Img';
import Layout from 'ds/Layout';

interface Props {
  onDeleteClick?: VoidFunction;
  image: EditableImage;
  disabled?: boolean;
  dimension?: number;
}

const TaskImageUploadThumbnail: React.FC<Props> = ({ onDeleteClick, image, disabled, dimension }) => {
  return (
    <Layout position="relative" height={dimension} width={dimension}>
      {image.key ? (
        <Img
          srcKey={image.key}
          alt="task image"
          width={320}
          style={{
            objectFit: 'cover',
            borderRadius: 8,
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        <img
          src={image.url}
          alt="request"
          style={{
            objectFit: 'cover',
            borderRadius: 8,
            width: '100%',
            height: '100%'
          }}
        />
      )}
      {!!onDeleteClick && (
        <Layout position="absolute" right={10} top={10}>
          <IconButton size="xs" type="gray" name="close" stroke={2} disabled={disabled} onClick={onDeleteClick} />
        </Layout>
      )}
    </Layout>
  );
};

export default TaskImageUploadThumbnail;
