import { dateInRange } from '@codiwork/codi';

import { Column, FilterState, Row, SortState } from './types';

export function defaultAccessor<T extends Row>(row: T, key: keyof T | string): string {
  const value = row[key as keyof T];

  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (value === null) {
    return '';
  } else {
    try {
      return ((value as unknown) as any).toString();
    } catch {
      return '';
    }
  }
}

export function filterRows<T extends Row>({
  filterState,
  rows,
  columns
}: {
  filterState: FilterState[];
  rows: T[];
  columns: Column<T>[];
}) {
  if (filterState.length === 0) {
    return rows;
  }

  const filters = filterState.map(filter => ({ ...filter, substring: filter.substring?.toLowerCase() }));

  return rows.filter(row => {
    return filters.every(({ key, substring, oneOf, startDate, endDate, minValue, maxValue }) => {
      const rawValue = row[key as keyof T];
      const column = columns.find(c => c.key === key);

      const value = column?.filterValue
        ? column?.filterValue(row)
        : typeof rawValue === 'number'
        ? rawValue.toString()
        : rawValue;

      const fn = column?.filters?.textSearch?.fn;

      if (fn && substring) {
        return fn(row, substring);
      }

      if (substring) {
        if (typeof value !== 'string') return false;

        return value.toLowerCase().includes(substring);
      } else if (oneOf) {
        // @ts-ignore
        return oneOf.includes(value);
      } else if (startDate || endDate) {
        if (typeof value !== 'string') return false;

        return dateInRange(new Date(value), startDate, endDate);
      } else if (minValue !== undefined || maxValue !== undefined) {
        if (minValue !== undefined && maxValue !== undefined) {
          return Number(value) >= Number(minValue) && Number(value) <= Number(maxValue);
        } else {
          return minValue !== undefined
            ? Number(value) >= Number(minValue)
            : true && maxValue !== undefined
            ? Number(value) <= Number(maxValue)
            : true;
        }
      }

      return false;
    });
  });
}

export function sortRows<T extends Row>({
  sortState,
  rows,
  columns
}: {
  sortState: SortState[];
  rows: T[];
  columns: Column<T>[];
}) {
  if (sortState.length === 0) {
    return rows;
  }

  return [...rows].sort((row1, row2) => {
    let sortResult = 0;

    for (let index = 0; index < sortState.length; index++) {
      const { key, direction } = sortState[index];
      const sortBy = columns.find(c => c.key === key)?.sortBy;
      if (!sortBy) {
        continue;
      }

      sortResult = sortBy(row1, row2) * (direction === 'desc' ? -1 : 1);
      if (sortResult !== 0) {
        break;
      }
    }

    return sortResult;
  });
}
