import React, { PropsWithChildren, useContext, useRef, useState } from 'react';
import { Color } from '@codiwork/codi';
import { useWindowScroll } from 'beautiful-react-hooks';

import { app } from 'context';
import Layout, { Props as LayoutProps } from 'ds/Layout';
import { actions } from 'store/UI';
import { useAppDispatch } from 'store/hooks';

interface Props extends Omit<LayoutProps, 'onScroll'> {
  top?: number;
  zIndex: number;
  shadow?: boolean;
  boxShadow?: string;
  background?: string;
  backgroundColor?: Color | 'transparent';
  isScreenWidth?: boolean;
  /** Used when there is a sticky header below the public header */
  removePublicHeaderShadow?: boolean;
  onScroll?: (scrollY: number) => void;
}

const StickyHeader: React.FC<PropsWithChildren<Props>> = ({
  top = 0,
  zIndex,
  children,
  shadow = true,
  boxShadow,
  background,
  backgroundColor = 'white',
  removePublicHeaderShadow = false,
  isScreenWidth,
  onScroll,
  __style,
  ...props
}) => {
  const [hasShadow, setHasShadow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { windowWidth, maxContentWidth, contentPaddingX } = useContext(app);
  const difference =
    maxContentWidth && windowWidth > maxContentWidth + contentPaddingX * 2 ? windowWidth - maxContentWidth : undefined;
  const right = difference && difference > 0 ? difference / 2 : contentPaddingX;
  const onWindowScroll = useWindowScroll();
  const handleScroll = () => {
    const element = ref.current;

    if (!element) return;

    const { top: elementTop } = element.getBoundingClientRect();
    const hasShadow = elementTop <= top;

    if (removePublicHeaderShadow) {
      dispatch(actions.setPublicHeaderHasShadow(!hasShadow));
    }

    onScroll && onScroll(window.scrollY);

    setHasShadow(element.offsetTop > 0);
  };

  onWindowScroll(handleScroll);

  return (
    <Layout
      top={top}
      zIndex={zIndex}
      {...(background
        ? { background }
        : backgroundColor === 'transparent'
        ? { background: 'transparent' }
        : { color: backgroundColor })}
      position="sticky"
      {...(hasShadow && shadow ? { boxShadow: boxShadow || 'rgba(87, 73, 109, 0.12) 2px 0 22px 0' } : {})}
      __ref={ref}
      {...(isScreenWidth ? { width: windowWidth, __style: { ...__style, transform: `translateX(-${right}px)` } } : {})}
      {...props}
    >
      {children}
    </Layout>
  );
};

export default StickyHeader;
