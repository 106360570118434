import React from 'react';

import { DEFAULT_META_DESCRIPTION } from 'helpers/constants';

import ErrorPage from 'components/ErrorPage';
import Head from 'components/Head';

const FiveHundred: React.FC = () => {
  return (
    <>
      <Head title="500 - Error" description={DEFAULT_META_DESCRIPTION} />
      <ErrorPage />
    </>
  );
};

export default FiveHundred;
