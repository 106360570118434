import React from 'react';
import { ReactComponent as Svg } from '@codiwork/codi/lib/icons/Image.svg';

import { Layout, Text } from 'ds';
import ProductImg from 'ds/ProductImg';
import { formatUsd } from 'helpers';

interface Props {
  name?: string;
  description?: string | null;
  displayPrice?: number;
  imageKey?: string;
  imageUrl?: string;
  width?: string | number;
}

const CatalogCard: React.FC<Props> = ({ imageKey, imageUrl, name, description, displayPrice, width }) => {
  return (
    <Layout borderRadius={12} width={width}>
      <Layout align="center" justify="center" height={200}>
        {imageKey ? (
          <ProductImg
            srcKey={imageKey}
            alt="product"
            size="lg"
            style={{
              width,
              height: 200,
              objectFit: 'contain'
            }}
          />
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt="product"
            style={{ width, height: 200, objectFit: 'contain', borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
          />
        ) : (
          <Layout height={200} align="center" justify="center" color="gray-25" flexGrow={1}>
            <Layout>
              <span className="Icon is-color-gray-100" style={{ width: 64, height: 64, display: 'inline-flex' }}>
                <Svg />
              </span>
              <Layout marginTop={8}>
                <Text size="body2" color="gray-300" align="center" scale>
                  300 x 300
                  <br />
                  Min Size
                </Text>
              </Layout>
            </Layout>
          </Layout>
        )}
      </Layout>
      <Layout paddingY={24}>
        {name && (
          <Text size={'body1'} color={name ? 'black' : 'gray-300'} maxLines={2} bold scale>
            {name}
          </Text>
        )}
        {!!description && (
          <Layout marginTop={8}>
            <Text size="body2" color={description ? 'black' : 'gray-300'} maxLines={2} scale>
              {description || 'Dimensions'}
            </Text>
          </Layout>
        )}
        {displayPrice !== undefined && (
          <Layout marginTop={8}>
            <Text size="body2" color={displayPrice ? 'black' : 'gray-300'} scale>
              {formatUsd(displayPrice)}/mo
            </Text>
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default CatalogCard;
