import React, { useContext } from 'react';

import { media } from 'context';
import { EmailInput, Layout, Text, TextInput } from 'ds';
import { actions } from 'store/STBRequest';
import { selectStbRequest } from 'store/STBRequest/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import STBSubmitRequest from './STBSubmitRequest';

interface Props {}

const STBRequestForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const stbRequest = useAppSelector(selectStbRequest);

  const { first_name, last_name, email } = stbRequest;

  const { xs } = useContext(media);

  return (
    <>
      <Text size="h5">Tell us how to contact you.</Text>
      <Layout marginTop={24}>
        <TextInput
          value={first_name}
          label="First Name"
          size={xs ? 'sm' : 'md'}
          onChange={({ value }) => {
            dispatch(actions.updateStbRequest({ first_name: value }));
          }}
        />
      </Layout>
      <Layout marginTop={12}>
        <TextInput
          value={last_name}
          label="Last Name"
          size={xs ? 'sm' : 'md'}
          onChange={({ value }) => {
            dispatch(actions.updateStbRequest({ last_name: value }));
          }}
        />
      </Layout>
      <Layout marginTop={12}>
        <EmailInput
          value={email}
          label="Work Email"
          size={xs ? 'sm' : 'md'}
          onChange={({ value }) => {
            dispatch(actions.updateStbRequest({ email: value }));
          }}
        />
      </Layout>
      {!xs && (
        <Layout marginTop={24}>
          <STBSubmitRequest />
        </Layout>
      )}
    </>
  );
};

export default STBRequestForm;
