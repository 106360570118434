import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ContentContainer } from 'ds';
import { selectGoogleMapsLoaded } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

import Page from './Page';
import { Route } from './types';

interface Props
  extends RouteComponentProps,
    Pick<
      Route,
      | 'requiredRole'
      | 'customLayout'
      | 'redirect'
      | 'hideHeader'
      | 'hideFooter'
      | 'hasFooterMargin'
      | 'hasHeaderCta'
      | 'scrollToTop'
      | 'waitForUserLoad'
      | 'waitForAnalyticsLoad'
      | 'waitForGoogleMapsLoad'
    > {
  children: JSX.Element;
  isReady: boolean;
  analyticsLoaded: boolean;
}

const PublicRoute: React.FC<Props> = ({
  children,
  requiredRole,
  customLayout,
  scrollToTop,
  hideHeader,
  hideFooter,
  hasFooterMargin = true,
  hasHeaderCta = true,
  analyticsLoaded,
  waitForAnalyticsLoad = true,
  waitForUserLoad = false,
  waitForGoogleMapsLoad = true,
  isReady
}) => {
  const googleMapsLoaded = useAppSelector(selectGoogleMapsLoaded);

  if (waitForUserLoad && !isReady) return null;

  if (waitForGoogleMapsLoad && !googleMapsLoaded) return null;

  return (
    <>
      {(analyticsLoaded || !waitForAnalyticsLoad) && (
        <Page
          hideHeader={hideHeader}
          hideFooter={hideFooter}
          hasFooterMargin={hasFooterMargin}
          hasHeaderCta={hasHeaderCta}
          scrollToTop={scrollToTop}
          customLayout={!!customLayout}
          requiredRole={requiredRole}
        >
          {customLayout ? children : <ContentContainer>{children}</ContentContainer>}
        </Page>
      )}
    </>
  );
};

export default PublicRoute;
