import { Bounds, OfficeLead, PartialPick, TourDestinationReviewResponse, transformBounds } from '@codiwork/codi';
import axios from '@codiwork/codi/lib/lib/axios';

import { ProspectSearchWorkspace } from './types';

export function inviteCoworker({
  email,
  officeLeadId,
  userId,
  message
}: {
  email: string;
  officeLeadId: number;
  userId: number;
  message?: string;
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/invite`, { email, message });
}

export function createTourDestinationRequest({
  officeLeadId,
  userId,
  workspaceId,
  tourId
}: {
  officeLeadId: number;
  userId: number;
  workspaceId: number;
  tourId: number;
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/tour_destination_requests`, {
    workspace_id: workspaceId,
    tour_id: tourId
  });
}

export function reviewTourDestinations({
  officeLeadId,
  userId,
  tourId,
  responses
}: {
  officeLeadId: number;
  userId: number;
  tourId: number;
  responses: { workspaceId: number; value: TourDestinationReviewResponse }[];
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/tour_destination_reviews`, {
    tour_id: tourId,
    responses: responses.map(r => ({ ...r, workspace_id: r.workspaceId }))
  });
}

export function destroyTourDestination({
  officeLeadId,
  userId,
  id
}: {
  officeLeadId: number;
  userId: number;
  id: number;
}) {
  return axios.delete<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/tour_destinations/${id}`);
}

export function destroyTourDestinationRequest({
  officeLeadId,
  userId,
  id
}: {
  officeLeadId: number;
  userId: number;
  id: number;
}) {
  return axios.delete<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/tour_destination_requests/${id}`);
}

export interface UpdatableOfficeLead
  extends PartialPick<
    OfficeLead,
    'min_monthly_budget' | 'max_monthly_budget' | 'num_meeting_rooms' | 'head_count' | 'locations'
  > {
  id: number;
}
export function updateOfficeLead({
  officeLead: { id, min_monthly_budget, max_monthly_budget, num_meeting_rooms, head_count, locations },
  userId
}: {
  officeLead: UpdatableOfficeLead;
  userId: number;
}) {
  return axios.patch<OfficeLead>(`/v1/users/${userId}/office_leads/${id}`, {
    min_monthly_budget,
    max_monthly_budget,
    num_meeting_rooms,
    head_count,
    locations
  });
}

export type ProspectOfficeInteractionSource = 'search' | 'list' | 'listing';

export function addOfficeLeadWorkspace({
  officeLeadId,
  workspaceId,
  userId,
  source
}: {
  officeLeadId: number;
  workspaceId: number;
  userId: number;
  source: ProspectOfficeInteractionSource;
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_lead_workspaces/add`, {
    office_lead_id: officeLeadId,
    workspace_id: workspaceId,
    source
  });
}

export function removeOfficeLeadWorkspace({
  officeLeadWorkspaceId,
  userId,
  source
}: {
  officeLeadWorkspaceId: number;
  userId: number;
  source: ProspectOfficeInteractionSource;
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_lead_workspaces/remove/${officeLeadWorkspaceId}`, {
    source
  });
}

export function rejectOfficeLeadWorkspace({
  officeLeadWorkspaceId,
  userId,
  message
}: {
  officeLeadWorkspaceId: number;
  userId: number;
  message?: string;
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_lead_workspaces/reject/${officeLeadWorkspaceId}`, {
    message
  });
}

export function unrejectOfficeLeadWorkspace({
  officeLeadWorkspaceId,
  userId
}: {
  officeLeadWorkspaceId: number;
  userId: number;
}) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_lead_workspaces/unreject/${officeLeadWorkspaceId}`);
}

export function searchProspectWorkspaces({ bounds }: { bounds: Bounds }) {
  const params = {
    ...(bounds ? transformBounds(bounds) : {})
  };

  return axios.get<ProspectSearchWorkspace[]>('/v1/search', { params });
}

export function leaveOfficeLead({ officeLeadId, userId }: { officeLeadId: number; userId: number }) {
  return axios.delete<OfficeLead | { destroyed: true }>(`/v1/users/${userId}/office_leads/${officeLeadId}`);
}
