import { Animate, ButterImg, Button, FullWidthBackground, Icon, Layout, Link, Text } from 'ds';
import { Blob as BlobType, CTA, ImageXs, SectionImage } from './CmsPage/types';
import React, { useContext } from 'react';
import { app, media } from 'context';

import Blob from 'ux/Public/CmsPage/Blob';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {
  title: string;
  subtitle: string;
  cta: CTA;
  images: SectionImage[];
  image_xs?: ImageXs;
  blob?: BlobType;
  blob_xs?: BlobType;
  padding_y?: number;
  padding_bottom_xs?: number;
  padding_top_xs?: number;
  reverse: boolean;
  full_width_background: boolean;
}

const ImageText: React.FC<Props> = props => {
  const mediaContext = useContext(media);
  const { xs, sm } = mediaContext;
  const { contentPaddingX } = useContext(app);

  const { images, image_xs, full_width_background } = props;

  const image = images[0];

  if (sm && image_xs?.src) {
    return (
      <Layout paddingTop={80} flex>
        <Animate equalWidth>
          <ButterImg width={500} compression={false} src={image_xs.src} alt={image_xs.alt || ''} />
        </Animate>
        <Layout paddingLeft={48} equalWidth>
          <ContentXs {...props} />
        </Layout>
      </Layout>
    );
  }

  return xs || sm ? (
    <Layout>
      {full_width_background ? (
        <FullWidthBackground color="blue-50" paddingY={112}>
          <ContentXs {...props} />
        </FullWidthBackground>
      ) : (
        <ContentXs {...props} />
      )}
      {image_xs?.src ? (
        <Animate justify="center">
          <ButterImg
            compression={false}
            width={500}
            src={image_xs.src}
            alt={image_xs?.alt || ''}
            style={{ maxWidth: 400 }}
          />
        </Animate>
      ) : (
        <Animate>
          <ButterImg
            width={xs ? 400 : 800}
            src={image.src}
            alt={image.alt}
            style={{
              height: sm ? 320 : 230,
              objectFit: 'cover',
              position: 'relative',
              width: `calc(100% + ${contentPaddingX * 2}px)`,
              left: -contentPaddingX
            }}
          />
        </Animate>
      )}
    </Layout>
  ) : full_width_background ? (
    <FullWidthBackground color="blue-50" paddingY={72}>
      <Content {...props} />
    </FullWidthBackground>
  ) : (
    <Content {...props} />
  );
};

const Content: React.FC<Props> = ({
  title,
  subtitle,
  images,
  blob,
  padding_y,
  cta,
  reverse,
  full_width_background
}) => {
  const { md, lg } = useContext(media);
  const { maxWidth, width } = useContext(app);

  const image = images[0];
  const widthToScale = maxWidth ? Math.min(maxWidth, width) : width;

  const scale1440 = widthToScale / 1440;

  return (
    <Layout
      align="center"
      paddingY={padding_y !== undefined ? scale1440 * padding_y : undefined}
      marginTop={padding_y !== undefined || full_width_background ? undefined : 72}
      direction={reverse ? 'row-reverse' : 'row'}
    >
      <Layout position="relative" equalWidth>
        {images.length > 1 ? (
          <Layout direction="column" flexGrow={1} flex>
            {images.map((image, index) => {
              return (
                <Animate key={image.src}>
                  <Layout
                    justify={
                      reverse ? (index === 0 ? 'flex-end' : 'flex-start') : index === 0 ? 'flex-start' : 'flex-end'
                    }
                    marginBottom={index === 0 ? 16 : undefined}
                  >
                    <ButterImg
                      width={600}
                      src={image.src}
                      alt={image.alt}
                      style={{
                        borderRadius: 44,
                        boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
                        maxWidth: lg ? 396 : md ? 372 : 320
                      }}
                    />
                  </Layout>
                </Animate>
              );
            })}
          </Layout>
        ) : (
          <Animate
            keyframes={[
              { opacity: 0, transform: 'translate(-28px, 28px)' },
              { opacity: 1, transform: 'translate(0, 0)' }
            ]}
          >
            <ButterImg
              width={1200}
              compression={false}
              src={image.src}
              alt={image.alt}
              style={{ width: image.width ? scale1440 * image.width : undefined }}
            />
          </Animate>
        )}
        {blob && <Blob {...blob} scale={scale1440} />}
      </Layout>
      <Layout {...(reverse ? { justify: 'flex-end' } : undefined)} equalWidth>
        <Animate>
          <Layout maxWidth={412} {...(reverse ? { marginRight: 148 } : { marginLeft: 148 })}>
            <Layout marginBottom={32}>
              <Text size="h4" color="blue-700">
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
              </Text>
            </Layout>
            <Layout marginBottom={32}>
              <Text size="body1" scale>
                <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
              </Text>
            </Layout>
            {cta.href ? (
              <Link href={cta.href}>
                <Layout inline align="center">
                  {cta.text}
                  <Layout marginLeft={8} display="inline-flex">
                    <Icon name="rightArrow" stroke={2} size="sm" color="blue-500" />
                  </Layout>
                </Layout>
              </Link>
            ) : (
              <Button text={cta.text} type={cta.type} size={cta.size} href={OFFICE_REQUEST_PATH} />
            )}
          </Layout>
        </Animate>
      </Layout>
    </Layout>
  );
};

const ContentXs: React.FC<Props> = ({
  title,
  subtitle,
  cta,
  full_width_background,
  padding_bottom_xs = 112,
  padding_top_xs = 112
}) => {
  return (
    <Animate
      paddingBottom={full_width_background ? undefined : padding_bottom_xs}
      paddingTop={full_width_background ? undefined : padding_top_xs}
    >
      <Layout marginBottom={24}>
        <Text size="h4" color="blue-700">
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </Text>
      </Layout>
      <Layout marginBottom={24}>
        <Text size="body1" color="gray-600">
          <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
        </Text>
      </Layout>
      {cta.href ? (
        <Link href={cta.href}>
          <Layout inline align="center">
            <Text size="body1" inline semibold>
              {cta.text}
            </Text>
            <Layout inline marginLeft={8} display="inline-flex">
              <Icon name="rightArrow" stroke={2} size="sm" color="blue-500" />
            </Layout>
          </Layout>
        </Link>
      ) : (
        <Button text={cta.text} type={cta.type} size="md" href={OFFICE_REQUEST_PATH} />
      )}
    </Animate>
  );
};

export default ImageText;
