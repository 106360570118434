import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Img, Layout, Link, Text } from 'ds';
import { SEARCH_PATH } from 'routes';

interface Props {
  location: string;
  srcKey: string;
  search: string;
}

const BrowseLocation: React.FC<Props> = ({ srcKey, location, search }) => {
  const { isMobile, md } = useContext(media);

  return (
    <Link
      href={SEARCH_PATH}
      search={`?location=${search}`}
      opacity={false}
      color="white"
      {...(isMobile || md ? { fullWidth: true } : {})}
    >
      <Layout
        __className="Home-browseLocation"
        boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
        borderRadius={16}
        position="relative"
      >
        <Img
          srcKey={srcKey}
          alt={location}
          width={isMobile ? 600 : 1000}
          folder="public"
          style={{
            borderRadius: 16,
            height: isMobile ? 212 : md ? 300 : 440,
            objectFit: 'cover'
          }}
        />
        <Layout position="absolute" left={36} bottom={36}>
          <Layout align="center">
            <Text size="overline-lg" color="white" semibold caps>
              {location}
            </Text>
            <Layout marginLeft={8} display="inline-flex">
              <Icon name="rightArrow" size="xl" color="white" />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Link>
  );
};

export default BrowseLocation;
