import React from 'react';
import { ListingWorkspace, determineIsNew, formatPublicAddress } from '@codiwork/codi';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import { Layout, Link, NewTag, Text, WorkspaceImg } from 'ds';
import { LISTING_PATH } from 'routes';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

interface Props {
  workspace: ListingWorkspace;
}

const NearbyWorkspace: React.FC<Props> = ({
  workspace: { images, slug, name, first_listed_at, address, monthly_price }
}) => {
  const isNew = determineIsNew(first_listed_at);
  const image = images[0];
  const isLoggedIn = !!useAppSelector(selectUser);

  if (!image || !name) return null;

  return (
    <Link href={`${LISTING_PATH}/${slug}`} opacity={false} draggable={false} display="block">
      <Layout position="relative">
        <WorkspaceImg
          srcKey={image.key}
          alt={name}
          style={{
            borderRadius: 12,
            userSelect: 'none',
            height: 240,
            objectFit: 'cover'
          }}
          draggable={false}
          size="lg"
        />
        {isNew && (
          <Layout position="absolute" top={12} left={12} zIndex={1}>
            <NewTag />
          </Layout>
        )}
      </Layout>
      <Layout marginTop={12}>
        <Text size="body1" color="gray-600" ellipsis scale>
          {formatPublicAddress(address)}
        </Text>
        <Text size="body1" color="gray-600" ellipsis scale>
          {name}
        </Text>
      </Layout>
      {isLoggedIn && !!monthly_price && monthly_price > 0 && (
        <Layout marginTop={12}>
          <Text size="body1" color="gray-900" bold ellipsis scale>
            {formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price })} / month
          </Text>
        </Layout>
      )}
    </Link>
  );
};

export default NearbyWorkspace;
