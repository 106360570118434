import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, Clickable, Icon, Layout, Text } from 'ds';
import { useOnClickOutside } from 'ds/helpers';
import { selectUser } from 'store/User/selectors';

import AccountMenu from './AccountMenu';

interface Props {
  isTransparentMode?: boolean;
  opacity?: number;
}

const ITEM_TEXT_SIZE = 'body2';

const NavItems: React.FC<Props> = ({ isTransparentMode, opacity }) => {
  const user = useSelector(selectUser);
  const ref = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  if (!user) return null;

  return (
    <Layout position="relative" justify="center" __ref={ref} opacity={opacity ? opacity : undefined}>
      <Clickable onClick={() => setMenuOpen(!menuOpen)}>
        <Layout
          align="center"
          borderRadius={100}
          paddingLeft={4}
          paddingY={4}
          paddingRight={10}
          hoverColor={menuOpen ? undefined : 'gray-25'}
          {...(menuOpen ? { color: 'gray-50' } : {})}
        >
          <Avatar size={32} avatarUrl={user?.avatar_url} />
          <Layout maxWidth={104} marginX={12}>
            <Text size={ITEM_TEXT_SIZE} color={isTransparentMode && !opacity ? 'white' : undefined} ellipsis>
              {user.firstname}
            </Text>
          </Layout>
          <Icon name="downCaret" size="sm" color={isTransparentMode && !opacity ? 'white' : 'black'} />
        </Layout>
      </Clickable>
      {menuOpen && <AccountMenu setMenuOpen={setMenuOpen} textSize={ITEM_TEXT_SIZE} />}
    </Layout>
  );
};

export default NavItems;
