import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import { media } from 'context';
import Clickable from 'ds/Clickable';
import Grid from 'ds/Grid';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import SwiperCarousel from 'ds/SwiperCarousel';
import { NO_SWIPING_CLASS } from 'ds/SwiperCarousel';
import Text from 'ds/Text';
import { TOUR_TIMING_OPTIONS } from 'ds/constants';
import { SwiperSlide } from 'swiper/react';

import { TourTime } from './types';
import { makeTourDateOptions } from './utils';

interface Props {
  selectedTourTime?: TourTime;
  onSelectTourTime: (tourTime: TourTime) => void;
  selectedTourDate?: DateTime;
  onSelectTourDate: (tourDate: DateTime) => void;
}

const TourTimeSelector: React.FC<Props> = ({
  selectedTourTime,
  onSelectTourTime,
  selectedTourDate,
  onSelectTourDate
}) => {
  const { xs } = useContext(media);
  const tourWeeks = makeTourDateOptions();
  const weekIndex = getWeekIndex({ tourWeeks, selectedTourDate });

  return (
    <Layout direction="column" justify="center" position="relative" paddingX={xs ? 24 : undefined}>
      <SwiperCarousel
        inputIndex={weekIndex}
        controlLayoutProps={{ right: xs ? -4 : -24, left: -30 }}
        config={{ sm: { slidesPerView: 1, spaceBetween: 0 } }}
        leftArrow={
          <Layout paddingTop={xs ? 48 : 40} justify="center" align="center" marginLeft={xs ? 0 : undefined}>
            <Icon size={xs ? 'lg' : 'sm'} name="leftChevron" stroke={1.5} color="gray-400" />
          </Layout>
        }
        rightArrow={
          <Layout paddingTop={xs ? 48 : 40} justify="center" align="center" marginRight={xs ? 0 : undefined}>
            <Icon size={xs ? 'lg' : 'sm'} name="rightChevron" stroke={1.5} color="gray-400" />
          </Layout>
        }
        showArrowsMobile
        noSwiping
      >
        {tourWeeks.map(week => (
          <SwiperSlide className={NO_SWIPING_CLASS} key={week[0].dateTime.toLocaleString()}>
            <Layout direction="row" align="center" justify="space-between" height={xs ? 48 : 40}>
              <Layout width="32" minWidth={32} justify="center" align="center" marginLeft={xs ? -8 : undefined} />
              <Layout direction="row" flexGrow={1} justify="space-evenly">
                {tourWeeks[weekIndex].map(({ dateTime }) => (
                  <Layout
                    key={`${dateTime.weekdayShort}`}
                    direction="column"
                    align="center"
                    width={xs ? 52 : 80}
                    paddingY={12}
                  >
                    <Text size="overline-md" color={xs ? 'gray-600' : 'gray-400'} align="center">
                      {dateTime.weekdayShort[0]}
                    </Text>
                  </Layout>
                ))}
              </Layout>
              <Layout width="32" minWidth={32} justify="center" align="center" marginRight={xs ? -8 : undefined} />
            </Layout>
            <Layout direction="row" justify="space-evenly" paddingX={xs ? 24 : 32}>
              {week.map(({ dateTime, disabled }) => {
                const isSelected = selectedTourDate?.equals(dateTime);

                return (
                  <Clickable
                    onClick={() => onSelectTourDate(dateTime)}
                    key={dateTime.toISO()}
                    disabled={disabled}
                    style={{ cursor: disabled ? 'not-allowed' : 'pointer', borderRadius: '50%' }}
                  >
                    <Layout
                      width={xs ? 52 : 80}
                      height={xs ? 52 : 80}
                      padding={isSelected ? 0 : 1}
                      borderWidth={isSelected ? 2 : 1}
                      borderColor={isSelected ? 'blue-500' : undefined}
                      color={isSelected ? 'blue-50' : undefined}
                      borderRadius="circular"
                      direction="column"
                      justify="center"
                      align="center"
                      hoverColor={disabled ? undefined : 'blue-50'}
                      opacity={disabled ? 0.5 : undefined}
                    >
                      <Text size={xs ? 'body-md' : 'body-lg'} color={disabled ? 'gray-700' : 'gray-900'}>
                        {dateTime.day}
                      </Text>
                      <Text size={xs ? 'tag' : 'body-xs'} color="gray-600">
                        {dateTime.monthShort.toUpperCase()}
                      </Text>
                    </Layout>
                  </Clickable>
                );
              })}
            </Layout>
          </SwiperSlide>
        ))}
      </SwiperCarousel>
      <Layout marginTop={36} />
      <Grid gap={8} itemsPerRow={xs ? 2 : 4}>
        {TOUR_TIMING_OPTIONS.map(time => {
          const isSelected = selectedTourTime?.label === time.label;

          const { label, timeDisplay } = time;
          return (
            <Clickable key={label} onClick={() => onSelectTourTime(time)}>
              <Layout
                paddingY={xs ? 8 : 12}
                borderRadius={8}
                margin={!isSelected ? 1 : undefined}
                borderWidth={isSelected ? 2 : 1}
                borderColor={isSelected ? 'blue-500' : undefined}
                direction="column"
                justify="center"
                align="center"
                hoverColor="blue-50"
                color={isSelected ? 'blue-50' : undefined}
                height={xs ? 52 : 64}
              >
                <Text size={xs ? 'body-xs' : 'body-sm'} semibold>
                  {label}
                </Text>
                <Layout marginTop={4} />
                <Text size="body-xs" color="gray-600">
                  {timeDisplay}
                </Text>
              </Layout>
            </Clickable>
          );
        })}
      </Grid>
    </Layout>
  );
};

function getWeekIndex({
  tourWeeks,
  selectedTourDate
}: {
  tourWeeks: { dateTime: DateTime; disabled: boolean }[][];
  selectedTourDate?: DateTime;
}) {
  if (!selectedTourDate) return 0;

  tourWeeks.forEach((week, index) => {
    week.forEach(day => {
      if (day.dateTime.equals(selectedTourDate)) {
        return index;
      }
    });
  });

  return 0;
}

export default TourTimeSelector;
