import React from 'react';

import { HEADER_Z_INDEX, Layout, Link, Logo, Text } from 'ds';
import { actions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

import { PUBLIC_OFFICE_REQUEST_HEADER_HEIGHT_MOBILE } from './constants';

interface Props {}

const StepMobileHeader: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <Layout
      height={PUBLIC_OFFICE_REQUEST_HEADER_HEIGHT_MOBILE}
      align="center"
      justify="space-between"
      zIndex={HEADER_Z_INDEX}
    >
      <Logo color="white" size="sm" />
      <Link onClick={() => dispatch(actions.setExitModalIsVisible(true))}>
        <Text size="body1" color="white">
          Exit
        </Text>
      </Link>
    </Layout>
  );
};

export default StepMobileHeader;
