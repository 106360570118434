import { CLEANING_TASK_TYPE, IconName, TOUCH_UP_TASK_TYPE, WeeklyCleaningDay } from '@codiwork/codi';

export const DAY_NUMBER_TO_LONG_DAY_MAP: { [key: number]: WeeklyCleaningDay } = {
  0: 'Mondays',
  1: 'Tuesdays',
  2: 'Wednesdays',
  3: 'Thursdays',
  4: 'Fridays',
  5: 'Saturdays',
  6: 'Sundays'
} as const;

export const LONG_DAY_TO_NUMBER_MAP: { [key: string]: number } = {
  Mondays: 0,
  Tuesdays: 1,
  Wednesdays: 2,
  Thursdays: 3,
  Fridays: 4,
  Saturdays: 5,
  Sundays: 6
} as const;

export const DAY_NUMBER_TO_SHORT_DAY_MAP: { [key: number]: string } = {
  0: 'Mon',
  1: 'Tues',
  2: 'Wed',
  3: 'Thur',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun'
} as const;

export type CleaningDayOption = {
  key: number;
  label: string;
  icon?: IconName;
  description?: string;
  price: number;
  value: typeof CLEANING_TASK_TYPE | typeof TOUCH_UP_TASK_TYPE | null;
  onClick: () => void;
};
