import { PartialPick, axios } from '@codiwork/codi';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import { SaveableShortTermBooking } from 'ux/Admin/ShortTermBookings/types';

import { ShortTermBooking, ShortTermBookingRequest } from './types';

export function numDays({ startDate, endDate }: { startDate: string; endDate: string }) {
  const startDateTime = DateTime.fromISO(startDate);
  const endDateTime = DateTime.fromISO(endDate);

  return endDateTime.diff(startDateTime, 'days').days + 1;
}

export function stbIsReadyForForm({ start_date, end_date, workspace_id }: Partial<SaveableShortTermBooking>) {
  return !!start_date && !!end_date && workspace_id;
}

export function isCreatableShortTermBookingRequest(
  stbRequest: Partial<ShortTermBookingRequest>
): stbRequest is CreatableShortTermBookingRequest {
  const { start_date, end_date, workspace_id, amount, first_name, last_name, email } = stbRequest;

  return !!start_date && !!end_date && !!workspace_id && !!amount && !!first_name && !!last_name && !!email;
}

export interface CreatableShortTermBookingRequest
  extends Pick<
    ShortTermBookingRequest,
    'start_date' | 'end_date' | 'workspace_id' | 'first_name' | 'last_name' | 'email'
  > {}

export function createShortTermBookingRequest(stbRequest: CreatableShortTermBookingRequest) {
  return axios.post<CreatableShortTermBookingRequest, AxiosResponse<ShortTermBooking>>(
    '/v1/short_term_booking_requests',
    stbRequest
  );
}

export function isCreatableShortTermBooking(
  stbRequest: Partial<SaveableShortTermBooking>
): stbRequest is CreatableShortTermBookingRequest {
  const { start_date, end_date, workspace_id, amount } = stbRequest;

  return !!start_date && !!end_date && !!workspace_id && !!amount;
}

export interface CreatableShortTermBooking
  extends Pick<ShortTermBooking, 'start_date' | 'end_date' | 'workspace_id'>,
    PartialPick<ShortTermBooking, 'contact_id'> {}

export function createShortTermBooking(stb: CreatableShortTermBooking) {
  const { contact_id, ...params } = stb;

  return axios.post<CreatableShortTermBooking, AxiosResponse<ShortTermBooking>>(
    `/v1/users/${contact_id}/short_term_bookings`,
    params
  );
}
