import React, { CSSProperties } from 'react';

import Clickable from 'ds/Clickable';
import Img from 'ds/Img';

interface Props {
  onClick: VoidFunction;
  style?: CSSProperties;
  srcKey: string;
}

const MessageImageThumbnail: React.FC<Props> = ({ onClick, style, srcKey }) => {
  return (
    <Clickable onClick={onClick} style={style}>
      <Img
        srcKey={srcKey}
        alt="task image"
        width={320}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: 8
        }}
      />
    </Clickable>
  );
};

export default MessageImageThumbnail;
