import React, { useContext } from 'react';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const SigningUpFlexOffices: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout flex direction={xs ? 'column' : 'row'}>
        <Layout width={xs ? contentWidth : 368} flexShrink={0}>
          <Text size="h3" color="blue-600" scale={xs}>
            Signing Up For Flex Offices
          </Text>
          <Layout marginTop={48}>
            <Text size="h6" color="gray-700" bold={false}>
              Once you’ve found an office, your rep will work with you to finalize your agreement. As in coworking, the
              process is fairly standardized. Your agreement will be with the flexible space provider — not the landlord
              — and the heavy negotiations have been done already. However, there are a few elements you’ll need to
              finalize.
            </Text>
          </Layout>
        </Layout>
        <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 24 : undefined}>
          <Layout paddingTop={20} paddingBottom={24}>
            <Text size="h5" color="blue-600">
              Elements to finalize
            </Text>
          </Layout>
          <Layout
            paddingTop={40}
            paddingX={36}
            paddingBottom={36}
            borderRadius={24}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            color="white"
            flex
            direction={xs ? 'column' : 'row'}
          >
            <Layout width={152} flexShrink={0}>
              <Text size="h6" color="blue-500">
                Minimum
                <br />
                Term Length
              </Text>
            </Layout>
            <Text size="body1" color="gray-700">
              Flexible spaces are just that: flexible. But the company you work with will still want to specify a
              minimum length of stay — starting from 3 months and up — to make sure all costs are covered
            </Text>
          </Layout>
          <Layout
            paddingTop={40}
            paddingX={36}
            paddingBottom={36}
            borderRadius={24}
            marginTop={24}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            color="white"
            flex
            direction={xs ? 'column' : 'row'}
          >
            <Layout width={152} flexShrink={0}>
              <Text size="h6" color="blue-500">
                Renovations
              </Text>
            </Layout>
            <Layout>
              <Text size="body1" color="gray-700">
                If there are changes or fixes you’ll need made, now’s the time to bring them up.
              </Text>
              <br />
              <ul>
                <li>
                  <Text size="body1" color="gray-700">
                    In some cases, you may be able to request that improvement costs be worked into your monthly payment
                    — covering those expenses over time rather than all at once.
                  </Text>
                </li>
                <li>
                  <Text size="body1" color="gray-700">
                    Preferred treatment regarding renovations will be determined by length of stay and after providing
                    positive company financials
                  </Text>
                </li>
              </ul>
            </Layout>
          </Layout>
          <Layout
            paddingTop={40}
            paddingX={36}
            paddingBottom={36}
            marginTop={24}
            borderRadius={24}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            color="white"
            flex
            direction={xs ? 'column' : 'row'}
          >
            <Layout width={152} flexShrink={0}>
              <Text size="h6" color="blue-500">
                Add-Ons
              </Text>
            </Layout>
            <Text size="body1" color="gray-700">
              If your flex office provider includes ongoing internet service, cleaning, maintenance, snacks, or other
              services, you should be in good shape. If there are other services you need, you may be able to negotiate
              them into the agreement
            </Text>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default SigningUpFlexOffices;
