import { Dispatch, SetStateAction } from 'react';
import { EditableImage } from '@codiwork/codi';
import loadImage from 'blueimp-load-image';
import convert from 'heic-convert';

import { TARGET_THUMBNAIL_WIDTH, THUMBNAIL_MARGIN_RIGHT } from './constants';

interface ImportedPhoto extends EditableImage {
  valid: boolean;
}

interface ImportPhotosResponse {
  images: EditableImage[];
}

export const importPhotos = async ({
  files,
  images,
  maxPhotoCount,
  setErrorMessage,
  minWidth,
  minHeight,
  maxFileSize
}: {
  files: File[];
  images: EditableImage[];
  maxPhotoCount: number;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  minWidth: number;
  minHeight: number;
  maxFileSize: number;
}): Promise<ImportPhotosResponse> => {
  const existingPhotoCount = images.length;

  const convertedFiles = await Promise.all(
    files.map(async file => {
      if (file.type === 'image/heic') {
        return (await convertHeicToJpeg(file)) as File;
      } else {
        return file;
      }
    })
  );

  const importedPhotosValidator = (await Promise.all(
    convertedFiles.slice(0, maxPhotoCount - existingPhotoCount).map(async photo => {
      try {
        const valid = (await validateImage({
          file: photo,
          minWidth,
          minHeight,
          maxFileSize
        })) as boolean;

        return { valid };
      } catch (error) {
        console.error(error);
      }
    })
  )) as ImportedPhoto[];

  if (importedPhotosValidator.filter(p => !p.valid).length) {
    setErrorMessage(
      `Photos must be JPEG or PNG format, have a pixel size of ${minWidth}w x ${minHeight}h or larger, and have a file size smaller than ${
        maxFileSize / 1000000
      }MB.`
    );
    return {
      images
    };
  } else {
    setErrorMessage(null);
  }

  const importedImages = convertedFiles.map(file => ({
    url: URL.createObjectURL(file)
  }));

  return {
    images: [...images, ...importedImages].slice(0, maxPhotoCount)
  };
};

export async function dataUrlToJpegFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
}

export async function convertHeicToJpeg(file: File) {
  return new Promise<File>(function (resolve, _reject) {
    let reader = new FileReader();
    reader.onload = async e => {
      if (!!e.target) {
        const inputBuffer = Buffer.from(e.target.result as string, 'base64');
        convert({
          buffer: inputBuffer, // the HEIC file buffer
          format: 'JPEG', // output format
          quality: 1 // the jpeg compression quality, between 0 and 1
        })
          .then((outputBuffer: any) => {
            resolve(dataUrlToJpegFile('data:image/jpeg;base64,' + outputBuffer.toString('base64'), file.name));
          })
          .catch(() => {
            resolve(file);
          });
      } else {
        resolve(file);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

export function validateImage({
  file,
  minWidth = 3500,
  minHeight = 2500,
  maxFileSize = 50000000
}: {
  file: File;
  minWidth: number;
  minHeight: number;
  maxFileSize: number;
}) {
  return new Promise(function (resolve, _reject) {
    if (file.size > maxFileSize) {
      return resolve(false);
    }

    loadImage.parseMetaData(file, function () {
      let reader = new FileReader();
      reader.onload = e => {
        let img = document.createElement('img');
        img.onload = () => {
          let imgWidth = img.width;
          let imgHeight = img.height;

          if ((minWidth && imgWidth < minWidth) || (minHeight && imgHeight < minHeight)) {
            return resolve(false);
          } else {
            return resolve(true);
          }
        };
        if (!!e.target) {
          img.src = e.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    });
  });
}

export function getThumbnailValues({
  containerWidth
}: {
  containerWidth: number;
}): { width: number; photosPerRow: number } {
  const naturalDivisons = containerWidth / (TARGET_THUMBNAIL_WIDTH + THUMBNAIL_MARGIN_RIGHT);
  const photosPerRow = Math.min(4, Math.floor(naturalDivisons));
  const width = (containerWidth - (photosPerRow - 1) * THUMBNAIL_MARGIN_RIGHT) / photosPerRow;

  return { width, photosPerRow };
}
