import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { app, media } from 'context';
import removeNonDigits from 'ds/helpers/removeNonDigits';

import Layout from './Layout';
import Spinner from './Spinner';
import Text from './Text';
import TextButton from './TextButton';

export interface Props {
  handleSubmit: (code: string) => void;
  email: string;
  submitting?: boolean;
  failed?: boolean;
  code?: string;
  error?: string;
  sendCode: (params: { email: string; source?: string }) => void;
  setCode: (code: string) => void;
  sendingCode?: boolean;
}

const CELLS = [0, 1, 2, 3, 4, 5];
const MARGIN_RIGHT = 12;
const CODE_REGEXP = /^\d{6}$/;

const VerificationCode: React.FC<Props> = ({
  handleSubmit,
  email,
  failed,
  sendCode,
  sendingCode,
  error,
  submitting,
  setCode,
  ...props
}) => {
  const { xxs, xs, sm } = useContext(media);
  const isMobile = xs || sm;
  const { contentWidth } = useContext(app);
  const code = props.code || '      ';
  const firstInputRef = useRef<HTMLInputElement>(null);
  const lastInputRef = useRef<HTMLInputElement>(null);
  const inputWidth = xxs ? 40 : xs ? 48 : 56;

  useEffect(() => {
    failed && !submitting && firstInputRef.current && firstInputRef.current.focus();
  }, [failed, submitting]);

  useEffect(() => {
    if (!CODE_REGEXP.test(code)) return;
    if (submitting) return;

    handleSubmit(code);
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaste = (event: React.ClipboardEvent) => {
    event.clipboardData.items[0].getAsString(text => {
      const pastedCode = text
        .split('')
        .map((char, _index) => {
          if (Number.isInteger(Number(char))) {
            return char;
          }
          return '';
        })
        .slice(0, 6)
        .join('');

      if (pastedCode.length !== 6) return;

      setCode(pastedCode);
    });
  };

  return (
    <Layout>
      <Layout opacity={submitting ? 0.4 : undefined}>
        <Layout align={xs ? 'center' : undefined} direction="column" position="relative">
          <form style={{ maxWidth: contentWidth }} onSubmit={e => e.preventDefault()}>
            <div onPaste={handlePaste}>
              <Layout flex>
                {CELLS.map(index => {
                  const cellValue = code[index];

                  return (
                    <div
                      key={index}
                      className="VerificationCode-inputContainer"
                      style={{ marginRight: index < 5 ? MARGIN_RIGHT : 0, width: inputWidth, height: inputWidth + 8 }}
                    >
                      <input
                        name={`code${index}`}
                        autoFocus={xs ? undefined : index === 0}
                        ref={index === 0 ? firstInputRef : index === 5 ? lastInputRef : undefined}
                        className={classNames('VerificationCode-input', {
                          'has-error': failed && !submitting,
                          'has-value': cellValue !== ' '
                        })}
                        value={cellValue === ' ' ? '' : cellValue}
                        disabled={submitting}
                        type="tel"
                        autoComplete="one-time-code"
                        onKeyDown={e => {
                          const target = e.target as HTMLInputElement;

                          if (index !== 0 && !target.value && e.key === 'Backspace') {
                            const container = target.parentElement;

                            const previousInput = container?.previousElementSibling?.firstElementChild;

                            if (!previousInput) return;

                            e.preventDefault();

                            (previousInput as HTMLInputElement).focus();
                          }
                        }}
                        onChange={e => {
                          const { value } = e.target;

                          if (value.length === 6) {
                            setCode(value);
                            return;
                          }

                          const isDelete = value === '';
                          const cells = code.split('');
                          const trimmedInput = removeNonDigits(value).trim();
                          const cleanedValue = trimmedInput[trimmedInput.length - 1];
                          // non-digit character entered
                          if (cleanedValue === undefined && cells[index] === ' ') {
                            return;
                          }

                          cells[index] = isDelete ? ' ' : cleanedValue;
                          const updatedCode = cells.join('');
                          setCode(updatedCode);

                          const container = e.target.parentElement!;

                          if (isDelete && index === 0) {
                            return;
                          } else if (updatedCode.trim().length === 6 && index === 5) {
                            return;
                          } else {
                            const inputToFocus = (isDelete
                              ? container.previousElementSibling
                              : container.nextElementSibling
                            )?.firstElementChild;

                            if (!inputToFocus) return;

                            (inputToFocus as HTMLInputElement).focus();
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </Layout>
            </div>
          </form>
          {submitting && (
            <Layout
              position="absolute"
              display="inline-flex"
              top="50%"
              left="50%"
              transform="translateY(-50%) translateX(-50%)"
            >
              <Spinner size="lg" color="gray-600" />
            </Layout>
          )}
          {failed && !submitting && (
            <Layout marginTop={xs ? 4 : 8}>
              <Text size="body2" color="red-700">
                {error || 'Invalid code, please try again.'}
              </Text>
            </Layout>
          )}
        </Layout>
        <Layout {...(isMobile ? { marginTop: 24 } : { marginTop: 16, marginBottom: 8 })}>
          {sendingCode ? (
            <Text color="gray-600" size="body3" inline>
              Resending code...
            </Text>
          ) : (
            <Layout justify={xs ? 'center' : undefined} align="center">
              <Text color="gray-600" size="body3" inline>
                Didn't get the email?&nbsp;
              </Text>
              <TextButton
                paddingY={false}
                textSize="body3"
                color="blue-500"
                text="Send again"
                onClick={() => sendCode({ email, source: 'web' })}
              />
            </Layout>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default VerificationCode;
