import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import { Layout, Text } from 'ds';
import { ButterLocation } from 'lib/butter/types';

import LocationColumn from './LocationColumn';
import { TRANSITION_DELAY, TRANSITION_OFFSET } from './constants';

interface Props {
  columns: ButterLocation[][];
  sectionStyle: CSSProperties;
  onImageLoad: VoidFunction;
  closeMenu: VoidFunction;
}

const Locations: React.FC<Props> = ({ columns, closeMenu, sectionStyle, onImageLoad }) => {
  return (
    <Layout paddingRight={48}>
      <Text size="body3" bold>
        {/** TODO: put this title in Butter? */}
        POPULAR LOCATIONS
      </Text>
      <Layout marginTop={32} flex>
        {columns.map((locations, index) => {
          return (
            <Layout
              key={index}
              width={`${(1 / columns.length) * 100}%`}
              marginRight={16}
              __className={classNames('NavigationMenu-locationSection')}
              __style={{
                transitionDelay: `${TRANSITION_DELAY + index * TRANSITION_OFFSET}ms`,
                ...sectionStyle
              }}
            >
              <LocationColumn locations={locations} closeMenu={closeMenu} onImageLoad={onImageLoad} />
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default Locations;
