import {
  EventUnion as SharedEventUnion,
  Events as SharedEvents,
  User,
  apiTrackPost,
  postTrackingError,
  setAnonymousIdHeader
} from '@codiwork/codi';
import { SegmentAnalytics } from '@segment/analytics.js-core';

import { actions as appActions } from 'store/App';
import { store } from 'store/store';

import { WebEventUnion, WebEvents } from './types';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS | undefined;
  }
}

type EventUnion = SharedEventUnion | WebEventUnion;
type Events = SharedEvents & WebEvents;

export const track_DEPRECATED = (event: string, properties?: object, options?: object) => {
  try {
    window.analytics && window.analytics.track(event, properties, options);
  } catch {
    postTrackingError({
      event: event,
      platform: 'web'
    });
  }
};

export function track<T extends EventUnion>(event: T, properties: Events[T], options?: object) {
  try {
    window.analytics && window.analytics.track(event, properties, options);
  } catch {
    postTrackingError({
      event: event,
      platform: 'web'
    });
  }
}

export function apiTrack<T extends EventUnion>(event: T, properties: Events[T]) {
  if (!window.analytics || !apiTrackPost) {
    return;
  }

  apiTrackPost({ event, properties });
}

export const page = (category: string, name?: string, properties?: object, options?: object) => {
  try {
    window.analytics && window.analytics.page(category, name, properties, options);
  } catch {
    postTrackingError({
      event: 'page',
      platform: 'web'
    });
  }
};

export const identify = (user: User, traits?: object) => {
  try {
    if (!window.analytics) {
      return;
    }

    const id: string = user.id.toString();

    const lastIdentifiedId = (window.analytics.user() as any)?.id();

    if (lastIdentifiedId && lastIdentifiedId === id) {
      return;
    }

    const integrations = {
      integrations: {}
    };

    window.analytics.identify(id, traits || {}, integrations);
  } catch {
    postTrackingError({
      event: 'identify',
      platform: 'web'
    });
  }
};

export const resetAnalytics = () => {
  try {
    if (!window.analytics) return;
    window.analytics.reset();
    const anonymousId = (window.analytics.user() as any).anonymousId();
    setAnonymousIdHeader(anonymousId);
    store.dispatch(appActions.setAnonymousId(anonymousId));
  } catch {
    postTrackingError({
      event: 'reset',
      platform: 'web'
    });
  }
};
