import React, { PropsWithChildren, forwardRef } from 'react';

import HeaderRow from './HeaderRow';
import { TableContext } from './TableProvider';
import { HEADER_HEIGHT } from './constants';
import { BaseColumnNonGeneric, SortFilterProps } from './types';

interface Props extends SortFilterProps {
  columns: BaseColumnNonGeneric[];
  style: React.CSSProperties;
}

const InnerElement = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({ children, style }, ref) => {
  return (
    <TableContext.Consumer>
      {({
        headerIndices,
        sortState,
        setSortState,
        filterState,
        setFilterState,
        columns,
        setColumnWidths,
        getColumnWidth,
        minWidth
      }) => (
        <div ref={ref} style={{ ...style, minWidth }}>
          {headerIndices.map(index => (
            <HeaderRow
              getColumnWidth={getColumnWidth!}
              setColumnWidths={setColumnWidths!}
              columns={columns || []}
              index={index}
              key={index}
              sortState={sortState!}
              setSortState={setSortState!}
              filterState={filterState!}
              setFilterState={setFilterState!}
              style={{ top: index * HEADER_HEIGHT, left: 0, width: '100%', height: HEADER_HEIGHT }}
            />
          ))}
          {children}
        </div>
      )}
    </TableContext.Consumer>
  );
});

export default InnerElement;
