import React, { useContext } from 'react';

import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookCTA from './PlaybookCTA';

import { ReactComponent as CardSvg } from './Card.svg';
import DesktopGradient from './DesktopGradient.png';
import MobileGradient from './MobileGradient.png';

interface Props {}

const Hero: React.FC<Props> = () => {
  const { xs, sm, md, lg } = useContext(media);
  const { contentWidth } = useContext(app);

  const Content = (
    <Layout width={contentWidth} direction="row" margin="0px auto">
      <Layout
        align="center"
        {...(xs ? { direction: 'column', paddingTop: 0, paddingBottom: 48 } : md ? { paddingY: 64 } : { paddingY: 96 })}
      >
        <Layout equalWidth={lg || md || sm} paddingTop={xs ? 36 : undefined}>
          <Layout>
            <Text size="h3" scale color="blue-700" inline>
              Your step-by-step guide to
            </Text>
            <Text size="h3" scale color="blue-500" inline>
              {' '}
              finding and managing a private office
            </Text>
          </Layout>
          <Layout marginTop={24}>
            <Text size="h6" scale bold={false}>
              With this comprehensive playbook, you can successfully navigate the world of commercial real estate.
              Discover expert tips for every step of the process - from choosing an office to signing a lease and moving
              in.
            </Text>
          </Layout>
          <Layout marginTop={xs ? 36 : 24}>
            <PlaybookCTA />
          </Layout>
        </Layout>
        {!xs && (
          <Layout
            equalWidth={lg || md || sm}
            marginLeft={xs ? -12 : lg ? 80 : 40}
            marginRight={xs ? -3 : undefined}
            marginTop={xs ? 46 : undefined}
          >
            <CardSvg style={{ width: '100%' }} />
          </Layout>
        )}
      </Layout>
    </Layout>
  );

  return xs ? (
    <>
      {Content}
      <HeroImage img={MobileGradient}>
        <Layout paddingLeft={12} paddingRight={21} paddingY={42}>
          <CardSvg style={{ width: '100%' }} />
        </Layout>
      </HeroImage>
    </>
  ) : (
    <HeroImage img={DesktopGradient}>{Content}</HeroImage>
  );
};

export default Hero;
