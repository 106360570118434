import React from 'react';
import { BLUE_300_HEX, GRAY_100_HEX, IconName } from '@codiwork/codi';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { FIELD_HEIGHT_SM } from 'ds/constants';

import Slider from './Slider';
import FilterContainer from '../FilterContainer';
import Layout from '../Layout';
import Text from '../Text';
import MoneyInput from '../inputs/MoneyInput';
import NumberInput from '../inputs/NumberInput';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface Props {
  icon: IconName;
  label: string;
  labelElement?: JSX.Element;
  shortLabel: string;
  values: number[];
  onInputMinChange: (min?: number) => void;
  onInputMaxChange: (max?: number) => void;
  inputMinValue?: number;
  inputMaxValue?: number;
  inputMax: number;
  sliderMin?: number;
  sliderMax: number;
  sliderMinValue?: number;
  sliderMaxValue?: number;
  onSliderMinChange: (min?: number) => void;
  onSliderMaxChange: (max?: number) => void;
  onClear?: VoidFunction;
  inputInterval?: number;
  chartInterval?: number;
  type: 'number' | 'money';
  buttonText: string;
  align?: 'left' | 'right';
  onClickOutside?: VoidFunction;
  onSubmit: VoidFunction;
  isDefaultSelected: boolean;
  suffix?: string;
  maxRequired?: boolean;
  initialState?: 'open' | 'closed';
}

let current = 50;
let intervalIndex = 0;
const intervalFactors = [2, 2.5, 2];
const INTERVALS = new Array(10).fill(1).map(_ => {
  let interval = current;
  interval *= intervalFactors[intervalIndex];

  intervalIndex++;

  if (intervalIndex === 3) {
    intervalIndex = 0;
  }

  current = interval;

  return interval;
});

const RangeFilterContainer: React.FC<Props> = ({
  onClickOutside,
  onSubmit,
  buttonText,
  align,
  values,
  sliderMin = 0,
  sliderMinValue,
  sliderMaxValue,
  sliderMax,
  inputInterval = 1,
  inputMinValue,
  inputMaxValue,
  icon,
  label,
  labelElement,
  onClear,
  onInputMinChange,
  onInputMaxChange,
  onSliderMinChange,
  onSliderMaxChange,
  shortLabel,
  inputMax,
  type,
  isDefaultSelected,
  suffix,
  maxRequired,
  initialState = 'closed'
}) => {
  return (
    <FilterContainer
      icon={icon}
      isDefaultSelected={isDefaultSelected}
      buttonText={buttonText}
      onClear={onClear}
      align={align}
      onClickOutside={onClickOutside}
      onSubmit={onSubmit}
      doneDisabled={maxRequired && !inputMaxValue}
      initialState={initialState}
    >
      <Layout align="center">
        <Text size="body2" semibold>
          {label}
        </Text>
        {labelElement}
      </Layout>
      <Layout marginTop={16}>
        <RangeFilter
          sliderMin={sliderMin}
          sliderMax={sliderMax}
          inputMinValue={inputMinValue}
          inputMaxValue={inputMaxValue}
          values={values}
          inputInterval={inputInterval}
          sliderMinValue={sliderMinValue}
          onInputMinChange={onInputMinChange}
          onInputMaxChange={onInputMaxChange}
          onSliderMinChange={onSliderMinChange}
          onSliderMaxChange={onSliderMaxChange}
          sliderMaxValue={sliderMaxValue}
          shortLabel={shortLabel}
          type={type}
          inputMax={inputMax}
          suffix={suffix}
          maxRequired={maxRequired}
        />
      </Layout>
    </FilterContainer>
  );
};

interface RangeFilterProps
  extends Omit<Props, 'onSubmit' | 'align' | 'onClear' | 'buttonText' | 'icon' | 'label' | 'isDefaultSelected'> {}

export const RangeFilter: React.FC<RangeFilterProps> = ({
  values,
  sliderMin = 0,
  sliderMax,
  sliderMinValue,
  sliderMaxValue,
  onSliderMinChange,
  onSliderMaxChange,
  onInputMinChange,
  onInputMaxChange,
  shortLabel,
  inputMinValue,
  inputMaxValue,
  type,
  inputInterval = 1,
  inputMax,
  suffix,
  maxRequired,
  ...props
}) => {
  const chartInterval = props.chartInterval || determineInterval({ min: sliderMin, max: sliderMax });
  const bucketed = bucketValues({ values, interval: chartInterval, min: sliderMin, max: sliderMax });
  const maxCount = Math.max(...bucketed.values);

  return (
    <>
      <Layout position="relative">
        <Bar
          options={{
            plugins: {
              legend: { display: false },
              tooltip: { enabled: false }
            },
            scales: {
              x: {
                ticks: { display: false },
                grid: { display: false }
              },
              y: {
                min: 0,
                max: maxCount + 1,
                ticks: { display: false },
                grid: { display: false, drawBorder: false }
              }
            }
          }}
          data={{
            labels: bucketed.labels,
            datasets: [
              {
                data: bucketed.values,
                backgroundColor: ({ dataIndex }) => {
                  const bucketMin = bucketed.labels[dataIndex];
                  const bucketMax = bucketed.labels[dataIndex + 1];
                  let inRange = false;

                  if (!bucketMax) {
                    inRange = (sliderMinValue || sliderMin) >= bucketMin;
                  } else if (
                    (bucketMin === undefined || (sliderMinValue || sliderMin) <= bucketMin) &&
                    (bucketMax === undefined || (sliderMaxValue || sliderMax) >= bucketMax)
                  ) {
                    inRange = true;
                  }

                  return inRange ? BLUE_300_HEX : GRAY_100_HEX;
                }
              }
            ]
          }}
          height={78}
          width={292}
        />
        <Layout position="absolute" bottom={-4} width="100%">
          <Slider
            min={sliderMin}
            max={sliderMax}
            minValue={sliderMinValue}
            maxValue={sliderMaxValue}
            onMinValueChange={({ value }) => onSliderMinChange(value)}
            onMaxValueChange={({ value }) => onSliderMaxChange(value)}
            interval={inputInterval || 1}
          ></Slider>
        </Layout>
      </Layout>
      <Layout marginTop={16} flex paddingBottom={16}>
        {type === 'money' ? (
          <MoneyInput
            name={`${shortLabel} min`}
            step={inputInterval}
            label={`Min ${shortLabel}`}
            suffix={suffix}
            size="sm"
            onChange={({ value }) => onInputMinChange(value)}
            value={inputMinValue}
            min={0}
            required={false}
            validateOnBlur={false}
            cents={false}
            onBlur={({ value }) => {
              if (typeof value === 'number' && value < sliderMin) {
                onInputMinChange(undefined);
              }
            }}
          />
        ) : (
          <Layout flexGrow={1}>
            <NumberInput
              name={`${shortLabel} min`}
              step={inputInterval}
              label={`Min ${shortLabel}`}
              size="sm"
              onChange={({ value }) => onInputMinChange(value)}
              value={inputMinValue}
              min={0}
              required={false}
              validateOnBlur={false}
              onBlur={({ value }) => {
                if (typeof value === 'number' && value < sliderMin) {
                  onInputMinChange(undefined);
                }
              }}
              suffix={suffix}
            />
          </Layout>
        )}
        <Layout paddingX={8} height={FIELD_HEIGHT_SM} align="center">
          <Text size="body2" scale>
            —
          </Text>
        </Layout>
        {type === 'money' ? (
          <MoneyInput
            name={`${shortLabel} max`}
            step={inputInterval}
            label={`Max ${shortLabel}`}
            size="sm"
            onChange={({ value }) => onInputMaxChange(value)}
            value={inputMaxValue}
            validateOnBlur={false}
            cents={false}
            suffix={suffix}
            onBlur={({ value }) => {
              if (typeof value === 'number' && value < 0) onInputMaxChange(undefined);
            }}
            max={inputMax}
            min={maxRequired ? 1 : 0}
            required={maxRequired}
          />
        ) : (
          <Layout flexGrow={1}>
            <NumberInput
              name={`${shortLabel} max`}
              step={inputInterval}
              label={`Max ${shortLabel}`}
              size="sm"
              onChange={({ value }) => onInputMaxChange(value)}
              value={inputMaxValue}
              min={maxRequired ? 1 : 0}
              required={maxRequired}
              suffix={suffix}
              validateOnBlur={false}
              onBlur={({ value }) => {
                if (typeof value === 'number' && value < 0) onInputMaxChange(undefined);
              }}
              max={inputMax}
            />
          </Layout>
        )}
      </Layout>
    </>
  );
};

function bucketValues({
  values,
  interval,
  ...props
}: {
  values: number[];
  interval: number;
  min?: number;
  max?: number;
}): { values: number[]; labels: number[] } {
  const min = typeof props.min === 'number' ? props.min : Math.min(...values);
  const max = typeof props.max === 'number' ? props.max : Math.max(...values);
  const range = max - min;
  const numberOfBuckets = Math.ceil(range / interval);
  const bucketedValues = new Array(numberOfBuckets).fill(0);
  const labels = new Array(numberOfBuckets).fill(null).map((_, index) => index * interval + min);
  const bucketLength = bucketedValues.length;

  values.forEach(value => {
    let bucketIndex = Math.floor((value - min) / interval);
    if (bucketIndex > bucketLength - 1) bucketIndex = bucketLength - 1;
    bucketedValues[bucketIndex] += 1;
  });

  return {
    values: bucketedValues,
    labels
  };
}

export const determineMax = ({ values, min = 0, max }: { values: number[]; min?: number; max?: number }) => {
  const calculatedMax = Math.max(...values);
  return Math.max(calculatedMax + determineInterval({ min, max: calculatedMax }) * 2, max || 0);
};

export function determineInterval({ min, max }: { min: number; max: number }) {
  const interval = (max - min) / 20;

  return INTERVALS.find(i => interval > i) || INTERVALS[0];
}

export default RangeFilterContainer;
