import React from 'react';
import classNames from 'classnames';

import Spinner, { Size as SpinnerSize } from 'ds/Spinner';
import useFieldSize from 'hooks/useFieldSize';

import { FieldSize } from '../types';

export interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: FieldSize;
  showSpinner?: boolean;
}

const SIZE_TO_SPINNER_SIZE: Record<FieldSize, SpinnerSize> = {
  xxs: 'xxs',
  xs: 'xxs',
  sm: 'xs',
  md: 'sm',
  lg: 'sm'
};

const ToggleControl: React.FC<Props> = ({ checked, disabled, showSpinner, onChange, ...props }) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  return (
    <button
      disabled={disabled}
      onClick={() => {
        if (disabled) {
          return;
        }

        onChange(!checked);
      }}
      className={classNames('Toggle-control', `is-size-${size}`, {
        'is-checked': checked,
        'is-disabled': !!disabled,
        'has-spinner': !!showSpinner
      })}
      style={{ position: 'relative' }}
    >
      <span className={classNames('Toggle-slider', 'is-color-blue-500')}>
        <span className="Toggle-circle"></span>
      </span>
      <span className="Toggle-spinner">
        <Spinner size={SIZE_TO_SPINNER_SIZE[size]} />
      </span>
    </button>
  );
};

export default ToggleControl;
