import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Head from 'components/Head';
import TopCities from 'components/TopCities';

import { app } from 'context';
import { Layout, Text } from 'ds';
import { Replacement, replaceValues } from 'ds/utils';
import { getComparisons } from 'lib/butter';
import { Comparison } from 'lib/butter/types';
import { COMPARE_PATH } from 'routes';
import { CMSRouteProps } from 'routes/types';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';
import Hero from 'ux/Public/Hero';

import Table from './Table';
import ClientCTA from '../CmsPage/ClientCTA';
import { COMPARE_DETAIL_PREFIX } from '../constants';

interface MatchProps {
  slug: string;
}

interface Props extends CMSRouteProps<MatchProps> {}

const CompareDetail: React.FC<Props> = props => {
  const [comparisons, setComparisons] = useState<Comparison[]>([]);
  const { sectionGutterY } = useContext(app);
  const page = useAppSelector(state => selectCmsPage(state, props.cmsSlug));

  useEffect(() => {
    getComparisons().then(comparisons => {
      setComparisons(comparisons);
    });
  }, []);

  const competitorName = props.match.params.slug.split(COMPARE_DETAIL_PREFIX).slice(-1)[0];
  const codi = comparisons.find(c => c.name === 'Codi');
  const competitor = comparisons.find(c => c.name === competitorName);

  if (competitorName === 'WeWork') {
    return <Redirect to={`${COMPARE_PATH}/${COMPARE_DETAIL_PREFIX}Coworking`} />;
  }

  if (!comparisons.length || !page || !codi || !competitor) return null;

  const {
    seo: { title, description, keywords },
    sections
  } = page.fields;

  const replacements: Replacement[] = [{ variable: 'competitor', value: competitor.name }];

  return (
    <>
      <Head
        title={replaceValues(competitor.seo_title || title, replacements)}
        description={replaceValues(competitor.seo_description || description, replacements)}
        keywords={replaceValues(competitor.seo_keywords || keywords, replacements)}
      />
      {sections.map((section: any, index: number) => {
        return (
          <Section
            replacements={replacements}
            key={index}
            section={section}
            marginTop={sectionGutterY}
            codi={codi}
            competitor={competitor}
          />
        );
      })}
    </>
  );
};

interface SectionProps {
  section: any;
  marginTop: number;
  codi: Comparison;
  competitor: Comparison;
  replacements: Replacement[];
}

const Section: React.FC<SectionProps> = ({ section: { type, fields }, marginTop, codi, competitor, replacements }) => {
  switch (type) {
    case 'hero':
      return (
        <Hero
          {...fields}
          replacements={replacements}
          title={
            <Text size="h3" scale>
              <span dangerouslySetInnerHTML={{ __html: replaceValues(fields.title, replacements) }} />
            </Text>
          }
        />
      );

    case 'table':
      const rows = fields.rows.map(({ name, key }: { name: string; key: keyof Comparison }) => ({
        name,
        key,
        codiValue: codi[key],
        competitorValue: competitor[key]
      }));

      return (
        <Layout marginTop={marginTop}>
          <Table rows={rows} competitor={competitor.name} />
        </Layout>
      );

    case 'top_cities':
      return (
        <Layout align="center" marginTop={marginTop}>
          <TopCities />
        </Layout>
      );

    case 'client_cta_section':
      return <ClientCTA {...fields} />;

    default:
      return null;
  }
};

export default CompareDetail;
