import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Color } from '@codiwork/codi';

import { media } from 'context';
import ClickableDiv from 'ds/ClickableDiv';
import Icon from 'ds/Icon';
import IconButton from 'ds/IconButton';
import Img from 'ds/Img';
import Layout from 'ds/Layout';
import Link from 'ds/Link';
import PillButton from 'ds/PillButton';
import Text from 'ds/Text';
import { DESKTOP_HERO_PROMO_HEIGHT, MOBILE_HERO_PROMO_HEIGHT, SECONDARY_PROMO_HEIGHT } from 'ds/constants';
import { ButtonType, RequestState } from 'ds/types';

export interface PromotionProps {
  type: 'hero' | 'secondary';
  onClose?: VoidFunction;
  backgroundColor: Color;
  title: { text: string | JSX.Element; color?: Color; textSize?: 'h5' | 'h6' };
  subtitle: { text: string; color?: Color; textSize?: 'body1' | 'body2' };
  cta: {
    text: string | JSX.Element;
    href?: string;
    onClick?: VoidFunction;
    search?: string;
    textColor?: Color;
    buttonType?: ButtonType;
    style?: 'button' | 'link';
    requestState?: RequestState;
  };
  hero:
    | { src: string; srcXs: string; height?: number; element?: never }
    | { src?: never; srcXs?: never; height?: number; element: JSX.Element };
  secondary: { src?: string; element?: never } | { src?: never; element: JSX.Element };
}

interface Props extends PromotionProps {}

const Promotion: React.FC<Props> = ({ type, onClose, backgroundColor, hero, secondary, subtitle, title, cta }) => {
  const { xs } = useContext(media);
  const history = useHistory();

  if (type === 'secondary') {
    const secondaryElement = (
      <Layout
        color={backgroundColor}
        align="center"
        paddingLeft={xs ? 24 : 16}
        height={SECONDARY_PROMO_HEIGHT}
        justify="space-between"
        position="relative"
        {...(xs ? {} : { borderRadius: 8 })}
        __style={{ boxSizing: 'border-box', textAlign: 'left' }}
      >
        <Layout flexGrow={1} zIndex={1}>
          <Text size="body2" bold color={title.color}>
            {title.text}
          </Text>
          <Layout marginTop={6}>
            <Text size="body3" color={subtitle.color}>
              {subtitle.text}
            </Text>
          </Layout>
          <Layout marginTop={16} align="center">
            <PillButton
              text={cta.text}
              type="primaryInverse"
              {...(xs
                ? { onClick: () => {} }
                : {
                    onClick: cta.onClick,
                    href: cta.href,
                    search: cta.search,
                    disabled: cta.requestState === 'in_progress'
                  })}
            />
          </Layout>
        </Layout>
        {secondary.src ? (
          <Img
            srcKey={secondary.src}
            folder="ux"
            width={600}
            style={{
              width: xs ? 142 : 132,
              height: SECONDARY_PROMO_HEIGHT,
              objectFit: 'cover',
              flexShrink: 0,
              marginLeft: 28,
              objectPosition: '0%',
              ...(xs ? {} : { borderTopRightRadius: 8, borderBottomRightRadius: 8 })
            }}
            alt="office"
          />
        ) : (
          secondary.element
        )}
        {onClose && (
          <Layout position="absolute" {...(xs ? { top: 8, right: 8 } : { top: 12, right: 12 })}>
            <IconButton
              size="xs"
              type="gray"
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
              name="close"
              stroke={2}
            />
          </Layout>
        )}
      </Layout>
    );

    return (
      <ClickableDiv
        onClick={() => {
          if (cta.onClick) {
            cta.onClick();
          } else {
            history.push({ pathname: cta.href, search: cta.search });
          }
        }}
      >
        {secondaryElement}
      </ClickableDiv>
    );
  }

  const heroElement = (
    <Layout
      color={backgroundColor}
      boxShadow=" 2px 0px 22px 0px rgba(87, 73, 109, 0.12)"
      borderRadius={8}
      align="center"
      position="relative"
      {...(xs ? { height: MOBILE_HERO_PROMO_HEIGHT, paddingLeft: 16 } : { paddingLeft: 36 })}
    >
      <Layout flexGrow={1} zIndex={1} marginRight={8}>
        <Text {...(xs ? { size: 'body2', bold: true } : { size: title.textSize || 'h5' })} color={title.color}>
          {title.text}
        </Text>
        <Layout {...(xs ? { marginTop: 4 } : { marginTop: 6 })}>
          <Text {...(xs ? { size: 'body3' } : { size: subtitle.textSize || 'body1' })} color={subtitle.color}>
            {subtitle.text}
          </Text>
        </Layout>
        <Layout {...(xs ? { marginTop: 12 } : { marginTop: 16 })}>
          {xs ? (
            <PillButton
              text={<>{cta.text}&nbsp;</>}
              type="primaryInverse"
              icon="rightArrow"
              onClick={() => {}}
              textSize="body3"
            />
          ) : cta.style === 'button' ? (
            <PillButton
              text={<>{cta.text}</>}
              href={cta.href}
              search={cta.search}
              type={cta.buttonType || 'primary'}
              disabled={cta.requestState === 'in_progress'}
              onClick={() => {}}
              textSize="body3"
            />
          ) : (
            <Link href={cta.href} search={cta.search}>
              <Layout align="center">
                <Text size="body2" color={cta.textColor || 'blue-500'}>
                  {cta.text}&nbsp;
                </Text>
                <Icon name="rightArrow" size={16} color={cta.textColor || 'blue-500'} />
              </Layout>
            </Link>
          )}
        </Layout>
      </Layout>
      {xs ? (
        hero.srcXs ? (
          <Img
            srcKey={hero.srcXs}
            alt="office"
            folder="ux"
            width={400}
            style={{
              height: MOBILE_HERO_PROMO_HEIGHT,
              width: 124,
              objectPosition: '0%',
              objectFit: 'cover',
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8
            }}
          />
        ) : (
          hero.element
        )
      ) : hero.src ? (
        <Layout width={592}>
          <Img
            srcKey={hero.src}
            alt="office"
            folder="ux"
            width={3000}
            style={{
              height: hero.height || DESKTOP_HERO_PROMO_HEIGHT,
              objectFit: 'cover',
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              objectPosition: '0%'
            }}
          />
        </Layout>
      ) : (
        hero.element
      )}
      {onClose && (
        <Layout position="absolute" {...(xs ? { top: 8, right: 8 } : { top: 12, right: 12 })}>
          <IconButton
            size="xs"
            type="gray"
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            name="close"
            stroke={2}
          />
        </Layout>
      )}
    </Layout>
  );

  return (
    <ClickableDiv
      onClick={() => {
        if (cta.onClick) {
          cta.onClick();
        } else {
          history.push({ pathname: cta.href, search: cta.search });
        }
      }}
    >
      {heroElement}
    </ClickableDiv>
  );
};

export default Promotion;
