import React, { useContext } from 'react';

import { media } from 'context';
import { Layout, Text } from 'ds';

import { ReactComponent as AvisonYoungLogo } from './avison-young-logo.svg';
import { ReactComponent as CcpLogo } from './ccp-logo.svg';
import { ReactComponent as JLLLogo } from './jll-logo.svg';
import { ReactComponent as NewmarkLogo } from './newmark-logo.svg';
import { ReactComponent as RDELogo } from './rde-logo.svg';

interface Props {}

const BrokerLogos: React.FC<Props> = () => {
  const { isMobile } = useContext(media);

  return (
    <Layout>
      <Text
        {...(isMobile ? { size: 'headline-lg', paddingX: 24 } : { size: 'display-sm' })}
        color="blue-600"
        align="center"
      >
        Trusted by brokers from coast to coast
      </Text>
      {isMobile ? (
        <Layout color="blue-700" padding={32} position="relative" marginTop={64}>
          <Layout align="center" justify="space-around">
            <JLLLogo width={81} />
            <NewmarkLogo width={97} />
            <AvisonYoungLogo width={66} />
          </Layout>
          <Layout align="center" justify="space-around">
            <RDELogo width={81} />
            <CcpLogo width={86} />
          </Layout>
        </Layout>
      ) : (
        <Layout
          marginTop={36}
          color="blue-700"
          borderRadius={16}
          paddingX={64}
          paddingY={124}
          align="center"
          justify="space-around"
        >
          <JLLLogo />
          <NewmarkLogo />
          <AvisonYoungLogo />
          <RDELogo />
          <CcpLogo />
        </Layout>
      )}
    </Layout>
  );
};

export default BrokerLogos;
