import React, { useContext, useState } from 'react';
import copy from 'copy-to-clipboard';

import { getBaseUrl } from 'helpers/environment';
import { showSnackbar } from 'helpers/snackbar';

import { app, media } from 'context';
import { Clickable, HEADER_Z_INDEX, Icon, IconButton, Layout, Link, Modal, Text, TextButton } from 'ds';
import { formatUsd } from 'helpers';
import { REFERRAL_LANDING_PATH, TERMS_PATH } from 'routes';
import { ReferralInfo } from 'ux/Public/types';

import { ReactComponent as CornerIllustrationXs } from './referral-sign-in-corner-illustration-xs.svg';
import { ReactComponent as CornerIllustration } from './referral-sign-in-corner-illustration.svg';
import { ReactComponent as TopIllustrationMd } from './referral-sign-in-illustration-md.svg';
import { ReactComponent as TopIllustrationSm } from './referral-sign-in-illustration-sm.svg';
import { ReactComponent as TopIllustrationXs } from './referral-sign-in-illustration-xs.svg';
import { ReactComponent as Illustration } from './referral-sign-in-illustration.svg';

interface Props {
  referralInfo: ReferralInfo;
}

const SignInReferralPrompt: React.FC<Props> = ({ referralInfo }) => {
  const { xs, sm, md, lg, xl, isMobile } = useContext(media);
  const { contentPaddingX, isSmallPhone } = useContext(app);
  const [bottomSheetIsVisible, setBottomSheetIsVisible] = useState<boolean>(false);

  const { max_amount, percentage_discount, code } = referralInfo;
  const referralUrl = `${getBaseUrl()}${REFERRAL_LANDING_PATH}/${code}`;

  const content = (
    <>
      <Layout marginTop={24}>
        <Text size={xs ? 'headline-xs' : 'headline-lg'} color="white">
          Refer and save up to {formatUsd(max_amount, false)}
        </Text>
      </Layout>
      <Layout marginTop={24}>
        <Text size={xs ? 'body-sm' : 'body-md'} align={!lg ? 'center' : undefined} color="white">
          Save up to {formatUsd(max_amount, false)} on your next plan payment for every new company that joins Codi
          using your link. The business you refer will get {percentage_discount}% off their first month, too.
        </Text>
      </Layout>
      <Layout marginTop={24}>
        <Text size="body-sm" color="white" semibold>
          Copy and share your link
        </Text>
      </Layout>
      <Layout marginTop={12} width="100%">
        <Clickable
          onClick={() => {
            copy(referralUrl);
            showSnackbar({ message: 'Copied.' });
          }}
          fullWidth
          style={{ borderRadius: 8 }}
        >
          <Layout
            color="white"
            justify="space-between"
            paddingX={16}
            paddingY={xs ? 14 : 16}
            borderRadius={8}
            align="center"
          >
            <Text size={xs ? 'body-xs' : 'body-sm'}>{referralUrl}</Text>
            <Icon size={xs ? 'sm' : 'md'} name="copy" />
          </Layout>
        </Clickable>
      </Layout>
      <Layout marginTop={16}>
        <Text size="tag" color="blue-200">
          Restrictions apply. See{' '}
          <Link href={`${TERMS_PATH}#referrals`} target="_blank">
            <Text inline size="tag" color="blue-200" underline>
              Terms
            </Text>
          </Link>{' '}
          for details.
        </Text>
      </Layout>
    </>
  );

  if (xs || sm || md) {
    return (
      <>
        <Layout
          position="fixed"
          top={0}
          left={0}
          color="blue-600"
          paddingY={16}
          paddingX={contentPaddingX}
          zIndex={HEADER_Z_INDEX}
          width="100%"
        >
          {xs ? (
            <TopIllustrationXs
              style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
              preserveAspectRatio="none"
            />
          ) : sm ? (
            <TopIllustrationSm
              style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
              preserveAspectRatio="none"
            />
          ) : (
            <TopIllustrationMd
              style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
              preserveAspectRatio="none"
            />
          )}
          <Layout justify="center" align="center" position="relative" zIndex={1}>
            <Text size={xs ? 'body-xs' : 'body-sm'} color="white">
              Refer and save up to {formatUsd(max_amount, false)}
            </Text>
            <Layout display="inline-flex" marginLeft={12} marginRight={4}>
              <TextButton
                onClick={() => setBottomSheetIsVisible(true)}
                text="Learn more"
                color="white"
                semibold={false}
                textSize={xs ? 'body-xs' : 'body-sm'}
                paddingY={false}
                underline
              />
            </Layout>
            <Icon size={md ? 'sm' : 'xs'} name="rightArrow" color="white" />
          </Layout>
        </Layout>
        <Modal
          isVisible={bottomSheetIsVisible}
          onClose={() => setBottomSheetIsVisible(false)}
          paddingBottom={0}
          showCloseButton={false}
          paddingX={0}
          paddingTop={0}
          isBottomSheet={xs || sm}
          bottomSheetIsRounded
        >
          <Layout
            paddingX={contentPaddingX}
            color="blue-600"
            height="100%"
            paddingTop={contentPaddingX}
            {...(isMobile ? {} : { borderRadius: 12, paddingBottom: 80 })}
          >
            <CornerIllustrationXs
              width={md ? 200 : isSmallPhone ? 180 : 245}
              style={{ position: 'absolute', right: 0, bottom: 0 }}
            />
            <Layout justify="flex-end" position="relative" zIndex={1}>
              <IconButton
                type="transparentDark"
                name="close"
                size="lg"
                onClick={() => setBottomSheetIsVisible(false)}
              />
            </Layout>
            <Layout align="center" direction="column" position="relative" zIndex={1}>
              <Illustration width={105} />
              {content}
            </Layout>
          </Layout>
        </Modal>
      </>
    );
  }

  return (
    <Layout width="60%" height="100vh" position="relative" color="blue-600" align="center">
      {/* top vertically aligns with sign in form */}
      <Layout marginX={xl ? 160 : 100} maxWidth={526} zIndex={1} position="relative" top={-16}>
        <Illustration width={200} height={182} />
        {content}
      </Layout>
      <CornerIllustration width={369} style={{ position: 'fixed', right: 0, bottom: 0, zIndex: 0 }} />
    </Layout>
  );
};

export default SignInReferralPrompt;
