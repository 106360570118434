import React from 'react';

import Img from './Img';

interface Props {
  dimension: number;
}

const PurpleGradientCircle: React.FC<Props> = ({ dimension }) => {
  return (
    <Img
      srcKey="chatbot-avatar_xp8LeSLeu.png"
      folder="ux"
      alt="purple gradient circle"
      width={300}
      style={{ width: dimension, height: dimension }}
    />
  );
};

export default PurpleGradientCircle;
