import { SearchSortLabel } from './types';

export const SEARCH_WORKSPACE_CARD_PADDING_Y = 16;
export const FILTER_BAR_FIELD_SIZE = 'sm';
export const LIST_MAP_SPACE = 24;
export const FILTER_BAR_HEIGHT = 84;
export const FILTER_BAR_HEIGHT_STACKED = 120;

export const NUM_MEETING_ROOMS_OPTIONS = [
  { label: 'Any', value: 0 },
  { label: '1+', value: 1 },
  { label: '2+', value: 2 },
  { label: '3+', value: 3 },
  { label: '4+', value: 4 }
];

export const DAYS_PER_WEEK_OPTIONS = [
  { label: 'Full time', sublabel: 'Includes weekends', value: 5 },
  { label: '3 days/week', value: 3 },
  { label: '2 days/week', value: 2 },
  { label: '1 day/week', value: 1 }
];

export const SORT_LABELS: SearchSortLabel[] = [
  'Recommended',
  'Newest',
  'Price (high to low)',
  'Price (low to high)',
  'Size (high to low)',
  'Size (low to high)'
];
