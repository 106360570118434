import React, { PropsWithChildren } from 'react';

import { Icon, Layout, Text } from 'ds';
import { highlightMatches } from 'ds/utils';

interface Props {
  isBottomSheet?: boolean;
  focused: boolean;
  label: string;
  searchValue?: string;
}

const LocationResult: React.FC<PropsWithChildren<Props>> = ({ isBottomSheet, focused, searchValue, ...props }) => {
  const label = highlightMatches({
    label: props.label,
    query: searchValue,
    MatchedWrapper: ({ children }) => (
      <Text size="h6" bold inline>
        {children}
      </Text>
    )
  });

  return isBottomSheet ? (
    <Layout paddingY={16} paddingX={24} align="center" __className="active-bg-color-blue-50">
      <Layout marginRight={16} display="inline-flex">
        <Icon name="addressPinOutline" size={isBottomSheet ? 'sm' : 'md'} color="black" />
      </Layout>
      <Text size="h6" bold={false} inline>
        {label}
      </Text>
    </Layout>
  ) : (
    <Layout
      paddingX={24}
      paddingY={16}
      align="center"
      cursor="pointer"
      color={focused ? 'blue-50' : undefined}
      hoverColor="blue-50"
    >
      <Layout marginRight={16} display="inline-flex">
        <Icon name="addressPinOutline" size={isBottomSheet ? 'sm' : 'md'} color="black" />
      </Layout>
      <Text size="h6" bold={false} inline>
        {label}
      </Text>
    </Layout>
  );
};

export default LocationResult;
