import React from 'react';
import { DateTime } from 'luxon';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Text from 'ds/Text';

interface Props extends ReactDatePickerCustomHeaderProps {}

const CalendarHeader: React.FC<Props> = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  const monthDateTime = DateTime.fromJSDate(monthDate);

  return (
    <Layout justify="space-between">
      <IconButton size="xs" type="gray" disabled={prevMonthButtonDisabled} name="leftChevron" onClick={decreaseMonth} />
      <Text size="body2" semibold>
        {monthDateTime.toFormat('LLLL yyyy')}
      </Text>
      <IconButton
        size="xs"
        type="gray"
        disabled={nextMonthButtonDisabled}
        name="rightChevron"
        onClick={increaseMonth}
      />
    </Layout>
  );
};

export default CalendarHeader;
