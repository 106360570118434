import React, { useContext } from 'react';

import HeroImage from 'components/HeroImage';

import { media } from 'context';
import { Button, Layout, Text } from 'ds';

interface Props {
  setOffsiteRequestFormIsVisible: (isVisible: boolean) => void;
}

const CaseStudyFooter: React.FC<Props> = ({ setOffsiteRequestFormIsVisible }) => {
  const { xs, sm, md, lg, xl, xxl } = useContext(media);
  const height = xxl ? 528 : xl ? 460 : lg ? 384 : md ? 346 : sm ? 296 : 242;

  return (
    <Layout position="relative">
      <HeroImage
        imageKey="case-study-footer_Pcc9--_5fn.jpeg"
        imageKitFolder="public"
        height={height}
        filter="275.71deg, rgba(0, 0, 0, 0.4) 25.95%, rgba(0, 0, 0, 0) 76.07%"
        style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
        backgroundPosition={`center ${xxl ? 59 : xl ? 63 : lg ? 60 : md ? 58 : 52}%`}
        overlay
      />
      <Layout position="absolute" bottom={xs ? 56 : sm ? 54 : md ? 50 : xl ? 126 : 88}>
        <Text size={xs ? 'h5' : sm ? 'h4' : 'h3'} color="white">
          Leave the hosting
          <br />
          to us.
        </Text>
        <Layout marginTop={24}>
          <Button
            text="Book an offsite"
            size={xs ? 'xs' : sm ? 'sm' : 'md'}
            type="blueGradient"
            onClick={() => setOffsiteRequestFormIsVisible(true)}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CaseStudyFooter;
