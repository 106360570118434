import React, { useContext } from 'react';
import { BLUE_100_HEX } from '@codiwork/codi';
import classNames from 'classnames';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';

import { ReactComponent as A16ZLogo } from './a16z-logo.svg';
import { ReactComponent as Graphic } from './sf-tech-week-hero-graphic.svg';
import { ReactComponent as MobileGraphic } from './sf-tech-week-mobile-hero-graphic.svg';
import { ReactComponent as CodiLogo } from 'ds/codi-logo-no-padding.svg';

interface Props {}

const Hero: React.FC<Props> = () => {
  const { width, contentPaddingX } = useContext(app);

  const { xs, sm, md, xl } = useContext(media);

  if (xs || sm) {
    return (
      <Layout paddingY={80} color="blue-700" paddingX={contentPaddingX} position="relative">
        <MobileGraphic
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}
          preserveAspectRatio="none"
        />
        <Layout zIndex={1} position="relative">
          <Text size="body1" color="white">
            Tuesday, May 30
            <br />
            Codi x SF #TechWeek 2023
          </Text>
          <Layout marginTop={24}>
            <Text size="h3" color="white" scale>
              Fundraising for Series B
            </Text>
          </Layout>
          <Layout marginTop={24}>
            <Text size="body1" color="white" semibold>
              Brunch + Chat w/ Andrew Chen @ a16z
            </Text>
            <Text size="body1" color="white">
              <br />
              Join us on May 30th for brunch and an exclusive chat with Andrew Chen, General Partner at a16z, as he
              shares insights on Series B fundraising. With a background leading Uber's Rider Growth teams and
              advising/investing in startups like Dropbox and Tinder, Andrew brings a wealth of experience relevant to
              your fundraising journey.
            </Text>
          </Layout>
          <Layout marginTop={48} justify="center">
            <Button
              text="Register now"
              size="md"
              type="primary"
              href="https://lu.ma/4zeuu24b"
              iconPosition="right"
              icon="rightArrow"
              iconStroke={2}
            />
          </Layout>
          <Layout marginTop={12} justify="center">
            <Text size="body2" color="white" italic>
              Seating is limited.
            </Text>
          </Layout>
          <Layout marginTop={112} paddingBottom={34} justify="center" flex>
            <Layout position="relative" marginLeft={30}>
              <Img
                srcKey="andrew-chen_t5sHNPudS.jpeg"
                folder="public"
                alt="Andrew Chen"
                width={600}
                style={{
                  width: 178,
                  height: 178,
                  borderRadius: '50%',
                  border: `4px solid ${BLUE_100_HEX}`,
                  objectFit: 'cover'
                }}
              />
              <Layout color="blue-700" paddingX={16} paddingY={7} position="absolute" left={-30} top={30} zIndex={2}>
                <A16ZLogo width={47} />
              </Layout>
            </Layout>
            <Layout position="relative" zIndex={0} left={-24} bottom={54} height="fit-content">
              <Img
                srcKey="christelle_OrV9-vFGO.jpeg"
                folder="public"
                alt="Christelle Rohaut"
                width={528}
                style={{
                  width: 157,
                  height: 157,
                  borderRadius: '50%',
                  border: `4px solid ${BLUE_100_HEX}`,
                  objectFit: 'cover',
                  position: 'relative'
                }}
              />
              <Layout position="absolute" color="blue-500" bottom={-5} right={21} paddingY={7} paddingX={16}>
                <span className={classNames('Logo', 'is-color-white')}>
                  <CodiLogo color="white" width={47} />
                </span>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout height={620} position="relative" color="blue-700">
      <Graphic
        style={{ position: 'absolute', top: 0, left: 0, width, height: 620, zIndex: 0 }}
        preserveAspectRatio="none"
      />
      <Layout zIndex={2} position="relative" height="100%" align="center" justify="center" flex>
        <Layout maxWidth={1200} align="center">
          <Layout borderRadius={24} padding={md ? 24 : 40} color="white" maxWidth={md ? 360 : !xl ? 400 : 544}>
            <Text size="h6" color="blue-700">
              Tuesday, May 30 | Codi x SF #TechWeek 2023
            </Text>
            <Layout marginTop={16}>
              <Text size="h3" color="blue-500">
                Fundraising for Series B
              </Text>
            </Layout>
            <Layout marginTop={16}>
              <Text size="h6" color="blue-700">
                Brunch + Chat w/ Andrew Chen @ a16z
              </Text>
            </Layout>
            <Layout marginTop={40} align="center">
              <Button
                text="Register now"
                size="md"
                type="primary"
                href="https://lu.ma/4zeuu24b"
                iconPosition="right"
                icon="rightArrow"
                iconStroke={2}
              />
              <Layout marginLeft={12}>
                <Text size="body2" color="blue-700" italic>
                  Seating is limited.
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout marginLeft={md ? 24 : 44} maxWidth={md ? 480 : 648} position="relative" flex>
            <Layout position="relative" marginLeft={56} marginTop={56}>
              <Img
                srcKey="andrew-chen_t5sHNPudS.jpeg"
                folder="public"
                alt="Andrew Chen"
                width={600}
                style={{
                  width: md ? 240 : 298,
                  height: md ? 240 : 298,
                  borderRadius: '50%',
                  border: `4px solid ${BLUE_100_HEX}`,
                  objectFit: 'cover'
                }}
              />
              <Layout color="blue-700" paddingX={24} paddingY={10} position="absolute" left={-58} top={51} zIndex={2}>
                <A16ZLogo width={md ? 70 : 98} />
              </Layout>
            </Layout>
            <Layout position="relative" zIndex={0} left={md ? -40 : -50} top={md ? 24 : undefined} height="fit-content">
              <Img
                srcKey="christelle_OrV9-vFGO.jpeg"
                folder="public"
                alt="Christelle Rohaut"
                width={528}
                style={{
                  width: md ? 200 : 264,
                  height: md ? 200 : 264,
                  borderRadius: '50%',
                  border: `4px solid ${BLUE_100_HEX}`,
                  objectFit: 'cover',
                  position: 'relative'
                }}
              />
              <Layout position="absolute" color="blue-500" bottom={-8} right={24} paddingY={9} paddingX={36}>
                <span className={classNames('Logo', 'is-color-white')}>
                  <CodiLogo color="white" width={md ? 70 : 96} />
                </span>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Hero;
