import React, { useContext, useEffect, useState } from 'react';
import { BLUE_500_HEX, Image } from '@codiwork/codi';

import { app } from 'context';
import Button from 'ds/Button';
import Clickable from 'ds/Clickable';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Modal from 'ds/Modal';
import Text from 'ds/Text';
import { lockScreenScroll, unlockScreenScroll } from 'ds/utils';
import TourCTA from 'ux/Public/Listing/TourCTA';
import { ListingCTAProps } from 'ux/Public/Listing/types';

import FloorPlans from './FloorPlans';
import Photos from './Photos';
import VideoTour from './VideoTour';
import { HEADER_HEIGHT } from './constants';
import { MEDIA_TABS, MediaTab } from '../constants';

type Props = {
  images: Image[];
  imageIndex: number;
  floorPlans: Image[];
  matterportId: string | null;
  tourVideoKey: string | null;
  onClose: VoidFunction;
  setImageIndex: (value: number) => void;
  name?: String;
  modalZIndex?: number;
} & ListingCTAProps;

const Mobile: React.FC<Props> = ({
  images,
  imageIndex,
  floorPlans,
  matterportId,
  tourVideoKey,
  setImageIndex,
  onClose,
  name,
  CTA,
  workspace,
  officeLead,
  modalZIndex
}) => {
  const { windowWidth, windowHeight, contentPaddingX } = useContext(app);
  const [selectedTab, setSelectedTab] = useState<MediaTab>('Photos');

  const mediaTabs = [...MEDIA_TABS];
  if (!matterportId) {
    mediaTabs.splice(mediaTabs.indexOf('3D Walkthrough'), 1);
  }
  if (!tourVideoKey) {
    mediaTabs.splice(mediaTabs.indexOf('Video Tour'), 1);
  }
  if (!floorPlans.length) {
    mediaTabs.splice(mediaTabs.indexOf('Floor Plans'), 1);
  }

  const imagesArray: (Image | string)[] = [...images];
  if (!!matterportId) {
    imagesArray.splice(2, 0, matterportId);
  }

  const isVisible = imageIndex > -1;

  useEffect(() => {
    if (isVisible) {
      lockScreenScroll();
    } else {
      unlockScreenScroll();
    }

    return unlockScreenScroll;
  }, [isVisible]);

  return (
    <>
      <Modal showCloseButton={false} isFullScreen onClose={onClose} isVisible={isVisible} zIndexPosition={modalZIndex}>
        <Layout direction="column" height={HEADER_HEIGHT} width="100%">
          <Layout position="absolute" width="100%" color="white" zIndex={1}>
            <Layout marginTop={12} paddingX={24} align="center" direction="row">
              <IconButton name={'leftChevron'} onClick={onClose} size={'md'} type={'noBackground'} />
              <Text scale size="h6" ellipsis notBold>
                {name}
              </Text>
            </Layout>
            <Layout height={52} paddingRight={contentPaddingX} overflowX="auto" __style={{ whiteSpace: 'nowrap' }}>
              {mediaTabs.map((tab, index) => {
                const selected = selectedTab === tab;
                return (
                  <Clickable
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                    key={tab}
                    style={{
                      paddingTop: 16,
                      display: 'inline-block',
                      paddingBottom: 4,
                      marginLeft: index === 0 ? 24 : 32,
                      borderBottom: selected ? `4px solid ${BLUE_500_HEX}` : undefined
                    }}
                  >
                    <Text color={selected ? 'blue-500' : 'gray-500'} semibold={selected} size="body1">
                      {tab}
                    </Text>
                  </Clickable>
                );
              })}
            </Layout>
          </Layout>
          {selectedTab === 'Photos' && (
            <Photos
              images={images}
              imageIndex={imageIndex}
              matterportId={matterportId}
              tourVideoKey={tourVideoKey}
              show3DWalkthroughTab={() => setSelectedTab('3D Walkthrough')}
              showVideoTourTab={() => setSelectedTab('Video Tour')}
            />
          )}
          {selectedTab === '3D Walkthrough' && (
            <Layout
              marginTop={HEADER_HEIGHT}
              width={windowWidth}
              height={windowHeight - HEADER_HEIGHT}
              position="relative"
            >
              <iframe
                title="3D Walkthrough"
                width="100%"
                height={windowHeight - HEADER_HEIGHT}
                src={`https://my.matterport.com/show/?m=${matterportId}&play=1&qs=1&tiles=1&vr=0&title=0&help=2&tourcta=2&hlr=2&rf-experience=`}
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
              ></iframe>
            </Layout>
          )}
          {selectedTab === 'Video Tour' && !!tourVideoKey && <VideoTour tourVideoKey={tourVideoKey} />}
          {selectedTab === 'Floor Plans' && <FloorPlans floorPlans={floorPlans} />}
          {['Photos', 'Floor Plans'].includes(selectedTab) && (
            <Layout marginTop={12} paddingBottom={104} paddingX={contentPaddingX}>
              {workspace ? (
                <TourCTA workspace={workspace} officeLead={officeLead} />
              ) : CTA ? (
                <Button
                  text={CTA.text}
                  onClick={e => {
                    setImageIndex(-1);
                    CTA.onClick && CTA.onClick(e);
                  }}
                  size="sm"
                  type="primary"
                />
              ) : null}
            </Layout>
          )}
        </Layout>
      </Modal>
    </>
  );
};

export default Mobile;
