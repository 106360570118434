import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeedbackRequest as FeedbackRequestModel, formatDate, getUser } from '@codiwork/codi';
import qs from 'qs';

import Clickable from 'ds/Clickable';
import Icon from 'ds/Icon';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { actions as userActions } from 'store/User';
import { selectUserId } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import FeedbackModal from './FeedbackModal';
import { TASK_TYPE_TO_ICON } from './constants';
import { dismissFeedbackRequest } from './requests';
import { EditableFeedback } from './types';

interface Props {
  feedbackRequest: FeedbackRequestModel;
  consolidated?: boolean;
}

const FeedbackRequest: React.FC<Props> = ({
  feedbackRequest,
  feedbackRequest: { task_id, type, date, action },
  consolidated
}) => {
  const userId = useAppSelector(selectUserId);

  const history = useHistory();
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { feedback_id } = queryParams;
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState<boolean>(
    !!feedback_id && typeof feedback_id === 'string' && parseInt(feedback_id) === task_id
  );
  const [feedbackModalOrdinal, setFeedbackModalOrdinal] = useState<number>(0);
  const [feedback, setFeedback] = useState<EditableFeedback>({ task_id });

  const dispatch = useAppDispatch();

  if (!userId) return null;

  if (action === 'rated') return null;

  const iconName = TASK_TYPE_TO_ICON[type];

  const dismiss = () => {
    dismissFeedbackRequest({ userId, feedback: { task_id } }).then(() => {
      getUser({ id: userId }).then(({ data: user }) => {
        dispatch(userActions.setUser(user));
      });
    });
  };

  const thumbClicked = (liked: boolean) => {
    setFeedback({ ...feedback, liked });
    setFeedbackModalOrdinal(feedbackModalOrdinal + 1);
    setTimeout(() => {
      setIsFeedbackModalVisible(true);
    }, 0);
  };

  const promptContent =
    action === 'dismissed' ? null : (
      <Layout
        paddingY={16}
        paddingLeft={16}
        paddingRight={consolidated ? 24 : 48}
        borderRadius={8}
        color="white"
        boxShadow="2px 0px 22px 0px #57496D1F"
        align="center"
        justify="space-between"
        position="relative"
      >
        <Layout position="absolute" right={-8} top={-8}>
          <IconButton size={consolidated ? 'xs' : 'sm'} name="close" onClick={dismiss} type="gray" />
        </Layout>
        <Layout align="center">
          {!consolidated && (
            <Layout
              color="blue-25"
              width={64}
              height={64}
              borderRadius={8}
              align="center"
              justify="center"
              marginRight={16}
            >
              <Layout transform="rotate(13deg)">
                <Icon name={iconName} stroke={1.5} color="blue-500" size={44} />
              </Layout>
            </Layout>
          )}
          <Layout direction="column" justify="center">
            <Text size="overline-sm" color="gray-300" caps>
              {type}
            </Text>
            <Text size="body-md" semibold>
              Rate experience
            </Text>
            <Text size="body-sm" color="gray-400" semibold>
              {formatDate({ date: date, format: 'MED_WITH_SHORT_WEEKDAY' })}
            </Text>
          </Layout>
        </Layout>
        <Layout align="center">
          <Clickable
            color="gray-25"
            hoverColor="gray-50"
            activeColor="gray-50"
            style={{
              borderRadius: '50%',
              width: 52,
              height: 52,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex'
            }}
            onClick={() => thumbClicked(true)}
            context="thumnbsUp-Dashboard"
          >
            <Icon name="thumbsUp" stroke={2} size="lg" />
          </Clickable>
          <Layout marginLeft={consolidated ? 16 : 20} />
          <Clickable
            color="gray-25"
            hoverColor="gray-50"
            activeColor="gray-50"
            style={{
              borderRadius: '50%',
              width: 52,
              height: 52,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex'
            }}
            onClick={() => thumbClicked(false)}
            context="thumnbsDown-Dashboard"
          >
            <Icon name="thumbsDown" stroke={2} size="lg" />
          </Clickable>
        </Layout>
      </Layout>
    );

  return (
    <>
      <FeedbackModal
        key={feedbackModalOrdinal}
        isVisible={isFeedbackModalVisible}
        onClose={() => {
          setIsFeedbackModalVisible(false);
          getUser({ id: userId }).then(({ data: user }) => {
            dispatch(userActions.setUser(user));
          });
        }}
        feedbackRequest={feedbackRequest}
        feedback={feedback}
      />
      {promptContent}
    </>
  );
};

export default FeedbackRequest;
