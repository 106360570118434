import React, { useContext } from 'react';
import { GRAY_50_HEX } from '@codiwork/codi';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const Comparison: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { width } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout overflowX={xs ? 'hidden' : undefined}>
        <Text size="h3" color="blue-gradient" scale={xs}>
          Side-By-Side Comparison
        </Text>
        <Layout
          marginTop={48}
          boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
          borderRadius={24}
          color="white"
          flex
          overflowX={xs ? 'scroll' : undefined}
          maxWidth={xs ? width : undefined}
        >
          <Layout>
            <Layout paddingTop={48} paddingX={48} paddingBottom={24}>
              <Text size="h5" color="blue-600">
                Traditional
              </Text>
            </Layout>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', padding: '12px 12px 16px 36px', width: '50%' }}>
                    <Text size="h6" color="blue-500">
                      PROS
                    </Text>
                  </th>
                  <th style={{ textAlign: 'left', padding: '12 36px 16px 0', width: '50%' }}>
                    <Text size="h6" color="gray-300">
                      CONS
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Customize fully
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Inflexible long-term lease
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Maximize company culture
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Need space management
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Many locations
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Long process
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Written legal protections
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Little transparency
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </Layout>
          <Layout __style={{ borderLeft: `2px solid ${GRAY_50_HEX}` }}>
            <Layout paddingTop={48} paddingX={48} paddingBottom={24}>
              <Text size="h5" color="blue-600">
                Coworking
              </Text>
            </Layout>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', padding: '12px 12px 16px 36px', width: '50%' }}>
                    <Text size="h6" color="blue-500">
                      PROS
                    </Text>
                  </th>
                  <th style={{ textAlign: 'left', padding: '12 36px 16px 0', width: '50%' }}>
                    <Text size="h6" color="gray-300">
                      CONS
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Easy, turnkey process
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Little opportunity to build culture
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      No long-term lease
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Almost no opportunity for customization
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Fully managed
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Little room for growth
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Cost transparency
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Potentially higher costs
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </Layout>
          <Layout __style={{ borderLeft: `2px solid ${GRAY_50_HEX}` }}>
            <Layout paddingTop={48} paddingX={48} paddingBottom={24}>
              <Text size="h5" color="blue-600">
                Flex Office
              </Text>
            </Layout>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', padding: '12px 12px 16px 36px', width: '50%' }}>
                    <Text size="h6" color="blue-500">
                      PROS
                    </Text>
                  </th>
                  <th style={{ textAlign: 'left', padding: '12 36px 16px 0', width: '50%' }}>
                    <Text size="h6" color="gray-300">
                      CONS
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Easy, turnkey process
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Limited stock
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      No long-term leases
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Customization, but no construction
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Space management included
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="gray-700" semibold>
                      Taxed differently
                    </Text>
                  </td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Cost transparency
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}></td>
                </tr>
                <tr style={{ height: 90 }}>
                  <td style={{ padding: '12px 0 12px 36px', borderTop: `2px solid ${GRAY_50_HEX}` }}>
                    <Text size="body2" color="blue-500" semibold>
                      Timeshare options
                    </Text>
                  </td>
                  <td style={{ padding: '12px 36px 12px 0', borderTop: `2px solid ${GRAY_50_HEX}` }}></td>
                </tr>
              </tbody>
            </table>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default Comparison;
