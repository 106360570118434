import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const BeforeTeamArrives: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Text color="blue-600" size="h3" scale={xs}>
          Before the Team Arrives
        </Text>
        <Layout marginTop={72} flex direction={xs ? 'column' : 'row'}>
          <Layout equalWidth>
            <Layout flex align="flex-start" direction={xs ? 'column' : 'row'}>
              <Layout align="center">
                <Icon name="chair" color="blue-gradient" size={48} />
                <Layout marginLeft={24} width={206}>
                  <Text size="h6" color="blue-600">
                    Style
                  </Text>
                </Layout>
              </Layout>
              <Text size="h6" color="gray-700" bold={false}>
                Your office is your company’s home. Make it say something about you by matching the overall style to
                what you do. For example, tech companies often go for modern, Danish/Swedish styles that are lighter and
                inviting. More traditional companies look for darker, utilitarian furniture that gives an impression of
                authority and capability.
              </Text>
            </Layout>
            <Layout marginTop={36} flex align="flex-start" direction={xs ? 'column' : 'row'}>
              <Layout align="center">
                <Icon name="buildingRetail" color="blue-gradient" size={48} />
                <Layout marginLeft={24} width={206}>
                  <Text size="h6" color="blue-600">
                    Furniture Rentals
                  </Text>
                </Layout>
              </Layout>
              <Text size="h6" color="gray-700" bold={false}>
                In most cities, there are reputable furniture vendors that have access to multiple brands, categories,
                and styles to choose from.
              </Text>
            </Layout>
          </Layout>
          <Layout marginLeft={xs ? undefined : 80} marginTop={xs ? 36 : undefined} equalWidth>
            <Layout flex align="flex-start" direction={xs ? 'column' : 'row'}>
              <Layout align="center">
                <Icon name="wifi" color="blue-gradient" size={48} />
                <Layout marginLeft={24} width={206}>
                  <Text size="h6" color="blue-600">
                    Internet Service
                  </Text>
                </Layout>
              </Layout>
              <Text size="h6" color="gray-700" bold={false}>
                Price, bandwidth, and speed of service can vary greatly from city to city. Make sure you get multiple
                quotes and know how long it will take for your local provider to offer service. Also, make sure you test
                the wifi in all areas of your office — you may need to bring in signal extenders
              </Text>
            </Layout>
            <Layout marginTop={36} flex align="flex-start" direction={xs ? 'column' : 'row'}>
              <Layout align="center">
                <Icon name="snack" color="blue-gradient" size={48} />
                <Layout marginLeft={24} width={206}>
                  <Text size="h6" color="blue-600">
                    Snacks
                  </Text>
                </Layout>
              </Layout>
              <Text size="h6" color="gray-700" bold={false}>
                Water, amenities — will you have bottled water delivered regularly? Will you offer snacks and beverages
                and, if so, how will those deliveries be handled?
              </Text>
            </Layout>
            <Layout marginTop={36} flex align="flex-start" direction={xs ? 'column' : 'row'}>
              <Layout align="center">
                <Icon name="users" color="blue-gradient" size={48} />
                <Layout marginLeft={24} width={206}>
                  <Text size="h6" color="blue-600">
                    Accessibility
                  </Text>
                </Layout>
              </Layout>
              <Text size="h6" color="gray-700" bold={false}>
                Are all areas of the office accessible for those with disabilities? If not, what do you have to do to
                ensure accessibility?
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default BeforeTeamArrives;
