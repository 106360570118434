import React from 'react';

import { Clickable, Icon, Layout, Text } from 'ds';

interface Props {
  primaryLabel: string;
  secondaryLabel?: string;
  onRemove: VoidFunction;
}

const LocationPill: React.FC<Props> = ({ primaryLabel, secondaryLabel, onRemove }) => {
  return (
    <Layout
      paddingY={12}
      paddingX={24}
      borderRadius={100}
      background="linear-gradient(94.99deg, rgba(26, 178, 217, 0.1) 28.48%, rgba(0, 120, 255, 0.1) 141.74%)"
      align="center"
      maxWidth={254}
    >
      <Layout direction="row" align="center" overflow="hidden">
        <Text size="body-md" wrap={false}>
          {primaryLabel}
        </Text>
        <Layout marginLeft={8} />
        <Text size="body-md" color="gray-400" ellipsis>
          {secondaryLabel}
        </Text>
      </Layout>
      <Clickable onClick={onRemove} style={{ marginLeft: 16, display: 'inline-flex' }}>
        <Icon size="sm" color="gray-900" name="close" />
      </Clickable>
    </Layout>
  );
};

export default LocationPill;
