import React from 'react';

import { track } from 'lib/analytics';

import { CONTROL_DIMENSION } from './constants';
import Icon from '../Icon';
import Layout from '../Layout';

export interface Props {
  handleClick: VoidFunction;
  onKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

const Control: React.FC<Props> = ({ handleClick, onKeyDown }) => {
  return (
    <button
      onClick={() => {
        handleClick();
        track('Element Interacted', { type: 'button', name: 'carousel control' });
      }}
      className="Carousel-button"
      onKeyDown={onKeyDown}
    >
      <Layout
        __className="Carousel-control"
        width={CONTROL_DIMENSION}
        height={CONTROL_DIMENSION}
        borderRadius="circular"
        color="white"
        justify="center"
        align="center"
        __style={{ boxShadow: '2px 0px 22px rgba(87, 73, 109, 0.12)' }}
      >
        <Icon name="rightChevron" size="md" color="blue-500" />
      </Layout>
    </button>
  );
};

export default Control;
