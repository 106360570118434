import { useContext } from 'react';
import poweredByGoogle from '@codiwork/codi/lib/assets/powered-by-google-on-white-hdpi.png';

import { app } from 'context';
import { Layout } from 'ds';

import LocationResult from './LocationResult';
import { BaseLocationResult } from './types';

interface Props<T extends BaseLocationResult> {
  results: T[];
  onLocationSelect: (result: T) => void;
  isBottomSheet?: boolean;
  setSelectedResult?: (result: T) => void;
  searchValue?: string;
  focusedIndex: number;
  showGoogleAttribution: boolean;
}

const LocationResultsList = <T extends BaseLocationResult>({
  results,
  onLocationSelect,
  isBottomSheet,
  searchValue,
  focusedIndex,
  showGoogleAttribution
}: Props<T>) => {
  const { isSmallPhone } = useContext(app);

  return (
    <Layout position="relative" {...(results.length ? { paddingBottom: 16 } : { height: 0 })}>
      <ul
        style={{
          listStyleType: 'none',
          padding: isSmallPhone ? '4px 0' : '8px 0',
          margin: 0
        }}
      >
        {results.map((result, index) => (
          <li
            key={result.value}
            onClick={() => {
              onLocationSelect(result);
            }}
          >
            <LocationResult
              label={result.label}
              focused={isBottomSheet ? false : index === focusedIndex}
              isBottomSheet={isBottomSheet}
              searchValue={searchValue}
            />
          </li>
        ))}
      </ul>
      {showGoogleAttribution && searchValue && results.length > 0 && (
        <Layout justify="flex-end" {...(isBottomSheet ? { marginTop: 8, paddingRight: 24 } : { paddingRight: 8 })}>
          <Layout {...(isBottomSheet ? { width: 100 } : { width: 120 })}>
            <img src={poweredByGoogle} alt="Powered by Google" />
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default LocationResultsList;
