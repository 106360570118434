import React from 'react';

import Layout from './Layout';
import Text from './Text';

interface Props {
  count: number;
}

const BadgeCount: React.FC<Props> = ({ count }) => {
  return (
    <Layout
      color="red-700"
      borderWidth={1}
      borderColor="white"
      paddingY={2}
      paddingX={6}
      minWidth={16}
      align="center"
      justify="center"
      borderRadius={100}
    >
      <Text color="white" size="tag">
        {count}
      </Text>
    </Layout>
  );
};

export default BadgeCount;
