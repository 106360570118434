import React, { useContext } from 'react';
import { BLUE_500_HEX, BLUE_50_HEX, GRAY_50_HEX } from '@codiwork/codi';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

interface Props {
  rows: { name: string; key: string; codiValue: string; competitorValue: string }[];
  competitor: string;
}

const BORDER_STYLE = `1px solid ${GRAY_50_HEX}`;

const Table: React.FC<Props> = ({ rows, competitor }) => {
  const { xs, md, lg } = useContext(media);
  const { contentWidth, contentPaddingX } = useContext(app);
  const padding = lg ? 40 : md ? 32 : 24;

  return (
    <Layout
      position="relative"
      left={xs ? -contentPaddingX : undefined}
      overflowX="auto"
      paddingLeft={xs ? contentPaddingX : undefined}
      paddingRight={xs ? contentPaddingX : undefined}
      __style={{ width: xs ? contentWidth + 2 * contentPaddingX : undefined }}
    >
      <Layout __style={{ minWidth: xs ? 816 : undefined }} paddingRight={xs ? contentPaddingX : undefined}>
        <table
          style={{
            border: BORDER_STYLE,
            borderCollapse: 'separate',
            borderSpacing: 0,
            borderRadius: 12,
            overflow: 'hidden'
          }}
        >
          <thead>
            <tr style={{ background: BLUE_500_HEX }}>
              <th />
              <th style={{ padding }}>
                <Text size="h4" color="white" inline scale>
                  Codi
                </Text>
              </th>
              <th style={{ padding }}>
                <Text size="h4" color="white" inline scale>
                  {competitor}
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map(({ name, key, codiValue, competitorValue }, index) => {
              const isLastRow = index === rows.length - 1;
              const cellStyle = {
                padding,
                borderBottom: isLastRow ? undefined : BORDER_STYLE,
                verticalAlign: 'top'
              };

              return (
                <tr key={key}>
                  <td
                    style={{
                      ...cellStyle,
                      backgroundColor: BLUE_50_HEX,
                      width: lg ? 320 : md ? 232 : undefined
                    }}
                  >
                    <Text size="h5" color="gray-600" inline scale>
                      {name}
                    </Text>
                  </td>
                  <td
                    style={{
                      ...cellStyle,
                      borderBottom: isLastRow ? undefined : BORDER_STYLE
                    }}
                  >
                    <Text size="body1" inline scale>
                      {codiValue}
                    </Text>
                  </td>
                  <td
                    style={{
                      ...cellStyle,
                      borderLeft: BORDER_STYLE,
                      borderBottom: isLastRow ? undefined : BORDER_STYLE
                    }}
                  >
                    <Text size="body1" inline scale>
                      {competitorValue}
                    </Text>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Layout>
    </Layout>
  );
};

export default Table;
