import React, { useContext, useState } from 'react';

import Head from 'components/Head';

import { media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';

import Included from './Included';
import ShareDetailsModal from './ShareDetailsModal';
import Hero from '../CmsPage/Hero';

interface Props {}
const AnnualReport: React.FC<Props> = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { xs } = useContext(media);
  return (
    <>
      <Head
        title="2023 Workplace Trends Report"
        description="This report highlights how workplaces have evolved over the past year and details tips from world-class companies for managing a workspace during a recession."
      />
      <Layout {...(xs ? { paddingTop: 64 } : { paddingTop: 120 })}>
        <Hero
          name="ANNUAL REPORT"
          title='<span class="is-color-blue-500"><span class="Text is-color-blue-700">How to Approach Office Space</span> in 2023</span>'
          midtitle='<span class="is-color-blue-600">5 Recession-Proof Tips</span>'
          subtitle="A third year in a row, Codi delivers a thought-provoking piece about remote and hybrid work. If you’re wondering how to approach office space in 2023 in these ever-changing economic times, you’ve come to the right place. We’ve broken down our top recession-proof tips for choosing the right workspace for your needs."
          cta={{
            text: 'Download report',
            type: 'primary',
            size: 'md',
            href: undefined,
            iconName: 'download',
            iconStroke: 1.5,
            iconSize: 'md',
            iconPosition: 'left',
            onClick: () => setIsModalVisible(true)
          }}
          image={{
            src: 'https://assets.codi.com/public/Workplace-Report-2023-Codi_q6c-PTr1G.png?updatedAt=1681433994083',
            alt: 'Office'
          }}
          blob={{
            width: 764,
            svg: `
            <svg viewBox="0 0 727 763" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M665.19 692.334C746.947 612.475 735.202 475.911 696.302 195.243C657.402 -85.4248 330.293 1.33473 266.291 63.3563C202.29 125.378 -38.8729 312.819 5.98559 486.858C50.8441 660.898 282.43 686.267 399.448 733.781C516.465 781.295 583.433 772.193 665.19 692.334Z" fill="#D0E2FF"/>
            </svg>`,
            bottom: -240,
            right: -360
          }}
          image_xs={{
            no_shadow: true,
            src:
              'https://ik.imagekit.io/r2atwkj92wo/public/Codi_11965_EastWilliamsburg_Brooklyn_LightFilledLoft_7_RET_1_qCS_GhFW7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1654207439656',
            alt: 'Office',
            margin_top: 64,
            position: 'fullWidth'
          }}
        />
        <Included setIsModalVisible={setIsModalVisible} />
        <Layout paddingY={xs ? 80 : 60} justify="center" align="center">
          <Layout width={xs ? '100%' : 630}>
            <Layout justify="center" marginBottom={xs ? 32 : 40}>
              <Text size={xs ? 'h5' : 'h4'} scale={!xs} color="blue-600">
                Past Reports
              </Text>
            </Layout>
            <Layout direction="row" justify="space-between">
              <Text size="body1" semibold>
                Title
              </Text>
              <Text size="body1" semibold>
                Downloads
              </Text>
            </Layout>
            {!xs && <Layout marginTop={36} borderBottom />}
            <Layout direction="row" justify="space-between" align="center" paddingY={xs ? 38 : 36} borderBottom>
              <Text size="body2" color="gray-700">
                2022 Annual Report
              </Text>
              <Link
                href="https://codi-downloads.s3.amazonaws.com/2022_State+of+the+Workplace_WebOptimized.pdf"
                target="_blank"
              >
                <Layout direction="row" align="center">
                  <Text size="body2" color="blue-500" bold>
                    PDF
                  </Text>
                  <Layout marginLeft={8} />
                  <Icon size={24} name="download" color="blue-500" />
                </Layout>
              </Link>
            </Layout>
            <Layout direction="row" justify="space-between" align="center" paddingY={xs ? 38 : 36} borderBottom>
              <Text size="body2" color="gray-700">
                2021 Annual Report
              </Text>
              <Link
                href="https://codi-downloads.s3.amazonaws.com/State+of+The+Workplace+2021+by+Codi.pdf"
                target="_blank"
              >
                <Layout direction="row" align="center">
                  <Text size="body2" color="blue-500" bold>
                    PDF
                  </Text>
                  <Layout marginLeft={8} />
                  <Icon size={24} name="download" color="blue-500" />
                </Layout>
              </Link>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <ShareDetailsModal onClose={() => setIsModalVisible(false)} isVisible={isModalVisible} />
    </>
  );
};

export default AnnualReport;
