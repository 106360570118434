import React, { useContext } from 'react';

import { app, media } from 'context';
import { Animate, ButterImg, Button, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import Blob from './Blob';
import CustomImage from './CustomImage';
import Quotes from './Quotes';
import { ImageTextSection } from './types';

interface Props extends ImageTextSection {}

const ImageText: React.FC<Props> = ({ title, subtitle, image, image_xs = {}, blob, blob_xs, cta, reverse, quotes }) => {
  const mediaContext = useContext(media);
  const { xs, sm, md, lg } = mediaContext;
  const { maxWidth, width, contentWidth, contentPaddingX } = useContext(app);

  const widthToScale = maxWidth ? Math.min(maxWidth, width) : width;
  const scale1440 = widthToScale / 1440;
  const scale375 = width / 375;
  const imageXs = {
    src: image_xs.src?.length ? image_xs.src : image?.src,
    alt: image_xs.alt?.length ? image_xs.alt : image?.alt,
    margin_top: typeof image_xs.margin_top === 'number' ? image_xs.margin_top : 128,
    position: image_xs.position?.length ? image_xs.position : 'left',
    no_shadow: image_xs.no_shadow || false
  };
  const label = xs ? image_xs?.label || image?.label : image?.label;
  const paddingY = lg ? 96 : md ? 72 : sm ? 48 : 12;

  return (
    <>
      <Layout
        align="center"
        direction={xs ? 'column-reverse' : reverse ? 'row-reverse' : 'row'}
        __style={{
          paddingTop: typeof image?.margin_top === 'number' && !xs ? image.margin_top * scale1440 : paddingY,
          paddingBottom: typeof image?.margin_top === 'number' && !xs ? image.margin_top * scale1440 : paddingY
        }}
      >
        <Layout
          equalWidth={!xs}
          marginRight={!xs && !reverse ? 68 : undefined}
          marginLeft={!xs && reverse ? 68 : undefined}
          position="relative"
          __style={{ marginTop: xs ? imageXs.margin_top * scale375 : undefined }}
          justify={xs ? undefined : reverse ? 'flex-start' : 'flex-end'}
        >
          {image && image.width && !xs ? (
            <CustomImage {...image} width={image.width} />
          ) : quotes && quotes.length ? (
            <Quotes quotes={quotes} />
          ) : xs && imageXs.src && imageXs.alt ? (
            <Animate position="relative">
              <ButterImg
                src={imageXs.src}
                alt={imageXs.alt}
                width={800}
                style={{
                  borderTopLeftRadius: imageXs.position === 'center' || imageXs.position === 'right' ? 24 : 0,
                  borderBottomLeftRadius: imageXs.position === 'center' || imageXs.position === 'right' ? 24 : 0,
                  borderTopRightRadius: imageXs.position === 'center' || imageXs.position === 'left' ? 24 : 0,
                  borderBottomRightRadius: imageXs.position === 'center' || imageXs.position === 'left' ? 24 : 0,
                  boxShadow: imageXs.no_shadow ? undefined : '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
                  width: imageXs.position === 'center' ? undefined : contentWidth + contentPaddingX,
                  position: 'relative',
                  left:
                    imageXs.position === 'center'
                      ? undefined
                      : (contentPaddingX / 2) * (imageXs.position === 'right' ? 1 : -1)
                }}
              />
              {label ? (
                <span
                  style={{
                    position: 'absolute',
                    padding: '8px 12px',
                    left: reverse ? 12 : undefined,
                    right: reverse ? undefined : 12,
                    bottom: 24,
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderRadius: 8,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)'
                  }}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              ) : null}
            </Animate>
          ) : (
            image && (
              <Animate position="relative">
                <ButterImg
                  src={image.src}
                  alt={image.alt}
                  width={800}
                  style={{
                    borderRadius: 44,
                    boxShadow: '0 13px 23px 0 rgba(0, 0, 0, 0.05)',
                    width: xs ? contentWidth + contentPaddingX : 'auto',
                    maxHeight: xs ? undefined : 480,
                    maxWidth: xs ? undefined : '100%',
                    position: xs ? 'relative' : undefined,
                    left: xs ? (contentPaddingX / 2) * (reverse ? -1 : 1) : undefined
                  }}
                />
                {label ? (
                  <span
                    style={{
                      position: 'absolute',
                      padding: '8px 12px',
                      left: reverse ? undefined : 28,
                      right: reverse ? 28 : undefined,
                      bottom: 28,
                      display: 'inline-flex',
                      alignItems: 'center',
                      borderRadius: 8,
                      backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}
                    dangerouslySetInnerHTML={{ __html: label }}
                  />
                ) : null}
              </Animate>
            )
          )}
          {blob && !xs && <Blob {...blob} scale={scale1440} />}
          {blob_xs && xs && <Blob {...blob_xs} scale={scale375} />}
        </Layout>
        <Animate
          equalWidth={!xs}
          justify={reverse ? 'flex-end' : 'flex-start'}
          marginLeft={!xs && !reverse ? 68 : undefined}
          marginRight={!xs && reverse ? 68 : undefined}
        >
          <Layout maxWidth={xs ? undefined : 440}>
            <Layout marginBottom={lg ? 40 : 24}>
              <Text size="h4" scale={!xs}>
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
              </Text>
            </Layout>
            <Layout marginBottom={lg ? 40 : 24}>
              <Text size="h6" bold={false} scale>
                <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
              </Text>
            </Layout>
            <Button text={cta.text} type={cta.type} size={cta.size} href={OFFICE_REQUEST_PATH} fullWidth={xs} />
          </Layout>
        </Animate>
      </Layout>
    </>
  );
};

export default ImageText;
