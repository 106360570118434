import React, { useState } from 'react';

import { CONTENT_PADDING_X_XS, Icon, Layout, NavItem, Pressable, Text } from 'ds';

import MenuItem from './MenuItem';

interface Props {
  title: string;
  items: NavItem[];
}

const CollapsableSection: React.FC<Props> = ({ title, items }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Layout>
      <Pressable onPress={() => setIsExpanded(!isExpanded)} fullWidth>
        <Layout justify="space-between" align="center" paddingX={CONTENT_PADDING_X_XS} paddingY={36}>
          <Text size="body1" semibold>
            {title}
          </Text>
          <Layout transition="transform 150ms" display="inline-flex" transform={`rotate(${isExpanded ? 180 : 0}deg)`}>
            <Icon name="downChevron" size="sm" color="gray-900" />
          </Layout>
        </Layout>
      </Pressable>
      {isExpanded && (
        <Layout>
          {items.map(item => {
            return <MenuItem {...item} key={item.key} />;
          })}
        </Layout>
      )}
    </Layout>
  );
};

export default CollapsableSection;
