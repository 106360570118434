import React, { useContext } from 'react';

import { app, media } from 'context';
import { Layout, SwiperCarousel, Text } from 'ds';
import { Avatar } from 'ds';
import { SwiperSlide } from 'swiper/react';

const RESPONSIVE_CONFIG = {
  md: {
    slidesPerView: 1,
    spaceBetween: 0
  }
};

interface Props {
  fields: {
    title: string;
    quotes: {
      text: string;
      avatar: string;
      name: string;
      title: string;
    }[];
  };
}

const Quotes: React.FC<Props> = ({ fields }) => {
  const { sectionGutterY } = useContext(app);
  const { xs } = useContext(media);
  const { title, quotes } = fields;

  return (
    <Layout width="100%" justify="center" align="center" marginTop={sectionGutterY} direction="column">
      <Text size="h3" align="center" scale>
        {title}
      </Text>
      <Layout maxWidth={648} width="100%" marginTop={xs ? 24 : 64}>
        <SwiperCarousel config={RESPONSIVE_CONFIG}>
          {quotes.map((quote, idx) => {
            return (
              <SwiperSlide key={idx}>
                <Layout>
                  <Layout color="gray-50" padding={32} borderRadius={20}>
                    <Text size="h6" bold={false} scale>
                      &quot;{quote.text}&quot;
                    </Text>
                  </Layout>
                  <Layout align="center" marginTop={16}>
                    <Layout>
                      <Avatar size={64} avatarUrl={quote.avatar} />
                    </Layout>
                    <Layout marginLeft={16}>
                      <Text size="h6" scale>
                        {quote.name}
                      </Text>
                      <Text color="gray-600" size="body2" scale>
                        {quote.title}
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
              </SwiperSlide>
            );
          })}
        </SwiperCarousel>
      </Layout>
    </Layout>
  );
};

export default Quotes;
