import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

const GUTTER = 48;

interface Props {}

const SearchIntro: React.FC<Props> = () => {
  const { xs } = useContext(media);
  const { windowHeight } = useContext(app);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={48}
        >
          <Layout>
            <Text size="h6" bold={false} color="gray-700">
              Flex office options are the newest models. While growing, there aren’t nearly as many options in nearly as
              many cities. That said, the movement is growing quickly and it doesn’t hurt to inquire about spaces in
              your city even if it isn’t listed yet. Companies like Codi are largely based in San Francisco and NYC, but
              also Austin, Los Angeles, and Toronto.
              <br />
              <br />
              The flex model generally combines the simplicity and turnkey management provided by coworking with the
              privacy and customizability of traditional office space.
              <br />
              <br />
              There are a number of models currently being explored. Some enable a “drop-in” setup by which individuals
              or small teams working remotely can choose from a list of local options for short terms or on short
              notice. Others act as brokers with online marketplaces — introducing companies in need of space with
              pre-vetted, traditional landlords who are more open to flexible terms.
            </Text>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="sky-blue-office_31h6tvDC8.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT,
                transform: 'matrix(-1, 0, 0, 1, 0, 0)'
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default SearchIntro;
