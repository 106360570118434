import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, Layout, Text } from 'ds';
import { PLAYBOOK_PATH } from 'routes';

import PlaybookContentContainer from './PlaybookContentContainer';
import PlaybookStepButton from './PlaybookStepButton';

interface Props {
  title: string;
  activeStep: number;
}

const PlaybookStepPage: React.FC<Props> = ({ title, activeStep }) => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout>
        <Layout flex>
          <PlaybookStepButton
            path={`${PLAYBOOK_PATH}/review-your-options`}
            isSelected={activeStep === 1}
            step="01"
            key="01"
          />
          <Layout marginLeft={16}>
            <PlaybookStepButton path={`${PLAYBOOK_PATH}/the-search`} isSelected={activeStep === 2} step="02" />
          </Layout>
          <Layout marginLeft={16}>
            <PlaybookStepButton
              path={`${PLAYBOOK_PATH}/negotiations-agreements`}
              isSelected={activeStep === 3}
              step="03"
            />
          </Layout>
          <Layout marginLeft={16}>
            <PlaybookStepButton path={`${PLAYBOOK_PATH}/moving-in`} isSelected={activeStep === 4} step="04" />
          </Layout>
          <Layout marginLeft={16}>
            <PlaybookStepButton path={`${PLAYBOOK_PATH}/handy-tools`} isSelected={activeStep === 5} step="..." />
          </Layout>
        </Layout>
        <Animate marginTop={24} maxWidth={788} minHeight={192} animation="fade-in-from-left">
          <Text size="h2" color="white" scale={xs}>
            {title}
          </Text>
        </Animate>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default PlaybookStepPage;
