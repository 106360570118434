import React, { useContext, useEffect, useState } from 'react';
import { REGION_ABBREVIATION_TO_NAME } from '@codiwork/codi';
import chunk from 'lodash/chunk';

import Head from 'components/Head';

import { media } from 'context';
import { Layout, Link, Text } from 'ds';
import { replaceValues } from 'ds/utils';
import { getComparisons } from 'lib/butter';
import { Comparison } from 'lib/butter/types';
import { BLOG_PATH, COMPARE_PATH, LISTING_PATH } from 'routes/paths';
import { selectCmsPages, selectDynamicCmsPages } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

import { INDEXED_PAGES } from './constants';
import { getSitemap } from './requests';
import { SitemapResponse } from './requests/types';
import { getPageTitle } from './utils';
import { PLAYBOOK_META } from '../Playbook/meta';
import { COMPARE_DETAIL_PREFIX } from '../constants';

type Country = keyof typeof REGION_ABBREVIATION_TO_NAME;

const regionAbbreviation = ({ country, region }: { country: string; region: string }) => {
  const countryMatch = REGION_ABBREVIATION_TO_NAME[country as Country];
  return countryMatch ? countryMatch[region as keyof typeof countryMatch] || region : region;
};

const Sitemap: React.FC = () => {
  const { xs, sm, md, lg, xl } = useContext(media);
  const [sitemapData, setSitemapData] = useState<SitemapResponse>();
  const [comparisons, setComparisons] = useState<Comparison[]>([]);
  const pages = useAppSelector(selectCmsPages);
  const dynamicCmsPages = useAppSelector(selectDynamicCmsPages);

  const columnWidth = xl ? '33%' : lg ? '25%' : md ? '50%' : '100%';
  const competitors = comparisons.filter(c => c.name !== 'Codi');
  const comparePage = pages.find(p => p.slug === 'compare');
  const compareDetailPage = pages.find(p => p.slug === 'compare-detail');

  useEffect(() => {
    Promise.all([getSitemap(), getComparisons()]).then(([{ data }, comparisons]) => {
      setSitemapData(data);
      setComparisons(comparisons);
    });
  }, []);

  if (!sitemapData || !compareDetailPage) return null;

  const { countries, blog_posts, markets } = sitemapData;

  const indexedPageChunks = chunk(
    [...INDEXED_PAGES, ...dynamicCmsPages.map(p => ({ href: p.fields.path, title: p.fields.seo.title }))],
    5
  );

  return (
    <>
      <Head
        title="Sitemap"
        description="The Codi sitemap includes links to important pages as well as our most popular office spaces arranged by city."
      />
      <Layout marginTop={xs ? 72 : sm ? 76 : 96}>
        <Text tag="h1" size="h3">
          Codi Sitemap
        </Text>
        <Layout marginTop={48} wrap>
          {indexedPageChunks.map((routes, index) => (
            <Layout width={columnWidth} key={`${routes[0].href}${index}`} flexGrow={0}>
              {routes.map((route, index2) => {
                const title = route.title || getPageTitle({ path: route.href, pages });

                return (
                  <Layout marginBottom={12} key={`${title}${index2}`}>
                    <Link href={route.href} display="block">
                      <Text size="link" ellipsis>
                        {title}
                      </Text>
                    </Link>
                  </Layout>
                );
              })}
            </Layout>
          ))}
        </Layout>
      </Layout>
      <Layout marginTop={60}>
        <Layout marginBottom={24}>
          <Text tag="h2" size="h4">
            Browse offices in
          </Text>
        </Layout>
        {markets.map(market => (
          <React.Fragment key={market.name}>
            <Layout marginBottom={24}>
              <Link href={market.url}>
                <Text tag="h3" size="h5">
                  {market.name}
                </Text>
              </Link>
            </Layout>
            <Layout direction="row" wrap>
              {market.submarkets.map(submarket => (
                <Layout width={xs ? '100%' : sm ? '50%' : '25%'} key={submarket.name}>
                  <Layout marginBottom={24}>
                    <Layout marginBottom={24}>
                      <Link href={submarket.url}>
                        <Text tag="h4" size="body1" semibold>
                          {submarket.name}
                        </Text>
                      </Link>
                    </Layout>
                    {submarket.neighborhoods.map(neighborhood => (
                      <Layout marginBottom={12} marginLeft={24} key={neighborhood.name}>
                        <Link href={neighborhood.url}>
                          <Text tag="h5" size="link" semibold>
                            {neighborhood.name}
                          </Text>
                        </Link>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
              ))}
            </Layout>
          </React.Fragment>
        ))}
      </Layout>
      <Layout marginTop={60}>
        <Layout marginBottom={24}>
          <Text tag="h2" size="h4">
            Codi office spaces
          </Text>
        </Layout>
        {countries.map(country => (
          <React.Fragment key={country.name}>
            <Layout marginBottom={24}>
              <Text tag="h3" size="h5">
                {country.name}
              </Text>
            </Layout>
            {country.regions.map(region => (
              <Layout marginBottom={48} key={region.name}>
                <Layout marginBottom={24}>
                  <Text tag="h4" size="h6">
                    {regionAbbreviation({
                      country: country.code,
                      region: region.name
                    })}
                  </Text>
                </Layout>
                <Layout direction="row" wrap>
                  {region.cities.map(city => (
                    <Layout width={xs ? '100%' : sm ? '50%' : '25%'} key={city.name}>
                      <Layout marginBottom={24}>
                        <Layout marginBottom={24}>
                          {city.path ? (
                            <Link href={city.path} search={city.search}>
                              <Text tag="h5" size="body1" semibold>
                                {city.name}
                              </Text>
                            </Link>
                          ) : (
                            <Layout marginBottom={24}>
                              <Text tag="h5" size="body1" semibold>
                                {city.name}
                              </Text>
                            </Layout>
                          )}
                        </Layout>
                        {city.workspaces.map(workspace => (
                          <Layout marginBottom={12} marginLeft={24} key={workspace.slug}>
                            <Link href={`${LISTING_PATH}/${workspace.slug}`}>
                              <Text size="link" semibold>
                                {workspace.name}
                              </Text>
                            </Link>
                          </Layout>
                        ))}
                      </Layout>
                    </Layout>
                  ))}
                </Layout>
              </Layout>
            ))}
          </React.Fragment>
        ))}
      </Layout>
      <Layout marginTop={72}>
        <Text tag="h2" size="h4">
          Blog
        </Text>
        <Layout direction="column" marginTop={24}>
          <Layout marginBottom={12}>
            <Link href={BLOG_PATH}>All blog posts</Link>
          </Layout>
          {blog_posts.map(post => (
            <Layout marginBottom={12} key={post.slug}>
              <Link href={`${BLOG_PATH}/${post.slug}`}>
                <Text size="link">{post.title}</Text>
              </Link>
            </Layout>
          ))}
        </Layout>
      </Layout>
      <Layout marginTop={72}>
        <Text tag="h2" size="h4">
          Playbook
        </Text>
        <Layout direction="column" marginTop={24}>
          {Object.keys(PLAYBOOK_META).map(path => (
            <Layout marginBottom={12} key={path}>
              <Link href={path}>
                <Text size="link">{PLAYBOOK_META[path].title}</Text>
              </Link>
            </Layout>
          ))}
        </Layout>
      </Layout>
      <Layout marginTop={72}>
        <Text tag="h2" size="h4">
          Compare office space providers
        </Text>
        <Layout marginTop={24}>
          <Layout marginBottom={12}>
            <Link href={COMPARE_PATH}>
              <Text size="link">{comparePage?.fields.seo.title}</Text>
            </Link>
          </Layout>
          {chunk(competitors, 5).map(competitors => (
            <Layout width={columnWidth} key={competitors[0].name}>
              {competitors.map(competitor => (
                <Layout marginBottom={12} key={competitor.name}>
                  <Link href={`${COMPARE_PATH}/${COMPARE_DETAIL_PREFIX}${competitor.name}`}>
                    <Text size="link">
                      {competitor.seo_title ||
                        replaceValues(compareDetailPage?.fields.seo.title, [
                          { value: competitor.name, variable: 'competitor' }
                        ])}
                    </Text>
                  </Link>
                </Layout>
              ))}
            </Layout>
          ))}
        </Layout>
      </Layout>
    </>
  );
};

export default Sitemap;
