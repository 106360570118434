import React, { CSSProperties, MouseEvent, RefObject } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Color, IconName, IconSize, IconStroke } from '@codiwork/codi';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import { getBaseUrl } from 'helpers/environment';

import { Size as TextSize } from 'ds/Text';
import { track } from 'lib/analytics';

import Icon from './Icon';
import Layout from './Layout';
import { LinkProps } from './Link';
import Spinner from './Spinner';
import Text from './Text';
import { DS_CLASS_NAME } from './constants';
import { ButtonBorderRadius, ButtonColor, ButtonSize, SpinnerColor } from './types';
import { HASH_LINK_REGEXP, bgColorClass, extractText, hoverBgColorClass } from './utils';

export interface Props extends Partial<LinkProps> {
  /** Use <span> if text is JSX */
  text?: string | JSX.Element;
  color: ButtonColor;
  hoverColor: ButtonColor;
  activeColor: ButtonColor;
  disabledColor?: ButtonColor;
  activeBorderColor?: ButtonColor;
  focusBorderColor?: ButtonColor;
  hasShadow?: boolean;
  scaleText?: boolean;
  textOpacity?: number;
  textColor: Color | 'blue-gradient';
  /** Use only if absolutely necessary */
  textSize?: TextSize;
  size?: ButtonSize | 'pill-sm' | 'pill-md' | 'joinCircle';
  height?: number;
  paddingX?: number;
  paddingY?: number;
  iconGutter?: number;
  fullWidth?: boolean;
  spinnerColor?: SpinnerColor;
  onClick?: (event: ButtonOnClickEvent | LinkOnClickEvent) => void;
  disabled?: boolean;
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  iconSize?: IconSize;
  iconStroke?: IconStroke;
  iconColor?: Color | 'blue-gradient';
  showSpinner?: boolean;
  semibold?: boolean;
  tabIndex?: number;
  htmlType?: 'button' | 'reset' | 'submit';
  borderWidth?: 0 | 1 | 2;
  borderTopWidth?: 0 | 1 | 2;
  borderBottomWidth?: 0 | 1 | 2;
  borderLeftWidth?: 0 | 1 | 2;
  borderRightWidth?: 0 | 1 | 2;
  borderColor?: Color | 'transparent';
  borderRadius: ButtonBorderRadius;
  borderBottomLeftRadius?: ButtonBorderRadius | 0;
  borderBottomRightRadius?: ButtonBorderRadius | 0;
  borderTopLeftRadius?: ButtonBorderRadius | 0;
  borderTopRightRadius?: ButtonBorderRadius | 0;
  __ref?: RefObject<HTMLButtonElement>;
  search?: string;
  background?: string;
  /** Used for hash links */
  scrollYOffset?: number;
}

export type ButtonOnClickEvent = MouseEvent<HTMLButtonElement, MouseEvent>;
export type LinkOnClickEvent = MouseEvent<HTMLAnchorElement, MouseEvent>;

const ButtonPrivate: React.FC<Props> = ({
  text,
  textOpacity = 1,
  fullWidth,
  href,
  icon,
  size,
  height,
  paddingX,
  paddingY,
  iconGutter,
  iconPosition = 'right',
  iconSize,
  iconStroke = 2,
  iconColor,
  showSpinner,
  tabIndex,
  textSize,
  hasShadow,
  color,
  hoverColor,
  activeColor,
  activeBorderColor,
  focusBorderColor,
  textColor,
  scaleText,
  spinnerColor,
  borderWidth,
  borderTopWidth,
  borderBottomWidth,
  borderLeftWidth,
  borderRightWidth,
  borderColor,
  borderRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  semibold = true,
  disabledColor,
  htmlType,
  scrollYOffset = -24,
  __ref,
  context,
  value,
  target,
  search,
  state,
  background,
  ...props
}) => {
  const disabled = props.disabled || showSpinner;
  const spinnerSize = size && ['xs', 'xxs', 'pill'].includes(size) ? 'xs' : 'sm';
  const { pathname } = useLocation();
  const style: CSSProperties = {};

  const handleScroll = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = scrollYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  if (background) style.background = background;
  if (height) style.height = height;
  if (paddingX) {
    style.paddingLeft = paddingX;
    style.paddingRight = paddingX;
  }
  if (paddingY) {
    style.paddingTop = paddingY;
    style.paddingBottom = paddingY;
  }

  const innerElements = (
    <Layout justify="center" direction={icon && iconPosition === 'left' ? 'row-reverse' : 'row'} align="center">
      {showSpinner && (
        <span className={classNames('Button-spinnerContainer', `is-size-${spinnerSize}`)}>
          <span className={classNames('Button-spinner', `is-size-${spinnerSize}`)}>
            <Spinner size={spinnerSize} color={spinnerColor} />
          </span>
        </span>
      )}
      {textSize ? (
        <span className="Button-buttonTextContainer" style={{ opacity: textOpacity, justifyContent: 'center' }}>
          <Text color={textColor} scale={scaleText} size={textSize} inline semibold={semibold}>
            {text}
          </Text>
        </span>
      ) : (
        <span
          className={classNames('Button-buttonText', `is-color-${textColor}`)}
          style={{ opacity: textOpacity, justifyContent: 'center' }}
        >
          {text}
        </span>
      )}
      {icon && iconSize && (
        <span
          className={classNames('Button-iconContainer', `is-${iconPosition}`)}
          style={{
            ...(iconGutter
              ? iconPosition === 'left'
                ? { marginRight: iconGutter }
                : { marginLeft: iconGutter }
              : undefined)
          }}
        >
          <Icon name={icon} color={iconColor || textColor} size={iconSize} stroke={iconStroke} />
        </span>
      )}
    </Layout>
  );

  const className = classNames(
    'Button',
    `is-size-${size}`,
    DS_CLASS_NAME,
    ...(disabled
      ? [`bg-color-${disabledColor}`]
      : [bgColorClass(color), hoverBgColorClass(hoverColor), `active-bg-color-${activeColor}`]),
    borderRadius ? `is-border-radius-${borderRadius}` : undefined,
    borderBottomLeftRadius ? `is-border-bottom-left-radius-${borderBottomLeftRadius}` : undefined,
    borderBottomRightRadius ? `is-border-bottom-right-radius-${borderBottomRightRadius}` : undefined,
    borderTopLeftRadius ? `is-border-top-left-radius-${borderTopLeftRadius}` : undefined,
    borderTopRightRadius ? `is-border-top-right-radius-${borderTopRightRadius}` : undefined,
    borderWidth !== undefined ? `is-border-width-${borderWidth}` : undefined,
    borderTopWidth !== undefined ? `is-border-top-width-${borderTopWidth}` : undefined,
    borderBottomWidth !== undefined ? `is-border-bottom-width-${borderBottomWidth}` : undefined,
    borderLeftWidth !== undefined ? `is-border-left-width-${borderLeftWidth}` : undefined,
    borderRightWidth !== undefined ? `is-border-right-width-${borderRightWidth}` : undefined,
    borderColor ? `is-border-color-${borderColor}` : undefined,
    activeBorderColor ? `is-active-border-color-${activeBorderColor}` : undefined,
    focusBorderColor ? `is-focus-border-color-${focusBorderColor}` : undefined,
    `icon-position-${iconPosition}`,
    {
      'is-fullWidth': fullWidth,
      'is-disabled': disabled,
      'has-spinner': showSpinner,
      'has-shadow': hasShadow,
      'is-semibold': semibold,
      'has-icon': icon
    }
  );

  const onClick = disabled
    ? (e: any) => e.preventDefault()
    : (e: any) => {
        props.onClick && props.onClick(e);
        track('Element Interacted', { context, value, name: extractText(text), type: 'button' });
      };

  const isRouterLink = href && href[0] === '/';

  if (href && !isRouterLink) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={className}
        onClick={onClick}
        href={disabled ? '#' : href}
        target={disabled ? undefined : target || '_blank'}
        rel="noopener noreferrer"
        tabIndex={tabIndex}
        style={style}
      >
        {innerElements}
      </a>
    );
  } else if (href) {
    const isHashLink = HASH_LINK_REGEXP.test(href);
    const LinkComponent = isHashLink ? HashLink : Link;

    return (
      // @ts-expect-error
      <LinkComponent
        className={className}
        to={
          disabled
            ? { pathname: '#' }
            : isHashLink
            ? href
            : process.env.STORYBOOK
            ? (location: Location) => ({ ...location, path: getBaseUrl() + href })
            : { pathname: href, state: { prevPath: pathname, ...state }, search }
        }
        onClick={onClick}
        tabIndex={tabIndex}
        target={target}
        style={style}
        {...(isHashLink ? { scroll: handleScroll } : {})}
      >
        {innerElements}
      </LinkComponent>
    );
  } else {
    return (
      <button
        ref={__ref}
        className={className}
        onClick={onClick}
        disabled={disabled || showSpinner}
        tabIndex={tabIndex}
        type={htmlType}
        style={style}
      >
        {innerElements}
      </button>
    );
  }
};

export default ButtonPrivate;
