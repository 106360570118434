import React, { useContext } from 'react';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Button, Layout } from 'ds';
import { formatUsd } from 'helpers';
import { OFFICE_REQUEST_PATH } from 'routes';

import Hero from './Hero';
import AllInclusive from '../Home/AllInclusive';
import Browse from '../Home/Browse';
import CTAs from '../Home/CTAs';
import CaseStudy from '../Home/CaseStudy';
import Compare from '../Home/Compare';
import Insights from '../Home/Insights';
import LogosMarquee from '../Home/LogosMarquee';
import Press from '../Home/Press';
import PriceExplanation from '../Home/PriceExplanation';
import Solutions from '../Home/Solutions';
import Testimonials from '../Home/Testimonials';
import ValueProps from '../Home/ValueProps';
import { HOME_PAGE_MAX_WIDTH } from '../Home/constants';

import { ReactComponent as CirclesBottom } from '../Home/circles-bottom.svg';
import { ReactComponent as CirclesLeft } from '../Home/circles-left.svg';

interface Props {}

const MAX_AMOUNT = 2500;

const Home: React.FC<Props> = () => {
  const { xs, sm, isMobile } = useContext(media);
  const { contentPaddingX, windowWidth, contentWidth } = useContext(app);

  let paddingX: number | undefined;
  let usingMaxWidth = false;
  if (windowWidth - contentPaddingX * 2 > HOME_PAGE_MAX_WIDTH) {
    paddingX = (windowWidth - HOME_PAGE_MAX_WIDTH) / 2;
    usingMaxWidth = true;
  } else {
    paddingX = contentPaddingX;
  }

  const containerProps = {
    maxWidth: usingMaxWidth ? HOME_PAGE_MAX_WIDTH : contentWidth + contentPaddingX * 2,
    flexGrow: 1,
    paddingX: usingMaxWidth ? undefined : contentPaddingX
  };

  return (
    <>
      <Layout justify="center" color="blue-25" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Head
            title="WeWork Relief Fund | New York and San Francisco | Codi"
            description={`Codi provides private, furnished, and fully serviced offices. WeWork members can apply to get priority access and up to ${formatUsd(
              MAX_AMOUNT
            )} off their first month's rent with Codi.`}
            omitTitleSuffix
          />
          <Hero />
          <Layout marginTop={isMobile ? 96 : 100}>
            <Compare />
          </Layout>
          <Layout marginTop={isMobile ? 24 : 46} paddingX={contentPaddingX} justify="center">
            <Layout {...(xs ? { flexGrow: 1 } : { width: 242 })}>
              <Button
                text="Request an office"
                size="md"
                type="primary"
                textSize="body-sm"
                fullWidth
                href={OFFICE_REQUEST_PATH}
              />
            </Layout>
          </Layout>
          <Layout marginTop={isMobile ? 128 : 278}>
            <ValueProps />
          </Layout>
          <Layout marginTop={isMobile ? 128 : 350}>
            <Testimonials />
          </Layout>
        </Layout>
        {!isMobile && <CirclesLeft style={{ position: 'absolute', left: 0, top: 1148 }} />}
      </Layout>
      <Layout color="blue-25" paddingTop={xs ? 128 : 236}>
        <LogosMarquee />
      </Layout>
      <Layout justify="center" color="blue-25" position="relative">
        <Layout {...(xs ? {} : containerProps)}>
          <Layout {...(xs ? { position: 'relative' } : {})} marginTop={128}>
            <HeroImage
              imageKey="home-page-kearny_F7JjKdo4p.jpeg"
              imageKitFolder="public"
              height={xs ? 244 : sm ? 380 : 436}
              {...(xs ? { imgWidth: 1600, imgHeight: 2000 } : { paddingX: paddingX, imgWidth: 3000, imgHeight: 1200 })}
              accountForPaddingX={!xs}
              parallax={!isMobile}
            />
          </Layout>
          <Layout {...(xs ? { paddingX: contentPaddingX, marginTop: 64 } : { marginTop: 264 })}>
            <PriceExplanation />
          </Layout>
          <Layout marginTop={xs ? 88 : 264}>
            <AllInclusive />
          </Layout>
          <Layout marginTop={xs ? 100 : 264}>
            <Browse />
          </Layout>
          <Layout marginTop={isMobile ? 128 : 160}>
            <Press />
          </Layout>
          <Layout
            {...(xs
              ? { paddingX: contentPaddingX, marginTop: 64 }
              : sm
              ? { marginTop: 100 }
              : {
                  marginTop: 160,
                  paddingBottom: 160
                })}
            position="relative"
            zIndex={1}
          >
            <CaseStudy />
          </Layout>
        </Layout>

        {!isMobile && <CirclesBottom style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 0 }} />}
      </Layout>
      <Layout
        {...(isMobile
          ? { paddingX: contentPaddingX, color: 'blue-25', paddingTop: 64 }
          : sm
          ? { color: 'blue-25', paddingTop: 64 }
          : { color: 'white', marginTop: 132 })}
        justify="center"
      >
        <Layout {...(xs ? {} : containerProps)}>
          <Solutions />
        </Layout>
      </Layout>
      <Layout
        {...(isMobile
          ? { paddingX: contentPaddingX, color: 'blue-25', paddingY: 64 }
          : { color: 'blue-50', marginTop: 92, paddingY: 100 })}
        justify="center"
      >
        <Layout {...(xs ? {} : containerProps)}>
          <Insights />
          <Layout marginTop={isMobile ? 64 : 100}>
            <CTAs />
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Home;
