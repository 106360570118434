import React from 'react';

import { Button, Icon, Layout, Text } from 'ds';
import { PAYMENTS_PATH } from 'routes';
import { actions as chatbotActions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

interface Props extends ChatbotIntentProps {}

const Billing: React.FC<Props> = ({ chatbotInteraction }) => {
  const { llm_response } = chatbotInteraction;
  const dispatch = useAppDispatch();

  return (
    <Layout paddingTop={12}>
      <Layout padding={12} color="white" marginLeft={52} borderRadius={16}>
        <Text size="body-sm">{llm_response}</Text>
      </Layout>
      <Layout marginTop={16} color="white" borderRadius={16} direction="column">
        <Layout
          borderTopRightRadius={16}
          borderTopLeftRadius={16}
          height={112}
          flexGrow={1}
          direction="row"
          justify="space-evenly"
          align="center"
          paddingX={48}
          color="blue-400"
        >
          <Icon name="creditCard" size={64} color="blue-200" />
          <Icon name="dollarSign" size={64} color="blue-200" />
          <Icon name="payout" size={64} color="blue-200" />
        </Layout>
        <Layout padding={16}>
          <Button
            type="secondary"
            size="xs"
            text="View payments"
            fullWidth
            icon="externalLink"
            iconSize="xs"
            href={`${PAYMENTS_PATH}`}
            onClick={() => dispatch(chatbotActions.setIsVisible(false))}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Billing;
