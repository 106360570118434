import React, { useContext } from 'react';

import { app, media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const DecisionFactors: React.FC<Props> = () => {
  const { xs, lg } = useContext(media);
  const { contentWidth } = useContext(app);

  return (
    <PlaybookContentContainer>
      <Layout {...(lg ? { flex: true, paddingTop: 96 } : {})}>
        <Layout {...(lg ? { maxWidth: 332 } : xs ? { maxWidth: contentWidth } : {})}>
          <Layout>
            <Text size="h4" color="blue-gradient">
              Factors in Your Decision
            </Text>
          </Layout>
          <Layout marginTop={24}>
            <Text size="h6" color="white" bold={false}>
              No matter which option you choose, there are a number of decisions you’ll need to make before you get
              started on your search.
            </Text>
          </Layout>
          <Layout marginTop={24}>
            <Text size="h6" color="white" bold={false}>
              Even if you choose to go with a coworking environment, there are still things you might need to buy on
              your own to make your team comfortable and productive. Remember, with coworking, what you see is what you
              get.
            </Text>
          </Layout>
        </Layout>
        <Layout
          {...(lg
            ? { marginLeft: 40 }
            : xs
            ? { marginTop: 24, width: contentWidth, direction: 'column' }
            : { marginTop: 40 })}
          flex
        >
          <Layout
            padding={36}
            borderRadius={12}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            color="white"
            equalWidth
          >
            <Layout justify="center">
              <Icon size={48} name="sort" color="blue-600" />
            </Layout>
            <Layout marginTop={24}>
              <Text size="h6" color="blue-600" align="center">
                What are your priorities?
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <Text size="body2" color="gray-700">
                The space you ultimately select should align with your business priorities, in order of importance:
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <ul>
                <li>
                  <Text size="body2" color="gray-700">
                    Uniqueness/culture
                  </Text>
                </li>
                <li>
                  <Text size="body2" color="gray-700">
                    Location/commutability
                  </Text>
                </li>
                <li>
                  <Text size="body2" color="gray-700">
                    Arranged for quiet, focused work, or prioritizing team collaboration
                  </Text>
                </li>
                <li>
                  <Text size="body2" color="gray-700">
                    Privacy/security
                  </Text>
                </li>
              </ul>
            </Layout>
          </Layout>
          <Layout
            padding={36}
            marginLeft={xs ? undefined : 24}
            marginTop={xs ? 24 : undefined}
            borderRadius={12}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            color="white"
            equalWidth
          >
            <Layout justify="center">
              <Icon size={48} name="buildingNew" color="blue-600" />
            </Layout>
            <Layout marginTop={24}>
              <Text size="h6" color="blue-600" align="center">
                What is your rental budget?
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <Text size="body2" color="gray-700">
                Obviously, the amount that you’re willing or able to spend will determine the kind of office space you
                can have.
              </Text>
            </Layout>
          </Layout>
          <Layout
            padding={36}
            marginLeft={xs ? undefined : 24}
            marginTop={xs ? 24 : undefined}
            borderRadius={12}
            boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
            color="white"
            minWidth={200}
            equalWidth
          >
            <Layout justify="center">
              <Icon size={48} name="ruler" color="blue-600" />
            </Layout>
            <Layout marginTop={24}>
              <Text size="h6" color="blue-600" align="center">
                What is your CapEx budget?
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <Text size="body2" color="gray-700">
                A Capital Expenditure Budget is the amount of cash your organization will use to fund projects and/or
                long‐term assets.
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <Text size="body2" color="gray-700">
                A CapEx budget will be vital if you choose to commit to a traditional space — the space could be quite
                raw when you take it over, or still have remaining features from the previous tenant. It’s very likely
                that you’ll need to make some changes.
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </PlaybookContentContainer>
  );
};

export default DecisionFactors;
