import React, { useContext, useState } from 'react';
import { Color } from '@codiwork/codi';
import { useThrottledCallback, useWindowScroll } from 'beautiful-react-hooks';

import { app } from 'context';
import Layout from 'ds/Layout';

import { APP_PAGE_NAV_BAR_HEIGHT, APP_PAGE_TITLE_HEIGHT_MOBILE } from './constants';

interface Props {
  Initial: JSX.Element;
  Transitioned: JSX.Element;
  color?: Color;
}

const MobileTransitionNavBar: React.FC<Props> = ({ Initial, Transitioned, color = 'white' }) => {
  const [navBarOpacity, setNavBarOpacity] = useState<number>(0);
  const { appPaddingX } = useContext(app);

  const onWindowScroll = useWindowScroll();

  onWindowScroll(
    useThrottledCallback(_event => {
      const opacity = window.scrollY / APP_PAGE_NAV_BAR_HEIGHT;

      setNavBarOpacity(Math.min(opacity, 1));
    })
  );

  return (
    <Layout position="sticky" top={0} color={color} zIndex={100} height={APP_PAGE_TITLE_HEIGHT_MOBILE} align="center">
      <Layout
        opacity={1 - navBarOpacity}
        justify="space-between"
        position="absolute"
        width="100%"
        paddingX={appPaddingX}
        align="center"
        zIndex={navBarOpacity > 0.5 ? -1 : 1}
      >
        {Initial}
      </Layout>
      <Layout opacity={navBarOpacity} justify="space-between" paddingX={appPaddingX} align="center" flexGrow={1}>
        {Transitioned}
      </Layout>
    </Layout>
  );
};

export default MobileTransitionNavBar;
