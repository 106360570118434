import React from 'react';
import { titleize } from '@codiwork/codi';

import {
  CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR,
  CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR,
  Icon,
  Layout,
  PillButton,
  StatusDisplay,
  Text
} from 'ds';
import { CUSTOMER_SUPPORT_PATH } from 'routes';
import { SupportTicket } from 'ux/Customer/Support/types';

interface Props {
  supportTicket: SupportTicket;
}

const ChatbotViewSupportTicket: React.FC<Props> = ({ supportTicket: { title, description, id, status } }) => {
  return (
    <Layout color="white" borderRadius={16}>
      <Layout align="center" paddingX={16} paddingY={20} gap={8}>
        <Layout color="blue-500" borderRadius="circular" width={24} height={24} align="center" justify="center">
          <Icon name="checkmark" size={12} color="white" />
        </Layout>
        <Text size="body-md" semibold>
          Ticket submitted
        </Text>
      </Layout>
      <Layout borderTop padding={16}>
        <Layout align="flex-end">
          <Layout>
            <Text size="body-md" maxLines={2} semibold>
              {title}
            </Text>
            <Layout marginTop={8}>
              <Text size="body-sm" maxLines={2} color="gray-400">
                {description}
              </Text>
            </Layout>
            <Layout marginTop={8}>
              <Text size="body-xs" color="gray-400">
                #{id}
              </Text>
            </Layout>
          </Layout>
          <StatusDisplay
            color={CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR[status]}
            dotColor={CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR[status]}
            textColor={CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR[status]}
            text={titleize(status)}
          />
        </Layout>
        <Layout marginTop={12}>
          <PillButton
            type="secondary"
            size="md"
            icon="externalLink"
            text="View details"
            href={`${CUSTOMER_SUPPORT_PATH}/${id}`}
            fullWidth
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ChatbotViewSupportTicket;
