import React, { PropsWithChildren, useContext } from 'react';

import { app, media } from 'context';
import Layout from 'ds/Layout';

import AppPageNavBar from './AppPageNavBar';
import Content from './Content';
import CustomerTableLayout from './CustomerTableLayout';
import SecondaryMenu from './SecondaryMenu';
import { CommonPageLayoutProps, PageTypeProps } from './types';

type Props = CommonPageLayoutProps & PageTypeProps;

const PageLayout: React.FC<PropsWithChildren<Props>> = ({ children, overflowX, color, ...props }) => {
  const { xs, sm, md, lg, isMobile } = useContext(media);
  const { overflowY } = useContext(app);

  switch (props.type) {
    case 'secondary':
    case 'secondaryObject':
      return (
        <>
          {(md || lg) && <SecondaryMenu {...props.secondaryMenuProps} />}
          <Layout flexGrow={1} overflowX={overflowX} color={color}>
            <AppPageNavBar color={color} {...props} />
            <Content {...props}>{children}</Content>
          </Layout>
        </>
      );
    case 'table':
      return xs || sm ? (
        <Layout flexGrow={1} overflowX={overflowX}>
          <AppPageNavBar color={color} {...props} />
          {children}
        </Layout>
      ) : (
        <>
          {props.secondaryMenuProps && <SecondaryMenu {...props.secondaryMenuProps} />}
          <Layout flexGrow={1} overflowX={overflowX} color={color}>
            {children}
          </Layout>
        </>
      );
    case 'customerTable':
      return <CustomerTableLayout {...props}>{children}</CustomerTableLayout>;
    default:
      return (
        <Layout
          minWidth={0}
          flexGrow={1}
          overflowX={overflowX}
          overflowY={overflowY}
          color={isMobile ? undefined : color}
        >
          <AppPageNavBar color={color} {...props} />
          <Content {...props}>{children}</Content>
        </Layout>
      );
  }
};

export default PageLayout;
