import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { STATE_ABBREVIATION_TO_NAME, titleize } from '@codiwork/codi';

import { getLocations } from 'lib/butter';
import { CMSRouteProps } from 'routes/types';
import { actions } from 'store/App';
import { selectCmsPage, selectLocations } from 'store/App/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import LocationPage from './Page';
import { generateLocationPath } from '../utils';

interface MatchProps {
  country: string;
  region: string;
  city: string;
}

interface Props extends CMSRouteProps<MatchProps> {}

const Container: React.FC<Props> = ({ match, cmsSlug, history }) => {
  const { city: cityEncoded, region: regionEncoded, country: countryEncoded } = match.params;
  const city = decode(cityEncoded);
  let region = decode(regionEncoded);

  const stateNames = Object.values(STATE_ABBREVIATION_TO_NAME);

  if (stateNames.includes(region)) {
    const matchedStateAbbreviation = Object.keys(STATE_ABBREVIATION_TO_NAME).find(
      key => STATE_ABBREVIATION_TO_NAME[key as keyof typeof STATE_ABBREVIATION_TO_NAME] === region
    );

    region = matchedStateAbbreviation || region;
  } else {
    region = region.toUpperCase();
  }

  let country = decode(countryEncoded);

  if (country === 'United States') {
    country = 'US';
  } else {
    country = country.toUpperCase();
  }

  const correctedPathname = generateLocationPath({ location: city, country, region });
  const { pathname } = history.location;

  useEffect(() => {
    if (correctedPathname === pathname) return;

    history.replace({ pathname: correctedPathname });
  }, [pathname, correctedPathname, history]);

  const locations = useSelector(selectLocations);
  const page = useAppSelector(state => selectCmsPage(state, cmsSlug));
  const dispatch = useAppDispatch();

  useEffect(() => {
    getLocations().then(fetchedLocations => {
      dispatch(
        actions.setButterLocations(
          fetchedLocations.map(({ region, name, ...rest }) => {
            const id = `${region}_${name}`;
            const matchedLocation = locations.find(l => l.id === id);
            const { lat, lng } = matchedLocation || {};

            return { lat, lng, ...rest, region, name, id };
          })
        )
      );
    });
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!locations.length || !page || correctedPathname !== pathname) return null;

  const matchedLocation = locations.find(l => l.name === city && l.region === region);

  const location = matchedLocation || {
    name: city,
    region,
    country,
    id: `${region}_${city}`
  };

  return <LocationPage key={location.name} location={location} fields={page.fields} />;
};

export default Container;

function decode(pathname: string) {
  return titleize(decodeURIComponent(pathname).replaceAll('-', ' '));
}
