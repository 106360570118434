import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Link, SwiperCarousel, Text } from 'ds';
import { CATALOG_PATH } from 'routes';
import { SwiperSlide } from 'swiper/react';

interface Props {
  maxContentWidth: number;
}

const ADD_ONS = [
  {
    name: 'Phone booth',
    srcKey: 'phone-booth_mcGy1D3A2.png?updatedAt=1692754819979',
    width: '80%'
  },
  {
    name: 'Zoom Room',
    srcKey: 'zoom-room_81HdQZN5r.jpeg?updatedAt=1692669084958'
  },
  {
    name: 'Height-adjustable workstation',
    srcKey: 'workstation_keBlqYkK1.jpeg?updatedAt=1692837671309'
  },
  {
    name: 'Snacks',
    srcKey: 'snacks-collage_i5u0UpyWC?updatedAt=1692809048731'
  },
  {
    name: 'Monitor set',
    srcKey: 'monitor-set_hiUA6wJwd.jpeg?updatedAt=1692669089586'
  },
  {
    name: 'Additional cleanings',
    srcKey: 'additional-cleanings_qzkER9SuD.jpeg'
  }
];

const OptionalAddOns: React.FC<Props> = ({ maxContentWidth }) => {
  const { xs } = useContext(media);
  const { windowWidth, contentPaddingX, contentWidth } = useContext(app);

  return (
    <Layout width={xs ? contentWidth : undefined}>
      <Text size={xs ? 'body1' : 'h5'} semibold={xs}>
        Optional add-ons
      </Text>
      <Layout marginTop={8} align="center">
        <Text size="body2">Customize your office to fit your needs.&nbsp;</Text>
        <Layout marginLeft={4} flexShrink={0}>
          <Link href={CATALOG_PATH} target="_blank" size="body2" semibold={false}>
            View all
          </Link>
        </Layout>
      </Layout>
      <Layout
        marginTop={24}
        {...(xs
          ? {
              width: windowWidth,
              overflow: 'hidden',
              position: 'relative',
              left: -contentPaddingX,
              paddingLeft: contentPaddingX
            }
          : { maxWidth: maxContentWidth })}
      >
        <SwiperCarousel
          config={{
            lg: { slidesPerView: 3, spaceBetween: 12 },
            md: { slidesPerView: 2, spaceBetween: 12 },
            xs: { slidesPerView: 1.2, spaceBetween: 12 }
          }}
          controlLayoutProps={{ width: 24, height: 24, left: -16, right: 56, top: 46 }}
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          {ADD_ONS.map(({ name, width, srcKey }) => {
            return (
              <SwiperSlide key={name}>
                <Layout flexShrink={0}>
                  <Layout color="blue-25" borderRadius={8} justify="center">
                    <Img
                      srcKey={srcKey}
                      folder="ux"
                      alt={name}
                      width={600}
                      style={{
                        width,
                        height: xs ? 140 : 128,
                        objectFit: 'cover',
                        borderRadius: 8
                      }}
                    />
                  </Layout>
                  <Layout paddingX={24} paddingY={12}>
                    <Layout height={48}>
                      <Text size="body2" color="gray-700">
                        {name}
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
              </SwiperSlide>
            );
          })}
        </SwiperCarousel>
      </Layout>
    </Layout>
  );
};

export default OptionalAddOns;
