import React from 'react';
import { Provider } from 'react-redux';
import { IKContext } from 'imagekitio-react';

import { IMAGE_KIT_URL } from 'helpers/images';

import ErrorBoundary from 'components/ErrorBoundary';

import AppContainer from 'AppContainer';

import { store } from './store/store';

import './manifest.scss';

const App: React.FC = () => {
  return (
    <IKContext urlEndpoint={`${IMAGE_KIT_URL}/${process.env.REACT_APP_ENV}`}>
      <Provider store={store}>
        <ErrorBoundary>
          <AppContainer />
        </ErrorBoundary>
      </Provider>
    </IKContext>
  );
};

export default App;
