import React, { useContext, useRef } from 'react';

import { app, media } from 'context';
import Layout from 'ds/Layout';

import Animate from './Animate';
import ButterImg from './ButterImg';

interface Props {
  image: { src: string; alt: string };
  text: JSX.Element;
}

const TEXT_WIDTH = 480;
const OVERLAP = 64;

const OverlappingSections: React.FC<Props> = ({ image, text }) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const { xs, lg } = useContext(media);
  const { contentWidth, contentPaddingX, width } = useContext(app);

  if (xs) {
    return (
      <>
        <Animate paddingY={80}>{text}</Animate>
        <Animate width={width} left={-contentPaddingX} position="relative">
          <ButterImg src={image.src} alt={image.alt} width={750} height={468} />
        </Animate>
      </>
    );
  }

  if (lg) {
    return (
      <Layout align="center">
        <Layout width={`calc(100% - ${TEXT_WIDTH + OVERLAP}px)`} __ref={imageRef} position="relative">
          <Animate>
            <img
              src={image.src}
              onLoad={() => {
                const image = imageRef.current;
                const text = textRef.current;

                if (!image || !text) return;
              }}
              style={{ width: '100%', maxHeight: 688, objectFit: 'cover', borderRadius: 40 }}
              alt={image.alt}
            />
          </Animate>
        </Layout>
        <Layout position="relative" left={-OVERLAP} width={TEXT_WIDTH - OVERLAP}>
          <Animate delay={150}>
            <Layout
              position="relative"
              color="white"
              borderRadius={40}
              width="calc(100% + 64px)"
              boxShadow="0px 20px 35px rgba(171, 188, 222, 0.25)"
              padding={64}
              __ref={textRef}
            >
              {text}
            </Layout>
          </Animate>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout position="relative">
      <Layout width={width} left={-contentPaddingX} position="relative">
        <Animate width={width}>
          <img src={image.src} style={{ width: '100%', maxHeight: 688, objectFit: 'cover' }} alt={image.alt} />
        </Animate>
      </Layout>
      <Layout position="absolute" top={contentPaddingX} left={0}>
        <Animate delay={150}>
          <Layout
            position="relative"
            color="white"
            borderRadius={40}
            width={contentWidth}
            boxShadow="0px 20px 35px rgba(171, 188, 222, 0.25)"
            padding={64}
            __ref={textRef}
          >
            {text}
          </Layout>
        </Animate>
      </Layout>
    </Layout>
  );
};

export default OverlappingSections;
