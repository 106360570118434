import React from 'react';

import { Layout } from 'ds';

interface Props {}

const DOT_SIZE = 6;

const ChatbotThinkingDisplay: React.FC<Props> = () => {
  return (
    <Layout gap={4} flex>
      <Layout
        __className="Chatbot-chatbotThinkingDisplayDot"
        color="gray-100"
        width={DOT_SIZE}
        height={DOT_SIZE}
        borderRadius="circular"
      />
      <Layout
        __className="Chatbot-chatbotThinkingDisplayDot"
        color="gray-100"
        width={DOT_SIZE}
        height={DOT_SIZE}
        borderRadius="circular"
      />
      <Layout
        __className="Chatbot-chatbotThinkingDisplayDot"
        color="gray-100"
        width={DOT_SIZE}
        height={DOT_SIZE}
        borderRadius="circular"
      />
    </Layout>
  );
};

export default ChatbotThinkingDisplay;
