import React, { Children, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import SwiperClass, { A11y, Virtual } from 'swiper';

import { app, getScreenSizeXl, media } from 'context';
import { track } from 'lib/analytics';
import { Swiper, SwiperProps } from 'swiper/react';

import Dots from './Dots';
import PaginationControl from './PaginationControl';
import { DEFAULT_PAGINATION_CONTROL_DIMENSION } from './constants';
import Layout, { Props as LayoutProps } from '../Layout';

const CONFIG: Config = {
  xl: {
    slidesPerView: 4,
    spaceBetween: 24
  },
  lg: {
    slidesPerView: 4,
    spaceBetween: 24
  },
  md: {
    slidesPerView: 3,
    spaceBetween: 24
  },
  sm: {
    slidesPerView: 2,
    spaceBetween: 24
  },
  xs: {
    slidesPerView: 2,
    spaceBetween: 24
  },
  phone: {
    slidesPerView: 1,
    spaceBetween: 12
  }
};

const SCREEN_SIZES = ['phone', 'xs', 'sm', 'md', 'lg', 'xl'] as const;
type ScreenSize = typeof SCREEN_SIZES[number];

type Config = Partial<Record<ScreenSize, SizeConfig>>;
type CompleteConfig = Record<ScreenSize, SizeConfig>;

type SizeConfig = {
  slidesPerView: number;
  spaceBetween?: number;
  isFullScreen?: boolean;
};

export const PHONE_QUERY = '(min-width: 0) and (max-width: 499px)';

export interface Props extends SwiperProps {
  config?: Config;
  controlLayoutProps?: LayoutProps;
  controlDimension?: number;
  leftArrow?: JSX.Element;
  rightArrow?: JSX.Element;
  width?: number;
  ref?: MutableRefObject<SwiperClass | undefined>;
  name?: string;
  inputIndex?: number;
  ignoreTouch?: boolean;
  showDots?: boolean;
  showArrows?: boolean;
  showArrowsMobile?: boolean;
  dotsType?: 'light' | 'dark';
}

export const NO_SWIPING_CLASS = 'swiper-no-swiping';

const SwiperCarousel: React.FC<Props> = ({
  children,
  leftArrow,
  rightArrow,
  config = CONFIG,
  controlLayoutProps,
  controlDimension = DEFAULT_PAGINATION_CONTROL_DIMENSION,
  loop,
  width,
  inputIndex,
  ignoreTouch,
  virtual = true,
  name = 'photo carousel',
  showDots,
  showArrows = true,
  showArrowsMobile = false,
  noSwiping = false,
  style,
  dotsType = 'light',
  ...props
}) => {
  const mediaContext = useContext(media);
  const { xs, sm, md, lg } = mediaContext;
  const { windowWidth, contentPaddingX } = useContext(app);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const localRef = useRef<SwiperClass>();
  const ref: MutableRefObject<SwiperClass | undefined> = props.ref || localRef;
  const isPhone = useMediaQuery(PHONE_QUERY);
  let screenSize: ScreenSize = getScreenSizeXl(mediaContext);

  if (isPhone) screenSize = 'phone';

  const isTouch = (xs || sm) && !ignoreTouch;
  const isMouse = md || lg;

  const { spaceBetween, slidesPerView, isFullScreen } = fillInConfig(config)[screenSize];

  const swiper = ref.current;
  const numSlides = Children.toArray(children).length;
  const isSlideable = numSlides > slidesPerView;

  useEffect(() => {
    if (inputIndex !== undefined && !!swiper) {
      swiper.slideTo(inputIndex + 1);
    }
  }, [inputIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      position="relative"
      {...(isFullScreen
        ? { left: -contentPaddingX, width: windowWidth }
        : isTouch && !width
        ? { width: windowWidth - contentPaddingX * 2 }
        : { width })}
    >
      <Swiper
        {...(isTouch ? { style: { ...style, overflow: 'visible' } } : { style })}
        {...props}
        onInit={swiper => {
          ref.current = swiper;

          props.onInit && props.onInit(swiper);
        }}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        onActiveIndexChange={params => {
          const { realIndex } = params;

          setActiveIndex(realIndex);
          props.onActiveIndexChange && props.onActiveIndexChange(params);
        }}
        modules={[A11y, Virtual]}
        virtual={virtual}
        noSwiping={noSwiping || isMouse}
        noSwipingClass={NO_SWIPING_CLASS}
        loop={loop && isSlideable}
      >
        {children}
      </Swiper>
      {showArrows && (showArrowsMobile || isMouse) && isSlideable && (activeIndex > 0 || loop) ? (
        <Layout
          position="absolute"
          zIndex={2}
          {...controlLayoutProps}
          {...(controlLayoutProps?.top !== undefined ? undefined : { top: '50%', transform: 'translateY(-50%)' })}
          right={undefined}
          left={typeof controlLayoutProps?.left === 'number' ? controlLayoutProps?.left : -56}
        >
          <PaginationControl
            onClick={() => {
              const swiper = ref.current;

              if (!swiper) return;

              swiper.slidePrev();

              track('Element Interacted', { type: 'iconButton', name, action: 'previous' });
            }}
            direction="left"
            dimension={controlDimension}
            padding={showArrowsMobile && !isMouse ? 0 : undefined}
          >
            {leftArrow}
          </PaginationControl>
        </Layout>
      ) : null}

      {showArrows && (showArrowsMobile || isMouse) && isSlideable && (!swiper?.isEnd || loop) ? (
        <Layout
          position="absolute"
          zIndex={2}
          {...controlLayoutProps}
          {...(controlLayoutProps?.top !== undefined ? undefined : { top: '50%', transform: 'translateY(-50%)' })}
          left={undefined}
          right={typeof controlLayoutProps?.right === 'number' ? controlLayoutProps.right : -56}
        >
          <PaginationControl
            padding={showArrowsMobile && !isMouse ? 0 : undefined}
            onClick={() => {
              const swiper = ref.current;

              if (!swiper) return;

              swiper.slideNext();

              track('Element Interacted', { type: 'iconButton', name, action: 'next' });
            }}
            direction="right"
            dimension={controlDimension}
          >
            {rightArrow}
          </PaginationControl>
        </Layout>
      ) : null}
      {((isFullScreen && isSlideable) || showDots) && numSlides > 1 && (
        <Layout
          bottom={16}
          width={50}
          position="absolute"
          left="50%"
          __style={{ transform: 'translateX(-50%)' }}
          zIndex={1}
          flex
        >
          <Dots length={numSlides} activeIndex={activeIndex} type={dotsType} />
        </Layout>
      )}
    </Layout>
  );
};

function fillInConfig(config: Config): CompleteConfig {
  const transformed = { ...config };

  SCREEN_SIZES.forEach(size => {
    if (!transformed[size]) {
      transformed[size] = findNextConfig(config, size);
    }
  });

  return transformed as CompleteConfig;
}

// First searches for next larger configs and then next smaller
function findNextConfig(config: Config, size: ScreenSize): SizeConfig {
  const largerSizes = SCREEN_SIZES.slice(SCREEN_SIZES.findIndex(s => s === size));
  let matchedConfig: SizeConfig | null = null;

  for (let index = 0; index < largerSizes.length; index++) {
    const size = largerSizes[index];
    const sizeConfig = config[size];

    if (sizeConfig) {
      matchedConfig = sizeConfig;
      break;
    }
  }

  if (matchedConfig) return matchedConfig;

  const descendingSize = [...SCREEN_SIZES].reverse();

  for (let index = 0; index < descendingSize.length; index++) {
    const size = descendingSize[index];
    const sizeConfig = config[size];

    if (sizeConfig) {
      matchedConfig = sizeConfig;
      break;
    }
  }

  return matchedConfig || { slidesPerView: 1 };
}

export default SwiperCarousel;
