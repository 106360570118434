import React, { useContext } from 'react';

import { app, media } from 'context';
import { Img, Layout, Link, Text } from 'ds';

import { PLAYBOOK_FOOTER_HEIGHT, PLAYBOOK_HEADER_HEIGHT } from './constants';
import { playbookContentHeight } from './utils';

interface Props {}

const GUTTER = 48;

const TransienceOfCoworking: React.FC<Props> = () => {
  const { windowHeight } = useContext(app);
  const { xs, md } = useContext(media);

  return (
    <Layout height={xs ? undefined : playbookContentHeight({ windowHeight })}>
      <Layout height="100%" flex>
        <Layout
          equalWidth
          direction="column"
          justify="center"
          marginRight={GUTTER}
          position="relative"
          top={-(PLAYBOOK_FOOTER_HEIGHT / 2)}
          paddingTop={md ? 100 : 48}
        >
          <Text size="h3" color="blue-600">
            The Transience of Coworking Offices
          </Text>
          <Layout marginTop={48}>
            <Text size="h6" bold={false} color="gray-700">
              Likely, you’ll have numerous options to choose from, ranging from international companies to regional
              coworking brands, to single, locally based companies. If you’re looking for a long-term solution within a
              coworking environment, be sure to keep an eye on occupancy and typical longevity of a specific office —
              only 40% of coworking spaces are profitable and as we’ve seen with companies like{' '}
              <Link
                underline
                semibold={false}
                size="h6"
                href="https://www.nytimes.com/2022/11/10/business/wework-quarterly-earnings.html#:~:text=WeWork%20said%20Thursday%20that%20it,and%20subsequent%20bailout%20in%202019."
              >
                WeWork
              </Link>{' '}
              and{' '}
              <Link
                underline
                semibold={false}
                size="h6"
                href="https://www.nytimes.com/2022/08/31/style/the-wing-shuts-down.html"
              >
                The Wing
              </Link>
              , they can close suddenly when profits fall. The coworking spaces that thrive are the ones that maintain
              memberships of{' '}
              <Link
                underline
                semibold={false}
                size="h6"
                href="https://www.slideshare.net/carstenfoertsch/2019-profitability-of-coworking-spaces-171146678"
              >
                200 or more workers
              </Link>
              . Some operate on a razor’s edge: profit margins in coworking can be extremely tight, around{' '}
              <Link underline semibold={false} size="h6" href="https://levels.io/coworking-space-economics/">
                10%
              </Link>{' '}
              in some cases. Taken together, it’s important to conduct a bit more due diligence on the location you
              choose, or you could be conducting another office hunt unexpectedly.
            </Text>
          </Layout>
        </Layout>
        {!xs && (
          <Layout position="relative" equalWidth>
            <Img
              srcKey="chess-office_sWR8PalzV.jpeg"
              folder="public"
              alt="office"
              width={1600}
              style={{
                height: windowHeight - PLAYBOOK_FOOTER_HEIGHT,
                width: `calc(50vw - ${GUTTER / 2}px)`,
                objectFit: 'cover',
                position: 'absolute',
                top: -PLAYBOOK_HEADER_HEIGHT
              }}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default TransienceOfCoworking;
