import React, { useContext } from 'react';
import { colorClassName } from '@codiwork/codi';
import classNames from 'classnames';

import { media } from 'context';
import { Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

import { ReactComponent as BuildingStars } from './building-and-stars.svg';
import { ReactComponent as Logo } from 'ds/LogoResizable.svg';

interface Props {}

const Intro: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout justify="space-between" flexGrow={1} align="center">
        <Layout maxWidth={824}>
          <span className={classNames('Logo', colorClassName('blue-500'))}>
            <Logo style={{ height: 68 }} />
          </span>
          <Layout marginTop={36}>
            <Text color="blue-gradient" size="h2" scale={xs}>
              How to Find &<br />
              Manage a Private Office
            </Text>
          </Layout>
          <Layout marginTop={44}>
            <Text size="h5" color="blue-700" bold={false}>
              A Playbook for Success
            </Text>
          </Layout>
        </Layout>
        {!xs && <BuildingStars />}
      </Layout>
    </PlaybookContentContainer>
  );
};

export default Intro;
