import React from 'react';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { FieldSize } from 'ds/types';
import { useFieldSize } from 'hooks';

interface Props {
  size?: FieldSize;
  width: number;
  isCreatable?: boolean;
}

const SIZE_CONFIG = {
  xxs: {
    fontSize: 'body3'
  },
  xs: {
    fontSize: 'body3'
  },
  sm: {
    fontSize: 'body3'
  },
  md: {
    fontSize: 'body2'
  },
  lg: {
    fontSize: 'body1'
  }
} as const;

const NoMatchesItem: React.FC<Props> = ({ width, isCreatable, ...props }) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  return (
    <Layout paddingX={16} paddingY={16} direction="row" justify="flex-start" align="center" flexGrow={1} width={width}>
      {isCreatable ? (
        <>
          <Icon name="plus" color="blue-500" size="xs" />
          <Layout marginLeft={4}>
            <Text size="body3" color="blue-500">
              Create new
            </Text>
          </Layout>
        </>
      ) : (
        <Text size={SIZE_CONFIG[size].fontSize} color="gray-400">
          No matches found
        </Text>
      )}
    </Layout>
  );
};

export default NoMatchesItem;
