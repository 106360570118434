import { useContext } from 'react';

import { app, media } from 'context';

import { CHATBOT_DESKTOP_MAX_HEIGHT } from './constants';

export default function useChatbotHeight() {
  const { isMobile } = useContext(media);
  const { windowHeight } = useContext(app);

  if (isMobile) {
    // TODO: remove?
    return 0;
  }

  return windowHeight > 800 ? CHATBOT_DESKTOP_MAX_HEIGHT : windowHeight - 96;
}
