import React, { MouseEvent } from 'react';
import { ReactComponent as AddressPin } from '@codiwork/codi/lib/icons/AddressPin.svg';
import classNames from 'classnames';
import { ChildComponentProps } from 'google-map-react';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import { track } from 'lib/analytics';

interface Props extends ChildComponentProps {
  isActive: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  context?: string;
  id?: number | string;
  type?: 'office';
}

const PinMarker: React.FC<Props> = ({ isActive, onClick, context, id, type }) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    track('Element Interacted', {
      type: 'button',
      name: 'map pin',
      action: 'select',
      value: id,
      context
    });

    onClick(e);
  };

  const isOffice = type === 'office';

  return (
    <button
      onClick={handleClick}
      className={classNames('MapMarker has-shadow', {
        'is-active': isActive,
        'is-not-active': !isActive
      })}
    >
      <span className={classNames('MapMarker-whitePin')}>
        <AddressPin />
      </span>
      <span
        className={classNames('MapMarker-innerPin', {
          'is-bluePin': !type,
          'is-goldPin': isOffice
        })}
      >
        <AddressPin />
      </span>
      {isOffice && (
        <Layout position="absolute" top={5.8} transform="translateX(-50%)" left="50%" display="inline-flex">
          <Icon name="star" color="white" size={14} />
        </Layout>
      )}
    </button>
  );
};

export default PinMarker;
