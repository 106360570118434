import React, { useContext } from 'react';

import { media } from 'context';
import { Animate, Layout, Text } from 'ds';

import { CustomAmenities as CustomAmenitiesType } from './types';

interface Props extends CustomAmenitiesType {}

const CustomAmenities: React.FC<Props> = ({ title, subtitle }) => {
  const { xs } = useContext(media);

  return (
    <Animate>
      <Layout paddingX={xs ? undefined : 240} paddingTop={xs ? undefined : 24} paddingBottom={xs ? undefined : 40}>
        <Layout marginBottom={24}>
          <Text size="h3" align="center" scale>
            {title}
          </Text>
        </Layout>
        <Layout>
          <Text size="h5" align="center" bold={false} scale>
            {subtitle}
          </Text>
        </Layout>
      </Layout>
    </Animate>
  );
};

export default CustomAmenities;
