import React, { useContext } from 'react';

import { media } from 'context';
import { Layout } from 'ds';
import { selectDaysPerWeek } from 'store/OfficeRequest/selectors';
import { useAppSelector } from 'store/hooks';

import DaysPerWeekSelector from './DaysPerWeekSelector';
import OfficeRequestBottomSheet from './OfficeRequestBottomSheet';
import StepContainer from './StepContainer';
import StepDescription from './StepDescription';
import StepTitle from './StepTitle';
import { generateStepPath } from './utils';

interface Props {}

const Step3: React.FC<Props> = () => {
  const daysPerWeek = useAppSelector(selectDaysPerWeek);
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;

  return (
    <StepContainer
      step={3}
      nextHref={generateStepPath({ step: 4 })}
      backHref={generateStepPath({ step: 2 })}
      ctaDisabled={!daysPerWeek}
      stepCompletedEventValue={daysPerWeek}
      stepName="days per week"
    >
      <Layout>
        <StepTitle>How many days per week do you need?</StepTitle>
        <Layout marginTop={isMobile ? 16 : 24}>
          <StepDescription>
            We’re all about hybrid, but full-time schedules will result in more options.
          </StepDescription>
        </Layout>
      </Layout>
      {isMobile ? (
        <OfficeRequestBottomSheet>
          <Layout justify="center">
            <DaysPerWeekSelector />
          </Layout>
        </OfficeRequestBottomSheet>
      ) : (
        <DaysPerWeekSelector />
      )}
    </StepContainer>
  );
};

export default Step3;
