import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { app } from 'context';
import { Button, Icon, Layout, Modal, Pressable, Text } from 'ds';
import { track } from 'lib/analytics';

import { SORT_LABELS } from './constants';
import { SearchSortLabel } from './types';
import { generateSearch } from './utils';

interface Props {
  isVisible: boolean;
  handleClose: VoidFunction;
  sortLabel: SearchSortLabel;
}

const SortBottomSheet: React.FC<Props> = ({ isVisible, handleClose, ...props }) => {
  const [sortLabel, setSortLabel] = useState<SearchSortLabel>(props.sortLabel);
  const { contentPaddingX } = useContext(app);
  const history = useHistory();
  const { search } = useLocation();

  return (
    <Modal
      isVisible={isVisible}
      onClose={() => {
        handleClose();
        setTimeout(() => {
          setSortLabel(props.sortLabel);
        }, 300);
      }}
      animate={false}
      size="md"
      header="Sort Workspaces"
      headerAlign="left"
      isFullScreen
      hasBottomSheetPaddingX
      footer={
        <Layout align="center" justify="space-between">
          <Layout equalWidth>
            <Button
              text="Cancel"
              type="secondaryOutline"
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  setSortLabel(props.sortLabel);
                }, 300);
              }}
              fullWidth
            />
          </Layout>
          <Layout marginLeft={10} equalWidth>
            <Button
              type="primary"
              size="sm"
              text="Done"
              onClick={() => {
                history.replace({
                  search: generateSearch({ sortLabel, search })
                });
                handleClose();

                track('Element Interacted', { name: 'Mobile search sort', value: sortLabel, type: 'button' });
              }}
              fullWidth
            />
          </Layout>
        </Layout>
      }
    >
      <Layout marginTop={8} borderColor="gray-50" borderTop />
      <Layout marginTop={52}>
        {SORT_LABELS.map(label => {
          const isSelected = label === sortLabel;

          return (
            <Pressable
              key={label}
              onPress={() => setSortLabel(label)}
              style={{
                position: 'relative',
                left: -contentPaddingX,
                width: `calc(100% + ${contentPaddingX}px * 2)`,
                height: 56,
                alignItems: 'center',
                display: 'block'
              }}
            >
              <Layout justify="space-between" align="center" paddingX={24} inline>
                <Layout inline>
                  <Text size="body2" semibold={isSelected} align="left">
                    {label}
                  </Text>
                </Layout>
                {isSelected && <Icon name="checkmark" size="md" stroke={2} color="gray-900" />}
              </Layout>
            </Pressable>
          );
        })}
      </Layout>
    </Modal>
  );
};

export default SortBottomSheet;
