import React, { useContext } from 'react';

import { media } from 'context';
import { Button, FullWidthBackground, Layout, Text } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

interface Props {}

const CTA: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <FullWidthBackground color="blue-50">
      <Layout paddingY={xs ? 130 : 166} flexGrow={1} direction="column" align="center">
        <Text size={xs ? 'h3' : 'h4'} color="blue-500">
          Ready to
        </Text>
        <Text size={xs ? 'h3' : 'h4'} color="blue-700">
          get started?
        </Text>
        <Layout marginTop={36} />
        <Button size={xs ? 'sm' : 'md'} type="primary" href={OFFICE_REQUEST_PATH} text="Request an office" />
      </Layout>
    </FullWidthBackground>
  );
};

export default CTA;
