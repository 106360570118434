import React from 'react';
import classNames from 'classnames';

import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import Layout from './Layout';
import Text from './Text';
import { DS_CLASS_NAME } from './constants';
import ToggleControl from './controls/ToggleControl';
import { FieldSize } from './types';

export interface Props {
  label: string;
  checked: boolean;
  disabled?: boolean;
  showSpinner?: boolean;
  size?: FieldSize;
  textSize?: 'body1' | 'body2' | 'body3';
  onChange: (value: boolean) => void;
  border?: boolean;
  context?: string;
}

const Toggle: React.FC<Props> = ({
  label,
  checked,
  disabled,
  context,
  showSpinner,
  onChange,
  border = true,
  textSize,
  ...props
}) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  return (
    <Layout
      __className={classNames(DS_CLASS_NAME, 'Toggle', `is-size-${size}`, { 'has-border': border })}
      direction="row"
      justify="space-between"
      align="center"
    >
      <label className="Toggle-label">{textSize ? <Text size={textSize}>{label}</Text> : label}</label>
      <ToggleControl
        checked={checked}
        disabled={disabled}
        showSpinner={showSpinner}
        onChange={checked => {
          onChange(checked);
          track('Element Interacted', {
            context,
            name: label,
            type: 'toggle',
            value: checked ? 'on' : 'off'
          });
        }}
        size={size}
      />
    </Layout>
  );
};

export default Toggle;
