import React, { useContext } from 'react';
import { BLUE_500_HEX } from '@codiwork/codi';

import { media } from 'context';
import { Button, FullWidthBackground, Layout, Text } from 'ds';
import { SEARCH_PATH } from 'routes';

import TestimonialComponent from './Testimonial';
import { Testimonial } from './types';

interface Props {
  title: string;
  cta_text: string;
  testimonials: Testimonial[];
}

const Testimonials: React.FC<Props> = ({ title, cta_text, testimonials: _testimonials }) => {
  const { xs, media500, lg, xl } = useContext(media);

  let testimonials;

  if (xl) {
    testimonials = _testimonials;
  } else if (lg) {
    testimonials = _testimonials.slice(0, 2);
  } else {
    testimonials = _testimonials.slice(0, 1);
  }

  return (
    <FullWidthBackground color="blue-600" paddingY={xs ? 64 : 90}>
      <Text size="h4" color="white" align="center">
        {title}
      </Text>
      <Layout marginTop={48} flex>
        {testimonials.map((testimonial, index) => {
          return (
            <Layout key={testimonial.name} marginLeft={index === 0 || xs ? undefined : 24} equalWidth>
              <TestimonialComponent {...testimonial} />
            </Layout>
          );
        })}
      </Layout>
      <Layout marginTop={48} />
      <Layout width="100%" justify="center">
        <Layout width={xs ? '100%' : 274} maxWidth={media500 ? undefined : 328} justify="center">
          <Button
            background={BLUE_500_HEX}
            size="lg"
            type="primary"
            text={cta_text}
            href={SEARCH_PATH}
            search="?offsites_only=1"
            fullWidth={media500}
          />
        </Layout>
      </Layout>
    </FullWidthBackground>
  );
};

export default Testimonials;
