import React, { useContext, useState } from 'react';
import {
  WorkspaceUserType,
  dateToYyyyMmDd,
  formatAddress,
  googleResultToCodiAddress,
  isAddress,
  yyyyMmDdToDate
} from '@codiwork/codi';
import { DateTime } from 'luxon';

import { app, media } from 'context';
import { AddressInput, Button, DatePicker, Layout, MoneyInput, NumberInput, Select, Text, ToggleControl } from 'ds';
import { formatMoney } from 'helpers';
import { apiTrack } from 'lib/analytics';
import { ADD_PROPERTY_PATH } from 'routes';
import { actions } from 'store/AddProperty';
import { selectAddPropertyWorkspace } from 'store/AddProperty/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { BUILDING_CLASS_OPTIONS } from './constants';
import {
  askingRentToRentPerSquareFootPerYear,
  isCreatableProperty,
  rentPerSquareFootPerYearToAskingRent
} from './utils';

interface Props {}

const WORKSPACE_USER_TYPES: WorkspaceUserType[] = ['Landlord', 'Broker', 'Property Manager', 'Sublessor'];

const AddPropertyForm: React.FC<Props> = () => {
  const workspace = useAppSelector(selectAddPropertyWorkspace);
  const { first_hosting_availability, square_feet, building_class, workspace_price, user_type } = workspace;
  const [address, setAddress] = useState<string>(
    workspace.address ? formatAddress(workspace.address, { line2: false }) : ''
  );
  const [rentPerSquareFootPerYear, setRentPerSquareFootPerYear] = useState<number | undefined>();
  const { isMobile } = useContext(media);
  const [line2, setLine2] = useState<string | undefined>(workspace.address?.line2 || undefined);
  const [addressError, setAddressError] = useState<string>();
  const [isAvailableToday, setIsAvailableToday] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const isValid = isCreatableProperty(workspace) && !!user_type && !addressError;
  const { contentPaddingX } = useContext(app);
  const trackSubmission = () => {
    if (!workspace.address || !square_feet || !first_hosting_availability) return;

    apiTrack('Property Submitted', {
      location: formatAddress(workspace.address),
      size: square_feet,
      dateAvailable: first_hosting_availability
    });
  };

  return (
    <>
      <AddressInput
        size="sm"
        onAddressChange={({ address, place }) => {
          if (!place) return;

          const placeLocation = place.geometry?.location;
          const lat = placeLocation?.lat();
          const lng = placeLocation?.lng();
          setAddress(address);

          if (lat && lng && place.address_components) {
            googleResultToCodiAddress(place).then(address => {
              dispatch(actions.updateWorkspace({ address: { ...address, line2 } }));

              if (isAddress(place)) {
                setAddressError(undefined);
              }
            });
          }
        }}
        onChange={({ value }) => {
          setAddress(value);
        }}
        line2OnChange={({ value }) => {
          setLine2(value);
          dispatch(actions.updateWorkspace({ address: { ...workspace.address, line2: value } }));
        }}
        onNonStreetAddressSelection={() => setAddressError('Address must include street number')}
        error={addressError}
        value={address}
        line2Value={line2}
        onClear={() => {
          setAddress('');
          setLine2('');
          dispatch(actions.updateWorkspace({ address: {} }));
        }}
        autoFocus
      />
      <Layout marginTop={isMobile ? 24 : 12}>
        <NumberInput
          size="sm"
          label="Size"
          suffix="ft²"
          value={square_feet}
          max={999999}
          onChange={({ value }) => {
            dispatch(
              actions.updateWorkspace({
                square_feet: value,
                workspace_price:
                  !!rentPerSquareFootPerYear && !!value
                    ? {
                        asking_rent: rentPerSquareFootPerYearToAskingRent(rentPerSquareFootPerYear, value)!
                      }
                    : undefined
              })
            );
          }}
          required
        />
      </Layout>
      <Layout marginTop={isMobile ? 24 : 12} direction="row" align="center">
        <Layout width="50%" align="center">
          <MoneyInput
            size="sm"
            label="Rental rate"
            cents={false}
            suffix="/ft²/yr"
            value={
              !!workspace_price?.asking_rent && !!square_feet
                ? askingRentToRentPerSquareFootPerYear(workspace_price.asking_rent, square_feet)
                : undefined
            }
            disabled={!square_feet}
            max={999999}
            onChange={({ value }) => {
              setRentPerSquareFootPerYear(value);

              if (!value || !square_feet) {
                dispatch(actions.updateWorkspace({ workspace_price: undefined }));
                return;
              }

              dispatch(
                actions.updateWorkspace({
                  workspace_price: {
                    asking_rent: rentPerSquareFootPerYearToAskingRent(value, square_feet)!
                  }
                })
              );
            }}
            required
          />
        </Layout>
        <Layout direction="row" align="center" justify="flex-start" marginLeft={12}>
          <Text size="body-sm">= ${formatMoney(workspace_price?.asking_rent || 0)}/mo</Text>
        </Layout>
      </Layout>
      <Layout marginTop={isMobile ? 24 : 12}>
        <Select
          label="Building class"
          options={[...BUILDING_CLASS_OPTIONS]}
          value={building_class || ''}
          onChange={({ value }) => {
            dispatch(actions.updateWorkspace({ building_class: value }));
          }}
          fullWidth
          size="sm"
          required
        />
      </Layout>
      <Layout {...(isMobile ? { marginTop: 24, paddingBottom: 80 } : { marginTop: 12 })}>
        <Select
          label="Role"
          options={WORKSPACE_USER_TYPES.map(role => ({ value: role, label: role }))}
          value={user_type}
          size="sm"
          onChange={({ value }) => {
            dispatch(actions.updateWorkspace({ user_type: value as WorkspaceUserType }));
            dispatch(actions.updateUser({ role: value as WorkspaceUserType }));
          }}
          fullWidth
          required
        />
      </Layout>
      <Layout {...(isMobile ? { marginTop: 24, paddingBottom: 80, paddingTop: 16 } : { marginTop: 12 })}>
        <Layout align="center" justify="space-between" {...(isMobile ? {} : { paddingY: 16 })}>
          <Text size="body2" color="gray-700">
            This property is available today
          </Text>
          <ToggleControl
            checked={isAvailableToday}
            onChange={() => {
              if (isAvailableToday) {
                setIsAvailableToday(false);
                dispatch(actions.updateWorkspace({ first_hosting_availability: undefined }));

                if (isMobile) {
                  window.scrollTo(0, document.body.scrollHeight);
                }
              } else {
                setIsAvailableToday(true);
                dispatch(actions.updateWorkspace({ first_hosting_availability: DateTime.now().toISODate() }));
              }
            }}
            size="sm"
          />
        </Layout>
        {!isAvailableToday && (
          <Layout marginTop={12}>
            <DatePicker
              size="sm"
              selected={first_hosting_availability ? yyyyMmDdToDate(first_hosting_availability) : undefined}
              label="Date available"
              placeholder="Date available"
              onChange={date =>
                date
                  ? dispatch(actions.updateWorkspace({ first_hosting_availability: dateToYyyyMmDd(date) }))
                  : dispatch(actions.updateWorkspace({ first_hosting_availability: undefined }))
              }
              dateTimeFormat="FULL_WITH_WEEKDAY_YEAR"
            />
          </Layout>
        )}
      </Layout>
      {isMobile ? (
        <Layout color="white" position="fixed" bottom={0} paddingY={12} width={`calc(100% - ${contentPaddingX * 2}px)`}>
          <Button
            text="Next"
            size="sm"
            type="primary"
            href={`${ADD_PROPERTY_PATH}/1`}
            onClick={trackSubmission}
            disabled={!isValid}
            fullWidth
          />
        </Layout>
      ) : (
        <Layout marginTop={28}>
          <Button
            text="Next"
            size="sm"
            type="primary"
            href={`${ADD_PROPERTY_PATH}/1`}
            onClick={trackSubmission}
            disabled={!isValid}
          />
        </Layout>
      )}
    </>
  );
};

export default AddPropertyForm;
